// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import { connect } from "react-redux";
import {
  Button,
  Text,
  Select,
  Pane,
  TextInputField,
  Strong,
  AddIcon,
} from "evergreen-ui";
import Checkbox from "@material-ui/core/Checkbox";

import FormControlLabel from "@material-ui/core/FormControlLabel";

import styled from "styled-components";
import { updateExtra, removeExtra, getCustomerDetails } from "../../actions";
import {
  checkIntOrFloat,
  resourcesNotLoadedToast,
  validateCreditsAndPrice,
} from "../../utils";
import validator from "validator";
import { toaster } from "evergreen-ui/commonjs/toaster";
import TransactionModeSelect from "../TransactionModeSelect/index";

// -----------------------------------------------------------------------
// Styled Components
// -----------------------------------------------------------------------
const ExtrasActionsDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  .action-elem-container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 32px;
    margin-top: 20px;
  }

  .first-action-elem-container {
    margin-top: 24px;
  }

  .extras-price-container {
    width: 69px;
    height: 32px;
    margin-right: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  #extras-price-label {
    font-size: 12px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #425a70;
  }

  .added-custom-extras-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-bottom: 8px;
    border-bottom: 1px solid #edf0f2;
    width: 528px;
  }

  .extras-item-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 344px;
    height: 32px;
    margin-bottom: 16px;
  }

  .item-name-container,
  .item-price-container,
  .item-qty-container,
  .item-cross-container {
    width: 117px;
    margin-right: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .item-cross-container {
    width: 75px;
  }

  #item-name,
  #item-price,
  #item-qty {
    font-size: 12px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #425a70;
  }

  .item-price-container,
  .item-qty-container {
    width: 69px;
  }
`;

// -----------------------------------------------------------------------
// ExtrasActions Component
// -----------------------------------------------------------------------
class ExtrasActions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showCustomExtraAddDiv: false,
      showCustomExtraAddBtn: true,
      showAddedCustomExtras: false,
      customExtraItemName: "",
      customExtraItemPrice: "",
      customExtraItemDisc: "",
      customExtraItemAdv: "",
      customExtraItemQuantity: "",
      selectedExtra: "club-extras",
      clubExtraItemPrice: 0,
      clubExtraItemQty: "",
      clubExtraItemDisc: "",
      clubExtraItemAdv: "",
      clubExtraId: "",
      clubExtraItemName: "",
      postExtraDetails: [],
      isRent: false,
      clubCredit: false,
      customCredit: false,
      clubItemRent: "",
      clubItemQuantity: "",
      paymentMode: "No Pay",
      transactionMode: 0,
      selectedTransactionMode: 0,
      selectedCustomTransactionMode: 0,
      selectedClubTransactionMode: 0,
      disableNoPay: false,
      buttonLoader: false,
    };
    this.baseState = this.state;
  }

  async componentDidMount() {
    let reqBody = {
      clubId: 1,
      mobile: this.props.mobile,
    };

    await this.props.dispatch(getCustomerDetails(reqBody, false));
  }

  // -----------------------------------------------------------------------
  // handleClubExtrasSelect()
  // -----------------------------------------------------------------------
  handleClubExtrasSelect = async (extraId) => {
    if (extraId !== "club-extras") {
      await this.setState({
        selectedExtra: parseInt(extraId),
        clubExtraItemQty: 1,
        showCustomExtraAddDiv: false,
        showCustomExtraAddBtn: true,
      });
      for (var i = 0; i < this.props.clubExtrasList.extras.length; i++) {
        if (
          this.props.clubExtrasList.extras[i].extraId ===
          this.state.selectedExtra
        ) {
          await this.setState({
            clubExtraId: this.props.clubExtrasList.extras[i].extraId,
            clubExtraItemName: this.props.clubExtrasList.extras[i].name,
            clubExtraItemPrice: this.props.clubExtrasList.extras[i].price,
            clubItemRent: this.props.clubExtrasList.extras[i].isRent,
            clubItemQuantity: this.props.clubExtrasList.extras[i].quantity,
          });
        }
      }
    } else {
      this.setState({
        selectedExtra: extraId,
        clubExtraItemPrice: 0,
        clubExtraItemQty: "",
        clubExtraItemName: "",
        clubExtraId: "",
        clubExtraItemAdv: 0,
        clubExtraItemDisc: 0,
      });
    }
  };

  // -----------------------------------------------------------------------
  // handleCustomExtra()
  // -----------------------------------------------------------------------
  handleCustomExtraButton = () => {
    this.setState({
      showCustomExtraAddDiv: true,
      showCustomExtraAddBtn: false,
    });
  };

  // -----------------------------------------------------------------------
  // handleClubExtraQtyChange()
  // -----------------------------------------------------------------------
  handleClubExtraChange = async (e) => {
    const target = e.target;
    if (target.name === "item-Qty") {
      if (
        e.target.value.length > 0 &&
        e.target.value > 0 &&
        validator.isInt(e.target.value) &&
        !validator.contains(e.target.value, ".") &&
        !validator.contains(e.target.value, "+")
      ) {
        this.setState({ clubExtraItemQty: e.target.value });
      } else if (
        e.target.value.length === 1 &&
        e.target.value.charAt(0) === 0
      ) {
        this.setState({ clubExtraItemQty: "" });
      } else if (e.target.value.length <= 0) {
        this.setState({ clubExtraItemQty: "" });
      }
    }
    if (target.name === "is-credit") {
      this.setState({ clubCredit: target.checked });
    }
    if (target.name === "item-adv") {
      if (validateCreditsAndPrice(target.value) === "set") {
        this.setState({ clubExtraItemAdv: target.value });
        if (this.state.selectedClubTransactionMode === 0 && target.value > 0) {
          await this.setState({
            paymentMode: "Cash",
            transactionMode: 1,
            selectedClubTransactionMode: 1,
            disableNoPay: true,
          });
        }
      } else if (target.value.length <= 0) {
        this.setState({
          clubExtraItemAdv: "",
          paymentMode: "No Pay",
          transactionMode: 0,
          selectedClubTransactionMode: 0,
          disableNoPay: false,
        });
      }
    }

    if (target.name === "item-disc") {
      if (
        target.value.length >= 0 &&
        target.value >= 0 &&
        (validator.isInt(target.value) || validator.isFloat(target.value))
      ) {
        this.setState({ clubExtraItemDisc: target.value });
      } else if (target.value.length <= 0) {
        this.setState({ clubExtraItemDisc: "" });
      }
    }
  };

  // -----------------------------------------------------------------------
  // handleCustomExtraFieldsChange()
  // -----------------------------------------------------------------------
  handleCustomExtraFieldsChange = async (e) => {
    const target = e.target;
    if (target.name === "item-name") {
      this.setState({ customExtraItemName: target.value });
    }

    if (target.name === "is-rent") {
      this.setState({ isRent: target.checked });
    }
    if (target.name === "is-credit") {
      this.setState({ customCredit: target.checked });
    }

    if (target.name === "item-price") {
      if (
        target.value.length > 0 &&
        target.value > 0 &&
        (validator.isInt(target.value) || validator.isFloat(target.value))
      ) {
        this.setState({ customExtraItemPrice: target.value });
      } else if (target.value.length <= 0) {
        this.setState({ customExtraItemPrice: "" });
      }
    }

    if (target.name === "item-adv") {
      if (
        target.value.length >= 0 &&
        target.value >= 0 &&
        (validator.isInt(target.value) || validator.isFloat(target.value))
      ) {
        this.setState({ customExtraItemAdv: target.value });
        if (
          this.state.selectedCustomTransactionMode === 0 &&
          target.value > 0
        ) {
          await this.setState({
            paymentMode: "Cash",
            transactionMode: 1,
            selectedCustomTransactionMode: 1,
            disableNoPay: true,
          });
        }
      } else if (target.value.length <= 0) {
        this.setState({
          customExtraItemAdv: "",
          paymentMode: "No Pay",
          transactionMode: 0,
          selectedCustomTransactionMode: 0,
          disableNoPay: false,
        });
      }
    }

    if (target.name === "item-disc") {
      if (
        target.value.length >= 0 &&
        target.value >= 0 &&
        (validator.isInt(target.value) || validator.isFloat(target.value))
      ) {
        this.setState({ customExtraItemDisc: target.value });
      } else if (target.value.length <= 0) {
        this.setState({ customExtraItemDisc: "" });
      }
    }

    if (target.name === "item-qty") {
      if (
        target.value.length > 0 &&
        target.value > 0 &&
        validator.isInt(target.value) &&
        !validator.contains(target.value, ".")
      ) {
        this.setState({ customExtraItemQuantity: target.value });
      } else if (target.value.length <= 0) {
        this.setState({ customExtraItemQuantity: "" });
      }
    }
  };

  // -----------------------------------------------------------------------
  // handleAddCustomExtra()
  // -----------------------------------------------------------------------
  handleAddCustomExtra = async (e) => {
    e.persist();
    this.setState({
      buttonLoader: true,
    });
    let { bulkBooking, bookingId, credits, cartData } = this.props;

    let {
      customExtraItemName,
      customExtraItemPrice,
      customExtraItemQuantity,
      customExtraItemAdv,
      customExtraItemDisc,

      customCredit,
    } = this.state;
    let discount = isNaN(parseInt(customExtraItemDisc))
      ? 0
      : parseInt(customExtraItemDisc);
    let advance = isNaN(parseInt(customExtraItemAdv))
      ? 0
      : parseInt(customExtraItemAdv);
    let sum = discount + advance;
    let type = customCredit ? true : false;

    if (sum > customExtraItemPrice) {
      this.setState({
        buttonLoader: false,
      });
      toaster.warning("Values are not valid");
    } else if (
      customExtraItemName !== "" &&
      customExtraItemPrice !== "" &&
      customExtraItemQuantity !== ""
    ) {
      let reqBody = {
        extraId: null,
        bookingId: bookingId,
        name: customExtraItemName,
        price: checkIntOrFloat(customExtraItemPrice),
        quantity: parseInt(customExtraItemQuantity),
        useCredits: type,
        advance: advance,
        discount: discount,
        mode: this.state.selectedCustomTransactionMode,
      };

      if (bulkBooking) {
        reqBody = {
          patternBookingId: bookingId,
          extraId: null,
          name: customExtraItemName,
          price: checkIntOrFloat(customExtraItemPrice),
          quantity: parseInt(customExtraItemQuantity),
          useCredits: type,
          advance: advance,
          discount: discount,
          mode: this.state.selectedCustomTransactionMode,
        };
      }
      await this.props.dispatch(updateExtra(reqBody, bulkBooking));
      if (this.props.cartToast) {
        this.setState({
          buttonLoader: false,
        });
        if (this.props.cartToast.resourcesNotLoadedMessage) {
          resourcesNotLoadedToast();
        } else if (this.props.cartToast.httpErrorMessage) {
          toaster.danger(this.props.cartToast.httpErrorMessage, {
            id: "HTTP-ERROR-TOAST-4",
          });
        } else if (this.props.cartToast.requestStatus === -1) {
          toaster.notify("Sorry, you don't have permission to do that");
        } else if (
          this.props.cartToast.requestStatus === 1 &&
          this.props.cartData
        ) {
          let reqBody = {
            clubId: 1,
            mobile: this.props.mobile,
          };

          await this.props.dispatch(getCustomerDetails(reqBody, false));
          toaster.success(this.props.cartToast.message, {
            id: "HTTP-ERROR-TOAST-4",
          });
          this.setState({
            ...this.baseState,
          });
          await this.setState({
            customExtraItemName: "",
            customExtraItemPrice: "",
            customExtraItemQuantity: "",
            customExtraItemAdv: 0,
            customExtraItemDisc: 0,
          });
        } else if (this.props.cartToast.requestStatus === 0) {
          toaster.danger(this.props.cartToast.message, {
            id: "HTTP-ERROR-TOAST-4",
          });
        }
      }
    } else if (customExtraItemName === "") {
      this.setState({
        buttonLoader: false,
      });
      toaster.warning("name is mandatory");
    } else if (customExtraItemPrice === "") {
      this.setState({
        buttonLoader: false,
      });
      toaster.warning("price is mandatory");
    } else if (customExtraItemQuantity === "") {
      this.setState({
        buttonLoader: false,
      });
      toaster.warning("plaese enter quantity");
    }
  };

  // -----------------------------------------------------------------------
  // handleAddClubExtra()
  // -----------------------------------------------------------------------
  handleAddClubExtra = async () => {
    this.setState({
      buttonLoader: true,
    });
    let { bulkBooking, bookingId } = this.props;
    let {
      clubExtraItemName,
      clubExtraItemPrice,
      clubExtraItemAdv,
      clubExtraItemDisc,
      clubExtraId,
      clubExtraItemQty,
      clubCredit,
      clubItemRent,
    } = this.state;
    let body;

    let type = clubCredit ? true : false;

    let discount = isNaN(parseInt(clubExtraItemDisc))
      ? 0
      : parseInt(clubExtraItemDisc);

    let advance = isNaN(parseInt(clubExtraItemAdv))
      ? 0
      : parseInt(clubExtraItemAdv);
    let sum = discount + advance;

    if (sum > clubExtraItemPrice) {
      this.setState({
        buttonLoader: false,
      });
      toaster.warning("Values are not valid");
    } else if (this.state.selectedExtra !== "club-extras") {
      if (this.state.clubExtraItemQty !== "") {
        let reqBody = {
          bookingId: bookingId,
          extraId: clubExtraId,
          name: clubExtraItemName,
          price: clubExtraItemPrice,
          quantity: clubExtraItemQty,

          advance: advance,
          discount: discount,
          mode: this.state.selectedClubTransactionMode,
          useCredits: type,
        };

        if (bulkBooking) {
          reqBody = {
            patternBookingId: bookingId,
            extraId: clubExtraId,
            name: clubExtraItemName,
            price: clubExtraItemPrice,
            quantity: clubExtraItemQty,
            advance: advance,
            discount: discount,
            mode: this.state.selectedClubTransactionMode,
            useCredits: type,
          };
        }

        await this.props.dispatch(updateExtra(reqBody, bulkBooking));

        if (this.props.cartToast) {
          if (this.props.cartToast.resourcesNotLoadedMessage) {
            this.setState({
              buttonLoader: false,
            });
            resourcesNotLoadedToast();
          } else if (this.props.cartToast.httpErrorMessage) {
            this.setState({
              buttonLoader: false,
            });
            toaster.danger(this.props.cartToast.httpErrorMessage, {
              id: "HTTP-ERROR-TOAST-4",
            });
          } else if (
            this.props.cartToast.requestStatus === 1 &&
            this.props.cartData
          ) {
            let reqBody = {
              clubId: 1,
              mobile: this.props.mobile,
            };

            await this.props.dispatch(getCustomerDetails(reqBody, false));
            //postExtraDetails[postExtraDetails.length]=reqBody

            toaster.success(this.props.cartToast.message, {
              id: "HTTP-ERROR-TOAST-4",
            });
            this.setState({
              ...this.baseState,
            });

            await this.setState({
              selectedExtra: "club-extras",
              clubExtraItemPrice: 0,
              clubExtraItemQty: "",
              clubExtraItemName: "",
              clubExtraItemAdv: 0,
              buttonLoader: false,
              clubExtraItemDisc: 0,
              //postExtraDetails: this.state.postExtraDetails,
            });
          } else if (this.props.cartToast.requestStatus === 0) {
            toaster.danger(this.props.cartToast.message, {
              id: "HTTP-ERROR-TOAST-4",
            });
            this.setState({
              buttonLoader: false,
            });
          }
        }
      } else {
        this.setState({
          buttonLoader: false,
        });
        toaster.warning("Minimum quantity must be 1");
      }
    } else {
      this.setState({
        buttonLoader: false,
      });
      toaster.warning("Select an addon first");
    }
  };

  // -----------------------------------------------------------------------
  // handleRemoveExtra()
  // -----------------------------------------------------------------------
  handleRemoveExtra = async (id) => {
    let reqParams = { cartedExtraId: id };
    await this.props.dispatch(removeExtra(reqParams));
    if (this.props.cartToast) {
      if (this.props.cartToast.resourcesNotLoadedMessage) {
        resourcesNotLoadedToast();
      } else if (this.props.cartToast.httpErrorMessage) {
        toaster.danger(this.props.cartToast.httpErrorMessage, {
          id: "HTTP-ERROR-TOAST-4",
        });
      } else if (this.props.cartToast.requestStatus === -1) {
        toaster.notify("Sorry, you don't have permission to do that");
      } else if (
        this.props.cartToast.requestStatus === 1 &&
        this.props.cartData
      ) {
        toaster.success(this.props.cartToast.message, {
          id: "HTTP-ERROR-TOAST-4",
        });
      } else if (this.props.cartToast.requestStatus === 0) {
        toaster.danger(this.props.cartToast.message, {
          id: "HTTP-ERROR-TOAST-4",
        });
      }
    }
  };

  // -----------------------------------------------------------------------
  // changeTransactionMode()
  // -----------------------------------------------------------------------
  changeTransactionMode = (e, type) => {
    e.persist();

    if (type === "custom") {
      if (this.state.customExtraItemAdv.length > 0) {
        this.setState({
          paymentMode:
            e.nativeEvent.target[
              e.nativeEvent.target.selectedIndex
            ].text.toString(),
          transactionMode: parseInt(e.target.value),
          selectedCustomTransactionMode: parseInt(e.target.value),
          isAdvanceInvalid: false,
        });
      } else {
        this.setState({ customExtraItemAdv: "" });
      }
    } else {
      if (this.state.clubExtraItemAdv.length > 0) {
        this.setState({
          paymentMode:
            e.nativeEvent.target[
              e.nativeEvent.target.selectedIndex
            ].text.toString(),
          transactionMode: parseInt(e.target.value),
          selectedClubTransactionMode: parseInt(e.target.value),
          isAdvanceInvalid: false,
        });
      } else {
        this.setState({ clubExtraItemAdv: "" });
      }
    }
  };

  // -----------------------------------------------------------------------
  // render()
  // -----------------------------------------------------------------------
  render() {
    const {
      showCustomExtraAddDiv,
      showCustomExtraAddBtn,
      clubExtraItemQty,
      clubExtraItemAdv,
      clubExtraItemDisc,
      clubCredit,
      customExtraItemName,
      customExtraItemPrice,
      customExtraItemQuantity,
      selectedExtra,
      clubExtraItemPrice,
      customExtraItemAdv,
      customExtraItemDisc,
      clubItemRent,
      clubItemQuantity,
      clubExtraId,
      selectedClubTransactionMode,
      selectedCustomTransactionMode,
      disableNoPay,
      customCredit,
      buttonLoader,
    } = this.state;
    const {
      cartData,
      clubExtrasList,
      extrasList,
      postBooking,
      customerDetails,
    } = this.props;

    let clubCreditAvail = Math.sign(
      clubExtraItemPrice - clubExtraItemDisc - clubExtraItemAdv
    );
    let canClubCredit =
      clubCreditAvail > 0
        ? clubExtraItemPrice - clubExtraItemDisc - clubExtraItemAdv
        : 0;

    let customCreditAvail = Math.sign(
      customExtraItemPrice - customExtraItemDisc - customExtraItemAdv
    );
    let canCustomCredit =
      customCreditAvail > 0
        ? customExtraItemPrice - customExtraItemDisc - customExtraItemAdv
        : 0;

    return (
      <Pane display={"flex"} flexDirection="column" marginTop={12}>
        <Pane flexDirection="row" display="flex" justifyContent="flex-start">
          <Pane width={"24%"}>
            <Select
              height={35}
              marginTop={23}
              onChange={(e) => this.handleClubExtrasSelect(e.target.value)}
            >
              <option
                value="club-extras"
                selected={selectedExtra === "club-extras"}
              >
                Club Add on
              </option>
              {clubExtrasList &&
                clubExtrasList.extras.map((extra) => (
                  <option
                    key={extra.extraId}
                    value={extra.extraId}
                    selected={selectedExtra === extra.extraId}
                  >
                    {extra.name}
                  </option>
                ))}
            </Select>
          </Pane>

          <TextInputField
            width={"22%"}
            disabled
            label="Price"
            value={clubExtraItemPrice}
            marginLeft={12}
          />
          <TextInputField
            width={"22%"}
            label="Quantity"
            name="item-Qty"
            value={clubExtraItemQty}
            onChange={(e) => this.handleClubExtraChange(e)}
            marginLeft={12}
          />

          {clubItemRent
            ? clubExtraId && (
                <Strong marginLeft={12} marginTop={35} size={300} color="green">
                  Available For Rent
                </Strong>
              )
            : clubExtraId && (
                <Strong marginLeft={12} marginTop={35} size={300} color="red">
                  Non Rental ({clubItemQuantity})
                </Strong>
              )}
        </Pane>
        <Pane flexDirection="row" display="flex" justifyContent="flex-start">
          <TextInputField
            width={"24%"}
            value={clubExtraItemDisc}
            name="item-disc"
            onChange={(e) => this.handleClubExtraChange(e)}
            label="Discount"
          />

          <TextInputField
            width={"22%"}
            label="Advance"
            value={clubExtraItemAdv}
            name="item-adv"
            onChange={(e) => this.handleClubExtraChange(e)}
            marginLeft={12}
          />
          <Pane width={"22%"}>
            <FormControlLabel
              value={""}
              control={
                <TransactionModeSelect
                  disableNoPay={disableNoPay}
                  selectedTransactionMode={selectedClubTransactionMode}
                  changeTransactionMode={(e) =>
                    this.changeTransactionMode(e, "club")
                  }
                />
              }
              label={
                <Text marginLeft={-55} size={400}>
                  Mode
                </Text>
              }
              labelPlacement="top"
            />
          </Pane>
          <Pane marginLeft={10}>
            <FormControlLabel
              value={""}
              control={
                <Pane display="flex" flexDirection="row">
                  <Checkbox
                    color="primary"
                    checked={clubCredit}
                    name="is-credit"
                    onChange={(e) => this.handleClubExtraChange(e)}
                  />
                  <Pane display="flex" flexDirection="column">
                    {/* <Strong color="black" marginTop={34}>
											{canClubCredit}
										</Strong> */}
                    <Strong color="muted" size={300} marginTop={14}>
                      Available{" "}
                      {customerDetails &&
                      customerDetails.customerDetails &&
                      customerDetails.customerDetails.credits
                        ? customerDetails.customerDetails.credits.toFixed(2)
                        : "0"}
                    </Strong>
                  </Pane>
                </Pane>
              }
              label={<Strong size={300}>Redeem Credits</Strong>}
              labelPlacement="top"
            />
          </Pane>
        </Pane>

        <Pane
          display="flex"
          borderTop="1px solid #eff0f3"
          marginTop={12}
          flexDirection="row"
          justifyContent={!showCustomExtraAddDiv ? "flex-end" : "space-between"}
        >
          {!showCustomExtraAddDiv && (
            <Button
              intent="none"
              color="black"
              marginTop={10}
              iconBefore={<AddIcon />}
              background={"#eff0f3"}
              onClick={(e) => this.handleCustomExtraButton(e)}
            >
              Custom Add On
            </Button>
          )}
          <Button
            height={34}
            marginTop={10}
            width={85}
            marginLeft={"auto"}
            justifyContent={"center"}
            isLoading={buttonLoader}
            appearance="primary"
            onClick={(e) => this.handleAddClubExtra(e)}
            intent="none"
          >
            Add
          </Button>
        </Pane>

        {showCustomExtraAddDiv && (
          <Pane
            flexDirection="column"
            display="flex"
            marginTop={8}
            borderTop="1px solid #eff0f3"
          >
            <Pane
              marginTop={8}
              flexDirection="row"
              display="flex"
              justifyContent="flex-start"
            >
              <TextInputField
                width={"22%"}
                name="item-name"
                value={customExtraItemName}
                onChange={(e) => this.handleCustomExtraFieldsChange(e)}
                label="Name"
              />

              <TextInputField
                width={"22%"}
                label="Price"
                name="item-price"
                value={customExtraItemPrice}
                onChange={(e) => this.handleCustomExtraFieldsChange(e)}
                marginLeft={12}
              />
              <TextInputField
                width={"22%"}
                label="Quantity"
                name="item-qty"
                value={customExtraItemQuantity}
                onChange={(e) => this.handleCustomExtraFieldsChange(e)}
                marginLeft={12}
              />
            </Pane>
            <Pane
              flexDirection="row"
              display="flex"
              justifyContent="flex-start"
            >
              <TextInputField
                name="item-disc"
                value={customExtraItemDisc}
                onChange={(e) => this.handleCustomExtraFieldsChange(e)}
                width={"22%"}
                label="Discount"
              />

              <TextInputField
                width={"22%"}
                label="Advance"
                name="item-adv"
                marginLeft={12}
                value={customExtraItemAdv}
                onChange={(e) => this.handleCustomExtraFieldsChange(e)}
              />
              <Pane width={"22%"}>
                <FormControlLabel
                  value={""}
                  control={
                    <TransactionModeSelect
                      disableNoPay={disableNoPay}
                      selectedTransactionMode={selectedCustomTransactionMode}
                      changeTransactionMode={(e) =>
                        this.changeTransactionMode(e, "custom")
                      }
                    />
                  }
                  label={
                    <Text marginLeft={-55} size={400}>
                      Mode
                    </Text>
                  }
                  labelPlacement="top"
                />
              </Pane>
              <Pane marginLeft={10}>
                <FormControlLabel
                  value={""}
                  control={
                    <Pane display="flex" flexDirection="row">
                      <Checkbox
                        color="primary"
                        checked={customCredit}
                        name="is-credit"
                        onChange={(e) => this.handleCustomExtraFieldsChange(e)}
                      />
                      <Pane display="flex" flexDirection="column">
                        {/* <Strong color="black" marginTop={34}>
													{canCustomCredit}
												</Strong> */}
                        <Strong color="muted" size={300} marginTop={14}>
                          Available{" "}
                          {customerDetails &&
                          customerDetails.customerDetails &&
                          customerDetails.customerDetails.credits
                            ? customerDetails.customerDetails.credits.toFixed(2)
                            : "0"}
                        </Strong>
                      </Pane>
                    </Pane>
                  }
                  label={<Strong size={300}>Redeem Credits</Strong>}
                  labelPlacement="top"
                />
              </Pane>
            </Pane>
            <Pane
              display="flex"
              borderTop="1px solid #eff0f3"
              marginTop={8}
              flexDirection="row"
              justifyContent="flex-end"
            >
              <Button
                height={34}
                marginTop={8}
                width={85}
                marginLeft={"auto"}
                justifyContent={"center"}
                isLoading={buttonLoader}
                appearance="primary"
                onClick={(e) => this.handleAddCustomExtra(e)}
                intent="none"
              >
                Add
              </Button>
            </Pane>
          </Pane>
        )}
      </Pane>
    );
  }
}

const mapStateToProps = ({
  cartData,
  clubExtrasList,
  cartToast,
  customerDetails,
  updateExtraPaymentToast,
}) => ({
  cartData,
  clubExtrasList,
  cartToast,
  customerDetails,
  updateExtraPaymentToast,
});

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default connect(mapStateToProps)(ExtrasActions);
