// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import { Text, TextInput } from "evergreen-ui";
import { checkIntOrFloat, generateCurrencySymbol } from "../../utils";
import TransactionModeSelect from "../TransactionModeSelect";

// -----------------------------------------------------------------------
// PayNowDialogContent Component
// -----------------------------------------------------------------------
class PayNowDialogContent extends React.Component {
  // -----------------------------------------------------------------------
  // render()
  // -----------------------------------------------------------------------
  render() {
    const {
      handlePayNow,
      changeTransactionMode,
      balance,
      payNowAmount,
      isPayNowInvalid,
      selectedTransactionMode,
      disableNoPay
    } = this.props;
    return (
      <React.Fragment>
        <div className="PNDC-A1-wrapper">
          <div className="PNDC-A1-col-container">
            <div className="PNDC-A1-row-container">
              <Text id="PNDC-A1-type-one-label" marginRight={48}>
                Amount
              </Text>
              <TextInput
                marginRight={16}
                width={104}
                height={32}
                placeholder={`${generateCurrencySymbol()} 0`}
                value={payNowAmount}
                onChange={e => handlePayNow(e, "amount-change")}
                isInvalid={isPayNowInvalid}
              />
              <div className="PNDC-A1-transaction-mode-select-container">
                <TransactionModeSelect
                  selectedTransactionMode={selectedTransactionMode}
                  disableNoPay={disableNoPay}
                  changeTransactionMode={changeTransactionMode}
                />
              </div>
            </div>
            <div className="PNDC-A1-row-container PNDC-A1-margin-top-24">
              <Text id="PNDC-A1-type-one-label" marginRight={48}>
                Balance
              </Text>
              <Text id="PNDC-A1-type-two-label">
                {`${generateCurrencySymbol()} ${checkIntOrFloat(balance)}`}
              </Text>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default PayNowDialogContent;
