// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import { Text, Icon, BuildIcon } from "evergreen-ui";

// -----------------------------------------------------------------------
// ComingSoon Component
// -----------------------------------------------------------------------
class ComingSoon extends React.Component {
  // -----------------------------------------------------------------------
  // render()
  // -----------------------------------------------------------------------
  render() {
    return (
      <div className="CS-A1-wrapper">
        <div className="CS-A1-icon-container">
          <Icon icon={<BuildIcon />} color="disabled" size={200} />
        </div>
        <div className="CS-A1-label-container">
          <Text id="CS-A1-label-type-one">Coming Soon...</Text>
        </div>
      </div>
    );
  }
}

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default ComingSoon;
