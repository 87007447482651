// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import {
  Text,
  Popover,
  Radio,
  Button,
  Position,
  CaretDownIcon,
} from "evergreen-ui";

// -----------------------------------------------------------------------
// TransactionReportsFilter Component
// -----------------------------------------------------------------------
class TransactionReportsFilter extends React.Component {
  // -----------------------------------------------------------------------
  // render()
  // -----------------------------------------------------------------------
  render() {
    const {
      selectedShowTransactionFor,
      handleShowTransactionsForChange,
      showTransactionReportsFilterPopover,
    } = this.props;
    return (
      <React.Fragment>
        <div className="TRF-A1-wrapper">
          <div className="TRF-A1-col-container">
            <div className="TRF-A1-label-container TRF-A1-margin-bottom-8">
              <Text id="TRF-A1-type-one-label">Show</Text>
            </div>
            <div className="TRF-A1-show-transaction-for-radio-container">
              {showTransactionReportsFilterPopover && (
                <Popover
                  content={
                    <div className="TRF-A1-show-transaction-for-radio-popover-wrapper">
                      <div className="TRF-A1-label-container TRF-A1-margin-bottom-4">
                        <Text id="TRF-A1-type-one-label">Transactions</Text>
                      </div>
                      <div
                        className="TRF-A1-radio-btn-container"
                        aria-label="Radio Group Label 16"
                        role="group"
                      >
                        <Radio
                          className="TRF-A1-radio-input"
                          width={160}
                          size={16}
                          name="group32"
                          label="All"
                          checked={
                            selectedShowTransactionFor === "all" ? true : false
                          }
                          onChange={(e) =>
                            handleShowTransactionsForChange(e, "all")
                          }
                        />
                        <Radio
                          className="TRF-A1-radio-input"
                          width={160}
                          size={16}
                          name="group32"
                          label="Only Paid"
                          checked={
                            selectedShowTransactionFor === "only paid"
                              ? true
                              : false
                          }
                          onChange={(e) =>
                            handleShowTransactionsForChange(e, "only paid")
                          }
                        />
                      </div>
                    </div>
                  }
                  position={Position.BOTTOM_LEFT}
                >
                  <Button
                    className="TRF-A1-button-as-select"
                    width={160}
                    height={32}
                    iconAfter={<CaretDownIcon />}
                  >
                    {selectedShowTransactionFor}
                  </Button>
                </Popover>
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default TransactionReportsFilter;
