// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// -----------------------------------------------------------------------
// CustomizedTimePicker Component
// -----------------------------------------------------------------------
class CustomizedTimePicker extends React.Component {
  // -----------------------------------------------------------------------
  // render()
  // -----------------------------------------------------------------------
  render() {
    const {
      time,
      clubMinuteStep,
      handleTimeChange,
      isTimePickerDisabled
    } = this.props;
    return (
      <div className="CTP-A1-wrapper">
        <DatePicker
          selected={time}
          onChange={handleTimeChange}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={clubMinuteStep}
          dateFormat="h:mm aa"
          timeCaption="Time"
          disabled={isTimePickerDisabled}
        />
      </div>
    );
  }
}

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default CustomizedTimePicker;
