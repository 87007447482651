// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import { Button } from "evergreen-ui";
import styled from "styled-components";

// -----------------------------------------------------------------------
// Styled Components
// -----------------------------------------------------------------------
const UnblockActionsDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 20px auto 16px auto;
  width: 292px;
`;

// -----------------------------------------------------------------------
// UnblockActions Component
// -----------------------------------------------------------------------
class UnblockActions extends React.Component {
  // -----------------------------------------------------------------------
  // render()
  // -----------------------------------------------------------------------
  render() {
    const { slotData, handleUnblocking } = this.props;
    return (
      <React.Fragment>
        <UnblockActionsDiv>
          <Button
            className="button"
            width={130}
            height={32}
            appearance="primary"
            intent="success"
            onClick={e => {
              handleUnblocking(slotData);
            }}
          >
            Unblock
          </Button>
        </UnblockActionsDiv>
      </React.Fragment>
    );
  }
}

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default UnblockActions;
