// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import { Button } from "evergreen-ui";
import styled from "styled-components";

// -----------------------------------------------------------------------
// Styled Components
// -----------------------------------------------------------------------
const CourtActionsDiv = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 20px auto 16px auto;
	width: 292px;
`;

// -----------------------------------------------------------------------
// CourtActions Component
// -----------------------------------------------------------------------
class CourtActions extends React.Component {
	// -----------------------------------------------------------------------
	// render()
	// -----------------------------------------------------------------------
	render() {
		const {
			slotData,
			date,
			selectedActivityId,
			onAddToCart,
			onBookNow,
			activityType1Details,
		} = this.props;
		return (
			<React.Fragment>
				<CourtActionsDiv>
					<Button
						className="button"
						width={130}
						height={32}
						appearance="primary"
						onClick={(e) => {
							onBookNow(slotData, date, selectedActivityId);
							if (activityType1Details) {
								activityType1Details(slotData, false);
							}
						}}
					>
						Book Now
					</Button>

					<Button
						className="button"
						width={130}
						height={32}
						appearance="minimal"
						onClick={(e) => {
							onAddToCart(slotData, date, selectedActivityId);
							if (activityType1Details) {
								activityType1Details(slotData, false);
							}
						}}
					>
						Add to cart
					</Button>
				</CourtActionsDiv>
			</React.Fragment>
		);
	}
}

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default CourtActions;
