const authStatus = (state = false, action) => {
  if (action.type === "TOGGLE_AUTH_STATUS") {
    state = !state;
    return state;
  } else {
    return state;
  }
  // switch (action.type) {
  //   case "TOGGLE_AUTH_STATUS": {
  //     state = !state;
  //     return state;
  //   }

  //   default: {
  //     return state;
  //   }
  // }
};

export default authStatus;
