// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import { connect } from "react-redux";
import {
  TextInputField,
  Button,
  toaster,
  Strong,
  Pane,
  SelectField,
  Dialog,
  Radio,
  IconButton,
  Menu,
  Switch,
  CrossIcon,
} from "evergreen-ui";

import { generateCurrencySymbol } from "../../utils";
import {
  updateConfigExtra,
  addConfigExtra,
  getConfigExtraList,
  downloadExtra,
} from "../../actions/index";
import TableBody from "../ExtraConfig/tableBody";
// -----------------------------------------------------------------------
// LoginPage Component
// -----------------------------------------------------------------------
class ExtraConfig extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAddAdons: false,
      selectedActivityValue: "",
      selectedActivityId: "",
      selectedActivityName: "",
      defaultActivityName: "Select a sport",
      defaultActivityValue: "Select a sport",
      name: "",
      quantity: "",
      price: "",
      isActive: true,
      isRental: false,
      isPerActivity: false,
      isAllActivity: true,
      disable: false,
      extraId: "",
    };
    this.baseState = this.state;
  }

  addAddons = async (type) => {
    if (type === "open") {
      this.setState({
        isAddAdons: true,
      });
    } else {
      this.setState({
        isAddAdons: false,
      });
      this.setState({
        ...this.baseState,
      });
    }
  };

  editAddons = async (data) => {
    this.setState({
      disable: true,
      name: data.name,
      quantity: data.quantity,
      price: data.price,
      extraId: data.extraId,
      isActive: data.isActive,
      isRental: data.isRent,
      isAllActivity: !data.isPerActivity,
      isPerActivity: data.isPerActivity,
    });
    this.addAddons("open");
  };

  onConfirm = async () => {
    let {
      extraId,
      disable,
      name,
      quantity,
      price,
      isRental,
      isActive,
      isPerActivity,
      selectedActivityValue,
    } = this.state;

    if (disable) {
      if (extraId && name.length > 0 && quantity >= 0 && price >= 0) {
        let extraPrice = isNaN(parseInt(price)) ? 0 : price;
        let extraQuantity = isNaN(parseInt(quantity)) ? 0 : parseInt(quantity);
        let reqBody = {
          extraId: extraId,
          name: name,
          quantity: extraQuantity,
          price: extraPrice,
          active: isActive,
        };
        await this.props.dispatch(updateConfigExtra(reqBody));
      } else {
        await this.props.dispatch({
          type: "SET_CONFIG_EXTRA_TOAST",
          payload: null,
        });
        toaster.warning("Feilds cant be empty or Invalid");
      }
    } else {
      if (name.length > 0 && quantity >= 0 && price >= 0) {
        let extraPrice = isNaN(parseInt(price)) ? 0 : price;
        let extraQuantity = isNaN(parseInt(quantity)) ? 0 : parseInt(quantity);
        let reqBody = {
          name: name,
          quantity: extraQuantity,
          price: extraPrice,
          isRent: isRental,
          isPerActivity: isPerActivity,
          activityId: isPerActivity ? selectedActivityValue : null,
        };
        await this.props.dispatch(addConfigExtra(reqBody));
      } else {
        await this.props.dispatch({
          type: "SET_CONFIG_EXTRA_TOAST",
          payload: null,
        });
        toaster.warning("Feilds cant be empty or Invalid");
      }
    }

    if (
      this.props.configExtrasToast &&
      this.props.configExtrasToast.requestStatus === -1
    ) {
      toaster.notify("Sorry, you don't have permission to do that");
    } else if (
      this.props.configExtrasToast &&
      this.props.configExtrasToast.requestStatus === 1
    ) {
      await this.props.dispatch(getConfigExtraList());
      toaster.success(this.props.configExtrasToast.message);
      this.addAddons("close");
    } else if (
      this.props.configExtrasToast &&
      this.props.configExtrasToast.requestStatus === 0
    ) {
      toaster.success(this.props.configExtrasToast.message);
    }
  };

  handleActivityChange = async (e) => {
    e.persist();
    if (e.target.value !== "select-a-sport") {
      await this.setState({
        selectedActivityValue: e.target.value,
        selectedActivityId: parseInt(e.target.value),
        selectedActivityName:
          e.nativeEvent.target[
            e.nativeEvent.target.selectedIndex
          ].text.toString(),
      });
    }
  };

  // -----------------------------------------------------------------------
  // ComponentDimMount()
  // -----------------------------------------------------------------------
  componentDidMount() {
    this.setState({
      ...this.baseState,
    });
  }

  handleChange = (e) => {
    const target = e.target;

    if (target.name === "name") {
      this.setState({ name: target.value });
    }
    if (target.name === "qty") {
      this.setState({ quantity: target.value });
    }
    if (target.name === "price") {
      this.setState({ price: target.value });
    }
    if (target.name === "isActive") {
      this.setState({ isActive: target.checked });
    }

    if (target.name === "rental") {
      this.setState({ isRental: target.checked });
    }
    if (target.name === "per-activity") {
      this.setState({ isPerActivity: target.checked, isAllActivity: false });
    }
    if (target.name === "all-activity") {
      this.setState({ isAllActivity: target.checked, isPerActivity: false });
    }
  };

  render() {
    let {
      defaultActivityName,
      selectedActivityValue,
      defaultActivityValue,
      isActive,
      isRental,
      name,
      quantity,
      price,
      isPerActivity,
      isAllActivity,
      disable,
    } = this.state;

    const { configExtrasList, clubActivityData } = this.props;

    // let extrasCount =
    // 	configExtrasList && configExtrasList.data && configExtrasList.data.length;
    return (
      <React.Fragment>
        <Pane
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"flex-start"}
          alignItems={"flex-start"}
        >
          <Pane padding={10}>Addons Configrations</Pane>
          <Pane
            display={"flex"}
            flexDirection={"row"}
            background="tint2"
            padding={15}
            marginBottom={16}
            width="100%"
            justifyContent={"flext-start"}
          ></Pane>
          <Pane
            display={"flex"}
            flexDirection={"row"}
            padding={5}
            marginBottom={5}
            width="100%"
            justifyContent={"flex-end"}
          >
            <Button
              width={98}
              marginTop={25}
              height={30}
              marginRight={15}
              border={1}
              appearance="minimal"
              className={"border-button"}
              onClick={() => this.props.dispatch(downloadExtra())}
            >
              Export as Excel
            </Button>
            <Button
              width={98}
              marginTop={25}
              height={30}
              marginRight={50}
              appearance="primary"
              intent="success"
              onClick={() => this.addAddons("open")}
            >
              Add Addons
            </Button>
          </Pane>
          <TableBody
            editAddons={this.editAddons}
            configExtrasList={configExtrasList}
          />
        </Pane>

        <Dialog
          width={550}
          isShown={this.state.isAddAdons}
          hasFooter={false}
          hasHeader={false}
          onCloseComplete={(e) => this.addAddons("close")}
        >
          <Pane
            marginBottom={7}
            display={"flex"}
            flexDirection={"row"}
            justifyContent="space-between"
            alignItems={"center"}
          >
            <Strong size={400}>Add Addons</Strong>
            <IconButton
              icon={<CrossIcon />}
              appearance="minimal"
              onClick={(e) => this.addAddons("close")}
            />
          </Pane>

          <Menu.Divider />

          <Pane
            display={"flex"}
            flexDirection="row"
            justifyContent="space-between"
            marginBottom={12}
            marginTop={10}
          >
            <TextInputField
              onChange={(e) => this.handleChange(e)}
              name={"name"}
              label="Name"
              height={35}
              value={name}
              width={"45%"}
              // style={{ fontWeight: 900 }}
            />
            <TextInputField
              onChange={(e) => this.handleChange(e)}
              name={"qty"}
              value={quantity}
              label="Qty Available"
              width={"45%"}
              height={35}
              // style={{ fontWeight: 900 }}
            />
          </Pane>
          <Pane
            display={"flex"}
            flexDirection="row"
            justifyContent="space-between"
            marginBottom={12}
            marginTop={10}
          >
            <TextInputField
              label={`Price Per Item (${generateCurrencySymbol()})`}
              height={35}
              // style={{ fontWeight: 900 }}
              width={"45%"}
              value={price}
              onChange={(e) => this.handleChange(e)}
              name={"price"}
            />
            <Pane marginTop={23} background={"tint1"} height={32} width={"45%"}>
              <Pane
                display={"flex"}
                flexDirection={"row"}
                justifyContent="center"
                marginTop={8}
              >
                <Strong size={300} color={"#888A8D"} marginRight={8}>
                  Rental
                </Strong>
                <Switch
                  disabled={disable}
                  onChange={(e) => this.handleChange(e)}
                  name={"rental"}
                  checked={isRental}
                />
              </Pane>
            </Pane>
          </Pane>

          <Pane
            display={"flex"}
            flexDirection="row"
            justifyContent="space-between"
            marginBottom={20}
            marginTop={20}
          >
            <Pane display={"flex"} flexDirection="row" width={"45%"}>
              <Radio
                disabled={disable}
                marginRight={15}
                checked={isPerActivity}
                size={15}
                onChange={(e) => this.handleChange(e)}
                name={"per-activity"}
                label={<Strong>Per Activity</Strong>}
              />
              <Radio
                disabled={disable}
                checked={isAllActivity}
                size={15}
                label={<Strong>All Activity</Strong>}
                onChange={(e) => this.handleChange(e)}
                name={"all-activity"}
              />
            </Pane>
            {isPerActivity && !disable && (
              <Pane width={"45%"} marginTop={10}>
                {clubActivityData &&
                  clubActivityData.requestStatus === 1 &&
                  clubActivityData.activities && (
                    <SelectField
                      label={"Select Activity"}
                      height={32}
                      onChange={(e) => this.handleActivityChange(e)}
                      value={selectedActivityValue}
                    >
                      <option value={defaultActivityValue}>
                        {defaultActivityName}
                      </option>
                      {Object.keys(clubActivityData.activities).map(
                        (activityId) => (
                          <option key={activityId} value={activityId}>
                            {clubActivityData.activities[activityId]}
                          </option>
                        )
                      )}
                    </SelectField>
                  )}
              </Pane>
            )}
          </Pane>
          <Menu.Divider />

          <Pane
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            marginTop={15}
          >
            <Pane
              display={"flex"}
              flexDirection={"row"}
              marginTop={10}
              justifyContent="center"
            >
              <Strong size={300} color={"#888A8D"} marginRight={8}>
                Active
              </Strong>
              <Switch
                disabled={!disable}
                onChange={(e) => this.handleChange(e)}
                name={"isActive"}
                checked={isActive}
              />
            </Pane>
            <Button
              onClick={() => this.onConfirm()}
              appearance="primary"
              intent="success"
            >
              Confirm
            </Button>
          </Pane>
        </Dialog>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({
  authStatus,
  loginDetails,
  userRemove,
  configExtrasList,
  clubActivityData,
  configExtrasToast,
}) => ({
  authStatus,
  loginDetails,
  userRemove,
  configExtrasList,
  clubActivityData,
  configExtrasToast,
});

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default connect(mapStateToProps)(ExtraConfig);
