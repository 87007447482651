// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Collapse from "@material-ui/core/Collapse";

import {
  Text,
  Icon,
  Heading,
  Avatar,
  IconButton,
  Button,
  Menu,
  Popover,
  Position,
  Badge,
  Link,
  CaretDownIcon,
  CaretRightIcon,
  CogIcon,
  RefreshIcon,
  ShieldIcon,
  HeadsetIcon,
  PowerIcon,
  HomeIcon,
} from "evergreen-ui";
import PlayoLogo from "../../assets/images/playo-logo.svg";
import { mailToPlayo } from "../../utils";
import { syncAvailability } from "../../actions";
import { Power } from "@material-ui/icons";

// -----------------------------------------------------------------------
// Styled Components
// -----------------------------------------------------------------------
const SideBarDiv = styled.div`
  min-width: 240px;
  width: 240px;
  min-height: 100vh;
  height: 100vh;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  border-right: 1px solid #e4e7eb;
  background-color: #084b8a;
  overflow: hidden;

  #slogan-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
    font-size: 18px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: -0.1px;
    color: #f6f9fd;
  }

  #list-item-text {
    font-size: 12px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #fafafa;
    text-transform: uppercase;
  }

  #nested-list-label {
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: 0px;
    color: #f6f9fd;
  }

  #club-name {
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: -0.1px;
    color: #f6f9fd;
  }

  .user-name-avatar {
    background-color: #dbeaf7;
    span {
      font-size: 16px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.13;
      text-align: center;
      color: #084b8a;
    }
  }

  .user-details-container {
    width: 135px;
    max-width: 135px;
    margin-right: 8px;
  }

  #user-name-label {
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: -0.1px;
    color: #f6f9fd;
    text-transform: capitalize;
  }

  #user-type-label {
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: 0px;
    color: rgba(246, 249, 253, 0.53);
  }

  .sidebar-icon-btn svg {
    fill: #ffffff !important;
  }

  .sidebar-action-btn {
    font-size: 10px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    :hover {
      color: black;
    }
  }
`;

const styles = (theme) => ({
  root: {
    width: "100%",
    paddingTop: "1px",
    paddingBottom: "0",
  },
  LogoSloganContainer: {
    width: "100%",
    height: "80px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "32px 20px 0px 16px",
    borderBottom: "1px solid rgb(228,231,235, 0.15);",
  },
  SidebarBottomPanel: {
    width: "100%",
  },
  clubNameContainer: {
    width: "100%",
    paddingLeft: "16px",
    paddingRight: "16px",
    textAlign: "left",
    paddingBottom: "16px",
    borderBottom: "1px solid rgb(228,231,235, 0.15);",
  },
  userNameAndActionsContainer: {
    width: "100%",
    padding: "24px 16px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexGrow: "0",
    flexShrink: "0",
  },
  actionButtonContainer: {
    width: "100%",
    padding: "0 16px 16px 16px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    flexGrow: "0",
    flexShrink: "0",
  },
  oldConsoleAccess: {
    marginTop: "15px",
    marginBottom: "5px",
    padding: 0,
    color: "white",
  },
});

// -----------------------------------------------------------------------
// NestedList Component
// -----------------------------------------------------------------------
class NestedList extends React.Component {
  state = {
    open: 1,
  };

  // -----------------------------------------------------------------------
  // handleClick()
  // -----------------------------------------------------------------------
  handleClick = (no) => {
    const { open } = this.state;
    const nextOpen = no === open ? 0 : no;
    this.setState({ open: nextOpen });
  };

  // -----------------------------------------------------------------------
  // render()
  // -----------------------------------------------------------------------
  render() {
    const {
      classes,
      selectedListItem,
      handleSidebar,
      handleLogout,
      playoUserName,
      playoClubName,
      handleAddDeskPersonDialogVisibility,
      handleChangePasswordDialogVisibility,
      handleSwitchAccountDialogVisibility,
      handleContactSupportDialogVisibility,
      userClubsCount,
    } = this.props;
    return (
      <SideBarDiv>
        <div className="sidebar-top-panel">
          <div className={classes.LogoSloganContainer}>
            <img src={PlayoLogo} alt="playo-logo" />
            <Heading id="slogan-text" marginTop={4}>
              Partner Console
              {/* <Badge color="neutral" isSolid marginLeft={8}>
                beta
              </Badge> */}
            </Heading>
            {/* <div className={classes.oldConsoleAccess}>
              <Text padding="0" color="white" size="300">
                Access the old console{" "}
                <Link
                  color="white"
                  // href={
                  //   process.env.REACT_APP_OLD_CONSOLE_LINK ||
                  //   "https://playo.club/?redirect=0"
                  // }
                >
                  here
                </Link>
              </Text>
            </div> */}
          </div>
          <List component="nav" className={classes.root}>
            {!this.props.hideDashBoard && (
              <ListItem
                button
                onClick={(e) => handleSidebar(e, "dashboard")}
                selected={selectedListItem === "dashboard"}
                className={
                  selectedListItem === "dashboard"
                    ? "nested-list-item-selected"
                    : "nested-list-item"
                }
              >
                <Text id="list-item-text">Home</Text>
              </ListItem>
            )}
            <ListItem
              disableRipple={true}
              className="list-item"
              button
              onClick={(e) => this.handleClick(1)}
              selected={this.state.open === 1}
            >
              {this.state.open === 1 ? (
                <CaretDownIcon
                  marginLeft={26}
                  cursor={"pointer"}
                  size={12}
                  marginRight={10}
                  color="#fafafa"
                />
              ) : (
                <CaretRightIcon
                  marginLeft={26}
                  cursor={"pointer"}
                  size={12}
                  marginRight={10}
                  color="#fafafa"
                />
              )}
              <Text id="list-item-text">Schedule</Text>
            </ListItem>
            <Collapse in={this.state.open === 1} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem
                  button
                  className={
                    selectedListItem === "calendar"
                      ? "nested-list-item-selected"
                      : "nested-list-item"
                  }
                  onClick={(e) => handleSidebar(e, "calendar")}
                  selected={selectedListItem === "calendar"}
                >
                  <Text id="nested-list-label">Calendar</Text>
                </ListItem>
                <ListItem
                  button
                  className={
                    selectedListItem === "bulk-booking"
                      ? "nested-list-item-selected"
                      : "nested-list-item"
                  }
                  onClick={(e) => handleSidebar(e, "bulk-booking")}
                  selected={selectedListItem === "bulk-booking"}
                >
                  <Text id="nested-list-label">Bulk Booking</Text>
                </ListItem>
                {/*<ListItem*/}
                {/*button*/}
                {/*className={*/}
                {/*selectedListItem === "block-slots"*/}
                {/*? "nested-list-item-selected"*/}
                {/*: "nested-list-item"*/}
                {/*}*/}
                {/*onClick={e => handleSidebar(e, "block-slots")}*/}
                {/*selected={selectedListItem === "block-slots"}*/}
                {/*>*/}
                {/*<Text id="nested-list-label">Block Slots</Text>*/}
                {/*</ListItem>*/}
                <ListItem
                  button
                  className={
                    selectedListItem === "search"
                      ? "nested-list-item-selected"
                      : "nested-list-item"
                  }
                  onClick={(e) => handleSidebar(e, "search")}
                  selected={selectedListItem === "search"}
                >
                  <Text id="nested-list-label">Search</Text>
                </ListItem>
              </List>
            </Collapse>
            <ListItem
              disableRipple={true}
              className="list-item"
              button
              onClick={(e) => this.handleClick(2)}
              selected={this.state.open === 2}
            >
              {this.state.open === 2 ? (
                <CaretDownIcon
                  marginLeft={26}
                  cursor={"pointer"}
                  size={12}
                  marginRight={10}
                  color="#fafafa"
                />
              ) : (
                <CaretRightIcon
                  marginLeft={26}
                  cursor={"pointer"}
                  size={12}
                  marginRight={10}
                  color="#fafafa"
                />
              )}
              <Text id="list-item-text">CRM</Text>
            </ListItem>
            <Collapse in={this.state.open === 2} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem
                  button
                  className={
                    selectedListItem === "members"
                      ? "nested-list-item-selected"
                      : "nested-list-item"
                  }
                  onClick={(e) => handleSidebar(e, "members")}
                  selected={selectedListItem === "members"}
                >
                  <Text id="nested-list-label">Members</Text>
                </ListItem>
                <ListItem
                  button
                  className={
                    selectedListItem === "membership-packages"
                      ? "nested-list-item-selected"
                      : "nested-list-item"
                  }
                  onClick={(e) => handleSidebar(e, "membership-packages")}
                  selected={selectedListItem === "membership-packages"}
                >
                  <Text id="nested-list-label">Membership Packages</Text>
                </ListItem>
              </List>
            </Collapse>
            <ListItem
              disableRipple={true}
              className="list-item"
              button
              onClick={(e) => this.handleClick(3)}
              selected={this.state.open === 3}
            >
              {this.state.open === 3 ? (
                <CaretDownIcon
                  marginLeft={26}
                  cursor={"pointer"}
                  size={12}
                  marginRight={10}
                  color="#fafafa"
                />
              ) : (
                <CaretRightIcon
                  marginLeft={26}
                  cursor={"pointer"}
                  size={12}
                  marginRight={10}
                  color="#fafafa"
                />
              )}
              <Text id="list-item-text">Promote</Text>
            </ListItem>
            <Collapse in={this.state.open === 3} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem
                  button
                  className={
                    selectedListItem === "generate-coupons"
                      ? "nested-list-item-selected"
                      : "nested-list-item"
                  }
                  onClick={(e) => handleSidebar(e, "generate-coupons")}
                  selected={selectedListItem === "generate-coupons"}
                >
                  <Text id="nested-list-label">Coupons</Text>
                </ListItem>
              </List>
            </Collapse>
            <ListItem
              disableRipple={true}
              className="list-item"
              button
              onClick={(e) => this.handleClick(4)}
              selected={this.state.open === 4}
            >
              {this.state.open === 4 ? (
                <CaretDownIcon
                  marginLeft={26}
                  cursor={"pointer"}
                  size={12}
                  marginRight={10}
                  color="#fafafa"
                />
              ) : (
                <CaretRightIcon
                  marginLeft={26}
                  cursor={"pointer"}
                  size={12}
                  marginRight={10}
                  color="#fafafa"
                />
              )}
              <Text id="list-item-text">Reports</Text>
            </ListItem>
            <Collapse in={this.state.open === 4} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem
                  button
                  className={
                    selectedListItem === "reports"
                      ? "nested-list-item-selected"
                      : "nested-list-item"
                  }
                  onClick={(e) => handleSidebar(e, "reports")}
                  selected={selectedListItem === "reports"}
                >
                  <Text id="nested-list-label">Generate Reports</Text>
                </ListItem>
                <ListItem
                  button
                  className={
                    selectedListItem === "analytics"
                      ? "nested-list-item-selected"
                      : "nested-list-item"
                  }
                  onClick={(e) => handleSidebar(e, "analytics")}
                  selected={selectedListItem === "analytics"}
                >
                  <Text id="nested-list-label">Utilisation</Text>
                </ListItem>
              </List>
            </Collapse>
            <ListItem
              disableRipple={true}
              className="list-item"
              button
              onClick={(e) => this.handleClick(5)}
              selected={this.state.open === 5}
            >
              {this.state.open === 5 ? (
                <CaretDownIcon
                  marginLeft={26}
                  cursor={"pointer"}
                  size={12}
                  marginRight={10}
                  color="#fafafa"
                />
              ) : (
                <CaretRightIcon
                  cursor={"pointer"}
                  size={12}
                  marginRight={10}
                  marginLeft={26}
                  color="#fafafa"
                />
              )}
              <Text id="list-item-text">Config</Text>
            </ListItem>
            <Collapse in={this.state.open === 5} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem
                  button
                  className={
                    selectedListItem === "pricing"
                      ? "nested-list-item-selected"
                      : "nested-list-item"
                  }
                  onClick={(e) => handleSidebar(e, "pricing")}
                  selected={selectedListItem === "pricing"}
                >
                  <Text id="nested-list-label">Pricing</Text>
                </ListItem>
                <ListItem
                  button
                  className={
                    selectedListItem === "staff-console"
                      ? "nested-list-item-selected"
                      : "nested-list-item"
                  }
                  onClick={(e) => handleSidebar(e, "staff-console")}
                  selected={selectedListItem === "staff-console"}
                >
                  <Text id="nested-list-label">Staff Management</Text>
                </ListItem>
                <ListItem
                  button
                  className={
                    selectedListItem === "event-dashboard"
                      ? "nested-list-item-selected"
                      : "nested-list-item"
                  }
                  onClick={(e) => handleSidebar(e, "event-dashboard")}
                  selected={selectedListItem === "event-dashboard"}
                >
                  <Text id="nested-list-label">Events</Text>
                </ListItem>
                <ListItem
                  button
                  className={
                    selectedListItem === "addon-config"
                      ? "nested-list-item-selected"
                      : "nested-list-item"
                  }
                  onClick={(e) => handleSidebar(e, "addon-config")}
                  selected={selectedListItem === "addon-config"}
                >
                  <Text id="nested-list-label">Addons</Text>
                </ListItem>
              </List>
            </Collapse>
          </List>
        </div>
        <div className={classes.SidebarBottomPanel}>
          <div className={classes.clubNameContainer}>
            <Text id="club-name">{playoClubName}</Text>
          </div>
          <div className={classes.userNameAndActionsContainer}>
            <Avatar
              className="user-name-avatar"
              name={playoUserName ? playoUserName : "-"}
              size={40}
              marginRight={8}
            />
            <div className="user-details-container display-flex col-flex">
              <Text id="user-name-label">
                {playoUserName ? playoUserName : "-"}
              </Text>
              {/* <Text id="user-type-label">Admin</Text> */}
            </div>
            <Popover
              content={
                <Menu>
                  <Menu.Group>
                    <Menu.Item
                      marginBottom={4}
                      onSelect={() =>
                        handleAddDeskPersonDialogVisibility("open")
                      }
                    >
                      Add Desk Person
                    </Menu.Item>
                    <Menu.Item
                      marginBottom={4}
                      onSelect={() =>
                        handleChangePasswordDialogVisibility("open")
                      }
                    >
                      Change Password
                    </Menu.Item>
                    {userClubsCount > 1 && (
                      <Menu.Item
                        marginBottom={4}
                        onSelect={() =>
                          handleSwitchAccountDialogVisibility("open")
                        }
                      >
                        Switch Club
                      </Menu.Item>
                    )}
                  </Menu.Group>
                </Menu>
              }
              position={Position.BOTTOM_LEFT}
            >
              <IconButton
                className="sidebar-icon-btn"
                icon={<CogIcon />}
                appearance="minimal"
                iconSize={16}
              />
            </Popover>
          </div>
          <div className={classes.actionButtonContainer}>
            <Button
              className="sidebar-action-btn"
              height={32}
              marginBottom={8}
              appearance="minimal"
              iconBefore={<RefreshIcon />}
              onClick={() => syncAvailability()}
            >
              Sync Availability
            </Button>
            <Button
              className="sidebar-action-btn"
              height={32}
              marginBottom={8}
              appearance="minimal"
              iconBefore={<ShieldIcon />}
              onClick={() => mailToPlayo()}
            >
              Report a Bug
            </Button>
            <Button
              className="sidebar-action-btn"
              height={32}
              marginBottom={8}
              appearance="minimal"
              iconBefore={<HeadsetIcon />}
              onClick={(e) => handleContactSupportDialogVisibility("open")}
            >
              Contact Support
            </Button>
            <Button
              className="sidebar-action-btn"
              height={32}
              marginBottom={8}
              appearance="minimal"
              iconBefore={<PowerIcon />}
              onClick={(e) => handleLogout(e)}
            >
              Log Out
            </Button>
          </div>
        </div>
      </SideBarDiv>
    );
  }
}

NestedList.propTypes = {
  classes: PropTypes.object.isRequired,
};

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default withStyles(styles)(NestedList);
