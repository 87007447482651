// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import { Button } from "evergreen-ui";

// -----------------------------------------------------------------------
// BookingActions Component
// -----------------------------------------------------------------------
class BookingActions extends React.Component {
  // -----------------------------------------------------------------------
  // render()
  // -----------------------------------------------------------------------
  render() {
    const {
      handleConfirmBooking,
      handleDismissAction,
      isConfirmBookingBtnDisabled
    } = this.props;
    return (
      <div className="BA-A1-wrapper">
        <Button
          className="BA-A1-button"
          width={152}
          height={34}
          onClick={e => handleDismissAction(e)}
        >
          Dismiss
        </Button>
        <Button
          className="BA-A1-button"
          width={152}
          height={34}
          appearance="primary"
          intent="success"
          onClick={e => handleConfirmBooking(e)}
          disabled={isConfirmBookingBtnDisabled}
        >
          Confirm Booking
        </Button>
      </div>
    );
  }
}

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default BookingActions;
