// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import { connect } from "react-redux";
import Navbar from "../../components/Navbar";
import PageHeadingAndActionsNav from "../../components/PageHeadingAndActionsNav";
import {
  Text,
  TextInputField,
  Paragraph,
  Button,
  toaster,
  Dialog,
  Select,
  Icon,
  IconButton,
  TextInput,
  InlineAlert,
  Checkbox,
  TickCircleIcon,
  CrossIcon,
} from "evergreen-ui";
import CustomizedDatePicker from "../../components/CustomizedDatePicker";
import moment from "moment";
import {
  checkIntOrFloat,
  validateCreditsAndPrice,
  toDateFormat1,
  toDateFormat32,
  resourcesNotLoadedToast,
  stringifyIt,
  generateCurrencySymbol,
} from "../../utils";
import {
  clearSelectedMembershipPackageDetails,
  subscribeMembershipPackage,
  getCustomerDetails,
  getMemberDetails,
  getIndividualMemberDetails,
  togglePremiumFeatureFlag,
} from "../../actions";
import TransactionModeSelect from "../../components/TransactionModeSelect";
import validator from "validator";

// -----------------------------------------------------------------------
// MembershipCheckoutPage Component
// -----------------------------------------------------------------------
class MembershipCheckoutPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clubId: 1,
      memberMobile: "",
      memberName: "",
      memberEmail: "",
      memberId: "",
      isMobileInvalid: false,
      showInvalidMobileDiv: false,
      mobileIsRequiredMsg: false,
      date: moment(
        this.props.selectedMembershipPackageDetails.packageDetails.startDate
      ).format("YYYY-MM-DD"),
      showConfirmDismissDialog: false,
      isConfirmPackageButtonDisabled: false,
      showDiscountAppliedDiv: false,
      showDiscountActionDiv: true,
      discountAmount: "",
      discountApplied: 0,
      advanceAmount: "",
      isDiscountInvalid: false,
      showInvalidDiscountDiv: false,
      showDiscountExceedsBalanceDiv: false,
      isAdvanceInvalid: false,
      showInvalidAdvanceDiv: false,
      sendConfirmationSmschecked: false,
      paymentMode: "No Pay",
      transactionMode: 0,
      selectedTransactionMode: 0,
      disableNoPay: false,
      showMemberDoesNotExistDialog: false,
      isNonMember: false,
      period: this.props.selectedMembershipPackageDetails.packageDetails.period,
      price:
        this.props.selectedMembershipPackageDetails.packageDetails.packagePrice,
    };
  }

  calculateDateDifference = (date1, date2) => {
    const momentDate1 = moment(date1);
    const momentDate2 = moment(date2);

    const daysDifference = momentDate2.diff(momentDate1, "days");

    return daysDifference;
  };

  // -----------------------------------------------------------------------
  // handleDateChange()
  // -----------------------------------------------------------------------
  handleDateChange = async (date) => {
    let type = this.props.selectedMembershipPackageDetails.packageDetails.type;
    if (moment(date).isBefore(new Date())) {
      toaster.warning("Date can't be set to a past date", {
        id: "MPCP-TOAST-1",
      });
    } else if (
      type !== "Rotational" &&
      moment(date).isAfter(
        this.props.selectedMembershipPackageDetails.packageDetails.endDate
      )
    ) {
      toaster.warning("Date can't be set to a after the package period date", {
        id: "MPCP-TOAST-1",
      });
    } else {
      console.log("date", date);

      const startDate = moment(date).format("YYYY-MM-DD");
      let rotationalDate = moment(startDate).add(this.state.period, "days");
      const endDate = moment(
        this.props.selectedMembershipPackageDetails.packageDetails.endDate
      ).format("YYYY-MM-DD");
      await this.setState({
        period:
          type === "Rotational"
            ? this.calculateDateDifference(startDate, rotationalDate)
            : this.calculateDateDifference(startDate, endDate),
      });
      await this.setState({
        date: startDate,
        price:
          type !== "Rotational"
            ? this.state.oneDayPrice *
              this.calculateDateDifference(startDate, endDate)
            : this.state.price,
      });
    }
  };

  // -----------------------------------------------------------------------
  // resetForm()
  // -----------------------------------------------------------------------
  resetForm = async () => {
    await this.setState({
      clubId: 1,
      memberMobile: "",
      memberName: "",
      memberEmail: "",
      memberId: "",
      isMobileInvalid: false,
      showInvalidMobileDiv: false,
      mobileIsRequiredMsg: false,
      date: new Date(),
      isConfirmPackageButtonDisabled: false,
      showDiscountAppliedDiv: false,
      showDiscountActionDiv: true,
      discountAmount: "",
      discountApplied: 0,
      advanceAmount: "",
      isDiscountInvalid: false,
      showInvalidDiscountDiv: false,
      showDiscountExceedsBalanceDiv: false,
      isAdvanceInvalid: false,
      showInvalidAdvanceDiv: false,
      sendConfirmationSmschecked: false,
      paymentMode: "No Pay",
      transactionMode: 0,
      selectedTransactionMode: 0,
      disableNoPay: false,
      showMemberDoesNotExistDialog: false,
    });
    await this.props.dispatch(clearSelectedMembershipPackageDetails());
  };

  // -----------------------------------------------------------------------
  // handleConfirmDismissDialog()
  // -----------------------------------------------------------------------
  handleConfirmDismissDialog = async (type) => {
    if (type === "show") {
      this.setState({ showConfirmDismissDialog: true });
    } else if (type === "hide") {
      this.setState({ showConfirmDismissDialog: false });
    } else if (type === "confirm") {
      await this.resetForm();
      await this.setState({ showConfirmDismissDialog: false });
      await this.props.handleMembershipPackagesCheckoutPageDisplay("hide");
    }
  };

  // -----------------------------------------------------------------------
  // handleMemberDoesNotExistDialog()
  // -----------------------------------------------------------------------
  handleMemberDoesNotExistDialog = async (type) => {
    if (type === "show") {
      this.setState({ showMemberDoesNotExistDialog: true });
    } else if (type === "hide" || type === "confirm") {
      this.setState({ showMemberDoesNotExistDialog: false });
      this.props.handleMembershipPackagesCheckoutPageDisplay("hide");
      await this.resetForm();
    }
  };

  // -----------------------------------------------------------------------
  // confirmAddPackage()
  // -----------------------------------------------------------------------
  confirmAddPackage = async (e) => {
    e.preventDefault();
  };

  // -----------------------------------------------------------------------
  // changeDiscountAmount()
  // -----------------------------------------------------------------------
  changeDiscountAmount = async (e) => {
    e.persist();
    if (validateCreditsAndPrice(e.target.value) === "set") {
      await this.setState({
        discountAmount: e.target.value,
      });
    } else if (validateCreditsAndPrice(e.target.value) === "clear") {
      await this.setState({
        discountAmount: "",
        showInvalidDiscountDiv: false,
        isDiscountInvalid: false,
      });
    }
  };

  // -----------------------------------------------------------------------
  // applyDiscount()
  // -----------------------------------------------------------------------
  applyDiscount = async () => {
    if (
      this.state.discountAmount <= this.state.price &&
      this.state.discountAmount >
        this.state.price - this.state.discountApplied - this.state.advanceAmount
    ) {
      await this.setState({
        showInvalidDiscountDiv: false,
        showDiscountExceedsBalanceDiv: true,
      });
    } else {
      if (Number(this.state.discountAmount) > 0) {
        if (Number(this.state.discountAmount) <= this.state.price) {
          this.setState({
            showInvalidDiscountDiv: false,
            isDiscountInvalid: false,
            showDiscountExceedsBalanceDiv: false,
          });
          if (Number(this.state.discountAmount) > 0) {
            await this.setState({
              discountApplied: Number(this.state.discountAmount),
              showDiscountAppliedDiv: true,
              showDiscountActionDiv: false,
              showInvalidDiscountDiv: false,
              isDiscountInvalid: false,
            });
            if (
              this.props.selectedMembershipPackageDetails.packageDetails
                .packagePrice -
                this.state.discountApplied -
                this.state.advanceAmount <
              0
            ) {
              this.setState({
                showInvalidAdvanceDiv: true,
                isAdvanceInvalid: true,
                isConfirmPackageButtonDisabled: true,
              });
            } else {
              this.setState({
                showInvalidAdvanceDiv: false,
                isAdvanceInvalid: false,
                isConfirmPackageButtonDisabled: false,
              });
            }
          }
        } else {
          await this.setState({
            showDiscountAppliedDiv: false,
            showDiscountActionDiv: true,
            showInvalidDiscountDiv: true,
            showDiscountExceedsBalanceDiv: false,
            isDiscountInvalid: true,
          });
        }
      }
    }
  };

  // -----------------------------------------------------------------------
  // resetDiscount()
  // -----------------------------------------------------------------------
  resetDiscount = () => {
    this.setState({
      discountAmount: "",
      discountApplied: 0,
      showDiscountAppliedDiv: false,
      showDiscountActionDiv: true,
    });
  };

  // -----------------------------------------------------------------------
  // changeTransactionMode()
  // -----------------------------------------------------------------------
  changeTransactionMode = (e) => {
    e.persist();
    if (this.state.advanceAmount.length > 0) {
      this.setState({
        paymentMode:
          e.nativeEvent.target[
            e.nativeEvent.target.selectedIndex
          ].text.toString(),
        transactionMode: parseInt(e.target.value),
        selectedTransactionMode: parseInt(e.target.value),
        isAdvanceInvalid: false,
      });
    } else {
      this.setState({ isAdvanceInvalid: true });
    }
  };

  // -----------------------------------------------------------------------
  // handleAdvanceInput()
  // -----------------------------------------------------------------------
  handleAdvanceInput = async (e) => {
    e.persist();
    if (validateCreditsAndPrice(e.target.value) === "set") {
      if (
        e.target.value <=
        this.props.selectedMembershipPackageDetails.packageDetails
          .packagePrice -
          this.state.discountApplied
      ) {
        await this.setState({
          advanceAmount: e.target.value,
          showInvalidAdvanceDiv: false,
          isAdvanceInvalid: false,
          isConfirmPackageButtonDisabled: false,
        });
      } else {
        await this.setState({
          advanceAmount: e.target.value,
          showInvalidAdvanceDiv: true,
          isAdvanceInvalid: true,
          isConfirmPackageButtonDisabled: true,
        });
      }
      if (this.state.selectedTransactionMode === 0) {
        await this.setState({
          paymentMode: "Cash",
          transactionMode: 1,
          selectedTransactionMode: 1,
          disableNoPay: true,
        });
      }
    } else if (validateCreditsAndPrice(e.target.value) === "clear") {
      await this.setState({
        advanceAmount: "",
        showInvalidAdvanceDiv: false,
        isAdvanceInvalid: false,
        isConfirmPackageButtonDisabled: false,
        paymentMode: "No Pay",
        transactionMode: 0,
        selectedTransactionMode: 0,
        disableNoPay: false,
      });
    }
  };

  componentDidMount() {
    console.log(this.state.date);
    let price =
      this.props.selectedMembershipPackageDetails.packageDetails.packagePrice;
    let numberOfDaysInPackage =
      this.props.selectedMembershipPackageDetails.packageDetails.period;

    let oneDayPrice = price / numberOfDaysInPackage;
    this.setState({
      oneDayPrice: oneDayPrice,
    });
    this.props.handleSidebar("", "membership-packages");
  }

  // -----------------------------------------------------------------------
  // handleSendConfirmationSmsCheckbox()
  // -----------------------------------------------------------------------
  handleSendConfirmationSmsCheckbox = async (e) => {
    e.persist();
    await this.setState({
      sendConfirmationSmschecked: e.target.checked,
    });
  };

  // -----------------------------------------------------------------------
  // confirmSubscribePackage()
  // -----------------------------------------------------------------------
  confirmSubscribePackage = async (e) => {
    e.persist();
    if (
      this.props.selectedMembershipPackageDetails &&
      this.props.selectedMembershipPackageDetails.memberDetails
    ) {
      await this.proceedToSubscribePackage();
    } else {
      if (this.state.memberMobile !== "" && this.state.memberName !== "") {
        await this.proceedToSubscribePackage();
      } else {
        await this.setState({
          isMobileInvalid: true,
          mobileIsRequiredMsg: true,
        });
        await this.scrollToElement("mobileIsRequiredMsg");
        // toaster.warning("Member details required", {
        //   id: "MPCP-TOAST-2"
        // });
      }
    }
  };

  // -----------------------------------------------------------------------
  // getUpdatedMembersDetails()
  // -----------------------------------------------------------------------
  getUpdatedMembersDetails = async () => {
    let reqParams = {
      page: this.props.selectedMembershipPackageDetails.pageNumOnMembersPage,
    };
    await this.props.dispatch(getMemberDetails(reqParams));
  };

  // -----------------------------------------------------------------------
  // getUpdatedIndividualMemberDetails()
  // -----------------------------------------------------------------------
  getUpdatedIndividualMemberDetails = async () => {
    let reqParams = {
      page: 1,
      mobile:
        this.props.selectedMembershipPackageDetails.memberDetails.memberMobile,
    };
    await this.props.dispatch(getIndividualMemberDetails(reqParams));
  };

  // -----------------------------------------------------------------------
  // afterSubscribeSuccess()
  // -----------------------------------------------------------------------
  afterSubscribeSuccess = async () => {
    await this.resetForm();
    await this.props.handleMembershipPackagesCheckoutPageDisplay("hide");
    toaster.success(this.props.subscribeMembershipPackageToast.message, {
      id: "MPCP-TOAST-3",
    });
    await this.props.handleViewMembershipPackagesPageDisplay("show");
  };

  // -----------------------------------------------------------------------
  // proceedToSubscribePackage()
  // -----------------------------------------------------------------------
  proceedToSubscribePackage = async () => {
    let reqBody = {
      packageData: {
        packageId:
          this.props.selectedMembershipPackageDetails.packageDetails.packageId,
        type: this.props.selectedMembershipPackageDetails.packageDetails.type,
        startDate: toDateFormat1(
          this.props.selectedMembershipPackageDetails.packageDetails.startDate
        ),
        endDate: toDateFormat1(
          this.props.selectedMembershipPackageDetails.packageDetails.endDate
        ),
        grossPrice:
          this.props.selectedMembershipPackageDetails.packageDetails
            .packagePrice,
        period:
          this.props.selectedMembershipPackageDetails.packageDetails.period,
        slotId:
          this.props.selectedMembershipPackageDetails.packageDetails.slotId,
      },
      ticketCount:
        this.props.selectedMembershipPackageDetails.subscriptionType ===
        "add-package"
          ? 1
          : 0,
      memberId: this.props.selectedMembershipPackageDetails.memberDetails
        ? this.props.selectedMembershipPackageDetails.memberDetails.memberId
        : this.state.memberId,
      advance:
        this.state.advanceAmount === ""
          ? 0
          : checkIntOrFloat(this.state.advanceAmount),
      discount: this.state.discountApplied,
      startDate: toDateFormat1(this.state.date),
      transactionData: {
        type: 1,
        mode: this.state.transactionMode,
      },
    };
    await this.props.dispatch(subscribeMembershipPackage(reqBody));
    if (this.props.subscribeMembershipPackageToast) {
      if (
        this.props.subscribeMembershipPackageToast.resourcesNotLoadedMessage
      ) {
        resourcesNotLoadedToast();
      } else if (this.props.subscribeMembershipPackageToast.httpErrorMessage) {
        toaster.danger(
          this.props.subscribeMembershipPackageToast.httpErrorMessage,
          {
            id: "HTTP-ERROR-TOAST-4",
          }
        );
      } else if (
        this.props.subscribeMembershipPackageToast.requestStatus === -1
      ) {
        toaster.notify("Sorry, you don't have permission to do that");
      } else if (
        this.props.subscribeMembershipPackageToast.requestStatus === 0
      ) {
        toaster.danger(
          stringifyIt(this.props.subscribeMembershipPackageToast.message),
          {
            id: "HP-TOAST",
          }
        );
      } else if (
        this.props.subscribeMembershipPackageToast.requestStatus === 1
      ) {
        await this.getUpdatedMembersDetails();
        if (
          this.props.selectedMembershipPackageDetails
            .updateIndividualMemberDetails
        ) {
          await this.getUpdatedIndividualMemberDetails();
          if (this.props.individualMemberDetailsToast) {
            if (
              this.props.individualMemberDetailsToast.resourcesNotLoadedMessage
            ) {
              resourcesNotLoadedToast();
            } else if (
              this.props.individualMemberDetailsToast.httpErrorMessage
            ) {
              toaster.danger(
                this.props.individualMemberDetailsToast.httpErrorMessage,
                {
                  id: "HTTP-ERROR-TOAST-4",
                }
              );
            } else if (
              this.props.individualMemberDetailsToast.requestStatus === -1
            ) {
              toaster.notify("Sorry, you don't have permission to do that");
            } else if (
              this.props.individualMemberDetailsToast.requestStatus === 0
            ) {
              toaster.warning(this.props.individualMemberDetailsToast.message, {
                id: "HP-TOAST",
              });
            }
          }
        }
        if (this.props.memberDetails) {
          if (this.props.memberDetails.resourcesNotLoadedMessage) {
            resourcesNotLoadedToast();
          } else if (this.props.memberDetails.httpErrorMessage) {
            toaster.danger(this.props.memberDetails.httpErrorMessage, {
              id: "HTTP-ERROR-TOAST-4",
            });
          } else if (this.props.memberDetails.requestStatus === -1) {
            toaster.notify("Sorry, you don't have permission to do that");
          } else if (this.props.memberDetails.requestStatus === 0) {
            toaster.warning(this.props.memberDetails.message, {
              id: "HP-TOAST",
            });
          } else if (this.props.memberDetails.requestStatus === 1) {
            await this.afterSubscribeSuccess();
          }
        }
      }
    }
  };

  // -----------------------------------------------------------------------
  // onChangeOfMobileInputField()
  // -----------------------------------------------------------------------
  onChangeOfMobileInputField = (e) => {
    this.setState({ memberMobile: e.target.value.toString() });
    if (e.target.value.length <= 0) {
      this.setState({
        showInvalidMobileDiv: false,
        isMobileInvalid: true,
        mobileIsRequiredMsg: true,
      });
    } else {
      this.setState({
        showInvalidMobileDiv: false,
        isMobileInvalid: false,
        mobileIsRequiredMsg: false,
      });
    }
  };

  // -----------------------------------------------------------------------
  // scrollToElement()
  // -----------------------------------------------------------------------
  scrollToElement = async (elem) => {
    await this.refs[elem].scrollIntoView({ block: "end", behavior: "smooth" });
  };

  // -----------------------------------------------------------------------
  // onBlurOfMobileInputField()
  // -----------------------------------------------------------------------
  onBlurOfMobileInputField = async (e) => {
    e.persist();
    if (this.state.memberMobile.length > 0) {
      if (validator.isMobilePhone(this.state.memberMobile.toString())) {
        await this.setState({
          showInvalidMobileDiv: false,
          isMobileInvalid: false,
          mobileIsRequiredMsg: false,
        });
        let reqBody = {
          clubId: this.state.clubId,
          mobile: this.state.memberMobile,
        };
        await this.props.dispatch(getCustomerDetails(reqBody, true));
        if (this.props.customerDetails) {
          if (this.props.customerDetails.resourcesNotLoadedMessage) {
            resourcesNotLoadedToast();
          } else if (this.props.customerDetails.httpErrorMessage) {
            toaster.danger(this.props.customerDetails.httpErrorMessage, {
              id: "HTTP-ERROR-TOAST-4",
            });
          } else if (this.props.customerDetails.requestStatus === -1) {
            toaster.notify("Sorry, you don't have permission to do that");
          } else if (
            this.props.customerDetails &&
            !this.props.customerDetails.toBeRegistered &&
            this.props.customerDetails.customerDetails
          ) {
            if (!this.props.customerDetails.isMember) {
              this.setState({
                isNonMember: true,
              });
              // this.props.handleAddnewMemberOnMemberShipCheckout();
            } else {
              await this.setState({
                memberName:
                  this.props.customerDetails.customerDetails.customerName,
                memberEmail:
                  this.props.customerDetails.customerDetails.customerEmail,
                memberId: this.props.customerDetails.customerDetails.id,
              });
            }
          } else if (
            this.props.customerDetails &&
            this.props.customerDetails.toBeRegistered &&
            !this.props.customerDetails.customerDetails
          ) {
            await this.setState({ memberName: "", memberEmail: "" });
            await this.handleMemberDoesNotExistDialog("show");
          }
        }
      } else {
        await this.setState({
          showInvalidMobileDiv: true,
          isMobileInvalid: true,
        });
      }
    }
  };

  // -----------------------------------------------------------------------
  // render()
  // -----------------------------------------------------------------------
  render() {
    const {
      memberMobile,
      memberName,
      memberEmail,
      date,
      showConfirmDismissDialog,
      isConfirmPackageButtonDisabled,
      showDiscountAppliedDiv,
      showDiscountActionDiv,
      discountApplied,
      discountAmount,
      isDiscountInvalid,
      showDiscountExceedsBalanceDiv,
      showInvalidDiscountDiv,
      advanceAmount,
      isAdvanceInvalid,
      showInvalidAdvanceDiv,
      sendConfirmationSmschecked,
      isMobileInvalid,
      showInvalidMobileDiv,
      mobileIsRequiredMsg,
      showMemberDoesNotExistDialog,
      selectedTransactionMode,
      disableNoPay,
    } = this.state;
    const { selectedMembershipPackageDetails } = this.props;

    return (
      <React.Fragment>
        <div className="MPCP-A1-wrapper">
          <div className="MPCP-A1-header-container">
            <Navbar />
            <PageHeadingAndActionsNav
              headingText={"Membership Packages Checkout"}
              handleConfirmDismissDialog={this.handleConfirmDismissDialog}
            />
          </div>
          {selectedMembershipPackageDetails &&
            selectedMembershipPackageDetails.requestStatus === 1 && (
              <div className="MPCP-A1-body-container">
                <div className="MPCP-A1-confirm-package-form-container">
                  <div className="MPCP-A1-membership-packages-checkout-form-left-panel-container">
                    <div className="MPCP-A1-left-panel-col-content MPCP-A1-margin-top-32">
                      <div className="MPCP-A1-label-type-one-container">
                        <Text id="MPCP-A1-label-type-one">Member Details</Text>
                      </div>
                      <div className="MPCP-A1-row-container MPCP-A1-margin-top-16">
                        <div className="MPCP-A1-col-container">
                          <div className="MPCP-A1-text-input-field-container">
                            {selectedMembershipPackageDetails.memberDetails ? (
                              <TextInputField
                                className="MPCP-A1-text-input-field"
                                label="Contact Number"
                                width={336}
                                height={32}
                                value={
                                  selectedMembershipPackageDetails.memberDetails
                                    .memberMobile
                                }
                                disabled
                              />
                            ) : (
                              <React.Fragment>
                                <Dialog
                                  isShown={this.state.isNonMember}
                                  title="Member Not Found"
                                  hasFooter={false}
                                  hasClose={true}
                                  onCloseComplete={() =>
                                    this.setState({ isNonMember: false })
                                  }
                                >
                                  <Text id="MPCP-A1-confirmation-dialog-label">
                                    {`Mobile number ${memberMobile} is either not registered as a member or their membership has expired. Please consider adding the member or renewing their member validity.`}
                                  </Text>
                                </Dialog>
                                <Dialog
                                  width={560}
                                  isShown={showMemberDoesNotExistDialog}
                                  title="Warning"
                                  intent="warning"
                                  confirmLabel="Leave Page"
                                  hasCancel={false}
                                  onConfirm={(e) =>
                                    this.handleMemberDoesNotExistDialog(
                                      "confirm"
                                    )
                                  }
                                  onCloseComplete={(e) =>
                                    this.handleMemberDoesNotExistDialog("hide")
                                  }
                                >
                                  <Text id="MPCP-A1-confirmation-dialog-label">
                                    {`Mobile number ${memberMobile} doesn't match to any
                                    member. Consider adding the member first`}
                                  </Text>
                                </Dialog>
                                <TextInputField
                                  className="MPCP-A1-text-input-field"
                                  label="Contact Number"
                                  width={336}
                                  height={32}
                                  placeholder="Mobile"
                                  value={memberMobile}
                                  onChange={(e) =>
                                    this.onChangeOfMobileInputField(e)
                                  }
                                  onBlur={(e) =>
                                    this.onBlurOfMobileInputField(e)
                                  }
                                  isInvalid={isMobileInvalid}
                                />
                              </React.Fragment>
                            )}
                          </div>
                          {showInvalidMobileDiv && (
                            <div className="MPCP-A1-invalid-input-msg-container">
                              <InlineAlert
                                className="MPCP-A1-inline-alert"
                                intent="danger"
                              >
                                <Text id="MPCP-A1-invalid-input-msg">
                                  Invalid mobile number
                                </Text>
                              </InlineAlert>
                            </div>
                          )}
                          {mobileIsRequiredMsg && (
                            <div
                              className="MPCP-A1-invalid-input-msg-container"
                              ref={"mobileIsRequiredMsg"}
                            >
                              <InlineAlert
                                className="MPCP-A1-inline-alert"
                                intent="danger"
                              >
                                <Text id="MPCP-A1-invalid-input-msg">
                                  Mobile is required
                                </Text>
                              </InlineAlert>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="MPCP-A1-row-container MPCP-A1-margin-top-32">
                        <div className="MPCP-A1-text-input-field-container">
                          {selectedMembershipPackageDetails.memberDetails ? (
                            <TextInputField
                              className="MPCP-A1-text-input-field"
                              label="Name"
                              width={336}
                              height={32}
                              value={
                                selectedMembershipPackageDetails.memberDetails
                                  .memberName
                              }
                              disabled
                            />
                          ) : (
                            <TextInputField
                              className="MPCP-A1-text-input-field"
                              label="Name"
                              width={336}
                              height={32}
                              value={memberName}
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="MPCP-A1-row-container MPCP-A1-margin-top-32">
                        <div className="MPCP-A1-text-input-field-container">
                          {selectedMembershipPackageDetails.memberDetails ? (
                            <TextInputField
                              className="MPCP-A1-text-input-field"
                              label="Email"
                              width={336}
                              height={32}
                              value={
                                selectedMembershipPackageDetails.memberDetails
                                  .memberEmail
                              }
                              disabled
                            />
                          ) : (
                            <TextInputField
                              className="MPCP-A1-text-input-field"
                              label="Email"
                              width={336}
                              height={32}
                              value={memberEmail}
                              disabled
                            />
                          )}
                        </div>
                      </div>
                      <div className="MPCP-A1-horizontal-line-decorator MPCP-A1-margin-top-32" />
                      <div className="MPCP-A1-col-container MPCP-A1-margin-top-32">
                        <div className="MPCP-A1-input-field-label-container MPCP-A1-margin-bottom-8">
                          <Text id="MPCP-A1-input-field-label">Start Date</Text>
                        </div>
                        <div className="MPCP-A1-row-container">
                          <div className="MPCP-A1-customized-date-picker-container">
                            <CustomizedDatePicker
                              date={new Date(date)}
                              handleDateChange={this.handleDateChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="MPCP-A1-membership-packages-checkout-form-right-panel-container">
                    <div className="MPCP-A1-right-panel-col-content MPCP-A1-margin-top-32">
                      <div className="MPCP-A1-label-type-one-container">
                        <Text id="MPCP-A1-label-type-one">Package Details</Text>
                      </div>
                      <div className="MPCP-A1-row-container MPCP-A1-margin-top-16">
                        <Text id="MPCP-A1-label-type-two">
                          {
                            selectedMembershipPackageDetails.packageDetails
                              .packageName
                          }
                        </Text>
                      </div>
                      {selectedMembershipPackageDetails.packageDetails
                        .description !== "" ? (
                        <div className="MPCP-A1-row-container MPCP-A1-margin-bottom-8">
                          <Paragraph id="MPCP-A1-label-type-three">
                            {
                              selectedMembershipPackageDetails.packageDetails
                                .description
                            }
                          </Paragraph>
                        </div>
                      ) : (
                        <div className="MPCP-A1-row-container MPCP-A1-margin-top-16 MPCP-A1-margin-bottom-8">
                          <Paragraph id="MPCP-A1-label-type-three">
                            Package description not available
                          </Paragraph>
                        </div>
                      )}
                      <div className="MPCP-A1-row-container ">
                        <Text marginY={10}>
                          Type:{" "}
                          {selectedMembershipPackageDetails.packageDetails.type}
                        </Text>
                      </div>
                      <div className="MPCP-A1-row-container ">
                        <Text marginY={10}>
                          Credits:{" "}
                          {
                            selectedMembershipPackageDetails?.packageDetails
                              ?.packageCredits
                          }
                        </Text>
                      </div>
                      <div className="MPCP-A1-row-container">
                        <div>
                          <Text id="MPCP-A1-label-type-four">
                            {`${generateCurrencySymbol()} ${checkIntOrFloat(
                              this.state.price.toFixed(2)
                            )}`}
                          </Text>
                          &nbsp;
                        </div>
                        {selectedMembershipPackageDetails.packageDetails
                          .startDate !== "" && (
                          <div>
                            <Text id="MPCP-A1-label-type-four">
                              {`| Started on ${toDateFormat32(
                                selectedMembershipPackageDetails.packageDetails
                                  .startDate
                              )} `}
                            </Text>
                          </div>
                        )}
                        &nbsp;
                        <div>
                          <Text id="MPCP-A1-label-type-four">
                            {`| ${this.state.period} Days
                            `}
                          </Text>
                        </div>
                      </div>
                      <div className="MPCP-A1-horizontal-line-decorator MPCP-A1-margin-top-16" />
                      <div className="MPCP-A1-row-container MPCP-A1-margin-top-24">
                        <div className="MPCP-A1-width-88">
                          <Text id="MPCP-A1-label-type-five">Duration</Text>
                        </div>
                        <div className="MPCP-A1-width-256">
                          <Text id="MPCP-A1-label-type-six">{`${this.state.period} Days`}</Text>
                        </div>
                      </div>
                      <div className="MPCP-A1-row-container MPCP-A1-margin-top-16">
                        <div className="MPCP-A1-width-88">
                          <Text id="MPCP-A1-label-type-five">Validity</Text>
                        </div>
                        <div className="MPCP-A1-width-256">
                          <Text id="MPCP-A1-label-type-six">{`${toDateFormat32(
                            this.state.date
                          )} to ${toDateFormat32(
                            moment(this.state.date).add(
                              this.state.period,
                              "days"
                            )
                          )}`}</Text>
                        </div>
                      </div>
                      <div className="MPCP-A1-row-container MPCP-A1-margin-top-16">
                        <div className="MPCP-A1-width-88">
                          <Text id="MPCP-A1-label-type-five">Price</Text>
                        </div>
                        <div className="MPCP-A1-width-256">
                          <Text id="MPCP-A1-label-type-six">{`${generateCurrencySymbol()} ${this.state.price.toFixed(
                            2
                          )}`}</Text>
                        </div>
                      </div>
                      <div className="MPCP-A1-horizontal-line-decorator MPCP-A1-margin-top-16" />
                      <div className="MPCP-A1-row-container MPCP-A1-margin-top-16">
                        <div className="MPCP-A1-right-panel-left-row-max-156">
                          <Text id="MPCP-A1-label-type-two">Gross</Text>
                        </div>
                        <div className="MPCP-A1-right-panel-right-row-max-156">
                          <Text id="MPCP-A1-label-type-seven">{`${generateCurrencySymbol()} ${this.state.price.toFixed(
                            2
                          )}`}</Text>
                        </div>
                      </div>
                      <div className="MPCP-A1-col-container MPCP-A1-margin-top-24">
                        {showDiscountAppliedDiv && (
                          <div className="MPCP-A1-success-msg-container">
                            <div className="MPCP-A1-success-msg-left-pane">
                              <TickCircleIcon
                                cursor={"pointer"}
                                color="success"
                                size={14}
                                marginRight={12}
                              />
                              <Text id="MPCP-A1-medium-dark-label">
                                {`Discount applied - ${generateCurrencySymbol()} ${checkIntOrFloat(
                                  discountApplied
                                )}`}
                              </Text>
                            </div>
                            <div className="MPCP-A1-success-msg-right-pane">
                              <IconButton
                                appearance="minimal"
                                icon={<CrossIcon />}
                                intent="danger"
                                iconSize={16}
                                onClick={(e) => this.resetDiscount(e)}
                              />
                            </div>
                          </div>
                        )}
                        {showDiscountActionDiv && (
                          <div className="MPCP-A1-col-container">
                            <div className="MPCP-A1-row-344-container">
                              <Select width={88} height={32} marginRight={8}>
                                <option value="discount">Discount</option>
                              </Select>
                              <div className="MPCP-A1-text-input-field-container">
                                <TextInput
                                  className="MPCP-A1-text-input-field"
                                  width={160}
                                  height={32}
                                  placeholder="Amount"
                                  value={discountAmount}
                                  onChange={(e) => this.changeDiscountAmount(e)}
                                  isInvalid={isDiscountInvalid}
                                />
                              </div>
                              <Button
                                className="MPCP-A1-button"
                                width={80}
                                height={32}
                                marginLeft={8}
                                appearance="minimal"
                                onClick={(e) => this.applyDiscount(e)}
                              >
                                Apply
                              </Button>
                            </div>
                            {showInvalidDiscountDiv && (
                              <div className="MPCP-A1-invalid-input-msg-container">
                                <InlineAlert
                                  className="MPCP-A1-inline-alert"
                                  intent="danger"
                                >
                                  <Text id="MPCP-A1-invalid-input-msg">
                                    Discount cannot be greater than gross amount
                                  </Text>
                                </InlineAlert>
                              </div>
                            )}
                            {showDiscountExceedsBalanceDiv && (
                              <div className="MPCP-A1-invalid-input-msg-container">
                                <InlineAlert
                                  className="MPCP-A1-inline-alert"
                                  intent="danger"
                                >
                                  <Text id="MPCP-A1-invalid-input-msg">
                                    Discount cannot be applied as it exceeds
                                    total amount payable
                                  </Text>
                                </InlineAlert>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                      <div className="MPCP-A1-horizontal-line-decorator MPCP-A1-margin-top-24" />
                      <div className="MPCP-A1-row-container MPCP-A1-margin-top-24">
                        <div className="MPCP-A1-right-panel-left-row-max-156">
                          <Text id="MPCP-A1-bigger-label">Net Price</Text>
                        </div>
                        <div className="MPCP-A1-right-panel-right-row-max-156">
                          <Text id="MPCP-A1-bigger-label">
                            {`${generateCurrencySymbol()} ${checkIntOrFloat(
                              this.state.price.toFixed(2) - discountApplied
                            )}`}
                          </Text>
                        </div>
                      </div>
                      <div className="MPCP-A1-horizontal-line-decorator MPCP-A1-margin-top-24" />
                      <div className="MPCP-A1-label-type-one-container MPCP-A1-margin-top-24">
                        <Text id="MPCP-A1-label-type-one">Payment Details</Text>
                      </div>
                      <div className="MPCP-A1-col-container MPCP-A1-margin-top-16">
                        <div className="MPCP-A1-row-344-container">
                          <div className="MPCP-A1-width-86 MPCP-A1-margin-right-8">
                            <Text id="MPCP-A1-small-light-label">Advance</Text>
                          </div>
                          <div className="MPCP-A1-text-input-field-container MPCP-A1-width-104 MPCP-A1-margin-right-16">
                            <TextInput
                              className="MPCP-A1-text-input-field"
                              width={104}
                              height={32}
                              marginRight={30}
                              placeholder={`${generateCurrencySymbol()} 0`}
                              value={advanceAmount}
                              onChange={(e) => this.handleAdvanceInput(e)}
                              isInvalid={isAdvanceInvalid}
                            />
                          </div>
                          <div className="MPCP-A1-select-field-container">
                            <TransactionModeSelect
                              changeTransactionMode={this.changeTransactionMode}
                              selectedTransactionMode={selectedTransactionMode}
                              disableNoPay={disableNoPay}
                            />
                          </div>
                        </div>
                        {showInvalidAdvanceDiv && (
                          <div className="MPCP-A1-invalid-input-msg-container">
                            <InlineAlert
                              className="MPCP-A1-inline-alert"
                              intent="danger"
                            >
                              <Text id="MPCP-A1-invalid-input-msg">
                                Advance cannot be greater than net price
                              </Text>
                            </InlineAlert>
                          </div>
                        )}
                      </div>
                      <div className="MPCP-A1-row-344-container MPCP-A1-margin-top-24">
                        <div className="MPCP-A1-width-120 MPCP-A1-margin-right-8">
                          <Text id="MPCP-A1-small-light-label">Balance</Text>
                        </div>
                        <div className="MPCP-A1-width-88 MPCP-A1-margin-right-8 MPCP-A1-word-break-all">
                          <Text id="MPCP-A1-small-dark-label">
                            {`${checkIntOrFloat(
                              this.state.price.toFixed(2) -
                                discountApplied -
                                advanceAmount
                            )}`}
                          </Text>
                        </div>
                      </div>
                      <div className="MPCP-A1-row-344-container MPCP-A1-margin-top-24">
                        <Checkbox
                          label="Send Confirmation SMS"
                          checked={sendConfirmationSmschecked}
                          onChange={(e) =>
                            this.handleSendConfirmationSmsCheckbox(e)
                          }
                        />
                      </div>
                      <div className="MPCP-A1-row-container MPCP-A1-margin-top-32">
                        <Dialog
                          width={560}
                          isShown={showConfirmDismissDialog}
                          title="Warning"
                          intent="danger"
                          confirmLabel="Confirm"
                          onConfirm={(e) =>
                            this.handleConfirmDismissDialog("confirm")
                          }
                          onCloseComplete={(e) =>
                            this.handleConfirmDismissDialog("hide")
                          }
                        >
                          <Text id="MPCP-A1-confirmation-dialog-label">
                            All the details will be cleared. Do you wish to
                            continue?
                          </Text>
                        </Dialog>
                        <Button
                          className="button"
                          width={152}
                          height={32}
                          marginRight={36}
                          onClick={(e) =>
                            this.handleConfirmDismissDialog("show")
                          }
                        >
                          Dismiss
                        </Button>
                        <Button
                          className="button"
                          width={152}
                          height={32}
                          appearance="primary"
                          intent="success"
                          disabled={isConfirmPackageButtonDisabled}
                          onClick={(e) => this.confirmSubscribePackage(e)}
                        >
                          Confirm Package
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({
  memberDetails,
  selectedMembershipPackageDetails,
  subscribeMembershipPackageToast,
  customerDetails,
  individualMemberDetailsToast,
}) => ({
  memberDetails,
  selectedMembershipPackageDetails,
  subscribeMembershipPackageToast,
  customerDetails,
  individualMemberDetailsToast,
});

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default connect(mapStateToProps)(MembershipCheckoutPage);
