import {
  Button,
  Pane,
  Strong,
  Table,
  Text,
  TickCircleIcon,
} from "evergreen-ui";
import React from "react";
import { createObjects } from "../../utils";

export default function BookingCheckIn({
  bookingId,
  bookingCheckIn,
  checkinData,
  type,
  moreInfo,
  bookingDate,
}) {
  function isIndexInCheckInData(index, data = []) {
    try {
      // Validate input
      const numericIndex = Number(index);

      if (!Number.isInteger(numericIndex)) {
        throw new Error("Index must be a valid integer.");
      }

      if (!Array.isArray(data)) {
        throw new Error("Data must be an array.");
      }

      // Find the item with the matching numeric index
      const matchingItem = data.find(
        (item) => Number(item.attendee_id) === numericIndex
      );

      if (matchingItem) {
        return matchingItem;
      } else {
        throw new Error("Item not found with the specified index.");
      }
    } catch (error) {
      console.error(`Error in getItemIfIndexExists: ${error.message}`);
      return null; // or some default value
    }
  }
  // let checkinData = [
  //   {
  //     attendee_id: 1,
  //   },
  // ];

  function compareDates(bookingDate, formattedDate) {
    // Convert strings to Date objects
    var date1 = new Date(bookingDate);
    var date2 = new Date(formattedDate);

    // Compare the dates
    return date1.getTime() === date2.getTime();
  }

  function renderObject(obj, index) {
    let checkedIn = isIndexInCheckInData(index, checkinData);
    console.log({ checkedIn });
    var dateBooked = new Date();

    // Formatting the date components
    var year = dateBooked.getFullYear();
    var month = ("0" + (dateBooked.getMonth() + 1)).slice(-2); // Months are zero-based
    var day = ("0" + dateBooked.getDate()).slice(-2);

    // Creating the formatted date string
    var formattedDate = year + "-" + month + "-" + day;
    console.log({ bookingDate, formattedDate });
    return (
      <Table background={"tint2"}>
        {checkedIn ? (
          <Pane
            display="flex"
            flexDirection="row"
            marginTop={10}
            padding={6}
            borderBottom={"1px solid lightGray"}
            justifyContent="space-between"
          >
            <Pane
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              textAlign="start"
              gap={5}
            >
              <Pane display="flex" flexDirection="row" alignItems="center">
                {" "}
                <Strong color="#52BD95">Checked In</Strong>{" "}
                <TickCircleIcon color="success" marginLeft={8} />
              </Pane>
              <Text>{checkedIn?.timestamp}</Text>
            </Pane>

            <Pane
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              textAlign="end"
              gap={5}
            >
              <Text>Checked In By</Text>
              <Text>{checkedIn?.handler}</Text>
            </Pane>
          </Pane>
        ) : (
          <Button
            width={"100%"}
            appearance="primary"
            intent="success"
            disabled={!compareDates(bookingDate, formattedDate)}
            onClick={() => bookingCheckIn(bookingId, index)}
          >
            Check In
          </Button>
        )}

        <Table.Body>
          {Object.entries(obj).map(([key, value]) => (
            <Table.Row key={key}>
              <Table.TextCell>{key}</Table.TextCell>
              <Table.TextCell>{value}</Table.TextCell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    );
  }

  let moreInfoObject = moreInfo && createObjects(moreInfo);

  return type === 0 && moreInfo ? (
    <Pane
      width={"100%"}
      marginTop={20}
      paddingBottom={10}
      display={"flex"}
      flexDirection={"column"}
    >
      {moreInfoObject.map((obj, index) => (
        <div key={index} style={{ marginBottom: "20px" }}>
          {renderObject(obj, index)}
        </div>
      ))}
    </Pane>
  ) : (
    <Pane paddingBottom={10}>
      {checkinData === null ? (
        <Button
          maxWidth={320}
          minWidth={320}
          appearance="primary"
          intent="success"
          onClick={() => bookingCheckIn(bookingId)}
        >
          Check In
        </Button>
      ) : (
        <Pane
          display="flex"
          flexDirection="row"
          marginTop={10}
          maxWidth={360}
          minWidth={340}
          justifyContent="space-between"
        >
          <Pane
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            textAlign="start"
            gap={5}
          >
            <Pane display="flex" flexDirection="row" alignItems="center">
              {" "}
              <Strong color="#52BD95">Checked In</Strong>{" "}
              <TickCircleIcon color="success" marginLeft={8} />
            </Pane>
            <Text>{checkinData.timestamp}</Text>
          </Pane>
          <Pane
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            textAlign="end"
            gap={5}
          >
            <Text>Checked In By</Text>
            <Text>{checkinData.handler}</Text>
          </Pane>
        </Pane>
      )}
    </Pane>
  );
}
