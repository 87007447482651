// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import { Button, TrashIcon } from "evergreen-ui";

// -----------------------------------------------------------------------
// RemoveButton Component
// -----------------------------------------------------------------------
class RemoveButton extends React.Component {
  // -----------------------------------------------------------------------
  // render()
  // -----------------------------------------------------------------------
  render() {
    const { onRemoveFromCart, slot } = this.props;
    const appearance = "minimal";
    const iconBefore = <TrashIcon />;
    const intent = "danger";
    return (
      <React.Fragment>
        <Button
          appearance={appearance}
          iconBefore={iconBefore}
          intent={intent}
          className="remove-btn"
          onClick={(e) => onRemoveFromCart(slot["cartedSlotId"])}
        >
          Remove
        </Button>
      </React.Fragment>
    );
  }
}

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default RemoveButton;
