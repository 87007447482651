// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import { connect } from "react-redux";
import Drawer from "@material-ui/core/Drawer";
import styled from "styled-components";
import Backdrop from "@material-ui/core/Backdrop";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import {
  Button,
  Text,
  Pane,
  SelectField,
  Strong,
  Heading,
  CrossIcon,
} from "evergreen-ui";
import CourtActions from "../CourtActions";
import BookingDetails from "./bookingDetails";

import {
  toDateFormat,
  slotTimeConverter,
  generateCurrencySymbol,
} from "../../utils";

// -----------------------------------------------------------------------
// Styled Components
// -----------------------------------------------------------------------
const DrawerDiv = styled.div`
  position: relative;

  height: auto;
  background-color: #f5f6f7;

  .close-btn-cart-top-label-container {
    width: 413px;
    height: 56px;
    margin-top: 56px;
    box-shadow: inset 0 -1px 0 0 #edf0f2;
    background-color: #ffffff;
    display: flex;
    align-items: center;
  }

  .cart-top-label-container {
    height: 32px;
    display: flex;
    align-items: center;
  }

  #cart-top-label {
    width: fit-content;
    height: 24px;
    font-size: 20px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2;
    letter-spacing: -0.1px;
    color: #234361;
    text-align: center;
  }
`;

const PopoverContentDiv = styled.div`
  width: 340px;
  height: 132px;

  .slot-date-time-close-btn-container {
    display: flex;
    margin: 14px auto 10px auto;
    justify-content: space-between;
    width: 316px;
  }

  #slot-date-time {
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: -0.1px;
    color: #234361;
  }

  .court-name-price-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin: 10px auto 20px auto;
    width: 292px;
  }

  #court-name {
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: -0.1px;
    color: #425a70;
  }

  #court-price {
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: -0.1px;
    text-align: right;
    color: #425a70;
  }
`;

// -----------------------------------------------------------------------
// DrawerHolder Component
// -----------------------------------------------------------------------
class ActivityType extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: "",
      isShown: false,
    };
  }

  handleChange = async (value) => {
    this.setState({
      expanded: value,
    });
  };

  // -----------------------------------------------------------------------
  //  render
  // -----------------------------------------------------------------------
  render() {
    const {
      date,
      slotData,
      slotBookingCount,
      selectedActivityId,
      status,
      isShown,
      activityType1Details,
      onAddToCart,
      onBookNow,

      typeOneDetails,
      updateSlotBookingCount,
      updateCalendarPage,
    } = this.props;
    let isCollapse = this.state.isShown;

    let intent =
      status && status.toLowerCase() === "book" ? "warning" : "danger";
    return (
      <React.Fragment>
        <Backdrop style={{ zIndex: "15" }} open={isShown}></Backdrop>
        <PopoverContentDiv style={{ height: "auto", width: "auto" }}>
          <Drawer
            variant="permanent"
            anchor="right"
            open={isShown}
            style={{ display: isShown ? "initial" : "none" }}
          >
            <DrawerDiv>
              {status && status.toLowerCase() === "book" ? (
                <div>
                  <div className="close-btn-cart-top-label-container">
                    <CrossIcon
                      height={32}
                      marginLeft={8}
                      marginRight={8}
                      appearance="minimal"
                      cursor="pointer"
                      onClick={(e) => activityType1Details(slotData, false)}
                    />
                    <div className="cart-top-label-container">
                      <Heading id="cart-top-label"></Heading>
                    </div>
                  </div>
                  <Pane
                    marginLeft={20}
                    padding={15}
                    marginTop={10}
                    height={150}
                    background={"white"}
                  >
                    <Text id="slot-date-time">
                      {toDateFormat(date) +
                        ` | ` +
                        slotTimeConverter(slotData.slotTime, slotData.endTime)}
                    </Text>

                    <Pane
                      align
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                    >
                      <Text id="court-name">{`${slotData.courtName}`}</Text>
                      <SelectField
                        width={86}
                        height={22}
                        marginRight={10}
                        marginLeft={10}
                        marginTop={5}
                        value={slotBookingCount}
                        onChange={(e) => {
                          updateSlotBookingCount(e.target.value);
                        }}
                      >
                        {Array.from(Array(slotData.available).keys()).map(
                          (i) => (
                            <option key={i} value={i + 1}>
                              {i + 1}
                            </option>
                          )
                        )}
                      </SelectField>
                      <Text paddingRight={30}>Slot(s)</Text>
                      <Text paddingRight={20}>X</Text>
                      <Text id="court-price">{`${generateCurrencySymbol()} ${
                        slotData.price
                      }`}</Text>
                    </Pane>

                    <React.Fragment>
                      <CourtActions
                        activityType1Details={activityType1Details}
                        slotData={slotData}
                        date={date}
                        selectedActivityId={selectedActivityId}
                        onAddToCart={onAddToCart}
                        onBookNow={onBookNow}
                      />
                    </React.Fragment>
                  </Pane>
                </div>
              ) : (
                <div className="close-btn-cart-top-label-container">
                  <CrossIcon
                    height={32}
                    marginLeft={8}
                    marginRight={8}
                    appearance="minimal"
                    cursor="pointer"
                    onClick={(e) => activityType1Details(slotData, false)}
                  />
                  <div className="cart-top-label-container">
                    <Heading id="cart-top-label">
                      <Text id="slot-date-time">
                        {toDateFormat(date) +
                          ` | ` +
                          slotTimeConverter(
                            slotData.slotTime,
                            slotData.endTime
                          )}
                      </Text>
                    </Heading>
                  </div>
                </div>
              )}
              <Pane marginLeft={20} marginTop={10}>
                {this.props.typeOneDetails
                  ? typeOneDetails.data.map((data, index) => (
                      <Pane marginTop={10} marginBottom={20}>
                        {" "}
                        <Accordion
                          style={{
                            boxShadow: "none",
                          }}
                          rounded={false}
                          // onClick={() =>
                          // 	this.setState({
                          // 		//isShown: !this.state.isShown,
                          // 	})
                          // }
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                          >
                            <Pane display={"flex"} flexDirection={"row"}>
                              <pane marginRight={10} flexDirection={"column"}>
                                <Typography variant="body2" gutterBottom>
                                  Slot
                                </Typography>
                                <Strong color="black" marginLeft={5}>
                                  {data.slotCount}
                                </Strong>
                              </pane>

                              <div style={{ marginLeft: "10px" }}>
                                <pane flexDirection={"column"}>
                                  <Typography variant="body2" gutterBottom>
                                    Booking Id
                                  </Typography>
                                  <Strong>{data.bookingId}</Strong>
                                </pane>
                              </div>
                              <div style={{ marginLeft: "20px" }}>
                                <pane marginLeft={10} flexDirection={"column"}>
                                  <Typography variant="body2" gutterBottom>
                                    Name
                                  </Typography>
                                  <Strong>{data.customerName}</Strong>
                                </pane>
                              </div>
                            </Pane>
                          </AccordionSummary>
                          <AccordionDetails padding={0}>
                            <BookingDetails
                              updateCalendarPage={updateCalendarPage}
                              date={this.props.date}
                              slotData={slotData}
                              data={data}
                            />
                          </AccordionDetails>
                        </Accordion>
                      </Pane>
                    ))
                  : null}
              </Pane>
            </DrawerDiv>
          </Drawer>

          <Button
            className="button"
            width={112}
            height={32}
            appearance="primary"
            onClick={(e) => {
              activityType1Details(slotData, true);
            }}
            intent={
              slotData.maxSlotCount === slotData.available ? "success" : intent
            }
          >
            {status}
          </Button>
        </PopoverContentDiv>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ typeOneDetails }) => ({ typeOneDetails });

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default connect(mapStateToProps)(ActivityType);
