// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import { Text, Icon, ChevronLeftIcon, ChevronRightIcon } from "evergreen-ui";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  LineChart,
  Line,
} from "recharts";

// -----------------------------------------------------------------------
// LineChartBox Component
// -----------------------------------------------------------------------
class LineChartBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  // -----------------------------------------------------------------------
  // render()
  // -----------------------------------------------------------------------
  render() {
    const { chartLabel, chartData } = this.props;

    return (
      <div className="BCB-A1-wrapper">
        <div className="BCB-A1-label-container BCB-A1-margin-bottom-8">
          <Text id="BCB-A1-label-type-one">{chartLabel}</Text>
        </div>
        <div className="BCB-A1-chart-wrapper">
          {/* <div className="BCB-A1-navigation-btn-container">
            <img src={ovalLeft} alt="chevron-left" />
            <div className="BCB-A1-icon-container BCB-A1-chevron-left-icon-container">
              <ChevronLeftIcon color="muted" size={16} />
            </div>
          </div> */}
          <div className="BCB-A1-chart-container">
            <LineChart
              width={380}
              height={220}
              data={chartData}
              margin={{ top: 10, right: 20, left: 20, bottom: 10 }}
            >
              <XAxis
                dataKey="month"
                tick={{ width: 91.33, fontSize: 11 }}
                interval={0}
              />

              {/* Remove vertical grid lines */}
              <CartesianGrid strokeDasharray="3 3" vertical={false} />
              <Tooltip />

              <Line
                type="monotone"
                dataKey="count"
                stroke="#82ca9d"
                strokeWidth={3}
              />
            </LineChart>
          </div>
          {/* <div className="BCB-A1-navigation-btn-container">
            <img src={ovalRight} alt="chevron-right" />
            <div className="BCB-A1-icon-container BCB-A1-chevron-right-icon-container">
              <ChevronRightIcon color="muted" size={16} />
            </div>
          </div> */}
        </div>
      </div>
    );
  }
}

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default LineChartBox;
