// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import { SelectField } from "evergreen-ui";
import { connect } from "react-redux";

// -----------------------------------------------------------------------
// ClubActivityListSelect Component
// -----------------------------------------------------------------------
class ClubActivityListSelect extends React.Component {
  // -----------------------------------------------------------------------
  // render()
  // -----------------------------------------------------------------------
  render() {
    const {
      handleActivityChange,
      selectedActivityValue,
      clubActivityData,
      defaultActivityValue,
      defaultActivityName,
      disabled,
      width,
    } = this.props;
    return (
      <React.Fragment>
        {clubActivityData &&
          clubActivityData.requestStatus === 1 &&
          clubActivityData.activities && (
            <div className="CALS-A1-select-input-field-container">
              <SelectField
                className="CALS-A1-select-input-field"
                label="sport"
                width={width ? width : 280}
                height={32}
                onChange={(e) => handleActivityChange(e)}
                value={selectedActivityValue}
                disabled={disabled}
              >
                <option value={defaultActivityValue}>
                  {defaultActivityName}
                </option>
                {clubActivityData.activityDetails.map((activity, index) => (
                  <option key={index} value={activity.activityId}>
                    {clubActivityData.activities[activity.activityId]}
                  </option>
                ))}
              </SelectField>
            </div>
          )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ clubActivityData }) => ({ clubActivityData });
// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default connect(mapStateToProps)(ClubActivityListSelect);
