const premiumFeatureFlagToggle = (state = false, action) => {
  if (action.type === "TOGGLE_PREMIUM_FEATURE_FLAG") {
    state = action.payload;
  } else {
    return state;
  }
  // switch (action.type) {
  //   case "TOGGLE_PREMIUM_FEATURE_FLAG": {
  //     state = action.payload;
  //   }
  //   /* falls through */
  //   default: {
  //     return state;
  //   }
  // }
};

export default premiumFeatureFlagToggle;
