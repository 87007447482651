// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import { connect } from "react-redux";
import { debounce } from "throttle-debounce";
import Fuse from "fuse.js";
import {
  Table,
  Text,
  Avatar,
  Badge,
  IconButton,
  Menu,
  Popover,
  Position,
  RefreshIcon,
  DeleteIcon,
  MoreIcon,
  TextInputField,
  Pane,
  Button,
  toaster,
  Strong,
  CrossIcon,
} from "evergreen-ui";
import Navbar from "../../components/Navbar";
import PageHeadingAndActionsNav from "../../components/PageHeadingAndActionsNav";
import { generateCurrencySymbol, toDateFormat32 } from "../../utils";
import moment from "moment";
import NoMatchingResultsMessage from "../../components/NoMatchingResultsMessage";
import {
  cancelMembership,
  getMembershipSubscriptionDetails,
  saveSelectedMembershipPackageDetails,
} from "../../actions";
import { Dialog } from "@material-ui/core";
import TransactionModeSelect from "../../components/TransactionModeSelect";
import { toggleLoaderOff, toggleLoaderOn } from "../../actions/loaderUtils";
import MemberRow from "./MemberRow";
import CustomizedPagination from "../../components/CustomizedPagination";
import { MemberPagination } from "./MemberPagination";

// -----------------------------------------------------------------------
// AllMembersListPage Component
// -----------------------------------------------------------------------
class AllMembersListPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchQuery: "",
      selectedMemberDetails: {},
      selectedMembershipPackageDetails: {},
      showMenuPopover: true,
      allMembersList: null,
      initialLoad: true,
      showDeleteMemberDialog: false,
      selectedTransactionMode: 0,
      disableNoPay: false,
      isRefundInvalid: false,
      refundInput: "",
      currentPage: 1,
      itemsPerPage: 10,
      removeMembershipId: null,
      membershipId: null,
    };
  }

  componentDidMount = async () => {
    this.setStateWithAllMemberDetails();
  };

  // -----------------------------------------------------------------------
  //  componentDidUpdate()
  // -----------------------------------------------------------------------
  componentDidUpdate = (prevProps, prevState) => {
    try {
      if (
        prevProps.membershipSubscriptionDetails === null &&
        this.props.membershipSubscriptionDetails !== null &&
        this.props.membershipSubscriptionDetails.requestStatus === 1
      ) {
        this.setStateWithAllMemberDetails();
      }
    } catch (err) {
      console.log(err);
    }
  };

  // -----------------------------------------------------------------------
  // setStateWithAllMemberDetails()
  // -----------------------------------------------------------------------
  setStateWithAllMemberDetails = async () => {
    try {
      if (
        this.props.membershipSubscriptionDetails &&
        this.props.membershipSubscriptionDetails.packages
      ) {
        await this.setState({
          allMembersList: this.props.membershipSubscriptionDetails.packages,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  // -----------------------------------------------------------------------
  // handleFilterChange()
  // -----------------------------------------------------------------------
  handleFilterChange = async (value) => {
    await this.setState({ searchQuery: value });
    debounce(500, async () => {
      await this.filterMembers();
    })();
  };

  // -----------------------------------------------------------------------
  // filterMembers()
  // -----------------------------------------------------------------------
  filterMembers = async () => {
    try {
      if (
        this.props.membershipSubscriptionDetails &&
        this.props.membershipSubscriptionDetails.packages
      ) {
        if (this.state.searchQuery.trim().length <= 0) {
          await this.setState({
            allMembersList: this.props.membershipSubscriptionDetails.packages,
          });
        } else {
          var options = {
            shouldSort: true,
            threshold: 0.0,
            location: 0,
            distance: 100,
            maxPatternLength: 32,
            minMatchCharLength: 0,
            keys: ["memberName"],
          };
          var fuse = new Fuse(
            this.props.membershipSubscriptionDetails.packages,
            options
          );
          var result = await fuse.search(this.state.searchQuery.toString());

          await this.setState({ allMembersList: result });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  // -----------------------------------------------------------------------
  // toggleMenuPopover()
  // -----------------------------------------------------------------------
  toggleMenuPopover = async (type) => {
    if (type === "show") {
      await this.setState({ showMenuPopover: true });
    } else if (type === "hide") {
      await this.setState({ showMenuPopover: false });
    }
  };

  // -----------------------------------------------------------------------
  // handleRefundInput()
  // -----------------------------------------------------------------------
  handleRefundInput = async (e) => {
    e.persist();
    if (e.target.value.length > 0) {
      await this.setState({
        refundInput: e.target.value,
        isRefundInvalid: false,
      });
    } else {
      await this.setState({
        refundInput: e.target.value,
        isRefundInvalid: true,
      });
    }
  };

  // -----------------------------------------------------------------------
  // handleBackButtonOnAllMembersListPage()
  // -----------------------------------------------------------------------
  handleBackButtonOnAllMembersListPage = async (e) => {
    e.persist();
    await this.props.dispatch({
      type: "SET_MEMBERSHIP_SUBSCRIPTION_DETAILS_DATA",
      payload: null,
    });
    await this.setState({ searchQuery: "" });
    await this.props.handleAllMembersListPageDisplay("hide");
  };
  //  -----------------------------------------------------------------------
  // handleDeleteMemberDialog()
  // -----------------------------------------------------------------------

  handleDeleteMemberDialog = async (type, membershipId) => {
    if (type === "open") {
      toggleLoaderOff(this.props.dispatch);
      this.setState({
        showDeleteMemberDialog: true,
        membershipId: membershipId,
      });
    } else if (type === "close") {
      toggleLoaderOff(this.props.dispatch);
      this.setState({ showDeleteMemberDialog: false });
    }
  };

  // -----------------------------------------------------------------------
  // changeActiveMemberDetails()
  // -----------------------------------------------------------------------
  changeActiveMemberDetails = async (member) => {
    let memberDetails = {
      memberMobile: member.memberMobile,
      memberId: member.memberId,
      memberName: member.memberName,
      memberEmail: member.memberEmail,
    };
    let packageDetails = {
      additionalInfo: member.additionalInfo,
      advance: member.advance,
      availableTickets: member.availableTickets,
      balance: member.balance,
      discount: member.discount,
      endDate: member.endDate,
      grossAmount: member.grossAmount,
      isActive: member.isActive,
      memberId: member.memberId,
      memberLimit: member.memberLimit,
      memberMobile: member.memberMobile,
      memberName: member.memberName,
      membershipId: member.membershipId,
      membershipStartDate: member.membershipStartDate,
      packageCredits: member.packageCredits,
      packageDescription: member.packageDescription,
      packageId: member.packageId,
      packageName: member.packageName,
      packagePrice: member.packagePrice,
      period: member.period,
      renewalDate: member.renewalDate,
      slotId: member.slotId,
      soldTickets: member.soldTickets,
      startDate: member.startDate,
      status: member.status,
      subscriptionId: member.subscriptionId,
      totalTickets: member.soldTickets,
      type: member.type,
    };
    await this.setState({
      selectedMemberDetails: memberDetails,
      selectedMembershipPackageDetails: packageDetails,
    });
  };

  // -----------------------------------------------------------------------
  // changeTransactionMode()
  // -----------------------------------------------------------------------
  changeTransactionMode = (e) => {
    e.persist();
    if (this.state.refundInput.length > 0) {
      this.setState({
        paymentMode:
          e.nativeEvent.target[
            e.nativeEvent.target.selectedIndex
          ].text.toString(),
        transactionMode: parseInt(e.target.value),
        selectedTransactionMode: parseInt(e.target.value),
        isRefundInvalid: false,
      });
    } else {
      this.setState({ isRefundInvalid: true });
    }
  };

  // -----------------------------------------------------------------------
  // handleRenewPackage()
  // -----------------------------------------------------------------------
  handleRenewPackage = async () => {
    this.toggleMenuPopover("hide");
    await this.props.dispatch(
      saveSelectedMembershipPackageDetails(
        this.state.selectedMembershipPackageDetails,
        this.state.selectedMemberDetails,
        "renew-package"
      )
    );
    this.toggleMenuPopover("show");
    if (this.props.selectedMembershipPackageDetails) {
      if (this.props.selectedMembershipPackageDetails.requestStatus === 1) {
        await this.props.handleMembershipPackagesCheckoutPageDisplay(
          "show",
          "all-members-list-page"
        );
      }
    }
  };

  // -----------------------------------------------------------------------
  // handleCancelMembership()
  // -----------------------------------------------------------------------
  handleCancelMembership = async (membershipCancel = false) => {
    let { membershipId } = this.state;

    console.log(this.state);
    let data = {
      subscriptionId:
        this.state.selectedMembershipPackageDetails?.subscriptionId,
      amount: 1,
      transactionData: {
        type: -1,
        mode: this.state.selectedTransactionMode,
      },
    };

    // if (this.state.refundInput <= 0) {
    //   toaster.danger("Please enter valid amount");
    //   this.setState({ isRefundInvalid: true });
    // } else {

    cancelMembership(data, this.props.dispatch).then(async (res) => {
      if (res.requestStatus === 1) {
        toaster.success("Membership Cancelled Successfully");
        this.setState({ removeMembershipId: membershipId });
      } else {
        toaster.danger("Something went wrong, please try again later");
      }

      this.setState({ showDeleteMemberDialog: false });
    });
    // }
  };

  handlePageChange = (newPage) => {
    this.setState({ currentPage: newPage });
  };

  // -----------------------------------------------------------------------
  // render()
  // -----------------------------------------------------------------------
  render() {
    const { searchQuery, showMenuPopover, allMembersList } = this.state;
    const { membershipSubscriptionDetails } = this.props;
    const { currentPage, itemsPerPage } = this.state;

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentMembers = allMembersList?.slice(
      indexOfFirstItem,
      indexOfLastItem
    );

    const totalItems = allMembersList?.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    return (
      <React.Fragment>
        <div className="AMLP-A1-wrapper">
          <div className="AMLP-A1-header-container">
            <Navbar />
            <PageHeadingAndActionsNav
              headingText={"All Members List"}
              handleBackButtonOnAllMembersListPage={
                this.handleBackButtonOnAllMembersListPage
              }
            />
          </div>
          <div className="AMLP-A1-body-container">
            <div className="AMLP-A1-members-list-container">
              {membershipSubscriptionDetails &&
              membershipSubscriptionDetails.requestStatus === 1 &&
              membershipSubscriptionDetails.packages.length === 0 ? (
                <NoMatchingResultsMessage />
              ) : (
                allMembersList !== null && (
                  <div className="">
                    <Table>
                      <Table.Head>
                        <Table.SearchHeaderCell
                          className="AMLP-A1-table-text-header-cell AMLP-A1-flex-basis-252"
                          placeholder="Search by name..."
                          onChange={this.handleFilterChange}
                          value={searchQuery}
                        />
                        <Table.TextHeaderCell className="AMLP-A1-table-text-header-cell AMLP-A1-flex-basis-241">
                          Package
                        </Table.TextHeaderCell>
                        <Table.TextHeaderCell className="AMLP-A1-table-text-header-cell AMLP-A1-flex-basis-241">
                          Phone Number
                        </Table.TextHeaderCell>
                        <Table.TextHeaderCell className="AMLP-A1-table-text-header-cell AMLP-A1-flex-basis-182">
                          Renewal
                        </Table.TextHeaderCell>
                        <Table.TextHeaderCell className="AMLP-A1-table-text-header-cell AMLP-A1-flex-basis-48" />
                      </Table.Head>
                      <Table.Body className="AMLP-A1-virtual-table-body">
                        {searchQuery?.length > 2
                          ? allMembersList.map(
                              (memberDetails, index) =>
                                memberDetails.membershipId !==
                                  this.state.removeMembershipId && (
                                  <MemberRow
                                    key={index}
                                    memberDetails={memberDetails}
                                    showMenuPopover={showMenuPopover}
                                    changeActiveMemberDetails={
                                      this.changeActiveMemberDetails
                                    }
                                    handleRenewPackage={this.handleRenewPackage}
                                    handleDeleteMemberDialog={
                                      this.handleDeleteMemberDialog
                                    }
                                  />
                                )
                            )
                          : currentMembers.map(
                              (memberDetails, index) =>
                                memberDetails.membershipId !==
                                  this.state.removeMembershipId && (
                                  <MemberRow
                                    key={index}
                                    memberDetails={memberDetails}
                                    showMenuPopover={showMenuPopover}
                                    changeActiveMemberDetails={
                                      this.changeActiveMemberDetails
                                    }
                                    handleRenewPackage={this.handleRenewPackage}
                                    handleDeleteMemberDialog={
                                      this.handleDeleteMemberDialog
                                    }
                                  />
                                )
                            )}
                      </Table.Body>
                    </Table>
                  </div>
                )
              )}
              <MemberPagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={this.handlePageChange}
              />
            </div>
          </div>
        </div>

        <Dialog
          open={this.state.showDeleteMemberDialog}
          intent="danger"
          onCloseComplete={() => this.handleDeleteMemberDialog("close")}
          confirmLabel="Delete"
        >
          <Pane
            marginBottom={7}
            display={"flex"}
            padding={20}
            flexDirection={"row"}
            justifyContent="space-between"
          >
            <Strong size={400}>Cancel Membership</Strong>
            <CrossIcon
              cursor={"pointer"}
              appearance="minimal"
              onClick={() => this.handleDeleteMemberDialog("close")}
            />
          </Pane>
          <Pane
            display="flex"
            flexDirection={"row"}
            height={"100px"}
            width={"100%"}
            padding={20}
            justifyContent="space-between"
          >
            {/* <TextInputField
              className="BC-A1-text-input-field"
              width={220}
              label="Refund Amount"
              height={32}
              marginRight={30}
              placeholder={`${generateCurrencySymbol()} 0`}
              value={this.state.refundInput}
              onChange={(e) => this.handleRefundInput(e)}
              isInvalid={this.state.isRefundInvalid}
            /> */}
            <Text>Are you sure you want to cancel membership?</Text>
            {/* <Pane marginTop={26} marginLeft={20}>
              <TransactionModeSelect
                disableNoPay={this.state.disableNoPay}
                selectedTransactionMode={this.state.selectedTransactionMode}
                changeTransactionMode={this.changeTransactionMode}
              />
            </Pane> */}
          </Pane>
          <Pane
            display="flex"
            flexDirection="column"
            alignItems="end"
            justifyContent="end"
            width={"100%"}
            padding={20}
          >
            <Button
              className="SPDC-A1-button"
              width={114}
              height={32}
              appearance="primary"
              intent="danger"
              onClick={() => this.handleCancelMembership(true)}
            >
              Confirm
            </Button>
          </Pane>
        </Dialog>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({
  membershipSubscriptionDetails,
  selectedMembershipPackageDetails,
}) => ({
  membershipSubscriptionDetails,
  selectedMembershipPackageDetails,
});

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default connect(mapStateToProps)(AllMembersListPage);
