// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import { connect } from "react-redux";
import {
  Text,
  Button,
  toaster,
  Strong,
  Pane,
  Table,
  Spinner,
  RadioGroup,
  ConfirmIcon,
} from "evergreen-ui";
import update from "immutability-helper";

import { getCurrentClub } from "../../utils";
import {
  getFeatureList,
  getUserPermission,
  updateUserPermission,
} from "../../actions/index";
import userPermissions from "../../reducers/userPermission";

// -----------------------------------------------------------------------
// LoginPage Component
// -----------------------------------------------------------------------
class tableBody extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      finalPermissions: [],
      permissionsArray: [],
      featureList: [],
      statePermission: false,
      options: [{ value: "read-only" }, { value: "write" }, { value: "none" }],
    };
  }
  async setPermission(data, index, id, read, write) {
    let value = data.target.value;
    let { permissionsArray, finalPermissions, featureList } = this.state;

    let length = finalPermissions.length;
    if (featureList.features[index].value == "none") {
      {
        if (write == false && value == "write") {
          toaster.warning(
            "Write access are not available for the selected permission"
          );
        } else if (read == false && value == "read-only") {
          toaster.warning(
            "Read access are not available for the selected permission"
          );
        } else {
          this.state.featureList.features[index].value = value;
          value = value == "write" ? false : true;
          let arrayValues = {
            featureId: index + 1,
            isReadOnly: value,
          };
          let a = finalPermissions.slice(); //creates the clone of the state
          a[length] = arrayValues;

          await this.setState({ finalPermissions: a });
        }
      }
      //finalPermissions[index].push(arrayValues);
    } else if (
      value == "none" &&
      this.state.featureList.features[index].value !== "none"
    ) {
      this.state.featureList.features[index].value = value;
      var val = finalPermissions.map((el) => el.featureId).indexOf(id);

      finalPermissions[val].featureId = null;
      finalPermissions[val].isReadOnly = null;
      // this.setState((prevState) => ({
      // 	finalPermissions: update(prevState.finalPermissions, {
      // 		$splice: [[val, 1]],
      // 	}),
      // }));
      //newPermissions.sort((a, b) => a.featureId - b.featureId);

      //this.setState({ finalPermissions: [newPermissions] });
    } else if (value == "write") {
      if (write == false) {
        toaster.warning(
          "Write access are not available for the selected permission"
        );
      } else {
        this.state.featureList.features[index].value = value;
        var val = finalPermissions.map((el) => el.featureId).indexOf(id);

        finalPermissions[val].featureId = id;
        finalPermissions[val].isReadOnly = false;
      }
    } else if (value == "read-only") {
      if (read == false) {
        toaster.warning(
          "Read access are not available for the selected permission"
        );
      } else {
        this.state.featureList.features[index].value = value;
        var val = finalPermissions.map((el) => el.featureId).indexOf(id);

        finalPermissions[val].featureId = index + 1;
        finalPermissions[val].isReadOnly = true;
      }
    }

    await this.setState({
      featureList,
    });

    // permissionsArray[index].value = value;
  }

  splitCamelCaseToString(s) {
    return s
      .split(/(?=[A-Z])/)
      .map(function (p) {
        return p.charAt(0).toUpperCase() + p.slice(1);
      })
      .join(" ");
  }

  async componentDidMount() {
    let clubId = getCurrentClub();
    let extraValues;
    await this.props.dispatch(getFeatureList());
    let { staffMember, featureList } = this.props;

    extraValues = {
      featureName: "default",
      featureId: 20,
    };
    if (featureList.requestStatus === 1) {
      featureList.features.splice(19, 0, extraValues);
      await this.setState({
        featureList: featureList,
      });
      await this.props.dispatch(
        getUserPermission({ userId: staffMember.userId, clubId: clubId })
      );
      let { permissionsArray } = this.state;
      let value = "";
      let readOnly = "";
      let arrayValues;

      if (this.props.userPermissions.requestStatus === 1) {
        this.setState({
          statePermission: true,
        });
        let { permissions } = this.props.userPermissions;

        //var headers = Object.keys(permissions); // get the header row

        var finalPermissions = [];

        // get the values and iterate over them
        Object.values(permissions).forEach((item, index) => {
          if (item.featureId) {
            if (item.read == true && item.write == true) {
              value = "write";
              readOnly = false;
            } else if (item.read == true && item.write == false) {
              value = "read-only";
              readOnly = true;
            } else if (item.read == false && item.write == true) {
              value = "write";
              readOnly = false;
            }

            arrayValues = {
              featureId: item.featureId,
              isReadOnly: readOnly,
            };
            var val = this.state.featureList.features
              .map((el) => el.featureId)
              .indexOf(item.featureId);

            if (val !== -1) {
              this.state.featureList.features[val].value = value;
            }
            finalPermissions.push(arrayValues);
            //finalPermissions.sort((a, b) => a.featureId - b.featureId);
            //this.setPermission(value, item.featureId);
          }

          Object.values(item).forEach((val, index) => {
            //	console.log(val);
          });
        });
        Object.values(this.state.featureList.features).forEach(
          (permissions, index) => {
            if (!permissions.value) {
              value = "none";
              this.state.featureList.features[index].value = value;
            }

            //finalPermissions.sort((a, b) => a.featureId - b.featureId);
            //this.setPermission(value, item.featureId);
          }
        );

        extraValues = {
          featureName: "default",
        };

        this.setState({
          finalPermissions,
        });

        // console.log(permissions["Booking"]);
        // for (const [key, { featureId }] of Object.entries(permissions)) {
        // 	permissions[featureId] = key
        // }
        // console.log(permissions)
      } else {
        toaster.warning("You dont have permission");
      }
    }
  }

  savePermissions = async () => {
    let { staffMember, handleSidebar } = this.props;
    let { finalPermissions } = this.state;
    var filtered = finalPermissions.filter(function (el) {
      return el.featureId != null;
    });

    let body = {
      userId: staffMember.userId,
      features: filtered,
    };

    await this.props.dispatch(updateUserPermission(body));
    if (this.props.saveUserPermissions.requestStatus === 1) {
      toaster.success("Permissions updated");
      handleSidebar("", "staff-console");
    } else {
      toaster.danger(this.props.saveUserPermissions.message);
    }
  };

  render() {
    let { staffMember, userPermissions } = this.props;
    let { finalPermissions, statePermission } = this.state;

    return (
      <Table border="default">
        <Table.Head>
          <Table.TextCell flexBasis={370} flexShrink={0} flexGrow={0}>
            Name
          </Table.TextCell>
          <Table.TextCell>
            <Pane
              display={"flex"}
              marginRight={160}
              justifyContent="space-between"
            >
              <Strong>View</Strong>
              <Strong>View/Edit</Strong>
              <Strong>None</Strong>
            </Pane>
          </Table.TextCell>
        </Table.Head>

        <Table.Body>
          {statePermission &&
          this.state.featureList &&
          this.state.featureList.features ? (
            this.state.featureList &&
            this.state.featureList.features.map((data, index) => (
              <Table.Row
                height={80}
                background={
                  !data.readAccess && !data.writeAccess ? "tint2" : null
                }
              >
                {" "}
                <Table.TextCell flexBasis={370} flexShrink={0} flexGrow={0}>
                  {" "}
                  <Pane display={"flex"} flexDirection={"column"}>
                    <Text size={400} color="black">
                      {this.splitCamelCaseToString(data.featureName)}
                    </Text>
                    {/* <Text size={200} color="muted">
											Discription
										</Text> */}
                  </Pane>
                </Table.TextCell>
                <Table.TextCell>
                  {!data.readAccess && !data.writeAccess ? (
                    <Pane></Pane>
                  ) : (
                    <RadioGroup
                      marginRight={170}
                      display={"flex"}
                      marginTop={10}
                      size={15}
                      value={data.value}
                      justifyContent="space-between"
                      options={this.state.options}
                      onChange={(event) =>
                        this.setPermission(
                          event,
                          index,
                          data.featureId,
                          data.readAccess,
                          data.writeAccess
                        )
                      }
                    />
                  )}
                </Table.TextCell>
              </Table.Row>
            ))
          ) : (
            <Pane
              display="flex"
              alignItems="center"
              justifyContent="center"
              height={400}
            >
              <Spinner />
            </Pane>
          )}
        </Table.Body>
        <Pane display={"flex"} justifyContent={"flex-end"} padding={20}>
          {statePermission ? (
            <Button
              marginRight={16}
              width={80}
              appearance="primary"
              justifyContent={"flex-end"}
              intent="success"
              iconAfter={<ConfirmIcon />}
              onClick={() => this.savePermissions()}
            >
              Save
            </Button>
          ) : null}
        </Pane>
      </Table>
    );
  }
}

const mapStateToProps = ({
  authStatus,
  loginDetails,
  userPermissions,
  saveUserPermissions,
  featureList,
}) => ({
  authStatus,
  loginDetails,
  userPermissions,
  saveUserPermissions,
  featureList,
});

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default connect(mapStateToProps)(tableBody);
