const bookingDetailsOnHover = (state = null, action) => {
    if (action.type === "SET_BOOKING_DETAILS_ON_HOVER") {
        return action.payload;
    } else {
        return state;
    }
    // switch (action.type) {
    //   case "SET_BOOKING_DETAILS": {
    //     return action.payload;
    //   }

    //   default: {
    //     return state;
    //   }
    // }
};

export default bookingDetailsOnHover;
