// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import { Text, InlineAlert } from "evergreen-ui";
import Navbar from "../../components/Navbar";
import PageHeadingAndActionsNav from "../../components/PageHeadingAndActionsNav";

// -----------------------------------------------------------------------
// BookingCheckout Component
// -----------------------------------------------------------------------
class BookingCheckout extends React.Component {
  // -----------------------------------------------------------------------
  // render()
  // -----------------------------------------------------------------------
  render() {
    const { hidePremiumFeaturePage } = this.props;
    return (
      <React.Fragment>
        <div className="PF-A1-wrapper">
          <div className="PF-A1-header-container">
            <Navbar />
            <PageHeadingAndActionsNav
              headingText={"Premium Feature"}
              hidePremiumFeaturePage={hidePremiumFeaturePage}
            />
          </div>
          <div className="PF-A1-body-container">
            <div className="PF-A1-invalid-input-msg-container">
              <InlineAlert className="PF-A1-inline-alert" intent="info">
                <Text id="PF-A1-info-input-msg">
                  The content you tried to access is a premium feature. Please
                  contact Playo to avail it.
                </Text>
              </InlineAlert>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default BookingCheckout;
