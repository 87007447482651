// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import { connect } from "react-redux";
import ExtrasActions from "../ExtrasActions/index";
import api from "../../api";
import BookingDetailsDrawerActions from "../BookingDetailsDrawerActions/index";

import {
  Button,
  Badge,
  Text,
  IconButton,
  Pane,
  Strong,
  Menu,
  toaster,
  Dialog,
  TextInputField,
  Icon,
  Textarea,
  Paragraph,
  CaretRightIcon,
  EditIcon,
  AddIcon,
  CrossIcon,
  FloppyDiskIcon,
} from "evergreen-ui";

import ExtraPayNow from "../ExtrasPayNow/index";
import TransactionsDialogContent from "../TransactionsDialogContent/index";
import TransactionModeSelect from "../TransactionModeSelect/index";

import {
  updateBookingRemarks,
  updateBookingPayment,
  getTransactionsDetails,
  generateBookingStatement,
  getActivityTypeOneDetails,
  getClubExtrasList,
} from "../../actions";

import {
  toDateFormat1,
  generateCurrencySymbol,
  checkIntOrFloat,
  toDateTimeFormat,
  validateCreditsAndPrice,
  resourcesNotLoadedToast,
} from "../../utils";

class BookingDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: "",
      showTransactionsDialog: false,
      showRemarksDiv: true,
      showRemarksTextArea: false,
      modifiedRemarks: "",
      showPayNowDialog: false,
      selectedPaymentMode: "No Pay",
      payNowAmount: "",
      showTransactionsDialog: false,
      paymentMode: "No Pay",
      selectedTransactionMode: 0,
      disableNoPay: false,
      transactionMode: 0,
      isPayNowAmountInvalid: false,
      discount: 0,
      isDiscountAmountInvalid: false,
      getInTouchDialogIsShown: false,
      getInTouchData: {},
      editBookingAmountDialogIsShown: false,
      editBookingAmountData: {},
      showExtrasDialog: false,
      showPayNowExtraDialog: false,
      extraPayNowData: "",
      extraPayNow: false,

      extraBalance: "",
      ebookingId: "",
    };
  }

  // -----------------------------------------------------------------------
  //  showTextArea()
  // -----------------------------------------------------------------------
  showTextArea = () => {
    this.setState({ showRemarksDiv: false, showRemarksTextArea: true });
  };

  // -----------------------------------------------------------------------
  //  hideTextArea()
  // -----------------------------------------------------------------------
  hideTextArea = () => {
    this.setState({ showRemarksDiv: true, showRemarksTextArea: false });
  };

  // -----------------------------------------------------------------------
  //  handleRemarksChange()
  // -----------------------------------------------------------------------
  handleRemarksChange = async (e) => {
    e.persist();
    await this.setState({ modifiedRemarks: e.target.value.toString() });
  };

  // -----------------------------------------------------------------------
  // changeTransactionMode()
  // -----------------------------------------------------------------------
  changeTransactionMode = (e) => {
    e.persist();
    if (this.state.payNowAmount.length > 0) {
      this.setState({
        paymentMode:
          e.nativeEvent.target[
            e.nativeEvent.target.selectedIndex
          ].text.toString(),
        selectedTransactionMode: parseInt(e.target.value),
        isPayNowAmountInvalid: false,
      });
    } else {
      this.setState({ isPayNowAmountInvalid: true });
    }
  };

  // -----------------------------------------------------------------------
  //  handleRemarkButton()
  // -----------------------------------------------------------------------
  handleRemarkButton = async (e, type) => {
    e.persist();
    let { slotData } = this.props;
    await this.showTextArea();
    if (type === "edit") {
      await this.setState({
        modifiedRemarks: this.props.data.remarks,
      });
      await this.showTextArea();
    }
    if (type === "add_remark") {
      await this.setState({
        modifiedRemarks: "",
      });
      await this.showTextArea();
    }
    if (type === "cancel") {
      await this.setState({
        modifiedRemarks: "",
      });
      this.hideTextArea();
    }
    if (type === "save") {
      if (this.state.modifiedRemarks.trim().length > 0) {
        await this.props.dispatch(
          updateBookingRemarks({
            bookingId: this.props.data.bookingId,
            patternBookingId: null,
            bookingRemarks: this.state.modifiedRemarks,
          })
        );
        if (this.props.updateBookingRemarksToast) {
          if (this.props.updateBookingRemarksToast.resourcesNotLoadedMessage) {
            resourcesNotLoadedToast();
          } else if (this.props.updateBookingRemarksToast.httpErrorMessage) {
            toaster.danger(
              this.props.updateBookingRemarksToast.httpErrorMessage,
              {
                id: "HTTP-ERROR-TOAST-1",
              }
            );
          } else if (
            this.props.updateBookingRemarksToast.requestStatus === -1
          ) {
            toaster.notify("Sorry, you don't have permission to do that");
          } else if (this.props.updateBookingRemarksToast.requestStatus === 1) {
            let body = {
              activityId: slotData.activityId,
              slot: slotData.slotTime,
              date: toDateFormat1(this.props.date),
              courtId: slotData.courtId,
            };
            await this.props.dispatch(getActivityTypeOneDetails(body));

            if (this.props.typeOneDetails) {
              if (this.props.typeOneDetails.resourcesNotLoadedMessage) {
                resourcesNotLoadedToast();
              } else if (this.props.typeOneDetails.httpErrorMessage) {
                toaster.danger(this.props.typeOneDetails.httpErrorMessage, {
                  id: "HTTP-ERROR-TOAST-4",
                });
              } else if (this.props.typeOneDetails.requestStatus === -1) {
                toaster.notify("Sorry, you don't have permission to do that");
              } else if (this.props.typeOneDetails.requestStatus === 0) {
                toaster.danger(this.props.typeOneDetails.message, {
                  id: "HP-TOAST",
                });
              }
              await this.hideTextArea();
              await toaster.success(
                this.props.updateBookingRemarksToast.message,
                {
                  id: "BDOCP-TOAST-1",
                }
              );
            }
          } else if (this.props.updateBookingRemarksToast.requestStatus === 0) {
            toaster.danger(this.props.updateBookingRemarksToast.message, {
              id: "BDOCP-TOAST-2",
            });
          }
        }
      } else {
        toaster.warning("Remarks can't be blank", {
          id: "BDOCP-TOAST-3",
        });
      }
    }
  };

  updateBookingDetails = async () => {
    let { slotData } = this.props;
    let body = {
      activityId: slotData.activityId,
      slot: slotData.slotTime,
      date: toDateFormat1(this.props.date),
      courtId: slotData.courtId,
    };
    await this.props.dispatch(getActivityTypeOneDetails(body));
  };
  getBookingDetails = async () => {
    let { slotData } = this.props;
    let body = {
      activityId: slotData.activityId,
      slot: slotData.slotTime,
      date: toDateFormat1(this.props.date),
      courtId: slotData.courtId,
    };
    await this.props.dispatch(getActivityTypeOneDetails(body));
  };

  // -----------------------------------------------------------------------
  //  handlePayNow()
  // -----------------------------------------------------------------------
  handlePayNow = async (e, type) => {
    let slotData = this.props.slotData;
    if (type === "show-dialog") {
      this.setState({ showPayNowDialog: true });
    }
    if (type === "hide-dialog") {
      this.setState({
        showPayNowDialog: false,
        disableNoPay: false,
        paymentMode: "No Pay",
        selectedPaymentMode: "No Pay",
        transactionMode: 0,
        selectedTransactionMode: 0,
      });
    }
    if (type === "amount-change") {
      if (validateCreditsAndPrice(e.target.value) === "set") {
        await this.setState({
          payNowAmount: e.target.value,
          isPayNowAmountInvalid: false,
        });
        if (
          this.state.selectedTransactionMode === 0 &&
          this.state.payNowAmount > 0
        ) {
          await this.setState({
            paymentMode: "Cash",
            transactionMode: 1,
            selectedTransactionMode: 1,
            disableNoPay: true,
          });
        }
        if (this.state.payNowAmount <= 0) {
          await this.setState({
            paymentMode: "No Pay",
            transactionMode: 0,
            selectedTransactionMode: 0,
            disableNoPay: false,
          });
        }
      } else if (validateCreditsAndPrice(e.target.value) === "clear") {
        await this.setState({
          payNowAmount: "",
          isPayNowAmountInvalid: false,
          paymentMode: "No Pay",
          transactionMode: 0,
          selectedTransactionMode: 0,
          disableNoPay: false,
        });
      }
    }
    if (type === "apply-discount") {
      if (!isNaN(e.target.value) && parseFloat(e.target.value) > 0) {
        await this.setState({
          discount: e.target.value,
          isDiscountAmountInvalid: false,
        });
      } else {
        await this.setState({
          isDiscountAmountInvalid: true,
        });
      }
    }
    if (type === "confirm") {
      if (this.state.payNowAmount > this.props.data.courtSummary.balance) {
        toaster.warning("Amount is greater than balance", {
          id: "BDOCP-TOAST-6",
        });
      } else if (this.state.payNowAmount === "") {
        toaster.warning("Amount is required", {
          id: "BDOCP-TOAST-6",
        });
        await this.setState({ isPayNowAmountInvalid: true });
      } else if (this.state.isDiscountAmountInvalid) {
        toaster.warning("Invalid discount amount", {
          id: "BDOCP-TOAST-6",
        });
        await this.setState({ isPayNowAmountInvalid: true });
      } else {
        const reqBody = {
          bookingId: this.props.data.bookingId,
          patternBookingId: null,
          amount: parseFloat(this.state.payNowAmount),
          discount: parseFloat(this.state.discount),
          transactionData: {
            mode: this.state.selectedTransactionMode,
            type: 3,
          },
        };
        await this.props.dispatch(updateBookingPayment(reqBody));
        if (this.props.updateBookingPaymentToast) {
          if (this.props.updateBookingPaymentToast.resourcesNotLoadedMessage) {
            resourcesNotLoadedToast();
          } else if (this.props.updateBookingPaymentToast.httpErrorMessage) {
            toaster.danger(
              this.props.updateBookingPaymentToast.httpErrorMessage,
              {
                id: "HTTP-ERROR-TOAST-1",
              }
            );
          } else if (
            this.props.updateBookingPaymentToast.requestStatus === -1
          ) {
            toaster.notify("Sorry, you don't have permission to do that");
          } else if (this.props.updateBookingPaymentToast.requestStatus === 1) {
            await this.setState({ payNowAmount: 0 });
            await this.setState({ discount: 0 });
            await this.setState({ showPayNowDialog: false });
            if (this.props.updateSearchPage) {
              await this.props.updateSearchPage();
            }
            let body = {
              activityId: slotData.activityId,
              slot: slotData.slotTime,
              date: toDateFormat1(this.props.date),
              courtId: slotData.courtId,
            };
            await this.props.dispatch(getActivityTypeOneDetails(body));
            if (this.props.handleShowAvailability) {
              await this.props.handleShowAvailability();
            }
            toaster.success(this.props.updateBookingPaymentToast.message);
          } else if (this.props.updateBookingPaymentToast.requestStatus === 0) {
            // await this.setState({ showPayNowDialog: false });
            toaster.danger(this.props.updateBookingPaymentToast.message, {
              id: "BDOCP-TOAST-5",
            });
          }
        }
      }
    }
  };

  // -----------------------------------------------------------------------
  //  showGetInTouchDialog()
  // -----------------------------------------------------------------------
  showGetInTouchDialog = async (status, getInTouchData = {}) => {
    await this.setState({
      getInTouchDialogIsShown: status,
      getInTouchData,
    });
  };

  // -----------------------------------------------------------------------
  //  showGetInTouchDialog()
  // -----------------------------------------------------------------------
  updateGetInTouchVenueMobile = async (e) => {
    await this.setState({
      getInTouchData: {
        ...this.state.getInTouchData,
        venueMobile: e.target.value,
      },
    });
  };

  // -----------------------------------------------------------------------
  //  getInTouchViaMobile()
  // -----------------------------------------------------------------------
  getInTouchViaMobile = async () => {
    try {
      const getInTouch = await api.getInTouchViaMobile(
        this.state.getInTouchData
      );
      await this.showGetInTouchDialog(false);
      if (getInTouch.status === 200) {
        if (getInTouch.body["requestStatus"]) {
          toaster.success("You will receive a call shortly", {
            id: "get-in-touch",
          });
        } else {
          toaster.warning(getInTouch.body["message"], {
            id: "get-in-touch",
          });
        }
      } else {
        toaster.warning("Something went wrong, please contact Playo", {
          id: "send-payment-link",
        });
      }
    } catch (e) {
      toaster.warning("Something went wrong, please contact Playo", {
        id: "send-payment-link",
      });
    }
  };

  // -----------------------------------------------------------------------
  //  handleTransactionsDialog()
  // -----------------------------------------------------------------------
  handleTransactionsDialog = async (type) => {
    if (type === "hide") {
      await this.setState({ showTransactionsDialog: false });
    }
    if (type === "show") {
      await this.props.dispatch(
        getTransactionsDetails(this.props.data.bookingId)
      );
      if (this.props.transactionsDetails) {
        if (this.props.transactionsDetails.resourcesNotLoadedMessage) {
          resourcesNotLoadedToast();
        } else if (this.props.transactionsDetails.httpErrorMessage) {
          toaster.danger(this.props.transactionsDetails.httpErrorMessage, {
            id: "HTTP-ERROR-TOAST-1",
          });
        } else if (this.props.transactionsDetails.requestStatus === -1) {
          toaster.notify("Sorry, you don't have permission to do that");
        } else if (this.props.transactionsDetails.requestStatus === 1) {
          await this.setState({ showTransactionsDialog: true });
        } else if (this.props.transactionsDetails.requestStatus === 0) {
          toaster.danger(this.props.transactionsDetails.message);
        }
      }
    }
  };

  // -----------------------------------------------------------------------
  //  generateStatement()
  // -----------------------------------------------------------------------
  generateStatement = async (bookingId) => {
    let reqParams = { bookingId: bookingId };
    await this.props.dispatch(generateBookingStatement(reqParams));
    if (this.props.generateBookingStatementToast) {
      if (this.props.generateBookingStatementToast.resourcesNotLoadedMessage) {
        resourcesNotLoadedToast();
      } else if (this.props.generateBookingStatementToast.httpErrorMessage) {
        toaster.danger(
          this.props.generateBookingStatementToast.httpErrorMessage,
          {
            id: "HTTP-ERROR-TOAST-1",
          }
        );
      } else if (
        this.props.generateBookingStatementToast.requestStatus === -1
      ) {
        toaster.notify("Sorry, you don't have permission to do that");
      } else if (this.props.generateBookingStatementToast.requestStatus === 1) {
        // toaster.success(this.props.generateBookingStatementToast.message);
      } else if (this.props.generateBookingStatementToast.requestStatus === 0) {
        toaster.danger(this.props.generateBookingStatementToast.message);
      }
    }
  };

  // -----------------------------------------------------------------------
  // handleExtrasDialog()
  // -----------------------------------------------------------------------

  handleExtrasDialog = async (type) => {
    let body = {
      activityId: this.props.slotData.activityId,
    };
    if (type === "open") {
      //this.props.dispatch({ type: "SET_CART_DATA", payload: null });
      this.props.dispatch(getClubExtrasList(body));
      this.setState({ showExtrasDialog: true });
    } else if (type === "close") {
      let body = {
        activityId: this.props.slotData.activityId,
        slot: this.props.slotData.slotTime,
        date: toDateFormat1(this.props.date),
        courtId: this.props.slotData.courtId,
      };
      await this.props.dispatch(getActivityTypeOneDetails(body, true));
      // await this.props.updateBookingDetails(
      // 	this.props.data.bookingId
      // );
      await this.props.dispatch({ type: "SET_CUSTOMER_DETAILS", payload: {} });
      this.setState({ showExtrasDialog: false });
    }
  };

  // -----------------------------------------------------------------------
  //  handleExtraPayNow
  // -----------------------------------------------------------------------

  handleExtraPayNow = async (type) => {
    if (type === "open") {
      this.setState({
        extraPayNow: true,
        extraPayNowData: this.props.data.extras,
      });
    } else {
      let body = {
        activityId: this.props.slotData.activityId,
        slot: this.props.slotData.slotTime,
        date: toDateFormat1(this.props.date),
        courtId: this.props.slotData.courtId,
      };
      await this.props.dispatch(getActivityTypeOneDetails(body, true));
      this.setState({
        extraPayNow: false,
        extraPayNowData: "",
      });
    }
  };

  showEditBookingAmountDialog = async (status, editBookingAmountData = {}) => {
    await this.setState({
      editBookingAmountDialogIsShown: status,
      editBookingAmountData,
    });
  };

  updateEditBookingAmountData = async (e) => {
    await this.setState({
      editBookingAmountData: {
        ...this.state.editBookingAmountData,
        amount: parseFloat(e.target.value),
      },
    });
  };

  editBookingAmount = async () => {
    let { slotData } = this.props;
    try {
      const editAmount = await api.editBookingAmount(
        this.state.editBookingAmountData
      );
      if (editAmount.status === 200) {
        if (editAmount.body["requestStatus"]) {
          toaster.success("Updated Booking Amount", {
            id: "get-in-touch",
          });
          await this.showEditBookingAmountDialog(false);
          let body = {
            activityId: slotData.activityId,
            slot: slotData.slotTime,
            date: toDateFormat1(this.props.date),
            courtId: slotData.courtId,
          };
          await this.props.dispatch(getActivityTypeOneDetails(body));
        } else {
          toaster.warning(editAmount.body["message"], {
            id: "get-in-touch",
          });
        }
      } else {
        toaster.warning("Something went wrong, please contact Playo", {
          id: "send-payment-link",
        });
      }
    } catch (e) {
      toaster.warning("Something went wrong, please contact Playo", {
        id: "send-payment-link",
      });
    }
  };

  // -----------------------------------------------------------------------
  //  render
  // -----------------------------------------------------------------------
  render() {
    const { data, transactionsDetails, updateCalendarPage } = this.props;
    let {
      showTransactionsDialog,
      showRemarksDiv,
      showRemarksTextArea,
      modifiedRemarks,
      showPayNowDialog,

      extraPayNowData,
      selectedTransactionMode,
      disableNoPay,
      isPayNowAmountInvalid,
      isDiscountAmountInvalid,
      getInTouchDialogIsShown,
      showExtrasDialog,
      editBookingAmountDialogIsShown,
      editBookingAmountData,
    } = this.state;
    let extras = data.extras ? data.extras : [];
    return (
      <Pane height={"auto"} width={368} paddingBottom={0}>
        <Pane borderRadius={5} background={"white"}>
          <Pane padding={8} display={"flex"} flexDirection={"column"}>
            <Pane
              display={"flex"}
              justifyContent={"space-between"}
              flexDirection={"row"}
            >
              <Badge marginTop={5} color="blue" isSolid>
                {data.activityName}
              </Badge>

              <Button
                className="border-button"
                height={32}
                minWidth={100}
                appearance="minimal"
                onClick={() => this.generateStatement(data.bookingId)}
              >
                <Strong color="#0679c5" size={300}>
                  Statements
                </Strong>
              </Button>
            </Pane>
            {data.isPlayoBooking && (
              <Badge
                background={"#00B562"}
                display="flex"
                alignItems={"center"}
                flexDirection="row"
                isSolid
                color="white"
                width="113px"
              >
                Playo Booking
              </Badge>
            )}
            <Pane marginTop={10} display={"flex"} flexDirection={"row"}>
              <Pane display="flex" flexDirection={"column"}>
                <Strong color="muted" size={300}>
                  BOOKING ID
                </Strong>
                <Strong color="black">{data.bookingId}</Strong>
              </Pane>
              <Pane marginLeft={120} display="flex" flexDirection={"column"}>
                <Strong color="muted" size={300}>
                  TYPE
                </Strong>
                <Strong color="black">{data.bookingType}</Strong>
              </Pane>
            </Pane>
            <Pane
              marginTop={10}
              display={"flex"}
              justifyContent={"space-between"}
              flexDirection={"row"}
            >
              <Pane display="flex" flexDirection={"column"}>
                <Text id="RBDDC-A1-type-three-label">
                  {`${data.courts} | ${data.slotCount} slot(s)`}
                </Text>
                <Text id="RBDDC-A1-type-two-label" marginTop={12}>
                  {toDateTimeFormat(
                    data.dateBooked,
                    data.startTime,
                    data.endTime
                  )}
                </Text>
              </Pane>
            </Pane>
          </Pane>
          <Menu.Divider />
          <Pane padding={8}>
            <Pane marginTop={10} display={"flex"} flexDirection={"row"}>
              <Pane display="flex" flexDirection={"column"}>
                <Strong color="muted" size={300}>
                  NAME
                </Strong>
                <Strong color="black">
                  {data && data.customerName ? data.customerName : "None"}
                </Strong>
              </Pane>
              <Pane display="flex" marginLeft={105} flexDirection={"column"}>
                {data.customerCountryCode === "+91" && data.isPlayoBooking ? (
                  <Button
                    className="border-button"
                    height={32}
                    minWidth={100}
                    appearance="minimal"
                    onClick={() =>
                      this.showGetInTouchDialog(true, {
                        bookingId: data.bookingId,
                      })
                    }
                  >
                    Get in Touch
                  </Button>
                ) : (
                  <Pane display="flex" flexDirection={"column"}>
                    <Strong color="muted" size={300}>
                      PHONE NUMBER
                    </Strong>
                    <Strong color="black">
                      {data && data.customerMobile
                        ? data.customerMobile
                        : "None"}
                    </Strong>{" "}
                  </Pane>
                )}
              </Pane>
            </Pane>
            <Pane
              marginTop={10}
              display={"flex"}
              justifyContent={"space-between"}
              flexDirection={"row"}
            >
              <Pane display="flex" flexDirection={"column"}>
                <Strong color="muted" size={300}>
                  EMAIL ID
                </Strong>
                <Strong color="black">
                  {" "}
                  {data && data.customerEmail ? data.customerEmail : "None"}
                </Strong>
              </Pane>
            </Pane>
          </Pane>
          <Menu.Divider />

          <Pane padding={12}>
            <Pane
              display={"flex"}
              justifyContent={"space-between"}
              flexDirection={"row"}
            >
              <Strong color="muted" size={500} marginTop={8}>
                Payment Details
              </Strong>

              <Button
                className="border-button"
                height={32}
                minWidth={100}
                appearance="minimal"
                onClick={() => this.handleTransactionsDialog("show")}
              >
                <Strong color="#0679c5" size={300}>
                  Transaction
                </Strong>
              </Button>
            </Pane>
            <Pane
              marginTop={15}
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"flex-start"}
            >
              <Pane display="flex" flexDirection={"column"} width={164}>
                <Strong color="muted" size={300}>
                  Gross
                </Strong>
                <Strong color="black">
                  {`${generateCurrencySymbol()} ${
                    data && checkIntOrFloat(data.summary.gross)
                  }`}
                </Strong>
              </Pane>
              <Pane display="flex" flexDirection={"column"}>
                <Strong color="muted" size={300}>
                  Discount
                </Strong>
                <Strong color="black">
                  {`${generateCurrencySymbol()} ${
                    data && checkIntOrFloat(data.summary.discount)
                  }`}
                </Strong>
              </Pane>
            </Pane>

            <Pane padding={5}>
              <Pane
                marginTop={15}
                display={"flex"}
                justifyContent="flex-start"
                flexDirection={"row"}
              >
                <CaretRightIcon color="muted" marginRight={3} />
                <Pane display="flex" width={200} flexDirection={"column"}>
                  <Text color="muted" size={300}>
                    Court
                  </Text>
                  <Strong color="muted" width={80}>
                    {`${generateCurrencySymbol()} ${
                      data && checkIntOrFloat(data.courtSummary.gross)
                    }`}
                  </Strong>
                </Pane>
                <Pane display="flex" flexDirection={"column"}>
                  <Text color="muted" size={300}>
                    Discount
                  </Text>
                  <Strong color="muted">
                    {`${generateCurrencySymbol()} ${
                      data && checkIntOrFloat(data.courtSummary.discount)
                    }`}
                  </Strong>
                </Pane>

                <Button
                  className="border-button"
                  height={32}
                  marginLeft={24}
                  minWidth={70}
                  appearance="minimal"
                  onClick={(e) => this.handlePayNow(e, "show-dialog")}
                >
                  <Strong color="#0679c5" size={300}>
                    Pay Now
                  </Strong>
                </Button>
                <EditIcon
                  cursor={"pointer"}
                  onClick={() =>
                    this.showEditBookingAmountDialog(true, {
                      bookingId: data["bookingId"],
                    })
                  }
                  marginLeft={5}
                  size={30}
                  appearance={"minimal"}
                />
              </Pane>
              {data.extras && (
                <Pane
                  marginTop={15}
                  display={"flex"}
                  justifyContent="flex-start"
                  flexDirection={"row"}
                >
                  <CaretRightIcon color="muted" marginRight={3} />
                  <Pane display="flex" width={200} flexDirection={"column"}>
                    <Text color="muted" size={300}>
                      Add On
                    </Text>
                    <Strong color="muted">
                      {`${generateCurrencySymbol()} ${
                        data && checkIntOrFloat(data.extrasSummary.gross)
                      }`}
                    </Strong>
                  </Pane>
                  <Pane display="flex" flexDirection={"column"}>
                    <Text color="muted" size={300}>
                      Discount
                    </Text>
                    <Strong color="muted">
                      {`${generateCurrencySymbol()} ${
                        data && checkIntOrFloat(data.extrasSummary.discount)
                      }`}
                    </Strong>
                  </Pane>

                  <Button
                    className="border-button"
                    height={32}
                    marginLeft={24}
                    minWidth={70}
                    appearance="minimal"
                    onClick={(e) => this.handleExtraPayNow("open")}
                  >
                    <Strong color="#0679c5" size={300}>
                      Pay Now
                    </Strong>
                  </Button>

                  <IconButton
                    onClick={() => this.handleExtrasDialog("open")}
                    marginLeft={5}
                    icon={<AddIcon cursor={"pointer"} />}
                    appearance={"minimal"}
                  />
                </Pane>
              )}
            </Pane>
            <Menu.Divider />
            <Pane
              marginTop={15}
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"flex-start"}
            >
              <Pane display="flex" flexDirection={"column"} width={164}>
                <Strong color="muted" size={300}>
                  Collected
                </Strong>
                <Strong color="black">
                  {`${generateCurrencySymbol()} ${
                    data && checkIntOrFloat(data.summary.advance)
                  }`}
                </Strong>
              </Pane>
              <Pane display="flex" flexDirection={"column"}>
                <Strong color="muted" size={300}>
                  Balance Due
                </Strong>
                <Strong color="black">
                  {`${generateCurrencySymbol()} ${
                    data && checkIntOrFloat(data.summary.balance)
                  }`}
                </Strong>
              </Pane>
            </Pane>
          </Pane>
          {!data.extras && <Menu.Divider />}
          {!data.extras && (
            <Pane
              marginTop={10}
              marginBottom={10}
              display={"flex"}
              justifyContent="center"
              flexDirection={"row"}
            >
              <Button
                appearance="minimal"
                className={"border-button"}
                padding={5}
                marginLeft={5}
                marginTOp={8}
                width={98}
                onClick={() => this.handleExtrasDialog("open")}
              >
                <Strong size={300} color={"#0679c5"}>
                  Add Addon
                </Strong>
              </Button>
            </Pane>
          )}
          <Menu.Divider />

          <Pane display="flex" padding={8} height={10} justifyContent="center">
            {showRemarksTextArea ||
            (data.remarks && data.remarks.trim().length > 0) ? null : (
              <Button
                className="border-button"
                height={32}
                minWidth={70}
                marginTop={10}
                appearance="minimal"
                onClick={(e) => this.handleRemarkButton(e, "add_remark")}
              >
                <Strong color="#0679c5" padding={10} size={300}>
                  Add Remarks
                </Strong>
              </Button>
            )}
          </Pane>
          <Pane display="flex" height={220} padding={8}>
            {showRemarksTextArea && (
              <Pane
                display={"flex"}
                flexDirection="column"
                justifyContent="flex-start"
              >
                <Pane display={"flex"} flexDirection="row">
                  <Textarea
                    className="RBDDC-A1-text-area-input"
                    style={{ resize: "none" }}
                    width={330}
                    height={72}
                    placeholder={"Write your Remarks here."}
                    maxLength="100"
                    value={modifiedRemarks}
                    onChange={(e) => this.handleRemarksChange(e)}
                  />
                </Pane>
                <Pane
                  display={"flex"}
                  flexDirection="row"
                  justifyContent="flex-end"
                >
                  <Button
                    className="RBDDC-A1-btn"
                    appearance="minimal"
                    width={88}
                    fontWeight={501}
                    height={32}
                    marginRight={8}
                    onClick={(e) => this.handleRemarkButton(e, "cancel")}
                  >
                    <CrossIcon
                      appearance="minimal"
                      color="muted"
                      marginRight={2}
                    />
                    <Text marginTop={2} color={"muted"}>
                      Cancel
                    </Text>
                  </Button>
                  <Button
                    className="RBDDC-A1-btn"
                    appearance="minimal"
                    width={50}
                    fontWeight={501}
                    height={32}
                    iconBefore={<FloppyDiskIcon />}
                    onClick={(e) => this.handleRemarkButton(e, "save")}
                  >
                    Save
                  </Button>
                </Pane>
              </Pane>
            )}
            {showRemarksDiv && (
              <Pane display="flex" flexDirection="column">
                <Pane display="flex" flexDirection="row">
                  <Paragraph id="RBDDC-A1-type-four-label">
                    {data.remarks && data.remarks.trim().length > 0
                      ? data.remarks
                      : null}
                  </Paragraph>
                </Pane>
                <Pane display="flex" flexDirection="row">
                  {data.remarks && data.remarks.trim().length > 0 && (
                    <Button
                      justifyContent="center"
                      className="border-button"
                      height={32}
                      minWidth={70}
                      marginTop={10}
                      appearance="minimal"
                      onClick={(e) => this.handleRemarkButton(e, "edit")}
                    >
                      <Strong color="#0679c5" padding={10} size={300}>
                        Edit Remarks
                      </Strong>
                    </Button>
                  )}
                </Pane>
              </Pane>
            )}
          </Pane>
        </Pane>
        <Pane display="flex" flexDirection="row" justifyContent="center">
          <BookingDetailsDrawerActions
            showPaymentLink={data.showPaymentLink}
            balance={data.paymentPending}
            bookingId={data.bookingId}
            courts={data.courtName}
            dateBooked={data.dateBooked}
            updateCalendarPage={updateCalendarPage}
            startTime={data.startTime}
            endTime={data.endTime}
            bookingCancelled={data.bookingCancelled}
            updateBookingDetails={this.updateBookingDetails}
            handleGetBookingDetails={this.handleGetBookingDetails}
            playoBooking={data.isPlayoBooking}
            type1={true}
          />
        </Pane>

        {/********************* dialogs ***************************/}

        <Dialog
          isShown={showExtrasDialog}
          width={600}
          padding={10}
          hasFooter={false}
          onCloseComplete={() => this.handleExtrasDialog("close")}
          hasHeader={false}
        >
          <Pane
            marginBottom={7}
            display={"flex"}
            flexDirection={"row"}
            justifyContent="space-between"
            alignItems={"center"}
          >
            <Strong size={400}>Add Addons</Strong>
            <IconButton
              appearance="minimal"
              icon={<CrossIcon />}
              onClick={() => this.handleExtrasDialog("close")}
            />
          </Pane>
          <Menu.Divider />
          <ExtrasActions
            mobile={data.customerMobile}
            bookingId={data.bookingId}
          />
        </Dialog>

        <Dialog
          isShown={getInTouchDialogIsShown}
          onCloseComplete={() => this.showGetInTouchDialog(false)}
          title={"Get in Touch"}
          onConfirm={this.getInTouchViaMobile}
        >
          <TextInputField
            label={"Enter venue phone number"}
            onChange={(e) => this.updateGetInTouchVenueMobile(e)}
          />
        </Dialog>

        {/* <Dialog
					isShown={editBookingAmountDialogIsShown}
					onCloseComplete={() => this.showEditBookingAmountDialog(false)}
					title={"Edit Amount"}
					onConfirm={this.editBookingAmount}
				>
					<TextInputField
						label={`Enter amount in ${generateCurrencySymbol()}`}
						onChange={(e) => this.updateEditBookingAmountData(e)}
					/>
				</Dialog> */}
        <Dialog
          width={450}
          isShown={editBookingAmountDialogIsShown}
          onCloseComplete={() => this.showEditBookingAmountDialog(false)}
          title={"Edit (Court Price)"}
          hasFooter={false}
          hasHeader={false}
        >
          <Pane
            marginBottom={7}
            display={"flex"}
            flexDirection={"row"}
            justifyContent="space-between"
          >
            <Strong size={400}>Edit Amount</Strong>
            <CrossIcon
              appearance="minimal"
              onClick={() => this.showEditBookingAmountDialog(false)}
            />
          </Pane>
          <Menu.Divider />
          <Pane
            display={"flex"}
            flexDirection="row"
            justifyContent="flex-start"
            marginBottom={5}
            marginTop={10}
          >
            <TextInputField
              width={250}
              label={"Court Price"}
              onChange={(e) => this.updateEditBookingAmountData(e)}
            />
          </Pane>
          <div className="RBDDC-A1-pay-now-last-row">
            {/* <Button
								className="RBDDC-A1-btn"
								marginRight={12}
								width={72}
								height={32}
								onClick={(e) => this.handlePayNow(e, "hide-dialog")}
							>
								Cancel
							</Button> */}
            <Button
              className="RBDDC-A1-btn"
              width={80}
              height={32}
              appearance="primary"
              intent="success"
              onClick={this.editBookingAmount}
            >
              Done
            </Button>
          </div>
        </Dialog>

        <Dialog
          width={450}
          isShown={showPayNowDialog}
          title="Pay Now"
          hasFooter={false}
          hasHeader={false}
          onCloseComplete={(e) => this.handlePayNow(e, "hide-dialog")}
        >
          <Pane
            marginBottom={7}
            display={"flex"}
            flexDirection={"row"}
            justifyContent="space-between"
            alignItems={"center"}
          >
            <Strong size={400}>Pay Now (Court Price)</Strong>
            <IconButton
              icon={<CrossIcon />}
              appearance="minimal"
              onClick={(e) => this.handlePayNow(e, "hide-dialog")}
            />
          </Pane>

          <Menu.Divider />

          <Pane
            display={"flex"}
            flexDirection="row"
            justifyContent="flex-start"
            marginBottom={5}
            marginTop={10}
          >
            <TextInputField
              width={150}
              label="Collect Amount"
              height={32}
              name="pay-amount-input"
              placeholder={`${generateCurrencySymbol()} 0`}
              onChange={(e) => this.handlePayNow(e, "amount-change")}
              isInvalid={isPayNowAmountInvalid}
            />
            <TextInputField
              marginLeft={20}
              maxWidth={130}
              height={32}
              label="Discount"
              name="apply-discount-input"
              placeholder={`${generateCurrencySymbol()} 0`}
              onChange={(e) => this.handlePayNow(e, "apply-discount")}
              isInvalid={isDiscountAmountInvalid}
            />
            <Pane marginLeft={20} marginTop={23}>
              <TransactionModeSelect
                disableNoPay={disableNoPay}
                selectedTransactionMode={selectedTransactionMode}
                changeTransactionMode={this.changeTransactionMode}
              />
            </Pane>
          </Pane>
          <Pane
            display={"flex"}
            flexDirection={"row"}
            marginTop={10}
            marginBottom={10}
          >
            <Text id="RBDDC-A1-type-five-label" marginRight={10}>
              Balance Amount
            </Text>
            <Text id="RBDDC-A1-type-six-label">
              {`${generateCurrencySymbol()} ${
                data && checkIntOrFloat(data.courtSummary.balance)
              }`}
            </Text>
          </Pane>
          <div className="RBDDC-A1-pay-now-last-row">
            {/* <Button
								className="RBDDC-A1-btn"
								marginRight={12}
								width={72}
								height={32}
								onClick={(e) => this.handlePayNow(e, "hide-dialog")}
							>
								Cancel
							</Button> */}
            <Button
              className="RBDDC-A1-btn"
              width={88}
              height={32}
              appearance="primary"
              intent="success"
              onClick={(e) => this.handlePayNow(e, "confirm")}
            >
              Confirm
            </Button>
          </div>
        </Dialog>
        <ExtraPayNow
          handleExtraPayNow={this.handleExtraPayNow}
          extraPayNow={this.state.extraPayNow}
          bookingId={this.props.data.bookingId}
          extraPayNowData={this.state.extraPayNowData}
        />
        <Dialog
          width="fit-content"
          isShown={showTransactionsDialog}
          hasFooter={false}
          hasHeader={false}
          onCloseComplete={() => this.handleTransactionsDialog("hide")}
          height={"fit-content"}
        >
          <Pane
            marginBottom={7}
            display={"flex"}
            flexDirection={"row"}
            justifyContent="space-between"
          >
            <Strong size={400}>
              Transactions (Booking Id {data.bookingId})
            </Strong>
            <IconButton
              icon={"cross"}
              appearance="minimal"
              onClick={() => this.handleTransactionsDialog("hide")}
            />
          </Pane>
          <Menu.Divider />
          <TransactionsDialogContent
            transactionsDetails={transactionsDetails}
          />
        </Dialog>
      </Pane>
    );
  }
}

const mapStateToProps = ({
  updateBookingRemarksToast,
  typeOneDetails,
  transactionsDetails,
  updateBookingPaymentToast,
}) => ({
  updateBookingRemarksToast,
  typeOneDetails,
  transactionsDetails,
  updateBookingPaymentToast,
});

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default connect(mapStateToProps)(BookingDetails);
