// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import Header from "../../components/Header";
import GenerateCouponsForm from "../../components/GenerateCouponsForm";
import EditCouponsForm from "../../components/EditCouponsForm";
import {
  AddIcon,
  Button,
  Dialog,
  InlineAlert,
  SearchInput,
  Text,
  toaster,
} from "evergreen-ui";
import CustomInlineAlert from "../../components/CustomInlineAlert";
import { resourcesNotLoadedToast } from "../../utils";
import validator from "validator";
import {
  getCouponCodes,
  getCouponDetails,
  togglePremiumFeatureFlag,
} from "../../actions";
import { connect } from "react-redux";
import CouponTable from "./CouponTable";
import CouponCodesTable from "./CouponCodesTable";

// -----------------------------------------------------------------------
// GenerateCoupons Component
// -----------------------------------------------------------------------
class GenerateCoupons extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSearchStringInvalid: false,
      isSearchBtnDisabled: false,
      page: 1,
      searchString: "",
      showCouponCodesDialog: false,
      showGenerateCouponsDialog: false,
      showEditCouponDetailsDialog: false,
      coupon: {
        activities: [],
        days: [],
      },
      couponDetailId: null,
    };
  }

  // -----------------------------------------------------------------------
  // handleSearchInputChange()
  // -----------------------------------------------------------------------
  handleSearchInputChange = (e) => {
    this.setState({
      pageNum: 1,
      searchString: e.target.value.toString(),
      isSearchStringInvalid: false,
    });
  };

  // -----------------------------------------------------------------------
  // handleSearchInputKeyUp()
  // -----------------------------------------------------------------------
  handleSearchInputKeyUp = async (e) => {
    e.persist();
    if (e.key === "Enter" && e.target.value.length > 0) {
      await this.searchMemberDetails(e);
    }
  };

  // -----------------------------------------------------------------------
  // scrollToElement()
  // -----------------------------------------------------------------------
  scrollToElement = async (elem) => {
    await this.refs[elem].scrollIntoView({ block: "end", behavior: "smooth" });
  };

  // -----------------------------------------------------------------------
  // handleMemberDetailsResponse()
  // -----------------------------------------------------------------------
  handleCouponDetailsResponse = async (reqParams) => {
    await this.props.dispatch(getCouponDetails(reqParams));
    if (this.props.couponDetails) {
      if (this.props.couponDetails.resourcesNotLoadedMessage) {
        resourcesNotLoadedToast();
      } else if (
        this.props.couponDetails.httpErrorMessage &&
        this.props.couponDetails.statusCode === 404
      ) {
        toaster.danger(this.props.couponDetails.httpErrorMessage, {
          id: "HTTP-ERROR-TOAST-1",
        });
        await this.setState({ isSearchBtnDisabled: true });
        setTimeout(() => {
          this.setState({ isSearchBtnDisabled: false });
        }, 3000);
      } else if (this.props.couponDetails.httpErrorMessage) {
        toaster.danger(this.props.couponDetails.httpErrorMessage, {
          id: "HTTP-ERROR-TOAST-4",
        });
      } else if (this.props.couponDetails.requestStatus === -1) {
        toaster.notify("Sorry, you don't have permission to do that");
      }
    }
  };

  // -----------------------------------------------------------------------
  // searchMemberDetails()
  // -----------------------------------------------------------------------
  searchCouponDetails = async (e) => {
    e.persist();
    if (this.state.searchString.length > 0) {
      if (validator.isUppercase(this.state.searchString)) {
        let reqParams = {
          couponId: this.state.searchString,
          page: this.state.page,
        };
        await this.handleCouponDetailsResponse(reqParams);
      } else {
        await this.setState({ isSearchStringInvalid: true });
        await this.scrollToElement("isSearchStringInvalid");
      }
    } else {
      await this.setState({ pageNum: 1 });
      let reqParams = {
        page: this.state.pageNum,
      };
      await this.handleCouponDetailsResponse(reqParams);
    }
  };

  // -----------------------------------------------------------------------
  // handlePageChange()
  // -----------------------------------------------------------------------
  handlePageChange = async (newPageNum) => {
    await this.setState({ page: newPageNum });
    let reqParams = {
      page: this.state.page,
      couponId: this.state.searchString,
    };
    await this.handleCouponDetailsResponse(reqParams);
  };

  // -----------------------------------------------------------------------
  // toggleCouponCodesDialogVisibility()
  // -----------------------------------------------------------------------
  toggleCouponCodesDialogVisibility = async (type) => {
    if (type === "open") {
      this.setState({ showCouponCodesDialog: true });
    }
    if (type === "close") {
      await this.setState({ showCouponCodesDialog: false });
    }
  };

  // -----------------------------------------------------------------------
  // toggleGenerateCouponsDialogVisibility()
  // -----------------------------------------------------------------------
  toggleGenerateCouponsDialogVisibility = async (type) => {
    if (type === "open") {
      this.setState({ showGenerateCouponsDialog: true });
    }
    if (type === "close") {
      await this.setState({ showGenerateCouponsDialog: false });
    }
  };

  // -----------------------------------------------------------------------
  // toggleGenerateCouponsDialogVisibility()
  // -----------------------------------------------------------------------
  toggleEditCouponDetailsDialogVisibility = async (type) => {
    if (type === "open") {
      this.setState({ showEditCouponDetailsDialog: true });
    }
    if (type === "close") {
      await this.setState({ showEditCouponDetailsDialog: false });
    }
  };

  // -----------------------------------------------------------------------
  // handleCouponCodesDialog()
  // -----------------------------------------------------------------------
  handleCouponCodesDialog = async (e, couponDetailId) => {
    await this.setState({ couponDetailId });
    await this.props.dispatch(getCouponCodes({ id: couponDetailId }));
    if (this.props.getCouponCodes) {
      if (this.props.getCouponCodes.resourcesNotLoadedMessage) {
        resourcesNotLoadedToast();
      } else if (
        this.props.getCouponCodes.httpErrorMessage &&
        this.props.getCouponCodes.statusCode === 404
      ) {
        toaster.danger(this.props.getCouponCodes.httpErrorMessage, {
          id: "HTTP-ERROR-TOAST-1",
        });
      } else if (this.props.getCouponCodes.httpErrorMessage) {
        toaster.danger(this.props.getCouponCodes.httpErrorMessage, {
          id: "HTTP-ERROR-TOAST-4",
        });
      } else if (this.props.getCouponCodes.requestStatus === -1) {
        toaster.notify("Sorry, you don't have permission to do that");
      }
    }
    await this.toggleCouponCodesDialogVisibility("open");
  };

  // -----------------------------------------------------------------------
  // handleEditCouponDetailsDialog()
  // -----------------------------------------------------------------------
  handleEditCouponDetailsDialog = async (coupon) => {
    await this.setState({ coupon: coupon });
    await this.toggleEditCouponDetailsDialogVisibility("open");
  };

  // -----------------------------------------------------------------------
  // render()
  // -----------------------------------------------------------------------
  render() {
    const {
      isSearchStringInvalid,
      isSearchBtnDisabled,
      page,
      showCouponCodesDialog,
      showGenerateCouponsDialog,
      showEditCouponDetailsDialog,
      coupon,
      couponDetailId,
    } = this.state;

    const { couponDetails, couponCodes } = this.props;

    return (
      <React.Fragment>
        <Header headerText="Coupons" />
        <div className="MP-A1-action-bar">
          <div className="MP-A1-col-container">
            <div className="MP-A1-label-container MP-A1-margin-bottom-8">
              <Text id="MP-A1-label-type-one">Find Coupons</Text>
            </div>
            <div className="MP-A1-row-container MP-A1-align-items-center">
              <SearchInput
                placeholder="Search coupon code"
                type="text"
                width={328}
                height={32}
                marginRight={8}
                isInvalid={isSearchStringInvalid}
                onChange={(e) => this.handleSearchInputChange(e)}
                onKeyUp={(e) => this.handleSearchInputKeyUp(e)}
              />
              <Button
                className="MP-A1-button"
                appearance="primary"
                minWidth={88}
                height={32}
                marginRight={24}
                onClick={(e) => this.searchCouponDetails(e)}
                disabled={isSearchBtnDisabled}
              >
                Search
              </Button>
              <div className="MP-A1-vertical-line-decorator" />
              <Button
                className="MP-A1-button"
                iconBefore={<AddIcon />}
                appearance="primary"
                intent="warning"
                minWidth={149}
                height={32}
                marginLeft={24}
                marginRight={24}
                onClick={() =>
                  this.toggleGenerateCouponsDialogVisibility("open")
                }
              >
                Generate Coupons
              </Button>
            </div>
            {isSearchStringInvalid && (
              <div
                className="MP-A1-custom-inline-alert-container"
                ref={"isSearchStringInvalid"}
              >
                <CustomInlineAlert
                  intent="danger"
                  alertMsg="Invalid coupon code"
                />
              </div>
            )}
          </div>
        </div>
        {couponDetails &&
        couponDetails.data &&
        couponDetails.data.length &&
        couponDetails.requestStatus === 1 ? (
          <CouponTable
            couponDetails={couponDetails}
            currentPage={page}
            handlePageChange={this.handlePageChange}
            handleCouponCodesDialog={this.handleCouponCodesDialog}
            handleEditCouponDetailsDialog={this.handleEditCouponDetailsDialog}
          />
        ) : (
          <div className="MP-A1-message-container">
            <InlineAlert intent="warning" marginLeft={16} marginRight={4}>
              No Matching Results.
            </InlineAlert>
          </div>
        )}
        <Dialog
          isShown={showCouponCodesDialog}
          title="Coupon Codes"
          onCloseComplete={() =>
            this.toggleCouponCodesDialogVisibility("close")
          }
          hasFooter={false}
          display={"flex"}
          justifyContent={"center"}
        >
          <CouponCodesTable
            couponCodes={couponCodes}
            couponDetailId={couponDetailId}
          />
        </Dialog>
        <GenerateCouponsForm
          showGenerateCouponsDialog={showGenerateCouponsDialog}
          toggleGenerateCouponsDialogVisibility={
            this.toggleGenerateCouponsDialogVisibility
          }
        />
        <EditCouponsForm
          showEditCouponDetailsDialog={showEditCouponDetailsDialog}
          toggleGenerateCouponsDialogVisibility={
            this.toggleEditCouponDetailsDialogVisibility
          }
          coupon={coupon}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ couponDetails, couponCodes }) => ({
  couponDetails,
  couponCodes,
});

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default connect(mapStateToProps)(GenerateCoupons);
