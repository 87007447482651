const activityList = (state = null, action) => {
  if (action.type === "SET_CLUB_ACTIVITY_DATA") {
    return action.payload;
  } else {
    return state;
  }
  // switch (action.type) {
  //   case "SET_CLUB_ACTIVITY_DATA": {
  //     return action.payload;
  //   }

  //   default: {
  //     return state;
  //   }
  // }
};

export default activityList;
