// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import { Text, Button, ArrowRightIcon } from "evergreen-ui";
import { generateCurrencySymbol } from "../../utils";
import { ArrowRight } from "@material-ui/icons";

// -----------------------------------------------------------------------
// DataBox Component
// -----------------------------------------------------------------------
const DataBox = ({ count, label, isMonetary, seeAll, btnFunction }) => (
  <div className="DB-A1-wrapper">
    <div className="DB-A1-label-container DB-A1-margin-bottom-6">
      <Text id="DB-A1-label-type-one">
        {isMonetary ? `${generateCurrencySymbol()} ${count}` : count}
      </Text>
    </div>
    <div className="DB-A1-label-container DB-A1-margin-bottom-3">
      <Text id="DB-A1-label-type-two">{label}</Text>
    </div>
    <div className="DB-A1-btn-container">
      {seeAll && (
        <Button
          marginTop={6}
          padding={8}
          backgroundColor="tint2"
          height={24}
          appearance="minimal"
          boxShadow="0px 2px 4px rgba(67, 70, 74, 0.14)"
          iconAfter={<ArrowRightIcon />}
          onClick={(e) => btnFunction(e, seeAll)}
        >
          See All
        </Button>
      )}
    </div>
  </div>
);

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default DataBox;
