const updateMemberDetailsToast = (state = null, action) => {
  if (action.type === "SET_UPDATE_MEMBER_DETAILS_TOAST") {
    return action.payload;
  } else {
    return state;
  }
  // switch (action.type) {
  //   case "SET_UPDATE_MEMBER_DETAILS_TOAST": {
  //     return action.payload;
  //   }
  
  //   default: {
  //     return state;
  //   }
  // }
};

export default updateMemberDetailsToast;
