// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import styled from "styled-components";
import {
  toggleLoaderOn,
  toggleLoaderOff,
} from "../../../src/actions/loaderUtils";
import {
  Badge,
  Text,
  Button,
  Paragraph,
  Textarea,
  toaster,
  Dialog,
  Pane,
  TextInputField,
  Strong,
  Menu,
  CalendarIcon,
  CaretRightIcon,
  CrossIcon,
  FloppyDiskIcon,
  IconButton,
} from "evergreen-ui";
import { connect } from "react-redux";
import {
  checkIntOrFloat,
  slotTimeConverter3,
  slotTimeConverter12,
  resourcesNotLoadedToast,
  validateCreditsAndPrice,
  generateCurrencySymbol,
} from "../../utils";
import BulkBookingDetailsOnSearchScreenActions from "../../components/BulkBookingDetailsOnSearchScreenActions";
import {
  updateBookingRemarks,
  updateBookingPayment,
  getIndividualBulkBookingSearchResult,
  getTransactionsDetails,
  generateBookingStatement,
} from "../../actions";
// import validator from "validator";
import TransactionsDialogContent from "../../components/TransactionsDialogContent";
import TransactionModeSelect from "../../components/TransactionModeSelect";

// -----------------------------------------------------------------------
// Styled Components
// -----------------------------------------------------------------------
const BookingDetailsDiv = styled.div`
  .booking-details-wrapper {
    width: 368px;
    height: calc(100vh - 228px);
    min-height: calc(100vh - 228px);
    margin: 16px auto 0 auto;
    border-radius: 5px 5px 0 0;
    box-shadow: 0 0 1px 0 rgba(67, 90, 111, 0.47);
    background-color: #ffffff;
    overflow-y: auto;
  }

  .selected-sport-name-container {
    width: 336px;
    margin: 16px 16px 0 16px;
    justify-content: space-between;
  }

  .booking-details-content-large-box {
    align-items: flex-start;
    justify-content: flex-start;
    width: 336px;
    border-bottom: 1px solid #eff0f3;
    margin: 24px 16px 24px 16px;
    padding-bottom: 24px;
  }

  .detail-row {
    width: 336px;
    justify-content: flex-start;
    align-items: center;
    margin-top: 16px;
  }

  .booking-type-container,
  .from-date-container,
  .to-date-container,
  .timing-container,
  .days-container,
  .slots-count-container,
  .extras-data-container,
  .customer-name-container,
  .customer-phone-container,
  .customer-email-container {
    width: 160px;
  }

  .to-date-container,
  .days-container,
  .extras-data-container {
    margin-left: 16px;
  }

  .customer-phone-container {
    margin-left: 24px;
  }

  .top-div-reset-margin-top {
    margin-top: 0;
  }

  .font-style-stretch-normal {
    font-style: normal;
    font-stretch: normal;
  }

  #detail-description-label {
    font-size: 11px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.45;
    letter-spacing: 0.3px;
    color: #66788a;
    text-transform: uppercase;
  }

  .detail-label {
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: -0.1px;
    color: #234361;
  }

  .booking-date-time,
  .balance-label,
  .balance-amount {
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: -0.1px;
    color: #234361;
  }

  .balance-label,
  .balance-amount {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.25;
  }

  #label-2 {
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: -0.05px;
    color: #234361;
  }

  #label-3 {
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: -0.05px;
    text-align: right;
    color: #234361;
  }

  .handler-name-container,
  .payment-mode-container {
    width: 96px;
  }

  .gross-amount-container,
  .advance-amount-container {
    width: 72px;
    margin-right: 88px;
  }

  .discount-amount-container {
    width: 72px;
  }
  .payment-mode-container {
    width: fit-content;
  }

  .remarks-container {
    // width: 100%;
    max-height: 72px;
    overflow-y: auto;
  }

  #label-4,
  .text-area-input {
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: -0.04px;
    color: #66788a;
  }

  .booking-details-actions-wrapper {
    width: 368px;
    height: 80px;
    position: absolute;
    margin: -4px 16px 0 16px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    box-shadow: 0 -5px 10px -5px rgba(67, 90, 111, 0.47);
    background-color: #ffffff;
    z-index: 3;
  }

  .width-160 {
    min-width: 160px;
    max-width: 160px;
  }

  .width-80 {
    min-width: 80px;
    max-width: 80px;
  }

  .margin-right-8 {
    margin-right: 8px;
  }

  .break-word {
    word-break: break-all;
  }
`;

// -----------------------------------------------------------------------
// BulkBookingDetailsOnSearchPage Component
// -----------------------------------------------------------------------
class BulkBookingDetailsOnSearchPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showRemarksDiv: true,
      showRemarksTextArea: false,
      modifiedRemarks: "",
      showPayNowDialog: false,
      selectedPaymentMode: "No Pay",
      payNowAmount: "",
      isPayNowAmountInvalid: false,
      daysData: {
        0: "Mon",
        1: "Tue",
        2: "Wed",
        3: "Thu",
        4: "Fri",
        5: "Sat",
        6: "Sun",
      },
      paymentModeId: { 0: "No Pay", 1: "Cash", 2: "Card", 3: "Other" },
      showTransactionsDialog: false,
      paymentMode: "No Pay",
      transactionMode: 0,
      selectedTransactionMode: 0,
      disableNoPay: false,
    };
  }

  // -----------------------------------------------------------------------
  //  componentDidUpdate()
  // -----------------------------------------------------------------------
  componentDidUpdate = (prevProps, prevState) => {
    if (
      prevState.showRemarksTextArea &&
      prevProps.individualBulkBookingSearchResult.patternBookings[0]
        .patternBookingId !==
        this.props.individualBulkBookingSearchResult.patternBookings[0]
          .patternBookingId
    ) {
      this.hideTextArea();
    }
  };

  // -----------------------------------------------------------------------
  // changeTransactionMode()
  // -----------------------------------------------------------------------
  changeTransactionMode = (e) => {
    e.persist();
    if (this.state.payNowAmount.length > 0) {
      this.setState({
        paymentMode:
          e.nativeEvent.target[
            e.nativeEvent.target.selectedIndex
          ].text.toString(),
        selectedTransactionMode: parseInt(e.target.value),
        isPayNowAmountInvalid: false,
      });
    } else {
      this.setState({ isPayNowAmountInvalid: true });
    }
  };

  // -----------------------------------------------------------------------
  //  showTextArea()
  // -----------------------------------------------------------------------
  showTextArea = () => {
    this.setState({ showRemarksDiv: false, showRemarksTextArea: true });
  };

  // -----------------------------------------------------------------------
  //  hideTextArea()
  // -----------------------------------------------------------------------
  hideTextArea = () => {
    this.setState({ showRemarksDiv: true, showRemarksTextArea: false });
  };

  // -----------------------------------------------------------------------
  //  handleRemarksChange()
  // -----------------------------------------------------------------------
  handleRemarksChange = async (e) => {
    e.persist();
    await this.setState({ modifiedRemarks: e.target.value.toString() });
  };

  // -----------------------------------------------------------------------
  //  handleRemarkButton()
  // -----------------------------------------------------------------------
  handleRemarkButton = async (e, type) => {
    e.persist();
    await this.showTextArea();
    if (type === "edit") {
      await this.setState({
        modifiedRemarks:
          this.props.individualBulkBookingSearchResult.patternBookings[0]
            .remarks,
      });
      await this.showTextArea();
    }
    if (type === "add_remark") {
      await this.setState({
        modifiedRemarks: "",
      });
      await this.showTextArea();
    }
    if (type === "cancel") {
      await this.setState({
        modifiedRemarks: "",
      });
      this.hideTextArea();
    }
    if (type === "save") {
      if (this.state.modifiedRemarks.trim().length > 0) {
        await this.props.dispatch(
          updateBookingRemarks({
            bookingId: null,
            patternBookingId:
              this.props.individualBulkBookingSearchResult.patternBookings[0]
                .patternBookingId,
            bookingRemarks: this.state.modifiedRemarks,
          })
        );
        if (this.props.updateBookingRemarksToast) {
          if (this.props.updateBookingRemarksToast.resourcesNotLoadedMessage) {
            resourcesNotLoadedToast();
          } else if (this.props.updateBookingRemarksToast.httpErrorMessage) {
            toaster.danger(
              this.props.updateBookingRemarksToast.httpErrorMessage,
              {
                id: "HTTP-ERROR-TOAST-1",
              }
            );
          } else if (
            this.props.updateBookingRemarksToast.requestStatus === -1
          ) {
            toaster.notify("Sorry, you don't have permission to do that");
          } else if (this.props.updateBookingRemarksToast.requestStatus === 1) {
            await this.hideTextArea();
            toaster.success(this.props.updateBookingRemarksToast.message);
            if (this.props.updateSearchPage) {
              await this.props.updateSearchPage();
            }
            await this.getUpdatedIndividualBulkBookingSearchResult(
              this.props.individualBulkBookingSearchResult.patternBookings[0]
                .patternBookingId
            );
          } else if (this.props.updateBookingRemarksToast.requestStatus === 0) {
            toaster.danger(this.props.updateBookingRemarksToast.message);
          }
        }
      } else {
        toaster.warning("Remarks can't be blank");
      }
    }
  };

  // -----------------------------------------------------------------------
  //  getUpdatedIndividualBulkBookingSearchResult()
  // -----------------------------------------------------------------------
  getUpdatedIndividualBulkBookingSearchResult = async (patternBookingId) => {
    let reqParams = {
      input: patternBookingId,
      page: 1,
    };
    await this.props.dispatch(getIndividualBulkBookingSearchResult(reqParams));
    if (this.props.individualBulkBookingSearchResult) {
      if (
        this.props.individualBulkBookingSearchResult.resourcesNotLoadedMessage
      ) {
        resourcesNotLoadedToast();
      } else if (
        this.props.individualBulkBookingSearchResult.httpErrorMessage
      ) {
        toaster.danger(
          this.props.individualBulkBookingSearchResult.httpErrorMessage,
          {
            id: "HTTP-ERROR-TOAST-1",
          }
        );
      } else if (
        this.props.individualBulkBookingSearchResult.requestStatus === -1
      ) {
        toaster.notify("Sorry, you don't have permission to do that");
      } else if (
        this.props.individualBulkBookingSearchResult.requestStatus === 0
      ) {
        toaster.danger(this.props.individualBulkBookingSearchResult.message, {
          id: "HTTP-ERROR-TOAST-1",
        });
      }
    }
  };

  // -----------------------------------------------------------------------
  //  handlePayNow()
  // -----------------------------------------------------------------------
  handlePayNow = async (e, type) => {
    if (type === "show-dialog") {
      this.setState({ showPayNowDialog: true });
    }
    if (type === "hide-dialog") {
      this.setState({
        showPayNowDialog: false,
        disableNoPay: false,
        paymentMode: "No Pay",
        selectedPaymentMode: "No Pay",
        transactionMode: 0,
        selectedTransactionMode: 0,
      });
    }
    if (type === "amount-change") {
      if (validateCreditsAndPrice(e.target.value) === "set") {
        await this.setState({
          payNowAmount: e.target.value,
          isPayNowAmountInvalid: false,
        });
        if (
          this.state.selectedTransactionMode === 0 &&
          this.state.payNowAmount > 0
        ) {
          await this.setState({
            paymentMode: "Cash",
            transactionMode: 1,
            selectedTransactionMode: 1,
            disableNoPay: true,
          });
        }
        if (this.state.payNowAmount <= 0) {
          await this.setState({
            paymentMode: "No Pay",
            transactionMode: 0,
            selectedTransactionMode: 0,
            disableNoPay: false,
          });
        }
      } else if (validateCreditsAndPrice(e.target.value) === "clear") {
        await this.setState({
          payNowAmount: "",
          isPayNowAmountInvalid: false,
          paymentMode: "No Pay",
          transactionMode: 0,
          selectedTransactionMode: 0,
          disableNoPay: false,
        });
      }
    }
    if (type === "confirm") {
      if (
        this.state.payNowAmount >
        this.props.individualBulkBookingSearchResult.patternBookings[0]
          .courtSummary.balance
      ) {
        toaster.warning("Amount is greater than balance", {
          id: "BDOCP-TOAST-6",
        });
      } else if (
        this.state.payNowAmount === "" ||
        this.state.payNowAmount === 0
      ) {
        toaster.warning("Amount is required", {
          id: "BDOCP-TOAST-6",
        });
        await this.setState({ isPayNowAmountInvalid: true });
      } else {
        const reqBody = {
          bookingId: null,
          patternBookingId:
            this.props.individualBulkBookingSearchResult.patternBookings[0]
              .patternBookingId,
          amount: parseFloat(this.state.payNowAmount),
          transactionData: {
            mode: this.state.selectedTransactionMode,
            type: 3,
          },
        };
        await this.props.dispatch(updateBookingPayment(reqBody));
        if (this.props.updateBookingPaymentToast) {
          if (this.props.updateBookingPaymentToast.resourcesNotLoadedMessage) {
            resourcesNotLoadedToast();
          } else if (this.props.updateBookingPaymentToast.httpErrorMessage) {
            toaster.danger(
              this.props.updateBookingPaymentToast.httpErrorMessage,
              {
                id: "HTTP-ERROR-TOAST-1",
              }
            );
          } else if (
            this.props.updateBookingPaymentToast.requestStatus === -1
          ) {
            toaster.notify("Sorry, you don't have permission to do that");
          } else if (this.props.updateBookingPaymentToast.requestStatus === 1) {
            await this.setState({ payNowAmount: 0 });
            await this.setState({ showPayNowDialog: false });
            if (this.props.updateSearchPage) {
              await this.props.updateSearchPage();
            }
            await this.getUpdatedIndividualBulkBookingSearchResult(
              this.props.individualBulkBookingSearchResult.patternBookings[0]
                .patternBookingId
            );
            toaster.success(this.props.updateBookingPaymentToast.message);
          } else if (this.props.updateBookingPaymentToast.requestStatus === 0) {
            toaster.danger(this.props.updateBookingPaymentToast.message);
          }
        }
      }
    }
  };

  // -----------------------------------------------------------------------
  //  handleTransactionsDialog()
  // -----------------------------------------------------------------------
  handleTransactionsDialog = async (type) => {
    let dispatch = this.props.dispatch;
    if (type === "hide") {
      await this.setState({ showTransactionsDialog: false });
    }
    if (type === "show") {
      toggleLoaderOn(dispatch);
      await this.props.dispatch(
        getTransactionsDetails(
          this.props.individualBulkBookingSearchResult.patternBookings[0]
            .patternBookingId
        )
      );
      if (this.props.transactionsDetails) {
        if (this.props.transactionsDetails.resourcesNotLoadedMessage) {
          toggleLoaderOff(dispatch);
          resourcesNotLoadedToast();
        } else if (this.props.transactionsDetails.httpErrorMessage) {
          toggleLoaderOff(dispatch);
          toaster.danger(this.props.transactionsDetails.httpErrorMessage, {
            id: "HTTP-ERROR-TOAST-1",
          });
        } else if (this.props.transactionsDetails.requestStatus === -1) {
          toggleLoaderOff(dispatch);
          toaster.notify("Sorry, you don't have permission to do that");
        } else if (this.props.transactionsDetails.requestStatus === 1) {
          toggleLoaderOff(dispatch);
          await this.setState({ showTransactionsDialog: true });
        } else if (this.props.transactionsDetails.requestStatus === 0) {
          toggleLoaderOff(dispatch);
          toaster.danger(this.props.transactionsDetails.message);
        }
      }
    }
  };

  // -----------------------------------------------------------------------
  //  generateStatement()
  // -----------------------------------------------------------------------
  generateStatement = async (bookingId) => {
    let reqParams = { patternBookingId: bookingId };
    await this.props.dispatch(generateBookingStatement(reqParams));
    if (this.props.generateBookingStatementToast) {
      if (this.props.generateBookingStatementToast.resourcesNotLoadedMessage) {
        resourcesNotLoadedToast();
      } else if (this.props.generateBookingStatementToast.httpErrorMessage) {
        toaster.danger(
          this.props.generateBookingStatementToast.httpErrorMessage,
          {
            id: "HTTP-ERROR-TOAST-1",
          }
        );
      } else if (
        this.props.generateBookingStatementToast.requestStatus === -1
      ) {
        toaster.notify("Sorry, you don't have permission to do that");
      } else if (this.props.generateBookingStatementToast.requestStatus === 1) {
        // toaster.success(this.props.generateBookingStatementToast.message);
      } else if (this.props.generateBookingStatementToast.requestStatus === 0) {
        toaster.danger(this.props.generateBookingStatementToast.message);
      }
    }
  };

  // -----------------------------------------------------------------------
  //  render
  // -----------------------------------------------------------------------
  render() {
    const {
      showRemarksDiv,
      showRemarksTextArea,
      modifiedRemarks,
      showPayNowDialog,
      paymentModeId,
      showTransactionsDialog,
      disableNoPay,
      selectedTransactionMode,
    } = this.state;
    const {
      individualBulkBookingSearchResult,
      transactionsDetails,
      handleSeeAllBookings,
      handleDrawer,
      handleSeeAllBookingsfromBulkBookingDetailsDrawer,
      getPatternBookingDetailsForDrawer,
    } = this.props;
    return (
      <React.Fragment>
        <Pane height={"auto"} padding={15} paddingBottom={0}>
          <Pane borderRadius={5} background={"white"}>
            <Pane padding={8} display={"flex"} flexDirection={"column"}>
              <Pane
                display={"flex"}
                justifyContent={"space-between"}
                flexDirection={"row"}
              >
                <Badge marginTop={5} color="blue" isSolid>
                  {individualBulkBookingSearchResult &&
                    individualBulkBookingSearchResult.patternBookings[0].sport}
                </Badge>

                <Button
                  className="border-button"
                  height={32}
                  minWidth={100}
                  appearance="minimal"
                  onClick={() =>
                    this.generateStatement(
                      individualBulkBookingSearchResult.patternBookings[0]
                        .patternBookingId
                    )
                  }
                >
                  <Strong color="#0679c5" size={300}>
                    Statements
                  </Strong>
                </Button>
              </Pane>
              {individualBulkBookingSearchResult &&
                individualBulkBookingSearchResult.patternBookings[0]
                  .isPlayoBooking && (
                  <Badge
                    background={"#00B562"}
                    display="flex"
                    alignItems={"center"}
                    flexDirection="row"
                    isSolid
                    color="white"
                    width="113px"
                  >
                    Playo Booking
                  </Badge>
                )}
              <Pane
                marginTop={10}
                display={"flex"}
                justifyContent={"flex-start"}
                flexDirection={"row"}
              >
                <Pane width={"50%"} display="flex" flexDirection={"column"}>
                  <Strong color="muted" size={300}>
                    PATTERN BOOKING ID
                  </Strong>
                  <Strong color="black">
                    {
                      individualBulkBookingSearchResult.patternBookings[0]
                        .patternBookingId
                    }
                  </Strong>
                </Pane>
                <Pane display="flex" flexDirection={"column"}>
                  <Strong color="muted" size={300}>
                    TYPE
                  </Strong>
                  <Strong color="black">Bulk</Strong>
                </Pane>
              </Pane>
              <Pane
                marginTop={15}
                display={"flex"}
                justifyContent={"flex-start"}
                flexDirection={"row"}
              >
                <Pane width={"50%"} display="flex" flexDirection={"column"}>
                  <Strong color="muted" size={300}>
                    FROM
                  </Strong>
                  <Strong color="black">
                    {slotTimeConverter3(
                      individualBulkBookingSearchResult.patternBookings[0]
                        .startDate
                    )}
                  </Strong>
                </Pane>
                <Pane display="flex" flexDirection={"column"}>
                  <Strong color="muted" size={300}>
                    TO
                  </Strong>
                  <Strong color="black">
                    {slotTimeConverter3(
                      individualBulkBookingSearchResult.patternBookings[0]
                        .endDate
                    )}
                  </Strong>
                </Pane>
              </Pane>
              <Pane
                marginTop={15}
                display={"flex"}
                justifyContent={"flex-start"}
                flexDirection={"row"}
              >
                <Pane width={"50%"} display="flex" flexDirection={"column"}>
                  <Strong color="muted" size={300}>
                    TIMING
                  </Strong>
                  <Strong color="black">
                    {slotTimeConverter12(
                      individualBulkBookingSearchResult.patternBookings[0]
                        .startTime,
                      individualBulkBookingSearchResult.patternBookings[0]
                        .endTime
                    )}
                  </Strong>
                </Pane>
                <Pane display="flex" flexDirection={"column"}>
                  <Strong color="muted" size={300}>
                    DAYS
                  </Strong>
                  <Strong color="black">
                    {individualBulkBookingSearchResult.patternBookings[0].days
                      .length === 7
                      ? "All"
                      : individualBulkBookingSearchResult.patternBookings[0].days.map(
                          (day, index) => {
                            if (
                              index ===
                              individualBulkBookingSearchResult
                                .patternBookings[0].days.length -
                                1
                            ) {
                              return (
                                this.state.daysData[day] ||
                                day[0].toUpperCase() + day.substring(1, 3)
                              );
                            } else {
                              return (
                                (this.state.daysData[day] ||
                                  day[0].toUpperCase() + day.substring(1, 3)) +
                                ", "
                              );
                            }
                          }
                        )}
                  </Strong>
                </Pane>
              </Pane>
              <Pane
                marginTop={15}
                display={"flex"}
                justifyContent={"flex-start"}
                flexDirection={"row"}
              >
                <Pane width={"60%"} display="flex" flexDirection={"column"}>
                  <Strong color="muted" size={300}>
                    SLOTS
                  </Strong>
                  <Strong color="black">
                    {
                      individualBulkBookingSearchResult.patternBookings[0]
                        .slotCount
                    }
                  </Strong>
                </Pane>
                <Pane display="flex" width={"54%"} flexDirection={"column"}>
                  <Strong color="muted" size={300}>
                    ACCESSORIES
                  </Strong>
                  <Strong color="black">
                    {individualBulkBookingSearchResult.patternBookings[0] &&
                    individualBulkBookingSearchResult.patternBookings[0]
                      .extras &&
                    individualBulkBookingSearchResult.patternBookings[0].extras
                      .items
                      ? individualBulkBookingSearchResult.patternBookings[0]
                          .extras.items
                      : "None"}
                  </Strong>
                </Pane>
              </Pane>
              <Pane
                marginTop={15}
                display={"flex"}
                justifyContent={"flex-start"}
                flexDirection={"row"}
              >
                <Button
                  className="border-button"
                  height={32}
                  minWidth={150}
                  marginRight={16}
                  appearance="minimal"
                  iconBefore={<CalendarIcon />}
                  onClick={(e) =>
                    handleSeeAllBookingsfromBulkBookingDetailsDrawer
                      ? handleSeeAllBookingsfromBulkBookingDetailsDrawer(
                          e,
                          individualBulkBookingSearchResult.patternBookings[0]
                            .patternBookingId
                        )
                      : handleSeeAllBookings(
                          e,
                          individualBulkBookingSearchResult.patternBookings[0]
                            .patternBookingId
                        )
                  }
                >
                  See All Bookings
                </Button>
              </Pane>
            </Pane>
            <Menu.Divider />
            <Pane padding={8}>
              <Pane marginTop={10} display={"flex"} flexDirection={"row"}>
                <Pane display="flex" flexDirection={"column"}>
                  <Strong color="muted" size={300}>
                    NAME
                  </Strong>
                  <Strong color="black">
                    {individualBulkBookingSearchResult.patternBookings[0] &&
                    individualBulkBookingSearchResult.patternBookings[0]
                      .customerDetails.name
                      ? individualBulkBookingSearchResult.patternBookings[0]
                          .customerDetails.name
                      : "None"}
                  </Strong>
                </Pane>
                <Pane display="flex" marginLeft={120} flexDirection={"column"}>
                  {individualBulkBookingSearchResult.patternBookings[0]
                    .customerDetails &&
                  individualBulkBookingSearchResult.patternBookings[0]
                    .customerDetails.customerCountryCode === "+91" &&
                  individualBulkBookingSearchResult.patternBookings[0]
                    .isPlayoBooking ? (
                    <Button
                      className="border-button"
                      height={32}
                      minWidth={100}
                      appearance="minimal"
                      onClick={() =>
                        this.showGetInTouchDialog(true, {
                          bookingId:
                            individualBulkBookingSearchResult.patternBookings[0]
                              .bookingId,
                        })
                      }
                    >
                      Get in Touch
                    </Button>
                  ) : (
                    <Pane
                      display="flex"
                      marginLeft={20}
                      flexDirection={"column"}
                    >
                      <Strong color="muted" size={300}>
                        PHONE NUMBER
                      </Strong>
                      <Strong color="black">
                        {individualBulkBookingSearchResult.patternBookings[0] &&
                        individualBulkBookingSearchResult.patternBookings[0]
                          .customerDetails.mobile
                          ? individualBulkBookingSearchResult.patternBookings[0]
                              .customerDetails.mobile
                          : "None"}
                      </Strong>{" "}
                    </Pane>
                  )}
                </Pane>
              </Pane>
              <Pane
                marginTop={10}
                display={"flex"}
                justifyContent={"space-between"}
                flexDirection={"row"}
              >
                <Pane display="flex" flexDirection={"column"}>
                  <Strong color="muted" size={300}>
                    EMAIL ID
                  </Strong>
                  <Strong color="black">
                    {" "}
                    {individualBulkBookingSearchResult.patternBookings[0] &&
                    individualBulkBookingSearchResult.patternBookings[0]
                      .customerDetails.email
                      ? individualBulkBookingSearchResult.patternBookings[0]
                          .customerDetails.email
                      : "None"}
                  </Strong>
                </Pane>
              </Pane>
            </Pane>
            <Menu.Divider />

            <Pane padding={12}>
              <Pane
                display={"flex"}
                justifyContent={"space-between"}
                flexDirection={"row"}
              >
                <Strong color="muted" size={500} marginTop={8}>
                  Payment Details
                </Strong>

                <Button
                  className="border-button"
                  height={32}
                  minWidth={100}
                  appearance="minimal"
                  onClick={() => this.handleTransactionsDialog("show")}
                >
                  <Strong color="#0679c5" size={300}>
                    Transaction
                  </Strong>
                </Button>
              </Pane>
              <Pane
                marginTop={15}
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"flex-start"}
              >
                <Pane display="flex" flexDirection={"column"} width={164}>
                  <Strong color="muted" size={300}>
                    Gross
                  </Strong>
                  <Strong color="black">
                    {`${generateCurrencySymbol()} ${
                      individualBulkBookingSearchResult.patternBookings[0] &&
                      individualBulkBookingSearchResult.patternBookings[0]
                        .summary &&
                      checkIntOrFloat(
                        individualBulkBookingSearchResult.patternBookings[0]
                          .summary.gross
                      )
                    }`}
                  </Strong>
                </Pane>
                <Pane display="flex" flexDirection={"column"}>
                  <Strong color="muted" size={300}>
                    Discount
                  </Strong>
                  <Strong color="black">
                    {`${generateCurrencySymbol()} ${
                      individualBulkBookingSearchResult.patternBookings[0] &&
                      individualBulkBookingSearchResult.patternBookings[0]
                        .summary &&
                      checkIntOrFloat(
                        individualBulkBookingSearchResult.patternBookings[0]
                          .summary.discount
                      )
                    }`}
                  </Strong>
                </Pane>
              </Pane>

              <Pane padding={5}>
                <Pane
                  marginTop={15}
                  display={"flex"}
                  justifyContent="flex-start"
                  flexDirection={"row"}
                >
                  <CaretRightIcon
                    cursor={"pointer"}
                    color="muted"
                    marginRight={3}
                  />
                  <Pane display="flex" width={"42%"} flexDirection={"column"}>
                    <Text color="muted" size={300}>
                      Court
                    </Text>
                    <Strong color="muted" width={80}>
                      {`${generateCurrencySymbol()} ${
                        individualBulkBookingSearchResult.patternBookings[0] &&
                        individualBulkBookingSearchResult.patternBookings[0]
                          .courtSummary &&
                        checkIntOrFloat(
                          individualBulkBookingSearchResult.patternBookings[0]
                            .courtSummary.gross
                        )
                      }`}
                    </Strong>
                  </Pane>
                  <Pane display="flex" flexDirection={"column"}>
                    <Text color="muted" size={300}>
                      Discount
                    </Text>
                    <Strong color="muted">
                      {`${generateCurrencySymbol()} ${
                        individualBulkBookingSearchResult.patternBookings[0] &&
                        individualBulkBookingSearchResult.patternBookings[0]
                          .courtSummary &&
                        checkIntOrFloat(
                          individualBulkBookingSearchResult.patternBookings[0]
                            .courtSummary.discount
                        )
                      }`}
                    </Strong>
                  </Pane>
                  {individualBulkBookingSearchResult.patternBookings[0]
                    .courtSummary &&
                    individualBulkBookingSearchResult.patternBookings[0]
                      .courtSummary.balance > 0 &&
                    !individualBulkBookingSearchResult.patternBookings[0]
                      .bookingCancelled && (
                      <Button
                        className="border-button"
                        height={32}
                        marginLeft={35}
                        minWidth={70}
                        appearance="minimal"
                        onClick={(e) => this.handlePayNow(e, "show-dialog")}
                      >
                        <Strong color="#0679c5" size={300}>
                          Pay Now
                        </Strong>
                      </Button>
                    )}
                  {/* {individualBulkBookingSearchResult.patternBookings[0]
										.courtSummary.balance > 0 &&
										!individualBulkBookingSearchResult.patternBookings[0]
											.bookingCancelled && (
											<IconButton
												onClick={() =>
													this.showEditBookingAmountDialog(true, {
														bookingId:
															individualBulkBookingSearchResult
																.patternBookings[0]["bookingId"],
													})
												}
												marginLeft={5}
												icon={"edit"}
												size={30}
												appearance={"minimal"}
											/>
										)}
									{individualBulkBookingSearchResult.patternBookings[0]
										.courtSummary.balance === 0 &&
										!individualBulkBookingSearchResult.patternBookings[0]
											.bookingCancelled && (
											<Button
												className="border-button"
												height={32}
												marginLeft={24}
												minWidth={100}
												appearance="minimal"
												onClick={() =>
													this.showEditBookingAmountDialog(true, {
														bookingId:
															individualBulkBookingSearchResult
																.patternBookings[0]["bookingId"],
													})
												}
											>
												<Strong color="#0679c5" size={300}>
													Edit Amount
												</Strong>
											</Button>
										)} */}
                </Pane>
                {individualBulkBookingSearchResult.patternBookings[0]
                  .extras && (
                  <Pane
                    marginTop={15}
                    display={"flex"}
                    justifyContent="flex-start"
                    flexDirection={"row"}
                  >
                    <CaretRightIcon
                      cursor={"pointer"}
                      color="muted"
                      marginRight={3}
                    />
                    <Pane display="flex" width={"42%"} flexDirection={"column"}>
                      <Text color="muted" size={300}>
                        Add On
                      </Text>
                      <Strong color="muted">
                        {`${generateCurrencySymbol()} ${
                          individualBulkBookingSearchResult
                            .patternBookings[0] &&
                          individualBulkBookingSearchResult.patternBookings[0]
                            .extrasSummary &&
                          checkIntOrFloat(
                            individualBulkBookingSearchResult.patternBookings[0]
                              .extrasSummary.gross
                          )
                        }`}
                      </Strong>
                    </Pane>
                    <Pane display="flex" flexDirection={"column"}>
                      <Text color="muted" size={300}>
                        Discount
                      </Text>
                      <Strong color="muted">
                        {`${generateCurrencySymbol()} ${
                          individualBulkBookingSearchResult
                            .patternBookings[0] &&
                          individualBulkBookingSearchResult.patternBookings[0]
                            .extrasSummary &&
                          checkIntOrFloat(
                            individualBulkBookingSearchResult.patternBookings[0]
                              .extrasSummary.discount
                          )
                        }`}
                      </Strong>
                    </Pane>
                    {/* 
										{individualBulkBookingSearchResult.patternBookings[0]
											.extrasSummary.balance > 0 &&
											!individualBulkBookingSearchResult.patternBookings[0]
												.bookingCancelled && (
												<Button
													className="border-button"
													height={32}
													marginLeft={24}
													minWidth={70}
													appearance="minimal"
													onClick={(e) => this.handleExtraPayNow("open")}
												>
													<Strong color="#0679c5" size={300}>
														Pay Now
													</Strong>
												</Button>
											)}
										{individualBulkBookingSearchResult.patternBookings[0]
											.extrasSummary.balance <= 0 && (
											<Button
												className="border-button"
												height={32}
												marginLeft={24}
												minWidth={70}
												appearance="minimal"
												onClick={(e) => this.handleExtraPayNow("open")}
											>
												<Strong color="#0679c5" size={300}>
													View
												</Strong>
											</Button>
										)}
										{!individualBulkBookingSearchResult.patternBookings[0]
											.bookingCancelled && (
											<IconButton
												onClick={() => this.handleExtrasDialog("open")}
												marginLeft={5}
												appearance="minimal"
												icon="add"
												border={0}
											/>
										)} */}
                  </Pane>
                )}
              </Pane>
              <Menu.Divider />
              <Pane
                marginTop={15}
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"flex-start"}
              >
                <Pane display="flex" flexDirection={"column"} width={164}>
                  <Strong color="muted" size={300}>
                    Collected
                  </Strong>
                  <Strong color="black">
                    {`${generateCurrencySymbol()} ${
                      individualBulkBookingSearchResult.patternBookings[0] &&
                      individualBulkBookingSearchResult.patternBookings[0]
                        .summary &&
                      checkIntOrFloat(
                        individualBulkBookingSearchResult.patternBookings[0]
                          .summary.advance
                      )
                    }`}
                  </Strong>
                </Pane>
                <Pane display="flex" flexDirection={"column"}>
                  <Strong color="muted" size={300}>
                    Balance Due
                  </Strong>
                  <Strong color="black">
                    {`${generateCurrencySymbol()} ${
                      individualBulkBookingSearchResult.patternBookings[0] &&
                      individualBulkBookingSearchResult.patternBookings[0]
                        .summary &&
                      checkIntOrFloat(
                        individualBulkBookingSearchResult.patternBookings[0]
                          .summary.balance
                      )
                    }`}
                  </Strong>
                </Pane>
              </Pane>
            </Pane>
            {!individualBulkBookingSearchResult.patternBookings[0].extras && (
              <Menu.Divider />
            )}
            {/* {!individualBulkBookingSearchResult.patternBookings[0].extras && (
							<Pane
								marginTop={10}
								marginBottom={10}
								display={"flex"}
								justifyContent="center"
								flexDirection={"row"}
							>
								<Button
									appearance="minimal"
									className={"border-button"}
									padding={5}
									marginLeft={5}
									marginTOp={8}
									width={98}
									iconBefore="add"
									color={"#0679c5"}
									onClick={() => this.handleExtrasDialog("open")}
								>
									<Strong marginTop={3} size={300} color={"#0679c5"}>
										Addons
									</Strong>
								</Button>
							</Pane>
						)} */}
            <Menu.Divider />

            <Pane
              display="flex"
              padding={8}
              height={10}
              justifyContent="center"
            >
              {showRemarksTextArea ||
              (individualBulkBookingSearchResult.patternBookings[0].remarks &&
                individualBulkBookingSearchResult.patternBookings[0].remarks.trim()
                  .length > 0) ? null : (
                <Button
                  className="border-button"
                  height={32}
                  minWidth={70}
                  marginTop={10}
                  appearance="minimal"
                  onClick={(e) => this.handleRemarkButton(e, "add_remark")}
                >
                  <Strong color="#0679c5" padding={10} size={300}>
                    Add Remarks
                  </Strong>
                </Button>
              )}
            </Pane>
            <Pane display="flex" height={250} padding={8}>
              {showRemarksTextArea && (
                <Pane
                  display={"flex"}
                  flexDirection="column"
                  justifyContent="flex-start"
                >
                  <Pane display={"flex"} flexDirection="row">
                    <Textarea
                      className="RBDDC-A1-text-area-input"
                      style={{ resize: "none" }}
                      width={330}
                      height={72}
                      placeholder={"Write your Remarks here."}
                      maxLength="100"
                      value={modifiedRemarks}
                      onChange={(e) => this.handleRemarksChange(e)}
                    />
                  </Pane>
                  <Pane
                    display={"flex"}
                    flexDirection="row"
                    justifyContent="flex-end"
                  >
                    <Button
                      className="RBDDC-A1-btn"
                      appearance="minimal"
                      width={88}
                      fontWeight={501}
                      height={32}
                      marginRight={8}
                      onClick={(e) => this.handleRemarkButton(e, "cancel")}
                    >
                      <CrossIcon
                        cursor={"pointer"}
                        appearance="minimal"
                        color="muted"
                        marginRight={2}
                      />
                      <Text marginTop={2} color={"muted"}>
                        Cancel
                      </Text>
                    </Button>
                    <Button
                      className="RBDDC-A1-btn"
                      appearance="minimal"
                      width={50}
                      fontWeight={501}
                      height={32}
                      iconBefore={<FloppyDiskIcon />}
                      onClick={(e) => this.handleRemarkButton(e, "save")}
                    >
                      Save
                    </Button>
                  </Pane>
                </Pane>
              )}
              {showRemarksDiv && (
                <Pane display="flex" flexDirection="column">
                  <Pane display="flex" flexDirection="row">
                    <Paragraph id="RBDDC-A1-type-four-label">
                      {individualBulkBookingSearchResult.patternBookings[0]
                        .remarks &&
                      individualBulkBookingSearchResult.patternBookings[0].remarks.trim()
                        .length > 0
                        ? individualBulkBookingSearchResult.patternBookings[0]
                            .remarks
                        : null}
                    </Paragraph>
                  </Pane>
                  <Pane display="flex" flexDirection="row">
                    {individualBulkBookingSearchResult.patternBookings[0]
                      .remarks &&
                      individualBulkBookingSearchResult.patternBookings[0].remarks.trim()
                        .length > 0 && (
                        <Button
                          justifyContent="center"
                          className="border-button"
                          height={32}
                          minWidth={70}
                          marginTop={10}
                          appearance="minimal"
                          onClick={(e) => this.handleRemarkButton(e, "edit")}
                        >
                          <Strong color="#0679c5" padding={10} size={300}>
                            Edit Remarks
                          </Strong>
                        </Button>
                      )}
                  </Pane>
                </Pane>
              )}
            </Pane>
          </Pane>
        </Pane>
        <div
          className="RBDDC-A1-booking-details-actions-wrapper"
          style={{ margin: "0px" }}
        >
          <BulkBookingDetailsOnSearchScreenActions
            bookingId={
              individualBulkBookingSearchResult.patternBookings[0]
                .patternBookingId
            }
            handleDrawer={handleDrawer}
            bookingCancelled={
              individualBulkBookingSearchResult.patternBookings[0].cancelled
            }
            getPatternBookingDetailsForDrawer={
              getPatternBookingDetailsForDrawer
            }
            getUpdatedIndividualBulkBookingSearchResult={
              this.getUpdatedIndividualBulkBookingSearchResult
            }
          />
        </div>
        <Dialog
          width="fit-content"
          isShown={showTransactionsDialog}
          title="Transactions"
          onCloseComplete={() => this.handleTransactionsDialog("hide")}
          hasFooter={false}
        >
          <TransactionsDialogContent
            transactionsDetails={transactionsDetails}
          />
        </Dialog>

        <Dialog
          width={450}
          isShown={showPayNowDialog}
          title="Pay Now"
          hasFooter={false}
          hasHeader={false}
          onCloseComplete={(e) => this.handlePayNow(e, "hide-dialog")}
        >
          <Pane
            marginBottom={7}
            display={"flex"}
            flexDirection={"row"}
            justifyContent="space-between"
            alignItems={"center"}
          >
            <Strong size={400}>Pay Now (Court Price)</Strong>
            <IconButton
              icon={<CrossIcon />}
              appearance="minimal"
              onClick={(e) => this.handlePayNow(e, "hide-dialog")}
            />
          </Pane>

          <Menu.Divider />

          <Pane
            display={"flex"}
            flexDirection="row"
            justifyContent="flex-start"
            marginBottom={5}
            marginTop={10}
          >
            <TextInputField
              width={150}
              label="Collect Amount"
              height={32}
              name="pay-amount-input"
              placeholder={`${generateCurrencySymbol()} 0`}
              onChange={(e) => this.handlePayNow(e, "amount-change")}
            />

            <Pane marginLeft={20} marginTop={23}>
              <TransactionModeSelect
                disableNoPay={disableNoPay}
                selectedTransactionMode={selectedTransactionMode}
                changeTransactionMode={this.changeTransactionMode}
              />
            </Pane>
          </Pane>
          <Pane
            display={"flex"}
            flexDirection={"row"}
            marginTop={10}
            marginBottom={10}
          >
            <Text id="RBDDC-A1-type-five-label" marginRight={10}>
              Balance Amount
            </Text>
            <Text id="RBDDC-A1-type-six-label">
              {`${generateCurrencySymbol()} ${
                individualBulkBookingSearchResult &&
                individualBulkBookingSearchResult.patternBookings[0] &&
                individualBulkBookingSearchResult.patternBookings[0]
                  .courtSummary &&
                checkIntOrFloat(
                  individualBulkBookingSearchResult.patternBookings[0]
                    .courtSummary.balance
                )
              }`}
            </Text>
          </Pane>
          <div className="RBDDC-A1-pay-now-last-row">
            {/* <Button
								className="RBDDC-A1-btn"
								marginRight={12}
								width={72}
								height={32}
								onClick={(e) => this.handlePayNow(e, "hide-dialog")}
							>
								Cancel
							</Button> */}
            <Button
              className="RBDDC-A1-btn"
              width={88}
              height={32}
              appearance="primary"
              intent="success"
              onClick={(e) => this.handlePayNow(e, "confirm")}
            >
              Confirm
            </Button>
          </div>
        </Dialog>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({
  updateBookingRemarksToast,
  updateBookingPaymentToast,
  transactionsDetails,
  individualBulkBookingSearchResult,
  generateBookingStatementToast,
}) => ({
  updateBookingRemarksToast,
  updateBookingPaymentToast,
  transactionsDetails,
  individualBulkBookingSearchResult,
  generateBookingStatementToast,
});

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default connect(mapStateToProps)(BulkBookingDetailsOnSearchPage);
