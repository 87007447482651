// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import { connect } from "react-redux";
import {
  Text,
  Table,
  Popover,
  Menu,
  IconButton,
  EditIcon,
  MoreIcon,
} from "evergreen-ui";
import { slotDateConverter4, slotTimeConverter12 } from "../../utils";
import CustomizedPagination from "../../components/CustomizedPagination";

// -----------------------------------------------------------------------
// CouponTable Component
// -----------------------------------------------------------------------
class CouponTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      daysData: {
        1: "Mon",
        2: "Tue",
        3: "Wed",
        4: "Thu",
        5: "Fri",
        6: "Sat",
        0: "Sun",
      },
    };
  }

  // -----------------------------------------------------------------------
  // render()
  // -----------------------------------------------------------------------
  render() {
    const { daysData } = this.state;

    const {
      couponDetails,
      clubActivityData,
      currentPage,
      handlePageChange,
      handleCouponCodesDialog,
      handleEditCouponDetailsDialog,
    } = this.props;

    return (
      <React.Fragment>
        <div className="MT-A1-wrapper">
          <div className="CCT-A1-table-container">
            <Table>
              <Table.Head>
                <Table.TextHeaderCell className="ST-A1-table-text-header-cell ST-A1-flex-basis-180">
                  Handler/Timestamp
                </Table.TextHeaderCell>
                <Table.TextHeaderCell className="ST-A1-table-text-header-cell ST-A1-flex-basis-128">
                  Activity
                </Table.TextHeaderCell>
                <Table.TextHeaderCell className="ST-A1-table-text-header-cell ST-A1-flex-basis-180">
                  Dates/Days
                </Table.TextHeaderCell>
                <Table.TextHeaderCell className="ST-A1-table-text-header-cell ST-A1-flex-basis-160">
                  Timing
                </Table.TextHeaderCell>
                <Table.TextHeaderCell className="ST-A1-table-text-header-cell ST-A1-flex-basis-160">
                  Discount
                </Table.TextHeaderCell>
                <Table.TextHeaderCell className="ST-A1-table-text-header-cell ST-A1-flex-basis-220">
                  Remarks
                </Table.TextHeaderCell>
              </Table.Head>
              <Table.Body className="MT-A1-table-body" overflow="none">
                {couponDetails &&
                  couponDetails.data &&
                  couponDetails.data.map((coupon, index) => (
                    <Table.Row
                      isSelectable
                      className="MT-A1-table-row"
                      key={index}
                    >
                      <Table.Cell className="ST-A1-table-cell ST-A1-flex-basis-180">
                        <div className="ST-A1-table-cell-data-container">
                          <Text id="ST-A1-label-type-one">
                            {coupon.handler}
                          </Text>
                          <Text id="ST-A1-label-type-two" marginTop={8}>
                            {coupon.timestamp}
                          </Text>
                        </div>
                      </Table.Cell>
                      <Table.Cell className="ST-A1-table-cell ST-A1-flex-basis-128">
                        <div className="ST-A1-table-cell-data-container">
                          {coupon.activities.length &&
                          coupon.activities.length !==
                            Object.keys(clubActivityData.activities).length ? (
                            coupon.activities.map((activityId, index) => {
                              return (
                                <Text id="ST-A1-label-type-one" key={index}>
                                  {clubActivityData.activities[activityId]}
                                </Text>
                              );
                            })
                          ) : (
                            <Text id="ST-A1-label-type-one" key={index}>
                              All Activities
                            </Text>
                          )}
                        </div>
                      </Table.Cell>
                      <Table.Cell className="ST-A1-table-cell ST-A1-flex-basis-180">
                        <div className="ST-A1-table-cell-data-container">
                          <Text
                            id="ST-A1-label-type-four"
                            onClick={(e) =>
                              handleCouponCodesDialog(e, coupon.couponDetailId)
                            }
                          >
                            {slotDateConverter4(
                              coupon.startDate,
                              coupon.endDate
                            )}
                          </Text>
                          <Text id="ST-A1-label-type-two" marginTop={8}>
                            {coupon.days.length === 7
                              ? "All Days"
                              : coupon.days.map((day, index) => {
                                  if (index === coupon.days.length - 1) {
                                    return (
                                      daysData[day] ||
                                      day[0].toUpperCase() + day.substring(1, 3)
                                    );
                                  } else {
                                    return (
                                      (daysData[day] ||
                                        day[0].toUpperCase() +
                                          day.substring(1, 3)) + ", "
                                    );
                                  }
                                })}
                          </Text>
                        </div>
                      </Table.Cell>
                      <Table.Cell className="ST-A1-table-cell ST-A1-flex-basis-160">
                        <div className="ST-A1-table-cell-data-container">
                          <Text id="ST-A1-label-type-one">
                            {coupon.startTime && coupon.endTime
                              ? slotTimeConverter12(
                                  coupon.startTime,
                                  coupon.endTime
                                )
                              : "All Slots"}
                          </Text>
                        </div>
                      </Table.Cell>
                      <Table.Cell className="ST-A1-table-cell ST-A1-flex-basis-160">
                        <div className="ST-A1-table-cell-data-container">
                          {coupon.amount ? (
                            <Text id="ST-A1-label-type-one">
                              Amount: {coupon.amount}
                            </Text>
                          ) : (
                            <div className="ST-A1-table-cell-data-container">
                              <Text id="ST-A1-label-type-one">
                                Percentage: {coupon.percent}%
                              </Text>
                              <Text id="ST-A1-label-type-two" marginTop={8}>
                                Limit: {coupon.upperLimit}
                              </Text>
                            </div>
                          )}
                        </div>
                      </Table.Cell>
                      <Table.Cell className="ST-A1-table-cell ST-A1-flex-basis-220">
                        <Text id="ST-A1-label-type-two">
                          {coupon.remarks || "--"}
                        </Text>
                      </Table.Cell>
                      <Table.Cell className="MT-A1-action-btn-container-table-cell">
                        <div className="MT-A1-member-actions-menu-and-btn-container">
                          <Popover
                            content={
                              <Menu>
                                <Menu.Group>
                                  <Menu.Item
                                    icon={<EditIcon />}
                                    onSelect={() =>
                                      handleEditCouponDetailsDialog(coupon)
                                    }
                                  >
                                    Edit Details
                                  </Menu.Item>
                                </Menu.Group>
                              </Menu>
                            }
                          >
                            <IconButton
                              appearance="minimal"
                              icon={<MoreIcon />}
                              width={24}
                              height={24}
                            />
                          </Popover>
                        </div>
                      </Table.Cell>
                    </Table.Row>
                  ))}
              </Table.Body>
            </Table>
          </div>
          <div className="CCT-A1-pagination-wrapper">
            <CustomizedPagination
              currentPage={currentPage}
              totalPages={
                couponDetails &&
                couponDetails.paginationData &&
                couponDetails.paginationData.total
              }
              handlePageChange={handlePageChange}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ clubActivityData }) => ({
  clubActivityData,
});

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default connect(mapStateToProps)(CouponTable);
