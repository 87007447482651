// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import {Label, Button} from "evergreen-ui";
import "react-datepicker/dist/react-datepicker.css";
import ClubActivityListSelect from "../../components/ClubActivityListSelect";
import CustomizedDatePicker from "../../components/CustomizedDatePicker";

// -----------------------------------------------------------------------
// ActionBar Component
// -----------------------------------------------------------------------
class ActionBar extends React.Component {
    // -----------------------------------------------------------------------
    // render()
    // -----------------------------------------------------------------------
    render() {
        const {
            startDate,
            endDate,
            handleStartDateChange,
            handleEndDateChange,
            handleActivityChange,
            selectedActivityValue,
            defaultActivityValue,
            defaultActivityName,
            showUtilisation,
            disableShowUtilisation
        } = this.props;
        return (
            <div className="HPAB-A1-wrapper">
                <div className="HPAB-A1-left-actions-container HPAB-A1-margin-right-124">
                    <div className="HPAB-A1-club-activities-select-container HPAB-A1-margin-right-32">
                        <ClubActivityListSelect
                            handleActivityChange={handleActivityChange}
                            selectedActivityValue={selectedActivityValue}
                            defaultActivityValue={defaultActivityValue}
                            defaultActivityName={defaultActivityName}
                        />
                    </div>
                    <div className="HPAB-A1-date-picker-wrapper HPAB-A1-margin-right-32">
                        <div className="HPAB-A1-label-container HPAB-A1-margin-bottom-8">
                            <Label id="HPAB-A1-label-type-one">From</Label>
                        </div>
                        <div className="HPAB-A1-date-picker-container">
                            <CustomizedDatePicker
                                date={startDate}
                                handleDateChange={handleStartDateChange}
                                isDatePickerDisabled={false}
                            />
                        </div>
                    </div>
                    <div className="HPAB-A1-date-picker-wrapper HPAB-A1-margin-right-32">
                        <div className="HPAB-A1-label-container HPAB-A1-margin-bottom-8">
                            <Label id="HPAB-A1-label-type-one">To</Label>
                        </div>
                        <div className="HPAB-A1-date-picker-container">
                            <CustomizedDatePicker
                                date={endDate}
                                handleDateChange={handleEndDateChange}
                                isDatePickerDisabled={false}
                            />
                        </div>
                    </div>
                    <div className="HPAB-A1-show-availability-btn-container">
                        <Button
                            className="button"
                            width={124}
                            onClick={() => showUtilisation()}
                            disabled={disableShowUtilisation}
                            appearance="primary"
                        >
                            Show Utilisation
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default ActionBar;
