import { Pane } from "evergreen-ui";
import React from "react";
import { Line } from "react-chartjs-3";

function LineChart({ options, data }) {
  return (
    <Pane
      background="white"
      borderRadius={"10px"}
      position="relative"
      padding={"24px"}
      width={"70%"}
    >
      <Line options={options} data={data} />
    </Pane>
  );
}

export default LineChart;
