// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import {
  Text,
  Table,
  Position,
  Popover,
  Menu,
  IconButton,
  toaster,
  DocumentIcon,
  Pane,
  Tablist,
  Tab,
  Paragraph,
  MoreIcon,
  Spinner,
} from "evergreen-ui";
import { connect } from "react-redux";
import {
  toDateFormat16,
  resourcesNotLoadedToast,
  generateCurrencySymbol,
} from "../../utils";
import { generateReceipt } from "../../actions";

// -----------------------------------------------------------------------
// TransactionTable Component
// -----------------------------------------------------------------------
class TransactionTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMenuPopover: true,
      activeTransactionId: "",
      selectedIndex: 0,
      loader: false,
      tabs: [],
    };
  }

  // -----------------------------------------------------------------------
  // changeActiveTransactionId()
  // -----------------------------------------------------------------------
  changeActiveTransactionId = async (transactionId) => {
    await this.setState({ activeTransactionId: transactionId });
  };

  // -----------------------------------------------------------------------
  // handleGenerateTaxInvoice()
  // -----------------------------------------------------------------------
  handleGenerateTaxInvoice = async (e) => {
    e.persist();
    try {
      let reqParams = { transactionId: this.state.activeTransactionId };
      await this.props.dispatch(generateReceipt(reqParams));
      if (this.props.generateReceiptToast) {
        if (this.props.generateReceiptToast.resourcesNotLoadedMessage) {
          resourcesNotLoadedToast();
        } else if (this.props.generateReceiptToast.httpErrorMessage) {
          toaster.danger(this.props.generateReceiptToast.httpErrorMessage, {
            id: "HTTP-ERROR-TOAST-3",
          });
        } else if (this.props.generateReceiptToast.requestStatus === 1) {
          // toaster.success(this.props.generateReceiptToast.message, {
          //   id: "HTTP-ERROR-TOAST-3"
          // });
        }
      }
    } catch (err) {
      toaster.danger("Some error has occured", { id: "RA-TOAST" });
    }
  };
  handleTabSelect = (index) => {
    this.setState({ loader: true }, () => {
      // The loader state is now true
      this.setState({ selectedIndex: index, loader: false });
      // The loader state is now false
    });
  };
  // -----------------------------------------------------------------------
  // render()
  // -----------------------------------------------------------------------
  render() {
    const { showMenuPopover, selectedIndex } = this.state;
    const { reportsData } = this.props;
    return (
      <React.Fragment>
        <Tablist marginY={20} marginRight={24}>
          {reportsData?.data.map((club, index) => (
            <Tab
              aria-controls={`panel-${club}`}
              isSelected={index === selectedIndex}
              key={club?.clubId}
              onSelect={() => this.handleTabSelect(index)}
            >
              {club.clubName}
            </Tab>
          ))}
        </Tablist>
        <Pane background="tint1" flex="1">
          {reportsData?.data.map((club, index) => (
            <Pane
              aria-labelledby={club}
              aria-hidden={index !== selectedIndex}
              display={index === selectedIndex ? "block" : "none"}
              key={club?.clubId}
              role="tabpanel"
            >
              <div className="RT-A1-reports-table-container">
                <Table>
                  <Table.Head>
                    <Table.TextHeaderCell className="RT-A1-table-text-header-cell RT-A1-flex-basis-140">
                      Timestamp
                    </Table.TextHeaderCell>
                    <Table.TextHeaderCell className="RT-A1-table-text-header-cell RT-A1-flex-basis-100">
                      Receipt
                    </Table.TextHeaderCell>
                    <Table.TextHeaderCell className="RT-A1-table-text-header-cell RT-A1-flex-basis-90">
                      Type
                    </Table.TextHeaderCell>
                    <Table.TextHeaderCell className="RT-A1-table-text-header-cell RT-A1-flex-basis-180">
                      Booking Detail
                    </Table.TextHeaderCell>
                    <Table.TextHeaderCell className="RT-A1-table-text-header-cell RT-A1-flex-basis-159">
                      Customer Detail
                    </Table.TextHeaderCell>
                    <Table.TextHeaderCell className="RT-A1-table-text-header-cell RT-A1-flex-basis-141">
                      Paid / Mode
                    </Table.TextHeaderCell>
                    <Table.TextHeaderCell className="RT-A1-table-text-header-cell RT-A1-flex-basis-124">
                      Place / Handler
                    </Table.TextHeaderCell>
                    <Table.TextHeaderCell className="RT-A1-table-text-header-cell RT-A1-flex-basis-56" />
                  </Table.Head>
                  <Table.Body className="RT-A1-table-body" overflow="none">
                    {club &&
                      club.data &&
                      club.data.map((data, index) => (
                        <Table.Row
                          isSelectable
                          className="RT-A1-table-row"
                          key={index}
                        >
                          <Table.Cell className="RT-A1-table-cell RT-A1-flex-basis-140">
                            <div className="RT-A1-table-cell-data-container">
                              <Text id="RT-A1-label-type-one" marginBottom={8}>
                                {toDateFormat16(data.timestamp.split(" ")[0])}
                              </Text>
                              <Text id="RT-A1-label-type-two" marginBottom={8}>
                                {data.timestamp.split(" ")[1]}
                              </Text>
                            </div>
                          </Table.Cell>
                          <Table.Cell className="RT-A1-table-cell RT-A1-flex-basis-100">
                            <div className="RT-A1-table-cell-data-container">
                              <Text
                                id="RT-A1-label-type-three"
                                marginBottom={8}
                              >
                                {data.receipt}
                              </Text>
                            </div>
                          </Table.Cell>
                          <Table.Cell className="RT-A1-table-cell RT-A1-flex-basis-90">
                            <div className="RT-A1-table-cell-data-container">
                              <Text
                                id="RT-A1-label-type-three"
                                marginBottom={8}
                              >
                                {data.type}
                              </Text>
                            </div>
                          </Table.Cell>
                          <Table.Cell className="RT-A1-table-cell RT-A1-flex-basis-180">
                            <div className="RT-A1-table-cell-data-container">
                              <Text
                                id="RT-A1-label-type-three"
                                marginBottom={8}
                              >
                                {data.bookingId}
                              </Text>
                              <Text
                                id="RT-A1-label-type-three"
                                marginBottom={8}
                              >
                                {data.sport}
                              </Text>
                              <Text id="RT-A1-label-type-two" marginBottom={8}>
                                {data.court}
                              </Text>
                            </div>
                          </Table.Cell>
                          <Table.Cell className="RT-A1-table-cell RT-A1-flex-basis-159">
                            <div className="RT-A1-table-cell-data-container">
                              <Text
                                id="RT-A1-label-type-three"
                                marginBottom={8}
                              >
                                {data.customerName}
                              </Text>
                              <Text id="RT-A1-label-type-two" marginBottom={8}>
                                {data.customerMobile}
                              </Text>
                              <Text id="RT-A1-label-type-two" marginBottom={8}>
                                {data.customerEmail}
                              </Text>
                            </div>
                          </Table.Cell>
                          <Table.Cell className="RT-A1-table-cell RT-A1-flex-basis-141">
                            <div className="RT-A1-table-cell-data-container">
                              <Text
                                id="RT-A1-label-type-three"
                                marginBottom={8}
                              >
                                {`${generateCurrencySymbol()} ${data.amount}`}
                              </Text>
                              <Text id="RT-A1-label-type-two" marginBottom={8}>
                                {data.mode}
                              </Text>
                            </div>
                          </Table.Cell>
                          <Table.Cell className="RT-A1-table-cell RT-A1-flex-basis-124">
                            <div className="RT-A1-table-cell-data-container">
                              <Text id="RT-A1-label-type-two" marginBottom={8}>
                                {`${data.place} /`}
                              </Text>
                              <Text id="RT-A1-label-type-two" marginBottom={8}>
                                {data.handler}
                              </Text>
                            </div>
                          </Table.Cell>
                          {data.amount ? (
                            <Table.Cell className="RT-A1-table-cell RT-A1-flex-basis-56">
                              <div className="RT-A1-action-btn-container">
                                {showMenuPopover && (
                                  <Popover
                                    onOpen={() =>
                                      this.changeActiveTransactionId(
                                        data.transactionId
                                      )
                                    }
                                    content={
                                      <Menu>
                                        <Menu.Group>
                                          <Menu.Item
                                            icon={<DocumentIcon />}
                                            onSelect={(e) =>
                                              this.handleGenerateTaxInvoice(e)
                                            }
                                          >
                                            Tax Invoice
                                          </Menu.Item>
                                        </Menu.Group>
                                      </Menu>
                                    }
                                    position={Position.BOTTOM_RIGHT}
                                  >
                                    <IconButton
                                      appearance="minimal"
                                      icon={<MoreIcon />}
                                      width={24}
                                      height={24}
                                    />
                                  </Popover>
                                )}
                              </div>
                            </Table.Cell>
                          ) : (
                            <div />
                          )}
                        </Table.Row>
                      ))}
                  </Table.Body>
                </Table>
              </div>
            </Pane>
          ))}
        </Pane>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ reportsData, generateReceiptToast }) => ({
  reportsData,
  generateReceiptToast,
});

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default connect(mapStateToProps)(TransactionTable);
