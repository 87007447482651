// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import {
  Text,
  Popover,
  Radio,
  Button,
  Position,
  CaretDownIcon,
} from "evergreen-ui";

// -----------------------------------------------------------------------
// BookingReportsFilter Component
// -----------------------------------------------------------------------
class BookingReportsFilter extends React.Component {
  // -----------------------------------------------------------------------
  // render()
  // -----------------------------------------------------------------------
  render() {
    const {
      selectedDateRangeForBookingReports,
      handleBookingReportsDateRangeChange,
      showBookingReportsFilterPopover,
    } = this.props;
    return (
      <React.Fragment>
        <div className="BRF-A1-wrapper">
          <div className="BRF-A1-col-container">
            <div className="BRF-A1-label-container BRF-A1-margin-bottom-8">
              <Text id="BRF-A1-type-one-label">Date Range For</Text>
            </div>
            <div className="BRF-A1-show-transaction-for-radio-container">
              {showBookingReportsFilterPopover && (
                <Popover
                  content={
                    <div className="BRF-A1-show-transaction-for-radio-popover-wrapper">
                      <div className="BRF-A1-label-container BRF-A1-margin-bottom-4">
                        <Text id="BRF-A1-type-one-label">Bookings</Text>
                      </div>
                      <div
                        className="BRF-A1-radio-btn-container"
                        aria-label="Radio Group Label 16"
                        role="group"
                      >
                        <Radio
                          className="BRF-A1-radio-input"
                          width={160}
                          size={16}
                          name="group33"
                          label="Transaction Date"
                          checked={
                            selectedDateRangeForBookingReports ===
                            "transaction date"
                              ? true
                              : false
                          }
                          onChange={(e) =>
                            handleBookingReportsDateRangeChange(
                              e,
                              "transaction date"
                            )
                          }
                        />
                        <Radio
                          className="BRF-A1-radio-input"
                          width={160}
                          size={16}
                          name="group33"
                          label="Booking Date"
                          checked={
                            selectedDateRangeForBookingReports ===
                            "booking date"
                              ? true
                              : false
                          }
                          onChange={(e) =>
                            handleBookingReportsDateRangeChange(
                              e,
                              "booking date"
                            )
                          }
                        />
                      </div>
                    </div>
                  }
                  position={Position.BOTTOM_LEFT}
                >
                  <Button
                    className="BRF-A1-button-as-select"
                    width={160}
                    height={32}
                    iconAfter={<CaretDownIcon />}
                  >
                    {selectedDateRangeForBookingReports}
                  </Button>
                </Popover>
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default BookingReportsFilter;
