// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import { connect } from "react-redux";
import Header from "../../components/Header";
import { AddIcon, Button, Pane, toaster } from "evergreen-ui";
// import MembershipPackagesTable from "./MembershipPackagesTable";
// import NoMatchingResultsMessage from "../../components/NoMatchingResultsMessage";
import {
  getMembershipPackageDetails,
  updateMembershipPackageDetails,
  saveMembershipPackageDetailsForEditing,
  saveSelectedMembershipPackageDetails,
  togglePremiumFeatureFlag,
  getMembershipSubscriptionDetails,
  getSummary,
  getChartData,
} from "../../actions";
// import ClubActivityListSelect from "../../components/ClubActivityListSelect";
import { resourcesNotLoadedToast } from "../../utils";
import DataBox from "../../components/DataBox";
import BarChartBox from "../../components/BarChartBox";
import LineChartBox from "../../components/LineChartBox";
import UserExpiryTable from "./UserExpiryTable";

// -----------------------------------------------------------------------
// MembershipPackages Component
// -----------------------------------------------------------------------
class MembershipPackages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clubId: 1,
      currentPage: 1,
      selectedActivityValue: "select-a-sport",
      defaultActivityValue: "select-a-sport",
      defaultActivityName: "Select a sport",
      activePackageDetails: {},
      showMenuPopover: true,
      barChartStartIndex: 0,
      barChartEndIndex: null,
      barChartCurrentPage: 1,
      barChartTotalPage: 1,
      disableBarChartNextBtn: true,
      disableBarChartPreviousBtn: true,
      monthlyActive: [],
      activePackageMember: [],
    };
  }

  componentDidMount = async () => {
    getChartData().then((res) => {
      console.log(res);
      this.setState({
        monthlyActive: res.data?.membersCount,
        activePackageMember: res.data?.packageMembersCount,
      });
    });

    await this.props.dispatch(getSummary());
    let reqParams = {};
    // await this.props.dispatch(getMembershipSubscriptionDetails(reqParams));
  };

  // -----------------------------------------------------------------------
  // componentDidUpdate()
  // -----------------------------------------------------------------------
  componentDidUpdate = (prevProps) => {
    if (
      prevProps.membershipPackageDetails === null &&
      this.props.membershipPackageDetails &&
      this.props.membershipPackageDetails.packages.length > 0
    ) {
      this.setState({
        barChartTotalPage: Math.ceil(
          this.props.membershipPackageDetails.packages.length / 3
        ),
        disableBarChartPreviousBtn: true,
        disableBarChartNextBtn:
          Math.ceil(this.props.membershipPackageDetails.packages.length / 3) ===
          1
            ? true
            : false,
      });
    }
  };

  // -----------------------------------------------------------------------
  // handleActivityChange()
  // -----------------------------------------------------------------------
  handleActivityChange = async (e) => {
    e.persist();
    await this.setState({ selectedActivityValue: e.target.value });
  };

  // -----------------------------------------------------------------------
  // handlePageChange()
  // -----------------------------------------------------------------------
  handlePageChange = async (newPageNum) => {
    await this.setState({ currentPage: newPageNum });
    await this.getMembershipPackages();
  };

  // -----------------------------------------------------------------------
  // getMembershipPackages()
  // -----------------------------------------------------------------------
  getMembershipPackages = async () => {
    let reqParams = {
      page: this.state.currentPage,
    };
    await this.props.dispatch(getMembershipPackageDetails(reqParams));
    if (this.props.membershipPackageDetails) {
      if (this.props.membershipPackageDetails.resourcesNotLoadedMessage) {
        resourcesNotLoadedToast();
      } else if (this.props.membershipPackageDetails.httpErrorMessage) {
        toaster.danger(this.props.membershipPackageDetails.httpErrorMessage, {
          id: "HTTP-ERROR-TOAST-4",
        });
      } else if (this.props.membershipPackageDetails.requestStatus === -1) {
        toaster.notify("Sorry, you don't have permission to do that");
      } else if (this.props.membershipPackageDetails.requestStatus === 0) {
        toaster.danger(this.props.membershipPackageDetails.message, {
          id: "HP-TOAST",
        });
      }
    }
  };

  // -----------------------------------------------------------------------
  // changeActivePackageDetails()
  // -----------------------------------------------------------------------
  changeActivePackageDetails = async (packageDetails) => {
    await this.setState({ activePackageDetails: packageDetails });
  };

  // -----------------------------------------------------------------------
  // reActivatePackage()
  // -----------------------------------------------------------------------
  reActivatePackage = async () => {
    let reqBody = {
      packageId: this.state.activePackageDetails.packageId,
      packageName: this.state.activePackageDetails.packageName,
      price: this.state.activePackageDetails.packagePrice,
      remarks: this.state.activePackageDetails.description,
      credits: this.state.activePackageDetails.packageCredits,
      memberLimit: this.state.activePackageDetails.memberLimit,
      additionalInfo: this.state.activePackageDetails.additionalInfo,
      type: this.state.activePackageDetails.type === "Rotational" ? 1 : 2,
      startDate: this.state.activePackageDetails.startDate,
      period: this.state.activePackageDetails.period,
      isActive: true,
    };
    this.toggleMenuPopover("hide");
    await this.props.dispatch(updateMembershipPackageDetails(reqBody));
    this.toggleMenuPopover("show");
    await this.getMembershipPackages();
    if (this.props.updateMembershipPackageDetailsToast) {
      if (
        this.props.updateMembershipPackageDetailsToast.resourcesNotLoadedMessage
      ) {
        resourcesNotLoadedToast();
      } else if (
        this.props.updateMembershipPackageDetailsToast.httpErrorMessage
      ) {
        toaster.danger(
          this.props.updateMembershipPackageDetailsToast.httpErrorMessage,
          {
            id: "HTTP-ERROR-TOAST-4",
          }
        );
      } else if (
        this.props.updateMembershipPackageDetailsToast.requestStatus === -1
      ) {
        toaster.notify("Sorry, you don't have permission to do that");
      } else if (
        this.props.updateMembershipPackageDetailsToast.requestStatus === 0
      ) {
        toaster.danger(this.props.updateMembershipPackageDetailsToast.message, {
          id: "HP-TOAST",
        });
      } else if (
        this.props.updateMembershipPackageDetailsToast.requestStatus === 1
      ) {
        toaster.success(
          this.props.updateMembershipPackageDetailsToast.message,
          { id: "MPP-TOAST-1" }
        );
      }
    }
  };

  // -----------------------------------------------------------------------
  // toggleMenuPopover()
  // -----------------------------------------------------------------------
  toggleMenuPopover = async (type) => {
    if (type === "show") {
      await this.setState({ showMenuPopover: true });
    } else if (type === "hide") {
      await this.setState({ showMenuPopover: false });
    }
  };

  // -----------------------------------------------------------------------
  // deactivatePackage()
  // -----------------------------------------------------------------------
  deactivatePackage = async () => {
    let reqBody = {
      packageId: this.state.activePackageDetails.packageId,
      packageName: this.state.activePackageDetails.packageName,
      price: this.state.activePackageDetails.packagePrice,
      remarks: this.state.activePackageDetails.description,
      credits: this.state.activePackageDetails.packageCredits,
      memberLimit: this.state.activePackageDetails.memberLimit,
      additionalInfo: this.state.activePackageDetails.additionalInfo,
      type: this.state.activePackageDetails.type === "Rotational" ? 1 : 2,
      startDate: this.state.activePackageDetails.startDate,
      period: this.state.activePackageDetails.period,
      isActive: false,
    };
    this.toggleMenuPopover("hide");
    await this.props.dispatch(updateMembershipPackageDetails(reqBody));
    this.toggleMenuPopover("show");
    await this.getMembershipPackages();
    if (this.props.updateMembershipPackageDetailsToast) {
      if (
        this.props.updateMembershipPackageDetailsToast.resourcesNotLoadedMessage
      ) {
        resourcesNotLoadedToast();
      } else if (
        this.props.updateMembershipPackageDetailsToast.httpErrorMessage
      ) {
        toaster.danger(
          this.props.updateMembershipPackageDetailsToast.httpErrorMessage,
          {
            id: "HTTP-ERROR-TOAST-4",
          }
        );
      } else if (
        this.props.updateMembershipPackageDetailsToast.requestStatus === 0
      ) {
        toaster.danger(this.props.updateMembershipPackageDetailsToast.message, {
          id: "HP-TOAST",
        });
      } else if (
        this.props.updateMembershipPackageDetailsToast.requestStatus === 1
      ) {
        toaster.success(
          this.props.updateMembershipPackageDetailsToast.message,
          { id: "MPP-TOAST-1" }
        );
      }
    }
  };

  // -----------------------------------------------------------------------
  // handleEditPackage()
  // -----------------------------------------------------------------------
  handleEditPackage = async () => {
    this.toggleMenuPopover("hide");
    await this.props.dispatch(
      saveMembershipPackageDetailsForEditing(this.state.activePackageDetails)
    );
    this.toggleMenuPopover("show");
    if (
      this.props.membershipPackageDetailsForEditing &&
      this.props.membershipPackageDetailsForEditing.requestStatus === 1
    ) {
      await this.props.handleCreateMembershipPackagesPageDisplay("show");
    }
  };

  // -----------------------------------------------------------------------
  // handleAddMember()
  // -----------------------------------------------------------------------
  handleAddMember = async () => {
    this.toggleMenuPopover("hide");
    await this.props.dispatch(
      saveSelectedMembershipPackageDetails(
        this.state.activePackageDetails,
        null
      )
    );
    this.toggleMenuPopover("show");
    if (
      this.props.selectedMembershipPackageDetails &&
      this.props.selectedMembershipPackageDetails.requestStatus === 1
    ) {
      await this.props.handleMembershipPackagesCheckoutPageDisplay("show");
    }
  };

  // -----------------------------------------------------------------------
  // generateBarChartData()
  // -----------------------------------------------------------------------
  generateBarChartData = (
    packagesData = this.props.membershipPackageDetails.packages
  ) => {
    const { barChartStartIndex, barChartEndIndex } = this.state;
    var modifiedPackagesData = [];
    var totalPackages = packagesData.length;
    var colorCount = 0;
    for (
      var i = barChartStartIndex;
      i >= 0 && i <= barChartEndIndex && i < totalPackages;
      i++
    ) {
      modifiedPackagesData.push({
        packageName: packagesData[i].packageName,
        activeMember: packagesData[i].soldTickets,
        fill:
          colorCount === 0
            ? "#1070ca"
            : colorCount === 1
            ? "#47b881"
            : "#ec4c47",
      });
      colorCount++;
    }
    colorCount = 0;
    return modifiedPackagesData;
  };

  // -----------------------------------------------------------------------
  // handleChartNavigaionBtn()
  // -----------------------------------------------------------------------
  handleChartNavigaionBtn = async (chartType, navType) => {
    if (chartType === "bar-chart") {
      if (navType === "previous") {
        let startIndex = this.state.barChartStartIndex - 3;
        let endIndex = this.state.barChartStartIndex - 1;
        let currentPage = this.state.barChartCurrentPage - 1;
        await this.setState({
          barChartStartIndex: startIndex,
          barChartEndIndex: endIndex,
          barChartCurrentPage: currentPage,
        });
        await this.toggleBarChartBtnStatus();
        await this.generateBarChartData();
      } else if (navType === "next") {
        let startIndex = this.state.barChartEndIndex + 1;
        let endIndex = this.state.barChartEndIndex + 3;
        let currentPage = this.state.barChartCurrentPage + 1;
        await this.setState({
          barChartStartIndex: startIndex,
          barChartEndIndex: endIndex,
          barChartCurrentPage: currentPage,
        });
        await this.toggleBarChartBtnStatus();
        await this.generateBarChartData();
      }
    } else if (chartType === "line-chart") {
      if (navType === "previous") {
      } else if (navType === "next") {
      }
    }
  };

  // -----------------------------------------------------------------------
  // toggleBarChartBtnStatus()
  // -----------------------------------------------------------------------
  toggleBarChartBtnStatus = async () => {
    await this.setState({
      disableBarChartPreviousBtn:
        this.state.barChartCurrentPage === 1 ? true : false,
      disableBarChartNextBtn:
        this.state.barChartCurrentPage === this.state.barChartTotalPage
          ? true
          : false,
    });
  };

  // -----------------------------------------------------------------------
  // handleBoxSeeAllBtn()
  // -----------------------------------------------------------------------
  handleBoxSeeAllBtn = async (e, seeAll) => {
    e.persist();
    if (seeAll === "packages") {
      await this.props.handleViewMembershipPackagesPageDisplay("show");
    } else if (seeAll === "members") {
      await this.props.handleSidebar(e, "members");
    } else if (seeAll === "pending-payments") {
    }
  };

  // -----------------------------------------------------------------------
  // render()
  // -----------------------------------------------------------------------
  render() {
    const {
      // currentPage,
      // selectedActivityValue,
      // defaultActivityName,
      // defaultActivityValue,
      // showMenuPopover,
      disableBarChartNextBtn,
      disableBarChartPreviousBtn,
      monthlyActive,
      activePackageMember,
    } = this.state;
    const {
      handleCreateMembershipPackagesPageDisplay,
      membershipPackageDetails,
      handleAllMembersListPageDisplay,
      handleViewMembershipPackagesPageDisplay,
      userSummary,
      // handleMembershipPackagesCheckoutPageDisplay
    } = this.props;
    return (
      <React.Fragment>
        <div className="MPP-A1-wrapper">
          <Header headerText={"Packages Dashboard"} />
          <div className="MPP-A1-action-bar">
            <Button
              className="button"
              iconBefore={<AddIcon />}
              appearance="primary"
              intent="warning"
              width={149}
              height={32}
              marginRight={32}
              onClick={() => handleCreateMembershipPackagesPageDisplay("show")}
            >
              Create Package
            </Button>
            {/* <Button
              className="button"
              appearance="primary"
              intent="success"
              width={149}
              height={32}
              marginRight={32}
              onClick={() => handleAllMembersListPageDisplay("show")}
            >
              See Members
            </Button> */}
            <Button
              className="button"
              appearance="primary"
              intent="success"
              width={149}
              height={32}
              onClick={() => handleViewMembershipPackagesPageDisplay("show")}
            >
              See Packages
            </Button>
            {/* <div className="MPP-A1-vertical-line-decorator" />
              <ClubActivityListSelect
                selectedActivityValue={selectedActivityValue}
                defaultActivityName={defaultActivityName}
                defaultActivityValue={defaultActivityValue}
                handleActivityChange={this.handleActivityChange}
              /> */}
          </div>
          <div className="MPP-A1-dashboard-container">
            <div className="MPP-A1-row-container">
              <div className="MPP-A1-data-box-container MPP-A1-margin-right-48">
                <DataBox
                  count={
                    (userSummary &&
                      userSummary.data &&
                      userSummary.data.membershipPackageCount) ||
                    0
                  }
                  label={"Packages"}
                  seeAll={"packages"}
                  btnFunction={this.handleBoxSeeAllBtn}
                />
              </div>
              <div className="MPP-A1-data-box-container MPP-A1-margin-right-48">
                <DataBox
                  count={
                    (userSummary &&
                      userSummary.data &&
                      userSummary.data.subscribedMembersCount) ||
                    0
                  }
                  label={"Members"}
                  seeAll={"members"}
                  btnFunction={() => handleAllMembersListPageDisplay("show")}
                  //btnFunction={this.handleBoxSeeAllBtn}
                />
              </div>
              <div className="MPP-A1-data-box-container MPP-A1-margin-right-48">
                <DataBox
                  count={
                    (userSummary &&
                      userSummary.data &&
                      userSummary.data.balance &&
                      userSummary.data.balance.toFixed(2)) ||
                    0
                  }
                  label={"Pending Payments"}
                  isMonetary={true}
                  btnFunction={() => console.log("pending payments")}
                  // handleAllMembersListPageDisplay(
                  //   "show",
                  //   "",
                  //   "",
                  //   "",
                  //   "pending-payments"
                  // )
                />
              </div>
            </div>

            <div className="MPP-A1-row-container DB-A1-margin-top-27">
              <div className="MPP-A1-bar-chart-box-container MPP-A1-margin-right-70">
                {activePackageMember && (
                  <BarChartBox
                    chartLabel={"Active Members Per Package"}
                    chartData={activePackageMember}
                    handleChartNavigaionBtn={this.handleChartNavigaionBtn}
                    disableBarChartNextBtn={disableBarChartNextBtn}
                    disableBarChartPreviousBtn={disableBarChartPreviousBtn}
                  />
                )}
              </div>
              <div className="MPP-A1-line-chart-box-container">
                {monthlyActive && (
                  <LineChartBox
                    chartData={monthlyActive}
                    chartLabel={"Active Member Count"}
                  />
                )}
              </div>
            </div>
          </div>

          <Pane display="flex" flexDirection="row" maxHeight={350}>
            <UserExpiryTable
              dispatch={this.props.dispatch}
              handleAllMembersListPageDisplay={handleAllMembersListPageDisplay}
            />
          </Pane>
          {/* {membershipPackageDetails &&
          membershipPackageDetails.requestStatus === 1 ? (
            <MembershipPackagesTable
              handleMembershipPackagesCheckoutPageDisplay={
                handleMembershipPackagesCheckoutPageDisplay
              }
              showMenuPopover={showMenuPopover}
              handleAddMember={this.handleAddMember}
              handleEditPackage={this.handleEditPackage}
              reActivatePackage={this.reActivatePackage}
              deactivatePackage={this.deactivatePackage}
              changeActivePackageDetails={this.changeActivePackageDetails}
              currentPage={currentPage}
              handlePageChange={this.handlePageChange}
              membershipPackageDetails={membershipPackageDetails}
              handleAllMembersListPageDisplay={handleAllMembersListPageDisplay}
            />
          ) : (
            <NoMatchingResultsMessage />
          )} */}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({
  membershipPackageDetails,
  updateMembershipPackageDetailsToast,
  membershipPackageDetailsForEditing,
  selectedMembershipPackageDetails,
  userSummary,
}) => ({
  membershipPackageDetails,
  updateMembershipPackageDetailsToast,
  membershipPackageDetailsForEditing,
  selectedMembershipPackageDetails,
  userSummary,
});

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default connect(mapStateToProps)(MembershipPackages);
