// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import { Text } from "evergreen-ui";

// -----------------------------------------------------------------------
// MobileView Component
// -----------------------------------------------------------------------
class MobileView extends React.Component {
  // -----------------------------------------------------------------------
  // linkToApp()
  // -----------------------------------------------------------------------
  linkToApp = (e, type) => {
    e.persist();
    if (type === "google-play") {
      let url =
        "https://play.google.com/store/apps/details?id=com.techmash.playobooking";
      window.open(url, "_blank");
    } else if (type === "app-store") {
      let url =
        "https://apps.apple.com/us/app/venue-partner-interface/id1057092085";
      window.open(url, "_blank");
    }
  };

  // -----------------------------------------------------------------------
  // render()
  // -----------------------------------------------------------------------
  render() {
    return (
      <div className="MV-A1-wrapper">
        <header className="MV-A1-header">
          <img
            className="MV-A1-playo-logo"
            src="https://playo-website.gumlet.io/VSP-2.0/playo-logo.svg?auto=format,compress,enhance"
            alt="playo-logo"
          />
        </header>
        <div className="MV-A1-body-container">
          <img
            className="MV-A1-football-ground-mobile-img"
            src="https://playo-website.gumlet.io/VSP-2.0/football-ground-mobile.png?auto=format,compress,enhance"
            alt="playo-logo"
          />
          <div className="MV-A1-hello-partner-label-container">
            <div className="MV-A1-label-container MV-A1-margin-bottom-12">
              <Text id="MV-A1-label-type-one">Hello</Text>
            </div>
            <div className="MV-A1-label-container MV-A1-margin-bottom-21">
              <Text id="MV-A1-label-type-two">Partner.</Text>
            </div>
            <div className="MV-A1-label-container">
              <Text id="MV-A1-label-type-three">Get Partner App Now!</Text>
            </div>
          </div>
        </div>
        <footer className="MV-A1-footer">
          <div className="MV-A1-label-container MV-A1-margin-bottom-26">
            <Text id="MV-A1-label-type-four">
              Avaliable on Google Play for Android and on the App Store for iOS.
            </Text>
          </div>
          <div className="MV-A1-store-logo-container">
            <img
              className="MV-A1-google-play-logo-img MV-A1-link MV-A1-margin-right-12"
              src="https://playo-website.gumlet.io/VSP-2.0/get-it-on-google-play-logo.png?auto=format,compress,enhance"
              onClick={(e) => this.linkToApp(e, "google-play")}
              alt="google-play-logo"
            />
            <img
              className="MV-A1-app-store-logo-img MV-A1-link"
              src="https://playo-website.gumlet.io/VSP-2.0/download-on-the-app-store-logo.png?auto=format,compress,enhance"
              onClick={(e) => this.linkToApp(e, "app-store")}
              alt="app-store-logo"
            />
          </div>
        </footer>
      </div>
    );
  }
}

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default MobileView;
