// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import { Button, Dialog, Text } from "evergreen-ui";

// -----------------------------------------------------------------------
// MemberDetailsDrawerActions Component
// -----------------------------------------------------------------------
class MemberDetailsDrawerActions extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isShown: false };
  }

  // -----------------------------------------------------------------------
  // handleDeleteMemberDialog()
  // -----------------------------------------------------------------------
  handleDeleteMemberDialog = type => {
    if (type === "open") {
      this.setState({ isShown: true });
    }
    if (type === "close") {
      this.setState({ isShown: false });
    }
  };

  // -----------------------------------------------------------------------
  // confirmDeleteMember()
  // -----------------------------------------------------------------------
  confirmDeleteMember = () => {
    this.handleDeleteMemberDialog("close");
  };

  // -----------------------------------------------------------------------
  // render()
  // -----------------------------------------------------------------------
  render() {
    const { isShown } = this.state;
    const { memberMobile, handleSeeBookingsFromMembersDrawer } = this.props;
    return (
      <React.Fragment>
        <div className="MDDA-A1-wrapper">
          <div className="MDDA-A1-action-btn-container">
            <Button
              className="MDDA-A1-button"
              appearance="default"
              width={144}
              height={32}
              onClick={e => handleSeeBookingsFromMembersDrawer(e, memberMobile)}
            >
              See Bookings
            </Button>
            <Dialog
              isShown={isShown}
              title="Warning"
              intent="danger"
              onCloseComplete={e => this.handleDeleteMemberDialog("close")}
              confirmLabel="Delete Member"
              onConfirm={() => this.confirmDeleteMember()}
            >
              <div className="MDDA-A1-label-container">
                <Text id="MDDA-A1-type-one-label">
                  The member will be deleted. Do you wish to continue?
                </Text>
              </div>
            </Dialog>
            <Button
              className="MDDA-A1-button"
              appearance="default"
              intent="danger"
              width={144}
              height={32}
              onClick={e => this.handleDeleteMemberDialog("open")}
            >
              Delete Member
            </Button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default MemberDetailsDrawerActions;
