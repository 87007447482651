// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import { connect } from "react-redux";
import { Select } from "evergreen-ui";

// -----------------------------------------------------------------------
// TransactionModeSelect Component
// -----------------------------------------------------------------------
class TransactionModeSelect extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			transactionModes: [
				{ paymentMode: "No Pay", transactionMode: 0 },
				{ paymentMode: "Cash", transactionMode: 1 },
				{ paymentMode: "Card", transactionMode: 2 },
				{ paymentMode: "Other", transactionMode: 3 },
			],
		};
	}

	// -----------------------------------------------------------------------
	// render()
	// -----------------------------------------------------------------------
	render() {
		const {
			changeTransactionMode,
			transactionCodesAndTransactionTypes,
			disableNoPay,
			selectedTransactionMode,
		} = this.props;

		return (
			<React.Fragment>
				{transactionCodesAndTransactionTypes &&
					transactionCodesAndTransactionTypes.requestStatus === 1 &&
					transactionCodesAndTransactionTypes.transactionCodes
						.transactionModes && (
						<div className="TMS-A1-select-input-field-container">
							<Select
								width={112}
								height={32}
								onChange={(e) => changeTransactionMode(e)}
								value={selectedTransactionMode}
							>
								{disableNoPay
									? Object.entries(
											transactionCodesAndTransactionTypes.transactionCodes
												.transactionModes
									  )
											.filter((mode) => mode[0] !== "0")
											.map((mode) => (
												<option key={mode[0]} value={mode[0]}>
													{mode[1]}
												</option>
											))
									: Object.entries(
											transactionCodesAndTransactionTypes.transactionCodes
												.transactionModes
									  ).map((mode) => (
											<option key={mode[0]} value={mode[0]}>
												{mode[1]}
											</option>
									  ))}
							</Select>
						</div>
					)}
			</React.Fragment>
		);
	}
}

const mapStateToProps = ({ transactionCodesAndTransactionTypes }) => ({
	transactionCodesAndTransactionTypes,
});

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default connect(mapStateToProps)(TransactionModeSelect);
