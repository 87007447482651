// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import {
  Text,
  Table,
  Position,
  Popover,
  Menu,
  IconButton,
  Dialog,
  toaster,
  ChangesIcon,
  MoreIcon,
  Pane,
  Tablist,
  Tab,
  Paragraph,
  Spinner,
} from "evergreen-ui";
import { connect } from "react-redux";
import {
  getTransactionsDetails,
  togglePremiumFeatureFlag,
} from "../../actions";
import {
  toDateFormat16,
  toDateFormat,
  slotTimeConverter12,
  resourcesNotLoadedToast,
  generateCurrencySymbol,
} from "../../utils";
import TransactionsDialogContent from "../../components/TransactionsDialogContent";

// -----------------------------------------------------------------------
// CancellationTable Component
// -----------------------------------------------------------------------
class CancellationTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMenuPopover: true,
      showTransactionsDialog: false,
      activeBookingId: "",
      selectedIndex: 0,
      loader: false,
      tabs: [],
    };
  }

  // -----------------------------------------------------------------------
  //  handleTransactionsDialog()
  // -----------------------------------------------------------------------
  handleTransactionsDialog = async (type) => {
    if (type === "hide") {
      await this.setState({ showTransactionsDialog: false });
    }
    if (type === "show") {
      await this.props.dispatch(
        getTransactionsDetails(this.state.activeBookingId)
      );
      if (this.props.transactionsDetails) {
        if (this.props.transactionsDetails.resourcesNotLoadedMessage) {
          resourcesNotLoadedToast();
        } else if (this.props.transactionsDetails.httpErrorMessage) {
          toaster.danger(this.props.transactionsDetails.httpErrorMessage, {
            id: "HTTP-ERROR-TOAST-1",
          });
        } else if (this.props.transactionsDetails.requestStatus === -1) {
          toaster.notify("Sorry, you don't have permission to do that");
        } else if (this.props.transactionsDetails.requestStatus === 1) {
          await this.setState({ showTransactionsDialog: true });
        } else if (this.props.transactionsDetails.requestStatus === 0) {
          toaster.danger(this.props.transactionsDetails.message);
        }
      }
    }
  };

  // -----------------------------------------------------------------------
  //  changeActiveReportDetails()
  // -----------------------------------------------------------------------
  changeActiveReportDetails = async (bookingId) => {
    await this.setState({ activeBookingId: bookingId });
  };

  handleTabSelect = (index) => {
    this.setState({ loader: true }, () => {
      // The loader state is now true
      this.setState({ selectedIndex: index, loader: false });
      // The loader state is now false
    });
  };
  // -----------------------------------------------------------------------
  // render()
  // -----------------------------------------------------------------------
  render() {
    const { showMenuPopover, selectedIndex, showTransactionsDialog } =
      this.state;
    const { reportsData, transactionsDetails } = this.props;
    return (
      <React.Fragment>
        <Tablist marginY={20} marginRight={24}>
          {reportsData?.data.map((club, index) => (
            <Tab
              aria-controls={`panel-${club}`}
              isSelected={index === selectedIndex}
              key={club?.clubId}
              onSelect={() => this.handleTabSelect(index)}
            >
              {club.clubName}
            </Tab>
          ))}
        </Tablist>
        <Pane background="tint1" flex="1">
          {reportsData?.data.map((club, index) => (
            <Pane
              aria-labelledby={club}
              aria-hidden={index !== selectedIndex}
              display={index === selectedIndex ? "block" : "none"}
              key={club?.clubId}
              role="tabpanel"
            >
              <div className="RT-A1-reports-table-container">
                <Table>
                  <Table.Head>
                    <Table.TextHeaderCell className="RT-A1-table-text-header-cell RT-A1-flex-basis-180">
                      Timestamp
                    </Table.TextHeaderCell>
                    <Table.TextHeaderCell className="RT-A1-table-text-header-cell RT-A1-flex-basis-222">
                      Booking Detail
                    </Table.TextHeaderCell>
                    <Table.TextHeaderCell className="RT-A1-table-text-header-cell RT-A1-flex-basis-175">
                      Customer Detail
                    </Table.TextHeaderCell>
                    <Table.TextHeaderCell className="RT-A1-table-text-header-cell RT-A1-flex-basis-112">
                      Paid / Mode
                    </Table.TextHeaderCell>
                    <Table.TextHeaderCell className="RT-A1-table-text-header-cell RT-A1-flex-basis-112">
                      Refund
                    </Table.TextHeaderCell>
                    <Table.TextHeaderCell className="RT-A1-table-text-header-cell RT-A1-flex-basis-134">
                      Place
                    </Table.TextHeaderCell>
                    <Table.TextHeaderCell className="RT-A1-table-text-header-cell RT-A1-flex-basis-56" />
                  </Table.Head>
                  <Table.Body className="RT-A1-table-body" overflow="none">
                    {club &&
                      club.data &&
                      club.data.map((data, index) => (
                        <Table.Row
                          isSelectable
                          className="RT-A1-table-row"
                          key={index}
                        >
                          <Table.Cell className="RT-A1-table-cell RT-A1-flex-basis-180">
                            <div className="RT-A1-table-cell-data-container">
                              <Text id="RT-A1-label-type-one" marginBottom={8}>
                                {toDateFormat16(
                                  data.cancellationTimestamp.split(" ")[0]
                                )}
                              </Text>
                              <Text id="RT-A1-label-type-two" marginBottom={8}>
                                {data.cancellationTimestamp.split(" ")[1]}
                              </Text>
                            </div>
                          </Table.Cell>
                          <Table.Cell className="RT-A1-table-cell RT-A1-flex-basis-222">
                            <div className="RT-A1-table-cell-data-container">
                              <Text
                                id="RT-A1-label-type-three"
                                marginBottom={8}
                              >
                                {`${data.sportName} ${data.bookingId}`}
                              </Text>
                              <Text id="RT-A1-label-type-two" marginBottom={8}>
                                {`${toDateFormat(
                                  data.slotDate
                                )} | ${slotTimeConverter12(
                                  data.startTime,
                                  data.endTime
                                )}`}
                              </Text>
                              <Text id="RT-A1-label-type-two" marginBottom={8}>
                                {data.court}
                              </Text>
                              {data.remarks !== "" && (
                                <Text
                                  id="RT-A1-label-type-two"
                                  marginBottom={8}
                                >
                                  {data.remarks}
                                </Text>
                              )}
                            </div>
                          </Table.Cell>
                          <Table.Cell className="RT-A1-table-cell RT-A1-flex-basis-175">
                            <div className="RT-A1-table-cell-data-container">
                              <Text
                                id="RT-A1-label-type-three"
                                marginBottom={8}
                              >
                                {data.customerName}
                              </Text>
                              <Text id="RT-A1-label-type-two" marginBottom={8}>
                                {data.customerMobile}
                              </Text>
                              <Text id="RT-A1-label-type-two" marginBottom={8}>
                                {data.customerEmail}
                              </Text>
                            </div>
                          </Table.Cell>
                          <Table.Cell className="RT-A1-table-cell RT-A1-flex-basis-112">
                            <div className="RT-A1-table-cell-data-container">
                              <Text
                                id="RT-A1-label-type-three"
                                marginBottom={8}
                              >
                                {`${generateCurrencySymbol()} ${data.paid}`}
                              </Text>
                              <Text id="RT-A1-label-type-two" marginBottom={8}>
                                {data.paymentMode}
                              </Text>
                            </div>
                          </Table.Cell>
                          <Table.Cell className="RT-A1-table-cell RT-A1-flex-basis-112">
                            <div className="RT-A1-table-cell-data-container">
                              <Text
                                id="RT-A1-label-type-three"
                                marginBottom={8}
                              >
                                {`${generateCurrencySymbol()} ${data.refund}`}
                              </Text>
                            </div>
                          </Table.Cell>
                          <Table.Cell className="RT-A1-table-cell RT-A1-flex-basis-134">
                            <div className="RT-A1-table-cell-data-container">
                              <Text id="RT-A1-label-type-two" marginBottom={8}>
                                {`${data.place} `}
                              </Text>
                              {/* <Text id="RT-A1-label-type-two" marginBottom={8}>
                            {data.handler}
                          </Text> */}
                            </div>
                          </Table.Cell>
                          <Table.Cell className="RT-A1-table-cell RT-A1-flex-basis-56">
                            <div className="RT-A1-action-btn-container">
                              {showMenuPopover && (
                                <Popover
                                  onOpen={() =>
                                    this.changeActiveReportDetails(
                                      data.bookingId
                                    )
                                  }
                                  content={
                                    <Menu>
                                      <Menu.Group>
                                        <Menu.Item
                                          icon={<ChangesIcon />}
                                          onSelect={() =>
                                            this.handleTransactionsDialog(
                                              "show"
                                            )
                                          }
                                        >
                                          Transactions
                                        </Menu.Item>
                                      </Menu.Group>
                                    </Menu>
                                  }
                                  position={Position.BOTTOM_RIGHT}
                                >
                                  <IconButton
                                    appearance="minimal"
                                    icon={<MoreIcon />}
                                    width={24}
                                    height={24}
                                  />
                                </Popover>
                              )}
                            </div>
                          </Table.Cell>
                        </Table.Row>
                      ))}
                  </Table.Body>
                </Table>
              </div>

              <Dialog
                width="fit-content"
                isShown={showTransactionsDialog}
                title="Transactions"
                onCloseComplete={() => this.handleTransactionsDialog("hide")}
                hasFooter={false}
              >
                <TransactionsDialogContent
                  transactionsDetails={transactionsDetails}
                />
              </Dialog>
            </Pane>
          ))}
        </Pane>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ reportsData, transactionsDetails }) => ({
  reportsData,
  transactionsDetails,
});

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default connect(mapStateToProps)(CancellationTable);
