// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import { Heading } from "evergreen-ui";

// -----------------------------------------------------------------------
// Header Component
// -----------------------------------------------------------------------
class Header extends React.Component {
  // -----------------------------------------------------------------------
  // render()
  // -----------------------------------------------------------------------
  render() {
    return (
      <React.Fragment>
        <div className="H-A1-wrapper">
          <Heading id="H-A1-label-type-one">{this.props.headerText}</Heading>
        </div>
      </React.Fragment>
    );
  }
}

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default Header;
