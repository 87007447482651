// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import { Pagination } from "antd";

// -----------------------------------------------------------------------
// CustomizedPagination Component
// -----------------------------------------------------------------------
class CustomizedPagination extends React.Component {
  // -----------------------------------------------------------------------
  // render()
  // -----------------------------------------------------------------------
  render() {
    const { currentPage, totalPages, handlePageChange } = this.props;
    return (
      <React.Fragment>
        <Pagination
          className="CP-A1-pagination"
          size="small"
          current={currentPage}
          total={totalPages * 10}
          onChange={handlePageChange}
        />
      </React.Fragment>
    );
  }
}

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default CustomizedPagination;
