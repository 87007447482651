// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import { connect } from "react-redux";
import {
  Text,
  Table,
  Avatar,
  Badge,
  IconButton,
  Popover,
  Menu,
  Dialog,
  toaster,
  Position,
  Pane,
  PeopleIcon,
  CircleArrowRightIcon,
  EditIcon,
  TrashIcon,
  MoreIcon,
} from "evergreen-ui";
import {
  toDateTimeFormat15,
  resourcesNotLoadedToast,
  toDateAndTimeFormat,
} from "../../utils";
import {
  getTransactionsDetails,
  togglePremiumFeatureFlag,
  generateBookingStatement,
} from "../../actions";
import CustomizedPagination from "../../components/CustomizedPagination";
import TransactionsDialogContent from "../../components/TransactionsDialogContent";
import CancelBookingDialogContent from "../../components/CancelBookingDialogContent";
import RescheduleBookingDialogContent from "../../components/RescheduleBookingDialogContent";

// -----------------------------------------------------------------------
// RegularBookingTable Component
// -----------------------------------------------------------------------
class RegularBookingTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showTransactionsDialog: false,
      showCancelBookingDialog: false,
      selectedBookingId: "",
      showRescheduleBookingDialogOnRegularBookingTablePage: false,
      activeBookingId: "",
      activeCourts: "",
      activeDateBooked: "",
      activeSlotStartTime: "",
      activeSlotEndTime: "",
      isPlayoBooking: false,
    };
  }

  // -----------------------------------------------------------------------
  //  handleTransactionsDialog()
  // -----------------------------------------------------------------------
  handleTransactionsDialog = async (type, bookingId = null) => {
    if (type === "hide") {
      await this.setState({ showTransactionsDialog: false });
    }
    if (type === "show") {
      await this.props.dispatch(getTransactionsDetails(bookingId));
      if (this.props.transactionsDetails) {
        if (this.props.transactionsDetails.resourcesNotLoadedMessage) {
          resourcesNotLoadedToast();
        } else if (this.props.transactionsDetails.requestStatus === -1) {
          toaster.notify("Sorry, you don't have permission to do that");
        } else if (this.props.transactionsDetails.httpErrorMessage) {
          toaster.danger(this.props.transactionsDetails.httpErrorMessage, {
            id: "HTTP-ERROR-TOAST-1",
          });
        } else if (this.props.transactionsDetails.requestStatus === 1) {
          await this.setState({ showTransactionsDialog: true });
        } else if (this.props.transactionsDetails.requestStatus === 0) {
          toaster.danger(this.props.transactionsDetails.message);
        }
      }
    }
  };

  // -----------------------------------------------------------------------
  // handleCancelBookingDialogBefore()
  // -----------------------------------------------------------------------
  handleCancelBookingDialogBefore = async (bookingId, isPlayoBooking) => {
    this.setState({ selectedBookingId: bookingId });
    this.setState({ isPlayoBooking: isPlayoBooking });
    await this.handleCancelBookingDialog("open");
  };

  // -----------------------------------------------------------------------
  // handleCancelBookingDialog()
  // -----------------------------------------------------------------------
  handleCancelBookingDialog = async (type) => {
    if (type === "open") {
      await this.setState({ showCancelBookingDialog: true });
    }
    if (type === "close") {
      this.setState({ showCancelBookingDialog: false });
    }
  };

  // -----------------------------------------------------------------------
  // handleRescheduleBookingDialog()
  // -----------------------------------------------------------------------
  handleRescheduleBookingDialog = (type) => {
    if (type === "open") {
      this.setState({
        showRescheduleBookingDialogOnRegularBookingTablePage: true,
      });
    }
    if (type === "close") {
      this.setState({
        showRescheduleBookingDialogOnRegularBookingTablePage: false,
      });
    }
  };

  // -----------------------------------------------------------------------
  // changeActiveBookingDetails()
  // -----------------------------------------------------------------------
  changeActiveBookingDetails = async (bookingDetails) => {
    await this.setState({
      activeBookingId: bookingDetails.bookingId,
      activeCourts: bookingDetails.courts,
      activeDateBooked: bookingDetails.dateBooked,
      activeSlotStartTime: bookingDetails.startTime,
      activeSlotEndTime: bookingDetails.endTime,
    });
  };

  // -----------------------------------------------------------------------
  //  generateStatement()
  // -----------------------------------------------------------------------
  generateStatement = async () => {
    let reqParams = { bookingId: this.state.activeBookingId };
    await this.props.dispatch(generateBookingStatement(reqParams));
    if (this.props.generateBookingStatementToast) {
      if (this.props.generateBookingStatementToast.resourcesNotLoadedMessage) {
        resourcesNotLoadedToast();
      } else if (this.props.generateBookingStatementToast.httpErrorMessage) {
        toaster.danger(
          this.props.generateBookingStatementToast.httpErrorMessage,
          {
            id: "HTTP-ERROR-TOAST-1",
          }
        );
      } else if (
        this.props.generateBookingStatementToast.requestStatus === -1
      ) {
        toaster.notify("Sorry, you don't have permission to do that");
      } else if (this.props.generateBookingStatementToast.requestStatus === 1) {
        // toaster.success(this.props.generateBookingStatementToast.message);
      } else if (this.props.generateBookingStatementToast.requestStatus === 0) {
        toaster.danger(this.props.generateBookingStatementToast.message);
      }
    }
  };

  // -----------------------------------------------------------------------
  // render()
  // -----------------------------------------------------------------------
  render() {
    const {
      showTransactionsDialog,
      showCancelBookingDialog,
      selectedBookingId,
      showRescheduleBookingDialogOnRegularBookingTablePage,
      activeBookingId,
      activeCourts,
      activeDateBooked,
      activeSlotStartTime,
      activeSlotEndTime,
      isPlayoBooking,
    } = this.state;
    const {
      pageNum,
      handlePageChange,
      regularBookingSearchResult,
      handleGetBookingDetails,
      transactionsDetails,
      updateSearchPage,
    } = this.props;
    return (
      <React.Fragment>
        <div className="ST-A1-wrapper">
          <div className="ST-A1-table-container">
            <Table>
              <Table.Head>
                <Table.TextHeaderCell className="ST-A1-table-text-header-cell ST-A1-flex-basis-200">
                  Customer
                </Table.TextHeaderCell>
                <Table.TextHeaderCell className="ST-A1-table-text-header-cell ST-A1-flex-basis-180">
                  Activity
                </Table.TextHeaderCell>
                <Table.TextHeaderCell className="ST-A1-table-text-header-cell ST-A1-flex-basis-220">
                  Booking
                </Table.TextHeaderCell>
                <Table.TextHeaderCell className="ST-A1-table-text-header-cell ST-A1-flex-basis-110">
                  Status
                </Table.TextHeaderCell>
                <Table.TextHeaderCell className="ST-A1-table-text-header-cell ST-A1-flex-basis-160">
                  Source / Timestamp
                </Table.TextHeaderCell>
                <Table.TextHeaderCell className="ST-A1-table-text-header-cell ST-A1-flex-basis-120">
                  Payment
                </Table.TextHeaderCell>
                <Table.TextHeaderCell className="ST-A1-table-text-header-cell ST-A1-flex-basis-48" />
              </Table.Head>
              <Table.Body className="ST-A1-table-body" overflow="none">
                {regularBookingSearchResult &&
                  regularBookingSearchResult.bookingDetails &&
                  regularBookingSearchResult.bookingDetails.map(
                    (booking, index) => (
                      <Table.Row
                        isSelectable
                        className="ST-A1-table-row"
                        key={index}
                      >
                        <Table.Cell className="ST-A1-table-cell ST-A1-flex-basis-200">
                          <div className="ST-A1-table-cell-data-container">
                            <div className="ST-A1-member-avatar-and-name-container">
                              <Avatar
                                className="ST-A1-avatar"
                                name={booking.customerDetails.customerName}
                                size={24}
                                marginRight={12}
                              />
                              <Text id="ST-A1-label-type-one">
                                {booking.customerDetails.customerName}
                              </Text>
                            </div>
                            <Text
                              id="ST-A1-label-type-two"
                              marginLeft={36}
                              marginTop={8}
                            >
                              {booking.customerDetails.customerMobile}
                            </Text>
                            <Text
                              id="ST-A1-label-type-two"
                              marginLeft={36}
                              marginTop={8}
                            >
                              {booking.customerDetails.customerEmail}
                            </Text>
                          </div>
                        </Table.Cell>
                        <Table.Cell className="ST-A1-table-cell ST-A1-flex-basis-180">
                          <div className="ST-A1-table-cell-data-container">
                            <Text id="ST-A1-label-type-three">
                              {booking.sport}
                            </Text>
                          </div>
                        </Table.Cell>
                        <Table.Cell className="ST-A1-table-cell ST-A1-flex-basis-220">
                          <div className="ST-A1-table-cell-data-container">
                            <Text
                              id="ST-A1-label-type-four"
                              onClick={(e) => {
                                const bookingDetails = {
                                  bookingDetails: [booking],
                                };
                                this.props.dispatch({
                                  type: "SET_BOOKING_DETAILS",
                                  payload: bookingDetails,
                                });
                                handleGetBookingDetails(
                                  e,
                                  booking.bookingId,
                                  booking.type
                                );
                              }}
                            >
                              {booking.bookingId}
                            </Text>
                            <Text id="ST-A1-label-type-two" marginTop={8}>
                              {toDateTimeFormat15(
                                booking.dateBooked,
                                booking.startTime,
                                booking.endTime
                              )}
                            </Text>
                            <Text id="ST-A1-label-type-two" marginTop={8}>
                              {`${booking.courts} | ${booking.slotCount} slot(s)`}
                            </Text>
                          </div>
                        </Table.Cell>
                        <Table.Cell className="ST-A1-table-cell ST-A1-flex-basis-110">
                          <div className="ST-A1-table-cell-data-container">
                            <div className="ST-A1-status-container">
                              {booking.bookingStatus === "Upcoming" ? (
                                <div className="ST-A1-status-hint-circle ST-A1-upcoming" />
                              ) : booking.bookingStatus === "Completed" ? (
                                <div className="ST-A1-status-hint-circle ST-A1-completed" />
                              ) : booking.bookingStatus === "Ongoing" ? (
                                <div className="ST-A1-status-hint-circle ST-A1-ongoing" />
                              ) : booking.bookingStatus === "Cancelled" ? (
                                <div className="ST-A1-status-hint-circle ST-A1-cancelled" />
                              ) : null}
                              <Text id="ST-A1-label-type-five">
                                {booking.bookingStatus}
                              </Text>
                            </div>
                          </div>
                        </Table.Cell>
                        <Table.Cell className="ST-A1-table-cell ST-A1-flex-basis-160">
                          <div className="ST-A1-table-cell-data-container">
                            <Text id="ST-A1-label-type-two">
                              {booking.place}
                            </Text>
                            <Text id="ST-A1-label-type-two" marginTop={8}>
                              {toDateAndTimeFormat(booking.bookingTimestamp)}
                            </Text>
                          </div>
                        </Table.Cell>
                        <Table.Cell className="ST-A1-table-cell ST-A1-flex-basis-120">
                          <div className="ST-A1-table-cell-data-container">
                            {booking.balance === 0 &&
                              booking["bookingCancelled"] && (
                                <Badge
                                  width={72}
                                  height={16}
                                  color="neutral"
                                  // isSolid
                                >
                                  Paid
                                </Badge>
                              )}
                            {booking.balance === 0 &&
                              !booking["bookingCancelled"] && (
                                <Badge
                                  width={72}
                                  height={16}
                                  color="green"
                                  // isSolid
                                >
                                  Paid
                                </Badge>
                              )}
                            {booking.balance > 0 &&
                              booking["bookingCancelled"] && (
                                <Badge
                                  width={72}
                                  height={16}
                                  color="neutral"
                                  // isSolid
                                >
                                  Pending
                                </Badge>
                              )}
                            {booking.balance > 0 &&
                              !booking["bookingCancelled"] && (
                                // <Badge width={72} height={16} color="red" isSolid>
                                <Badge width={72} height={16} color="red">
                                  Pending
                                </Badge>
                              )}
                          </div>
                        </Table.Cell>
                        <Table.Cell className="ST-A1-table-cell ST-A1-flex-basis-48">
                          <div className="ST-A1-action-btn-container">
                            <Popover
                              onOpen={() =>
                                this.changeActiveBookingDetails(booking)
                              }
                              content={
                                <Menu>
                                  <Menu.Group>
                                    <Menu.Item
                                      icon={<PeopleIcon />}
                                      onSelect={() =>
                                        this.handleTransactionsDialog(
                                          "show",
                                          booking.bookingId
                                        )
                                      }
                                    >
                                      Transactions
                                    </Menu.Item>
                                    <Menu.Item
                                      icon={<CircleArrowRightIcon />}
                                      onSelect={() => this.generateStatement()}
                                    >
                                      Statements
                                    </Menu.Item>
                                    {!booking["bookingCancelled"] &&
                                      !booking["isPlayoBooking"] && (
                                        <Menu.Item
                                          icon={<EditIcon />}
                                          onSelect={() =>
                                            this.handleRescheduleBookingDialog(
                                              "open"
                                            )
                                          }
                                        >
                                          Reschedule
                                        </Menu.Item>
                                      )}
                                  </Menu.Group>
                                  {!booking["bookingCancelled"] && (
                                    <Menu.Divider />
                                  )}
                                  {!booking["bookingCancelled"] &&
                                    !booking["isPlayoBooking"] && (
                                      <Menu.Group>
                                        <Menu.Item
                                          icon={<TrashIcon />}
                                          intent="danger"
                                          onSelect={() =>
                                            this.handleCancelBookingDialogBefore(
                                              booking.bookingId,
                                              booking.isPlayoBooking
                                            )
                                          }
                                          disabled={booking["bookingCancelled"]}
                                        >
                                          Cancel
                                        </Menu.Item>
                                      </Menu.Group>
                                    )}
                                </Menu>
                              }
                              position={Position.BOTTOM_RIGHT}
                            >
                              <IconButton
                                className="ST-A1-action-btn"
                                appearance="minimal"
                                icon={<MoreIcon />}
                                width={24}
                                height={24}
                              />
                            </Popover>
                          </div>
                        </Table.Cell>
                      </Table.Row>
                    )
                  )}
              </Table.Body>
            </Table>
          </div>
          <div className="ST-A1-pagination-wrapper">
            <CustomizedPagination
              currentPage={pageNum}
              totalPages={
                regularBookingSearchResult &&
                regularBookingSearchResult.paginationData &&
                regularBookingSearchResult.paginationData.total
              }
              handlePageChange={handlePageChange}
            />
          </div>
        </div>
        <Dialog
          width="fit-content"
          isShown={showTransactionsDialog}
          title="Transactions"
          onCloseComplete={() => this.handleTransactionsDialog("hide")}
          hasFooter={false}
        >
          <TransactionsDialogContent
            transactionsDetails={transactionsDetails}
          />
        </Dialog>
        <Dialog
          isShown={showCancelBookingDialog}
          title="Cancel Booking"
          onCloseComplete={() => this.handleCancelBookingDialog("close")}
          hasFooter={false}
        >
          <CancelBookingDialogContent
            bookingId={selectedBookingId}
            handleCancelBookingDialog={this.handleCancelBookingDialog}
            updateSearchPage={updateSearchPage}
            bookingType="regular"
            isPlayoBooking={isPlayoBooking}
          />
        </Dialog>
        <Dialog
          width={592}
          isShown={showRescheduleBookingDialogOnRegularBookingTablePage}
          title="Reschedule Booking"
          onCloseComplete={(e) => this.handleRescheduleBookingDialog("close")}
          hasFooter={false}
        >
          <RescheduleBookingDialogContent
            bookingId={activeBookingId}
            courts={activeCourts}
            dateBooked={activeDateBooked}
            startTime={activeSlotStartTime}
            endTime={activeSlotEndTime}
            handleRescheduleBookingDialog={this.handleRescheduleBookingDialog}
            updateSearchPage={updateSearchPage}
          />
        </Dialog>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({
  transactionsDetails,
  generateBookingStatementToast,
  bookingDetails,
}) => ({
  transactionsDetails,
  generateBookingStatementToast,
  bookingDetails,
});

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default connect(mapStateToProps)(RegularBookingTable);
