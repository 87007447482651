// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import styled from "styled-components";

// -----------------------------------------------------------------------
// Styled Components
// -----------------------------------------------------------------------
const PageBody = styled.div`
  width: 100%;
  min-width: 100vw;
  min-height: 100vh;
  height: 100vh;
  display: flex;
  position: relative;
`;

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default PageBody;
