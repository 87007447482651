// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import {
  // SearchInput,
  Label,
  Button,
  Icon,
  Pill,
  ShoppingCartIcon,
} from "evergreen-ui";
import "react-datepicker/dist/react-datepicker.css";
import ClubActivityListSelect from "../../components/ClubActivityListSelect";
import CustomizedDatePicker from "../../components/CustomizedDatePicker";

// -----------------------------------------------------------------------
// Actionbar Component
// -----------------------------------------------------------------------
class Actionbar extends React.Component {
  // -----------------------------------------------------------------------
  // render()
  // -----------------------------------------------------------------------
  render() {
    const {
      date,
      handleDateChange,
      handleActivityChange,
      selectedActivityValue,
      defaultActivityValue,
      defaultActivityName,
      cartSlotCount,
      showCartDrawer,
      handleShowAvailability,
      disableShowAvailability,
    } = this.props;
    return (
      <div className="HPAB-A1-wrapper">
        <div className="HPAB-A1-left-actions-container HPAB-A1-margin-right-124">
          <div className="HPAB-A1-club-activities-select-container HPAB-A1-margin-right-32">
            <ClubActivityListSelect
              handleActivityChange={handleActivityChange}
              selectedActivityValue={selectedActivityValue}
              defaultActivityValue={defaultActivityValue}
              defaultActivityName={defaultActivityName}
              disabled={disableShowAvailability}
            />
          </div>
          <div className="HPAB-A1-date-picker-wrapper HPAB-A1-margin-right-32">
            <div className="HPAB-A1-label-container HPAB-A1-margin-bottom-8">
              <Label id="HPAB-A1-label-type-one">Date</Label>
            </div>
            <div className="HPAB-A1-date-picker-container">
              <CustomizedDatePicker
                date={date}
                handleDateChange={handleDateChange}
                isDatePickerDisabled={disableShowAvailability}
              />
            </div>
          </div>
          <div className="HPAB-A1-show-availability-btn-container">
            <Button
              className="button"
              width={124}
              onClick={() => handleShowAvailability()}
              disabled={disableShowAvailability}
              appearance="primary"
            >
              Show Availability
            </Button>
          </div>
        </div>
        <div className="HPAB-A1-right-actions-container">
          {/* <div className="HPAB-A1-find-bookings-wrapper HPAB-A1-margin-right-32">
            <div className="HPAB-A1-label-container HPAB-A1-margin-bottom-8">
              <Label id="HPAB-A1-label-type-one">Find Booking</Label>
            </div>
            <div className="HPAB-A1-search-input-container">
              <SearchInput
                placeholder="Booking ID or Phone Number"
                onKeyUp={e => handleSearchOnHomepage(e)}
                onChange={e => handleSearchOnHomepageChange(e)}
              />
            </div>
          </div> */}
          <div
            className="HPAB-A1-cart-icon-wrapper"
            onClick={(e) => showCartDrawer(e)}
          >
            <ShoppingCartIcon
              className="HPAB-A1-shopping-cart-icon"
              cursor={"pointer"}
              size={20}
            />
            <Pill
              className="HPAB-A1-cart-slot-count-pill"
              color={cartSlotCount > 0 ? "red" : "neutral"}
              isSolid={cartSlotCount > 0 ? true : false}
            >
              {cartSlotCount}
            </Pill>
          </div>
        </div>
      </div>
    );
  }
}
// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default Actionbar;
