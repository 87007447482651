// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import {Pane} from "evergreen-ui";
import Chart from "react-apexcharts"

// -----------------------------------------------------------------------
// DayGraph Component
// -----------------------------------------------------------------------
class DayGraph extends React.Component {

    options = {
        chart: {
            id: "basic-bar"
        },
        xaxis: {
            categories: [],
            tickAmount: 10,
            min: 0,
            max: 100,
            labels: {
                formatter: (value) => {
                    return value + "%"
                }
            }
        },
        dataLabels: {
            enabled: false
        },
        tooltip: {
            enabled: true,
            y: {
                formatter: (value) => {
                    return value + "%"
                }
            }
        },
        plotOptions: {
            bar: {
                horizontal: true,
            }
        },
    };

    getFormattedUtilisation = (utilisation) => {
        this.options.xaxis.categories = [];
        const series = [{
            name: "utilisation",
            data: []
        }];
        Object.keys(utilisation).forEach(day => {
            this.options.xaxis.categories.push(day.slice(0, 3));
            series[0].data.push(utilisation[day].percentage)
        });
        return series
    };

    render() {
        const {utilisation, graphType} = this.props;
        const series = this.getFormattedUtilisation(utilisation);
        return (
            graphType === 0 ?
                <Pane padding={16} flex="1">
                    <Chart
                        options={this.options}
                        series={series}
                        type="bar"
                        width="1000"
                        height="400"
                    />
                </Pane> : <div/>
        )
    }
}

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default DayGraph;