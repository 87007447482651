// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import { connect } from "react-redux";
import Navbar from "../../components/Navbar";
import PageHeadingAndActionsNav from "../../components/PageHeadingAndActionsNav";
import {
  Text,
  TextInputField,
  Radio,
  Button,
  toaster,
  Dialog,
  AddIcon,
} from "evergreen-ui";
import CustomizedDatePicker from "../../components/CustomizedDatePicker";
import moment from "moment";
import {
  checkIntOrFloat,
  validateCreditsAndPrice,
  checkPositiveInteger,
  toDateFormat1,
  resourcesNotLoadedToast,
  generateCurrencySymbol,
} from "../../utils";
import {
  createMembershipPackage,
  updateMembershipPackageDetails,
  togglePremiumFeatureFlag,
  getMembershipPackageDetails,
} from "../../actions";
import ClubActivityListSelect from "../../components/ClubActivityListSelect";
import ClubCourtListSelect from "../../components/ClubCourtListSelect";
import { AddCircleOutlineSharp } from "@material-ui/icons";

// -----------------------------------------------------------------------
// CreateMembershipPackages Component
// -----------------------------------------------------------------------
class CreateMembershipPackages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      packageId: "",
      clubId: 1,
      packageName: "",
      packagePrice: "",
      packageRemark: "",
      packageCredits: "",
      packageMemberLimit: "",
      packageAdvanceInfo: [],
      rotationalMembershipRenewal: "",
      proRataMembershipRenewal: "",
      date: new Date(),
      checkedPackageStatusValue: "active",
      checkedPackageTypeValue: "rotational",
      showConfirmDismissDialog: false,
      isRotationalMembershipRenewalDisabled: false,
      isProRataMembershipRenewalDisabled: true,
      isCreatePackageButtonDisabled: true,
      updateStateWithPackageDetailsCount: 0,
      defaultActivityName: "All",
      defaultActivityValue: "all",
      selectedActivityValue: "all",
      defaultCourtName: "All",
      defaultCourtValue: "all",
      selectedCourtValue: "all",
      showAdvanceInformationFields: false,
    };
  }

  // -----------------------------------------------------------------------
  // setStateWithPackageDetails()
  // -----------------------------------------------------------------------
  setStateWithPackageDetails = async () => {
    if (this.props.membershipPackageDetailsForEditing) {
      await this.setState({
        packageName:
          this.props.membershipPackageDetailsForEditing.packageDetails
            .packageName,
        packagePrice:
          this.props.membershipPackageDetailsForEditing.packageDetails
            .packagePrice,
        packageRemark:
          this.props.membershipPackageDetailsForEditing.packageDetails
            .description,
        packageCredits:
          this.props.membershipPackageDetailsForEditing.packageDetails
            .packageCredits,
        packageMemberLimit:
          this.props.membershipPackageDetailsForEditing.packageDetails
            .memberLimit,
        packageAdvanceInfo:
          this.props.membershipPackageDetailsForEditing.packageDetails
            .additionalInfo,
        checkedPackageTypeValue:
          this.props.membershipPackageDetailsForEditing.packageDetails.type
            .toString()
            .toLowerCase(),
        checkedPackageStatusValue: this.props.membershipPackageDetailsForEditing
          .packageDetails.isActive
          ? "active"
          : "deactive",
        date: new Date(
          this.props.membershipPackageDetailsForEditing.packageDetails.startDate
        ),
        rotationalMembershipRenewal:
          this.props.membershipPackageDetailsForEditing.packageDetails.type
            .toString()
            .toLowerCase() === "rotational"
            ? this.props.membershipPackageDetailsForEditing.packageDetails
                .period
            : "",
        proRataMembershipRenewal:
          this.props.membershipPackageDetailsForEditing.packageDetails.type
            .toString()
            .toLowerCase() === "pro-rata"
            ? this.props.membershipPackageDetailsForEditing.packageDetails
                .period
            : "",
        packageId:
          this.props.membershipPackageDetailsForEditing.packageDetails
            .packageId,
      });
      await this.controlCreatePackageButton();
    }
  };

  // -----------------------------------------------------------------------
  // componentDidUpdate()
  // -----------------------------------------------------------------------
  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.updateStateWithPackageDetailsCount === 0 &&
      this.props.membershipPackageDetailsForEditing !== null
    ) {
      this.setState({
        updateStateWithPackageDetailsCount: 1,
      });
      this.setStateWithPackageDetails();
    }
  }

  // -----------------------------------------------------------------------
  // handleInputChange()
  // -----------------------------------------------------------------------
  handleInputChange = async (e, field) => {
    e.persist();
    if (field === "package-name") {
      await this.setState({ packageName: e.target.value.toString() });
      await this.controlCreatePackageButton();
    } else if (field === "package-price") {
      if (validateCreditsAndPrice(e.target.value) === "set") {
        await this.setState({ packagePrice: e.target.value });
      } else if (validateCreditsAndPrice(e.target.value) === "clear") {
        await this.setState({ packagePrice: "" });
      }
      await this.controlCreatePackageButton();
    } else if (field === "package-remark") {
      this.setState({ packageRemark: e.target.value.toString() });
    } else if (field === "package-credits") {
      if (validateCreditsAndPrice(e.target.value) === "set") {
        this.setState({ packageCredits: e.target.value });
      } else if (validateCreditsAndPrice(e.target.value) === "clear") {
        this.setState({ packageCredits: "" });
      }
    } else if (field === "package-member-limit") {
      if (checkPositiveInteger(e.target.value) === "set") {
        this.setState({ packageMemberLimit: e.target.value });
      } else if (checkPositiveInteger(e.target.value) === "clear") {
        this.setState({ packageMemberLimit: "" });
      }
    } else if (field === "rotational-membership-renewal") {
      if (checkPositiveInteger(e.target.value) === "set") {
        this.setState({ rotationalMembershipRenewal: e.target.value });
      } else if (checkPositiveInteger(e.target.value) === "clear") {
        this.setState({ rotationalMembershipRenewal: "" });
      }
    } else if (field === "pro-rata-membership-renewal") {
      if (checkPositiveInteger(e.target.value) === "set") {
        this.setState({ proRataMembershipRenewal: e.target.value });
      } else if (checkPositiveInteger(e.target.value) === "clear") {
        this.setState({ proRataMembershipRenewal: "" });
      }
    }
  };

  // -----------------------------------------------------------------------
  // controlCreatePackageButton()
  // -----------------------------------------------------------------------
  controlCreatePackageButton = () => {
    if (this.state.packageName !== "" && this.state.packagePrice !== "") {
      this.setState({ isCreatePackageButtonDisabled: false });
    } else {
      this.setState({ isCreatePackageButtonDisabled: true });
    }
  };

  // -----------------------------------------------------------------------
  // changeCheckedPackageStatus()
  // -----------------------------------------------------------------------
  changeCheckedPackageStatus = (e) => {
    e.persist();
    this.setState({ checkedPackageStatusValue: e.target.value });
  };

  // -----------------------------------------------------------------------
  // changeCheckedPackageType()
  // -----------------------------------------------------------------------
  changeCheckedPackageType = async (e) => {
    e.persist();
    await this.setState({ checkedPackageTypeValue: e.target.value });
    if (this.state.checkedPackageTypeValue === "rotational") {
      await this.setState({
        isProRataMembershipRenewalDisabled: true,
        isRotationalMembershipRenewalDisabled: false,
      });
    } else if (this.state.checkedPackageTypeValue === "pro-rata") {
      await this.setState({
        isProRataMembershipRenewalDisabled: false,
        isRotationalMembershipRenewalDisabled: true,
      });
    }
  };

  // -----------------------------------------------------------------------
  // handleDateChange()
  // -----------------------------------------------------------------------
  handleDateChange = async (date) => {
    if (moment(date).isBefore(new Date())) {
      toaster.warning("Start date can't be set to a past date");
    } else {
      await this.setState({ date: date });
    }
  };

  // -----------------------------------------------------------------------
  // resetForm()
  // -----------------------------------------------------------------------
  resetForm = async () => {
    await this.setState({
      packageName: "",
      packageId: "",
      packagePrice: "",
      packageRemark: "",
      packageCredits: "",
      packageMemberLimit: "",
      packageAdvanceInfo: [],
      rotationalMembershipRenewal: "",
      proRataMembershipRenewal: "",
      date: new Date(),
      checkedPackageStatusValue: "active",
      checkedPackageTypeValue: "rotational",
      showConfirmDismissDialog: false,
      isRotationalMembershipRenewalDisabled: false,
      isProRataMembershipRenewalDisabled: true,
      isCreatePackageButtonDisabled: true,
      defaultActivityName: "All",
      defaultActivityValue: "all",
      selectedActivityValue: "all",
      showAdvanceInformationFields: false,
    });
  };

  // -----------------------------------------------------------------------
  // handleConfirmDismissDialog()
  // -----------------------------------------------------------------------
  handleConfirmDismissDialog = async (type) => {
    if (type === "show") {
      this.setState({ showConfirmDismissDialog: true });
    } else if (type === "hide") {
      this.setState({ showConfirmDismissDialog: false });
    } else if (type === "confirm") {
      await this.resetForm();
      await this.setState({ showConfirmDismissDialog: false });
      await this.props.handleCreateMembershipPackagesPageDisplay("hide");
      await this.resetAfterLeavingCreateMembershipPackagesPage();
    }
  };

  // -----------------------------------------------------------------------
  // confirmCreatePackage()
  // -----------------------------------------------------------------------
  confirmCreatePackage = async (e) => {
    e.preventDefault();
    if (this.state.packageName !== "" && this.state.packagePrice !== "") {
      let reqBody = {
        packageName: this.state.packageName,
        price: checkIntOrFloat(this.state.packagePrice),
        remarks: this.state.packageRemark,
        credits:
          this.state.packageCredits === ""
            ? 0
            : parseInt(this.state.packageCredits),
        memberLimit:
          this.state.packageMemberLimit === ""
            ? 0
            : this.state.packageMemberLimit,
        additionalInfo: this.state.packageAdvanceInfo,
        type:
          this.state.checkedPackageTypeValue === "rotational"
            ? 1
            : this.state.checkedPackageTypeValue === "pro-rata"
            ? 2
            : 0,
        startDate: toDateFormat1(this.state.date),
        period:
          this.state.checkedPackageTypeValue === "rotational"
            ? this.state.rotationalMembershipRenewal === ""
              ? 1
              : parseInt(this.state.rotationalMembershipRenewal)
            : this.state.checkedPackageTypeValue === "pro-rata"
            ? this.state.proRataMembershipRenewal === ""
              ? 1
              : parseInt(this.state.proRataMembershipRenewal)
            : null,
        isActive:
          this.state.checkedPackageStatusValue === "active" ? true : false,
      };
      await this.props.dispatch(createMembershipPackage(reqBody));
      if (this.props.createMembershipPackageToast) {
        if (this.props.createMembershipPackageToast.resourcesNotLoadedMessage) {
          resourcesNotLoadedToast();
        } else if (this.props.createMembershipPackageToast.httpErrorMessage) {
          toaster.danger(
            this.props.createMembershipPackageToast.httpErrorMessage,
            {
              id: "HTTP-ERROR-TOAST-4",
            }
          );
        } else if (
          this.props.createMembershipPackageToast.requestStatus === -1
        ) {
          toaster.notify("Sorry, you don't have permission to do that");
        } else if (
          this.props.createMembershipPackageToast.requestStatus === 0
        ) {
          toaster.danger(this.props.createMembershipPackageToast.message, {
            id: "HP-TOAST",
          });
        } else if (
          this.props.createMembershipPackageToast.requestStatus === 1
        ) {
          this.resetForm();

          this.props.handleCreateMembershipPackagesPageDisplay("hide");
          await this.resetAfterLeavingCreateMembershipPackagesPage();
          this.props.handleViewMembershipPackagesPageDisplay("show");
          toaster.success(this.props.createMembershipPackageToast.message);
        }
      }
    }
  };

  // -----------------------------------------------------------------------
  // confirmUpdatePackage()
  // -----------------------------------------------------------------------
  confirmUpdatePackage = async (e) => {
    e.preventDefault();
    if (this.state.packageName !== "" && this.state.packagePrice !== "") {
      let reqBody = {
        packageId: this.state.packageId,
        packageName: this.state.packageName,
        price: checkIntOrFloat(this.state.packagePrice),
        remarks: this.state.packageRemark,
        credits:
          this.state.packageCredits === ""
            ? 0
            : parseInt(this.state.packageCredits),
        memberLimit:
          this.state.packageMemberLimit === ""
            ? 0
            : parseInt(this.state.packageMemberLimit),
        additionalInfo: this.state.packageAdvanceInfo,
        type:
          this.state.checkedPackageTypeValue === "rotational"
            ? 1
            : this.state.checkedPackageTypeValue === "pro-rata"
            ? 2
            : 0,
        startDate: toDateFormat1(this.state.date),
        period:
          this.state.checkedPackageTypeValue === "rotational"
            ? this.state.rotationalMembershipRenewal === ""
              ? 1
              : parseInt(this.state.rotationalMembershipRenewal)
            : this.state.checkedPackageTypeValue === "pro-rata"
            ? this.state.proRataMembershipRenewal === ""
              ? 1
              : parseInt(this.state.proRataMembershipRenewal)
            : null,
        isActive:
          this.state.checkedPackageStatusValue === "active" ? true : false,
      };
      await this.props.dispatch(updateMembershipPackageDetails(reqBody));
      if (this.props.updateMembershipPackageDetailsToast) {
        if (
          this.props.updateMembershipPackageDetailsToast
            .resourcesNotLoadedMessage
        ) {
          resourcesNotLoadedToast();
        } else if (
          this.props.updateMembershipPackageDetailsToast.httpErrorMessage
        ) {
          toaster.danger(
            this.props.updateMembershipPackageDetailsToast.httpErrorMessage,
            {
              id: "HTTP-ERROR-TOAST-4",
            }
          );
        } else if (
          this.props.updateMembershipPackageDetailsToast.requestStatus === -1
        ) {
          toaster.notify("Sorry, you don't have permission to do that");
        } else if (
          this.props.updateMembershipPackageDetailsToast.requestStatus === 0
        ) {
          toaster.danger(
            this.props.updateMembershipPackageDetailsToast.message,
            {
              id: "HP-TOAST",
            }
          );
        } else if (
          this.props.updateMembershipPackageDetailsToast.requestStatus === 1
        ) {
          this.resetForm();
          this.props.handleCreateMembershipPackagesPageDisplay("hide");
          await this.resetAfterLeavingCreateMembershipPackagesPage();
          toaster.success(
            this.props.updateMembershipPackageDetailsToast.message
          );
        }
      }
    }
  };

  // -----------------------------------------------------------------------
  // resetAfterLeavingCreateMembershipPackagesPage()
  // -----------------------------------------------------------------------
  resetAfterLeavingCreateMembershipPackagesPage = async () => {
    setTimeout(() => {
      this.props.dispatch({
        type: "SET_MEMBERSHIP_PACKAGE_DETAILS_FOR_EDITING",
        payload: null,
      });
      this.setState({ updateStateWithPackageDetailsCount: 0 });
    }, 500);
  };

  // -----------------------------------------------------------------------
  // handleActivityChange()
  // -----------------------------------------------------------------------
  handleActivityChange = (e) => {
    e.persist();
    this.setState({
      selectedActivityValue: e.target.value,
    });
  };

  // -----------------------------------------------------------------------
  // handleCourtChange()
  // -----------------------------------------------------------------------
  handleCourtChange = (e) => {
    e.persist();
    this.setState({
      selectedCourtValue: e.target.value,
    });
  };

  // -----------------------------------------------------------------------
  // toggleAdvanceInformationFieldsVisibility()
  // -----------------------------------------------------------------------
  toggleAdvanceInformationFieldsVisibility = (type) => {
    this.setState({
      showAdvanceInformationFields: type === "show" ? true : false,
    });
  };

  // -----------------------------------------------------------------------
  // render()
  // -----------------------------------------------------------------------
  render() {
    const {
      date,
      packageName,
      packagePrice,
      packageRemark,
      packageCredits,
      packageMemberLimit,
      rotationalMembershipRenewal,
      proRataMembershipRenewal,
      checkedPackageStatusValue,
      checkedPackageTypeValue,
      isProRataMembershipRenewalDisabled,
      isRotationalMembershipRenewalDisabled,
      showConfirmDismissDialog,
      isCreatePackageButtonDisabled,
      defaultActivityName,
      defaultActivityValue,
      selectedActivityValue,
      showAdvanceInformationFields,
      selectedCourtValue,
      defaultCourtValue,
      defaultCourtName,
    } = this.state;
    const {
      handleCreateMembershipPackagesPageDisplay,
      membershipPackageDetailsForEditing,
    } = this.props;
    return (
      <React.Fragment>
        <div className="CMPP-A1-wrapper">
          <div className="CMPP-A1-header-container">
            <Navbar />
            <PageHeadingAndActionsNav
              headingText={"Create Membership Packages"}
              handleCreateMembershipPackagesPageDisplay={
                handleCreateMembershipPackagesPageDisplay
              }
              resetAfterLeavingCreateMembershipPackagesPage={
                this.resetAfterLeavingCreateMembershipPackagesPage
              }
            />
          </div>
          <div className="CMPP-A1-body-container">
            <div className="CMPP-A1-create-membership-package-form-container">
              <div className="CMPP-A1-row-type-one-content">
                <Text id="CMPP-A1-big-label">Package Details</Text>
              </div>
              <div className="CMPP-A1-row-type-one-content">
                <div className="CMPP-A1-text-input-field-type-one-container">
                  <TextInputField
                    className="CMPP-A1-text-input-field-type-one"
                    label="Name"
                    value={packageName}
                    placeholder="Package Name"
                    onChange={(e) => this.handleInputChange(e, "package-name")}
                  />
                </div>
                <div className="CMPP-A1-text-input-field-type-one-container">
                  <TextInputField
                    className="CMPP-A1-text-input-field-type-one"
                    label="Price"
                    value={packagePrice}
                    placeholder={`Package Cost in ${generateCurrencySymbol()}`}
                    onChange={(e) => this.handleInputChange(e, "package-price")}
                  />
                </div>
              </div>
              <div className="CMPP-A1-row-type-one-content">
                <div className="CMPP-A1-text-input-field-type-one-container">
                  <TextInputField
                    className="CMPP-A1-text-input-field-type-one"
                    label="Remark"
                    value={packageRemark}
                    maxLength="100"
                    placeholder="Summary (Optional)"
                    onChange={(e) =>
                      this.handleInputChange(e, "package-remark")
                    }
                  />
                </div>
                <div className="CMPP-A1-text-input-field-type-one-container">
                  <TextInputField
                    className="CMPP-A1-text-input-field-type-one"
                    label="Credits"
                    value={packageCredits}
                    placeholder="Package Credits (Optional)"
                    onChange={(e) =>
                      this.handleInputChange(e, "package-credits")
                    }
                  />
                </div>
              </div>
              <div className="CMPP-A1-row-type-one-content">
                <div className="CMPP-A1-text-input-field-type-one-container">
                  <TextInputField
                    className="CMPP-A1-text-input-field-type-one"
                    type="number"
                    label="Member Limit"
                    value={packageMemberLimit}
                    placeholder="Number of Members (Optional)"
                    onChange={(e) =>
                      this.handleInputChange(e, "package-member-limit")
                    }
                  />
                </div>
                <div className="CMPP-A1-col-type-one-content">
                  <div className="CMPP-A1-radio-input-wrapper">
                    <div className="CMPP-A1-radio-input-label-container">
                      <Text id="CMPP-A1-medium-label">Status</Text>
                    </div>
                    <div
                      className="CMPP-A1-radio-input-container"
                      aria-label="Radio Group Label 16"
                      role="group"
                    >
                      <Radio
                        className="CMPP-A1-radio-input"
                        checked={checkedPackageStatusValue === "active"}
                        size={16}
                        marginRight={48}
                        name="group10"
                        label="Active"
                        value="active"
                        onChange={(e) => this.changeCheckedPackageStatus(e)}
                      />
                      <Radio
                        className="CMPP-A1-radio-input"
                        checked={checkedPackageStatusValue === "deactive"}
                        size={16}
                        name="group10"
                        label="Deactive"
                        value="deactive"
                        onChange={(e) => this.changeCheckedPackageStatus(e)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="CMPP-A1-row-type-one-content">
                <ClubActivityListSelect
                  handleActivityChange={this.handleActivityChange}
                  defaultActivityName={defaultActivityName}
                  defaultActivityValue={defaultActivityValue}
                  selectedActivityValue={selectedActivityValue}
                  width={336}
                />
                {showAdvanceInformationFields ? (
                  <div className="CMPP-A1-club-court-list-select-container CMPP-A1-margin-left-40">
                    <ClubCourtListSelect
                      handleCourtChange={this.handleCourtChange}
                      defaultCourtValue={defaultCourtValue}
                      defaultCourtName={defaultCourtName}
                      selectedCourtValue={selectedCourtValue}
                      width={336}
                    />
                  </div>
                ) : (
                  <Button
                    className="button CMPP-A1-align-self-flex-end"
                    marginLeft={40}
                    marginRight={16}
                    width={164}
                    height={32}
                    appearance="minimal"
                    intent="none"
                    iconBefore={<AddIcon />}
                    onClick={() =>
                      this.toggleAdvanceInformationFieldsVisibility("show")
                    }
                  >
                    Advance Information
                  </Button>
                )}
              </div> */}
              <div className="CMPP-A1-horizontal-line" />
              {membershipPackageDetailsForEditing && (
                <div className="CMPP-A1-top-row-type-two-content">
                  <div className="CMPP-A1-col-type-one-content CMPP-A1-margin-right-52">
                    <Text marginBottom={4} id="CMPP-A1-medium-label">
                      Type
                    </Text>
                    <Text id="CMPP-A1-large-type-label">
                      {membershipPackageDetailsForEditing.packageDetails.type}
                    </Text>
                  </div>
                  <div className="CMPP-A1-text-input-field-type-two-container">
                    {membershipPackageDetailsForEditing &&
                    membershipPackageDetailsForEditing.packageDetails.type
                      .toString()
                      .toLowerCase() === "rotational" ? (
                      <TextInputField
                        className="CMPP-A1-text-input-field-type-two"
                        type="number"
                        label="Membership Renewal"
                        value={rotationalMembershipRenewal}
                        placeholder="0"
                        onChange={(e) =>
                          this.handleInputChange(
                            e,
                            "rotational-membership-renewal"
                          )
                        }
                      />
                    ) : (
                      <TextInputField
                        className="CMPP-A1-text-input-field-type-two"
                        type="number"
                        label="Membership Renewal"
                        value={proRataMembershipRenewal}
                        placeholder="0"
                        onChange={(e) =>
                          this.handleInputChange(
                            e,
                            "pro-rata-membership-renewal"
                          )
                        }
                      />
                    )}
                  </div>
                </div>
              )}
              {!membershipPackageDetailsForEditing && (
                <React.Fragment>
                  <div className="CMPP-A1-top-row-type-two-content">
                    <Text id="CMPP-A1-medium-label">Type</Text>
                  </div>
                  <div className="CMPP-A1-row-type-two-content">
                    <div className="CMPP-A1-col-type-one-content">
                      <div
                        className="CMPP-A1-radio-input-container"
                        aria-label="Radio Group Label 16"
                        role="group"
                      >
                        <Radio
                          className="CMPP-A1-radio-input"
                          checked={checkedPackageTypeValue === "rotational"}
                          size={16}
                          name="group11"
                          label="Rotational"
                          value="rotational"
                          onChange={(e) => this.changeCheckedPackageType(e)}
                        />
                      </div>
                      <div className="CMPP-A1-general-row-type-one-container">
                        <div
                          className={
                            isRotationalMembershipRenewalDisabled
                              ? "CMPP-A1-text-input-field-type-two-container CMPP-A1-text-input-field-container-disabled"
                              : "CMPP-A1-text-input-field-type-two-container"
                          }
                        >
                          <TextInputField
                            disabled={isRotationalMembershipRenewalDisabled}
                            className="CMPP-A1-text-input-field-type-two"
                            type="number"
                            label="Membership Renewal"
                            value={rotationalMembershipRenewal}
                            placeholder="0"
                            onChange={(e) =>
                              this.handleInputChange(
                                e,
                                "rotational-membership-renewal"
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="CMPP-A1-vertical-line" />
                    <div className="CMPP-A1-col-type-one-content">
                      <div
                        className="CMPP-A1-radio-input-container"
                        aria-label="Radio Group Label 16"
                        role="group"
                      >
                        <Radio
                          className="CMPP-A1-radio-input"
                          checked={checkedPackageTypeValue === "pro-rata"}
                          size={16}
                          name="group11"
                          label="Pro-rata"
                          value="pro-rata"
                          onChange={(e) => this.changeCheckedPackageType(e)}
                        />
                      </div>
                      <div className="CMPP-A1-general-row-type-one-container">
                        <div className="CMPP-A1-col-type-two-content">
                          <Text
                            id={
                              isProRataMembershipRenewalDisabled
                                ? "CMPP-A1-medium-disabled-label"
                                : "CMPP-A1-medium-label"
                            }
                          >
                            Set a Start Date
                          </Text>
                          <CustomizedDatePicker
                            date={date}
                            handleDateChange={this.handleDateChange}
                            isDatePickerDisabled={
                              isProRataMembershipRenewalDisabled
                            }
                          />
                        </div>
                        <div
                          className={
                            isProRataMembershipRenewalDisabled
                              ? "CMPP-A1-text-input-field-type-two-container CMPP-A1-text-input-field-container-disabled"
                              : "CMPP-A1-text-input-field-type-two-container"
                          }
                        >
                          <TextInputField
                            disabled={isProRataMembershipRenewalDisabled}
                            className="CMPP-A1-text-input-field-type-two"
                            type="number"
                            label="Membership Renewal"
                            value={proRataMembershipRenewal}
                            placeholder="0"
                            onChange={(e) =>
                              this.handleInputChange(
                                e,
                                "pro-rata-membership-renewal"
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              )}
              <div className="CMPP-A1-top-row-type-three-content">
                <Dialog
                  width={560}
                  isShown={showConfirmDismissDialog}
                  title="Warning"
                  intent="danger"
                  confirmLabel="Confirm"
                  onConfirm={(e) => this.handleConfirmDismissDialog("confirm")}
                  onCloseComplete={(e) =>
                    this.handleConfirmDismissDialog("hide")
                  }
                >
                  <Text id="CMPP-A1-medium-dark-label">
                    All the details will be cleared. Do you wish to continue?
                  </Text>
                </Dialog>
                <Button
                  className="button"
                  width={152}
                  height={32}
                  marginRight={36}
                  onClick={(e) => this.handleConfirmDismissDialog("show")}
                >
                  Dismiss
                </Button>
                {membershipPackageDetailsForEditing ? (
                  <Button
                    className="button"
                    width={152}
                    height={32}
                    appearance="primary"
                    intent="success"
                    disabled={isCreatePackageButtonDisabled}
                    onClick={(e) => this.confirmUpdatePackage(e)}
                  >
                    save
                  </Button>
                ) : (
                  <Button
                    className="button"
                    width={152}
                    height={32}
                    appearance="primary"
                    intent="success"
                    disabled={isCreatePackageButtonDisabled}
                    onClick={(e) => this.confirmCreatePackage(e)}
                  >
                    Create Package
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({
  createMembershipPackageToast,
  membershipPackageDetailsForEditing,
  updateMembershipPackageDetailsToast,
}) => ({
  createMembershipPackageToast,
  membershipPackageDetailsForEditing,
  updateMembershipPackageDetailsToast,
});

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default connect(mapStateToProps)(CreateMembershipPackages);
