import { Pane, Button, Text } from "evergreen-ui";
import React from "react";

export const MemberPagination = ({ currentPage, totalPages, onPageChange }) => {
  const visiblePageLinks = 5; // Number of visible page links around the current page
  const pageLinks = [];

  for (
    let i = Math.max(1, currentPage - Math.floor(visiblePageLinks / 2));
    i <= Math.min(totalPages, currentPage + Math.floor(visiblePageLinks / 2));
    i++
  ) {
    pageLinks.push(i);
  }

  return (
    <Pane
      display="flex"
      justifyContent="center"
      alignItems="center"
      marginY={16}
    >
      {currentPage > 1 && (
        <Button
          appearance="minimal"
          onClick={() => onPageChange(currentPage - 1)}
        >
          &lt; Previous
        </Button>
      )}
      {pageLinks.map((page) => (
        <Text
          key={page}
          className={`pagination-item ${currentPage === page ? "active" : ""}`}
          onClick={() => onPageChange(page)}
          marginLeft={8}
          marginRight={8}
          fontWeight={currentPage === page ? "bold" : "normal"}
          cursor="pointer"
        >
          {page}
        </Text>
      ))}
      {currentPage < totalPages && (
        <Button
          appearance="minimal"
          onClick={() => onPageChange(currentPage + 1)}
        >
          Next &gt;
        </Button>
      )}
    </Pane>
  );
};
