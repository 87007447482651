// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import { connect } from "react-redux";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import {
  Button,
  Text,
  IconButton,
  Pane,
  TextInputField,
  Strong,
  Menu,
  Dialog,
  CrossIcon,
  ChevronDownIcon,
  CaretRightIcon,
} from "evergreen-ui";

import TransactionModeSelect from "../TransactionModeSelect";

import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";

import { updateExtraPayment } from "../../actions";
import {
  resourcesNotLoadedToast,
  validateCreditsAndPrice,
  generateCurrencySymbol,
} from "../../utils";

import { toaster } from "evergreen-ui/commonjs/toaster";

const theme = createMuiTheme({
  overrides: {
    // Style sheet name ⚛️
    MuiAccordionSummary: {
      // Name of the rule
      content: {
        justifyContent: "space-between",
      },
    },
  },
});

// -----------------------------------------------------------------------
// ExtrasActions Component
// -----------------------------------------------------------------------
class ExtrasPayNow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showExtrasDialog: false,
      showPayNowExtraDialog: false,
      extraBalance: "",
      ebookingId: "",
      showRemarksDiv: true,
      showRemarksTextArea: false,
      modifiedRemarks: "",
      showPayNowDialog: false,
      selectedPaymentMode: "No Pay",
      payNowAmount: "",
      showTransactionsDialog: false,
      paymentMode: "No Pay",
      selectedTransactionMode: 0,
      disableNoPay: false,
      transactionMode: 0,
      isPayNowAmountInvalid: false,
      discount: 0,
      isDiscountAmountInvalid: false,
      getInTouchDialogIsShown: false,
      getInTouchData: {},
      editBookingAmountDialogIsShown: false,
      editBookingAmountData: {},
    };
  }

  // -----------------------------------------------------------------------
  //  handleExtraPayNow
  // -----------------------------------------------------------------------
  handleExtraPayNow = async (e, type, extra) => {
    let data = this.props.extraPayNowData;

    if (type === "show-dialog") {
      this.setState({
        showPayNowExtraDialog: true,
        extraBalance: data.balance,
        ebookingId: data.id,
      });
    }
    if (type === "hide-dialog") {
      this.setState({
        showPayNowExtraDialog: false,
        disableNoPay: false,
        paymentMode: "No Pay",
        selectedPaymentMode: "No Pay",
        transactionMode: 0,
        selectedTransactionMode: 0,
      });
    }
    if (type === "amount-change") {
      if (validateCreditsAndPrice(e.target.value) === "set") {
        await this.setState({
          payNowAmount: e.target.value,
          isPayNowAmountInvalid: false,
        });
        if (
          this.state.selectedTransactionMode === 0 &&
          this.state.payNowAmount > 0
        ) {
          await this.setState({
            paymentMode: "Cash",
            transactionMode: 1,
            selectedTransactionMode: 1,
            disableNoPay: true,
          });
        }
        if (this.state.payNowAmount <= 0) {
          await this.setState({
            paymentMode: "No Pay",
            transactionMode: 0,
            selectedTransactionMode: 0,
            disableNoPay: false,
          });
        }
      } else if (validateCreditsAndPrice(e.target.value) === "clear") {
        await this.setState({
          payNowAmount: "",
          isPayNowAmountInvalid: false,
          paymentMode: "No Pay",
          transactionMode: 0,
          selectedTransactionMode: 0,
          disableNoPay: false,
        });
      }
    }
    if (type === "apply-discount") {
      if (!isNaN(e.target.value) && parseFloat(e.target.value) >= 0) {
        await this.setState({
          discount: e.target.value,
          isDiscountAmountInvalid: false,
        });
      } else {
        await this.setState({
          isDiscountAmountInvalid: true,
        });
      }
    }
    if (type === "confirm") {
      this.setState({
        balance: extra.balance,
      });
      if (this.state.payNowAmount > this.state.balance) {
        toaster.warning("Amount is greater than balance", {
          id: "BDOCP-TOAST-6",
        });
      } else if (this.state.payNowAmount === "") {
        toaster.warning("Amount is required", {
          id: "BDOCP-TOAST-6",
        });
        await this.setState({ isPayNowAmountInvalid: true });
      } else if (this.state.isDiscountAmountInvalid) {
        toaster.warning("Invalid discount amount", {
          id: "BDOCP-TOAST-6",
        });
        await this.setState({ isPayNowAmountInvalid: true });
      } else {
        const reqBody = {
          bookingId: this.props.bookingId,
          ebookingId: extra.id,
          amount: parseFloat(this.state.payNowAmount),
          discount: parseFloat(this.state.discount),
          transactionData: {
            mode: this.state.selectedTransactionMode,
            type: 3,
            handler: "ground_admin",
            place: "partner",
          },
        };

        await this.props.dispatch(updateExtraPayment(reqBody));
        if (this.props.updateExtraPaymentToast) {
          if (this.props.updateExtraPaymentToast.resourcesNotLoadedMessage) {
            resourcesNotLoadedToast();
          } else if (this.props.updateExtraPaymentToast.httpErrorMessage) {
            toaster.danger(
              this.props.updateExtraPaymentToast.httpErrorMessage,
              {
                id: "HTTP-ERROR-TOAST-1",
              }
            );
          } else if (this.props.updateExtraPaymentToast.requestStatus === -1) {
            toaster.notify("Sorry, you don't have permission to do that");
          } else if (this.props.updateExtraPaymentToast.requestStatus === 1) {
            toaster.success(this.props.updateExtraPaymentToast.message);
            await this.setState({ payNowAmount: 0 });
            await this.setState({ discount: 0 });
            await this.setState({ showPayNowExtraDialog: false });

            this.props.handleExtraPayNow("close");

            // await this.props.dispatch(
            // 	getBookingDetails(
            // 		this.props.bookingDetails.bookingDetails[0].bookingId
            // 	)
            // );
            // if (this.props.handleShowAvailability) {
            // 	await this.props.handleShowAvailability();
            // }
            //toaster.success(this.props.updateBookingPaymentToast.message);
          } else if (this.props.updateExtraPaymentToast.requestStatus === 0) {
            toaster.warning(this.props.updateExtraPaymentToast.message);
          }
          //else if (this.props.updateBookingPaymentToast.requestStatus === 0) {
          // 	// await this.setState({ showPayNowDialog: false });
          // 	toaster.danger(this.props.updateBookingPaymentToast.message, {
          // 		id: "BDOCP-TOAST-5",
          // 	});
          // }
        }
      }
    }
  };

  // -----------------------------------------------------------------------
  // changeTransactionMode()
  // -----------------------------------------------------------------------
  changeTransactionMode = (e) => {
    e.persist();
    if (this.state.payNowAmount.length > 0) {
      this.setState({
        paymentMode:
          e.nativeEvent.target[
            e.nativeEvent.target.selectedIndex
          ].text.toString(),
        selectedTransactionMode: parseInt(e.target.value),
        isPayNowAmountInvalid: false,
      });
    } else {
      this.setState({ isPayNowAmountInvalid: true });
    }
  };

  // -----------------------------------------------------------------------
  // render()
  // -----------------------------------------------------------------------
  render() {
    const {
      isPayNowAmountInvalid,
      disableNoPay,
      selectedTransactionMode,
      isDiscountAmountInvalid,
    } = this.state;
    const { extraPayNow, handleExtraPayNow, extraPayNowData } = this.props;
    let length = extraPayNowData && extraPayNowData.length;

    return (
      <React.Fragment>
        <Dialog
          width={700}
          isShown={extraPayNow}
          title="Pay Now"
          hasFooter={false}
          hasHeader={false}
          onCloseComplete={(e) => handleExtraPayNow("close")}
        >
          <Pane
            marginBottom={7}
            display={"flex"}
            flexDirection={"row"}
            justifyContent="space-between"
            alignItems={"center"}
          >
            <Strong size={400}>Pay Now (Addons)</Strong>
            <IconButton
              appearance="minimal"
              icon={<CrossIcon />}
              onClick={(e) => handleExtraPayNow("close")}
            />
          </Pane>
          <Menu.Divider />
          <Pane display={"flex"} flexDirection="column" marginTop={10}>
            {extraPayNowData &&
              extraPayNowData.map((extra) => (
                <Pane display={"flex"} flexDirection={"column"}>
                  <Accordion
                    style={{
                      boxShadow: "none",
                      pointerEvents:
                        extra.balance > 0 && length !== 1 ? "" : "none",
                    }}
                    rounded={false}
                    // onClick={() =>
                    // 	this.setState({
                    // 		//isShown: !this.state.isShown,
                    // 	})
                    // }
                  >
                    <ThemeProvider theme={theme}>
                      <AccordionSummary
                        expandIcon={
                          extra.balance > 0 && length !== 1 ? (
                            <Pane background="tint2" elevation={1}>
                              <ChevronDownIcon appearance="minimal" />
                            </Pane>
                          ) : (
                            <IconButton
                              appearance="minimal"
                              border={"1px solid #eff0f3"}
                              icon=""
                            />
                          )
                        }
                        aria-controls="panel1bh-content"
                        justifyContent="space-between"
                        id="panel1bh-header"
                      >
                        <CaretRightIcon color="muted" marginTop={5} />
                        <Pane
                          display={"flex"}
                          flexDirection="column"
                          width={100}
                        >
                          <Pane
                            display={"flex"}
                            flexDirecticolor="muted"
                            justifyContent="space-between"
                            marginTop={5}
                          >
                            <Text color="muted" size={300}>
                              Name
                            </Text>
                          </Pane>
                          <Pane
                            display={"flex"}
                            flexDirection="row"
                            justifyContent="space-between"
                          >
                            <Strong color="muted" size={300}>
                              {extra.items}
                            </Strong>
                          </Pane>
                        </Pane>

                        <Pane display={"flex"} flexDirection="column">
                          <Pane
                            display={"flex"}
                            flexDirection="row"
                            marginTop={5}
                            justifyContent="space-between"
                          >
                            <Text color="muted" size={300}>
                              Quantity
                            </Text>
                          </Pane>
                          <Pane
                            display={"flex"}
                            flexDirection="row"
                            justifyContent="space-between"
                          >
                            <Strong color="muted" size={300}>
                              {extra.quantity}
                            </Strong>
                          </Pane>
                        </Pane>
                        <Pane display={"flex"} flexDirection="column">
                          <Pane
                            display={"flex"}
                            flexDirection="row"
                            marginTop={5}
                            justifyContent="space-between"
                          >
                            <Text color="muted" size={300}>
                              Gross
                            </Text>
                          </Pane>
                          <Pane
                            display={"flex"}
                            flexDirection="row"
                            justifyContent="space-between"
                          >
                            <Strong color="muted" size={300}>
                              {extra.grossAmount}
                            </Strong>
                          </Pane>
                        </Pane>
                        <Pane display={"flex"} flexDirection="column">
                          <Pane
                            display={"flex"}
                            marginTop={5}
                            flexDirection="row"
                            justifyContent="space-between"
                          >
                            <Text color="muted" size={300}>
                              Advance
                            </Text>
                          </Pane>
                          <Pane
                            display={"flex"}
                            flexDirection="row"
                            justifyContent="space-between"
                          >
                            <Strong color="muted" size={300}>
                              {extra.advance}
                            </Strong>
                          </Pane>
                        </Pane>
                        <Pane display={"flex"} flexDirection="column">
                          <Pane
                            display={"flex"}
                            flexDirection="row"
                            marginTop={5}
                            justifyContent="space-between"
                          >
                            <Text color="muted" size={300}>
                              Discount
                            </Text>
                          </Pane>
                          <Pane
                            display={"flex"}
                            flexDirection="row"
                            justifyContent="space-between"
                          >
                            <Strong color="muted" size={300}>
                              {extra.discount}
                            </Strong>
                          </Pane>
                        </Pane>
                        <Pane display={"flex"} flexDirection="column">
                          <Pane
                            display={"flex"}
                            flexDirection="row"
                            marginTop={5}
                            justifyContent="space-between"
                          >
                            <Text color="muted" size={300}>
                              Net Amount
                            </Text>
                          </Pane>
                          <Pane
                            display={"flex"}
                            flexDirection="row"
                            justifyContent="space-between"
                          >
                            <Strong color="muted" size={300}>
                              {extra.netAmount}
                            </Strong>
                          </Pane>
                        </Pane>
                        <Pane display={"flex"} flexDirection="column">
                          <Pane
                            display={"flex"}
                            flexDirection="row"
                            marginTop={5}
                            justifyContent="space-between"
                          >
                            <Text color="muted" size={300}>
                              Balance Amt.
                            </Text>
                          </Pane>
                          <Pane
                            display={"flex"}
                            flexDirection="row"
                            justifyContent="space-between"
                          >
                            <Strong color="black" size={300}>
                              {extra.balance}
                            </Strong>
                          </Pane>
                        </Pane>
                      </AccordionSummary>
                    </ThemeProvider>
                    <AccordionDetails>
                      <Pane
                        marginLeft={40}
                        display={"flex"}
                        flexDirection="row"
                        justifyContent="flex-start"
                      >
                        <TextInputField
                          width={150}
                          label="Collect Amount"
                          height={32}
                          name="pay-amount-input"
                          placeholder={`${generateCurrencySymbol()} 0`}
                          onChange={(e) =>
                            this.handleExtraPayNow(e, "amount-change")
                          }
                          isInvalid={isPayNowAmountInvalid}
                        />
                        <TextInputField
                          marginLeft={20}
                          maxWidth={130}
                          height={32}
                          label="Discount"
                          name="apply-discount-input"
                          placeholder={`${generateCurrencySymbol()} 0`}
                          onChange={(e) =>
                            this.handleExtraPayNow(e, "apply-discount")
                          }
                          isInvalid={isDiscountAmountInvalid}
                        />
                        <Pane marginLeft={20} marginTop={23}>
                          <TransactionModeSelect
                            disableNoPay={disableNoPay}
                            selectedTransactionMode={selectedTransactionMode}
                            changeTransactionMode={this.changeTransactionMode}
                          />
                        </Pane>
                        <Button
                          backgroundColor={"#49b881"}
                          width={80}
                          marginLeft={20}
                          marginTop={23}
                          alignItems={"center"}
                          height={32}
                          appearance="primary"
                          intent="success"
                          onClick={(e) =>
                            this.handleExtraPayNow(e, "confirm", extra)
                          }
                        >
                          Confirm
                        </Button>
                      </Pane>
                    </AccordionDetails>
                  </Accordion>
                  {length === 1 && extra.balance > 0 && (
                    <Pane
                      marginLeft={40}
                      display={"flex"}
                      flexDirection="row"
                      justifyContent="flex-start"
                    >
                      <TextInputField
                        width={150}
                        label="Collect Amount"
                        height={32}
                        name="pay-amount-input"
                        placeholder={`${generateCurrencySymbol()} 0`}
                        onChange={(e) =>
                          this.handleExtraPayNow(e, "amount-change")
                        }
                        isInvalid={isPayNowAmountInvalid}
                      />
                      <TextInputField
                        marginLeft={20}
                        maxWidth={130}
                        height={32}
                        label="Discount"
                        name="apply-discount-input"
                        placeholder={`${generateCurrencySymbol()} 0`}
                        onChange={(e) =>
                          this.handleExtraPayNow(e, "apply-discount")
                        }
                        isInvalid={isDiscountAmountInvalid}
                      />
                      <Pane marginLeft={20} marginTop={23}>
                        <TransactionModeSelect
                          disableNoPay={disableNoPay}
                          selectedTransactionMode={selectedTransactionMode}
                          changeTransactionMode={this.changeTransactionMode}
                        />
                      </Pane>
                      <Button
                        backgroundColor={"#49b881"}
                        width={80}
                        marginLeft={20}
                        marginTop={23}
                        alignItems={"center"}
                        height={32}
                        appearance="primary"
                        intent="success"
                        onClick={(e) =>
                          this.handleExtraPayNow(e, "confirm", extra)
                        }
                      >
                        Confirm
                      </Button>
                    </Pane>
                  )}
                </Pane>
              ))}
          </Pane>
        </Dialog>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({
  updateExtraPaymentToast,
  updateBookingPaymentToast,
}) => ({
  updateExtraPaymentToast,
  updateBookingPaymentToast,
});

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default connect(mapStateToProps)(ExtrasPayNow);
