// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import {connect} from "react-redux";
import {Text, Checkbox, toaster, Textarea, Dialog} from "evergreen-ui";
import CustomizedDatePicker from "../CustomizedDatePicker";
import CustomizedTimePicker from "../CustomizedTimePicker";
import {editCoupon, getCouponDetails} from "../../actions";
import {
    toDateFormat1,
    toTimeFormat,
    resourcesNotLoadedToast,
    checkIfDateIsAfter,
    checkIfDatesAreSame,
    checkIfTimeIsSameOrAfter,
} from "../../utils";
import CustomInlineAlert from "../../components/CustomInlineAlert";

// -----------------------------------------------------------------------
// EditCouponsForm Component
// -----------------------------------------------------------------------
class EditCouponsForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fromDate: new Date(),
            toDate: new Date(),
            fromTime: new Date(),
            toTime: new Date(),
            clubMinuteStep: 15,
            dayCheckAll: false,
            daysData: [
                {day: "Mon", id: 0, checked: false},
                {day: "Tue", id: 1, checked: false},
                {day: "Wed", id: 2, checked: false},
                {day: "Thu", id: 3, checked: false},
                {day: "Fri", id: 4, checked: false},
                {day: "Sat", id: 5, checked: false},
                {day: "Sun", id: 6, checked: false}
            ],
            selectedDayIds: [],
            couponRemark: "",
            isDayInvalid: false,
            isDateRangeInvalid: false,
            isTimeRangeInvalid: false,
        };
    }

    // -----------------------------------------------------------------------
    // scrollToElement()
    // -----------------------------------------------------------------------
    scrollToElement = async elem => {
        await this.refs[elem].scrollIntoView({block: "end", behavior: "smooth"});
    };

    // -----------------------------------------------------------------------
    // handleFromDateChange()
    // -----------------------------------------------------------------------
    handleFromDateChange = async date => {
        await this.setState({fromDate: date, toDate: date});
        await this.validateDates();
    };

    // -----------------------------------------------------------------------
    // handleToDateChange()
    // -----------------------------------------------------------------------
    handleToDateChange = async date => {
        await this.setState({toDate: date});
        await this.validateDates();
    };

    // -----------------------------------------------------------------------
    // validateDates()
    // -----------------------------------------------------------------------
    validateDates = async () => {
        if (
            checkIfDatesAreSame(this.state.toDate, this.state.fromDate) ||
            checkIfDateIsAfter(this.state.toDate, this.state.fromDate)
        ) {
            await this.setState({
                isDateRangeInvalid: false
            });
        } else {
            await this.setState({
                isDateRangeInvalid: true
            });
        }
    };

    // -----------------------------------------------------------------------
    // handleCheckAll()
    // -----------------------------------------------------------------------
    handleCheckAll = async (e, type) => {
        e.persist();
        if (type === "day") {
            const modifiedDaysData = await this.state.daysData.map(data => {
                return (data = {
                    ...data,
                    checked: e.target.checked ? true : false
                });
            });
            await this.setState({
                daysData: modifiedDaysData,
                dayCheckAll: !this.state.dayCheckAll
            });
            await this.setState({
                selectedDayIds: e.target.checked
                    ? this.state.daysData.map(data => {
                        return data.id;
                    })
                    : []
            });
            if (this.state.selectedDayIds.length > 0) {
                await this.setState({
                    isDayInvalid: false
                });
            } else {
                await this.setState({
                    isDayInvalid: true
                });
            }
        }
    };

    // -----------------------------------------------------------------------
    // handleCheckIndividual()
    // -----------------------------------------------------------------------
    handleCheckIndividual = async (e, type, identifier) => {
        e.persist();
        if (type === "day") {
            const modifiedDaysData = JSON.parse(JSON.stringify(this.state.daysData));
            for (var i = 0; i < modifiedDaysData.length; i++) {
                if (modifiedDaysData[i].id === identifier) {
                    modifiedDaysData[i].checked = e.target.checked ? true : false;
                }
            }
            await this.setState({
                daysData: modifiedDaysData
            });
            const updatedSelectedDayIds = [];
            for (i = 0; i < modifiedDaysData.length; i++) {
                if (modifiedDaysData[i].checked) {
                    updatedSelectedDayIds.push(modifiedDaysData[i].id);
                }
            }
            await this.setState({selectedDayIds: updatedSelectedDayIds});
            if (this.state.selectedDayIds.length !== this.state.daysData.length) {
                await this.setState({
                    dayCheckAll: false
                });
            }
            if (this.state.selectedDayIds.length > 0) {
                await this.setState({
                    isDayInvalid: false
                });
            } else {
                await this.setState({
                    isDayInvalid: true
                });
            }
        }
    };

    // -----------------------------------------------------------------------
    // handleFromTimeChange()
    // -----------------------------------------------------------------------
    handleFromTimeChange = async time => {
        await this.setState({fromTime: time, toTime: time});
        await this.validateTimes();
    };

    // -----------------------------------------------------------------------
    // handleToTimeChange()
    // -----------------------------------------------------------------------
    handleToTimeChange = async time => {
        await this.setState({toTime: time});
        await this.validateTimes();
    };

    // -----------------------------------------------------------------------
    // validateTimes()
    // -----------------------------------------------------------------------
    validateTimes = async () => {
        if (checkIfTimeIsSameOrAfter(this.state.toTime, this.state.fromTime)) {
            await this.setState({isTimeRangeInvalid: false});
        } else {
            await this.setState({isTimeRangeInvalid: true});
        }
    };

    // -----------------------------------------------------------------------
    // handleCouponRemarksInput()
    // -----------------------------------------------------------------------
    handleCouponRemarksInput = e => {
        this.setState({couponRemark: e.target.value});
    };

    // -----------------------------------------------------------------------
    // resetForm()
    // -----------------------------------------------------------------------
    resetForm = async () => {
        await this.setState({
            selectedActivityValue: Object.keys(this.props.clubActivityData.activities),
            fromDate: new Date(),
            toDate: new Date(),
            fromTime: new Date(),
            toTime: new Date(),
            dayCheckAll: false,
            daysData: [
                {day: "Mon", id: 0, checked: false},
                {day: "Tue", id: 1, checked: false},
                {day: "Wed", id: 2, checked: false},
                {day: "Thu", id: 3, checked: false},
                {day: "Fri", id: 4, checked: false},
                {day: "Sat", id: 5, checked: false},
                {day: "Sun", id: 6, checked: false}
            ],
            selectedDayIds: [],
            couponRemark: "",
            isDateRangeInvalid: false,
            isTimeRangeInvalid: false,
            isDayInvalid: false
        });
        await this.props.toggleGenerateCouponsDialogVisibility('close')
    };

    // -----------------------------------------------------------------------
    // confirmGenerateCoupon()
    // -----------------------------------------------------------------------
    confirmGenerateCoupon = async () => {
        if (
            !this.state.isDateRangeInvalid &&
            this.state.selectedDayIds.length > 0 &&
            !this.state.isTimeRangeInvalid
        ) {
            let reqBody = {
                couponDetailId: this.props.coupon.couponDetailId,
                activities: this.props.coupon.activities,
                startDate: toDateFormat1(this.state.fromDate),
                endDate: toDateFormat1(this.state.toDate),
                days: this.state.selectedDayIds,
                startTime: toTimeFormat(this.state.fromTime),
                endTime: toTimeFormat(this.state.toTime),
                remarks: this.state.couponRemark,
            };
            await this.props.dispatch(editCoupon(reqBody));
            if (this.props.editCouponToast) {
                if (this.props.editCouponToast.resourcesNotLoadedMessage) {
                    resourcesNotLoadedToast();
                } else if (this.props.editCouponToast.httpErrorMessage) {
                    toaster.danger(this.props.editCouponToast.httpErrorMessage, {
                        id: "HTTP-ERROR-TOAST-4"
                    });
                } else if (this.props.editCouponToast.requestStatus === -1) {
                    toaster.notify("Sorry, you don't have permission to do that");
                } else if (this.props.editCouponToast.requestStatus === 1) {
                    await this.resetForm();
                    toaster.success(this.props.editCouponToast.message, {
                        id: "GCF-TOAST-2"
                    });
                } else if (this.props.editCouponToast.requestStatus === 0) {
                    toaster.danger(this.props.generateCfouponToast.message, {
                        id: "GCF-TOAST-2"
                    });
                }
            }
            await this.props.dispatch(getCouponDetails({couponId: '', page: 1}));
        } else {
            if (this.state.isTimeRangeInvalid) {
                await this.scrollToElement("isTimeRangeInvalid");
            }
            if (this.state.selectedDayIds.length <= 0) {
                await this.setState({isDayInvalid: true});
                await this.scrollToElement("isDayInvalid");
            }
            if (this.state.isDateRangeInvalid) {
                await this.scrollToElement("isDateRangeInvalid");
            }
        }
    };


    // -----------------------------------------------------------------------
    // render()
    // -----------------------------------------------------------------------
    render() {
        const {
            dayCheckAll,
            daysData,
            fromTime,
            toTime,
            clubMinuteStep,
            couponRemark,
            isDayInvalid,
            isDateRangeInvalid,
            isTimeRangeInvalid,
        } = this.state;

        const {
            showEditCouponDetailsDialog
        } = this.props;

        return (
            <Dialog
                width={560}
                isShown={showEditCouponDetailsDialog}
                title="Generate Coupons"
                onConfirm={() => this.confirmGenerateCoupon()}
                onCloseComplete={() => this.resetForm()}
            >
                <React.Fragment>
                    <div className="GCF-A1-wrapper">
                        <div className="GCF-A1-actions-wrapper GCF-A1-margin-bottom-16">
                            <div className="GCF-A1-col-container">
                                <div className="GCF-A1-label-container GCF-A1-margin-bottom-16">
                                    <Text id="GCF-A1-label-type-one">Date</Text>
                                </div>
                                <div className="GCF-A1-row-container">
                                    <div className="GCF-A1-col-container GCF-A1-margin-right-124">
                                        <div className="GCF-A1-label-container GCF-A1-margin-bottom-4">
                                            <Text id="GCF-A1-label-type-two">From</Text>
                                        </div>
                                        <div className="GCF-A1-from-datepicker-container">
                                            <CustomizedDatePicker
                                                date={new Date(this.props.coupon.startDate)}
                                                handleDateChange={this.handleFromDateChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="GCF-A1-col-container">
                                        <div className="GCF-A1-label-container GCF-A1-margin-bottom-4">
                                            <Text id="GCF-A1-label-type-two">To</Text>
                                        </div>
                                        <div className="GCF-A1-to-datepicker-container">
                                            <CustomizedDatePicker
                                                date={new Date(this.props.coupon.endDate)}
                                                handleDateChange={this.handleToDateChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {isDateRangeInvalid && (
                                    <div
                                        className="GCF-A1-custom-inline-alert-container"
                                        ref={"isDateRangeInvalid"}
                                    >
                                        <CustomInlineAlert
                                            intent="danger"
                                            alertMsg="Invalid date range selected"
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="GCF-A1-actions-wrapper GCF-A1-margin-bottom-16">
                            <div className="GCF-A1-col-container">
                                <div className="GCF-A1-label-container GCF-A1-margin-bottom-16">
                                    <Text id="GCF-A1-label-type-one">Day</Text>
                                </div>
                                <div className="GCF-A1-checkbox-wrapper">
                                    <Checkbox
                                        label="All Days"
                                        marginRight={32}
                                        onChange={e => this.handleCheckAll(e, "day")}
                                        checked={dayCheckAll}
                                    />
                                    {daysData.map(data => (
                                        <Checkbox
                                            marginRight={16}
                                            key={data.id}
                                            label={data.day}
                                            checked={data.checked}
                                            onChange={e =>
                                                this.handleCheckIndividual(e, "day", data.id)
                                            }
                                        />
                                    ))}
                                </div>
                                {isDayInvalid && (
                                    <div
                                        className="GCF-A1-custom-inline-alert-container"
                                        ref={"isDayInvalid"}
                                    >
                                        <CustomInlineAlert
                                            intent="danger"
                                            alertMsg="Select at least one day"
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="GCF-A1-actions-wrapper GCF-A1-margin-bottom-16">
                            <div className="GCF-A1-col-container">
                                <div className="GCF-A1-label-container GCF-A1-margin-bottom-16">
                                    <Text id="GCF-A1-label-type-one">Time</Text>
                                </div>
                                <div className="GCF-A1-row-container">
                                    <div className="GCF-A1-col-container GCF-A1-margin-right-124">
                                        <div className="GCF-A1-label-container GCF-A1-margin-bottom-4">
                                            <Text id="GCF-A1-label-type-two">From</Text>
                                        </div>
                                        <div className="GCF-A1-from-timepicker-container">
                                            <CustomizedTimePicker
                                                time={fromTime}
                                                clubMinuteStep={clubMinuteStep}
                                                handleTimeChange={this.handleFromTimeChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="GCF-A1-col-container">
                                        <div className="GCF-A1-label-container GCF-A1-margin-bottom-4">
                                            <Text id="GCF-A1-label-type-two">To</Text>
                                        </div>
                                        <div className="GCF-A1-to-timepicker-container">
                                            <CustomizedTimePicker
                                                time={toTime}
                                                clubMinuteStep={clubMinuteStep}
                                                handleTimeChange={this.handleToTimeChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {isTimeRangeInvalid && (
                                    <div
                                        className="GCF-A1-custom-inline-alert-container"
                                        ref={"isTimeRangeInvalid"}
                                    >
                                        <CustomInlineAlert
                                            intent="danger"
                                            alertMsg="Invalid time range selected"
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="GCF-A1-last-actions-wrapper GCF-A1-margin-bottom-16">
                            <div className="GCF-A1-col-container">
                                <div className="GCF-A1-label-container GCF-A1-margin-bottom-16">
                                    <Text id="GCF-A1-label-type-one">Remarks</Text>
                                </div>
                                <div className="GCF-A1-row-container">
                                    <Textarea
                                        className="GCF-A1-input-field"
                                        style={{resize: "none"}}
                                        width={336}
                                        height={80}
                                        placeholder="Coupon remarks"
                                        maxLength="100"
                                        value={couponRemark}
                                        onChange={e => this.handleCouponRemarksInput(e)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            </Dialog>
        );
    }
}

const mapStateToProps = (
    {
        editCouponToast,
        clubActivityData,
    }) => (
    {
        editCouponToast,
        clubActivityData,
    });

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default connect(mapStateToProps)(EditCouponsForm);
