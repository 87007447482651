// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import { connect } from "react-redux";
import {
  Popover,
  Pane,
  Text,
  Button,
  Position,
  toaster,
  CaretDownIcon,
} from "evergreen-ui";
import { getCountryDetails } from "../../actions";
import {
  resourcesNotLoadedToast,
  checkClubCountryCallingCodeExists,
  getPlayoClubCountryCallingCode,
} from "../../utils";

// -----------------------------------------------------------------------
// CountrySelectMenu Component
// -----------------------------------------------------------------------
class CountrySelectMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      callingCode: "+91",
      countryCode: "IN",
      countryFlag: "",
      customCountryDetails: [],
      showPopover: false,
    };
  }

  // -----------------------------------------------------------------------
  // componentDidMount()
  // -----------------------------------------------------------------------
  componentDidMount() {
    this.generateCountryInformation();
  }

  // -----------------------------------------------------------------------
  // generateCountryInformation()
  // -----------------------------------------------------------------------
  generateCountryInformation = async () => {
    await this.props.dispatch(getCountryDetails());
    if (this.props.countryDetailsToast) {
      if (this.props.countryDetailsToast.resourcesNotLoadedMessage) {
        resourcesNotLoadedToast();
      } else if (this.props.countryDetailsToast.httpErrorMessage) {
        toaster.danger(this.props.countryDetailsToast.httpErrorMessage, {
          id: "HTTP-ERROR-TOAST-4",
        });
      } else if (
        this.props.countryDetailsToast.requestStatus === 1 &&
        this.props.countryDetails
      ) {
        const country = this.props.countryDetails;
        let customCountryDetails = [];
        for (var i = 0; i < country.length; i++) {
          if (country[i].callingCodes.length > 1) {
            for (var j = 0; j < country[i].callingCodes.length; j++) {
              customCountryDetails.push({
                name: country[i].name,
                alpha3Code: country[i].alpha3Code,
                callingCode: country[i].callingCodes[j],
                flag: country[i].flags,
              });
            }
          } else {
            customCountryDetails.push({
              name: country[i].name,
              alpha3Code: country[i].alpha3Code,
              callingCode: country[i].callingCodes[0],
              flag: country[i].flags,
            });
          }
        }
        await this.setState({ customCountryDetails: customCountryDetails });
        await this.handleGetDefaultCountryDetailsResponse();
      }
    }
  };

  // -----------------------------------------------------------------------
  // changeCallingCode()
  // -----------------------------------------------------------------------
  changeCallingCode = (e, callingCode, flag, alpha3Code) => {
    e.preventDefault();
    this.setState({
      callingCode: `+${callingCode}`,
      countryFlag: flag,
      countryCode: alpha3Code,
    });
    this.handlePopover("close");
    if (this.props.onCountryChange) {
      this.props.onCountryChange(`+${callingCode}`);
    }
  };

  // -----------------------------------------------------------------------
  // handlePopover()
  // -----------------------------------------------------------------------
  handlePopover = (type) => {
    if (type === "open") {
      this.setState({ showPopover: true });
    } else if (type === "close") {
      this.setState({ showPopover: false });
    }
  };

  // -----------------------------------------------------------------------
  // handleGetDefaultCountryDetailsResponse(
  // -----------------------------------------------------------------------
  handleGetDefaultCountryDetailsResponse = async () => {
    if (checkClubCountryCallingCodeExists()) {
      let callingCode = await getPlayoClubCountryCallingCode();
      if (callingCode.charAt(0) === "+") {
        callingCode = callingCode.substr(1);
      }
      let defaultCountryDetails = this.props.countryDetails.find(
        (obj) => obj.callingCodes == callingCode
      );

      // await this.props.dispatch(getDefaultCountryDetails(callingCode));
      // if (this.props.defaultCountryDetailsToast) {
      //   if (this.props.defaultCountryDetailsToast.resourcesNotLoadedMessage) {
      //     resourcesNotLoadedToast();
      //   } else if (this.props.defaultCountryDetailsToast.httpErrorMessage) {
      //     toaster.danger(
      //       this.props.defaultCountryDetailsToast.httpErrorMessage,
      //       {
      //         id: "HTTP-ERROR-TOAST-4"
      //       }
      //     );
      //   } else if (
      //     this.props.defaultCountryDetailsToast.requestStatus === 1 &&
      //     this.props.defaultCountryDetails
      //   ) {
      await this.setState({
        callingCode: getPlayoClubCountryCallingCode(),
        countryCode: defaultCountryDetails.alpha3Code,
        countryFlag: defaultCountryDetails.flags[1],
      });
      //     }
      //   }
    }
  };

  // -----------------------------------------------------------------------
  // render()
  // -----------------------------------------------------------------------
  render() {
    const {
      customCountryDetails,
      callingCode,
      countryFlag,
      countryCode,
      showPopover,
    } = this.state;

    return (
      <React.Fragment>
        <Popover
          isShown={showPopover}
          onOpen={() => this.handlePopover("open")}
          onClose={() => this.handlePopover("close")}
          content={
            <Pane
              width={240}
              height={240}
              display="flex"
              alignItems="flex-start"
              justifyContent="flex-start"
              flexDirection="column"
              overflow="auto"
            >
              {customCountryDetails &&
                // eslint-disable-next-line
                customCountryDetails.map((country, index) => {
                  if (country.callingCode !== "") {
                    return (
                      <div
                        className="country-select-menu-country-data-container"
                        key={index}
                        onClick={(e) =>
                          this.changeCallingCode(
                            e,
                            country.callingCode,
                            country.flag[1],
                            country.alpha3Code
                          )
                        }
                      >
                        <div className="country-select-menu-country-flag-container">
                          {/* <img
                            className='country-select-menu-country-flag'
                            src={country.flag[1]}
                            alt={country.alpha3Code}
                          /> */}
                        </div>
                        <div className="country-select-menu-country-name-container">
                          <Text id="country-select-menu-country-name">
                            {country.name}
                          </Text>
                        </div>
                        <div className="country-select-menu-country-calling-code-container">
                          <Text id="country-select-menu-country-calling-code">
                            {"(+" + country.callingCode + ")"}
                          </Text>
                        </div>
                      </div>
                    );
                  }
                })}
            </Pane>
          }
          position={Position.BOTTOM_LEFT}
        >
          <Button
            className="country-select-menu-button-as-select"
            width={136}
            height={32}
            iconAfter={<CaretDownIcon />}
          >
            {/* <img
              className='country-select-menu-country-flag-small'
              src={countryFlag}
              alt='country-flag'
            /> */}
            {countryCode + ` (${callingCode})`}
          </Button>
        </Popover>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({
  countryDetails,
  countryDetailsToast,
  defaultCountryDetails,
  defaultCountryDetailsToast,
}) => ({
  countryDetails,
  countryDetailsToast,
  defaultCountryDetails,
  defaultCountryDetailsToast,
});

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default connect(mapStateToProps)(CountrySelectMenu);
