// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import {Heading, Tablist, Tab} from "evergreen-ui";
import "react-datepicker/dist/react-datepicker.css";

// -----------------------------------------------------------------------
// ActionBar Component
// -----------------------------------------------------------------------
class ActionBar extends React.Component {
    render() {
        const {
            selectedTab,
            handleTabsChange,
            graphProjections
        } = this.props;

        return (
            <div className="APGAB-A1-wrapper">
                <div className="H-A1-wrapper">
                    <Heading id="H-A1-label-type-one">Utilisation Graph</Heading>
                </div>
                <div className="AP-A1-actions-wrapper BF-A1-margin-bottom-16">
                    <Tablist>
                        {Object.values(graphProjections).map((tab, index) =>
                            <Tab
                                 key={tab.type}
                                 id={tab.type}
                                 height={32}
                                 width={180}
                                 aria-controls={`panel-${tab.type}`}
                                 isSelected={selectedTab === index}
                                 onSelect={() => handleTabsChange(index)}>
                                {tab.name}
                            </Tab>
                        )}
                    </Tablist>
                </div>
            </div>
        );
    }
}

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default ActionBar;
