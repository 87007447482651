import { Backdrop } from "@material-ui/core";
import { Button, CaretDownIcon, Menu, Text } from "evergreen-ui";
import React from "react";
import SingleDateRangePicker from "./SingleDateRangePicker";

function DashboardDateRange({
  handlePeriodChange,
  selectedPeriod,
  selectedPeriodValue,
  handleDateChange,
  startDate,
  endDate,
  setPeriod,
}) {
  const [isShown, setIsShown] = React.useState(false);
  const [custom, showCustom] = React.useState(false);

  let dateData = [
    { title: "Today", value: 1 },
    { title: "Yesterday", value: 2 },
    { title: "This Week", value: 3 },
    { title: "This Month", value: 4 },
  ];

  const handleChange = (data) => {
    handlePeriodChange(data);
    setIsShown(false);
  };

  const handleClose = () => {
    setIsShown(false);
  };

  return (
    <div className="period-filter">
      <Button
        onClick={() => {
          setIsShown(!isShown);
        }}
        width={"100%"}
        height={40}
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Text>{selectedPeriod}</Text>
        <CaretDownIcon />
      </Button>
      {isShown && (
        <div className="filter-model">
          <Backdrop
            open={isShown}
            invisible={true}
            onClick={() => {
              setIsShown(!isShown);
            }}
          ></Backdrop>

          <Menu.Group>
            {" "}
            {dateData.map((data) => {
              return (
                <Menu.Item
                  height={"48px"}
                  width={"200px"}
                  onSelect={() => handleChange(data)}
                >
                  {data.title}
                </Menu.Item>
              );
            })}
            <Menu.Item
              height={"48px"}
              width="100%"
              onSelect={() => showCustom(!custom)}
            >
              Custom
            </Menu.Item>
          </Menu.Group>
          {custom && (
            <div className="date-range-picker-div">
              <SingleDateRangePicker
                handleClose={handleClose}
                handleDateRangeChange={handleDateChange}
                startDateLabel={startDate}
                endDateLabel={endDate}
                setPeriod={setPeriod}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default DashboardDateRange;
