// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import { Select } from "evergreen-ui";

// -----------------------------------------------------------------------
// DaysSelect Component
// -----------------------------------------------------------------------
class DaysSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      daysData: [
        { day: "Monday", id: 0 },
        { day: "Tuesday", id: 1 },
        { day: "Wednesday", id: 2 },
        { day: "Thursday", id: 3 },
        { day: "Friday", id: 4 },
        { day: "Saturday", id: 5 },
        { day: "Sunday", id: 6 }
      ]
    };
  }

  // -----------------------------------------------------------------------
  // render()
  // -----------------------------------------------------------------------
  render() {
    const { daysData } = this.state;
    const { changeDay, selectedDayId } = this.props;
    return (
      <React.Fragment>
        <Select
          width={224}
          height={32}
          onChange={e => changeDay(e)}
          value={selectedDayId}
        >
          {daysData.map((day, index) => (
            <option key={index} value={day.id}>
              {day.day}
            </option>
          ))}
        </Select>
      </React.Fragment>
    );
  }
}

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default DaysSelect;
