// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import { connect } from "react-redux";
import Navbar from "../../components/Navbar";
import PageHeadingAndActionsNav from "../../components/PageHeadingAndActionsNav";
import {
  Text,
  Button,
  toaster,
  Dialog,
  IconButton,
  TextInput,
  Radio,
  Tooltip,
  Alert,
  TrashIcon,
  AddIcon,
  RemoveIcon,
} from "evergreen-ui";
import {
  toTimeFormat,
  toTimeFormat1,
  getMomentTimeObj1,
  validateCreditsAndPrice,
  validateNumber2,
  validatePercentage,
  resourcesNotLoadedToast,
  generateCurrencySymbol,
} from "../../utils";
import DaysSelect from "../../components/DaysSelect";
import CustomizedTimePicker from "../../components/CustomizedTimePicker";
import update from "immutability-helper";
import moment from "moment";
import {
  savePricingDetails,
  getPricingConfigDetails,
  togglePremiumFeatureFlag,
} from "../../actions";
import CustomInlineAlert from "../../components/CustomInlineAlert";

// -----------------------------------------------------------------------
// EditPricingPage Component
// -----------------------------------------------------------------------
class EditPricingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clubMinuteStep: 15,
      showConfirmDismissDialog: false,
      isPeriodicPricing: false,
      startDate: "",
      endDate: "",
      checkedDayRangeValue: "range",
      priceSlots: [],
      priceClass: "",
      priceType: "",
      activityId: "",
      showForm: false,
      startDay: 0,
      endDay: 6,
      daysData: {
        monday: 0,
        tuesday: 1,
        wednesday: 2,
        thursday: 3,
        friday: 4,
        saturday: 5,
        sunday: 6,
      },
      tempTime: "",
      indexA: "",
      tempType: "",
      fieldsAreRequired: false,
    };
  }

  // -----------------------------------------------------------------------
  // componentDidUpdate()
  // -----------------------------------------------------------------------
  componentDidUpdate(prevProps) {
    if (prevProps.pricingData === null) {
      if (this.props.pricingData) {
        this.setStateWithReceivedPriceData();
      }
    }
  }

  // -----------------------------------------------------------------------
  // resetForm()
  // -----------------------------------------------------------------------
  resetForm = async () => {
    await this.setState({
      showConfirmDismissDialog: false,
      isPeriodicPricing: false,
      startDate: "",
      endDate: "",
      checkedDayRangeValue: "range",
      priceSlots: [],
      priceClass: "",
      priceType: "",
      activityId: "",
      showForm: false,
      startDay: 0,
      endDay: 6,
      tempTime: "",
      indexA: "",
      tempType: "",
      fieldsAreRequired: false,
    });
  };

  // -----------------------------------------------------------------------
  // setStateWithReceivedPriceData()
  // -----------------------------------------------------------------------
  setStateWithReceivedPriceData = async () => {
    if (this.props.pricingData) {
      await this.setState({
        isPeriodicPricing: this.props.pricingData.isPeriodicPricing,
        startDate: this.props.pricingData.startDate,
        endDate: this.props.pricingData.endDate,
        priceSlots: this.props.pricingData.priceSlots,
        priceClass: parseInt(this.props.pricingData.priceClass),
        priceType: this.props.pricingData.priceType.toString().toLowerCase(),
        activityId: parseInt(this.props.pricingData.activityId),
        openHour: this.props.pricingData.openHour,
        closeHour: this.props.pricingData.closeHour,
        slotDuration: this.props.pricingData.slotDuration,
        showForm: true,
      });
      if (
        this.state.priceSlots.length > 0 &&
        this.state.priceSlots[0].startDay.toLowerCase() !== "holiday" &&
        this.state.priceSlots[0].endDay.toLowerCase() !== "holiday"
      ) {
        if (
          this.state.priceSlots[0].startDay.toLowerCase() ===
          this.state.priceSlots[0].endDay.toLowerCase()
        ) {
          await this.setState({
            startDay:
              this.state.daysData[
                this.state.priceSlots[0].startDay.toLowerCase()
              ],
            endDay:
              this.state.daysData[
                this.state.priceSlots[0].endDay.toLowerCase()
              ],
            checkedDayRangeValue: "single-day",
          });
        } else {
          await this.setState({
            startDay:
              this.state.daysData[
                this.state.priceSlots[0].startDay.toLowerCase()
              ],
            endDay:
              this.state.daysData[
                this.state.priceSlots[0].endDay.toLowerCase()
              ],
            checkedDayRangeValue: "range",
          });
        }
      }
    }
  };

  // -----------------------------------------------------------------------
  // handleConfirmDismissDialog()
  // -----------------------------------------------------------------------
  handleConfirmDismissDialog = async (type) => {
    if (type === "show") {
      this.setState({ showConfirmDismissDialog: true });
    } else if (type === "hide") {
      this.setState({ showConfirmDismissDialog: false });
    } else if (type === "confirm") {
      await this.setState({ showConfirmDismissDialog: false });
      await this.props.handleEditPricingPageDisplay("hide");
      await this.props.dispatch({ type: "SET_PRICING_DATA", payload: null });
      await this.resetForm();
    }
  };

  // -----------------------------------------------------------------------
  // dayRangeRadioChange()
  // -----------------------------------------------------------------------
  dayRangeRadioChange = (e, type) => {
    e.persist();
    if (type === "range") {
      this.setState({ checkedDayRangeValue: "range" });
    } else {
      this.setState({ checkedDayRangeValue: "single-day" });
    }
  };

  // -----------------------------------------------------------------------
  // handleStartDayChange()
  // -----------------------------------------------------------------------
  handleStartDayChange = async (e) => {
    await this.setState({ startDay: parseInt(e.target.value) });
  };

  // -----------------------------------------------------------------------
  // handleEndDayChange()
  // -----------------------------------------------------------------------
  handleEndDayChange = async (e) => {
    await this.setState({ endDay: parseInt(e.target.value) });
  };

  // -----------------------------------------------------------------------
  // handleTimeChange()
  // -----------------------------------------------------------------------
  handleTimeChange = async (momentObj) => {
    await this.setState({ tempTime: toTimeFormat(momentObj) });
    await this.mutateTime();
  };

  // -----------------------------------------------------------------------
  // mutateTime()
  // -----------------------------------------------------------------------
  mutateTime = async () => {
    if (
      this.state.tempType === "startTime" ||
      this.state.tempType === "endTime"
    ) {
      const priceSlots = this.state.priceSlots[0];
      const newPriceSlots = update(priceSlots, {
        priceSlots: {
          [this.state.indexA]: {
            [this.state.tempType]: { $set: this.state.tempTime },
          },
        },
      });
      await this.setState({ priceSlots: [newPriceSlots] });
    }
  };

  // -----------------------------------------------------------------------
  // saveTemporary()
  // -----------------------------------------------------------------------
  saveTemporary = async (e, indexA, type) => {
    e.persist();
    await this.setState({ indexA: indexA, tempType: type });
  };

  // -----------------------------------------------------------------------
  // handleHourlyPriceChange()
  // -----------------------------------------------------------------------
  handleHourlyPriceChange = async (e, index) => {
    e.persist();
    if (validateCreditsAndPrice(e.target.value) === "set") {
      await this.mutateHourlyPricing(e.target.value, index);
    } else if (validateCreditsAndPrice(e.target.value) === "clear") {
      await this.mutateHourlyPricing("", index);
    }
  };

  // -----------------------------------------------------------------------
  // mutateHourlyPricing()
  // -----------------------------------------------------------------------
  mutateHourlyPricing = async (value, index) => {
    const priceSlots = this.state.priceSlots[0];
    const newPriceSlots = update(priceSlots, {
      priceSlots: {
        [index]: {
          pricingAmount: {
            $set: value,
          },
          isInvalid: { $set: value === "" ? true : false },
        },
      },
    });
    await this.setState({ priceSlots: [newPriceSlots] });
  };

  // -----------------------------------------------------------------------
  // handleVolumeDiscountChange()
  // -----------------------------------------------------------------------
  handleVolumeDiscountChange = async (e, indexA, indexB) => {
    e.persist();
    if (validateNumber2(e.target.value) === "set") {
      await this.mutateVolumeDiscount(e.target.value, indexA, indexB);
    } else if (validateNumber2(e.target.value) === "clear") {
      await this.mutateVolumeDiscount("", indexA, indexB);
    }
  };

  // -----------------------------------------------------------------------
  // mutateVolumeDiscount()
  // -----------------------------------------------------------------------
  mutateVolumeDiscount = async (value, indexA, indexB) => {
    const firstIndex = 0;
    const priceSlots = this.state.priceSlots[0];
    const newPriceSlots = update(priceSlots, {
      priceSlots: {
        [indexA]: {
          slotDiscount: {
            [indexB]: {
              [firstIndex]: { $set: value },
            },
          },
        },
      },
    });
    await this.setState({ priceSlots: [newPriceSlots] });
  };

  // -----------------------------------------------------------------------
  // handleVolumeDiscountPercentageChange()
  // -----------------------------------------------------------------------
  handleVolumeDiscountPercentageChange = async (e, indexA, indexB) => {
    e.persist();
    if (validatePercentage(e.target.value) === "set") {
      await this.mutateVolumeDiscountPercentage(e.target.value, indexA, indexB);
    } else if (validatePercentage(e.target.value) === "clear") {
      await this.mutateVolumeDiscountPercentage("", indexA, indexB);
    }
  };

  // -----------------------------------------------------------------------
  // mutateVolumeDiscountPercentage()
  // -----------------------------------------------------------------------
  mutateVolumeDiscountPercentage = async (value, indexA, indexB) => {
    const secondIndex = 1;
    const priceSlots = this.state.priceSlots[0];
    const newPriceSlots = update(priceSlots, {
      priceSlots: {
        [indexA]: {
          slotDiscount: {
            [indexB]: {
              [secondIndex]: { $set: value },
            },
          },
        },
      },
    });
    await this.setState({ priceSlots: [newPriceSlots] });
  };

  // -----------------------------------------------------------------------
  // handleRemoveDiscountSlot()
  // -----------------------------------------------------------------------
  handleRemoveDiscountSlot = async (e, indexA, indexB) => {
    e.persist();
    const priceSlots = this.state.priceSlots[0];
    const newPriceSlots = update(priceSlots, {
      priceSlots: {
        [indexA]: {
          slotDiscount: { $splice: [[indexB, 1]] },
        },
      },
    });
    await this.setState({ priceSlots: [newPriceSlots] });
  };

  // -----------------------------------------------------------------------
  // handleAddDiscountSlot()
  // -----------------------------------------------------------------------
  handleAddDiscountSlot = async (e, indexA, indexB) => {
    e.persist();
    const priceSlots = this.state.priceSlots[0];
    const newPriceSlots = update(priceSlots, {
      priceSlots: {
        [indexA]: {
          slotDiscount: { $push: [["", ""]] },
        },
      },
    });
    await this.setState({ priceSlots: [newPriceSlots] });
  };

  // -----------------------------------------------------------------------
  // handleRemovePriceSlot()
  // -----------------------------------------------------------------------
  handleRemovePriceSlot = async (e, indexA) => {
    e.persist();
    const priceSlots = this.state.priceSlots[0];
    const newPriceSlots = update(priceSlots, {
      priceSlots: { $splice: [[indexA, 1]] },
    });
    await this.setState({ priceSlots: [newPriceSlots] });
  };

  // -----------------------------------------------------------------------
  // handleAddPriceSlot()
  // -----------------------------------------------------------------------
  handleAddPriceSlot = async (e) => {
    e.persist();
    if (this.state.priceSlots.length > 0) {
      let priceSlotObj = {
        startTime: toTimeFormat(moment()),
        endTime: toTimeFormat(moment()),
        pricingAmount: "",
        slotDiscount: [["", ""]],
        bulkDiscount: {},
        isInvalid: false,
      };
      const priceSlots = this.state.priceSlots[0];
      const newPriceSlots = update(priceSlots, {
        priceSlots: { $push: [priceSlotObj] },
      });
      await this.setState({ priceSlots: [newPriceSlots] });
    } else {
      let pricingObj = {
        startDay: this.state.priceType === "holiday" ? 7 : 0,
        endDay: this.state.priceType === "holiday" ? 7 : 6,
        pricingVersion: this.state.priceType === "special" ? 1 : 0,
        priceSlots: [
          {
            startTime: toTimeFormat(moment()),
            endTime: toTimeFormat(moment()),
            pricingAmount: "",
            slotDiscount: [["", ""]],
            bulkDiscount: {},
            isInvalid: false,
          },
        ],
      };
      const priceSlots = this.state.priceSlots;
      const newPriceSlots = update(priceSlots, {
        $push: [pricingObj],
      });
      await this.setState({ priceSlots: newPriceSlots });
    }
  };

  // -----------------------------------------------------------------------
  // handleConfirmSavePricing()
  // -----------------------------------------------------------------------
  handleConfirmSavePricing = async (e) => {
    e.persist();
    let priceSlots = await this.modifyPriceSlots(this.state.priceSlots);
    let reqBody = {
      priceClass: parseInt(this.state.priceClass),
      activityId: parseInt(this.state.activityId),
      isPeriodicPricing: this.state.isPeriodicPricing,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      pricing: [
        {
          startDay:
            this.state.priceType === "holiday" ? 7 : this.state.startDay,
          endDay:
            this.state.priceType === "holiday"
              ? 7
              : this.state.checkedDayRangeValue === "range"
              ? this.state.endDay
              : this.state.startDay,
          slots: priceSlots[0].priceSlots,
        },
      ],
    };
    if (!this.state.fieldsAreRequired) {
      await this.props.dispatch(savePricingDetails(reqBody));
      if (this.props.savePricingDetailsToast) {
        if (this.props.savePricingDetailsToast.resourcesNotLoadedMessage) {
          resourcesNotLoadedToast();
        } else if (this.props.savePricingDetailsToast.httpErrorMessage) {
          toaster.danger(this.props.savePricingDetailsToast.httpErrorMessage, {
            id: "HTTP-ERROR-TOAST-1",
          });
        } else if (this.props.savePricingDetailsToast.requestStatus === -1) {
          toaster.notify("Sorry, you don't have permission to do that");
        } else if (this.props.savePricingDetailsToast.requestStatus === 1) {
          toaster.success(this.props.savePricingDetailsToast.message, {
            id: "EPP-TOAST",
          });
          let reqParams = {
            priceClass: parseInt(this.props.pricingData.priceClass),
            activityId: parseInt(this.props.pricingData.activityId),
          };
          await this.props.dispatch(getPricingConfigDetails(reqParams));
          if (this.props.pricingConfigDetails) {
            if (this.props.pricingConfigDetails.resourcesNotLoadedMessage) {
              resourcesNotLoadedToast();
            } else if (this.props.pricingConfigDetails.httpErrorMessage) {
              toaster.danger(this.props.pricingConfigDetails.httpErrorMessage, {
                id: "HTTP-ERROR-TOAST-1",
              });
            } else if (this.props.pricingConfigDetails.requestStatus === -1) {
              toaster.notify("Sorry, you don't have permission to do that");
            } else if (this.props.pricingConfigDetails.requestStatus === 1) {
              await this.props.handleEditPricingPageDisplay("hide");
              await this.props.dispatch({
                type: "SET_PRICING_DATA",
                payload: null,
              });
              await this.resetForm();
            } else if (this.props.savePricingDetailsToast.requestStatus === 0) {
              toaster.danger(this.props.savePricingDetailsToast.message, {
                id: "EPP-TOAST",
              });
            }
          }
        } else if (this.props.savePricingDetailsToast.requestStatus === 0) {
          toaster.danger(this.props.savePricingDetailsToast.message, {
            id: "EPP-TOAST",
          });
        }
      }
    }
  };

  // -----------------------------------------------------------------------
  // modifyPriceSlots()
  // -----------------------------------------------------------------------
  modifyPriceSlots = async (priceSlots) => {
    let modifiedPriceSlots = JSON.parse(JSON.stringify(priceSlots));
    await modifiedPriceSlots[0].priceSlots.forEach((slot) => {
      slot.price = slot.pricingAmount ? slot.pricingAmount.toString() : "";
      slot.slotDiscount = Object.fromEntries(
        slot.slotDiscount.filter((slot) => slot[0] !== "" && slot[1] !== "")
      );
    });

    var newPriceSlots = this.state.priceSlots[0];
    var areSlotsInvalid = false;
    var firstInvalidSlotIndex = null;
    for (var i = 0; i < modifiedPriceSlots[0].priceSlots.length; i++) {
      if (modifiedPriceSlots[0].priceSlots[i].pricingAmount === "") {
        newPriceSlots = update(newPriceSlots, {
          priceSlots: {
            [i]: {
              isInvalid: { $set: true },
            },
          },
        });
        areSlotsInvalid = true;
        firstInvalidSlotIndex =
          firstInvalidSlotIndex === null
            ? i
            : i < firstInvalidSlotIndex
            ? i
            : firstInvalidSlotIndex;
      } else {
        newPriceSlots = update(newPriceSlots, {
          priceSlots: {
            [i]: {
              isInvalid: { $set: false },
            },
          },
        });
      }
    }
    modifiedPriceSlots[0].priceSlots =
      await modifiedPriceSlots[0].priceSlots.map(
        ({ pricingAmount, day, pricingVersion, isInvalid, ...slot }) => slot
      );
    await this.setState({
      priceSlots: [newPriceSlots],
      fieldsAreRequired: areSlotsInvalid,
    });
    if (firstInvalidSlotIndex !== null) {
      await this.scrollToElement(
        `invalidHourlyPricing-${firstInvalidSlotIndex}`
      );
    }
    return modifiedPriceSlots;
  };

  // -----------------------------------------------------------------------
  // scrollToElement()
  // -----------------------------------------------------------------------
  scrollToElement = async (elem) => {
    await this.refs[elem].scrollIntoView({
      block: "center",
      behavior: "smooth",
    });
  };

  // -----------------------------------------------------------------------
  // render()
  // -----------------------------------------------------------------------
  render() {
    const {
      showConfirmDismissDialog,
      showForm,
      priceType,
      priceSlots,
      checkedDayRangeValue,
      startDay,
      endDay,
      clubMinuteStep,
      fieldsAreRequired,
      openHour,
      closeHour,
      slotDuration,
    } = this.state;

    return (
      <React.Fragment>
        <div className="EPP-A1-wrapper">
          <div className="EPP-A1-header-container">
            <Navbar />
            <PageHeadingAndActionsNav
              headingText={"Edit Pricing"}
              handleConfirmDismissDialog={this.handleConfirmDismissDialog}
            />
          </div>
          <div className="EPP-A1-body-container">
            <div className="EPP-A1-form-container">
              {showForm && (
                <React.Fragment>
                  <div className="EPP-A1-label-container">
                    <Text id="EPP-A1-label-type-one">
                      {priceType === "regular"
                        ? "Pricing Details"
                        : priceType === "holiday"
                        ? "Holiday Pricing"
                        : "Special Pricing"}
                    </Text>
                  </div>
                  <div className="EPP-A1-club-info-container EPP-A1-margin-top-18">
                    <Alert
                      intent="none"
                      title={`Club Timings: (${toTimeFormat1(
                        openHour
                      )} to ${toTimeFormat1(closeHour)})`}
                    >
                      <Text id="EPP-A1-label-type-three">
                        Configure Pricing per {slotDuration} Minutes.
                      </Text>
                    </Alert>
                  </div>
                  <div className="EPP-A1-regular-price-slots-actions-wrapper EPP-A1-margin-top-24">
                    {priceType !== "holiday" && (
                      <React.Fragment>
                        <div
                          className="EPP-A1-row-container"
                          aria-label="Radio Group Label 16"
                          role="group"
                        >
                          <Radio
                            className="EPP-A1-radio-input"
                            width={120}
                            marginRight={8}
                            size={16}
                            name="group25"
                            label="Range"
                            checked={
                              checkedDayRangeValue === "range" ? true : false
                            }
                            onChange={(e) =>
                              this.dayRangeRadioChange(e, "range")
                            }
                          />
                          <Radio
                            className="EPP-A1-radio-input"
                            width={120}
                            size={16}
                            name="group25"
                            label="Single Day"
                            checked={
                              checkedDayRangeValue === "single-day"
                                ? true
                                : false
                            }
                            onChange={(e) =>
                              this.dayRangeRadioChange(e, "single-day")
                            }
                          />
                        </div>

                        <div className="EPP-A1-row-container EPP-A1-margin-top-16">
                          <div className="EPP-A1-col-container">
                            <div className="EPP-A1-label-container EPP-A1-margin-bottom-8">
                              <Text id="EPP-A1-label-type-two">
                                {checkedDayRangeValue === "single-day"
                                  ? "For"
                                  : "From"}
                              </Text>
                            </div>
                            <div className="EPP-A1-days-select-container">
                              <DaysSelect
                                selectedDayId={startDay}
                                changeDay={this.handleStartDayChange}
                              />
                            </div>
                          </div>
                          {checkedDayRangeValue === "range" && (
                            <div className="EPP-A1-col-container EPP-A1-margin-left-48 ">
                              <div className="EPP-A1-label-container EPP-A1-margin-bottom-8">
                                <Text id="EPP-A1-label-type-two">To</Text>
                              </div>
                              <div className="EPP-A1-days-select-container">
                                <DaysSelect
                                  selectedDayId={endDay}
                                  changeDay={this.handleEndDayChange}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </React.Fragment>
                    )}
                    {priceSlots.length > 0 ? (
                      <div className="EPP-A1-price-slots-wrapper">
                        {priceSlots[0].priceSlots.map((slot, indexA) => (
                          <div
                            key={indexA}
                            className="EPP-A1-price-slot-row-container EPP-A1-margin-top-24"
                          >
                            <div className="EPP-A1-col-container EPP-A1-margin-right-16">
                              <div className="EPP-A1-label-container EPP-A1-margin-bottom-8">
                                <Text id="EPP-A1-label-type-two">
                                  Start Time
                                </Text>
                              </div>
                              <div
                                className="EPP-A1-timepicker-container"
                                onSelect={(e) =>
                                  this.saveTemporary(e, indexA, "startTime")
                                }
                              >
                                <CustomizedTimePicker
                                  clubMinuteStep={clubMinuteStep}
                                  handleTimeChange={this.handleTimeChange}
                                  time={getMomentTimeObj1(slot.startTime)}
                                />
                              </div>
                            </div>
                            <div className="EPP-A1-col-container EPP-A1-margin-right-16">
                              <div className="EPP-A1-label-container EPP-A1-margin-bottom-8">
                                <Text id="EPP-A1-label-type-two">End Time</Text>
                              </div>
                              <div
                                className="EPP-A1-timepicker-container"
                                onSelect={(e) =>
                                  this.saveTemporary(e, indexA, "endTime")
                                }
                              >
                                <CustomizedTimePicker
                                  clubMinuteStep={clubMinuteStep}
                                  handleTimeChange={this.handleTimeChange}
                                  time={getMomentTimeObj1(slot.endTime)}
                                />
                              </div>
                            </div>
                            <div className="EPP-A1-col-container EPP-A1-margin-right-16">
                              <div className="EPP-A1-label-container EPP-A1-margin-bottom-8">
                                <Text id="EPP-A1-label-type-two">
                                  Hourly Pricing
                                </Text>
                              </div>
                              <div
                                className="EPP-A1-text-input-container"
                                ref={`invalidHourlyPricing-${indexA}`}
                              >
                                <TextInput
                                  width={120}
                                  height={32}
                                  value={slot.pricingAmount}
                                  onChange={(e) =>
                                    this.handleHourlyPriceChange(e, indexA)
                                  }
                                  placeholder={`${generateCurrencySymbol()} Price per hour`}
                                  isInvalid={slot.isInvalid}
                                />
                              </div>
                            </div>
                            <div className="EPP-A1-vertical-line-decorator EPP-A1-margin-right-16" />
                            <div className="EPP-A1-col-container EPP-A1-margin-right-16">
                              {slot.slotDiscount.map((discountSlot, indexB) => (
                                <div
                                  key={indexB}
                                  className="EPP-A1-row-container EPP-A1-margin-bottom-8"
                                >
                                  <div className="EPP-A1-col-container EPP-A1-margin-right-16">
                                    <div className="EPP-A1-label-container EPP-A1-margin-bottom-8">
                                      <Text id="EPP-A1-label-type-two">
                                        Volume Discount
                                      </Text>
                                    </div>
                                    <div className="EPP-A1-text-input-container">
                                      <TextInput
                                        width={120}
                                        height={32}
                                        value={discountSlot[0]}
                                        onChange={(e) =>
                                          this.handleVolumeDiscountChange(
                                            e,
                                            indexA,
                                            indexB
                                          )
                                        }
                                        placeholder="Slot"
                                      />
                                    </div>
                                  </div>
                                  <div className="EPP-A1-col-container EPP-A1-margin-right-16">
                                    <div className="EPP-A1-label-container EPP-A1-margin-bottom-8">
                                      <Text id="EPP-A1-label-type-two">
                                        Discount
                                      </Text>
                                    </div>
                                    <div className="EPP-A1-text-input-container">
                                      <TextInput
                                        width={80}
                                        height={32}
                                        value={discountSlot[1]}
                                        onChange={(e) =>
                                          this.handleVolumeDiscountPercentageChange(
                                            e,
                                            indexA,
                                            indexB
                                          )
                                        }
                                        placeholder="%"
                                      />
                                    </div>
                                  </div>
                                  <div className="EPP-A1-slot-discount-actions-container EPP-A1-row-container EPP-A1-margin-right-32">
                                    {indexB > 0 && (
                                      <Tooltip content="Remove discount slot">
                                        <IconButton
                                          appearance="minimal"
                                          icon={<TrashIcon />}
                                          intent="danger"
                                          iconSize={16}
                                          marginRight={8}
                                          onClick={(e) =>
                                            this.handleRemoveDiscountSlot(
                                              e,
                                              indexA,
                                              indexB
                                            )
                                          }
                                        />
                                      </Tooltip>
                                    )}
                                    {indexB ===
                                      slot.slotDiscount.length - 1 && (
                                      <Tooltip content="Add discount slot">
                                        <IconButton
                                          appearance="minimal"
                                          cursor={"pointer"}
                                          icon={<AddIcon />}
                                          intent="success"
                                          iconSize={16}
                                          onClick={(e) =>
                                            this.handleAddDiscountSlot(
                                              e,
                                              indexA,
                                              indexB
                                            )
                                          }
                                        />
                                      </Tooltip>
                                    )}
                                  </div>
                                </div>
                              ))}
                            </div>
                            {indexA > 0 && (
                              <div className="EPP-A1-remove-time-slot-btn-container EPP-A1-row-container">
                                <Tooltip content="Remove time slot">
                                  <IconButton
                                    appearance="minimal"
                                    icon={<RemoveIcon />}
                                    iconSize={16}
                                    onClick={(e) =>
                                      this.handleRemovePriceSlot(e, indexA)
                                    }
                                  />
                                </Tooltip>
                              </div>
                            )}
                          </div>
                        ))}
                        <div className="EPP-A1-horizontal-line-decorator EPP-A1-margin-top-32" />
                        <div className="EPP-A1-row-container EPP-A1-margin-top-16">
                          <Button
                            className="EPP-A1-button"
                            width={105}
                            height={32}
                            iconBefore={<AddIcon />}
                            appearance="minimal"
                            onClick={(e) => this.handleAddPriceSlot(e)}
                          >
                            Add Slots
                          </Button>
                        </div>
                      </div>
                    ) : (
                      <React.Fragment>
                        <div className="EPP-A1-horizontal-line-decorator EPP-A1-margin-top-32" />
                        <div className="EPP-A1-row-container EPP-A1-margin-top-16">
                          <Button
                            className="EPP-A1-button"
                            width={105}
                            height={32}
                            iconBefore={<AddIcon />}
                            appearance="minimal"
                            onClick={(e) => this.handleAddPriceSlot(e)}
                          >
                            Add Slots
                          </Button>
                        </div>
                      </React.Fragment>
                    )}
                  </div>
                  <div className="EPP-A1-row-container EPP-A1-margin-top-68">
                    <Button
                      className="EPP-A1-button"
                      width={152}
                      height={32}
                      marginRight={36}
                      onClick={(e) => this.handleConfirmDismissDialog("show")}
                    >
                      Dismiss
                    </Button>
                    <div className="EPP-A1-col-container">
                      <Button
                        className="EPP-A1-button"
                        width={152}
                        height={32}
                        marginRight={36}
                        appearance="primary"
                        intent="success"
                        disabled={priceSlots.length > 0 ? false : true}
                        onClick={(e) => this.handleConfirmSavePricing(e)}
                      >
                        Save Pricing
                      </Button>
                      {fieldsAreRequired && (
                        <div className="EPP-A1-custom-inline-alert-container">
                          <CustomInlineAlert
                            intent="danger"
                            alertMsg="Form incomplete, fill all the required fields"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
        <Dialog
          width={560}
          isShown={showConfirmDismissDialog}
          title="Warning"
          intent="danger"
          confirmLabel="Confirm"
          onConfirm={(e) => this.handleConfirmDismissDialog("confirm")}
          onCloseComplete={(e) => this.handleConfirmDismissDialog("hide")}
        >
          <Text id="EPP-A1-confirmation-dialog-label">
            All the details will be cleared. Do you wish to continue?
          </Text>
        </Dialog>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({
  pricingData,
  savePricingDetailsToast,
  pricingConfigDetails,
}) => ({
  pricingData,
  savePricingDetailsToast,
  pricingConfigDetails,
});

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default connect(mapStateToProps)(EditPricingPage);
