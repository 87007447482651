// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { Text, TextInputField, Button, Paragraph, toaster } from "evergreen-ui";
import ReCAPTCHA from "react-google-recaptcha";
// import PlayoLogo from "../../assets/images/playo-logo.svg";
// import FootballGround from "../../assets/images/football-ground.png";
// import AndroidAppOnGooglePlay from "../../assets/images/android-app-on-google-play.png";
// import AvailableOnTheAppStore from "../../assets/images/available-on-the-app-store.png";
// import MapLogo from "../../assets/images/map-logo.png";
// import RunningShoeLogo from "../../assets/images/running-shoe-logo.png";
// import WhistleLogo from "../../assets/images/whistle-logo.png";
import { login, togglePremiumFeatureFlag } from "../../actions";
import { checkAuthTokenExists, resourcesNotLoadedToast } from "../../utils";
import Cookies from "universal-cookie";

const cookies = new Cookies();

// -----------------------------------------------------------------------
// LoginPage Component
// -----------------------------------------------------------------------
class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isUserNameInvalid: false,
      showIncorrectUserName: false,
      showIncorrectPassword: false,
      isPasswordInvalid: false,
      userName: "",
      password: "",
      captchaValue: "",
    };
  }

  // -----------------------------------------------------------------------
  // ComponentDimMount()
  // -----------------------------------------------------------------------
  componentDidMount() {
    document.title = "Playo Partner Console - Login";
  }

  // -----------------------------------------------------------------------
  // getInTouch()
  // -----------------------------------------------------------------------
  getInTouch = (e) => {
    e.persist();
    const url = "https://playo.co/contact";
    window.open(url, "_blank");
  };

  // -----------------------------------------------------------------------
  // linkToApp()
  // -----------------------------------------------------------------------
  linkToApp = (e, type) => {
    e.persist();
    if (type === "google-play") {
      let url =
        "https://play.google.com/store/apps/details?id=com.techmash.playobooking";
      window.open(url, "_blank");
    } else if (type === "app-store") {
      let url =
        "https://apps.apple.com/us/app/venue-partner-interface/id1057092085";
      window.open(url, "_blank");
    }
  };

  // -----------------------------------------------------------------------
  // handleInputChange()
  // -----------------------------------------------------------------------
  handleInputChange = async (e, type) => {
    e.persist();
    if (type === "username") {
      await this.setState({ userName: e.target.value });
      this.state.userName === ""
        ? await this.setState({
            isUserNameInvalid: true,
            showIncorrectUserName: false,
          })
        : await this.setState({
            isUserNameInvalid: false,
            showIncorrectUserName: false,
          });
    } else if (type === "password") {
      await this.setState({ password: e.target.value });
      this.state.password === ""
        ? await this.setState({
            isPasswordInvalid: true,
            showIncorrectPassword: false,
          })
        : await this.setState({
            isPasswordInvalid: false,
            showIncorrectPassword: false,
          });
    }
  };

  // -----------------------------------------------------------------------
  // handleLogin()
  // -----------------------------------------------------------------------
  handleLogin = async (e) => {
    e.preventDefault();
    if (this.state.userName !== "" && this.state.password !== "") {
      let reqBody = {
        username: this.state.userName.toString(),
        password: this.state.password.toString(),
        captcha: this.state.captchaValue,
      };
      await this.props.dispatch(login(reqBody));
      if (this.props.loginDetails) {
        if (this.props.loginDetails.resourcesNotLoadedMessage) {
          resourcesNotLoadedToast();
        } else if (this.props.loginDetails.httpErrorMessage) {
          toaster.danger(this.props.loginDetails.httpErrorMessage, {
            id: "HTTP-ERROR-TOAST-4",
          });
        } else if (this.props.loginDetails.requestStatus === -1) {
          toaster.notify("Sorry, you don't have permission to do that");
        } else if (this.props.loginDetails.requestStatus === 0) {
          if (this.props.loginDetails.message === "invalid username") {
            await this.setState({
              isUserNameInvalid: true,
              showIncorrectUserName: true,
              isPasswordInvalid: false,
              showIncorrectPassword: false,
            });
          } else if (this.props.loginDetails.message === "invalid password") {
            await this.setState({
              isUserNameInvalid: false,
              showIncorrectUserName: false,
              isPasswordInvalid: true,
              showIncorrectPassword: true,
            });
          } else {
            toaster.danger(this.props.loginDetails.message, {
              id: "HTTP-ERROR-TOAST-4",
            });
          }
        } else if (
          this.props.loginDetails.requestStatus === 1 &&
          // ToDo: remove string comparison
          this.props.loginDetails.message === "login successful"
        ) {
          cookies.set(
            "playoAuthToken",
            this.props.loginDetails.userAuth.authToken.toString(),
            {
              path: "/",
              maxAge: 31536000,
            }
          );
          cookies.set(
            "playoUserName",
            this.props.loginDetails.userAuth.username.toString(),
            {
              path: "/",
              maxAge: 31536000,
            }
          );
          cookies.set(
            "playoClubName",
            this.props.loginDetails.userAuth.clubName.toString(),
            {
              path: "/",
              maxAge: 31536000,
            }
          );
          cookies.set(
            "playoClubCountryCallingCode",
            this.props.loginDetails.userAuth.countryCode.toString(),
            {
              path: "/",
              // maxAge: 31536000
            }
          );
          cookies.set(
            "playoClubCurrency",
            this.props.loginDetails.userAuth.clubCurrency.toString(),
            {
              path: "/",
              // maxAge: 31536000
            }
          );
          localStorage.setItem(
            "playoUserClubs",
            JSON.stringify(this.props.loginDetails.userAuth.userClubs)
          );
          localStorage.setItem(
            "clubConfig",
            JSON.stringify(this.props.loginDetails.userAuth.config)
          );
          localStorage.setItem(
            "handlers",
            JSON.stringify(this.props.loginDetails.userAuth.handlers)
          );
          cookies.set(
            "playoClubId",
            this.props.loginDetails.userAuth.clubId.toString(),
            {
              path: "/",
              // maxAge: 31536000
            }
          );
          cookies.set("playoUserId", this.props.loginDetails.userAuth.id, {
            path: "/",
            // maxAge: 31536000
          });
          await this.props.dispatch({ type: "TOGGLE_AUTH_STATUS" });
        }
      }
    } else {
      if (this.state.userName === "") {
        await this.setState({ isUserNameInvalid: true });
      }
      if (this.state.password === "") {
        await this.setState({ isPasswordInvalid: true });
      }
    }
  };

  // -----------------------------------------------------------------------
  // handleEnterOLoginFields()
  // -----------------------------------------------------------------------
  handleEnterOLoginFields = async (e) => {
    e.persist();
    if (this.state.userName !== "" && this.state.password !== "") {
      if (e.key === "Enter") {
        await this.handleLogin(e);
      }
    }
  };

  handleCaptchaChange = (captchaValue) => {
    this.setState({ captchaValue });
  };

  // -----------------------------------------------------------------------
  // render()
  // -----------------------------------------------------------------------
  render() {
    const {
      isUserNameInvalid,
      showIncorrectUserName,
      showIncorrectPassword,
      isPasswordInvalid,
      userName,
      password,
    } = this.state;
    if (checkAuthTokenExists()) {
      return <Redirect to="/" />;
    }
    return (
      <React.Fragment>
        <div className="LP-A1-wrapper">
          <div className="LP-A1-company-logo-top-nav-bar">
            <img
              className="LP-A1-playo-logo"
              src="https://playo-website.gumlet.io/VSP-2.0/playo-logo.svg?auto=format,compress,enhance"
              alt="playo-logo"
            />
          </div>
          <div className="LP-A1-body-container">
            <div className="LP-A1-body-left-section-container">
              <img
                className="LP-A1-football-ground-image"
                src="https://playo-website.gumlet.io/VSP-2.0/football-ground.png?auto=format,compress,enhance"
                alt="football-ground"
              />
              <div className="LP-A1-hello-partner-label-container">
                <div className="LP-A1-label-container LP-A1-margin-bottom-12">
                  <Text id="LP-A1-label-type-one">Hello</Text>
                </div>
                <div className="LP-A1-label-container">
                  <Text id="LP-A1-label-type-two">Partner.</Text>
                </div>
              </div>
              <div className="LP-A1-descriptive-logo-wrapper LP-A1-row-container">
                <div className="LP-A1-col-container LP-A1-margin-right-64">
                  <div className="LP-A1-descriptive-logo-container LP-A1-margin-bottom-8">
                    <img
                      className="LP-A1-descriptive-logo LP-A1-map-logo"
                      src="https://playo-website.gumlet.io/VSP-2.0/map-logo.png?auto=format,compress,enhance"
                      alt="map-logo"
                    />
                  </div>
                  <div className="LP-A1-label-container LP-A1-width-76">
                    <Text id="LP-A1-label-type-seven">{`Venues & Clubs`}</Text>
                  </div>
                </div>
                <div className="LP-A1-col-container LP-A1-margin-right-64">
                  <div className="LP-A1-descriptive-logo-container LP-A1-margin-bottom-8">
                    <img
                      className="LP-A1-descriptive-logo LP-A1-running-shoe-logo"
                      src="https://playo-website.gumlet.io/VSP-2.0/running-shoe-logo.png?auto=format,compress,enhance"
                      alt="running-shoe-logo"
                    />
                  </div>
                  <div className="LP-A1-label-container LP-A1-width-76">
                    <Text id="LP-A1-label-type-seven">{`Activity Organizers`}</Text>
                  </div>
                </div>
                <div className="LP-A1-col-container">
                  <div className="LP-A1-descriptive-logo-container LP-A1-margin-bottom-8">
                    <img
                      className="LP-A1-descriptive-logo LP-A1-whistle-logo"
                      src="https://playo-website.gumlet.io/VSP-2.0/whistle-logo.png?auto=format,compress,enhance"
                      alt="whistle-logo"
                    />
                  </div>
                  <div className="LP-A1-label-container LP-A1-width-76">
                    <Text id="LP-A1-label-type-seven">{`Trainers & Academies`}</Text>
                  </div>
                </div>
              </div>
            </div>
            <div className="LP-A1-body-right-section-container">
              <div className="LP-A1-col-container LP-A1-margin-bottom-100">
                <div className="LP-A1-label-container LP-A1-margin-bottom-24">
                  <Text id="LP-A1-label-type-three">Login</Text>
                </div>
                <div className="LP-A1-text-input-field-container LP-A1-margin-bottom-24">
                  <TextInputField
                    className="LP-A1-text-input-field"
                    width={280}
                    height={32}
                    label="Username"
                    placeholder="Username"
                    value={userName}
                    isInvalid={isUserNameInvalid}
                    validationMessage={
                      showIncorrectUserName ? "Incorrect username" : null
                    }
                    onKeyUp={(e) => this.handleEnterOLoginFields(e)}
                    onChange={(e) => this.handleInputChange(e, "username")}
                  />
                </div>
                <div className="LP-A1-text-input-field-container LP-A1-margin-bottom-32">
                  <TextInputField
                    className="LP-A1-text-input-field"
                    width={280}
                    height={32}
                    label="Password"
                    placeholder="Password"
                    type="password"
                    value={password}
                    isInvalid={isPasswordInvalid}
                    validationMessage={
                      showIncorrectPassword ? "Incorrect password" : null
                    }
                    onKeyUp={(e) => this.handleEnterOLoginFields(e)}
                    onChange={(e) => this.handleInputChange(e, "password")}
                  />
                </div>
                <div className="LP-A1-text-input-field-container LP-A1-margin-bottom-32">
                  <ReCAPTCHA
                    sitekey="6LcftggTAAAAAOo7nJHUSTyNc7Q9bDlZDN5Dvvk7"
                    onChange={this.handleCaptchaChange}
                  />
                </div>
                <div className="LP-A1-button-container LP-A1-margin-bottom-40">
                  <Button
                    className="LP-A1-button"
                    appearance="primary"
                    width={144}
                    height={32}
                    onClick={(e) => this.handleLogin(e)}
                  >
                    Login
                  </Button>
                </div>
                <div className="LP-A1-label-container">
                  <Paragraph>
                    <Text id="LP-A1-label-type-four">
                      {"Don't have an account yet? "}
                    </Text>
                    <Text
                      id="LP-A1-label-type-five"
                      onClick={(e) => this.getInTouch(e)}
                    >
                      {"Get in touch,"}
                    </Text>
                    <Text id="LP-A1-label-type-four">
                      {" to become a playo partner."}
                    </Text>
                  </Paragraph>
                </div>
              </div>
              <div className="LP-A1-footer-container">
                <div className="LP-A1-col-container">
                  <div className="LP-A1-label-container LP-A1-margin-bottom-24">
                    <Text id="LP-A1-label-type-six">Get Partner App Now!</Text>
                  </div>
                  <div className="LP-A1-row-container LP-A1-google-play-app-store-logo-container">
                    <div
                      className="LP-A1-logo-container LP-A1-play-store-logo-container LP-A1-margin-right-32"
                      onClick={(e) => this.linkToApp(e, "google-play")}
                    >
                      <img
                        className="LP-A1-store-logo"
                        src="https://playo-website.gumlet.io/VSP-2.0/get-it-on-google-play-logo.png?auto=format,compress,enhance"
                        alt="google-play-logo"
                      />
                    </div>
                    <div
                      className="LP-A1-logo-container LP-A1-app-store-logo-container"
                      onClick={(e) => this.linkToApp(e, "app-store")}
                    >
                      <img
                        className="LP-A1-store-logo"
                        src="https://playo-website.gumlet.io/VSP-2.0/download-on-the-app-store-logo.png?auto=format,compress,enhance"
                        alt="app-store-logo"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ authStatus, loginDetails }) => ({
  authStatus,
  loginDetails,
});

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default connect(mapStateToProps)(LoginPage);
