// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import {
  Text,
  Table,
  Position,
  Popover,
  Menu,
  IconButton,
  Dialog,
  toaster,
  DocumentOpenIcon,
  ChangesIcon,
  Pane,
  Tablist,
  Tab,
  Paragraph,
  MoreIcon,
  Spinner,
} from "evergreen-ui";
import {
  getTransactionsDetails,
  togglePremiumFeatureFlag,
  generateBookingStatement,
} from "../../actions";
import { connect } from "react-redux";
import {
  toDateFormat16,
  toDateFormat,
  slotTimeConverter12,
  resourcesNotLoadedToast,
  generateCurrencySymbol,
  toTimeFormat1,
} from "../../utils";

import TransactionsDialogContent from "../../components/TransactionsDialogContent";

// -----------------------------------------------------------------------
// BookingTable Component
// -----------------------------------------------------------------------
class BookingTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMenuPopover: true,
      showTransactionsDialog: false,
      activeBookingId: "",
      selectedIndex: 0,
      loader: false,
      tabs: [],
    };
  }

  // -----------------------------------------------------------------------
  //  handleTransactionsDialog()
  // -----------------------------------------------------------------------
  handleTransactionsDialog = async (type) => {
    if (type === "hide") {
      await this.setState({ showTransactionsDialog: false });
    }
    if (type === "show") {
      await this.props.dispatch(
        getTransactionsDetails(this.state.activeBookingId)
      );
      if (this.props.transactionsDetails) {
        if (this.props.transactionsDetails.resourcesNotLoadedMessage) {
          resourcesNotLoadedToast();
        } else if (this.props.transactionsDetails.httpErrorMessage) {
          toaster.danger(this.props.transactionsDetails.httpErrorMessage, {
            id: "HTTP-ERROR-TOAST-1",
          });
        } else if (this.props.transactionsDetails.requestStatus === -1) {
          toaster.notify("Sorry, you don't have permission to do that");
        } else if (this.props.transactionsDetails.requestStatus === 1) {
          await this.setState({ showTransactionsDialog: true });
        } else if (this.props.transactionsDetails.requestStatus === 0) {
          toaster.danger(this.props.transactionsDetails.message);
        }
      }
    }
  };

  // -----------------------------------------------------------------------
  //  changeActiveReportDetails()
  // -----------------------------------------------------------------------
  changeActiveReportDetails = async (bookingId) => {
    await this.setState({ activeBookingId: bookingId });
  };

  // -----------------------------------------------------------------------
  //  generateStatement()
  // -----------------------------------------------------------------------
  generateStatement = async () => {
    let reqParams = { bookingId: this.state.activeBookingId };
    await this.props.dispatch(generateBookingStatement(reqParams));
    if (this.props.generateBookingStatementToast) {
      if (this.props.generateBookingStatementToast.resourcesNotLoadedMessage) {
        resourcesNotLoadedToast();
      } else if (this.props.generateBookingStatementToast.httpErrorMessage) {
        toaster.danger(
          this.props.generateBookingStatementToast.httpErrorMessage,
          {
            id: "HTTP-ERROR-TOAST-1",
          }
        );
      } else if (
        this.props.generateBookingStatementToast.requestStatus === -1
      ) {
        toaster.notify("Sorry, you don't have permission to do that");
      } else if (this.props.generateBookingStatementToast.requestStatus === 1) {
        // toaster.success(this.props.generateBookingStatementToast.message);
      } else if (this.props.generateBookingStatementToast.requestStatus === 0) {
        toaster.danger(this.props.generateBookingStatementToast.message);
      }
    }
  };

  handleTabSelect = (index) => {
    this.setState({ loader: true }, () => {
      // The loader state is now true
      this.setState({ selectedIndex: index, loader: false });
      // The loader state is now false
    });
  };

  // -----------------------------------------------------------------------
  // render()
  // -----------------------------------------------------------------------
  render() {
    const {
      showMenuPopover,
      showTransactionsDialog,
      tabs,
      loader,
      selectedIndex,
    } = this.state;
    const { reportsData, transactionsDetails } = this.props;

    return (
      <React.Fragment>
        <Tablist marginY={20} marginRight={24}>
          {reportsData?.data.map((club, index) => (
            <Tab
              aria-controls={`panel-${club}`}
              isSelected={index === selectedIndex}
              key={club?.clubId}
              onSelect={() => this.handleTabSelect(index)}
            >
              {club.clubName}
            </Tab>
          ))}
        </Tablist>
        <Pane background="tint1" flex="1">
          {reportsData?.data.map((club, index) => (
            <Pane
              aria-labelledby={club}
              aria-hidden={index !== selectedIndex}
              display={index === selectedIndex ? "block" : "none"}
              key={club?.clubId}
              role="tabpanel"
            >
              <div className="RT-A1-reports-table-container">
                <Table>
                  <Table.Head>
                    <Table.TextHeaderCell className="RT-A1-table-text-header-cell RT-A1-flex-basis-164">
                      Timestamp
                    </Table.TextHeaderCell>
                    <Table.TextHeaderCell className="RT-A1-table-text-header-cell RT-A1-flex-basis-91">
                      Type
                    </Table.TextHeaderCell>
                    <Table.TextHeaderCell className="RT-A1-table-text-header-cell RT-A1-flex-basis-211">
                      Booking Detail
                    </Table.TextHeaderCell>
                    <Table.TextHeaderCell className="RT-A1-table-text-header-cell RT-A1-flex-basis-167">
                      Customer Detail
                    </Table.TextHeaderCell>
                    <Table.TextHeaderCell className="RT-A1-table-text-header-cell RT-A1-flex-basis-90">
                      Gross / Discount
                    </Table.TextHeaderCell>
                    <Table.TextHeaderCell className="RT-A1-table-text-header-cell RT-A1-flex-basis-91">
                      Paid / Net
                    </Table.TextHeaderCell>
                    <Table.TextHeaderCell className="RT-A1-table-text-header-cell RT-A1-flex-basis-120">
                      Place / Handler
                    </Table.TextHeaderCell>
                    <Table.TextHeaderCell className="RT-A1-table-text-header-cell RT-A1-flex-basis-56" />
                  </Table.Head>

                  <Table.Body className="RT-A1-table-body" overflow="none">
                    {club &&
                      club.data &&
                      club.data.map((data, index) => (
                        <Table.Row
                          isSelectable
                          className="RT-A1-table-row"
                          key={index}
                        >
                          <Table.Cell className="RT-A1-table-cell RT-A1-flex-basis-164">
                            <div className="RT-A1-table-cell-data-container">
                              <Text id="RT-A1-label-type-one" marginBottom={8}>
                                {toDateFormat16(data.timestamp.split(" ")[0])}
                              </Text>
                              <Text id="RT-A1-label-type-two" marginBottom={8}>
                                {toTimeFormat1(data.timestamp.split(" ")[1])}
                              </Text>
                            </div>
                          </Table.Cell>
                          <Table.Cell className="RT-A1-table-cell RT-A1-flex-basis-91">
                            <div className="RT-A1-table-cell-data-container">
                              <Text
                                id="RT-A1-label-type-three"
                                marginBottom={8}
                              >
                                {data.type}
                              </Text>
                            </div>
                          </Table.Cell>
                          <Table.Cell className="RT-A1-table-cell RT-A1-flex-basis-211">
                            <div className="RT-A1-table-cell-data-container">
                              <Text
                                id="RT-A1-label-type-three"
                                marginBottom={8}
                              >
                                {`${data.sportName} ${data.bookingId}`}
                              </Text>
                              <Text id="RT-A1-label-type-two" marginBottom={8}>
                                {`${toDateFormat(
                                  data.slotDate
                                )} | ${slotTimeConverter12(
                                  data.startTime,
                                  data.endTime
                                )}`}
                              </Text>
                              <Text id="RT-A1-label-type-two" marginBottom={8}>
                                {data.court}
                              </Text>
                              {data.remarks !== "" && (
                                <Text
                                  id="RT-A1-label-type-two"
                                  marginBottom={8}
                                >
                                  {data.remarks}
                                </Text>
                              )}
                            </div>
                          </Table.Cell>
                          <Table.Cell className="RT-A1-table-cell RT-A1-flex-basis-167">
                            <div className="RT-A1-table-cell-data-container">
                              <Text
                                id="RT-A1-label-type-three"
                                marginBottom={8}
                              >
                                {data.customerName}
                              </Text>
                              <Text id="RT-A1-label-type-two" marginBottom={8}>
                                {data.customerMobile}
                              </Text>
                              <Text id="RT-A1-label-type-two" marginBottom={8}>
                                {data.customerEmail}
                              </Text>
                            </div>
                          </Table.Cell>
                          <Table.Cell className="RT-A1-table-cell RT-A1-flex-basis-90">
                            <div className="RT-A1-table-cell-data-container">
                              <Text
                                id="RT-A1-label-type-three"
                                marginBottom={8}
                              >
                                {`${generateCurrencySymbol()} ${data.gross}`}
                              </Text>
                              <Text id="RT-A1-label-type-two" marginBottom={8}>
                                {`${generateCurrencySymbol()} ${data.discount}`}
                              </Text>
                            </div>
                          </Table.Cell>
                          <Table.Cell className="RT-A1-table-cell RT-A1-flex-basis-91">
                            <div className="RT-A1-table-cell-data-container">
                              <Text
                                id="RT-A1-label-type-three"
                                marginBottom={8}
                              >
                                {`${generateCurrencySymbol()} ${data.paid}`}
                              </Text>
                              <Text id="RT-A1-label-type-two" marginBottom={8}>
                                {`${generateCurrencySymbol()} ${data.net}`}
                              </Text>
                            </div>
                          </Table.Cell>
                          <Table.Cell className="RT-A1-table-cell RT-A1-flex-basis-120">
                            <div className="RT-A1-table-cell-data-container">
                              <Text id="RT-A1-label-type-two" marginBottom={8}>
                                {`${data.place} /`}
                              </Text>
                              <Text id="RT-A1-label-type-two" marginBottom={8}>
                                {data.handler}
                              </Text>
                            </div>
                          </Table.Cell>
                          <Table.Cell className="RT-A1-table-cell RT-A1-flex-basis-56">
                            <div className="RT-A1-action-btn-container">
                              {showMenuPopover && (
                                <Popover
                                  onOpen={() =>
                                    this.changeActiveReportDetails(
                                      data.bookingId
                                    )
                                  }
                                  content={
                                    <Menu>
                                      <Menu.Group>
                                        <Menu.Item
                                          icon={<DocumentOpenIcon />}
                                          onSelect={() =>
                                            this.generateStatement()
                                          }
                                        >
                                          Statements
                                        </Menu.Item>
                                        <Menu.Item
                                          icon={<ChangesIcon />}
                                          onSelect={() =>
                                            this.handleTransactionsDialog(
                                              "show"
                                            )
                                          }
                                        >
                                          Transactions
                                        </Menu.Item>
                                      </Menu.Group>
                                    </Menu>
                                  }
                                  position={Position.BOTTOM_RIGHT}
                                >
                                  <IconButton
                                    appearance="minimal"
                                    icon={<MoreIcon />}
                                    width={24}
                                    height={24}
                                  />
                                </Popover>
                              )}
                            </div>
                          </Table.Cell>
                        </Table.Row>
                      ))}
                  </Table.Body>
                </Table>
              </div>

              <Dialog
                width="fit-content"
                isShown={showTransactionsDialog}
                title="Transactions"
                onCloseComplete={() => this.handleTransactionsDialog("hide")}
                hasFooter={false}
              >
                <TransactionsDialogContent
                  transactionsDetails={transactionsDetails}
                />
              </Dialog>
            </Pane>
          ))}
        </Pane>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({
  reportsData,
  transactionsDetails,
  generateBookingStatementToast,
}) => ({
  reportsData,
  transactionsDetails,
  generateBookingStatementToast,
});

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default connect(mapStateToProps)(BookingTable);
