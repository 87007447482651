// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import {Text, Badge, Switch, Table} from "evergreen-ui";
import {getCouponCodes} from "../../actions";
import Api from "../../api";
import {connect} from "react-redux";

// -----------------------------------------------------------------------
// CouponCodesTable Component
// -----------------------------------------------------------------------
class CouponCodesTable extends React.Component {

    updateCouponCode = async (coupon, couponDetailId) => {
        const requestBody = {
            couponDetailId,
            couponCodes: Object()
        };
        requestBody.couponCodes[coupon.couponCode] = {
            couponCode: coupon.couponCode,
            expired: coupon.expired
        };
        await Api.updateCouponCodes(requestBody);
        await this.props.dispatch(getCouponCodes({id: couponDetailId}))
    };

    // -----------------------------------------------------------------------
    // render()
    // -----------------------------------------------------------------------
    render() {
        const {
            couponCodes,
            couponDetailId
        } = this.props;
        return (
            <React.Fragment>
                <div className="CCTP-A1-table-container">
                    <Table>
                        <Table.Head>
                            <Table.TextHeaderCell className="ST-A1-table-text-header-cell CCTP-A1-flex-basis-130">
                                Coupon code
                            </Table.TextHeaderCell>
                            <Table.TextHeaderCell className="ST-A1-table-text-header-cell CCTP-A1-flex-basis-120">
                                Usage
                            </Table.TextHeaderCell>
                            <Table.TextHeaderCell className="ST-A1-table-text-header-cell CCTP-A1-flex-basis-120">
                                Status
                            </Table.TextHeaderCell>
                        </Table.Head>
                        <Table.Body className="MT-A1-table-body" overflow="none">
                            {couponCodes.data.map((coupon, index) =>
                                <Table.Row isSelectable className="CCTP-A1-table-row" key={index}>
                                    <Table.Cell className="ST-A1-table-cell CCTP-A1-flex-basis-130 Text-Align-Center">
                                        <Text id="ST-A1-label-type-one" marginTop={8}>{coupon.couponCode}</Text>
                                    </Table.Cell>
                                    <Table.Cell className="ST-A1-table-cell CCTP-A1-flex-basis-120 Text-Align-Center">
                                        <Text id="ST-A1-label-type-two" marginTop={8}>{coupon.usage}</Text>
                                    </Table.Cell>
                                    <Table.Cell className="ST-A1-table-cell CCTP-A1-flex-basis-120 Text-Align-Center">
                                        {coupon.expired ? <Badge color="red">Expired</Badge> :
                                            <Badge color="green">Active</Badge>}
                                    </Table.Cell>
                                    <Table.Cell className="ST-A1-table-cell CCTP-A1-flex-basis-70 Text-Align-Center">
                                        <Switch
                                            checked={!coupon.expired}
                                            onChange={e => {
                                                coupon.expired = !coupon.expired;
                                                this.updateCouponCode(coupon, couponDetailId)
                                            }}
                                        />
                                    </Table.Cell>
                                </Table.Row>
                            )}
                        </Table.Body>
                    </Table>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (
    {
        couponCodes
    }) => (
    {
        couponCodes
    });

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default connect(mapStateToProps)(CouponCodesTable);
