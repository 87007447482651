import React from "react";
import { Text, Button, Strong, Pane } from "evergreen-ui";

const index = ({ onReschedule, bookingId, rescheduleEnableText }) => {
  return (
    <Pane display={"flex"} flexDirection={"column"} border borderRadius={5} padding={15}>
      {" "}
      <Pane display='flex' flexDirection='row' alignItems='center' justifyContent={"space-between"}>
        {" "}
        <Strong color={"#696f8c"}>Enable Reschedule </Strong>{" "}
        <Button
          appearance='minimal'
          minWidth={70}
          onClick={() => {
            onReschedule(bookingId);
          }}
        >
          <Strong color='#0679c5'>Enable</Strong>
        </Button>
      </Pane>
      <Pane marginTop={10}>
        <Text>{rescheduleEnableText}</Text>
      </Pane>
    </Pane>
  );
};

export default index;
