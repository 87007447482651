// -----------------------------------------------------------------------
// Import Api and other Resources
// -----------------------------------------------------------------------
import Api from "../api";
import { toggleLoaderOn, toggleLoaderOff } from "./loaderUtils";
import { handleServerResponse } from "./handleServerResponse";
import { toaster } from "evergreen-ui";
import { filterBookingDetailsByDate } from "../utils";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
// -----------------------------------------------------------------------
// getCountryDetails()
// -----------------------------------------------------------------------
export const getCountryDetails = () => async (dispatch) => {
  try {
    const res = await Api.getCountryDetailsData();

    if (res.status === 200) {
      if (res.body) {
        const body = res.body;
        await dispatch({ type: "SET_COUNTRY_DETAILS", payload: body.data });
        await dispatch({
          type: "SET_COUNTRY_DETAILS_TOAST",
          payload: {
            message:
              "Successfully fetched country details from https://restcountries.eu/rest/v2/all",
            requestStatus: 1,
          },
        });
      }
    }
  } catch (res) {
    await handleServerResponse(dispatch, res, "SET_COUNTRY_DETAILS_TOAST");
  }
};

// -----------------------------------------------------------------------
// getDefaultCountryDetails()
// -----------------------------------------------------------------------
// export const getDefaultCountryDetails = (callingCode) => async (dispatch) => {
// 	try {
// 		const res = await Api.getDefaultCountryDetailsData(callingCode);
// 		if (res.status === 200) {
// 			if (res.body) {
// 				const body = res.body;
// 				await dispatch({ type: "SET_DEFAULT_COUNTRY_DETAILS", payload: body });
// 				await dispatch({
// 					type: "SET_DEFAULT_COUNTRY_DETAILS_TOAST",
// 					payload: {
// 						message:
// 							"Successfully fetched default country details from https://restcountries.eu/rest/v2/all",
// 						requestStatus: 1,
// 					},
// 				});
// 			}
// 		}
// 	} catch (res) {
// 		await handleServerResponse(
// 			dispatch,
// 			res,
// 			"SET_DEFAULT_COUNTRY_DETAILS_TOAST"
// 		);
// 	}
// };

// -----------------------------------------------------------------------
// getTransactionCodesAndTransactionTypes()
// -----------------------------------------------------------------------
export const getTransactionCodesAndTransactionTypes =
  () => async (dispatch) => {
    try {
      toggleLoaderOn(dispatch);
      const res = await Api.getTransactionCodesAndTransactionTypesData();

      await handleServerResponse(
        dispatch,
        res,
        "SET_TRANSACTION_CODES_AND_TRANSACTION_TYPES"
      );
    } catch (res) {
      await handleServerResponse(
        dispatch,
        res,
        "SET_TRANSACTION_CODES_AND_TRANSACTION_TYPES"
      );
    }
  };

// -----------------------------------------------------------------------
// getActivities()
// -----------------------------------------------------------------------
export const getActivities = () => async (dispatch) => {
  try {
    toggleLoaderOn(dispatch);
    const res = await Api.getActivitiesData();
    await handleServerResponse(dispatch, res, "SET_CLUB_ACTIVITY_DATA");
  } catch (res) {
    await handleServerResponse(dispatch, res, "SET_CLUB_ACTIVITY_DATA");
  }
};

// -----------------------------------------------------------------------
// getSlots()
// -----------------------------------------------------------------------
export const getSlots = (reqBody) => async (dispatch) => {
  try {
    toggleLoaderOn(dispatch);
    const res = await Api.getSlotsData(reqBody);
    if (res.body) {
      toggleLoaderOff(dispatch);
      const body = res.body;
      if (body.requestStatus === 1) {
        await dispatch({ type: "SET_SLOTS_LIST", payload: body.data });
        await dispatch({
          type: "SET_SLOTS_TOAST",
          payload: { message: body.message, requestStatus: 1 },
        });
      } else if (body.requestStatus === 0) {
        await dispatch({
          type: "SET_SLOTS_TOAST",
          payload: { message: body.message, requestStatus: 0 },
        });
      } else if (body.requestStatus === -1) {
        await dispatch({
          type: "SET_SLOTS_TOAST",
          payload: { message: body.message, requestStatus: -1 },
        });
      }
    }
  } catch (res) {
    await handleServerResponse(dispatch, res, "SET_SLOTS_TOAST");
  }
};

// -----------------------------------------------------------------------
// getCart()
// -----------------------------------------------------------------------
export const getCart = () => async (dispatch) => {
  try {
    toggleLoaderOn(dispatch);
    const res = await Api.getCartData();
    await handleServerResponse(dispatch, res, "SET_CART_DATA");
    toggleLoaderOff(dispatch);
  } catch (res) {
    await handleServerResponse(dispatch, res, "SET_CART_DATA");
  }
};

// -----------------------------------------------------------------------
// addSlotToCart()
// -----------------------------------------------------------------------
export const addSlotToCart = (reqBody) => async (dispatch) => {
  try {
    const res = await Api.addSlotToCartData(reqBody);
    if (res.body) {
      const body = res.body;
      if (body.requestStatus === 1) {
        await dispatch({ type: "SET_CART_DATA", payload: body });
        await dispatch({
          type: "SET_CART_TOAST",
          payload: { message: body.message, requestStatus: 1 },
        });
      } else if (body.requestStatus === 0) {
        await dispatch({
          type: "SET_CART_TOAST",
          payload: { message: body.message, requestStatus: 0 },
        });
      } else if (body.requestStatus === -1) {
        await dispatch({
          type: "SET_CART_TOAST",
          payload: { message: body.message, requestStatus: -1 },
        });
      }
    }
  } catch (res) {
    await handleServerResponse(dispatch, res, "SET_CART_TOAST");
  }
};

// -----------------------------------------------------------------------
// clearCart()
// -----------------------------------------------------------------------
export const clearCart = () => async (dispatch) => {
  try {
    const res = await Api.clearCartData();
    if (res.body) {
      const body = res.body;
      if (body.requestStatus === 1) {
        await dispatch({ type: "SET_CART_DATA", payload: body });
        await dispatch({
          type: "SET_CART_TOAST",
          payload: { message: body.message, requestStatus: 1 },
        });
      } else if (body.requestStatus === 0) {
        await dispatch({
          type: "SET_CART_TOAST",
          payload: { message: body.message, requestStatus: 0 },
        });
      } else if (body.requestStatus === -1) {
        await dispatch({
          type: "SET_CART_TOAST",
          payload: { message: body.message, requestStatus: -1 },
        });
      }
    }
  } catch (res) {
    await handleServerResponse(dispatch, res, "SET_CART_TOAST");
  }
};

// -----------------------------------------------------------------------
// removeFromCart()
// -----------------------------------------------------------------------
export const removeFromCart = (reqBody) => async (dispatch) => {
  try {
    const res = await Api.removeFromCartData(reqBody);
    if (res.body) {
      const body = res.body;
      if (body.requestStatus === 1) {
        await dispatch({ type: "SET_CART_DATA", payload: body });
        await dispatch({
          type: "SET_CART_TOAST",
          payload: { message: body.message, requestStatus: 1 },
        });
      } else if (body.requestStatus === 0) {
        await dispatch({
          type: "SET_CART_TOAST",
          payload: { message: body.message, requestStatus: 0 },
        });
      } else if (body.requestStatus === -1) {
        await dispatch({
          type: "SET_CART_TOAST",
          payload: { message: body.message, requestStatus: -1 },
        });
      }
    }
  } catch (res) {
    await handleServerResponse(dispatch, res, "SET_CART_TOAST");
  }
};

// -----------------------------------------------------------------------
// unblocking()
// -----------------------------------------------------------------------
export const unblocking = (reqBody) => async (dispatch) => {
  try {
    toggleLoaderOn(dispatch);
    const res = await Api.unblockingData(reqBody);
    await handleServerResponse(dispatch, res, "SET_UNBLOCKING_TOAST");
  } catch (res) {
    await handleServerResponse(dispatch, res, "SET_UNBLOCKING_TOAST");
  }
};

// // -----------------------------------------------------------------------
// // getCountryCodes()
// // -----------------------------------------------------------------------
// export const getCountryCodes = () => async dispatch => {
//   try {
//     toggleLoaderOn(dispatch);
//     const res = await Api.getCountryCodesData();
//     if (res.body) {
//       toggleLoaderOff(dispatch);
//       const body = res.body;
//       if (body.requestStatus === 1) {
//       await   dispatch({ type: "SET_COUNTRY_CODES", payload: body.countryCodes });
//       }
//     }
//   } catch (err) {
//     console.log("error", err);
//   }
// };

// -----------------------------------------------------------------------
// getCustomerDetails()
// -----------------------------------------------------------------------
export const getCustomerDetails = (reqBody, type) => async (dispatch) => {
  try {
    const res = await Api.getCustomerDetailsData(reqBody);
    if (res.body) {
      const body = res.body;

      if (body.requestStatus === 1) {
        await dispatch({ type: "SET_CUSTOMER_DETAILS", payload: body.data });
        if (type == true) {
          await dispatch({ type: "SET_CART_DATA", payload: body.data });
        }
      }
    }
  } catch (res) {
    await handleServerResponse(dispatch, res, "SET_CUSTOMER_DETAILS");
  }
};

// -----------------------------------------------------------------------
// redeemCredits()
// -----------------------------------------------------------------------
export const redeemCredits = (reqBody) => async (dispatch) => {
  try {
    const res = await Api.redeemCreditsData(reqBody);
    if (res.body) {
      const body = res.body;
      if (body.requestStatus === 1) {
        await dispatch({ type: "SET_CART_DATA", payload: body });
        await dispatch({
          type: "SET_CART_TOAST",
          payload: { message: body.message, requestStatus: 1 },
        });
      } else if (body.requestStatus === 0) {
        await dispatch({
          type: "SET_CART_TOAST",
          payload: { message: body.message, requestStatus: 0 },
        });
      } else if (body.requestStatus === -1) {
        await dispatch({
          type: "SET_CART_TOAST",
          payload: { message: body.message, requestStatus: -1 },
        });
      }
    }
  } catch (res) {
    await handleServerResponse(dispatch, res, "SET_CART_TOAST");
  }
};

// -----------------------------------------------------------------------
// resetCredits()
// -----------------------------------------------------------------------
export const resetCredits = () => async (dispatch) => {
  try {
    const res = await Api.resetCreditsData();
    if (res.body) {
      const body = res.body;
      if (body.requestStatus === 1) {
        await dispatch({ type: "SET_CART_DATA", payload: body });
        await dispatch({
          type: "SET_CART_TOAST",
          payload: { message: body.message, requestStatus: 1 },
        });
      } else if (body.requestStatus === 0) {
        await dispatch({
          type: "SET_CART_TOAST",
          payload: { message: body.message, requestStatus: 0 },
        });
      } else if (body.requestStatus === -1) {
        await dispatch({
          type: "SET_CART_TOAST",
          payload: { message: body.message, requestStatus: -1 },
        });
      }
    }
  } catch (res) {
    await handleServerResponse(dispatch, res, "SET_CART_TOAST");
  }
};

// -----------------------------------------------------------------------
// applyDiscount()
// -----------------------------------------------------------------------
export const applyDiscount = (reqBody) => async (dispatch) => {
  try {
    const res = await Api.applyDiscountData(reqBody);
    if (res.body) {
      const body = res.body;
      if (body.requestStatus === 1) {
        await dispatch({ type: "SET_CART_DATA", payload: body });
        await dispatch({
          type: "SET_CART_TOAST",
          payload: { message: body.message, requestStatus: 1 },
        });
      } else if (body.requestStatus === 0) {
        await dispatch({
          type: "SET_CART_TOAST",
          payload: { message: body.message, requestStatus: 0 },
        });
      } else if (body.requestStatus === -1) {
        await dispatch({
          type: "SET_CART_TOAST",
          payload: { message: body.message, requestStatus: -1 },
        });
      }
    }
  } catch (res) {
    await handleServerResponse(dispatch, res, "SET_CART_TOAST");
  }
};

// -----------------------------------------------------------------------
// resetDiscount()
// -----------------------------------------------------------------------
export const resetDiscount = () => async (dispatch) => {
  try {
    const res = await Api.resetDiscountData();
    if (res.body) {
      const body = res.body;
      if (body.requestStatus === 1) {
        await dispatch({ type: "SET_CART_DATA", payload: body });
        await dispatch({
          type: "SET_CART_TOAST",
          payload: { message: body.message, requestStatus: 1 },
        });
      } else if (body.requestStatus === 0) {
        await dispatch({
          type: "SET_CART_TOAST",
          payload: { message: body.message, requestStatus: 0 },
        });
      } else if (body.requestStatus === -1) {
        await dispatch({
          type: "SET_CART_TOAST",
          payload: { message: body.message, requestStatus: -1 },
        });
      }
    }
  } catch (res) {
    await handleServerResponse(dispatch, res, "SET_CART_TOAST");
  }
};

// -----------------------------------------------------------------------
// getCourtList()
// -----------------------------------------------------------------------
export const getCourtList = (activityId) => async (dispatch) => {
  try {
    toggleLoaderOn(dispatch);
    const res = await Api.getCourtListData(activityId);
    if (res.body) {
      toggleLoaderOff(dispatch);
      const body = res.body;
      if (body.requestStatus === 1) {
        await dispatch({ type: "SET_COURT_LIST", payload: body.courtList });
        await dispatch({
          type: "SET_COURT_LIST_TOAST",
          payload: { message: body.message, requestStatus: 1 },
        });
      } else if (body.requestStatus === 0) {
        await dispatch({
          type: "SET_COURT_LIST_TOAST",
          payload: { message: body.message, requestStatus: 0 },
        });
      } else if (body.requestStatus === -1) {
        await dispatch({
          type: "SET_COURT_LIST_TOAST",
          payload: { message: body.message, requestStatus: -1 },
        });
      }
    }
  } catch (res) {
    await handleServerResponse(dispatch, res, "SET_COURT_LIST_TOAST");
  }
};

// -----------------------------------------------------------------------
// verifySlotsAvailability()
// -----------------------------------------------------------------------
export const verifySlotsAvailability = (reqBody) => async (dispatch) => {
  try {
    toggleLoaderOn(dispatch);
    const res = await Api.verifySlotsAvailabilityData(reqBody);
    if (res.body) {
      toggleLoaderOff(dispatch);
      const body = res.body;
      if (body.requestStatus === 1) {
        await dispatch({
          type: "SET_VERIFY_SLOTS_AVAILABILITY_DATA",
          payload: body.data,
        });
        await dispatch({
          type: "SET_VERIFY_SLOTS_AVAILABILITY_TOAST",
          payload: { message: body.message, requestStatus: 1 },
        });
      } else if (body.requestStatus === 0) {
        await dispatch({
          type: "SET_VERIFY_SLOTS_AVAILABILITY_TOAST",
          payload: { message: body.message, requestStatus: 0 },
        });
      } else if (body.requestStatus === -1) {
        await dispatch({
          type: "SET_VERIFY_SLOTS_AVAILABILITY_TOAST",
          payload: { message: body.message, requestStatus: -1 },
        });
      }
    }
  } catch (res) {
    await handleServerResponse(
      dispatch,
      res,
      "SET_VERIFY_SLOTS_AVAILABILITY_TOAST"
    );
  }
};

// -----------------------------------------------------------------------
// patternSlotsBooking()
// -----------------------------------------------------------------------
export const patternSlotsBooking = (reqBody) => async (dispatch) => {
  try {
    toggleLoaderOn(dispatch);
    const res = await Api.patternSlotsBookingData(reqBody);
    await handleServerResponse(
      dispatch,
      res,
      "SET_VERIFY_SLOTS_AVAILABILITY_TOAST"
    );
  } catch (res) {
    await handleServerResponse(
      dispatch,
      res,
      "SET_VERIFY_SLOTS_AVAILABILITY_TOAST"
    );
  }
};

// -----------------------------------------------------------------------
// blocking()
// -----------------------------------------------------------------------
export const blocking = (reqBody) => async (dispatch) => {
  try {
    toggleLoaderOn(dispatch);
    const res = await Api.blockingData(reqBody);
    await handleServerResponse(dispatch, res, "SET_BLOCKING_TOAST");
  } catch (res) {
    await handleServerResponse(dispatch, res, "SET_BLOCKING_TOAST");
  }
};

// -----------------------------------------------------------------------
// booking()
// -----------------------------------------------------------------------
export const booking = (reqBody) => async (dispatch) => {
  try {
    toggleLoaderOn(dispatch);
    const res = await Api.bookingData(reqBody);
    await handleServerResponse(dispatch, res, "SET_BOOKING_TOAST");
  } catch (res) {
    await handleServerResponse(dispatch, res, "SET_BOOKING_TOAST");
  }
};

// -----------------------------------------------------------------------
// patternBooking()
// -----------------------------------------------------------------------
export const patternBooking = (reqBody) => async (dispatch) => {
  try {
    toggleLoaderOn(dispatch);
    const res = await Api.patternBookingData(reqBody);
    await handleServerResponse(dispatch, res, "SET_PATTERN_BOOKING_TOAST");
  } catch (res) {
    await handleServerResponse(dispatch, res, "SET_PATTERN_BOOKING_TOAST");
  }
};

// -----------------------------------------------------------------------
// getBookingDetails()
// -----------------------------------------------------------------------
export const getBookingDetails =
  (bookingId, dateBooked) => async (dispatch) => {
    try {
      let res = await Api.getBookingDetailsData(bookingId);
      if (dateBooked) {
        res = await filterBookingDetailsByDate(res, dateBooked);
      }
      console.log({ res });
      await handleServerResponse(dispatch, res, "SET_BOOKING_DETAILS");
    } catch (res) {
      await handleServerResponse(dispatch, res, "SET_BOOKING_DETAILS");
    }
  };

// -----------------------------------------------------------------------
// getBookingDetails()
// -----------------------------------------------------------------------
export const getBookingDetailsOnHover = (bookingId) => async (dispatch) => {
  try {
    const res = await Api.getBookingDetailsData(bookingId);
    await handleServerResponse(dispatch, res, "SET_BOOKING_DETAILS_ON_HOVER");
  } catch (res) {
    await handleServerResponse(dispatch, res, "SET_BOOKING_DETAILS_ON_HOVER");
  }
};

// -----------------------------------------------------------------------
// getRegularBookingSearchResult()
// -----------------------------------------------------------------------
export const getRegularBookingSearchResult =
  (reqParams) => async (dispatch) => {
    try {
      toggleLoaderOn(dispatch);
      const res = await Api.getRegularBookingSearchResultData(reqParams);
      await handleServerResponse(
        dispatch,
        res,
        "SET_REGULAR_BOOKING_SEARCH_RESULT"
      );
    } catch (res) {
      await handleServerResponse(
        dispatch,
        res,
        "SET_REGULAR_BOOKING_SEARCH_RESULT"
      );
    }
  };

// -----------------------------------------------------------------------
// getBulkBookingSearchResult()
// -----------------------------------------------------------------------
export const getBulkBookingSearchResult = (reqParams) => async (dispatch) => {
  try {
    toggleLoaderOn(dispatch);
    const res = await Api.getBulkBookingSearchResultData(reqParams);
    await handleServerResponse(dispatch, res, "SET_BULK_BOOKING_SEARCH_RESULT");
  } catch (res) {
    await handleServerResponse(dispatch, res, "SET_BULK_BOOKING_SEARCH_RESULT");
  }
};

// -----------------------------------------------------------------------
// getIndividualBulkBookingSearchResult()
// -----------------------------------------------------------------------
export const getIndividualBulkBookingSearchResult =
  (reqParams) => async (dispatch) => {
    try {
      const res = await Api.getBulkBookingSearchResultData(reqParams);
      await handleServerResponse(
        dispatch,
        res,
        "SET_INDIVIDUAL_BULK_BOOKING_SEARCH_RESULT"
      );
    } catch (res) {
      await handleServerResponse(
        dispatch,
        res,
        "SET_INDIVIDUAL_BULK_BOOKING_SEARCH_RESULT"
      );
    }
  };

// -----------------------------------------------------------------------
// getBlockingSearchResult()
// -----------------------------------------------------------------------
export const getBlockingSearchResult = (reqParams) => async (dispatch) => {
  try {
    toggleLoaderOn(dispatch);
    const res = await Api.getBlockingSearchResultData(reqParams);
    await handleServerResponse(dispatch, res, "SET_BLOCKING_SEARCH_RESULT");
  } catch (res) {
    await handleServerResponse(dispatch, res, "SET_BLOCKING_SEARCH_RESULT");
  }
};

// -----------------------------------------------------------------------
// updateBookingRemarks()
// -----------------------------------------------------------------------
export const updateBookingRemarks = (reqBody) => async (dispatch) => {
  try {
    const res = await Api.updateBookingRemarksData(reqBody);
    await handleServerResponse(
      dispatch,
      res,
      "SET_UPDATE_BOOKING_REMARKS_TOAST"
    );
  } catch (res) {
    await handleServerResponse(
      dispatch,
      res,
      "SET_UPDATE_BOOKING_REMARKS_TOAST"
    );
  }
};

// -----------------------------------------------------------------------
// updateBookingPayment()
// -----------------------------------------------------------------------
export const updateBookingPayment = (reqBody) => async (dispatch) => {
  try {
    const res = await Api.updateBookingPaymentData(reqBody);
    await handleServerResponse(
      dispatch,
      res,
      "SET_UPDATE_BOOKING_PAYMENT_TOAST"
    );
  } catch (res) {
    await handleServerResponse(
      dispatch,
      res,
      "SET_UPDATE_BOOKING_PAYMENT_TOAST"
    );
  }
};

// -----------------------------------------------------------------------
// updateBookingPayment()
// -----------------------------------------------------------------------
export const updateExtraPayment = (reqBody) => async (dispatch) => {
  try {
    const res = await Api.updateExtraPaymentData(reqBody);
    await handleServerResponse(dispatch, res, "SET_UPDATE_EXTRA_PAYMENT_TOAST");
  } catch (res) {
    await handleServerResponse(dispatch, res, "SET_UPDATE_EXTRA_PAYMENT_TOAST");
  }
};

// -----------------------------------------------------------------------
// getTransactionsDetails()
// -----------------------------------------------------------------------
export const getTransactionsDetails = (bookingId) => async (dispatch) => {
  try {
    const res = await Api.getTransactionsDetailsData(bookingId);
    await handleServerResponse(dispatch, res, "SET_TRANSACTIONS_DETAILS");
  } catch (res) {
    await handleServerResponse(dispatch, res, "SET_TRANSACTIONS_DETAILS");
  }
};

// -----------------------------------------------------------------------
// cancelBooking()
// -----------------------------------------------------------------------
export const cancelBooking = (reqBody) => async (dispatch) => {
  try {
    const res = await Api.cancelBookingData(reqBody);
    await handleServerResponse(dispatch, res, "SET_CANCEL_BOOKING_TOAST");
  } catch (res) {
    await handleServerResponse(dispatch, res, "SET_CANCEL_BOOKING_TOAST");
  }
};

// -----------------------------------------------------------------------
// getMemberDetails()
// -----------------------------------------------------------------------
export const getMemberDetails = (reqParams) => async (dispatch) => {
  try {
    toggleLoaderOn(dispatch);
    const res = await Api.getMemberDetailsData(reqParams);
    await handleServerResponse(dispatch, res, "SET_MEMBER_DETAILS");
  } catch (res) {
    await handleServerResponse(dispatch, res, "SET_MEMBER_DETAILS");
  }
};

// -----------------------------------------------------------------------
// getIndividualMemberDetails()
// -----------------------------------------------------------------------
export const getIndividualMemberDetails = (reqParams) => async (dispatch) => {
  try {
    const res = await Api.getIndividualMemberDetailsData(reqParams);
    if (res.body) {
      const body = res.body;
      if (body.requestStatus === 1) {
        await dispatch({
          type: "SET_INDIVIDUAL_MEMBER_DETAILS",
          payload: body.members[0],
        });
        await dispatch({
          type: "SET_INDIVIDUAL_MEMBER_DETAILS_TOAST",
          payload: { message: body.message, requestStatus: 1 },
        });
      }
      if (body.requestStatus === 0) {
        await dispatch({
          type: "SET_INDIVIDUAL_MEMBER_DETAILS_TOAST",
          payload: { message: body.message, requestStatus: 0 },
        });
      } else if (body.requestStatus === -1) {
        await dispatch({
          type: "SET_INDIVIDUAL_MEMBER_DETAILS_TOAST",
          payload: { message: body.message, requestStatus: -1 },
        });
      }
    }
  } catch (res) {
    await handleServerResponse(
      dispatch,
      res,
      "SET_INDIVIDUAL_MEMBER_DETAILS_TOAST"
    );
  }
};

// -----------------------------------------------------------------------
// registerMember()
// -----------------------------------------------------------------------
export const registerMember = (reqBody) => async (dispatch) => {
  try {
    const res = await Api.registerMemberData(reqBody);
    await handleServerResponse(dispatch, res, "SET_REGISTER_MEMBER_TOAST");
  } catch (res) {
    await handleServerResponse(dispatch, res, "SET_REGISTER_MEMBER_TOAST");
  }
};

// -----------------------------------------------------------------------
// updateMemberDetails()
// -----------------------------------------------------------------------
export const updateMemberDetails = (reqBody) => async (dispatch) => {
  try {
    const res = await Api.updateMemberDetailsData(reqBody);
    await handleServerResponse(
      dispatch,
      res,
      "SET_UPDATE_MEMBER_DETAILS_TOAST"
    );
  } catch (res) {
    await handleServerResponse(
      dispatch,
      res,
      "SET_UPDATE_MEMBER_DETAILS_TOAST"
    );
  }
};

// -----------------------------------------------------------------------
// createMemberPin()
// -----------------------------------------------------------------------
export const createMemberPin = (reqBody) => async (dispatch) => {
  try {
    const res = await Api.createMemberPinData(reqBody);
    await handleServerResponse(dispatch, res, "SET_CREATE_MEMBER_PIN_TOAST");
  } catch (res) {
    await handleServerResponse(dispatch, res, "SET_CREATE_MEMBER_PIN_TOAST");
  }
};

// -----------------------------------------------------------------------
// updateMemberPin()
// -----------------------------------------------------------------------
export const updateMemberPin = (reqBody) => async (dispatch) => {
  try {
    const res = await Api.updateMemberPinData(reqBody);
    await handleServerResponse(dispatch, res, "SET_UPDATE_MEMBER_PIN_TOAST");
  } catch (res) {
    await handleServerResponse(dispatch, res, "SET_UPDATE_MEMBER_PIN_TOAST");
  }
};

// -----------------------------------------------------------------------
// rechargeMemberCredits()
// -----------------------------------------------------------------------
export const rechargeMemberCredits = (reqBody) => async (dispatch) => {
  try {
    const res = await Api.rechargeMemberCreditsData(reqBody);
    await handleServerResponse(
      dispatch,
      res,
      "SET_RECHARGE_MEMBER_CREDITS_TOAST"
    );
  } catch (res) {
    await handleServerResponse(
      dispatch,
      res,
      "SET_RECHARGE_MEMBER_CREDITS_TOAST"
    );
  }
};

// -----------------------------------------------------------------------
// updateMemberCreditsValidity()
// -----------------------------------------------------------------------
export const updateMemberCreditsValidity = (reqBody) => async (dispatch) => {
  try {
    const res = await Api.updateMemberCreditsValidityData(reqBody);
    await handleServerResponse(
      dispatch,
      res,
      "SET_UPDATE_MEMBER_CREDITS_VALIDITY_TOAST"
    );
  } catch (res) {
    await handleServerResponse(
      dispatch,
      res,
      "SET_UPDATE_MEMBER_CREDITS_VALIDITY_TOAST"
    );
  }
};

// -----------------------------------------------------------------------
// verifyBookingRescheduleAvailability()
// -----------------------------------------------------------------------
export const verifyBookingRescheduleAvailability =
  (reqBody) => async (dispatch) => {
    try {
      const res = await Api.verifyBookingRescheduleAvailabilityData(reqBody);
      await handleServerResponse(
        dispatch,
        res,
        "SET_VERIFY_BOOKING_RESCHEDULE_AVAILABILITY_DATA"
      );
    } catch (res) {
      await handleServerResponse(
        dispatch,
        res,
        "SET_VERIFY_BOOKING_RESCHEDULE_AVAILABILITY_DATA"
      );
    }
  };

// -----------------------------------------------------------------------
// getBookingReschedulePricing()
// -----------------------------------------------------------------------
export const getBookingReschedulePricing = (reqBody) => async (dispatch) => {
  try {
    toggleLoaderOn(dispatch);
    const res = await Api.getBookingReschedulePricingData(reqBody);
    await handleServerResponse(
      dispatch,
      res,
      "SET_BOOKING_RESCHEDULE_PRICING_DATA"
    );
  } catch (res) {
    await handleServerResponse(
      dispatch,
      res,
      "SET_BOOKING_RESCHEDULE_PRICING_DATA"
    );
  }
};

// -----------------------------------------------------------------------
// confirmBookingReschedule()
// -----------------------------------------------------------------------
export const confirmBookingReschedule = (reqBody) => async (dispatch) => {
  try {
    toggleLoaderOn(dispatch);
    const res = await Api.confirmBookingRescheduleData(reqBody);
    await handleServerResponse(
      dispatch,
      res,
      "SET_CONFIRM_BOOKING_RESCHEDULE_DATA"
    );
  } catch (res) {
    await handleServerResponse(
      dispatch,
      res,
      "SET_CONFIRM_BOOKING_RESCHEDULE_DATA"
    );
  }
};

// -----------------------------------------------------------------------
// getClubExtrasList()
// -----------------------------------------------------------------------
export const getClubExtrasList = (activityId) => async (dispatch) => {
  try {
    const res = await Api.getClubExtrasListData(activityId);
    await handleServerResponse(dispatch, res, "SET_CLUB_EXTRAS_LIST");
  } catch (res) {
    await handleServerResponse(dispatch, res, "SET_CLUB_EXTRAS_LIST");
  }
};

// -----------------------------------------------------------------------
// addExtra()
// -----------------------------------------------------------------------
export const addExtra = (reqBody, type) => async (dispatch) => {
  try {
    const res =
      type === 0
        ? await Api.addExtraData(reqBody)
        : await Api.addCreditWithExtra(reqBody);
    if (res.body) {
      const body = res.body;
      if (body.requestStatus === 1) {
        await dispatch({ type: "SET_CART_DATA", payload: body });
        await dispatch({
          type: "SET_CART_TOAST",
          payload: { message: body.message, requestStatus: 1 },
        });
      } else if (body.requestStatus === 0) {
        await dispatch({
          type: "SET_CART_TOAST",
          payload: { message: body.message, requestStatus: 0 },
        });
      } else if (body.requestStatus === -1) {
        await dispatch({
          type: "SET_CART_TOAST",
          payload: { message: body.message, requestStatus: -1 },
        });
      }
    }
  } catch (res) {
    await handleServerResponse(dispatch, res, "SET_CART_TOAST");
  }
};

// -----------------------------------------------------------------------
// removeExtra()
// -----------------------------------------------------------------------
export const removeExtra = (reqParams) => async (dispatch) => {
  try {
    const res = await Api.removeExtraData(reqParams);
    if (res.body) {
      const body = res.body;
      if (body.requestStatus === 1) {
        await dispatch({ type: "SET_CART_DATA", payload: body });
        await dispatch({
          type: "SET_CART_TOAST",
          payload: { message: body.message, requestStatus: 1 },
        });
      } else if (body.requestStatus === 0) {
        await dispatch({
          type: "SET_CART_TOAST",
          payload: { message: body.message, requestStatus: 0 },
        });
      } else if (body.requestStatus === -1) {
        await dispatch({
          type: "SET_CART_TOAST",
          payload: { message: body.message, requestStatus: -1 },
        });
      }
    }
  } catch (res) {
    await handleServerResponse(dispatch, res, "SET_CART_TOAST");
  }
};

// -----------------------------------------------------------------------
// getMembershipPackageDetails()
// -----------------------------------------------------------------------
export const getMembershipPackageDetails =
  (reqParams, disableLoader = false) =>
  async (dispatch) => {
    try {
      if (!disableLoader) {
        toggleLoaderOn(dispatch);
      }
      const res = await Api.getMembershipPackageDetailsData(reqParams);
      dispatch(getSummary());
      await handleServerResponse(
        dispatch,
        res,
        "SET_MEMBERSHIP_PACKAGE_DETAILS"
      );
    } catch (res) {
      await handleServerResponse(
        dispatch,
        res,
        "SET_MEMBERSHIP_PACKAGE_DETAILS"
      );
    }
  };

// -----------------------------------------------------------------------
// createMembershipPackage()
// -----------------------------------------------------------------------
export const createMembershipPackage = (reqBody) => async (dispatch) => {
  try {
    const res = await Api.createMembershipPackageData(reqBody);

    await handleServerResponse(
      dispatch,
      res,
      "SET_CREATE_MEMBERSHIP_PACKAGE_TOAST"
    );
  } catch (res) {
    await handleServerResponse(
      dispatch,
      res,
      "SET_CREATE_MEMBERSHIP_PACKAGE_TOAST"
    );
  }
};

// -----------------------------------------------------------------------
// saveSelectedMembershipPackageDetails()
// -----------------------------------------------------------------------
export const saveSelectedMembershipPackageDetails =
  (
    packageDetails,
    memberDetails,
    subscriptionType,
    pageNumOnMembersPage = 1,
    updateIndividualMemberDetails = false
  ) =>
  async (dispatch) => {
    try {
      await dispatch({
        type: "SET_SELECTED_MEMBERSHIP_PACKAGE_DETAILS",
        payload: {
          packageDetails: packageDetails,
          memberDetails: memberDetails,
          subscriptionType: subscriptionType,
          pageNumOnMembersPage: pageNumOnMembersPage,
          updateIndividualMemberDetails: updateIndividualMemberDetails,
          requestStatus: 1,
          message: "Successfully saved selected membership package details",
        },
      });
    } catch (err) {
      console.log("error", err);
    }
  };

// -----------------------------------------------------------------------
// clearSelectedMembershipPackageDetails()
// -----------------------------------------------------------------------
export const clearSelectedMembershipPackageDetails = () => async (dispatch) => {
  try {
    await dispatch({
      type: "SET_SELECTED_MEMBERSHIP_PACKAGE_DETAILS",
      payload: {
        membershipPackage: {},
        requestStatus: 0,
        message: "Successfully cleared selected membership package details",
      },
    });
  } catch (err) {
    console.log("error", err);
  }
};

// -----------------------------------------------------------------------
// subscribeMembershipPackage()
// -----------------------------------------------------------------------
export const subscribeMembershipPackage = (reqBody) => async (dispatch) => {
  try {
    const res = await Api.subscribeMembershipPackageData(reqBody);
    await handleServerResponse(
      dispatch,
      res,
      "SET_SUBSCRIBE_MEMBERSHIP_PACKAGE_TOAST"
    );
  } catch (res) {
    await handleServerResponse(
      dispatch,
      res,
      "SET_SUBSCRIBE_MEMBERSHIP_PACKAGE_TOAST"
    );
  }
};
// -----------------------------------------------------------------------
// getMembershipExpiryTableData()
// -----------------------------------------------------------------------

export const getMembershipExpiryTableData = async (data) => {
  try {
    const res = await Api.getMembershipSubscriptionDetailsData(data);
    const body = res?.body;
    return body;
  } catch (res) {
    const body = res.body;
    toaster.danger(body?.message);
  }
};

// -----------------------------------------------------------------------
// getMembershipSubscriptionDetails()
// -----------------------------------------------------------------------
export const getMembershipSubscriptionDetails =
  (reqParams) => async (dispatch) => {
    try {
      toggleLoaderOn(dispatch);
      const res = await Api.getMembershipSubscriptionDetailsData(reqParams);

      await handleServerResponse(
        dispatch,
        res,
        "SET_MEMBERSHIP_SUBSCRIPTION_DETAILS_DATA"
      );
    } catch (res) {
      await handleServerResponse(
        dispatch,
        res,
        "SET_MEMBERSHIP_SUBSCRIPTION_DETAILS_DATA"
      );
    }
  };

// -----------------------------------------------------------------------
// generateCoupon()
// -----------------------------------------------------------------------
export const generateCoupon = (reqBody) => async (dispatch) => {
  try {
    toggleLoaderOn(dispatch);
    const res = await Api.generateCouponData(reqBody);
    await handleServerResponse(dispatch, res, "SET_GENERATE_COUPON_TOAST");
  } catch (res) {
    await handleServerResponse(dispatch, res, "SET_GENERATE_COUPON_TOAST");
  }
};

// -----------------------------------------------------------------------
// editCoupon()
// -----------------------------------------------------------------------
export const editCoupon = (reqBody) => async (dispatch) => {
  try {
    toggleLoaderOn(dispatch);
    const res = await Api.editCouponData(reqBody);
    await handleServerResponse(dispatch, res, "SET_EDIT_COUPON_TOAST");
  } catch (res) {
    await handleServerResponse(dispatch, res, "SET_EDIT_COUPON_TOAST");
  }
};

// -----------------------------------------------------------------------
// membershipSubscriptionPaymentUpdate()
// -----------------------------------------------------------------------
export const membershipSubscriptionPaymentUpdate =
  (reqBody) => async (dispatch) => {
    try {
      toggleLoaderOn(dispatch);
      const res = await Api.membershipSubscriptionPaymentUpdateData(reqBody);
      await handleServerResponse(
        dispatch,
        res,
        "SET_MEMBERSHIP_SUBSCRIPTION_PAYMENT_UPDATE_TOAST"
      );
    } catch (res) {
      await handleServerResponse(
        dispatch,
        res,
        "SET_MEMBERSHIP_SUBSCRIPTION_PAYMENT_UPDATE_TOAST"
      );
    }
  };

// -----------------------------------------------------------------------
// getMembersSubscribedPackages()
// -----------------------------------------------------------------------
export const getMembersSubscribedPackages = (reqBody) => async (dispatch) => {
  try {
    toggleLoaderOn(dispatch);
    const res = await Api.getMembersSubscribedPackagesData(reqBody);
    await handleServerResponse(
      dispatch,
      res,
      "SET_MEMBERS_SUBSCRIBED_PACKAGES_DATA"
    );
  } catch (res) {
    await handleServerResponse(
      dispatch,
      res,
      "SET_MEMBERS_SUBSCRIBED_PACKAGES_DATA"
    );
  }
};

// -----------------------------------------------------------------------
// updateMembershipPackageDetails()
// -----------------------------------------------------------------------
export const updateMembershipPackageDetails = (reqBody) => async (dispatch) => {
  try {
    toggleLoaderOn(dispatch);
    const res = await Api.updateMembershipPackageDetailsData(reqBody);
    await handleServerResponse(
      dispatch,
      res,
      "SET_UPDATE_MEMBERSHIP_PACKAGE_DETAILS_TOAST"
    );
  } catch (res) {
    await handleServerResponse(
      dispatch,
      res,
      "SET_UPDATE_MEMBERSHIP_PACKAGE_DETAILS_TOAST"
    );
  }
};

// -----------------------------------------------------------------------
// saveMembershipPackageDetailsForEditing()
// -----------------------------------------------------------------------
export const saveMembershipPackageDetailsForEditing =
  (packageDetails) => async (dispatch) => {
    try {
      await dispatch({
        type: "SET_MEMBERSHIP_PACKAGE_DETAILS_FOR_EDITING",
        payload: {
          packageDetails: packageDetails,
          requestStatus: 1,
          message: "Successfully saved membership package details",
        },
      });
    } catch (err) {
      console.log("error", err);
    }
  };

// -----------------------------------------------------------------------
// getPriceClass()
// -----------------------------------------------------------------------
export const getPriceClass = () => async (dispatch) => {
  try {
    toggleLoaderOn(dispatch);
    const res = await Api.getPriceClassData();
    await handleServerResponse(dispatch, res, "SET_PRICE_CLASS_DETAILS");
  } catch (res) {
    await handleServerResponse(dispatch, res, "SET_PRICE_CLASS_DETAILS");
  }
};
// -----------------------------------------------------------------------
// getStaffMember()
// -----------------------------------------------------------------------
export const getStaffMember = () => async (dispatch) => {
  try {
    toggleLoaderOn(dispatch);
    const res = await Api.getClubHandlersData();

    await handleServerResponse(dispatch, res, "SET_STAFF_MEMBER");
  } catch (res) {
    await handleServerResponse(dispatch, res, "SET_STAFF_MEMBER");
  }
};

// -----------------------------------------------------------------------
// getPricingConfigDetails()
// -----------------------------------------------------------------------
export const getPricingConfigDetails = (reqParams) => async (dispatch) => {
  try {
    toggleLoaderOn(dispatch);
    const res = await Api.getPricingConfigDetailsData(reqParams);
    await handleServerResponse(dispatch, res, "SET_PRICING_CONFIG_DETAILS");
  } catch (res) {
    await handleServerResponse(dispatch, res, "SET_PRICING_CONFIG_DETAILS");
  }
};

// -----------------------------------------------------------------------
// savePricingData()
// -----------------------------------------------------------------------
export const savePricingData = (
  priceType,
  priceClass,
  activityId,
  priceSlots,
  openHour,
  closeHour,
  slotDuration,
  isPeriodicPricing = false,
  startDate = "",
  endDate = ""
) => {
  return async (dispatch) => {
    try {
      await dispatch({
        type: "SET_PRICING_DATA",
        payload: {
          isPeriodicPricing: isPeriodicPricing,
          startDate: startDate,
          endDate: endDate,
          priceSlots: priceSlots,
          openHour: openHour,
          closeHour: closeHour,
          slotDuration: slotDuration,
          priceType: priceType,
          priceClass: priceClass,
          activityId: activityId,
          requestStatus: 1,
          message: "Successfully saved pricing data",
        },
      });
    } catch (err) {
      console.log("error", err);
    }
  };
};

// -----------------------------------------------------------------------
// savePricingDetails()
// -----------------------------------------------------------------------
export const savePricingDetails = (reqBody) => async (dispatch) => {
  try {
    toggleLoaderOn(dispatch);
    const res = await Api.savePricingDetailsData(reqBody);
    await handleServerResponse(dispatch, res, "SET_SAVE_PRICING_DETAILS_TOAST");
  } catch (res) {
    await handleServerResponse(dispatch, res, "SET_SAVE_PRICING_DETAILS_TOAST");
  }
};

// -----------------------------------------------------------------------
// getClubHandlers()
// -----------------------------------------------------------------------
export const getClubHandlers = () => async (dispatch) => {
  try {
    toggleLoaderOn(dispatch);
    const res = await Api.getClubHandlersData();
    await handleServerResponse(dispatch, res, "SET_CLUB_HANDLERS_LIST");
  } catch (res) {
    await handleServerResponse(dispatch, res, "SET_CLUB_HANDLERS_LIST");
  }
};

// -----------------------------------------------------------------------
// getReports()
// -----------------------------------------------------------------------
export const getReports =
  (
    reqParams,
    type,
    fromDate = "",
    toDate = "",
    selectedClubHandlerName = "All",
    selectedClubHandlerValue = "all",
    paid = 0,
    booked = 0
  ) =>
  async (dispatch) => {
    try {
      toggleLoaderOn(dispatch);
      const res = await Api.getReportsData(reqParams, type);
      if (res.body) {
        toggleLoaderOff(dispatch);
        const body = res.body;

        if (body.requestStatus === 1 || body.requestStatus === -1) {
          await dispatch({
            type: "SET_REPORTS_DATA",
            payload: {
              type: type,
              fromDate: fromDate,
              toDate: toDate,
              selectedClubHandlerName: selectedClubHandlerName,
              selectedClubHandlerValue: selectedClubHandlerValue,
              paid: paid,
              booked: booked,
              ...body,
            },
          });
        }
      }
    } catch (res) {
      await handleServerResponse(dispatch, res, "SET_REPORTS_DATA");
    }
  };

// -----------------------------------------------------------------------
// downloadReport()
// -----------------------------------------------------------------------
export const downloadReport = (reqParams, type) => async (dispatch) => {
  const res = await Api.downloadReportData(reqParams, type);
  if (res.body) {
    toggleLoaderOff(dispatch);
    const body = res.body;

    if (body.requestStatus === 1) {
      console.log({ body });
      const workbook = XLSX.utils.book_new();

      if (res.body?.data?.length > 0) {
        res.body.data.forEach((club) => {
          const { clubName, data, summary } = club;

          if (Array.isArray(data) && data.length > 0) {
            const sheetData = data.map((transaction) =>
              Object.values(transaction)
            );

            const sheet = XLSX.utils.aoa_to_sheet(
              [Object.keys(data[0])].concat(sheetData)
            );
            let maxLength = 30;
            let truncatedName =
              clubName.length > maxLength
                ? clubName.slice(0, maxLength - 3) + "..."
                : clubName;
            XLSX.utils.book_append_sheet(workbook, sheet, truncatedName);
          }
        });

        const blobOptions = { bookType: "xlsx", bookSST: true, type: "blob" };
        XLSX.writeFile(workbook, `${type}-report.xlsx`, blobOptions);

        toaster.success(body?.message);
      } else {
        toaster.warning("There are no reports for the selected dates.");
      }
    } else {
      toaster.danger(body?.message);
    }
  }
};

// -----------------------------------------------------------------------
// login()
// -----------------------------------------------------------------------
export const login = (reqBody) => async (dispatch) => {
  try {
    const res = await Api.loginData(reqBody);
    await handleServerResponse(dispatch, res, "SET_LOGIN_DETAILS");
  } catch (res) {
    await handleServerResponse(dispatch, res, "SET_LOGIN_DETAILS");
  }
};

// -----------------------------------------------------------------------
// switchAccount()
// -----------------------------------------------------------------------
export const switchAccount = (reqBody) => async (dispatch) => {
  try {
    const res = await Api.switchAccount(reqBody);
    await handleServerResponse(dispatch, res, "SET_SWITCH_ACCOUNT_DETAILS");
  } catch (res) {
    await handleServerResponse(dispatch, res, "SET_SWITCH_ACCOUNT_DETAILS");
  }
};

// -----------------------------------------------------------------------
// toggleAuthStatus()
// -----------------------------------------------------------------------
export const toggleAuthStatus = () => async (dispatch) => {
  try {
    await dispatch({
      type: "TOGGLE_AUTH_STATUS",
    });
  } catch (err) {
    console.log("error", err);
  }
};

// -----------------------------------------------------------------------
// logout()
// -----------------------------------------------------------------------
export const logout = () => async (dispatch) => {
  try {
    toggleLoaderOn(dispatch);
    const res = await Api.logoutData();
    await handleServerResponse(dispatch, res, "SET_LOGOUT_DETAILS");
  } catch (res) {
    await handleServerResponse(dispatch, res, "SET_LOGOUT_DETAILS");
  }
};

// -----------------------------------------------------------------------
// getHolidays()
// -----------------------------------------------------------------------
export const getHolidays = () => async (dispatch) => {
  try {
    toggleLoaderOn(dispatch);
    const res = await Api.getHolidaysData();
    await handleServerResponse(dispatch, res, "SET_HOLIDAYS_DATA");
  } catch (res) {
    await handleServerResponse(dispatch, res, "SET_HOLIDAYS_DATA");
  }
};

// -----------------------------------------------------------------------
// configureHoliday()
// -----------------------------------------------------------------------
export const configureHoliday = (reqBody) => async (dispatch) => {
  try {
    toggleLoaderOn(dispatch);
    const res = await Api.configureHolidayData(reqBody);
    await handleServerResponse(dispatch, res, "SET_CONFIGURE_HOLIDAY_TOAST");
  } catch (res) {
    await handleServerResponse(dispatch, res, "SET_CONFIGURE_HOLIDAY_TOAST");
  }
};

// -----------------------------------------------------------------------
// removeHoliday()
// -----------------------------------------------------------------------
export const removeHoliday = (reqBody) => async (dispatch) => {
  try {
    toggleLoaderOn(dispatch);
    const res = await Api.removeHolidayData(reqBody);
    await handleServerResponse(dispatch, res, "SET_REMOVE_HOLIDAY_TOAST");
  } catch (res) {
    await handleServerResponse(dispatch, res, "SET_REMOVE_HOLIDAY_TOAST");
  }
};

// -----------------------------------------------------------------------
// togglePremiumFeatureFlag()
// -----------------------------------------------------------------------
export const togglePremiumFeatureFlag = (bool) => async (dispatch) => {
  try {
    await dispatch({
      type: "TOGGLE_PREMIUM_FEATURE_FLAG",
      payload: bool,
    });
  } catch (err) {
    console.log("error", err);
  }
};

// -----------------------------------------------------------------------
// generateReceipt()
// -----------------------------------------------------------------------
export const generateReceipt = (reqParams) => async (dispatch) => {
  try {
    toggleLoaderOn(dispatch);
    const res = await Api.generateReceiptData(reqParams);
    if (res) {
      if (res.status === 200) {
        toggleLoaderOff(dispatch);
        const url = window.URL.createObjectURL(res.body);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("target", "_blank");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        await dispatch({
          type: "SET_GENERATE_RECEIPT_TOAST",
          payload: {
            message: "File downloaded",
            requestStatus: 1,
          },
        });
      }
    }
  } catch (res) {
    await handleServerResponse(dispatch, res, "SET_GENERATE_RECEIPT_TOAST");
  }
};

// -----------------------------------------------------------------------
// createUser()
// -----------------------------------------------------------------------
export const createUser = (reqBody) => async (dispatch) => {
  try {
    toggleLoaderOn(dispatch);
    const res = await Api.createUserData(reqBody);
    await handleServerResponse(dispatch, res, "SET_CREATE_USER_TOAST");
  } catch (res) {
    await handleServerResponse(dispatch, res, "SET_CREATE_USER_TOAST");
  }
};

// -----------------------------------------------------------------------
// changeUserPassword()
// -----------------------------------------------------------------------
export const changeUserPassword = (reqBody) => async (dispatch) => {
  try {
    toggleLoaderOn(dispatch);
    const res = await Api.changeUserPasswordData(reqBody);
    await handleServerResponse(dispatch, res, "SET_CHANGE_USER_PASSWORD_TOAST");
  } catch (res) {
    await handleServerResponse(dispatch, res, "SET_CHANGE_USER_PASSWORD_TOAST");
  }
};

// -----------------------------------------------------------------------
// generateBookingStatement()
// -----------------------------------------------------------------------
export const generateBookingStatement = (reqParams) => async (dispatch) => {
  try {
    toggleLoaderOn(dispatch);
    const res = await Api.generateBookingStatementData(reqParams);
    if (res) {
      if (res.status === 200) {
        toggleLoaderOff(dispatch);
        const url = window.URL.createObjectURL(res.body);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("target", "_blank");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        await dispatch({
          type: "SET_GENERATE_BOOKING_STATEMENT_TOAST",
          payload: {
            message: "File downloaded",
            requestStatus: 1,
          },
        });
      }
    }
  } catch (res) {
    await handleServerResponse(
      dispatch,
      res,
      "SET_GENERATE_BOOKING_STATEMENT_TOAST"
    );
  }
};

// -----------------------------------------------------------------------
// getEscalationMatrix()
// -----------------------------------------------------------------------
export const getEscalationMatrix = () => async (dispatch) => {
  try {
    toggleLoaderOn(dispatch);
    const res = await Api.getEscalationMatrixData();
    await handleServerResponse(dispatch, res, "SET_ESCALATION_MATRIX_DATA");
  } catch (res) {
    await handleServerResponse(dispatch, res, "SET_ESCALATION_MATRIX_DATA");
  }
};

// -----------------------------------------------------------------------
// getCouponDetails()
// -----------------------------------------------------------------------
export const getCouponDetails = (couponData) => async (dispatch) => {
  try {
    toggleLoaderOn(dispatch);
    const res = await Api.getCouponDetails(couponData);
    await handleServerResponse(dispatch, res, "SET_COUPON_DETAILS");
  } catch (res) {
    await handleServerResponse(dispatch, res, "SET_COUPON_DETAILS");
  }
};

// -----------------------------------------------------------------------
// getCouponDetails()
// -----------------------------------------------------------------------
export const getCouponCodes = (couponData) => async (dispatch) => {
  try {
    toggleLoaderOn(dispatch);
    const res = await Api.getCouponCodes(couponData);
    await handleServerResponse(dispatch, res, "SET_COUPON_CODES");
  } catch (res) {
    await handleServerResponse(dispatch, res, "SET_COUPON_CODES");
  }
};

// -----------------------------------------------------------------------
// getUserPermissions()
// -----------------------------------------------------------------------
export const getUserPermission = (userId) => async (dispatch) => {
  try {
    // toggleLoaderOn(dispatch);
    const res = await Api.getUserPermissions(userId);

    await handleServerResponse(dispatch, res, "SET_USER_PERMISSION");
  } catch (res) {
    await handleServerResponse(dispatch, res, "SET_USER_PERMISSION");
  }
};

// -----------------------------------------------------------------------
// updateUserPermissions()
// -----------------------------------------------------------------------
export const updateUserPermission = (permissions) => async (dispatch) => {
  try {
    // toggleLoaderOn(dispatch);
    const res = await Api.updateUserPermissions(permissions);

    await handleServerResponse(dispatch, res, "SAVE_USER_PERMISSION");
  } catch (res) {
    await handleServerResponse(dispatch, res, "SAVE_USER_PERMISSION");
  }
};

// -----------------------------------------------------------------------
// updateUserPermissions()
// -----------------------------------------------------------------------
export const removeUser = (data) => async (dispatch) => {
  try {
    // toggleLoaderOn(dispatch);
    const res = await Api.removeUser(data);

    await handleServerResponse(dispatch, res, "USER_REMOVE");
  } catch (res) {
    await handleServerResponse(dispatch, res, "USER_REMOVE");
  }
};

// -----------------------------------------------------------------------
// updateUserPermissions()
// -----------------------------------------------------------------------
export const getFeatureList = () => async (dispatch) => {
  try {
    // toggleLoaderOn(dispatch);
    const res = await Api.getFeatureList();

    await handleServerResponse(dispatch, res, "SET_FEATURE_LIST");
  } catch (res) {
    await handleServerResponse(dispatch, res, "SET_FEATURE_LIST");
  }
};

// -----------------------------------------------------------------------
// getActivityTypeOneDetails()
// -----------------------------------------------------------------------
export const getActivityTypeOneDetails = (data, type) => async (dispatch) => {
  try {
    if (!type) {
      toggleLoaderOn(dispatch);
    }
    const res = await Api.getActivityTypeOneDetails(data);

    await handleServerResponse(dispatch, res, "SET_TYPE_ONE_DETAILS");
  } catch (res) {
    await handleServerResponse(dispatch, res, "SET_TYPE_ONE_DETAILS");
  }
};

// -----------------------------------------------------------------------
// addExtra()
// -----------------------------------------------------------------------
export const updateExtra = (reqBody, type) => async (dispatch) => {
  try {
    const res =
      type == true
        ? await Api.updateBulkExtraData(reqBody)
        : await Api.updateExtraData(reqBody);
    if (res.body) {
      const body = res.body;
      if (body.requestStatus === 1) {
        //await dispatch({ type: "SET_CART_DATA", payload: body });
        await dispatch({
          type: "SET_CART_TOAST",
          payload: { message: body.message, requestStatus: 1 },
        });
      } else if (body.requestStatus === 0) {
        await dispatch({
          type: "SET_CART_TOAST",
          payload: { message: body.message, requestStatus: 0 },
        });
      } else if (body.requestStatus === -1) {
        await dispatch({
          type: "SET_CART_TOAST",
          payload: { message: body.message, requestStatus: -1 },
        });
      }
    }
  } catch (res) {
    await handleServerResponse(dispatch, res, "SET_CART_TOAST");
  }
};

// -----------------------------------------------------------------------
// getActivityTypeOneDetails()
// -----------------------------------------------------------------------
export const getConfigExtraList = () => async (dispatch) => {
  try {
    const res = await Api.getConfigExtraList();

    await handleServerResponse(dispatch, res, "SET_CONFIG_EXTRA_LIST");
  } catch (res) {
    await handleServerResponse(dispatch, res, "SET_CONFIG_EXTRA_LIST");
  }
};

// -----------------------------------------------------------------------
// updateConfigExtra()
// -----------------------------------------------------------------------
export const updateConfigExtra = (data) => async (dispatch) => {
  try {
    const res = await Api.updateConfigExtra(data);

    await handleServerResponse(dispatch, res, "SET_CONFIG_EXTRA_TOAST");
  } catch (res) {
    await handleServerResponse(dispatch, res, "SET_CONFIG_EXTRA_TOAST");
  }
};

// -----------------------------------------------------------------------
// updateConfigExtra()
// -----------------------------------------------------------------------
export const addConfigExtra = (data) => async (dispatch) => {
  try {
    const res = await Api.addConfigExtra(data);

    await handleServerResponse(dispatch, res, "SET_CONFIG_EXTRA_TOAST");
  } catch (res) {
    await handleServerResponse(dispatch, res, "SET_CONFIG_EXTRA_TOAST");
  }
};

// -----------------------------------------------------------------------
// downloadExtra()
// -----------------------------------------------------------------------
export const downloadExtra = () => async (dispatch) => {
  try {
    toggleLoaderOn(dispatch);
    const res = await Api.downloadExtra();

    if (res) {
      let jsonRes;
      try {
        jsonRes = JSON.parse(await res.body.text());
      } catch (e) {
        console.log(e);
      }
      if (jsonRes && jsonRes.requestStatus === 0) {
        toggleLoaderOff(dispatch);
        toaster.notify(jsonRes.message, {
          id: "extra-download",
        });
      } else if (jsonRes && jsonRes.requestStatus === -1) {
        toggleLoaderOff(dispatch);
        toaster.notify("You dont have permission", {
          id: "You dont have permissions",
        });
      } else {
        if (res.status === 200) {
          toggleLoaderOff(dispatch);
          const url = window.URL.createObjectURL(res.body);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `config-extra-list.xlsx`);

          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          await dispatch({
            type: "SET_DOWNLOAD_EXTRA_TOAST",
            payload: {
              message: "File downloaded",
              requestStatus: 1,
            },
          });
        }
      }
    }
  } catch (res) {
    await handleServerResponse(dispatch, res, "SET_DOWNLOAD_EXTRA_TOAST");
  }
};

// -----------------------------------------------------------------------
// Enable Reschedule()
// -----------------------------------------------------------------------
export const enableReschedule = (data) => async (dispatch) => {
  try {
    const res = await Api.enableReschedule(data);

    await handleServerResponse(dispatch, res, "SET_ENABLE_RESCHEDULE");
  } catch (res) {
    await handleServerResponse(dispatch, res, "SET_ENABLE_RESCHEDULE");
  }
};

// -----------------------------------------------------------------------
// Enable Reschedule()
// -----------------------------------------------------------------------
export const markNoShow = (data) => async (dispatch) => {
  try {
    const res = await Api.markNoShow(data);

    await handleServerResponse(dispatch, res, "SET_NOSHOW");
  } catch (res) {
    await handleServerResponse(dispatch, res, "SET_NOSHOW");
  }
};

// -----------------------------------------------------------------------
// syncAvailability()
// -----------------------------------------------------------------------
export const syncAvailability = async () => {
  try {
    const res = await Api.syncAvailability();
    let body = res.body;
    if (body.requestStatus === 1) {
      toaster.success(body?.message);
    } else {
      toaster.danger(body?.message);
    }
  } catch (res) {
    console.log({ res });
    const body = res.body;
    toaster.danger(body?.message);
  }
};

// -----------------------------------------------------------------------
// getDashBoardData()
// -----------------------------------------------------------------------
export const getDashboardData = async (data) => {
  console.log({ data });
  try {
    const res = await Api.getDashboardData(data);
    const body = res?.body;
    return body;
  } catch (res) {
    console.log({ res });
    const body = res.body;
    toaster.danger(body?.message);
  }
};

// -----------------------------------------------------------------------
// getEvents()
// -----------------------------------------------------------------------
export const getEvents = async () => {
  try {
    const res = await Api.getEvents();
    const body = res?.body;
    return body;
  } catch (res) {
    console.log({ res });
    const body = res.body;
    toaster.danger(body?.message);
  }
};

// -----------------------------------------------------------------------
// getEvents()
// -----------------------------------------------------------------------
export const getCategoriesList = async (eventId) => {
  try {
    const res = await Api.getCategoriesList(eventId);
    const body = res?.body;
    return body;
  } catch (res) {
    console.log({ res });
    const body = res.body;
    toaster.danger(body?.message);
  }
};

// -----------------------------------------------------------------------
// getEvents()
// -----------------------------------------------------------------------
export const getEventBookingList = async (eventId, categoryId, pageNo) => {
  try {
    const res = await Api.getEventBookingList(eventId, categoryId, pageNo);
    const body = res?.body;
    return body;
  } catch (res) {
    console.log({ res });
    const body = res.body;
    toaster.danger(body?.message);
  }
};

// -----------------------------------------------------------------------
// getEventCategorySummary()
// -----------------------------------------------------------------------
export const getEventCategorySummary = async (categoryId) => {
  try {
    const res = await Api.getEventCategorySummary(categoryId);
    const body = res?.body;
    return body;
  } catch (res) {
    console.log({ res });
    const body = res.body;
    toaster.danger(body?.message);
  }
};

// -----------------------------------------------------------------------
// updateCategoryStatus()
// -----------------------------------------------------------------------
export const updateCategoryStatus = async (reqBody) => {
  try {
    const res = await Api.updateCategoryStatus(reqBody);
    const body = res?.body;
    return body;
  } catch (res) {
    console.log({ res });
    const body = res.body;
    toaster.danger(body?.message);
  }
};

// -----------------------------------------------------------------------
// updateCategoryTickets()
// -----------------------------------------------------------------------
export const updateCategoryTickets = async (reqBody) => {
  try {
    const res = await Api.updateCategoryTickets(reqBody);
    const body = res?.body;
    return body;
  } catch (res) {
    console.log({ res });
    const body = res.body;
    toaster.danger(body?.message);
  }
};

// -----------------------------------------------------------------------
// downloadEventBooking()
// -----------------------------------------------------------------------
export const downloadEventBooking =
  (categoryId, eventId) => async (dispatch) => {
    try {
      toggleLoaderOn(dispatch);
      const res = await Api.downloadEventBooking(categoryId, eventId);

      if (res) {
        let jsonRes;
        try {
          jsonRes = JSON.parse(await res.body.text());
        } catch (e) {
          console.log(e);
        }
        if (jsonRes && jsonRes.requestStatus === 0) {
          toggleLoaderOff(dispatch);
          toaster.notify(jsonRes.message, {
            id: "booking-download-failed",
          });
        } else if (jsonRes && jsonRes.requestStatus === -1) {
          toggleLoaderOff(dispatch);
          toaster.notify("You dont have permission", {
            id: "You dont have permissions",
          });
        } else {
          if (res.status === 200) {
            toggleLoaderOff(dispatch);
            const url = window.URL.createObjectURL(res.body);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `eventBooking.xlsx`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            await dispatch({
              type: "SET_DOWNLOAD_REPORT_TOAST",
              payload: {
                message: "File downloaded",
                requestStatus: 1,
              },
            });
          }
        }
      }
    } catch (res) {
      await handleServerResponse(dispatch, res, "SET_DOWNLOAD_REPORT_TOAST");
    }
  };

// -----------------------------------------------------------------------
// getSummary()
// -----------------------------------------------------------------------
export const getSummary = () => async (dispatch) => {
  try {
    toggleLoaderOn(dispatch);
    const res = await Api.getSummary();

    await handleServerResponse(dispatch, res, "SET_SUMMARY");
  } catch (res) {
    await handleServerResponse(dispatch, res, "SET_SUMMARY");
  }
};

// -----------------------------------------------------------------------
// getChartData()
// -----------------------------------------------------------------------
export const getChartData = async () => {
  try {
    const res = await Api.getChartData();
    const body = res?.body;
    return body;
  } catch (res) {
    console.log({ res });
    const body = res.body;
    toaster.danger(body?.message);
  }
};

// -----------------------------------------------------------------------
// getChartData()
// -----------------------------------------------------------------------
export const cancelMembership = async (data, dispatch) => {
  try {
    const res = await Api.cancelMembership(data);
    dispatch(getSummary());
    const body = res?.body;
    return body;
  } catch (res) {
    console.log({ res });
    const body = res.body;
    toaster.danger(body?.message);
  }
};

// -----------------------------------------------------------------------
// getChartData()
// -----------------------------------------------------------------------
export const getUserClubsData = async (data) => {
  try {
    const res = await Api.getUserClubData(data);
    const body = res?.body;
    return body; // or return body.data if the data is nested under 'data'
  } catch (error) {
    console.log({ error });
    const body = error.body;
    toaster.danger(body?.message);
    // You might want to throw the error again if you don't want to suppress it
    throw error;
  }
};

// -----------------------------------------------------------------------
// getBookingDetails()
// -----------------------------------------------------------------------
export const viewNumber = (bookingId) => async (dispatch) => {
  try {
    let res = await Api.viewNumber(bookingId);
    return res.body;
  } catch (error) {
    console.log({ error });
    const body = error.body;
    toaster.danger(body?.message);
    // You might want to throw the error again if you don't want to suppress it
    throw error;
  }
};
