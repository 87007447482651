import { Pane, Strong, Text } from "evergreen-ui";
import React from "react";

function StatsCard({ data, currency }) {
  return (
    <Pane
      display="flex"
      flexDirection="column"
      alignItems="start"
      background="white"
      width={"100%"}
      height={"186px"}
      borderRadius={"10px"}
      padding="20px"
    >
      <Strong size={500}>
        {currency} {data.sum.toFixed(2)}
      </Strong>
      <Text textTransform={"capitalize"} marginTop="22px" color="gray600">
        {data.title} (GTV)
      </Text>
      <Pane
        display="flex"
        flexDirection="row"
        alignItems="center"
        width="100%"
        justifyContent="space-between"
        marginTop="16px"
      >
        <Text>Online</Text>
        <Strong>
          {currency} {data.online.toFixed(2)}
        </Strong>
      </Pane>
      <Pane
        display="flex"
        flexDirection="row"
        alignItems="center"
        width="100%"
        justifyContent="space-between"
        marginTop="16px"
      >
        <Text>Offline</Text>
        <Strong>
          {currency} {data.offline.toFixed(2)}
        </Strong>
      </Pane>
    </Pane>
  );
}

export default StatsCard;
