// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import { Button, Dialog, Pane, Text, toaster } from "evergreen-ui";
import CancelBookingDialogContent from "../../components/CancelBookingDialogContent";
import RescheduleBookingDialogContent from "../../components/RescheduleBookingDialogContent";
import api from "../../api";
import BookingCheckIn from "./BookingCheckIn";
import { getBookingDetails } from "../../actions";
import { connect } from "react-redux";

// -----------------------------------------------------------------------
// BookingDetailsDrawerActions Component
// -----------------------------------------------------------------------
class BookingDetailsDrawerActions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showCancelBookingDialog: false,
      showRescheduleBookingDialog: false,
    };
  }

  // -----------------------------------------------------------------------
  // handleCancelBookingDialog()
  // -----------------------------------------------------------------------
  handleCancelBookingDialog = (type) => {
    if (type === "open") {
      this.setState({ showCancelBookingDialog: true });
    }
    if (type === "close") {
      this.setState({ showCancelBookingDialog: false });
    }
  };

  // -----------------------------------------------------------------------
  // handleRescheduleBookingDialog()
  // -----------------------------------------------------------------------
  handleRescheduleBookingDialog = (type) => {
    if (type === "open") {
      this.setState({ showRescheduleBookingDialog: true });
    }
    if (type === "close") {
      this.setState({ showRescheduleBookingDialog: false });
    }
  };

  // -----------------------------------------------------------------------
  // sendBookingPaymentLink()
  // -----------------------------------------------------------------------
  sendBookingPaymentLink = async (bookingId) => {
    const paymentLink = await api.sendBookingPaymentLink(bookingId);
    if (paymentLink.status === 200) {
      if (paymentLink.body["requestStatus"]) {
        toaster.success("Payment Link Sent", {
          id: "send-payment-link",
        });
      } else {
        toaster.warning(paymentLink.body["message"], {
          id: "send-payment-link",
        });
      }
    } else {
      toaster.warning("Something went wrong, please contact Playo", {
        id: "send-payment-link",
      });
    }
  };

  bookingCheckIn = async (bookingId, id) => {
    let data = {
      bookingId,
      id,
    };
    await this.props.dispatch(
      getBookingDetails(bookingId, this.props.dateBooked)
    );
    const checkinData = await api.bookingCheckin(data);

    if (checkinData.status === 200) {
      if (checkinData.body["requestStatus"]) {
        toaster.success(checkinData.body["message"], {
          id: "booking-checkin",
        });
        await this.props.dispatch(
          getBookingDetails(bookingId, this.props.dateBooked)
        );
      } else {
        toaster.warning(checkinData.body["message"], {
          id: "booking-checkin",
        });
      }
    } else {
      toaster.warning("Something went wrong, please contact Playo", {
        id: "booking-checkin-failed",
      });
    }
  };

  // -----------------------------------------------------------------------
  // render()
  // -----------------------------------------------------------------------
  render() {
    const { showCancelBookingDialog, showRescheduleBookingDialog } = this.state;
    const {
      bookingId,
      courts,
      dateBooked,
      startTime,
      endTime,
      handleRescheduleBookingDialog,
      handleDrawer,
      updateCalendarPage,
      updateSearchPage,
      bookingCancelled,
      updateBookingDetails,
      handleGetBookingDetails,
      playoBooking,
      showPaymentLink,
      balance,
      moreInfo,
      type,
      type1,
      checkinData,
      rescheduleHistory,
    } = this.props;

    if (playoBooking) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Pane
            style={{
              flexGrow: 1,
              boxSizing: "border-box",
              padding: "20px",
              alignItems: "center",
              marginTop: "auto",
              width: "100%",
            }}
          >
            {type !== 0 && (
              <Text id="BDDA-A1-cancelled-text">
                Contact Playo to modify Playo bookings
              </Text>
            )}
            {!type1 && (
              // Assuming BookingCheckIn is another component
              <BookingCheckIn
                bookingId={bookingId}
                bookingCheckIn={this.bookingCheckIn}
                checkinData={checkinData}
                type={type}
                moreInfo={moreInfo}
                bookingDate={dateBooked}
              />
            )}
          </Pane>
        </div>
      );
    }
    return !bookingCancelled ? (
      <Pane
        display={"flex"}
        justifyContent={"center"}
        flexDirection="column"
        width={400}
        alignItems="center"
        padding={10}
      >
        {balance && showPaymentLink ? (
          <Button
            intent="warning"
            width={320}
            appearance="primary"
            marginTop={10}
            marginBottom={10}
            onClick={() => this.sendBookingPaymentLink(bookingId)}
          >
            Remind Payment
          </Button>
        ) : null}

        {type !== 0 ? (
          <Pane display={"flex"} justifyContent={"space-between"}>
            <Dialog
              isShown={showCancelBookingDialog}
              title="Cancel Booking"
              onCloseComplete={(e) => this.handleCancelBookingDialog("close")}
              hasFooter={false}
            >
              <CancelBookingDialogContent
                bookingId={bookingId}
                handleCancelBookingDialog={this.handleCancelBookingDialog}
                handleDrawer={handleDrawer}
                updateCalendarPage={updateCalendarPage}
                updateSearchPage={updateSearchPage}
                updateBookingDetails={updateBookingDetails}
                handleGetBookingDetails={handleGetBookingDetails}
                bookingType="regular"
                isPlayoBooking={playoBooking}
              />
            </Dialog>
            <Button
              className="BDDA-A1-button"
              appearance="default"
              intent="danger"
              width={150}
              height={32}
              marginRight={10}
              onClick={(e) => this.handleCancelBookingDialog("open")}
            >
              Cancel Booking
            </Button>
            <Dialog
              width={592}
              isShown={showRescheduleBookingDialog}
              title="Reschedule Booking"
              onCloseComplete={(e) =>
                this.handleRescheduleBookingDialog("close")
              }
              hasFooter={false}
            >
              <RescheduleBookingDialogContent
                bookingId={bookingId}
                rescheduleHistory={rescheduleHistory}
                courts={courts}
                dateBooked={dateBooked}
                startTime={startTime}
                endTime={endTime}
                handleRescheduleBookingDialog={
                  this.props.handleRescheduleBookingDialog
                    ? handleRescheduleBookingDialog
                    : this.handleRescheduleBookingDialog
                }
                handleDrawer={handleDrawer}
                updateCalendarPage={updateCalendarPage}
                updateSearchPage={updateSearchPage}
                updateBookingDetails={updateBookingDetails}
                handleGetBookingDetails={handleGetBookingDetails}
              />
            </Dialog>
            <Button
              className="BDDA-A1-button"
              appearance="default"
              intent="success"
              width={150}
              height={32}
              marginLeft={10}
              onClick={(e) => this.handleRescheduleBookingDialog("open")}
            >
              Reschedule Booking
            </Button>
          </Pane>
        ) : (
          <Pane>
            <Text id="BDDA-A1-cancelled-text">
              Contact Playo to modify Playo bookings
            </Text>
          </Pane>
        )}

        {!type1 && (
          <Pane
            display="flex"
            flexDirection={"row"}
            marginTop={10}
            width={"100%"}
            alignItems="center"
            justifyContent={"center"}
          >
            <BookingCheckIn
              bookingId={bookingId}
              bookingCheckIn={this.bookingCheckIn}
              checkinData={checkinData}
              moreInfo={moreInfo}
              type={type}
              bookingDate={dateBooked}
            />
          </Pane>
        )}
      </Pane>
    ) : (
      <Pane
        display={"flex"}
        justifyContent={"center"}
        flexDirection="column"
        width={400}
        alignItems="center"
        padding={10}
        height={40}
      >
        <Text size={300} id="BDDA-A1-cancelled-text">
          Booking is cancelled
        </Text>
      </Pane>
    );
  }
}

const mapStateToProps = ({}) => ({});

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default connect(mapStateToProps)(BookingDetailsDrawerActions);
