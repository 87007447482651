// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import Header from "../../components/Header";
import BulkForm from "../../components/BulkForm";

// -----------------------------------------------------------------------
// BulkBooking Component
// -----------------------------------------------------------------------
class BulkBooking extends React.Component {
  // -----------------------------------------------------------------------
  // render()
  // -----------------------------------------------------------------------
  render() {
    const {
      handleBookingCheckoutDisplay,
      handleSeeAllBulkBookingsFromBulkForm,
      setResetBookingCheckoutInputFieldsTrue
    } = this.props;
    return (
      <React.Fragment>
        <Header headerText={"Bulk Booking"} />
        <BulkForm
          formFor={"bulk-booking"}
          showTabs={false}
          handleBookingCheckoutDisplay={handleBookingCheckoutDisplay}
          handleSeeAllBulkBookingsFromBulkForm={
            handleSeeAllBulkBookingsFromBulkForm
          }
          setResetBookingCheckoutInputFieldsTrue={
            setResetBookingCheckoutInputFieldsTrue
          }
        />
      </React.Fragment>
    );
  }
}

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default BulkBooking;
