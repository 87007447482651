// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import { Text, Button, toaster } from "evergreen-ui";
import { connect } from "react-redux";
import Navbar from "../../components/Navbar";
import PageHeadingAndActionsNav from "../../components/PageHeadingAndActionsNav";
import CustomizedDatePicker from "../../components/CustomizedDatePicker";
import ClubHandlerSelect from "../../components/ClubHandlerSelect";
import TransactionTable from "../Reports/TransactionTable";
import BookingTable from "../Reports/BookingTable";
import CancellationTable from "../Reports/CancellationTable";
import BalanceTable from "../Reports/BalanceTable";
import CreditsTable from "../Reports/CreditsTable";
import RechargeTable from "../Reports/RechargeTable";
import ExtrasTable from "../Reports/ExtrasTable";
import {
  toDateFormat1,
  checkIntOrFloat,
  resourcesNotLoadedToast,
  generateCurrencySymbol,
  getUserClubs,
  getPlayoUserName,
} from "../../utils";
import {
  getReports,
  downloadReport,
  togglePremiumFeatureFlag,
  getUserClubsData,
} from "../../actions";
import ClubSelector from "../../components/ClubSelector";

// -----------------------------------------------------------------------
// ViewReportsPage Component
// -----------------------------------------------------------------------
class ViewReportsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clubId: 1,
      club: "",
      fromDate: new Date(),
      toDate: new Date(),
      defaultClubHandlerValue: "all",
      defaultClubHandlerName: "All",
      selectedClubHandlerValue: "all",
      selectedClubHandlerName: "All",
      paid: 0,
      booked: 0,
      clubIds: [],
      transactionReportsData: [
        ["paid", "paid"],
        ["refund", "refund"],
        ["net", "net"],
      ],
      bookingReportsData: ["bookings", "gross", "discount", "balance"],
      extrasReportsData: ["gross", "paid", "discount", "balance"],
      cancellationReportsData: [
        ["cancellations", "cancellations"],
        ["cash refund", "cashRefund"],
        ["card refund", "cardRefund"],
        ["credit refund", "creditsRefund"],
        ["total refund", "totalRefund"],
      ],
      balanceReportsData: ["bookings", "net", "paid", "balance"],
      rechargeReportsData: [
        ["recharges", "recharges"],
        ["cash", "cash"],
        ["card", "card"],
        ["total amount", "amount"],
      ],
    };
  }

  // -----------------------------------------------------------------------
  // componentDidUpdate()
  // -----------------------------------------------------------------------
  componentDidUpdate(prevProps) {
    if (prevProps.reportsData === null) {
      if (this.props.reportsData) {
        this.setStateWithReceivedReportsData();
      }
    }
  }

  // -----------------------------------------------------------------------
  // setStateWithReceivedReportsData()
  // -----------------------------------------------------------------------
  setStateWithReceivedReportsData = async () => {
    await this.setState({
      fromDate: this.props.reportsData.fromDate,
      toDate: this.props.reportsData.toDate,
      selectedClubHandlerName: this.props.reportsData.selectedClubHandlerName,
      selectedClubHandlerValue: this.props.reportsData.selectedClubHandlerValue,
      paid: this.props.reportsData.paid,
      booked: this.props.reportsData.booked,
    });
  };

  // -----------------------------------------------------------------------
  // handleFromDateChange()
  // -----------------------------------------------------------------------
  handleFromDateChange = async (date) => {
    await this.setState({ fromDate: date });
  };

  // -----------------------------------------------------------------------
  // handleToDateChange()
  // -----------------------------------------------------------------------
  handleToDateChange = async (date) => {
    await this.setState({ toDate: date });
  };

  // -----------------------------------------------------------------------
  // handleClubHandlerChange()
  // -----------------------------------------------------------------------
  handleClubHandlerChange = async (e) => {
    await this.setState({
      selectedClubHandlerValue: e.target.value,
      selectedClubHandlerName:
        e.nativeEvent.target[
          e.nativeEvent.target.selectedIndex
        ].text.toString(),
    });
  };

  // -----------------------------------------------------------------------
  // handleBackButtonOnViewReportsPage()
  // -----------------------------------------------------------------------
  handleBackButtonOnViewReportsPage = async () => {
    await this.props.handleViewReportsPageDisplay("hide");
    await this.props.dispatch({
      type: "SET_REPORTS_DATA",
      payload: null,
    });
  };

  // -----------------------------------------------------------------------
  // handleViewReports()
  // -----------------------------------------------------------------------
  handleViewReports = async (e, reportType = "view") => {
    e.persist();
    try {
      let reqParams = {
        clubIds: this.state.clubIds,
        startDate: toDateFormat1(this.state.fromDate),
        endDate: toDateFormat1(this.state.toDate),
      };
      if (this.props.reportsData.type === "transaction") {
        reqParams = {
          ...reqParams,
          user:
            this.state.selectedClubHandlerName === "All"
              ? null
              : this.state.selectedClubHandlerName,
          paid: this.state.paid,
        };
      } else if (this.props.reportsData.type === "booking" || "extras") {
        reqParams = {
          ...reqParams,
          booked: this.state.booked,
        };
      } else if (this.props.reportsData.type === "credits") {
        reqParams = { clubId: this.state.clubId };
      }
      if (reportType === "view") {
        await this.props.dispatch(
          getReports(reqParams, this.props.reportsData.type)
        );
        if (this.props.reportsData) {
          if (this.props.reportsData.resourcesNotLoadedMessage) {
            resourcesNotLoadedToast();
          } else if (this.props.reportsData.httpErrorMessage) {
            toaster.danger(this.props.reportsData.httpErrorMessage, {
              id: "HTTP-ERROR-TOAST-1",
            });
          } else if (this.props.reportsData.requestStatus === -1) {
            toaster.notify("Sorry, you don't have permission to do that");
          } else if (
            this.props.reportsData.requestStatus === 1 &&
            this.props.reportsData.data.length <= 0
          ) {
            toaster.notify(
              "No reports are available for the selected category",
              {
                id: "RA-TOAST",
              }
            );
          }
        }
      } else if (reportType === "download") {
        await this.props.dispatch(
          downloadReport(reqParams, this.props.reportsData.type)
        );
        if (this.props.downloadReportToast) {
          if (this.props.downloadReportToast.resourcesNotLoadedMessage) {
            resourcesNotLoadedToast();
          } else if (this.props.downloadReportToast.httpErrorMessage) {
            toaster.danger(this.props.downloadReportToast.httpErrorMessage, {
              id: "HTTP-ERROR-TOAST-3",
            });
          } else if (this.props.downloadReportToast.requestStatus === 1) {
            // toaster.success(this.props.downloadReportToast.message, {
            //   id: "HTTP-ERROR-TOAST-3"
            // });
          }
        }
      }
    } catch (err) {
      toaster.danger("Some error has occured", { id: "RA-TOAST" });
    }
  };

  // -----------------------------------------------------------------------
  // handleDownloadReport()
  // -----------------------------------------------------------------------
  handleDownloadReport = async (e) => {
    e.persist();
    await this.handleViewReports(e, "download");
  };

  onClubSelectionChange = (selectedClubs) => {
    this.setState(
      (prevState) => ({
        clubIds: selectedClubs,
      }),
      () => {
        console.log("Updated clubIds:", this.state.clubIds);
      }
    );
  };

  updateSelectedClub = (data) => {};

  componentDidMount() {
    let username = getPlayoUserName();
    // Assuming getUserClubsData returns a Promise
    getUserClubsData({ username }).then((res) => {
      this.setState({ club: res }); // set the state with the response
    });
  }

  // -----------------------------------------------------------------------
  // render()
  // -----------------------------------------------------------------------
  render() {
    const {
      transactionReportsData,
      bookingReportsData,
      cancellationReportsData,
      extrasReportsData,
      balanceReportsData,
      rechargeReportsData,
      fromDate,
      toDate,
      defaultClubHandlerName,
      defaultClubHandlerValue,
      selectedClubHandlerValue,
      clubIds,
    } = this.state;

    const { reportsData } = this.props;
    return (
      <React.Fragment>
        {reportsData && reportsData.requestStatus === 1 && (
          <div className="VRP-A1-wrapper">
            <div className="VRP-A1-header-container">
              <Navbar />
              <PageHeadingAndActionsNav
                headingText={
                  reportsData.type === "transaction"
                    ? "Master Reports"
                    : reportsData.type === "booking"
                    ? "Booking Reports"
                    : reportsData.type === "balance"
                    ? "Balance Reports"
                    : reportsData.type === "cancellation"
                    ? "Cancellation Reports"
                    : reportsData.type === "credits"
                    ? "Credit Reports"
                    : reportsData.type === "packages"
                    ? "Packages Reports"
                    : reportsData.type === "recharge"
                    ? "Recharge Reports"
                    : reportsData.type === "extras"
                    ? "Add On Reports"
                    : null
                }
                handleBackButtonOnViewReportsPage={
                  this.handleBackButtonOnViewReportsPage
                }
              />
            </div>

            <div className="VRP-A1-body-container">
              <div
                className="VRP-A1-reports-actions-and-details-container"
                style={
                  reportsData && reportsData.type === "extras"
                    ? { width: "1280px", minWidth: "1280px" }
                    : null
                }
              >
                <ClubSelector
                  updateSelectedClub={this.updateSelectedClub}
                  clubs={this.state.club}
                  onClubSelectionChange={this.onClubSelectionChange}
                />
                <div className="VRP-A1-reports-actions-container">
                  {reportsData && reportsData.type !== "credits" && (
                    <div className="VRP-A1-col-container VRP-A1-margin-right-32">
                      <div className="VRP-A1-label-container VRP-A1-margin-bottom-8">
                        <Text id="VRP-A1-type-one-label">From</Text>
                      </div>
                      <div className="VRP-A1-datepicker-container">
                        <CustomizedDatePicker
                          date={fromDate}
                          handleDateChange={this.handleFromDateChange}
                        />
                      </div>
                    </div>
                  )}
                  {reportsData && reportsData.type !== "credits" && (
                    <div className="VRP-A1-col-container VRP-A1-margin-right-32">
                      <div className="VRP-A1-label-container VRP-A1-margin-bottom-8">
                        <Text id="VRP-A1-type-one-label">To</Text>
                      </div>
                      <div className="VRP-A1-datepicker-container">
                        <CustomizedDatePicker
                          date={toDate}
                          handleDateChange={this.handleToDateChange}
                        />
                      </div>
                    </div>
                  )}
                  {reportsData && reportsData.type === "transaction" && (
                    <div className="VRP-A1-club-handlers-select-holder VRP-A1-margin-right-32">
                      <ClubHandlerSelect
                        width={220}
                        defaultClubHandlerName={defaultClubHandlerName}
                        defaultClubHandlerValue={defaultClubHandlerValue}
                        selectedClubHandlerValue={selectedClubHandlerValue}
                        handleClubHandlerChange={this.handleClubHandlerChange}
                      />
                    </div>
                  )}

                  <div className="VRP-A1-get-reports-action-buttons-holder">
                    <Button
                      width={128}
                      height={32}
                      marginRight={32}
                      appearance="primary"
                      intent="success"
                      disabled={clubIds?.length > 0 ? false : true}
                      className="VRP-A1-button"
                      onClick={(e) => this.handleViewReports(e)}
                    >
                      View Report
                    </Button>
                    <div className="VRP-A1-vertical-line-decorator VRP-A1-margin-right-32" />
                    <Button
                      width={128}
                      height={32}
                      appearance="primary"
                      className="VRP-A1-button"
                      disabled={
                        clubIds?.length > 0 &&
                        reportsData &&
                        reportsData.data.length > 0
                          ? false
                          : true
                      }
                      onClick={(e) => this.handleDownloadReport(e)}
                    >
                      Download
                    </Button>
                  </div>
                </div>

                <div className="VRP-A1-horizontal-line-decorator  VRP-A1-margin-top-24 VRP-A1-margin-bottom-16" />
                {reportsData && reportsData.data.length > 0 && (
                  <React.Fragment>
                    {reportsData.type === "transaction" ? (
                      <div className="VRP-A1-reports-data-container VRP-A1-margin-bottom-16">
                        {transactionReportsData.map((data, index) => (
                          <div
                            key={index}
                            className="VRP-A1-col-container VRP-A1-width-128 VRP-A1-margin-right-24"
                          >
                            <div className="VRP-A1-label-container VRP-A1-margin-bottom-8">
                              <Text id="VRP-A1-type-one-label">{data[0]}</Text>
                            </div>
                            <div className="VRP-A1-label-container">
                              <Text id="VRP-A1-type-two-label">
                                {reportsData.summary[data[1]] !== null
                                  ? `${generateCurrencySymbol()} ${checkIntOrFloat(
                                      reportsData.summary[data[1]]
                                    )}`
                                  : `${generateCurrencySymbol()} 0`}
                              </Text>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : reportsData.type === "booking" ? (
                      <div className="VRP-A1-reports-data-container VRP-A1-margin-bottom-16">
                        {bookingReportsData.map((data, index) => (
                          <div
                            key={index}
                            className="VRP-A1-col-container VRP-A1-width-128 VRP-A1-margin-right-24"
                          >
                            <div className="VRP-A1-label-container VRP-A1-margin-bottom-8">
                              <Text id="VRP-A1-type-one-label">{data}</Text>
                            </div>
                            <div className="VRP-A1-label-container">
                              <Text id="VRP-A1-type-two-label">
                                {reportsData.summary[data] !== null
                                  ? data === "bookings"
                                    ? `${checkIntOrFloat(
                                        reportsData.summary[data]
                                      )}`
                                    : `${generateCurrencySymbol()} ${checkIntOrFloat(
                                        reportsData.summary[data]
                                      )}`
                                  : data === "bookings"
                                  ? `0`
                                  : `${generateCurrencySymbol()} 0`}
                              </Text>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : reportsData.type === "cancellation" ? (
                      <div className="VRP-A1-reports-data-container VRP-A1-margin-bottom-16">
                        {cancellationReportsData.map((data, index) => (
                          <div
                            key={index}
                            className="VRP-A1-col-container VRP-A1-width-128 VRP-A1-margin-right-24"
                          >
                            <div className="VRP-A1-label-container VRP-A1-margin-bottom-8">
                              <Text id="VRP-A1-type-one-label">{data[0]}</Text>
                            </div>
                            <div className="VRP-A1-label-container">
                              <Text id="VRP-A1-type-two-label">
                                {reportsData.summary[data[1]] !== null
                                  ? data[0] === "cancellations"
                                    ? `${checkIntOrFloat(
                                        reportsData.summary[data[1]]
                                      )}`
                                    : `${generateCurrencySymbol()} ${checkIntOrFloat(
                                        reportsData.summary[data[1]]
                                      )}`
                                  : data === "cancellations"
                                  ? `0`
                                  : `${generateCurrencySymbol()} 0`}
                              </Text>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : reportsData.type === "balance" ? (
                      <div className="VRP-A1-reports-data-container VRP-A1-margin-bottom-16">
                        {balanceReportsData.map((data, index) => (
                          <div
                            key={index}
                            className="VRP-A1-col-container VRP-A1-width-128 VRP-A1-margin-right-24"
                          >
                            <div className="VRP-A1-label-container VRP-A1-margin-bottom-8">
                              <Text id="VRP-A1-type-one-label">{data}</Text>
                            </div>
                            <div className="VRP-A1-label-container">
                              <Text id="VRP-A1-type-two-label">
                                {reportsData.summary[data] !== null
                                  ? data === "bookings"
                                    ? `${checkIntOrFloat(
                                        reportsData.summary[data]
                                      )}`
                                    : `${generateCurrencySymbol()} ${checkIntOrFloat(
                                        reportsData.summary[data]
                                      )}`
                                  : data === "bookings"
                                  ? `0`
                                  : `${generateCurrencySymbol()} 0`}
                              </Text>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : reportsData.type === "recharge" ? (
                      <div className="VRP-A1-reports-data-container VRP-A1-margin-bottom-16">
                        {rechargeReportsData.map((data, index) => (
                          <div
                            key={index}
                            className="VRP-A1-col-container VRP-A1-width-128 VRP-A1-margin-right-24"
                          >
                            <div className="VRP-A1-label-container VRP-A1-margin-bottom-8">
                              <Text id="VRP-A1-type-one-label">{data[0]}</Text>
                            </div>
                            <div className="VRP-A1-label-container">
                              <Text id="VRP-A1-type-two-label">
                                {reportsData.summary[data[1]] !== null
                                  ? data[0] === "recharges"
                                    ? `${checkIntOrFloat(
                                        reportsData.summary[data[1]]
                                      )}`
                                    : `${generateCurrencySymbol()} ${checkIntOrFloat(
                                        reportsData.summary[data[1]]
                                      )}`
                                  : data === "recharges"
                                  ? `0`
                                  : `${generateCurrencySymbol()} 0`}
                              </Text>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : reportsData.type === "extras" ? (
                      <div className="VRP-A1-reports-data-container VRP-A1-margin-bottom-16">
                        {extrasReportsData.map((data, index) => (
                          <div
                            key={index}
                            className="VRP-A1-col-container VRP-A1-width-128 VRP-A1-margin-right-24"
                          >
                            <div className="VRP-A1-label-container VRP-A1-margin-bottom-8">
                              <Text id="VRP-A1-type-one-label">{data}</Text>
                            </div>
                            <div className="VRP-A1-label-container">
                              <Text id="VRP-A1-type-two-label">
                                {reportsData.summary[data] !== null
                                  ? data === "extras"
                                    ? `${checkIntOrFloat(
                                        reportsData.summary[data]
                                      )}`
                                    : `${generateCurrencySymbol()} ${checkIntOrFloat(
                                        reportsData.summary[data]
                                      )}`
                                  : data === "extras"
                                  ? `0`
                                  : `${generateCurrencySymbol()} 0`}
                              </Text>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : null}

                    {reportsData && reportsData.type === "transaction" ? (
                      <TransactionTable />
                    ) : reportsData && reportsData.type === "booking" ? (
                      <BookingTable />
                    ) : reportsData && reportsData.type === "cancellation" ? (
                      <CancellationTable />
                    ) : reportsData && reportsData.type === "balance" ? (
                      <BalanceTable />
                    ) : reportsData && reportsData.type === "credits" ? (
                      <CreditsTable />
                    ) : reportsData && reportsData.type === "recharge" ? (
                      <RechargeTable />
                    ) : reportsData && reportsData.type === "extras" ? (
                      <ExtrasTable />
                    ) : null}
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ reportsData, downloadReportToast }) => ({
  reportsData,
  downloadReportToast,
});

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default connect(mapStateToProps)(ViewReportsPage);
