// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import {
  Badge,
  Text,
  Button,
  Paragraph,
  Textarea,
  toaster,
  Dialog,
  TextInputField,
  Pane,
  Menu,
  Strong,
  CaretRightIcon,
  EditIcon,
  AddIcon,
  CrossIcon,
  FloppyDiskIcon,
  IconButton,
  Table,
} from "evergreen-ui";

import { connect } from "react-redux";
import {
  toDateTimeFormat,
  checkIntOrFloat,
  resourcesNotLoadedToast,
  validateCreditsAndPrice,
  generateCurrencySymbol,
} from "../../utils";
import BookingDetailsDrawerActions from "../BookingDetailsDrawerActions";
import ExtraPayNow from "../ExtrasPayNow/index";
import {
  updateBookingRemarks,
  updateBookingPayment,
  getBookingDetails,
  getTransactionsDetails,
  generateBookingStatement,
  getClubExtrasList,
  enableReschedule,
  markNoShow,
  viewNumber,
} from "../../actions";
import ExtrasActions from "../../components/ExtrasActions";
import TransactionsDialogContent from "../TransactionsDialogContent";
import TransactionModeSelect from "../TransactionModeSelect";
import EnableReschedule from "../EnableReschedule/index";
import MarkNoShow from "../MarkNoShow/index";

import api from "../../api";

// -----------------------------------------------------------------------
// RegularBookingDetailsDrawerContent Component
// -----------------------------------------------------------------------
class RegularBookingDetailsDrawerContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showRemarksDiv: true,
      showRemarksTextArea: false,
      modifiedRemarks: "",
      showPayNowDialog: false,
      selectedPaymentMode: "No Pay",
      payNowAmount: "",
      showTransactionsDialog: false,
      paymentMode: "No Pay",
      selectedTransactionMode: 0,
      disableNoPay: false,
      transactionMode: 0,
      isPayNowAmountInvalid: false,
      discount: 0,
      isDiscountAmountInvalid: false,
      getInTouchDialogIsShown: false,
      getInTouchData: {},
      editBookingAmountDialogIsShown: false,
      editBookingAmountData: {},
      showExtrasDialog: false,
      showPayNowExtraDialog: false,
      extraBalance: "",
      ebookingId: "",
      extraPayNowData: "",
      extraPayNow: false,
      unMaskedNumber: "",
    };
  }

  // -----------------------------------------------------------------------
  //  componentDidUpdate()
  // -----------------------------------------------------------------------
  componentDidUpdate = (prevProps, prevState) => {
    if (
      prevState.showRemarksTextArea &&
      prevProps.bookingDetails.bookingDetails[0].bookingId !==
        this.props.bookingDetails.bookingDetails[0].bookingId
    ) {
      this.hideTextArea();
    }
  };

  // -----------------------------------------------------------------------
  // changeTransactionMode()
  // -----------------------------------------------------------------------
  changeTransactionMode = (e) => {
    e.persist();
    if (this.state.payNowAmount.length > 0) {
      this.setState({
        paymentMode:
          e.nativeEvent.target[
            e.nativeEvent.target.selectedIndex
          ].text.toString(),
        selectedTransactionMode: parseInt(e.target.value),
        isPayNowAmountInvalid: false,
      });
    } else {
      this.setState({ isPayNowAmountInvalid: true });
    }
  };

  // -----------------------------------------------------------------------
  //  showTextArea()
  // -----------------------------------------------------------------------
  showTextArea = () => {
    this.setState({ showRemarksDiv: false, showRemarksTextArea: true });
  };

  // -----------------------------------------------------------------------
  //  hideTextArea()
  // -----------------------------------------------------------------------
  hideTextArea = () => {
    this.setState({ showRemarksDiv: true, showRemarksTextArea: false });
  };

  // -----------------------------------------------------------------------
  //  handleRemarksChange()
  // -----------------------------------------------------------------------
  handleRemarksChange = async (e) => {
    e.persist();
    await this.setState({ modifiedRemarks: e.target.value.toString() });
  };

  // -----------------------------------------------------------------------
  //  handleRemarkButton()
  // -----------------------------------------------------------------------
  handleRemarkButton = async (e, type) => {
    e.persist();
    await this.showTextArea();
    if (type === "edit") {
      await this.setState({
        modifiedRemarks: this.props.bookingDetails.bookingDetails[0].remarks,
      });
      await this.showTextArea();
    }
    if (type === "add_remark") {
      await this.setState({
        modifiedRemarks: "",
      });
      await this.showTextArea();
    }
    if (type === "cancel") {
      await this.setState({
        modifiedRemarks: "",
      });
      this.hideTextArea();
    }
    if (type === "save") {
      if (this.state.modifiedRemarks.trim().length > 0) {
        await this.props.dispatch(
          updateBookingRemarks({
            bookingId: this.props.bookingDetails.bookingDetails[0].bookingId,
            patternBookingId: null,
            bookingRemarks: this.state.modifiedRemarks,
          })
        );
        if (this.props.updateBookingRemarksToast) {
          if (this.props.updateBookingRemarksToast.resourcesNotLoadedMessage) {
            resourcesNotLoadedToast();
          } else if (this.props.updateBookingRemarksToast.httpErrorMessage) {
            toaster.danger(
              this.props.updateBookingRemarksToast.httpErrorMessage,
              {
                id: "HTTP-ERROR-TOAST-1",
              }
            );
          } else if (
            this.props.updateBookingRemarksToast.requestStatus === -1
          ) {
            toaster.notify("Sorry, you don't have permission to do that");
          } else if (this.props.updateBookingRemarksToast.requestStatus === 1) {
            if (this.props.updateSearchPage) {
              await this.props.updateSearchPage();
            }

            await this.props.dispatch(
              getBookingDetails(
                this.props.bookingDetails.bookingDetails[0].bookingId
              )
            );
            if (this.props.bookingDetails) {
              if (this.props.bookingDetails.resourcesNotLoadedMessage) {
                resourcesNotLoadedToast();
              } else if (this.props.bookingDetails.httpErrorMessage) {
                toaster.danger(this.props.bookingDetails.httpErrorMessage, {
                  id: "HTTP-ERROR-TOAST-4",
                });
              } else if (this.props.bookingDetails.requestStatus === -1) {
                toaster.notify("Sorry, you don't have permission to do that");
              } else if (this.props.bookingDetails.requestStatus === 0) {
                toaster.danger(this.props.bookingDetails.message, {
                  id: "HP-TOAST",
                });
              }
              await this.hideTextArea();
              await toaster.success(
                this.props.updateBookingRemarksToast.message,
                {
                  id: "BDOCP-TOAST-1",
                }
              );
            }
          } else if (this.props.updateBookingRemarksToast.requestStatus === 0) {
            toaster.danger(this.props.updateBookingRemarksToast.message, {
              id: "BDOCP-TOAST-2",
            });
          }
        }
      } else {
        toaster.warning("Remarks can't be blank", {
          id: "BDOCP-TOAST-3",
        });
      }
    }
  };

  // -----------------------------------------------------------------------
  //  handlePayNow()
  // -----------------------------------------------------------------------
  handlePayNow = async (e, type) => {
    if (type === "show-dialog") {
      this.setState({ showPayNowDialog: true });
    }
    if (type === "hide-dialog") {
      this.setState({
        showPayNowDialog: false,
        disableNoPay: false,
        paymentMode: "No Pay",
        selectedPaymentMode: "No Pay",
        transactionMode: 0,
        selectedTransactionMode: 0,
      });
    }
    if (type === "amount-change") {
      if (validateCreditsAndPrice(e.target.value) === "set") {
        await this.setState({
          payNowAmount: e.target.value,
          isPayNowAmountInvalid: false,
        });
        if (
          this.state.selectedTransactionMode === 0 &&
          this.state.payNowAmount > 0
        ) {
          await this.setState({
            paymentMode: "Cash",
            transactionMode: 1,
            selectedTransactionMode: 1,
            disableNoPay: true,
          });
        }
        if (this.state.payNowAmount <= 0) {
          await this.setState({
            paymentMode: "No Pay",
            transactionMode: 0,
            selectedTransactionMode: 0,
            disableNoPay: false,
          });
        }
      } else if (validateCreditsAndPrice(e.target.value) === "clear") {
        await this.setState({
          payNowAmount: "",
          isPayNowAmountInvalid: false,
          paymentMode: "No Pay",
          transactionMode: 0,
          selectedTransactionMode: 0,
          disableNoPay: false,
        });
      }
    }
    if (type === "apply-discount") {
      if (!isNaN(e.target.value) && parseFloat(e.target.value) >= 0) {
        await this.setState({
          discount: e.target.value,
          isDiscountAmountInvalid: false,
        });
      } else {
        await this.setState({
          isDiscountAmountInvalid: true,
        });
      }
    }
    if (type === "confirm") {
      if (
        this.state.payNowAmount >
        this.props.bookingDetails.bookingDetails[0].courtSummary.balance
      ) {
        toaster.warning("Amount is greater than balance", {
          id: "BDOCP-TOAST-6",
        });
      } else if (this.state.payNowAmount === "") {
        toaster.warning("Amount is required", {
          id: "BDOCP-TOAST-6",
        });
        await this.setState({ isPayNowAmountInvalid: true });
      } else if (this.state.isDiscountAmountInvalid) {
        toaster.warning("Invalid discount amount", {
          id: "BDOCP-TOAST-6",
        });
        await this.setState({ isPayNowAmountInvalid: true });
      } else {
        const reqBody = {
          bookingId: this.props.bookingDetails.bookingDetails[0].bookingId,
          patternBookingId: null,
          amount: parseFloat(this.state.payNowAmount),
          discount: parseFloat(this.state.discount),
          transactionData: {
            mode: this.state.selectedTransactionMode,
            type: 3,
          },
        };
        await this.props.dispatch(updateBookingPayment(reqBody));
        if (this.props.updateBookingPaymentToast) {
          if (this.props.updateBookingPaymentToast.resourcesNotLoadedMessage) {
            resourcesNotLoadedToast();
          } else if (this.props.updateBookingPaymentToast.httpErrorMessage) {
            toaster.danger(
              this.props.updateBookingPaymentToast.httpErrorMessage,
              {
                id: "HTTP-ERROR-TOAST-1",
              }
            );
          } else if (
            this.props.updateBookingPaymentToast.requestStatus === -1
          ) {
            toaster.notify("Sorry, you don't have permission to do that");
          } else if (this.props.updateBookingPaymentToast.requestStatus === 1) {
            await this.setState({ payNowAmount: 0 });
            await this.setState({ discount: 0 });
            await this.setState({ showPayNowDialog: false });
            if (this.props.updateCalendarPage) {
              this.props.updateCalendarPage();
            }
            if (this.props.updateBookingDetails) {
              await this.props.updateBookingDetails();
            }

            if (this.props.handleGetBookingDetails) {
              await this.props.handleGetBookingDetails(
                e,
                this.props.bookingDetails.bookingDetails[0].bookingId
              );
            }
            // await this.props.handleGetBookingDetails(
            // 	this.props.bookingDetails.bookingDetails[0].bookingId
            // );

            toaster.success(this.props.updateBookingPaymentToast.message);
          } else if (this.props.updateBookingPaymentToast.requestStatus === 0) {
            // await this.setState({ showPayNowDialog: false });
            toaster.danger(this.props.updateBookingPaymentToast.message, {
              id: "BDOCP-TOAST-5",
            });
          }
        }
      }
    }
  };

  // -----------------------------------------------------------------------
  //  handleTransactionsDialog()
  // -----------------------------------------------------------------------
  handleTransactionsDialog = async (type) => {
    if (type === "hide") {
      await this.setState({ showTransactionsDialog: false });
    }
    if (type === "show") {
      await this.props.dispatch(
        getTransactionsDetails(
          this.props.bookingDetails.bookingDetails[0].bookingId
        )
      );
      if (this.props.transactionsDetails) {
        if (this.props.transactionsDetails.resourcesNotLoadedMessage) {
          resourcesNotLoadedToast();
        } else if (this.props.transactionsDetails.httpErrorMessage) {
          toaster.danger(this.props.transactionsDetails.httpErrorMessage, {
            id: "HTTP-ERROR-TOAST-1",
          });
        } else if (this.props.transactionsDetails.requestStatus === -1) {
          toaster.notify("Sorry, you don't have permission to do that");
        } else if (this.props.transactionsDetails.requestStatus === 1) {
          await this.setState({ showTransactionsDialog: true });
        } else if (this.props.transactionsDetails.requestStatus === 0) {
          toaster.danger(this.props.transactionsDetails.message);
        }
      }
    }
  };

  // -----------------------------------------------------------------------
  //  generateStatement()
  // -----------------------------------------------------------------------
  generateStatement = async (bookingId) => {
    let reqParams = { bookingId: bookingId };
    await this.props.dispatch(generateBookingStatement(reqParams));
    if (this.props.generateBookingStatementToast) {
      if (this.props.generateBookingStatementToast.resourcesNotLoadedMessage) {
        resourcesNotLoadedToast();
      } else if (this.props.generateBookingStatementToast.httpErrorMessage) {
        toaster.danger(
          this.props.generateBookingStatementToast.httpErrorMessage,
          {
            id: "HTTP-ERROR-TOAST-1",
          }
        );
      } else if (
        this.props.generateBookingStatementToast.requestStatus === -1
      ) {
        toaster.notify("Sorry, you don't have permission to do that");
      } else if (this.props.generateBookingStatementToast.requestStatus === 1) {
        // toaster.success(this.props.generateBookingStatementToast.message);
      } else if (this.props.generateBookingStatementToast.requestStatus === 0) {
        toaster.danger(this.props.generateBookingStatementToast.message);
      }
    }
  };

  // -----------------------------------------------------------------------
  //  showGetInTouchDialog()
  // -----------------------------------------------------------------------
  showGetInTouchDialog = async (status, getInTouchData = {}) => {
    await this.setState({
      getInTouchDialogIsShown: status,
      getInTouchData,
    });
  };

  // -----------------------------------------------------------------------
  //  showGetInTouchDialog()
  // -----------------------------------------------------------------------
  updateGetInTouchVenueMobile = async (e) => {
    await this.setState({
      getInTouchData: {
        ...this.state.getInTouchData,
        venueMobile: e.target.value,
      },
    });
  };

  // -----------------------------------------------------------------------
  //  getInTouchViaMobile()
  // -----------------------------------------------------------------------
  getInTouchViaMobile = async () => {
    try {
      const getInTouch = await api.getInTouchViaMobile(
        this.state.getInTouchData
      );
      await this.showGetInTouchDialog(false);
      if (getInTouch.status === 200) {
        if (getInTouch.body["requestStatus"]) {
          toaster.success("You will receive a call shortly", {
            id: "get-in-touch",
          });
        } else {
          toaster.warning(getInTouch.body["message"], {
            id: "get-in-touch",
          });
        }
      } else {
        toaster.warning("Something went wrong, please contact Playo", {
          id: "send-payment-link",
        });
      }
    } catch (e) {
      toaster.warning("Something went wrong, please contact Playo", {
        id: "send-payment-link",
      });
    }
  };

  // -----------------------------------------------------------------------
  //  showGetInTouchDialog()
  // -----------------------------------------------------------------------
  showEditBookingAmountDialog = async (status, editBookingAmountData = {}) => {
    await this.setState({
      editBookingAmountDialogIsShown: status,
      editBookingAmountData,
    });
  };

  // -----------------------------------------------------------------------
  //  showGetInTouchDialog()
  // -----------------------------------------------------------------------
  updateEditBookingAmountData = async (e) => {
    await this.setState({
      editBookingAmountData: {
        ...this.state.editBookingAmountData,
        amount: parseFloat(e.target.value),
      },
    });
  };

  // -----------------------------------------------------------------------
  //  getInTouchViaMobile()
  // -----------------------------------------------------------------------
  editBookingAmount = async () => {
    try {
      const editAmount = await api.editBookingAmount(
        this.state.editBookingAmountData
      );
      if (editAmount.status === 200) {
        if (editAmount.body["requestStatus"]) {
          toaster.success("Updated Booking Amount", {
            id: "get-in-touch",
          });
          if (await this.props.updateSearchPage) {
            await this.props.updateSearchPage();
          }
          if (this.props.handleShowAvailability) {
            await this.props.handleShowAvailability();
          }
          this.showEditBookingAmountDialog(false);
          await this.props.dispatch(
            getBookingDetails(
              this.props.bookingDetails.bookingDetails[0].bookingId
            )
          );
        } else {
          toaster.warning(editAmount.body["message"], {
            id: "get-in-touch",
          });
        }
      } else {
        toaster.warning("Something went wrong, please contact Playo", {
          id: "send-payment-link",
        });
      }
    } catch (e) {
      toaster.warning("Something went wrong, please contact Playo", {
        id: "send-payment-link",
      });
    }
  };

  // -----------------------------------------------------------------------
  // Enable Reschedule()
  // -----------------------------------------------------------------------

  onReschedule = async (bookingId) => {
    let reqParams = { bookingId: bookingId };
    await this.props.dispatch(enableReschedule(reqParams));

    if (this.props.enableReschedule) {
      if (this.props.enableReschedule.resourcesNotLoadedMessage) {
        resourcesNotLoadedToast();
      } else if (this.props.enableReschedule.httpErrorMessage) {
        toaster.danger(this.props.enableReschedule.httpErrorMessage, {
          id: "HTTP-ERROR-TOAST-1",
        });
      } else if (this.props.enableReschedule.requestStatus === -1) {
        toaster.notify("Sorry, you don't have permission to do that");
      } else if (this.props.enableReschedule.requestStatus === 1) {
        toaster.success(this.props.enableReschedule.message);
      } else if (this.props.enableReschedule.requestStatus === 0) {
        toaster.danger(this.props.enableReschedule.message);
      }
      await this.props.dispatch(getBookingDetails(bookingId));
    }
  };

  // -----------------------------------------------------------------------
  // Mark NoShow()
  // -----------------------------------------------------------------------

  onNoShow = async (bookingId) => {
    let reqParams = { bookingId: bookingId };
    await this.props.dispatch(markNoShow(reqParams));

    if (this.props.markNoShow) {
      if (this.props.markNoShow.resourcesNotLoadedMessage) {
        resourcesNotLoadedToast();
      } else if (this.props.markNoShow.httpErrorMessage) {
        toaster.danger(this.props.markNoShow.httpErrorMessage, {
          id: "HTTP-ERROR-TOAST-1",
        });
      } else if (this.props.markNoShow.requestStatus === -1) {
        toaster.notify("Sorry, you don't have permission to do that");
      } else if (this.props.markNoShow.requestStatus === 1) {
        toaster.success(this.props.markNoShow.message);
      } else if (this.props.markNoShow.requestStatus === 0) {
        toaster.danger(this.props.markNoShow.message);
      }
      await this.props.dispatch(getBookingDetails(bookingId));
    }
  };

  // -----------------------------------------------------------------------
  // handleExtrasDialog()
  // -----------------------------------------------------------------------

  handleExtrasDialog = async (type) => {
    let body = {
      activityId: this.props.bookingDetails.bookingDetails[0].activityId,
    };

    if (type === "open") {
      //this.props.dispatch({ type: "SET_CART_DATA", payload: null });
      this.props.dispatch(getClubExtrasList(body));
      this.setState({ showExtrasDialog: true });
    } else if (type === "close") {
      await this.props.dispatch(
        getBookingDetails(this.props.bookingDetails.bookingDetails[0].bookingId)
      );
      await this.props.dispatch({ type: "SET_CUSTOMER_DETAILS", payload: {} });
      this.setState({ showExtrasDialog: false });
    }
  };

  // -----------------------------------------------------------------------
  //  handleExtraPayNow
  // -----------------------------------------------------------------------

  handleExtraPayNow = async (type, extrasData) => {
    if (type === "open") {
      this.setState({
        extraPayNow: true,
        extraPayNowData: this.props.bookingDetails.bookingDetails[0].extras,
      });
    } else {
      await this.props.dispatch(
        getBookingDetails(this.props.bookingDetails.bookingDetails[0].bookingId)
      );
      this.setState({
        extraPayNow: false,
        extraPayNowData: "",
      });
    }
  };

  // -----------------------------------------------------------------------
  //  handleExtraPayNow
  // -----------------------------------------------------------------------

  viewNumber = async (bookingId) => {
    let data = await this.props.dispatch(viewNumber(bookingId));
    console.log({ data });
    if (data.requestStatus === 1) {
      this.setState({
        unMaskedNumber: data.data.mobile,
      });
      if (data.message) {
        toaster.warning(data.message);
      }
    } else {
      toaster.warning(data.message);
    }
  };

  // -----------------------------------------------------------------------
  //  render
  // -----------------------------------------------------------------------
  render() {
    const {
      showRemarksDiv,
      showRemarksTextArea,
      modifiedRemarks,
      showPayNowDialog,
      showTransactionsDialog,
      selectedTransactionMode,
      disableNoPay,
      isPayNowAmountInvalid,
      isDiscountAmountInvalid,
      getInTouchDialogIsShown,
      editBookingAmountDialogIsShown,
      showExtrasDialog,
      extraPayNowData,
      unMaskedNumber,
    } = this.state;
    const {
      bookingDetails,
      transactionsDetails,
      handleDrawer,
      updateCalendarPage,
      updateSearchPage,
      updateBookingDetails,
      handleGetBookingDetails,
    } = this.props;
    let extras =
      bookingDetails.bookingDetails[0] &&
      bookingDetails.bookingDetails[0].extras &&
      bookingDetails.bookingDetails[0].extras;
    // console.log({ extras })
    // var formattedString = extrasString
    // 	? extrasString.split(",").map((line) => (
    // 			<span>
    // 				{line}
    // 				<br />
    // 			</span>
    // 	  ))
    // 	: "";
    let type = bookingDetails?.bookingDetails[0]?.type;
    let moreInfo = bookingDetails?.bookingDetails[0]?.moreInfo;
    console.log(this.props.bookingDetails);
    return (
      <Pane height={"100vh"} padding={15} paddingBottom={10}>
        <Pane borderRadius={5} background={"white"}>
          <Pane padding={8} display={"flex"} flexDirection={"column"}>
            <Pane
              display={"flex"}
              justifyContent={"space-between"}
              flexDirection={"row"}
            >
              <Badge marginBottom={5} marginTop={5} color="blue" isSolid>
                {bookingDetails.bookingDetails[0].sport}
              </Badge>

              {type !== 0 && (
                <Button
                  className="border-button"
                  height={32}
                  minWidth={100}
                  appearance="minimal"
                  onClick={() =>
                    this.generateStatement(
                      bookingDetails.bookingDetails[0].bookingId
                    )
                  }
                >
                  <Strong color="#0679c5" size={300}>
                    Statements
                  </Strong>
                </Button>
              )}
            </Pane>
            {bookingDetails.bookingDetails[0].isPlayoBooking && (
              <Badge
                background={"#00B562"}
                display="flex"
                alignItems={"center"}
                flexDirection="row"
                isSolid
                color="white"
                width="113px"
              >
                Playo Booking
              </Badge>
            )}
            <Pane marginTop={10} display={"flex"} flexDirection={"row"}>
              <Pane display="flex" flexDirection={"column"}>
                <Strong color="muted" size={300}>
                  BOOKING ID
                </Strong>
                <Strong color="black">
                  {bookingDetails.bookingDetails[0].bookingId}
                </Strong>
              </Pane>
              <Pane marginLeft={120} display="flex" flexDirection={"column"}>
                <Strong color="muted" size={300}>
                  TYPE
                </Strong>
                <Strong color="black">
                  {bookingDetails.bookingDetails[0].bookingType}
                  {type === 0 && "Event Booking"}
                </Strong>
              </Pane>
            </Pane>
            <Pane
              marginTop={10}
              display={"flex"}
              justifyContent={"space-between"}
              flexDirection={"row"}
            >
              <Pane display="flex" flexDirection={"column"}>
                <Text id="RBDDC-A1-type-three-label">
                  {`${bookingDetails.bookingDetails[0].courts} | ${bookingDetails.bookingDetails[0].slotCount} slot(s)`}
                </Text>
                <Text id="RBDDC-A1-type-two-label" marginTop={12}>
                  {toDateTimeFormat(
                    bookingDetails.bookingDetails[0].dateBooked,
                    bookingDetails.bookingDetails[0].startTime,
                    bookingDetails.bookingDetails[0].endTime
                  )}
                </Text>
              </Pane>
            </Pane>
          </Pane>
          <Menu.Divider />
          <Pane padding={8}>
            <Pane marginTop={10} display={"flex"} flexDirection={"row"} justifyContent={"space-between"}>
              <Pane display="flex" flexDirection={"column"}>
                <Strong color="muted" size={300}>
                  NAME
                </Strong>
                <Strong color="black">
                  {bookingDetails.bookingDetails[0] &&
                  bookingDetails.bookingDetails[0].customerDetails.customerName
                    ? bookingDetails.bookingDetails[0].customerDetails
                        .customerName
                    : "None"}
                </Strong>
              </Pane>
              <Pane display="flex" marginLeft={105} flexDirection={"column"}>
                {bookingDetails.bookingDetails[0].customerDetails &&
                bookingDetails.bookingDetails[0].customerDetails
                  .customerCountryCode === "+91" &&
                bookingDetails.bookingDetails[0].isPlayoBooking ? (
                  <Button
                    className="border-button"
                    height={32}
                    minWidth={100}
                    appearance="minimal"
                    onClick={() =>
                      this.showGetInTouchDialog(true, {
                        bookingId: bookingDetails.bookingDetails[0].bookingId,
                      })
                    }
                  >
                    Get in Touch
                  </Button>
                ) : null}
              </Pane>
              {/* <Pane display="flex" marginLeft={105} flexDirection={"column"}>
                {bookingDetails.bookingDetails[0].customerDetails &&
                bookingDetails.bookingDetails[0].customerDetails
                  .customerCountryCode === "+91" &&
                bookingDetails.bookingDetails[0].isPlayoBooking ? (
                  <Button
                    className="border-button"
                    height={32}
                    minWidth={100}
                    appearance="minimal"
                    onClick={() =>
                      this.showGetInTouchDialog(true, {
                        bookingId: bookingDetails.bookingDetails[0].bookingId,
                      })
                    }
                  >
                    Get in Touch
                  </Button>
                ) : (
                  <Pane display="flex" flexDirection={"column"}>
                    <Strong color="muted" size={300}>
                      PHONE NUMBER
                    </Strong>
                    <Pane
                      display="flex"
                      justifyItems={"center"}
                      alignItems={"center"}
                      flexDirection={"row"}
                    >
                      <Strong color="black">
                        {bookingDetails.bookingDetails[0].isPlayoBooking &&
                        unMaskedNumber
                          ? unMaskedNumber
                          : bookingDetails.bookingDetails[0] &&
                            bookingDetails.bookingDetails[0].customerDetails
                              .customerMobile}
                      </Strong>
                      {!unMaskedNumber &&
                        bookingDetails.bookingDetails[0].isPlayoBooking &&
                        bookingDetails.bookingDetails[0].showMaskedDeatils &&
                        bookingDetails.bookingDetails[0]
                          .maskCustomerDetails && (
                          <Button
                            onClick={() => {
                              this.viewNumber(
                                bookingDetails.bookingDetails[0].bookingId
                              );
                            }}
                            height={18}
                            marginLeft={10}
                            appearance="primary"
                          >
                            View No.
                          </Button>
                        )}
                    </Pane>
                  </Pane>
                )}
              </Pane> */}
            </Pane>
            <Pane
              marginTop={10}
              display={"flex"}
              justifyContent={"space-between"}
              flexDirection={"row"}
            >
              <Pane display="flex" flexDirection={"column"}>
                <Strong color="muted" size={300}>
                  EMAIL ID
                </Strong>
                <Strong color="black">
                  {" "}
                  {bookingDetails.bookingDetails[0] &&
                  bookingDetails.bookingDetails[0].customerDetails.customerEmail
                    ? bookingDetails.bookingDetails[0].customerDetails
                        .customerEmail
                    : "None"}
                </Strong>
              </Pane>
              {bookingDetails.bookingDetails[0].customerDetails.customerMobile ?
                <Pane display="flex" flexDirection={"column"}>
                <Strong color="muted" size={300}>
                  PHONE NUMBER
                </Strong>
                {/* <Strong color="black">
                  {" "}
                  {bookingDetails.bookingDetails[0] &&
                  bookingDetails.bookingDetails[0].customerDetails.customerMobile
                    ? bookingDetails.bookingDetails[0].customerDetails
                        .customerMobile
                    : "None"}
                </Strong> */}
                {!unMaskedNumber &&
                  bookingDetails.bookingDetails[0].isPlayoBooking &&
                  bookingDetails.bookingDetails[0].showMaskedDeatils &&
                  bookingDetails.bookingDetails[0]
                    .maskCustomerDetails ? (
                    <Button
                      onClick={() => {
                        this.viewNumber(
                          bookingDetails.bookingDetails[0].bookingId
                        );
                      }}
                      height={18}
                      marginLeft={10}
                      appearance="primary"
                    >
                      View No.
                    </Button>
                  ) : <Strong color="black">
                        {bookingDetails.bookingDetails[0].isPlayoBooking &&
                        unMaskedNumber
                          ? unMaskedNumber
                          : bookingDetails.bookingDetails[0] &&
                            bookingDetails.bookingDetails[0].customerDetails
                              .customerMobile}
                    </Strong>}
              </Pane> : null}
            </Pane> 
          </Pane>
          <Menu.Divider />

          <Pane padding={12}>
            <Pane
              display={"flex"}
              justifyContent={"space-between"}
              flexDirection={"row"}
            >
              <Strong color="muted" size={500} marginTop={8}>
                Payment Details
              </Strong>
              {type !== 0 && (
                <Button
                  className="border-button"
                  height={32}
                  minWidth={100}
                  appearance="minimal"
                  onClick={() => this.handleTransactionsDialog("show")}
                >
                  <Strong color="#0679c5" size={300}>
                    Transaction
                  </Strong>
                </Button>
              )}
            </Pane>
            <Pane
              marginTop={15}
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"flex-start"}
            >
              <Pane display="flex" flexDirection={"column"} width={164}>
                <Strong color="muted" size={300}>
                  Gross
                </Strong>
                <Strong color="black">
                  {`${generateCurrencySymbol()} ${
                    bookingDetails.bookingDetails[0] &&
                    checkIntOrFloat(
                      bookingDetails.bookingDetails[0].summary.gross
                    )
                  }`}
                </Strong>
              </Pane>
              <Pane display="flex" flexDirection={"column"}>
                <Strong color="muted" size={300}>
                  Discount
                </Strong>
                <Strong color="black">
                  {`${generateCurrencySymbol()} ${
                    bookingDetails.bookingDetails[0] &&
                    checkIntOrFloat(
                      bookingDetails.bookingDetails[0].summary.discount
                    )
                  }`}
                </Strong>
              </Pane>
            </Pane>

            <Pane padding={5}>
              <Pane
                marginTop={15}
                display={"flex"}
                justifyContent="flex-start"
                flexDirection={"row"}
              >
                <CaretRightIcon color="muted" marginRight={3} />
                <Pane display="flex" width={200} flexDirection={"column"}>
                  <Text color="muted" size={300}>
                    Court
                  </Text>
                  <Strong color="muted" width={80}>
                    {`${generateCurrencySymbol()} ${
                      bookingDetails.bookingDetails[0] &&
                      checkIntOrFloat(
                        bookingDetails.bookingDetails[0].courtSummary.gross
                      )
                    }`}
                  </Strong>
                </Pane>
                <Pane display="flex" flexDirection={"column"}>
                  <Text color="muted" size={300}>
                    Discount
                  </Text>
                  <Strong color="muted">
                    {`${generateCurrencySymbol()} ${
                      bookingDetails.bookingDetails[0] &&
                      checkIntOrFloat(
                        bookingDetails.bookingDetails[0].courtSummary.discount
                      )
                    }`}
                  </Strong>
                </Pane>
                {type !== 0 &&
                  bookingDetails.bookingDetails[0].courtSummary.balance > 0 &&
                  !bookingDetails.bookingDetails[0].bookingCancelled && (
                    <Button
                      className="border-button"
                      height={32}
                      marginLeft={24}
                      minWidth={70}
                      appearance="minimal"
                      onClick={(e) => this.handlePayNow(e, "show-dialog")}
                    >
                      <Strong color="#0679c5" size={300}>
                        Pay Now
                      </Strong>
                    </Button>
                  )}
                {type !== 0 &&
                  bookingDetails.bookingDetails[0].courtSummary.balance > 0 &&
                  !bookingDetails.bookingDetails[0].bookingCancelled && (
                    <EditIcon
                      cursor={"pointer"}
                      onClick={() =>
                        this.showEditBookingAmountDialog(true, {
                          bookingId:
                            bookingDetails.bookingDetails[0]["bookingId"],
                        })
                      }
                      marginLeft={5}
                      size={30}
                      appearance={"minimal"}
                    />
                  )}
                {type !== 0 &&
                  bookingDetails.bookingDetails[0].courtSummary.balance === 0 &&
                  !bookingDetails.bookingDetails[0].bookingCancelled && (
                    <Button
                      className="border-button"
                      height={32}
                      marginLeft={24}
                      minWidth={100}
                      appearance="minimal"
                      onClick={() =>
                        this.showEditBookingAmountDialog(true, {
                          bookingId:
                            bookingDetails.bookingDetails[0]["bookingId"],
                        })
                      }
                    >
                      <Strong color="#0679c5" size={300}>
                        Edit Amount
                      </Strong>
                    </Button>
                  )}
              </Pane>
              {type !== 0 && bookingDetails.bookingDetails[0].extras && (
                <Pane
                  marginTop={15}
                  display={"flex"}
                  justifyContent="flex-start"
                  flexDirection={"row"}
                >
                  <CaretRightIcon color="muted" marginRight={3} />
                  <Pane display="flex" width={200} flexDirection={"column"}>
                    <Text color="muted" size={300}>
                      Add On
                    </Text>
                    <Strong color="muted">
                      {`${generateCurrencySymbol()} ${
                        bookingDetails.bookingDetails[0] &&
                        checkIntOrFloat(
                          bookingDetails.bookingDetails[0].extrasSummary.gross
                        )
                      }`}
                    </Strong>
                  </Pane>
                  <Pane display="flex" flexDirection={"column"}>
                    <Text color="muted" size={300}>
                      Discount
                    </Text>
                    <Strong color="muted">
                      {`${generateCurrencySymbol()} ${
                        bookingDetails.bookingDetails[0] &&
                        checkIntOrFloat(
                          bookingDetails.bookingDetails[0].extrasSummary
                            .discount
                        )
                      }`}
                    </Strong>
                  </Pane>

                  {type !== 0 &&
                    bookingDetails.bookingDetails[0].extrasSummary.balance >
                      0 &&
                    !bookingDetails.bookingDetails[0].bookingCancelled && (
                      <Button
                        className="border-button"
                        height={32}
                        marginLeft={24}
                        minWidth={100}
                        appearance="minimal"
                        onClick={(e) => this.handleExtraPayNow("open")}
                      >
                        <Strong minWidth={100} color="#0679c5" size={300}>
                          Pay Add-ons
                        </Strong>
                      </Button>
                    )}
                  {type !== 0 &&
                    bookingDetails.bookingDetails[0].extrasSummary.balance <=
                      0 && (
                      <Button
                        className="border-button"
                        height={32}
                        marginLeft={24}
                        minWidth={70}
                        appearance="minimal"
                        onClick={(e) => this.handleExtraPayNow("open")}
                      >
                        <Strong color="#0679c5" size={300}>
                          View
                        </Strong>
                      </Button>
                    )}
                  {type !== 0 &&
                    !bookingDetails.bookingDetails[0].bookingCancelled && (
                      <AddIcon
                        onClick={() => this.handleExtrasDialog("open")}
                        marginLeft={5}
                        border={0}
                        size={28}
                        cursor={"pointer"}
                        appearance={"minimal"}
                      />
                    )}
                </Pane>
              )}
            </Pane>
            <Menu.Divider />
            <Pane
              marginTop={15}
              marginBottom={type === 0 ? 10 : 0}
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"flex-start"}
            >
              <Pane display="flex" flexDirection={"column"} width={164}>
                <Strong color="muted" size={300}>
                  Collected
                </Strong>
                <Strong color="black">
                  {`${generateCurrencySymbol()} ${
                    bookingDetails.bookingDetails[0] &&
                    checkIntOrFloat(
                      bookingDetails.bookingDetails[0].summary.advance
                    )
                  }`}
                </Strong>
              </Pane>
              <Pane display="flex" flexDirection={"column"}>
                <Strong color="muted" size={300}>
                  Balance Due
                </Strong>
                <Strong color="black">
                  {`${generateCurrencySymbol()} ${
                    bookingDetails.bookingDetails[0] &&
                    checkIntOrFloat(
                      bookingDetails.bookingDetails[0].summary.balance
                    )
                  }`}
                </Strong>
              </Pane>
            </Pane>
          </Pane>

          {!bookingDetails.bookingDetails[0].extras && <Menu.Divider />}
          {/* {moreInfo && (
            <Pane padding={8} marginTop={10}>
              <Strong>More Info</Strong>
              <Table.Body height={250}>
                {Object.entries(moreInfo).map(([key, value]) => {
                  return (
                    <Table.Row>
                      <Table.TextCell>{key}</Table.TextCell>
                      <Table.TextCell>{value}</Table.TextCell>{" "}
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Pane>
          )} */}
          {!bookingDetails.bookingDetails[0].extras && type !== 0 && (
            <Pane
              marginTop={10}
              marginBottom={10}
              display={"flex"}
              justifyContent="center"
              flexDirection={"row"}
            >
              <Button
                appearance="minimal"
                className={"border-button"}
                padding={5}
                marginLeft={5}
                marginTOp={8}
                width={98}
                iconBefore={<AddIcon />}
                color={"#0679c5"}
                onClick={() => this.handleExtrasDialog("open")}
              >
                <Strong marginTop={3} size={300} color={"#0679c5"}>
                  Addons
                </Strong>
              </Button>
            </Pane>
          )}
          <Menu.Divider />
          {showRemarksTextArea ||
          type === 0 ||
          (bookingDetails.bookingDetails[0].remarks &&
            bookingDetails.bookingDetails[0].remarks.trim().length >
              0) ? null : (
            <Pane
              display="flex"
              padding={8}
              height={10}
              marginBottom={20}
              justifyContent="center"
            >
              <Button
                className="border-button"
                height={32}
                minWidth={70}
                marginTop={10}
                appearance="minimal"
                onClick={(e) => this.handleRemarkButton(e, "add_remark")}
              >
                <Strong color="#0679c5" padding={10} size={300}>
                  Add Remarks
                </Strong>
              </Button>
            </Pane>
          )}
          {type !== 0 && bookingDetails.bookingDetails[0].isPlayoBooking && (
            <Pane
              display="flex"
              padding={15}
              marginTop={30}
              marginBottom={20}
              flexDirection="column"
              justifyContent="center"
            >
              <EnableReschedule
                rescheduleEnableText={
                  bookingDetails.bookingDetails[0].rescheduleEnableText
                }
                bookingId={bookingDetails.bookingDetails[0].bookingId}
                onReschedule={this.onReschedule}
              />

              {bookingDetails.bookingDetails[0].isBnpl &&
                !bookingDetails.bookingDetails[0].bookingCancelled && (
                  <MarkNoShow
                    bookingId={bookingDetails.bookingDetails[0].bookingId}
                    onNoShow={this.onNoShow}
                  />
                )}
            </Pane>
          )}

          {type !== 0 && (
            <Pane display="flex" padding={15} height={260}>
              {showRemarksTextArea && (
                <Pane
                  display={"flex"}
                  flexDirection="column"
                  justifyContent="flex-start"
                >
                  <Pane display={"flex"} flexDirection="row">
                    <Textarea
                      className="RBDDC-A1-text-area-input"
                      style={{ resize: "none" }}
                      width={330}
                      height={72}
                      placeholder={"Write your Remarks here."}
                      maxLength="100"
                      value={modifiedRemarks}
                      onChange={(e) => this.handleRemarksChange(e)}
                    />
                  </Pane>
                  <Pane
                    display={"flex"}
                    flexDirection="row"
                    justifyContent="flex-end"
                  >
                    <Button
                      className="RBDDC-A1-btn"
                      appearance="minimal"
                      width={88}
                      fontWeight={501}
                      height={32}
                      marginRight={8}
                      onClick={(e) => this.handleRemarkButton(e, "cancel")}
                    >
                      <CrossIcon
                        appearance="minimal"
                        color="muted"
                        marginRight={2}
                      />
                      <Text marginTop={2} color={"muted"}>
                        Cancel
                      </Text>
                    </Button>
                    <Button
                      className="RBDDC-A1-btn"
                      appearance="minimal"
                      width={50}
                      fontWeight={501}
                      height={32}
                      iconBefore={<FloppyDiskIcon />}
                      onClick={(e) => this.handleRemarkButton(e, "save")}
                    >
                      Save
                    </Button>
                  </Pane>
                </Pane>
              )}
              {showRemarksDiv && (
                <Pane display="flex" flexDirection="column">
                  <Pane display="flex" flexDirection="row">
                    <Paragraph id="RBDDC-A1-type-four-label">
                      {bookingDetails.bookingDetails[0].remarks &&
                      bookingDetails.bookingDetails[0].remarks.trim().length > 0
                        ? bookingDetails.bookingDetails[0].remarks
                        : null}
                    </Paragraph>
                  </Pane>
                  <Pane display="flex" flexDirection="row">
                    {bookingDetails.bookingDetails[0].remarks &&
                      bookingDetails.bookingDetails[0].remarks.trim().length >
                        0 && (
                        <Button
                          justifyContent="center"
                          className="border-button"
                          height={32}
                          minWidth={70}
                          marginTop={10}
                          appearance="minimal"
                          onClick={(e) => this.handleRemarkButton(e, "edit")}
                        >
                          <Strong color="#0679c5" padding={10} size={300}>
                            Edit Remarks
                          </Strong>
                        </Button>
                      )}
                  </Pane>
                </Pane>
              )}
            </Pane>
          )}
        </Pane>

        <Pane
          className={
            type === 0
              ? "only-checkin"
              : "RBDDC-A1-booking-details-actions-wrapper"
          }
        >
          <BookingDetailsDrawerActions
            showPaymentLink={bookingDetails.bookingDetails[0].showPaymentLink}
            balance={bookingDetails.bookingDetails[0].balance}
            bookingId={bookingDetails.bookingDetails[0].bookingId}
            courts={bookingDetails.bookingDetails[0].courts}
            dateBooked={bookingDetails.bookingDetails[0].dateBooked}
            startTime={bookingDetails.bookingDetails[0].startTime}
            endTime={bookingDetails.bookingDetails[0].endTime}
            handleDrawer={handleDrawer}
            updateCalendarPage={updateCalendarPage}
            updateSearchPage={updateSearchPage}
            bookingCancelled={bookingDetails.bookingDetails[0].bookingCancelled}
            updateBookingDetails={updateBookingDetails}
            handleGetBookingDetails={handleGetBookingDetails}
            playoBooking={bookingDetails.bookingDetails[0].isPlayoBooking}
            type={type}
            moreInfo={moreInfo}
            rescheduleHistory={
              bookingDetails.bookingDetails[0].rescheduleHistory
            }
            checkinData={bookingDetails.bookingDetails[0].checkinData}
          />
        </Pane>

        {/********************* dialogs ***************************/}

        <Dialog
          isShown={showExtrasDialog}
          width={600}
          padding={10}
          hasFooter={false}
          onCloseComplete={() => this.handleExtrasDialog("close")}
          hasHeader={false}
        >
          <Pane
            marginBottom={7}
            display={"flex"}
            flexDirection={"row"}
            justifyContent="space-between"
            alignItems={"center"}
          >
            <Strong size={400}>Add Addons</Strong>
            <IconButton
              appearance="minimal"
              icon={<CrossIcon />}
              onClick={() => this.handleExtrasDialog("close")}
            />
          </Pane>
          <Menu.Divider />
          <ExtrasActions
            mobile={
              this.props.bookingDetails.bookingDetails[0].customerDetails
                .customerMobile
            }
            bookingId={bookingDetails.bookingDetails[0].bookingId}
          />
        </Dialog>

        <Dialog
          isShown={getInTouchDialogIsShown}
          onCloseComplete={() => this.showGetInTouchDialog(false)}
          title={"Get in Touch"}
          onConfirm={this.getInTouchViaMobile}
        >
          <TextInputField
            label={"Enter venue phone number"}
            onChange={(e) => this.updateGetInTouchVenueMobile(e)}
          />
        </Dialog>

        {/* <Dialog
      		isShown={editBookingAmountDialogIsShown}
      		onCloseComplete={() => this.showEditBookingAmountDialog(false)}
      		title={"Edit Amount"}
      		onConfirm={this.editBookingAmount}
      	>
      		<TextInputField
      			label={`Enter amount in ${generateCurrencySymbol()}`}
      			onChange={(e) => this.updateEditBookingAmountData(e)}
      		/>
      	</Dialog> */}
        <Dialog
          width={450}
          isShown={editBookingAmountDialogIsShown}
          onCloseComplete={() => this.showEditBookingAmountDialog(false)}
          title={"Edit (Court Price)"}
          hasFooter={false}
          hasHeader={false}
        >
          <Pane
            marginBottom={7}
            display={"flex"}
            flexDirection={"row"}
            justifyContent="space-between"
          >
            <Strong size={400}>Edit Amount</Strong>
            <CrossIcon
              appearance="minimal"
              onClick={() => this.showEditBookingAmountDialog(false)}
            />
          </Pane>
          <Menu.Divider />
          <Pane
            display={"flex"}
            flexDirection="row"
            justifyContent="flex-start"
            marginBottom={5}
            marginTop={10}
          >
            <TextInputField
              width={250}
              label={"Court Price"}
              onChange={(e) => this.updateEditBookingAmountData(e)}
            />
          </Pane>
          <div className="RBDDC-A1-pay-now-last-row">
            {/* <Button
      					className="RBDDC-A1-btn"
      					marginRight={12}
      					width={72}
      					height={32}
      					onClick={(e) => this.handlePayNow(e, "hide-dialog")}
      				>
      					Cancel
      				</Button> */}
            <Button
              className="RBDDC-A1-btn"
              width={80}
              height={32}
              appearance="primary"
              intent="success"
              onClick={this.editBookingAmount}
            >
              Done
            </Button>
          </div>
        </Dialog>

        <Dialog
          width={450}
          isShown={showPayNowDialog}
          title="Pay Now"
          hasFooter={false}
          hasHeader={false}
          onCloseComplete={(e) => this.handlePayNow(e, "hide-dialog")}
        >
          <Pane
            marginBottom={7}
            display={"flex"}
            flexDirection={"row"}
            justifyContent="space-between"
            alignItems={"center"}
          >
            <Strong size={400}>Pay Now (Court Price)</Strong>
            <IconButton
              icon={<CrossIcon />}
              appearance="minimal"
              onClick={(e) => this.handlePayNow(e, "hide-dialog")}
            />
          </Pane>

          <Menu.Divider />

          <Pane
            display={"flex"}
            flexDirection="row"
            justifyContent="flex-start"
            marginBottom={5}
            marginTop={10}
          >
            <TextInputField
              width={150}
              label="Collect Amount"
              height={32}
              name="pay-amount-input"
              placeholder={`${generateCurrencySymbol()} 0`}
              onChange={(e) => this.handlePayNow(e, "amount-change")}
              isInvalid={isPayNowAmountInvalid}
            />
            <TextInputField
              marginLeft={20}
              maxWidth={130}
              height={32}
              label="Discount"
              name="apply-discount-input"
              placeholder={`${generateCurrencySymbol()} 0`}
              onChange={(e) => this.handlePayNow(e, "apply-discount")}
              isInvalid={isDiscountAmountInvalid}
            />
            <Pane marginLeft={20} marginTop={23}>
              <TransactionModeSelect
                disableNoPay={disableNoPay}
                selectedTransactionMode={selectedTransactionMode}
                changeTransactionMode={this.changeTransactionMode}
              />
            </Pane>
          </Pane>
          <Pane
            display={"flex"}
            flexDirection={"row"}
            marginTop={10}
            marginBottom={10}
          >
            <Text id="RBDDC-A1-type-five-label" marginRight={10}>
              Balance Amount
            </Text>
            <Text id="RBDDC-A1-type-six-label">
              {`${generateCurrencySymbol()} ${
                bookingDetails.bookingDetails[0] &&
                checkIntOrFloat(
                  bookingDetails.bookingDetails[0].courtSummary.balance
                )
              }`}
            </Text>
          </Pane>
          <div className="RBDDC-A1-pay-now-last-row">
            {/* <Button
      					className="RBDDC-A1-btn"
      					marginRight={12}
      					width={72}
      					height={32}
      					onClick={(e) => this.handlePayNow(e, "hide-dialog")}
      				>
      					Cancel
      				</Button> */}
            <Button
              className="RBDDC-A1-btn"
              width={88}
              height={32}
              appearance="primary"
              intent="success"
              onClick={(e) => this.handlePayNow(e, "confirm")}
            >
              Confirm
            </Button>
          </div>
        </Dialog>
        <ExtraPayNow
          handleExtraPayNow={this.handleExtraPayNow}
          extraPayNow={this.state.extraPayNow}
          bookingId={this.props.bookingDetails.bookingDetails[0].bookingId}
          extraPayNowData={this.state.extraPayNowData}
        />
        <Dialog
          width="fit-content"
          isShown={showTransactionsDialog}
          hasFooter={false}
          hasHeader={false}
          onCloseComplete={() => this.handleTransactionsDialog("hide")}
          height={"fit-content"}
        >
          <Pane
            marginBottom={7}
            display={"flex"}
            flexDirection={"row"}
            justifyContent="space-between"
          >
            <Strong size={400}>
              Transactions (Booking Id{" "}
              {bookingDetails.bookingDetails[0].bookingId})
            </Strong>
            <CrossIcon
              cursor={"pointer"}
              appearance="minimal"
              onClick={() => this.handleTransactionsDialog("hide")}
            />
          </Pane>
          <Menu.Divider />
          <TransactionsDialogContent
            transactionsDetails={transactionsDetails}
          />
        </Dialog>
      </Pane>
    );
  }
}

const mapStateToProps = ({
  updateBookingRemarksToast,
  updateBookingPaymentToast,
  transactionsDetails,
  bookingDetails,
  generateBookingStatementToast,
  enableReschedule,
  markNoShow,
}) => ({
  updateBookingRemarksToast,
  updateBookingPaymentToast,
  transactionsDetails,
  bookingDetails,
  generateBookingStatementToast,
  enableReschedule,
  markNoShow,
});

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default connect(mapStateToProps)(RegularBookingDetailsDrawerContent);
