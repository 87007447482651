// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import {
  Text,
  Table,
  Pane,
  Tablist,
  Tab,
  Paragraph,
  Spinner,
} from "evergreen-ui";
import { connect } from "react-redux";
import { generateCurrencySymbol } from "../../utils";

// -----------------------------------------------------------------------
// BalanceTable Component
// -----------------------------------------------------------------------
class BalanceTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedIndex: 0,
      loader: false,
      tabs: [],
    };
  }
  handleTabSelect = (index) => {
    this.setState({ loader: true }, () => {
      // The loader state is now true
      this.setState({ selectedIndex: index, loader: false });
      // The loader state is now false
    });
  };
  // -----------------------------------------------------------------------
  // render()
  // -----------------------------------------------------------------------
  render() {
    const { reportsData } = this.props;
    const { selectedIndex } = this.state;
    return (
      <React.Fragment>
        <Tablist marginY={20} marginRight={24}>
          {reportsData?.data.map((club, index) => (
            <Tab
              aria-controls={`panel-${club}`}
              isSelected={index === selectedIndex}
              key={club?.clubId}
              onSelect={() => this.handleTabSelect(index)}
            >
              {club.clubName}
            </Tab>
          ))}
        </Tablist>
        <Pane background="tint1" flex="1">
          {reportsData?.data.map((club, index) => (
            <Pane
              aria-labelledby={club}
              aria-hidden={index !== selectedIndex}
              display={index === selectedIndex ? "block" : "none"}
              key={club?.clubId}
              role="tabpanel"
            >
              <div className="RT-A1-reports-table-container">
                <Table>
                  <Table.Head>
                    <Table.TextHeaderCell className="RT-A1-table-text-header-cell RT-A1-flex-basis-250">
                      Booking Detail
                    </Table.TextHeaderCell>
                    <Table.TextHeaderCell className="RT-A1-table-text-header-cell RT-A1-flex-basis-189">
                      Customer Detail
                    </Table.TextHeaderCell>
                    <Table.TextHeaderCell className="RT-A1-table-text-header-cell RT-A1-flex-basis-138-point-25">
                      Gross
                    </Table.TextHeaderCell>
                    <Table.TextHeaderCell className="RT-A1-table-text-header-cell RT-A1-flex-basis-138-point-25">
                      Net
                    </Table.TextHeaderCell>
                    <Table.TextHeaderCell className="RT-A1-table-text-header-cell RT-A1-flex-basis-138-point-25">
                      Paid
                    </Table.TextHeaderCell>
                    <Table.TextHeaderCell className="RT-A1-table-text-header-cell RT-A1-flex-basis-138-point-25">
                      Balance
                    </Table.TextHeaderCell>
                  </Table.Head>
                  <Table.Body className="RT-A1-table-body" overflow="none">
                    {club &&
                      club.data &&
                      club.data.map((data, index) => (
                        <Table.Row
                          isSelectable
                          className="RT-A1-table-row"
                          key={index}
                        >
                          <Table.Cell className="RT-A1-table-cell RT-A1-flex-basis-250">
                            <div className="RT-A1-table-cell-data-container">
                              <Text
                                id="RT-A1-label-type-three"
                                marginBottom={8}
                              >
                                {data.bookingId}
                              </Text>
                              <Text
                                id="RT-A1-label-type-three"
                                marginBottom={8}
                              >
                                {data.sport}
                              </Text>
                            </div>
                          </Table.Cell>
                          <Table.Cell className="RT-A1-table-cell RT-A1-flex-basis-189">
                            <div className="RT-A1-table-cell-data-container">
                              <Text
                                id="RT-A1-label-type-three"
                                marginBottom={8}
                              >
                                {data.customerName}
                              </Text>
                              <Text id="RT-A1-label-type-two" marginBottom={8}>
                                {data.customerMobile}
                              </Text>
                              <Text id="RT-A1-label-type-two" marginBottom={8}>
                                {data.customerEmail}
                              </Text>
                            </div>
                          </Table.Cell>
                          <Table.Cell className="RT-A1-table-cell RT-A1-flex-basis-138-point-25">
                            <div className="RT-A1-table-cell-data-container">
                              <Text
                                id="RT-A1-label-type-three"
                                marginBottom={8}
                              >
                                {`${generateCurrencySymbol()} ${data.gross}`}
                              </Text>
                            </div>
                          </Table.Cell>
                          <Table.Cell className="RT-A1-table-cell RT-A1-flex-basis-138-point-25">
                            <div className="RT-A1-table-cell-data-container">
                              <Text
                                id="RT-A1-label-type-three"
                                marginBottom={8}
                              >
                                {`${generateCurrencySymbol()} ${data.net}`}
                              </Text>
                            </div>
                          </Table.Cell>
                          <Table.Cell className="RT-A1-table-cell RT-A1-flex-basis-138-point-25">
                            <div className="RT-A1-table-cell-data-container">
                              <Text
                                id="RT-A1-label-type-three"
                                marginBottom={8}
                              >
                                {`${generateCurrencySymbol()} ${data.paid}`}
                              </Text>
                            </div>
                          </Table.Cell>
                          <Table.Cell className="RT-A1-table-cell RT-A1-flex-basis-138-point-25">
                            <div className="RT-A1-table-cell-data-container">
                              <Text
                                id="RT-A1-label-type-three"
                                marginBottom={8}
                              >
                                {`${generateCurrencySymbol()} ${data.balance}`}
                              </Text>
                            </div>
                          </Table.Cell>
                        </Table.Row>
                      ))}
                  </Table.Body>
                </Table>
              </div>
            </Pane>
          ))}
        </Pane>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ reportsData }) => ({ reportsData });

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default connect(mapStateToProps)(BalanceTable);
