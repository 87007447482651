// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import styled from "styled-components";

// -----------------------------------------------------------------------
// Styled Components
// -----------------------------------------------------------------------
const NavBase = styled.div`
  width: 100%;
  height: 56px;
  background-color: #084b8a;
`;

// -----------------------------------------------------------------------
// Navbar Component
// -----------------------------------------------------------------------
class Navbar extends React.Component {
  render() {
    return <NavBase />;
  }
}

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default Navbar;
