// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import { InlineAlert, Text } from "evergreen-ui";

// -----------------------------------------------------------------------
// NoMatchingResultsMessage Component
// -----------------------------------------------------------------------
class NoMatchingResultsMessage extends React.Component {
  // -----------------------------------------------------------------------
  // render()
  // -----------------------------------------------------------------------
  render() {
    return (
      <React.Fragment>
        <div className="NMRM-A1-message-container">
          <InlineAlert intent="warning" marginLeft={16} marginRight={4}>
            No Matching Results.
          </InlineAlert>
          <Text marginTop={10}>Try to find by some other details.</Text>
        </div>
      </React.Fragment>
    );
  }
}

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default NoMatchingResultsMessage;
