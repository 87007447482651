// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import { connect } from "react-redux";
import {
  Text,
  TextInputField,
  TextInput,
  InlineAlert,
  toaster,
  Dialog,
  EyeOpenIcon,
  EyeOffIcon,
} from "evergreen-ui";
import CountrySelectMenu from "../CountrySelectMenu";
import validator from "validator";
import { createUser } from "../../actions";
import {
  resourcesNotLoadedToast,
  getPlayoClubCountryCallingCode,
  checkClubCountryCallingCodeExists,
} from "../../utils";

// -----------------------------------------------------------------------
// AddDeskPersonDialogContent Component
// -----------------------------------------------------------------------
class AddDeskPersonDialogContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      countryCode: checkClubCountryCallingCodeExists()
        ? getPlayoClubCountryCallingCode()
        : "+91",
      firstName: "",
      isFirstNameInvalid: false,
      lastName: "",
      email: "",
      isEmailInvalid: false,
      contactNumber: "",
      isContactNumberInvalid: false,
      userName: "",
      isUsernameInvalid: false,
      password: "",
      isPasswordInvalid: false,
      passwordInvalidMsg: "",
      showPasswordInvalidMsg: false,
      confirmPassword: "",
      isConfirmPasswordInvalid: false,
      showConfirmPasswordInvalidMsg: false,
      confirmPasswordInvalidMsg: "",
      showPasswordsMatchAlert: false,
      passwordEye: false,
    };
  }

  // -----------------------------------------------------------------------
  // onCountryChange()
  // -----------------------------------------------------------------------
  onCountryChange = (callingCode) => {
    this.setState({ countryCode: callingCode });
  };

  // -----------------------------------------------------------------------
  // onChangeOfInputFields()
  // -----------------------------------------------------------------------
  onChangeOfInputFields = async (e, type) => {
    e.persist();
    if (type === "first-name") {
      if (e.target.value.length > 0) {
        this.setState({
          firstName: e.target.value.toString(),
        });
        await this.setState({ isFirstNameInvalid: false });
      } else {
        this.setState({
          firstName: e.target.value.toString(),
        });
        await this.setState({ isFirstNameInvalid: true });
      }
    } else if (type === "last-name") {
      await this.setState({ lastName: e.target.value.toString() });
    } else if (type === "email") {
      if (e.target.value.length > 0) {
        await this.setState({ email: e.target.value.toString() });
      } else {
        await this.setState({
          email: e.target.value.toString(),
          isEmailInvalid: false,
        });
      }
    } else if (type === "contact-number") {
      if (e.target.value.length > 0) {
        await this.setState({ contactNumber: e.target.value.toString() });
      } else {
        await this.setState({
          contactNumber: e.target.value.toString(),
          isContactNumberInvalid: false,
        });
      }
    } else if (type === "username") {
      if (e.target.value.length > 0) {
        await this.setState({
          userName: e.target.value.toString(),
          isUsernameInvalid: false,
        });
      } else {
        await this.setState({
          userName: e.target.value.toString(),
          isUsernameInvalid: true,
        });
      }
    } else if (type === "password") {
      if (e.target.value.length > 0) {
        await this.setState({
          password: e.target.value.toString(),
          isPasswordInvalid: false,
          showPasswordInvalidMsg: false,
          isConfirmPasswordInvalid: false,
          showConfirmPasswordInvalidMsg: false,
        });
      } else {
        await this.setState({
          password: e.target.value.toString(),
          isPasswordInvalid: true,
          passwordInvalidMsg: "Password is required",
          showPasswordInvalidMsg: true,
          isConfirmPasswordInvalid: false,
          showConfirmPasswordInvalidMsg: false,
        });
      }
    } else if (type === "confirm-password") {
      if (e.target.value.length > 0) {
        await this.setState({
          confirmPassword: e.target.value.toString(),
          isConfirmPasswordInvalid: false,
          showConfirmPasswordInvalidMsg: false,
        });
      } else {
        await this.setState({
          confirmPassword: e.target.value.toString(),
        });
      }
    }
  };

  // -----------------------------------------------------------------------
  // validateEmail()
  // -----------------------------------------------------------------------
  validateEmail = (e) => {
    e.persist();
    if (this.state.email.length > 0) {
      if (validator.isEmail(this.state.email)) {
        this.setState({ isEmailInvalid: false });
      } else {
        this.setState({ isEmailInvalid: true });
      }
    }
  };

  // -----------------------------------------------------------------------
  // validateMobile()
  // -----------------------------------------------------------------------
  validateMobile = (e) => {
    e.persist();
    if (this.state.contactNumber.length > 0) {
      if (validator.isMobilePhone(this.state.contactNumber)) {
        this.setState({ isContactNumberInvalid: false });
      } else {
        this.setState({ isContactNumberInvalid: true });
      }
    }
  };

  // -----------------------------------------------------------------------
  // confirmAddDeskPerson()
  // -----------------------------------------------------------------------
  confirmAddDeskPerson = async () => {
    if (
      this.state.firstName !== "" &&
      this.state.userName !== "" &&
      !this.state.isContactNumberInvalid &&
      !this.state.isEmailInvalid &&
      this.state.password !== "" &&
      this.state.confirmPassword !== "" &&
      this.state.password === this.state.confirmPassword
    ) {
      let reqBody = {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        email: this.state.email,
        role: 1,
        phone: this.state.contactNumber,
        username: this.state.userName,
        password: this.state.password,
        features: [
          2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
        ],
      };
      await this.props.dispatch(createUser(reqBody));
      if (this.props.createUserToast) {
        if (this.props.createUserToast.resourcesNotLoadedMessage) {
          resourcesNotLoadedToast();
        } else if (this.props.createUserToast.httpErrorMessage) {
          toaster.danger(this.props.createUserToast.httpErrorMessage, {
            id: "HTTP-ERROR-TOAST-5",
          });
        } else if (this.props.createUserToast.requestStatus === -1) {
          toaster.notify("Sorry, you don't have permission to do that");
        } else if (this.props.createUserToast.requestStatus === 1) {
          this.props.handleSidebar("", "staff-console");
          await this.props.handleAddDeskPersonDialogVisibility("close");

          toaster.success(this.props.createUserToast.message, {
            id: "HTTP-ERROR-TOAST-5",
          });
        } else if (this.props.createUserToast.requestStatus === 0) {
          toaster.danger(this.props.createUserToast.message, {
            id: "HTTP-ERROR-TOAST-5",
          });
        }
      }
    } else {
      if (this.state.firstName === "") {
        await this.setState({ isFirstNameInvalid: true });
      }
      if (this.state.userName === "") {
        await this.setState({ isUsernameInvalid: true });
      }
      if (this.state.password === "") {
        await this.setState({
          isPasswordInvalid: true,
          passwordInvalidMsg: "Password is required",
          showPasswordInvalidMsg: true,
        });
      }
      if (this.state.password !== "" && this.state.confirmPassword === "") {
        await this.setState({
          isConfirmPasswordInvalid: true,
          confirmPasswordInvalidMsg: "Please enter password again",
          showConfirmPasswordInvalidMsg: true,
        });
      }
      if (
        this.state.password.length > 0 &&
        this.state.confirmPassword.length > 0 &&
        this.state.password !== this.state.confirmPassword
      ) {
        await this.setState({
          isConfirmPasswordInvalid: true,
          confirmPasswordInvalidMsg: "Passwords do not match",
          showConfirmPasswordInvalidMsg: true,
        });
      }
    }
  };

  // -----------------------------------------------------------------------
  // changePasswordEye()
  // -----------------------------------------------------------------------
  changePasswordEye = async () => {
    await this.setState({ passwordEye: !this.state.passwordEye });
  };

  // -----------------------------------------------------------------------
  // render()
  // -----------------------------------------------------------------------
  render() {
    const {
      countryCode,
      firstName,
      isFirstNameInvalid,
      lastName,
      email,
      isEmailInvalid,
      contactNumber,
      isContactNumberInvalid,
      userName,
      isUsernameInvalid,
      password,
      isPasswordInvalid,
      passwordInvalidMsg,
      showPasswordInvalidMsg,
      confirmPassword,
      isConfirmPasswordInvalid,
      confirmPasswordInvalidMsg,
      showConfirmPasswordInvalidMsg,
      passwordEye,
    } = this.state;
    const { handleAddDeskPersonDialogVisibility, showAddDeskPersonDialog } =
      this.props;
    return (
      <React.Fragment>
        <Dialog
          width="auto"
          isShown={showAddDeskPersonDialog}
          title="Add Desk Person"
          confirmLabel="Add Desk Person"
          onConfirm={(e) => this.confirmAddDeskPerson(e)}
          onCloseComplete={() => handleAddDeskPersonDialogVisibility("close")}
        >
          <div className="ADPDC-A1-wrapper">
            <div className="ADPDC-A1-row-container ADPDC-A1-margin-bottom-24">
              <div className="ADPDC-A1-input-field-container">
                <TextInputField
                  minWidth={300}
                  height={32}
                  marginRight={24}
                  label="First Name"
                  placeholder="First Name"
                  value={firstName}
                  isInvalid={isFirstNameInvalid}
                  onChange={(e) => this.onChangeOfInputFields(e, "first-name")}
                  validationMessage={
                    isFirstNameInvalid && "First name is required"
                  }
                />
              </div>
              <div className="ADPDC-A1-input-field-container">
                <TextInputField
                  minWidth={300}
                  height={32}
                  marginRight={24}
                  label="Last Name"
                  placeholder="Last Name (Optional)"
                  value={lastName}
                  onChange={(e) => this.onChangeOfInputFields(e, "last-name")}
                />
              </div>
            </div>
            <div className="ADPDC-A1-row-container ADPDC-A1-margin-bottom-24 ADPDC-A1-align-items-baseline">
              <div className="ADPDC-A1-input-field-container">
                <TextInputField
                  minWidth={300}
                  height={32}
                  marginRight={24}
                  label="Email"
                  placeholder="Email Address (Optional)"
                  value={email}
                  isInvalid={isEmailInvalid}
                  onChange={(e) => this.onChangeOfInputFields(e, "email")}
                  onBlur={(e) => this.validateEmail(e)}
                  validationMessage={isEmailInvalid && "Invalid email"}
                />
              </div>
              <div className="ADPDC-A1-col-container">
                <div className="ADPDC-A1-row-container">
                  <div className="ADPDC-A1-col-container  ADPDC-A1-margin-right-8">
                    <div className="ADPDC-A1-margin-bottom-4">
                      <Text id="ADPDC-A1-type-one-label">Contact Number</Text>
                    </div>
                    <div className="ADPDC-A1-country-select-menu-container">
                      <CountrySelectMenu
                        countryCode={countryCode}
                        onCountryChange={this.onCountryChange}
                      />
                    </div>
                  </div>
                  <div className="ADPDC-A1-input-field-container ADPDC-A1-align-self-flex-end">
                    <TextInput
                      maxWidth={156}
                      height={32}
                      marginRight={24}
                      placeholder="Mobile (Optional)"
                      value={contactNumber}
                      isInvalid={isContactNumberInvalid}
                      onChange={(e) =>
                        this.onChangeOfInputFields(e, "contact-number")
                      }
                      onBlur={(e) => this.validateMobile(e)}
                    />
                  </div>
                </div>
                {isContactNumberInvalid && (
                  <div className="ADPDC-A1-invalid-input-msg-container">
                    <InlineAlert
                      className="ADPDC-A1-inline-alert"
                      intent="danger"
                    >
                      <Text id="ADPDC-A1-invalid-input-msg">
                        Invalid mobile number
                      </Text>
                    </InlineAlert>
                  </div>
                )}
              </div>
            </div>
            <div className="ADPDC-A1-horizontal-line-decorator ADPDC-A1-margin-bottom-24" />
            <div className="ADPDC-A1-row-container ADPDC-A1-margin-bottom-24">
              <div className="ADPDC-A1-input-field-container">
                <TextInputField
                  minWidth={300}
                  height={32}
                  label="Username"
                  placeholder="Login Username"
                  value={userName}
                  isInvalid={isUsernameInvalid}
                  validationMessage={
                    isUsernameInvalid && "Username is required"
                  }
                  onChange={(e) => this.onChangeOfInputFields(e, "username")}
                />
              </div>
            </div>
            <div className="ADPDC-A1-row-container ADPDC-A1-margin-bottom-32">
              <div className="ADPDC-A1-input-field-container">
                <TextInputField
                  minWidth={300}
                  height={32}
                  marginRight={24}
                  label="Password"
                  placeholder="Account Password"
                  type={passwordEye ? "text" : "password"}
                  autoComplete="new-password"
                  value={password}
                  isInvalid={isPasswordInvalid}
                  validationMessage={
                    showPasswordInvalidMsg && passwordInvalidMsg
                  }
                  onChange={(e) => this.onChangeOfInputFields(e, "password")}
                />
              </div>
              <div className="ADPDC-A1-input-field-container">
                <TextInputField
                  minWidth={300}
                  height={32}
                  marginRight={8}
                  label="Confirm Password"
                  placeholder="Re-enter Password"
                  type={passwordEye ? "text" : "password"}
                  autoComplete="new-password"
                  value={confirmPassword}
                  isInvalid={isConfirmPasswordInvalid}
                  validationMessage={
                    showConfirmPasswordInvalidMsg && confirmPasswordInvalidMsg
                  }
                  onChange={(e) =>
                    this.onChangeOfInputFields(e, "confirm-password")
                  }
                />
              </div>
              <div
                className="ADPDC-A1-eye-icon-container"
                onClick={() => this.changePasswordEye()}
              >
                {/* <Icon
                  icon={passwordEye ? "eye-open" : "eye-off"}
                  color="muted"
                /> */}
                {passwordEye ? (
                  <EyeOpenIcon color="muted" />
                ) : (
                  <EyeOffIcon color="muted" />
                )}
              </div>
            </div>
          </div>
        </Dialog>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ createUserToast }) => ({ createUserToast });

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default connect(mapStateToProps)(AddDeskPersonDialogContent);
