// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import ReactDOM from "react-dom";
import { Route, BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, combineReducers } from "redux";
import Thunk from "redux-thunk";
import reducers from "./reducers";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import * as serviceWorker from "./serviceWorker";
import "pure-css-loader/dist/css-loader.css";
import "./styles/style.scss";
import { isMobileOnly } from "react-device-detect";
import MobileView from "./pages/MobileView";
import Download from "./pages/Download/download";

let preloadedState = {};
if (typeof window !== "undefined") {
  preloadedState = window.__PRELOADED_STATE__;
  delete window.__PRELOADED_STATE__;
}

// -----------------------------------------------------------------------
// Global Store
// -----------------------------------------------------------------------
const rootReducer = combineReducers(reducers);
const store = createStore(rootReducer, preloadedState, applyMiddleware(Thunk));

// -----------------------------------------------------------------------
// Render
// -----------------------------------------------------------------------
ReactDOM.render(
  <Router>
    <Provider store={store}>
      <Route exact path="/" component={isMobileOnly ? MobileView : HomePage} />
      <Route
        exact
        path="/login"
        component={isMobileOnly ? MobileView : LoginPage}
      />
        <Route  path="/download" component={Download} />
    </Provider>
  </Router>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
