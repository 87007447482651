const generateBookingStatementToast = (state = null, action) => {
  if (action.type === "SET_GENERATE_BOOKING_STATEMENT_TOAST") {
    return action.payload;
  } else {
    return state;
  }
  // switch (action.type) {
  //   case "SET_GENERATE_BOOKING_STATEMENT_TOAST": {
  //     return action.payload;
  //   }
  //   default: {
  //     return state;
  //   }
  // }
};

export default generateBookingStatementToast;
