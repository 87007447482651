// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import {connect} from "react-redux";
import {toaster, Dialog, Text, Button, Avatar} from "evergreen-ui";
import {changeUserPassword} from "../../actions";
import {resourcesNotLoadedToast} from "../../utils";

// -----------------------------------------------------------------------
// SwitchAccountDialogContent Component
// -----------------------------------------------------------------------
class SwitchAccountDialogContent extends React.Component {

    // -----------------------------------------------------------------------
    // confirmSwitchAccount()
    // -----------------------------------------------------------------------
    confirmSwitchAccount = async () => {
        if (
            this.state.oldPassword !== "" &&
            this.state.newPassword !== "" &&
            this.state.confirmPassword !== "" &&
            this.state.newPassword === this.state.confirmPassword
        ) {
            let reqBody = {
                old_password: this.state.oldPassword,
                new_password: this.state.newPassword
            };
            await this.props.dispatch(changeUserPassword(reqBody));
            if (this.props.changeUserPasswordToast) {
                if (this.props.changeUserPasswordToast.resourcesNotLoadedMessage) {
                    resourcesNotLoadedToast();
                } else if (this.props.changeUserPasswordToast.httpErrorMessage) {
                    toaster.danger(this.props.changeUserPasswordToast.httpErrorMessage, {
                        id: "HTTP-ERROR-TOAST-5"
                    });
                } else if (this.props.changeUserPasswordToast.requestStatus === -1) {
                    toaster.notify("Sorry, you don't have permission to do that");
                } else if (this.props.changeUserPasswordToast.requestStatus === 1) {
                    await this.props.handleSwitchAccountDialogVisibility("close");
                    toaster.success(this.props.changeUserPasswordToast.message, {
                        id: "HTTP-ERROR-TOAST-5"
                    });
                } else if (this.props.changeUserPasswordToast.requestStatus === 0) {
                    if (
                        this.props.changeUserPasswordToast.message ===
                        "the old password does not match"
                    ) {
                        await this.setState({
                            isOldPasswordInvalid: true,
                            oldPasswordInvalidMsg: "Old password does not match",
                            showOldPasswordInvalidMsg: true
                        });
                    } else {
                        toaster.danger(this.props.changeUserPasswordToast.message, {
                            id: "HTTP-ERROR-TOAST-5"
                        });
                    }
                }
            }
        } else {
            if (this.state.oldPassword === "") {
                await this.setState({
                    isOldPasswordInvalid: true,
                    oldPasswordInvalidMsg: "Old password is required",
                    showOldPasswordInvalidMsg: true
                });
            }
            if (this.state.newPassword === "") {
                await this.setState({
                    isNewPasswordInvalid: true,
                    newPasswordInvalidMsg: "New Password is required",
                    showNewPasswordInvalidMsg: true
                });
            }
            if (this.state.newPassword !== "" && this.state.confirmPassword === "") {
                await this.setState({
                    isConfirmPasswordInvalid: true,
                    confirmPasswordInvalidMsg: "Please enter password again",
                    showConfirmPasswordInvalidMsg: true
                });
            }
            if (
                this.state.newPassword.length > 0 &&
                this.state.confirmPassword.length > 0 &&
                this.state.newPassword !== this.state.confirmPassword
            ) {
                await this.setState({
                    isConfirmPasswordInvalid: true,
                    confirmPasswordInvalidMsg: "Passwords do not match",
                    showConfirmPasswordInvalidMsg: true
                });
            }
        }
    };

    // -----------------------------------------------------------------------
    // render()
    // -----------------------------------------------------------------------
    render() {
        const {
            handleSwitchAccountDialogVisibility,
            showSwitchAccountDialog,
            userClubs,
            currentClub,
            handleSwitchAccount
        } = this.props;
        return (
            <React.Fragment>
                <Dialog
                    width="auto"
                    isShown={showSwitchAccountDialog}
                    title="Switch Club"
                    onCloseComplete={() => handleSwitchAccountDialogVisibility("close")}
                    hasFooter={false}
                >
                    <div className="CPDC-A1-wrapper">
                        {userClubs.map((club, index) =>
                            <div className="MPDC-A1-data-container" key={index}>
                                <div className="MPDC-A1-row-container MPDC-A1-align-items-center">
                                    <Avatar
                                        className="user-name-avatar"
                                        name={club.clubName ? club.clubName : "-"}
                                        size={20}
                                        marginRight={8}
                                    />
                                    <div className="MPDC-A1-left-pane-content MPDC-A1-width-328">
                                        <Text id="MPDC-A1-text-type-one">{club.clubName}</Text>
                                    </div>
                                    <div
                                        className="MPDC-A1-right-pane-content MPDC-A1-width-0-184
                                        MPDC-A1-justify-content-flex-end">
                                        {currentClub === club.clubId ?
                                            <Button
                                                className="MPDC-A1-button"
                                                width={114}
                                                height={32}
                                                appearance="minimal"
                                                intent="none"
                                            >
                                                Active
                                            </Button> : <Button
                                                className="MPDC-A1-button"
                                                width={114}
                                                height={32}
                                                appearance="primary"
                                                onClick={e => handleSwitchAccount(e, club)}
                                            >
                                                Switch
                                            </Button>}

                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </Dialog>
            </React.Fragment>
        );
    }
}

const mapStateToProps = ({changeUserPasswordToast}) => ({
    changeUserPasswordToast
});

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default connect(mapStateToProps)(SwitchAccountDialogContent);
