// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import {
  Text,
  Popover,
  Radio,
  Button,
  Position,
  CaretDownIcon,
} from "evergreen-ui";
import CustomizedDatePicker from "../CustomizedDatePicker";

// -----------------------------------------------------------------------
// ReportsPeriodPicker Component
// -----------------------------------------------------------------------
class ReportsPeriodPicker extends React.Component {
  // -----------------------------------------------------------------------
  // render()
  // -----------------------------------------------------------------------
  render() {
    const {
      selectedPeriodType,
      handlePeriodTypeChange,
      showPeriodChangePopover,
      fromDate,
      toDate,
      handleFromDateChange,
      handleToDateChange,
    } = this.props;
    return (
      <React.Fragment>
        <div className="RPP-A1-wrapper">
          <div className="RPP-A1-col-container">
            <div className="RPP-A1-label-container RPP-A1-margin-bottom-8">
              <Text id="RPP-A1-type-one-label">Period</Text>
            </div>
            <div className="RPP-A1-period-radio-container">
              {showPeriodChangePopover && (
                <Popover
                  content={
                    <div className="RPP-A1-period-radio-popover-wrapper">
                      <div className="RPP-A1-label-container RPP-A1-margin-bottom-4">
                        <Text id="RPP-A1-type-one-label">Period</Text>
                      </div>
                      <div
                        className="RPP-A1-radio-btn-container"
                        aria-label="Radio Group Label 16"
                        role="group"
                      >
                        <Radio
                          className="RPP-A1-radio-input"
                          width={160}
                          size={16}
                          name="group25"
                          label="Range"
                          checked={
                            selectedPeriodType === "range" ? true : false
                          }
                          onChange={(e) => handlePeriodTypeChange(e, "range")}
                        />
                        <Radio
                          className="RPP-A1-radio-input"
                          width={160}
                          size={16}
                          name="group25"
                          label="Single Day"
                          checked={
                            selectedPeriodType === "single day" ? true : false
                          }
                          onChange={(e) =>
                            handlePeriodTypeChange(e, "single day")
                          }
                        />
                      </div>
                    </div>
                  }
                  position={Position.BOTTOM_LEFT}
                >
                  <Button
                    className="RPP-A1-button-as-select"
                    width={112}
                    height={32}
                    iconAfter={<CaretDownIcon />}
                  >
                    {selectedPeriodType}
                  </Button>
                </Popover>
              )}
            </div>
            <div className="RPP-A1-row-container RPP-A1-margin-top-24">
              <div className="RPP-A1-col-container RPP-A1-margin-right-32">
                <div className="RPP-A1-label-container RPP-A1-margin-bottom-8">
                  <Text id="RPP-A1-type-one-label">
                    {selectedPeriodType === "range" ? "From" : "On"}
                  </Text>
                </div>
                <div className="RPP-A1-datepicker-container">
                  <CustomizedDatePicker
                    date={fromDate}
                    handleDateChange={handleFromDateChange}
                  />
                </div>
              </div>
              {selectedPeriodType === "range" && (
                <div className="RPP-A1-col-container">
                  <div className="RPP-A1-label-container RPP-A1-margin-bottom-8">
                    <Text id="RPP-A1-type-one-label">To</Text>
                  </div>
                  <div className="RPP-A1-datepicker-container">
                    <CustomizedDatePicker
                      date={toDate}
                      handleDateChange={handleToDateChange}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default ReportsPeriodPicker;
