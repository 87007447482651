const staffMemberList = (state = null, action) => {
    if (action.type === "SET_STAFF_MEMBER") {
      return action.payload;
    } else {
      return state;
    }
    // switch (action.type) {
    //   case "SET_PRICE_CLASS_DETAILS": {
    //     return action.payload;
    //   }
  
    //   default: {
    //     return state;
    //   }
    // }
  };
  
  export default staffMemberList;
  