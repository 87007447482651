// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import { connect } from "react-redux";
import { Button, Text, Table, toaster, ChangesIcon } from "evergreen-ui";
import {
  toDateFormat16,
  toTimeFormat16,
  checkIntOrFloat,
  resourcesNotLoadedToast,
  generateCurrencySymbol,
} from "../../utils";
import { generateReceipt } from "../../actions";

// -----------------------------------------------------------------------
// TransactionsDialogContent Component
// -----------------------------------------------------------------------
class TransactionsDialogContent extends React.Component {
  // -----------------------------------------------------------------------
  // handleGenerateTaxInvoice()
  // -----------------------------------------------------------------------
  handleGenerateTaxInvoice = async (e, transactionId) => {
    e.persist();
    try {
      let reqParams = { transactionId: transactionId };
      await this.props.dispatch(generateReceipt(reqParams));
      if (this.props.generateReceiptToast) {
        if (this.props.generateReceiptToast.resourcesNotLoadedMessage) {
          resourcesNotLoadedToast();
        } else if (this.props.generateReceiptToast.httpErrorMessage) {
          toaster.danger(this.props.generateReceiptToast.httpErrorMessage, {
            id: "HTTP-ERROR-TOAST-3",
          });
        } else if (this.props.generateReceiptToast.requestStatus === 1) {
          // toaster.success(this.props.generateReceiptToast.message, {
          //   id: "HTTP-ERROR-TOAST-3"
          // });
        }
      }
    } catch (err) {
      toaster.danger("Some error has occured", { id: "RA-TOAST" });
    }
  };

  // -----------------------------------------------------------------------
  // render()
  // -----------------------------------------------------------------------
  render() {
    const { transactionsDetails } = this.props;
    return (
      <React.Fragment>
        <Table>
          <Table.Head>
            <Table.TextHeaderCell className="TDC-A1-table-text-header-cell TDC-A1-flex-basis-141">
              Type
            </Table.TextHeaderCell>
            <Table.TextHeaderCell className="TDC-A1-table-text-header-cell TDC-A1-flex-basis-171">
              Timestamp
            </Table.TextHeaderCell>
            <Table.TextHeaderCell className="TDC-A1-table-text-header-cell TDC-A1-flex-basis-155">
              Source / Handler
            </Table.TextHeaderCell>
            <Table.TextHeaderCell className="TDC-A1-table-text-header-cell TDC-A1-flex-basis-112">
              Paid / Mode
            </Table.TextHeaderCell>
            <Table.TextHeaderCell className="TDC-A1-table-text-header-cell TDC-A1-flex-basis-141" />
          </Table.Head>
          <Table.Body height={180} width={800}>
            {transactionsDetails.transactionDetails.map(
              (transaction, index) => (
                <Table.Row className="TDC-A1-table-row" key={index}>
                  <Table.Cell className="TDC-A1-table-cell TDC-A1-flex-basis-141">
                    <div className="TDC-A1-table-cell-data-container">
                      <div className="TDC-A1-label-container">
                        <Text id="TDC-A1-label-type-two">
                          {transaction.type}
                        </Text>
                      </div>
                    </div>
                  </Table.Cell>
                  <Table.Cell className="TDC-A1-table-cell TDC-A1-flex-basis-171">
                    <div className="TDC-A1-table-cell-data-container">
                      <div className="TDC-A1-label-container TDC-A1-margin-bottom-8">
                        <Text id="TDC-A1-label-type-two">
                          {toDateFormat16(transaction.timestamp)}
                        </Text>
                      </div>
                      <div className="TDC-A1-label-container">
                        <Text id="TDC-A1-label-type-three">
                          {toTimeFormat16(transaction.timestamp)}
                        </Text>
                      </div>
                    </div>
                  </Table.Cell>
                  <Table.Cell className="TDC-A1-table-cell TDC-A1-flex-basis-155">
                    <div className="TDC-A1-table-cell-data-container">
                      <div className="TDC-A1-label-container TDC-A1-margin-bottom-8">
                        <Text id="TDC-A1-label-type-four">
                          {transaction.place}
                        </Text>
                      </div>
                      <div className="TDC-A1-label-container">
                        <Text id="TDC-A1-label-type-three">
                          {transaction.handler}
                        </Text>
                      </div>
                    </div>
                  </Table.Cell>
                  <Table.Cell className="TDC-A1-table-cell TDC-A1-flex-basis-112">
                    <div className="TDC-A1-table-cell-data-container">
                      <div className="TDC-A1-label-container TDC-A1-margin-bottom-8">
                        <Text id="TDC-A1-label-type-four">
                          {`${generateCurrencySymbol()} ${checkIntOrFloat(
                            transaction.amount
                          )}`}
                        </Text>
                      </div>
                      <div className="TDC-A1-label-container">
                        <Text id="TDC-A1-label-type-three">
                          {transaction.mode}
                        </Text>
                      </div>
                    </div>
                  </Table.Cell>
                  <Table.Cell className="TDC-A1-table-cell TDC-A1-flex-basis-141">
                    {transaction.amount > 0 && (
                      <div className="TDC-A1-row-container">
                        <Button
                          className="TDC-A1-button"
                          height={32}
                          minWidth={112}
                          marginRight={8}
                          appearance="minimal"
                          iconBefore={<ChangesIcon />}
                          onClick={(e) =>
                            this.handleGenerateTaxInvoice(
                              e,
                              transaction.transactionId
                            )
                          }
                        >
                          Print Receipt
                        </Button>
                        {/* <Button
                                className="TDC-A1-button"
                                height={32}
                                minWidth={112}
                                marginRight={8}
                                appearance="minimal"
                                iconBefore="document-open"
                                onClick={e =>
                                  this.handleGenerateTaxInvoice(
                                    e,
                                    transaction.transactionId
                                  )
                                }
                              >
                                Get Invoice
                              </Button> */}
                      </div>
                    )}
                  </Table.Cell>
                </Table.Row>
              )
            )}
          </Table.Body>
        </Table>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ generateReceiptToast }) => ({
  generateReceiptToast,
});

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default connect(mapStateToProps)(TransactionsDialogContent);
