// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import {
  TextInput,
  Text,
  InlineAlert,
  TextInputField,
  Textarea,
  Radio
} from "evergreen-ui";
import CountrySelectMenu from "../CountrySelectMenu";
import CustomizedDatePicker from "../CustomizedDatePicker";

// -----------------------------------------------------------------------
// UpdateMemberDetailsDialogContent Component
// -----------------------------------------------------------------------
class UpdateMemberDetailsDialogContent extends React.Component {
  // -----------------------------------------------------------------------
  // render()
  // -----------------------------------------------------------------------
  render() {
    const mobileFieldLabel = (
      <Text id="UMDDC-A1-large-label">
        contact number<font color="#EC4C47">&nbsp;*</font>
      </Text>
    );
    const nameFieldLabel = (
      <Text id="UMDDC-A1-large-label">
        name<font color="#EC4C47">&nbsp;*</font>
      </Text>
    );
    const {
      memberMobileInputValue,
      isMobileInvalid,
      showInvalidMobileDiv,
      isNameInvalid,
      isNameInvalidMsg,
      isMobileRequiredMsg,
      memberName,
      memberEmail,
      memberAddress,
      isEmailInvalid,
      isEmailInvalidMsg,
      checkedGenderValue,
      memberExpiryDate,
      checkedMemberExpiryRadioValue,
      onChangeOfMobileInput,
      onBlurOfMobileInput,
      onChangeOfNameInput,
      onChangeOfEmailInput,
      validateEmail,
      shakeOnInvalid,
      onCountryChange,
      onChangeOfAddressInput,
      onChangeOfGenderRadioInput,
      onChangeOfMemberExpiryRadioInput,
      handleMemberExpiryDateChange
    } = this.props;
    return (
      <React.Fragment>
        <div className="UMDDC-A1-wrapper">
          <div className="UMDDC-A1-body-content">
            <div className="UMDDC-A1-col-elements-container">
              <div className="UMDDC-A1-label-container">{mobileFieldLabel}</div>
              <div className="UMDDC-A1-row-elements-container">
                <div className="UMDDC-A1-country-select-menu-container">
                  <CountrySelectMenu onCountryChange={onCountryChange} />
                </div>
                <div className="UMDDC-A1-mobile-num-input-container UMDDC-A1-input-container">
                  <TextInput
                    className={
                      shakeOnInvalid &&
                      (memberMobileInputValue.length <= 0 || isMobileInvalid)
                        ? "invalid-input-shake"
                        : ""
                    }
                    width={192}
                    height={32}
                    placeholder="Mobile"
                    onBlur={e => onBlurOfMobileInput(e)}
                    onChange={e => onChangeOfMobileInput(e)}
                    isInvalid={isMobileInvalid}
                    value={memberMobileInputValue}
                  />
                </div>
              </div>
              {isMobileRequiredMsg && (
                <div className="UMDDC-A1-invalid-mobile-num-msg-container">
                  <InlineAlert intent="danger">
                    <Text size={300}>
                      <font color="#bf0e08">Mobile is required</font>
                    </Text>
                  </InlineAlert>
                </div>
              )}
              {showInvalidMobileDiv && (
                <div className="UMDDC-A1-invalid-mobile-num-msg-container">
                  <InlineAlert intent="danger">
                    <Text size={300}>
                      <font color="#bf0e08">Invalid mobile number</font>
                    </Text>
                  </InlineAlert>
                </div>
              )}
            </div>
            <div className="UMDDC-A1-col-elements-container">
              <div className="UMDDC-A1-row-elements-container">
                <div className="UMDDC-A1-name-input-container UMDDC-A1-input-container">
                  <TextInputField
                    className={
                      shakeOnInvalid &&
                      (memberName.length <= 0 || isNameInvalid)
                        ? "invalid-input-shake"
                        : ""
                    }
                    label={nameFieldLabel}
                    width={336}
                    height={32}
                    placeholder="Full Name"
                    onChange={e => onChangeOfNameInput(e)}
                    isInvalid={isNameInvalid}
                    validationMessage={
                      isNameInvalidMsg ? "Name is required" : false
                    }
                    value={memberName}
                  />
                </div>
              </div>
            </div>
            <div className="UMDDC-A1-col-elements-container">
              <div className="UMDDC-A1-row-elements-container">
                <div className="UMDDC-A1-email-input-container UMDDC-A1-input-container">
                  <TextInputField
                    className={
                      shakeOnInvalid && isEmailInvalid
                        ? "invalid-input-shake"
                        : ""
                    }
                    label="Email"
                    width={336}
                    height={32}
                    placeholder="Email Address"
                    onChange={e => onChangeOfEmailInput(e)}
                    onBlur={e => validateEmail(e)}
                    isInvalid={isEmailInvalid}
                    validationMessage={
                      isEmailInvalidMsg ? "Invalid email" : false
                    }
                    value={memberEmail}
                  />
                </div>
              </div>
            </div>
            <div className="UMDDC-A1-col-elements-container">
              <div className="UMDDC-A1-label-container">
                <Text id="UMDDC-A1-large-label">Address</Text>
              </div>
              <div className="UMDDC-A1-row-elements-container">
                <div className="UMDDC-A1-address-input-container UMDDC-A1-input-container">
                  <Textarea
                    label="Address"
                    width={336}
                    height={80}
                    placeholder="Address (Optional)"
                    maxLength="150"
                    onChange={e => onChangeOfAddressInput(e)}
                    value={memberAddress}
                  />
                </div>
              </div>
            </div>
            <div className="UMDDC-A1-col-elements-container">
              <div className="UMDDC-A1-label-container">
                <Text id="UMDDC-A1-large-label">Gender</Text>
              </div>
              <div
                className="UMDDC-A1-row-elements-container"
                aria-label="Radio Group Label 16"
                role="group"
              >
                <Radio
                  className="UMDDC-A1-radio-input"
                  width={60}
                  marginRight={16}
                  size={16}
                  name="group4"
                  label="Male"
                  checked={checkedGenderValue === "M" ? true : false}
                  onChange={e => onChangeOfGenderRadioInput(e, "M")}
                />
                <Radio
                  className="UMDDC-A1-radio-input"
                  width={66}
                  marginRight={16}
                  size={16}
                  name="group4"
                  label="Female"
                  checked={checkedGenderValue === "F" ? true : false}
                  onChange={e => onChangeOfGenderRadioInput(e, "F")}
                />
                <Radio
                  className="UMDDC-A1-radio-input"
                  width={66}
                  marginRight={16}
                  size={16}
                  name="group4"
                  label="Other"
                  checked={checkedGenderValue === "O" ? true : false}
                  onChange={e => onChangeOfGenderRadioInput(e, "O")}
                />
              </div>
            </div>

            <div className="UMDDC-A1-col-elements-container">
              <div className="UMDDC-A1-label-container">
                <Text id="UMDDC-A1-large-label">Member Expiry</Text>
              </div>
              <div
                className="UMDDC-A1-row-elements-container"
                aria-label="Radio Group Label 16"
                role="group"
              >
                <Radio
                  className="UMDDC-A1-radio-input"
                  marginRight={164}
                  size={16}
                  name="group6"
                  label="Date"
                  checked={
                    checkedMemberExpiryRadioValue === "date" ? true : false
                  }
                  onChange={e => onChangeOfMemberExpiryRadioInput(e, "date")}
                />
                <Radio
                  className="UMDDC-A1-radio-input"
                  marginRight={16}
                  size={16}
                  name="group6"
                  label="Lifetime"
                  checked={
                    checkedMemberExpiryRadioValue === "lifetime" ? true : false
                  }
                  onChange={e =>
                    onChangeOfMemberExpiryRadioInput(e, "lifetime")
                  }
                />
              </div>
              {checkedMemberExpiryRadioValue === "date" && (
                <div className="UMDDC-A1-row-elements-container UMDDC-A1-date-picker-row">
                  <CustomizedDatePicker
                    date={
                      memberExpiryDate === "9999-12-31"
                        ? new Date()
                        : new Date(memberExpiryDate)
                    }
                    handleDateChange={handleMemberExpiryDateChange}
                  />
                </div>
              )}
            </div>
          </div>
          {/* <div className="UMDDC-A1-footer-content">
            <Button className="button" width={72} height={32} marginRight={12}>
              Cancel
            </Button>
            <Button
              className="button"
              width={104}
              height={32}
              appearance="primary"
            >
              Edit
            </Button>
          </div> */}
        </div>
      </React.Fragment>
    );
  }
}

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default UpdateMemberDetailsDialogContent;
