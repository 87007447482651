const typeOneDetails = (state = null, action) => {
    if (action.type === "SET_TYPE_ONE_DETAILS") {
      return action.payload;
    } else {
      return state;
    }
    // switch (action.type) {
    //   case "SET_SLOTS_LIST": {
    //     return action.payload;
    //   }
    
    //   default: {
    //     return state;
    //   }
    // }
  };
  
  export default typeOneDetails ;
  