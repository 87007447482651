// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import { connect } from "react-redux";
import {
  TextInputField,
  toaster,
  Dialog,
  EyeOpenIcon,
  EyeOffIcon,
} from "evergreen-ui";
import { changeUserPassword } from "../../actions";
import { resourcesNotLoadedToast } from "../../utils";

// -----------------------------------------------------------------------
// ChangePasswordDialogContent Component
// -----------------------------------------------------------------------
class ChangePasswordDialogContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      oldPassword: "",
      isOldPasswordInvalid: false,
      showOldPasswordInvalidMsg: false,
      oldPasswordInvalidMsg: "",
      newPassword: "",
      isNewPasswordInvalid: false,
      showNewPasswordInvalidMsg: false,
      newPasswordInvalidMsg: "",
      confirmPassword: "",
      isConfirmPasswordInvalid: false,
      showConfirmPasswordInvalidMsg: false,
      confirmPasswordInvalidMsg: "",
      oldPasswordEye: false,
      newPasswordEye: false,
    };
  }

  // -----------------------------------------------------------------------
  // onChangeOfInputFields()
  // -----------------------------------------------------------------------
  onChangeOfInputFields = async (e, type) => {
    e.persist();
    if (type === "old-password") {
      if (e.target.value.length > 0) {
        await this.setState({
          oldPassword: e.target.value.toString(),
          isOldPasswordInvalid: false,
          showOldPasswordInvalidMsg: false,
        });
      } else {
        await this.setState({
          oldPassword: e.target.value.toString(),
          isOldPasswordInvalid: true,
          oldPasswordInvalidMsg: "Old password is required",
          showOldPasswordInvalidMsg: true,
        });
      }
    } else if (type === "new-password") {
      if (e.target.value.length > 0) {
        await this.setState({
          newPassword: e.target.value.toString(),
          isNewPasswordInvalid: false,
          showNewPasswordInvalidMsg: false,
          isConfirmPasswordInvalid: false,
          showConfirmPasswordInvalidMsg: false,
        });
      } else {
        await this.setState({
          newPassword: e.target.value.toString(),
          isNewPasswordInvalid: true,
          newPasswordInvalidMsg: "New Password is required",
          showNewPasswordInvalidMsg: true,
          isConfirmPasswordInvalid: false,
          showConfirmPasswordInvalidMsg: false,
        });
      }
    } else if (type === "confirm-password") {
      if (e.target.value.length > 0) {
        await this.setState({
          confirmPassword: e.target.value.toString(),
          isConfirmPasswordInvalid: false,
          showConfirmPasswordInvalidMsg: false,
        });
      } else {
        await this.setState({
          confirmPassword: e.target.value.toString(),
        });
      }
    }
  };

  // -----------------------------------------------------------------------
  // confirmChangePassword()
  // -----------------------------------------------------------------------
  confirmChangePassword = async () => {
    if (
      this.state.oldPassword !== "" &&
      this.state.newPassword !== "" &&
      this.state.confirmPassword !== "" &&
      this.state.newPassword === this.state.confirmPassword
    ) {
      let reqBody = {
        old_password: this.state.oldPassword,
        new_password: this.state.newPassword,
      };
      await this.props.dispatch(changeUserPassword(reqBody));
      if (this.props.changeUserPasswordToast) {
        if (this.props.changeUserPasswordToast.resourcesNotLoadedMessage) {
          resourcesNotLoadedToast();
        } else if (this.props.changeUserPasswordToast.httpErrorMessage) {
          toaster.danger(this.props.changeUserPasswordToast.httpErrorMessage, {
            id: "HTTP-ERROR-TOAST-5",
          });
        } else if (this.props.changeUserPasswordToast.requestStatus === -1) {
          toaster.notify("Sorry, you don't have permission to do that");
        } else if (this.props.changeUserPasswordToast.requestStatus === 1) {
          await this.props.handleChangePasswordDialogVisibility("close");
          toaster.success(this.props.changeUserPasswordToast.message, {
            id: "HTTP-ERROR-TOAST-5",
          });
        } else if (this.props.changeUserPasswordToast.requestStatus === 0) {
          if (
            this.props.changeUserPasswordToast.message ===
            "the old password does not match"
          ) {
            await this.setState({
              isOldPasswordInvalid: true,
              oldPasswordInvalidMsg: "Old password does not match",
              showOldPasswordInvalidMsg: true,
            });
          } else {
            toaster.danger(this.props.changeUserPasswordToast.message, {
              id: "HTTP-ERROR-TOAST-5",
            });
          }
        }
      }
    } else {
      if (this.state.oldPassword === "") {
        await this.setState({
          isOldPasswordInvalid: true,
          oldPasswordInvalidMsg: "Old password is required",
          showOldPasswordInvalidMsg: true,
        });
      }
      if (this.state.newPassword === "") {
        await this.setState({
          isNewPasswordInvalid: true,
          newPasswordInvalidMsg: "New Password is required",
          showNewPasswordInvalidMsg: true,
        });
      }
      if (this.state.newPassword !== "" && this.state.confirmPassword === "") {
        await this.setState({
          isConfirmPasswordInvalid: true,
          confirmPasswordInvalidMsg: "Please enter password again",
          showConfirmPasswordInvalidMsg: true,
        });
      }
      if (
        this.state.newPassword.length > 0 &&
        this.state.confirmPassword.length > 0 &&
        this.state.newPassword !== this.state.confirmPassword
      ) {
        await this.setState({
          isConfirmPasswordInvalid: true,
          confirmPasswordInvalidMsg: "Passwords do not match",
          showConfirmPasswordInvalidMsg: true,
        });
      }
    }
  };

  // -----------------------------------------------------------------------
  // changePasswordEye()
  // -----------------------------------------------------------------------
  changePasswordEye = async (type) => {
    if (type === "old-password") {
      await this.setState({ oldPasswordEye: !this.state.oldPasswordEye });
    } else if (type === "new-password") {
      await this.setState({ newPasswordEye: !this.state.newPasswordEye });
    }
  };

  // -----------------------------------------------------------------------
  // render()
  // -----------------------------------------------------------------------
  render() {
    const {
      oldPassword,
      isOldPasswordInvalid,
      showOldPasswordInvalidMsg,
      oldPasswordInvalidMsg,
      newPassword,
      isNewPasswordInvalid,
      showNewPasswordInvalidMsg,
      newPasswordInvalidMsg,
      confirmPassword,
      isConfirmPasswordInvalid,
      showConfirmPasswordInvalidMsg,
      confirmPasswordInvalidMsg,
      oldPasswordEye,
      newPasswordEye,
    } = this.state;
    const { handleChangePasswordDialogVisibility, showChangePasswordDialog } =
      this.props;
    return (
      <React.Fragment>
        <Dialog
          width="auto"
          isShown={showChangePasswordDialog}
          title="Change Password"
          confirmLabel="Change Password"
          onConfirm={(e) => this.confirmChangePassword(e)}
          onCloseComplete={() => handleChangePasswordDialogVisibility("close")}
        >
          <div className="CPDC-A1-wrapper">
            <div className="CPDC-A1-row-container CPDC-A1-margin-bottom-24">
              <div className="CPDC-A1-input-field-container">
                <TextInputField
                  minWidth={300}
                  height={32}
                  marginRight={8}
                  label="Old Password"
                  placeholder="Old Password"
                  type={oldPasswordEye ? "text" : "password"}
                  autocomplete="new-password"
                  value={oldPassword}
                  isInvalid={isOldPasswordInvalid}
                  validationMessage={
                    showOldPasswordInvalidMsg && oldPasswordInvalidMsg
                  }
                  onChange={(e) =>
                    this.onChangeOfInputFields(e, "old-password")
                  }
                />
              </div>
              <div
                className="CPDC-A1-eye-icon-container"
                onClick={() => this.changePasswordEye("old-password")}
              >
                {/* <Icon
                  icon={oldPasswordEye ? "eye-open" : "eye-off"}
                  color="muted"
                /> */}
                {oldPasswordEye ? (
                  <EyeOpenIcon color="muted" />
                ) : (
                  <EyeOffIcon color="muted" />
                )}
              </div>
            </div>
            <div className="CPDC-A1-row-container CPDC-A1-margin-bottom-32">
              <div className="CPDC-A1-input-field-container">
                <TextInputField
                  minWidth={300}
                  height={32}
                  marginRight={24}
                  label="New Password"
                  placeholder="New Password"
                  type={newPasswordEye ? "text" : "password"}
                  autocomplete="new-password"
                  value={newPassword}
                  isInvalid={isNewPasswordInvalid}
                  validationMessage={
                    showNewPasswordInvalidMsg && newPasswordInvalidMsg
                  }
                  onChange={(e) =>
                    this.onChangeOfInputFields(e, "new-password")
                  }
                />
              </div>
              <div className="CPDC-A1-row-container">
                <div className="CPDC-A1-input-field-container">
                  <TextInputField
                    minWidth={300}
                    height={32}
                    marginRight={8}
                    label="Confirm Password"
                    placeholder="Re-enter Password"
                    type={newPasswordEye ? "text" : "password"}
                    autocomplete="new-password"
                    value={confirmPassword}
                    isInvalid={isConfirmPasswordInvalid}
                    validationMessage={
                      showConfirmPasswordInvalidMsg && confirmPasswordInvalidMsg
                    }
                    onChange={(e) =>
                      this.onChangeOfInputFields(e, "confirm-password")
                    }
                  />
                </div>
                <div
                  className="CPDC-A1-eye-icon-container"
                  onClick={() => this.changePasswordEye("new-password")}
                >
                  {/* <Icon
                    icon={newPasswordEye ? "eye-open" : "eye-off"}
                    color="muted"
                  /> */}

                  {newPasswordEye ? (
                    <EyeOpenIcon color="muted" />
                  ) : (
                    <EyeOffIcon color="muted" />
                  )}
                </div>
              </div>
            </div>
          </div>
        </Dialog>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ changeUserPasswordToast }) => ({
  changeUserPasswordToast,
});

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default connect(mapStateToProps)(ChangePasswordDialogContent);
