import {
  DollarIcon,
  Heading,
  Pane,
  Select,
  Spinner,
  Text,
  toaster,
} from "evergreen-ui";
import React, { Component } from "react";
import { generateCurrencySymbol, getDashboardFormatedData } from "../../utils";
import { connect } from "react-redux";
import DoughnutChart from "../../components/Dashboard/DoughnutChart";
import LineChart from "../../components/Dashboard/LineChart";
import StatsCard from "../../components/Dashboard/StatsCard";
import DashboardBottomStats from "../../components/Dashboard/DashboardBottomStats";
import { getDashboardData } from "../../actions";
import DashboardDaterange from "../../components/Dashboard/DashboardDateRange";
import { toggleLoaderOff, toggleLoaderOn } from "../../actions/loaderUtils";
import moment from "moment";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currency: generateCurrencySymbol(),
      dashboardData: {},
      labels: {},
      graphValue: [],
      bookingGtv: [],
      bookingCount: [],
      uniqueUsers: {},
      slotsUtilisation: {},
      defaultActivityName: "All Sports",
      defaultActivityValue: "select-a-sport",
      selectedActivityValue: "select-a-sport",
      selectedActivityId: "-1",
      selectedPeriod: "This Week",
      selectedPeriodValue: 3,
      startDate: "From Date",
      endDate: "To Date",
    };
  }

  componentDidMount() {
    this.fillDashData();
  }

  fillDashData = () => {
    let dispatch = this.props.dispatch;

    let { selectedPeriodValue, endDate, startDate, selectedActivityId } =
      this.state;
    console.log({
      selectedPeriodValue,
      endDate,
      startDate,
      selectedActivityId,
    });
    if (isNaN(selectedActivityId)) {
      selectedActivityId = -1;
      this.setState({
        selectedActivityId: -1,
      });
    }
    console.log({ selectedActivityId });
    let data = {
      period: selectedPeriodValue,
      activityId: selectedActivityId,
      fromDate: startDate,
      toDate: endDate,
    };
    toggleLoaderOn(dispatch);
    getDashboardData(data).then((res) => {
      console.log({ res });
      toggleLoaderOff(dispatch);
      if (res.requestStatus === 1) {
        getDashboardFormatedData(res).then((response) => {
          console.log({ response });
          this.setState({
            dashboardData: res,
            labels: response.labels,
            graphValue: response.graphValue,
            bookingGtv: response.bookingGtvData,
            bookingCount: response.bookingCountData,
            uniqueUsers: res.dashboardDetails.uniqueUsers,
            slotsUtilisation: res.dashboardDetails.slotsUtilisation,
          });
        });
      } else if (res.requestStatus === -1) {
        this.props.handleSidebar("", "calendar");
        this.props.hideSidePanelItem();
      } else {
        toaster.warning(res.message);
      }
    });
  };

  handlePeriodChange = async (data) => {
    this.setState(
      {
        selectedPeriod: data.title,
        selectedPeriodValue: data.value,
      },
      function () {
        this.fillDashData();
      }
    );
  };

  setPeriod = (title) => {
    this.setState({
      selectedPeriod: title,
    });
  };

  handleActivityChange = async (e) => {
    this.setState(
      {
        selectedActivityValue: e.target.value,
        selectedActivityId: parseInt(e.target.value),
        selectedActivityName:
          e.nativeEvent.target[
            e.nativeEvent.target.selectedIndex
          ].text.toString(),
      },
      function () {
        this.fillDashData();
      }
    );
  };

  handleDateChange = async (startDate, endDate, apply) => {
    console.log({ startDate, endDate });

    let startDateLabel = moment(startDate).format("YYYY-MM-DD");
    let endDateLabel = moment(endDate).format("YYYY-MM-DD");
    if (startDateLabel === "Invalid date" || endDateLabel === "Invalid date") {
      toaster.warning("Please select a valid date");
    } else {
      this.setState(
        {
          startDate: startDateLabel,
          endDate: endDateLabel,
          selectedPeriodValue: "-1",
        },
        function () {
          this.fillDashData();
        }
      );
    }
  };

  render() {
    let {
      currency,
      dashboardData,
      labels,
      graphValue,
      bookingGtv,
      bookingCount,
      uniqueUsers,
      slotsUtilisation,
      defaultActivityName,
      defaultActivityValue,
      selectedActivityValue,
      selectedPeriod,
      selectedPeriodValue,
      startDate,
      endDate,
    } = this.state;
    let { clubActivityData } = this.props;
    console.log({ clubActivityData });
    const options = {
      responsive: true,
      legend: {
        display: false,
      },
      scales: {
        yAxes: [
          {
            gridLines: {
              zeroLineColor: "rgba(113, 121, 113, 0.08)",
              color: "rgba(113, 121, 113, 0.08)",
            },
            ticks: {
              fontSize: 10,
              fontColor: "#758A80",
              beginAtZero: true,
              callback: function (value, index, values) {
                if (parseInt(value) >= 1000) {
                  return (
                    currency +
                    " " +
                    value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  );
                } else {
                  return currency + "  " + value;
                }
              },
            },
          },
        ],
        xAxes: [
          {
            gridLines: {
              zeroLineColor: "rgba(113, 121, 113, 0.08)",
              color: "rgba(113, 121, 113, 0.08)",
            },
            ticks: {
              fontSize: 10,
              fontColor: "#758A80",
            },
          },
        ],
      },
    };

    // scales: {
    //   yAxes: [
    //     {
    //       ticks: {
    //         beginAtZero: true,

    //         callback: function (value, index, values) {
    //           if (parseInt(value) >= 1000) {
    //             return (
    //               currency +
    //               value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    //             );
    //           } else {
    //             return currency + " " + value;
    //           }
    //         },
    //       },
    //     },
    //   ],
    // },

    const data = {
      labels: labels,
      datasets: [
        {
          tension: 0.3,
          borderColor: "rgb(255, 99, 132)",
          data: graphValue,
          fill: true,
          borderColor: "#0F4D88",
          pointRadius: 4,
          backgroundColor: (context) => {
            const ctx = context.chart.ctx;
            const gradient = ctx.createLinearGradient(0, 0, 0, 190);
            gradient.addColorStop(0, "rgba(15, 77, 136, 0.3)");
            gradient.addColorStop(1, "rgba(15, 77, 136, 0)");
            return gradient;
          },
          pointBackgroundColor: "#0F4D88",
          pointBorderColor: "#0F4D88",
          pointHoverBackgroundColor: "white",
          pointHoverBorderColor: "#0F4D88",
          borderWidth: 2,
        },
      ],
    };
    return dashboardData?.requestStatus === 1 ? (
      <Pane
        display={"flex"}
        width={"100%"}
        paddingLeft={"5%"}
        paddingRight={"5%"}
        paddingTop={"2%"}
        flexDirection={"column"}
        marginBottom="40px"
      >
        <Pane
          display={"flex"}
          flexDirection={"row"}
          width={"100%"}
          marginTop={"20px"}
          marginBottom={"20px"}
          justifyContent={"space-between"}
        >
          <Pane
            display={"flex"}
            flexDirection={"row"}
            margin={"auto"}
            width="60%"
            alignItems={"start"}
            justifyContent={"start"}
          >
            <Heading size={900}>Home</Heading>
          </Pane>
          <Pane
            display={"flex"}
            flexDirection={"row"}
            margin={"auto"}
            width="40%"
            alignItems={"end"}
            justifyContent={"space-between"}
          >
            <Pane maWidth="50%" position={"relative"}>
              <Select
                // label="sport"
                size="400"
                height={40}
                onChange={(e) => this.handleActivityChange(e)}
                value={selectedActivityValue}
                // disabled={disabled}
              >
                <option value={defaultActivityValue}>
                  {defaultActivityName}
                </option>
                {clubActivityData.activityDetails.map((activity, index) => (
                  <option key={index} value={activity.activityId}>
                    {clubActivityData.activities[activity.activityId]}
                  </option>
                ))}
              </Select>
            </Pane>
            <Pane marginLeft={20} width="50%" position={"relative"}>
              <DashboardDaterange
                handlePeriodChange={this.handlePeriodChange}
                selectedPeriod={selectedPeriod}
                selectedPeriodValue={selectedPeriodValue}
                handleDateChange={this.handleDateChange}
                startDate={startDate}
                endDate={endDate}
                setPeriod={this.setPeriod}
              />
            </Pane>
          </Pane>
        </Pane>

        {/*  ------------         Line Graph  ------------------  */}

        <Pane
          marginTop={"20px"}
          background="gray300"
          borderRadius={"10px"}
          width={"100%"}
          padding={"24px"}
          display={"flex"}
          flexDirection="column"
        >
          <Pane display={"flex"} alignItems="center" flexDirection={"row"}>
            <DollarIcon color="selected" size={24} />
            <Text marginLeft={"5px"}>Gross Transaction Value (GTV)</Text>
          </Pane>

          <Pane
            display="flex"
            flexDirection="row"
            alignItems="start"
            justifyContent="space-between"
            marginTop={"20px"}
          >
            {/*  ------------         Line Chart  ------------------  */}
            <LineChart options={options} data={data} />
            {/*  ------------         Line Graph  ------------------  */}

            <Pane
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="space-between"
              width={"28%"}
              height="392px"
            >
              {bookingGtv.map((data) => {
                return <StatsCard data={data} currency={currency} />;
              })}
            </Pane>
          </Pane>
        </Pane>
        <Pane
          justifyContent="space-between"
          flexDirection={"row"}
          display={"flex"}
          marginTop={"20px"}
        >
          {bookingCount.map((data) => {
            return <DoughnutChart chartData={data} currency={currency} />;
          })}
        </Pane>
        <DashboardBottomStats
          uniqueUsers={uniqueUsers}
          slotUtilisation={slotsUtilisation}
          handleSidebar={this.props.handleSidebar}
        />
      </Pane>
    ) : (
      <Pane>
        <Spinner size={32} /> loading...
      </Pane>
    );
  }
}

const mapStateToProps = ({ clubActivityData }) => ({ clubActivityData });

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default connect(mapStateToProps)(Dashboard);
