// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import { connect } from "react-redux";
import { Text, Pane, IconButton, Heading, ChevronLeftIcon } from "evergreen-ui";

import TableBody from "./tableBody";

// -----------------------------------------------------------------------
// LoginPage Component
// -----------------------------------------------------------------------
class ManagePermission extends React.Component {
  constructor(props) {
    super(props);
  }

  // -----------------------------------------------------------------------
  // ComponentDimMount()
  // -----------------------------------------------------------------------
  componentDidMount() {}

  render() {
    return (
      <React.Fragment>
        <Pane
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"flex-start"}
          alignItems={"flex-start"}
        >
          <Pane padding={10}>Permission</Pane>
          <Pane
            display={"flex"}
            flexDirection={"column"}
            background="tint2"
            padding={15}
            marginBottom={15}
            width="100%"
            justifyContent={"flext-start"}
          >
            <Pane display={"flex"} flexDirection={"row"}>
              <IconButton
                appearance="minimal"
                icon={<ChevronLeftIcon />}
                iconSize={16}
                marginRight={12}
                onClick={() => this.props.showManagePermission(false)}
              />
              <Heading marginLeft={0} marginTop={7} size={500}>
                {this.props.staffMember.username}
              </Heading>
            </Pane>
            <Pane display={"flex"} marginLeft={41} flexDirection={"row"}>
              <Text marginTop={15} color="muted" size={100}>
                {this.props.staffMember.role}
              </Text>
            </Pane>
          </Pane>

          <Pane padding={25} width="100%" marginBottom={30}>
            <TableBody
              handleSidebar={this.props.handleSidebar}
              staffMember={this.props.staffMember}
            />
          </Pane>
        </Pane>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ authStatus, loginDetails }) => ({
  authStatus,
  loginDetails,
});

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default connect(mapStateToProps)(ManagePermission);
