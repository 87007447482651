// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import Drawer from "@material-ui/core/Drawer";
import styled from "styled-components";
import { CrossIcon, IconButton, Pane, Strong } from "evergreen-ui";
import CartOnCalendarPage from "../../pages/HomePage/CartOnCalendarPage";
import BulkBookingDetailsOnSearchPage from "../../pages/Search/BulkBookingDetailsOnSearchPage";
import MemberDetailsOnMemberPage from "../../pages/Members/MemberDetailsOnMemberPage";
import RegularBookingDetailsDrawerContent from "../RegularBookingDetailsDrawerContent";

// -----------------------------------------------------------------------
// Styled Components
// -----------------------------------------------------------------------
const DrawerDiv = styled.div`
  position: relative;
  width: 400px;

  background-color: #f5f6f7;

  .close-btn-cart-top-label-container {
    width: 400px;
    height: 72px;
    margin-top: 56px;
    box-shadow: inset 0 -1px 0 0 #edf0f2;
    background-color: #ffffff;
    display: flex;
    align-items: center;
  }

  .cart-top-label-container {
    height: 32px;
    display: flex;
    align-items: center;
  }

  #cart-top-label {
    width: fit-content;
    height: 24px;
    font-size: 20px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2;
    letter-spacing: -0.1px;
    color: #234361;
    text-align: center;
  }
`;

// -----------------------------------------------------------------------
// DrawerHolder Component
// -----------------------------------------------------------------------
class DrawerHolder extends React.Component {
  // -----------------------------------------------------------------------
  //  render
  // -----------------------------------------------------------------------
  render() {
    const {
      showOnDrawer,
      cartData,
      // bookingDetails,
      // individualBulkBookingSearchResult,
      openDrawer,
      handleDrawer,
      handleClearCart,
      onRemoveFromCart,
      selectedActivityName,
      handleBookingCheckoutDisplay,
      handleSeeAllBookings,
      clubId,
      pageNumOnMembersPage,
      individualMemberDetails,
      handleSeeBookingsFromMembersDrawer,
      memberMobileInputValue,
      isMobileInvalid,
      showInvalidMobileDiv,
      isNameInvalid,
      isNameInvalidMsg,
      isMobileRequiredMsg,
      memberName,
      isEmailInvalid,
      isEmailInvalidMsg,
      memberEmail,
      memberAddress,
      checkedGenderValue,
      memberExpiryDate,
      onChangeOfMobileInput,
      onBlurOfMobileInput,
      onChangeOfNameInput,
      onChangeOfEmailInput,
      validateEmail,
      shakeOnInvalid,
      onCountryChange,
      onChangeOfAddressInput,
      onChangeOfGenderRadioInput,
      showUpdateMemberDetailsDialogOnMemberDetailsOnDrawerPage,
      toggleUpdateMemberDetailsDialogVisibility,
      checkedMemberExpiryRadioValue,
      onChangeOfMemberExpiryRadioInput,
      handleUpdateMemberDetails,
      handleMemberExpiryDateChange,
      updateCalendarPage,
      updateSearchPage,
      handleMembershipPackagesCheckoutPageDisplay,
      updateBookingDetails,
      updateIndividualBulkBookingDetails,
      handleSeeAllBookingsfromBulkBookingDetailsDrawer,
      handleGetBookingDetails,
      handleShowAvailability,
      getPatternBookingDetailsForDrawer,
    } = this.props;

    return (
      <React.Fragment>
        <Drawer
          className="drawer-holder-div"
          variant="persistent"
          PaperProps={{ elevation: 10, square: false }}
          anchor="right"
          open={openDrawer}
          style={{ display: openDrawer ? "initial" : "none" }}
        >
          <DrawerDiv>
            <Pane display={"flex"} flexDirection="row" marginTop={20}>
              <IconButton
                size={50}
                marginTop={"7px"}
                marginLeft={8}
                marginRight={8}
                appearance="minimal"
                icon={<CrossIcon />}
                onClick={() => handleDrawer("close")}
              />
              <Strong color="muted" size={500} marginTop={8}>
                {showOnDrawer === "cartDetailsOnCalendarPage" &&
                  "Booking Summary"}
                {showOnDrawer === "regularBookingDetailsOnDrawer" &&
                  "Booking Details"}
                {(showOnDrawer === "bulkBookingDetailsOnSearchPage" ||
                  showOnDrawer === "bulkBookingDetailsOnCalendarPage") &&
                  "Booking Details"}
                {showOnDrawer === "memberDetailsOnMembersPage" &&
                  "Member Details"}
              </Strong>
            </Pane>
            {showOnDrawer === "cartDetailsOnCalendarPage" && (
              <CartOnCalendarPage
                cartData={cartData}
                handleClearCart={handleClearCart}
                onRemoveFromCart={onRemoveFromCart}
                selectedActivityName={selectedActivityName}
                handleBookingCheckoutDisplay={handleBookingCheckoutDisplay}
              />
            )}
            {showOnDrawer === "regularBookingDetailsOnDrawer" && (
              <RegularBookingDetailsDrawerContent
                // bookingDetails={bookingDetails}
                handleDrawer={handleDrawer}
                updateCalendarPage={updateCalendarPage}
                updateSearchPage={updateSearchPage}
                updateBookingDetails={updateBookingDetails}
                handleGetBookingDetails={handleGetBookingDetails}
                handleShowAvailability={handleShowAvailability}
              />
            )}
            {(showOnDrawer === "bulkBookingDetailsOnSearchPage" ||
              showOnDrawer === "bulkBookingDetailsOnCalendarPage") && (
              <BulkBookingDetailsOnSearchPage
                // individualBulkBookingSearchResult={
                //   individualBulkBookingSearchResult
                // }

                handleSeeAllBookings={handleSeeAllBookings}
                handleDrawer={handleDrawer}
                updateSearchPage={updateSearchPage}
                updateIndividualBulkBookingDetails={
                  updateIndividualBulkBookingDetails
                }
                handleSeeAllBookingsfromBulkBookingDetailsDrawer={
                  handleSeeAllBookingsfromBulkBookingDetailsDrawer
                }
                getPatternBookingDetailsForDrawer={
                  getPatternBookingDetailsForDrawer
                }
              />
            )}
            {showOnDrawer === "memberDetailsOnMembersPage" && (
              <MemberDetailsOnMemberPage
                clubId={clubId}
                pageNumOnMembersPage={pageNumOnMembersPage}
                individualMemberDetails={individualMemberDetails}
                handleDrawer={handleDrawer}
                handleSeeBookingsFromMembersDrawer={
                  handleSeeBookingsFromMembersDrawer
                }
                memberMobileInputValue={memberMobileInputValue}
                isMobileInvalid={isMobileInvalid}
                showInvalidMobileDiv={showInvalidMobileDiv}
                isNameInvalid={isNameInvalid}
                isNameInvalidMsg={isNameInvalidMsg}
                isMobileRequiredMsg={isMobileRequiredMsg}
                memberName={memberName}
                isEmailInvalid={isEmailInvalid}
                isEmailInvalidMsg={isEmailInvalidMsg}
                memberEmail={memberEmail}
                checkedGenderValue={checkedGenderValue}
                onChangeOfMobileInput={onChangeOfMobileInput}
                onBlurOfMobileInput={onBlurOfMobileInput}
                onChangeOfNameInput={onChangeOfNameInput}
                onChangeOfEmailInput={onChangeOfEmailInput}
                validateEmail={validateEmail}
                onChangeOfGenderRadioInput={onChangeOfGenderRadioInput}
                shakeOnInvalid={shakeOnInvalid}
                onCountryChange={onCountryChange}
                onChangeOfAddressInput={onChangeOfAddressInput}
                checkedMemberExpiryRadioValue={checkedMemberExpiryRadioValue}
                memberAddress={memberAddress}
                memberExpiryDate={memberExpiryDate}
                onChangeOfMemberExpiryRadioInput={
                  onChangeOfMemberExpiryRadioInput
                }
                handleMemberExpiryDateChange={handleMemberExpiryDateChange}
                showUpdateMemberDetailsDialogOnMemberDetailsOnDrawerPage={
                  showUpdateMemberDetailsDialogOnMemberDetailsOnDrawerPage
                }
                toggleUpdateMemberDetailsDialogVisibility={
                  toggleUpdateMemberDetailsDialogVisibility
                }
                handleUpdateMemberDetails={handleUpdateMemberDetails}
                handleMembershipPackagesCheckoutPageDisplay={
                  handleMembershipPackagesCheckoutPageDisplay
                }
              />
            )}
          </DrawerDiv>
        </Drawer>
      </React.Fragment>
    );
  }
}

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default DrawerHolder;
