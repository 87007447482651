import clubActivityData from "./clubActivityData";
import slotsList from "./slotsList";
import cartData from "./cartData";
import cartToast from "./cartToast";
import slotsToast from "./slotsToast";
import countryCodes from "./countryCodes";
import customerDetails from "./customerDetails";
import clubExtrasList from "./clubExtrasList";
import courtList from "./courtList";
import slotsAvailabilityData from "./slotsAvailabilityData";
import slotsAvailabilityToast from "./slotsAvailabilityToast";
import showLoader from "./showLoader";
import blockingToast from "./blockingToast";
import unblockingToast from "./unblockingToast";
import redeemCreditsToast from "./redeemCreditsToast";
import bookingToast from "./bookingToast";
import bookingDetails from "./bookingDetails";
import bookingDetailsOnHover from "./bookingDetailsOnHover";
import regularBookingSearchResult from "./regularBookingSearchResult";
import bulkBookingSearchResult from "./bulkBookingSearchResult";
import bulkBookingInformation from "./bulkBookingInformation";
import blockingSearchResult from "./blockingSearchResult";
import updateBookingRemarksToast from "./updateBookingRemarksToast";
import updateBookingPaymentToast from "./updateBookingPaymentToast";
import transactionsDetails from "./transactionsDetails";
import cancelBookingToast from "./cancelBookingToast";
import memberDetails from "./memberDetails";
import countryDetails from "./countryDetails";
import registerMemberToast from "./registerMemberToast";
import individualMemberDetails from "./individualMemberDetails";
import individualBulkBookingSearchResult from "./individualBulkBookingSearchResult";
import updateMemberDetailsToast from "./updateMemberDetailsToast";
import createMemberPinToast from "./createMemberPinToast";
import updateMemberPinToast from "./updateMemberPinToast";
import rechargeMemberCreditsToast from "./rechargeMemberCreditsToast";
import updateMemberCreditsValidityToast from "./updateMemberCreditsValidityToast";
import verifyBookingRescheduleAvailabilityData from "./verifyBookingRescheduleAvailabilityData";
import bookingReschedulePricingData from "./bookingReschedulePricingData";
import confirmBookingRescheduleData from "./confirmBookingRescheduleData";
import membershipPackageDetails from "./membershipPackageDetails";
import createMembershipPackageToast from "./createMembershipPackageToast";
import selectedMembershipPackageDetails from "./selectedMembershipPackageDetails";
import subscribeMembershipPackageToast from "./subscribeMembershipPackageToast";
import membershipSubscriptionDetails from "./membershipSubscriptionDetails";
import generateCouponToast from "./generateCouponToast";
import membershipSubscriptionPaymentUpdateToast from "./membershipSubscriptionPaymentUpdateToast";
import membersSubscribedPackagesData from "./membersSubscribedPackagesData";
import updateMembershipPackageDetailsToast from "./updateMembershipPackageDetailsToast";
import membershipPackageDetailsForEditing from "./membershipPackageDetailsForEditing";
import priceClassDetails from "./priceClassDetails";
import pricingConfigDetails from "./pricingConfigDetails";
import pricingData from "./pricingData";
import savePricingDetailsToast from "./savePricingDetailsToast";
import clubHandlersList from "./clubHandlersList";
import reportsData from "./reportsData";
import authStatus from "./authStatus";
import loginDetails from "./loginDetails";
import logoutDetails from "./logoutDetails";
import patternBookingToast from "./patternBookingToast";
import individualMemberDetailsToast from "./individualMemberDetailsToast";
import courtListToast from "./courtListToast";
import countryDetailsToast from "./countryDetailsToast";
import downloadReportToast from "./downloadReportToast";
import transactionCodesAndTransactionTypes from "./transactionCodesAndTransactionTypes";
import configureHolidayToast from "./configureHolidayToast";
import holidaysData from "./holidaysData";
import removeHolidayToast from "./removeHolidayToast";
import premiumFeatureFlag from "./premiumFeatureFlag";
import generateReceiptToast from "./generateReceiptToast";
import createUserToast from "./createUserToast";
import changeUserPasswordToast from "./changeUserPasswordToast";
import generateBookingStatementToast from "./generateBookingStatementToast";
import escalationMatrixData from "./escalationMatrixData";
import defaultCountryDetails from "./defaultCountryDetails";
import defaultCountryDetailsToast from "./defaultCountryDetailsToast";
import switchAccountDetails from "./switchAccountDetails";
import couponDetails from "./couponDetails";
import couponCodes from "./couponCodes";
import editCouponToast from "./editCouponToast";
import staffMemberList from "./staffMemberList";
import userPermissions from "./userPermission";
import saveUserPermissions from "./saveUserPermission";
import userRemove from "./userRemove";
import featureList from "./featureList";
import typeOneDetails from "./typeOneDetails";
import updateExtraPaymentToast from "./updateExtraPaymentToast";
import configExtrasList from "./configExtrasList";
import configExtrasToast from "./configExtraToast";
import downloadExtrasToast from "./downloadExtra";
import enableReschedule from "./enableReschedule";
import markNoShow from "./markNoShow";
import userSummary from "./usersSummary";

const reducers = {
  clubActivityData,
  slotsList,
  cartData,
  cartToast,
  slotsToast,
  countryCodes,
  customerDetails,
  clubExtrasList,
  courtList,
  slotsAvailabilityData,
  slotsAvailabilityToast,
  showLoader,
  blockingToast,
  unblockingToast,
  redeemCreditsToast,
  bookingToast,
  bookingDetails,
  bookingDetailsOnHover,
  regularBookingSearchResult,
  bulkBookingSearchResult,
  bulkBookingInformation,
  blockingSearchResult,
  updateBookingRemarksToast,
  updateBookingPaymentToast,
  transactionsDetails,
  cancelBookingToast,
  memberDetails,
  countryDetails,
  registerMemberToast,
  individualMemberDetails,
  individualBulkBookingSearchResult,
  updateMemberDetailsToast,
  createMemberPinToast,
  updateMemberPinToast,
  rechargeMemberCreditsToast,
  updateMemberCreditsValidityToast,
  verifyBookingRescheduleAvailabilityData,
  bookingReschedulePricingData,
  confirmBookingRescheduleData,
  membershipPackageDetails,
  createMembershipPackageToast,
  selectedMembershipPackageDetails,
  subscribeMembershipPackageToast,
  membershipSubscriptionDetails,
  generateCouponToast,
  membershipSubscriptionPaymentUpdateToast,
  membersSubscribedPackagesData,
  updateMembershipPackageDetailsToast,
  membershipPackageDetailsForEditing,
  priceClassDetails,
  pricingConfigDetails,
  pricingData,
  savePricingDetailsToast,
  clubHandlersList,
  reportsData,
  authStatus,
  loginDetails,
  logoutDetails,
  patternBookingToast,
  individualMemberDetailsToast,
  courtListToast,
  countryDetailsToast,
  downloadReportToast,
  transactionCodesAndTransactionTypes,
  configureHolidayToast,
  holidaysData,
  removeHolidayToast,
  premiumFeatureFlag,
  generateReceiptToast,
  createUserToast,
  changeUserPasswordToast,
  generateBookingStatementToast,
  escalationMatrixData,
  defaultCountryDetails,
  defaultCountryDetailsToast,
  switchAccountDetails,
  couponDetails,
  couponCodes,
  editCouponToast,
  staffMemberList,
  userPermissions,
  saveUserPermissions,
  userRemove,
  featureList,
  typeOneDetails,
  updateExtraPaymentToast,
  configExtrasList,
  configExtrasToast,
  downloadExtrasToast,
  enableReschedule,
  markNoShow,
  userSummary,
};

export default reducers;
