// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import {
  Text,
  Button,
  Checkbox,
  toaster,
  Tablist,
  Tab,
  Textarea,
  Pane,
  InfoSignIcon,
  Tooltip,
} from "evergreen-ui";
import { connect } from "react-redux";
import "antd/dist/antd.css";
import { getCourtList, verifySlotsAvailability } from "../../actions";
import {
  toDateFormat1,
  toTimeFormat,
  resourcesNotLoadedToast,
  checkIfDateIsAfter,
  checkIfDatesAreSame,
  checkIfTimeIsSameOrAfter,
  toTimeFormat2,
} from "../../utils";
import AvailabilityDialog from "../../pages/BulkBooking/AvailabilityDialog";
import BlockSlotsDialog from "../../pages/Blocking/BlockSlotsDialog";
import UnblockSlotsDialog from "../../pages/Blocking/UnblockSlotsDialog";
import ClubActivityListSelect from "../../components/ClubActivityListSelect";
import CustomizedDatePicker from "../CustomizedDatePicker";
import CustomizedTimePicker from "../CustomizedTimePicker";
import CustomInlineAlert from "../../components/CustomInlineAlert";
import CountSelect from "../../components/CountSelect";

// -----------------------------------------------------------------------
// BulkForm Component
// -----------------------------------------------------------------------
class BulkForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedActivityId: null,
      courtCheckAll: false,
      dayCheckAll: false,
      timeCheckAll: false,
      memberBulkBookingCheck: false,
      fromDate: new Date(),
      toDate: new Date(),
      fromTime: new Date(),
      toTime: new Date(),
      defaultActivityName: "Select a sport",
      defaultActivityValue: "select-a-sport",
      selectedActivityValue: "select-a-sport",
      daysData: [
        { day: "Mon", id: 0, checked: false },
        { day: "Tue", id: 1, checked: false },
        { day: "Wed", id: 2, checked: false },
        { day: "Thu", id: 3, checked: false },
        { day: "Fri", id: 4, checked: false },
        { day: "Sat", id: 5, checked: false },
        { day: "Sun", id: 6, checked: false },
      ],
      modifiedCourtList: [],
      selectedCourtIds: [],
      selectedDayIds: [],
      clubMinuteStep: 15,
      showDialog: false,
      blockingRemarks: "",
      isCheckAvailabilityBtnDisabled: false,
      isActivitiesInvalid: false,
      isCourtInvalid: false,
      isDateRangeInvalid: false,
      isDayInvalid: false,
      isTimeRangeInvalid: false,
      countState: {},
      countValue: "Select slot count",
      defaultCount: "Select slot count",
      invalidCount: false,
      ignoreUnavailableSlots: true,
    };
  }

  // -----------------------------------------------------------------------
  // handleActivityChange()
  // -----------------------------------------------------------------------
  handleActivityChange = async (e) => {
    e.persist();

    let clubActivities = this.props.clubActivityData && this.props.clubActivityData.activityDetails;
    let data = clubActivities.find((x) => x.activityId == e.target.value);
    await this.setState({
      selectedCourtIds: [],
      selectedActivityValue: e.target.value,
      isActivitiesInvalid: false,
      countState: data,
      countValue: "Select slot count",
    });
    if (e.target.value !== this.state.defaultActivityValue) {
      await this.props.dispatch(getCourtList(e.target.value));
      if (this.props.courtListToast) {
        if (this.props.courtListToast.resourcesNotLoadedMessage) {
          resourcesNotLoadedToast();
        } else if (this.props.courtListToast.httpErrorMessage) {
          toaster.danger(this.props.courtListToast.httpErrorMessage, {
            id: "HTTP-ERROR-TOAST-4",
          });
        } else if (this.props.courtListToast.requestStatus === -1) {
          toaster.notify("Sorry, you don't have permission to do that");
        } else if (this.props.courtListToast.requestStatus === 0) {
          toaster.danger(this.props.courtListToast.message, {
            id: "HP-TOAST",
          });
        } else if (
          this.props.courtListToast.requestStatus === 1 &&
          this.props.courtList &&
          this.props.courtList.length > 0
        ) {
          const { courtList } = this.props;
          const modifiedCourtList = await courtList.map((court) => {
            return (court = { ...court, checked: false });
          });
          await this.setState({
            modifiedCourtList: modifiedCourtList,
            selectedActivityId: e.target.value,
          });
          await this.setState({
            courtCheckAll: false,
          });
        }
      }
    }
    if (e.target.value === this.state.defaultActivityValue) {
      await this.setState({
        courtCheckAll: false,
        selectedActivityId: null,
      });
      this.props.dispatch({ type: "SET_COURT_LIST", payload: null });
    }
  };

  // -----------------------------------------------------------------------
  // handleFromDateChange()
  // -----------------------------------------------------------------------
  handleFromDateChange = async (date) => {
    await this.setState({ fromDate: date, toDate: date });
    await this.validateDates();
  };

  // -----------------------------------------------------------------------
  // handleToDateChange()
  // -----------------------------------------------------------------------
  handleToDateChange = async (date) => {
    await this.setState({ toDate: date });
    await this.validateDates();
  };

  // -----------------------------------------------------------------------
  // validateDates()
  // -----------------------------------------------------------------------
  validateDates = async () => {
    if (
      checkIfDatesAreSame(this.state.toDate, this.state.fromDate) ||
      checkIfDateIsAfter(this.state.toDate, this.state.fromDate)
    ) {
      await this.setState({
        isDateRangeInvalid: false,
      });
    } else {
      await this.setState({
        isDateRangeInvalid: true,
      });
    }
  };

  // -----------------------------------------------------------------------
  // handleFromTimeChange()
  // -----------------------------------------------------------------------
  handleFromTimeChange = async (time) => {
    await this.setState({ fromTime: time, toTime: time, timeCheckAll: false });
    await this.validateTimes();
  };

  // -----------------------------------------------------------------------
  // handleToTimeChange()
  // -----------------------------------------------------------------------
  handleToTimeChange = async (time) => {
    await this.setState({ toTime: time, timeCheckAll: false });
    await this.validateTimes();
  };

  // -----------------------------------------------------------------------
  // validateTimes()
  // -----------------------------------------------------------------------
  validateTimes = async () => {
    if (
      checkIfTimeIsSameOrAfter(this.state.toTime, this.state.fromTime) ||
      toTimeFormat2(this.state.toTime) === "00:00:00"
    ) {
      await this.setState({ isTimeRangeInvalid: false });
    } else {
      await this.setState({ isTimeRangeInvalid: true });
    }
  };

  // -----------------------------------------------------------------------
  // handleCheckAll()
  // -----------------------------------------------------------------------
  handleCheckAll = async (e, type) => {
    e.persist();
    if (this.state.selectedActivityId !== null) {
      if (type === "court") {
        const modifiedCourtList = await this.state.modifiedCourtList.map((court) => {
          return (court = {
            ...court,
            checked: e.target.checked ? true : false,
          });
        });
        await this.setState({
          modifiedCourtList: modifiedCourtList,
          courtCheckAll: !this.state.courtCheckAll,
        });
        await this.setState({
          selectedCourtIds: e.target.checked
            ? this.state.modifiedCourtList.map((court) => {
                return court.courtId;
              })
            : [],
        });
        await this.setState({ isCourtInvalid: false });
      }
      if (type === "day") {
        const modifiedDaysData = await this.state.daysData.map((data) => {
          return (data = {
            ...data,
            checked: e.target.checked ? true : false,
          });
        });
        await this.setState({
          daysData: modifiedDaysData,
          dayCheckAll: !this.state.dayCheckAll,
        });
        await this.setState({
          selectedDayIds: e.target.checked
            ? this.state.daysData.map((data) => {
                return data.id;
              })
            : [],
        });
        await this.setState({ isDayInvalid: false });
      }
      if (type === "time") {
        await this.setState({
          timeCheckAll: e.target.checked ? true : false,
        });
        if (this.state.timeCheckAll) {
          await this.setState({
            isTimeRangeInvalid: false,
          });
        }
      }
      if (type === "member-bulk-booking") {
        await this.setState({
          memberBulkBookingCheck: e.target.checked ? true : false,
        });
      }
    } else {
      await this.setState({ isActivitiesInvalid: true });
      await this.scrollToElement("isActivitiesInvalid");
    }
  };

  // -----------------------------------------------------------------------
  // handleCheckIndividual()
  // -----------------------------------------------------------------------
  handleCheckIndividual = async (e, type, identifier) => {
    e.persist();
    if (this.state.selectedActivityId !== null) {
      if (type === "court") {
        const modifiedCourtList = JSON.parse(JSON.stringify(this.state.modifiedCourtList));
        for (var i = 0; i < modifiedCourtList.length; i++) {
          if (modifiedCourtList[i].courtId === identifier) {
            modifiedCourtList[i].checked = e.target.checked ? true : false;
          }
        }
        await this.setState({ modifiedCourtList: modifiedCourtList });
        const updatedSelectedCourtIds = [];
        for (i = 0; i < modifiedCourtList.length; i++) {
          if (modifiedCourtList[i].checked) {
            updatedSelectedCourtIds.push(modifiedCourtList[i].courtId);
          }
        }
        let allCourtsCheck = true;
        this.state.modifiedCourtList.map((data) => {
          if (data.checked == false) {
            allCourtsCheck = false;
          }
        });

        await this.setState({ selectedCourtIds: updatedSelectedCourtIds });
        if (this.state.selectedCourtIds.length !== this.state.modifiedCourtList.length) {
          await this.setState({
            courtCheckAll: false,
          });
        }

        if (allCourtsCheck == true) {
          await this.setState({
            courtCheckAll: allCourtsCheck,
          });
        }
        await this.setState({ isCourtInvalid: false });
      }
      if (type === "day") {
        const modifiedDaysData = JSON.parse(JSON.stringify(this.state.daysData));
        for (i = 0; i < modifiedDaysData.length; i++) {
          if (modifiedDaysData[i].id === identifier) {
            modifiedDaysData[i].checked = e.target.checked ? true : false;
          }
        }
        await this.setState({
          daysData: modifiedDaysData,
        });
        const updatedSelectedDayIds = [];
        for (i = 0; i < modifiedDaysData.length; i++) {
          if (modifiedDaysData[i].checked) {
            updatedSelectedDayIds.push(modifiedDaysData[i].id);
          }
        }
        let allDaysCheck = true;
        this.state.daysData.map((data) => {
          if (data.checked == false) {
            allDaysCheck = false;
          }
        });

        await this.setState({ selectedDayIds: updatedSelectedDayIds });
        if (this.state.selectedDayIds.length !== this.state.daysData.length) {
          await this.setState({
            dayCheckAll: false,
          });
        }

        if (allDaysCheck == true) {
          await this.setState({
            dayCheckAll: allDaysCheck,
          });
        }
        await this.setState({ isDayInvalid: false });
      }
    } else {
      await this.setState({ isActivitiesInvalid: true });
      await this.scrollToElement("isActivitiesInvalid");
    }
  };

  // -----------------------------------------------------------------------
  // scrollToElement()
  // -----------------------------------------------------------------------
  scrollToElement = async (elem) => {
    await this.refs[elem].scrollIntoView({ block: "end", behavior: "smooth" });
  };

  validateCount = () => {
    let { countState, countValue, selectedActivityId } = this.state;

    if (selectedActivityId === null) {
      return true;
    } else if (countState.activityType === 1 && countValue !== "Select slot count") {
      return true;
    } else if (countState.activityType === 1 && countValue === "Select slot count") {
      return false;
    } else if (countState.activityType === 0) {
      return true;
    }
  };

  // -----------------------------------------------------------------------
  // onCheckAvailability()
  // -----------------------------------------------------------------------
  onCheckAvailability = async (e) => {
    e.persist();
    const {
      selectedActivityId,
      selectedCourtIds,
      selectedDayIds,
      isDateRangeInvalid,
      isTimeRangeInvalid,
      countState,
      countValue,
      ignoreUnavailableSlots,
    } = this.state;
    let isCountValid = this.validateCount();

    if (selectedActivityId !== null && isCountValid) {
      if (
        selectedCourtIds.length > 0 &&
        selectedDayIds.length > 0 &&
        !isDateRangeInvalid &&
        !isTimeRangeInvalid
      ) {
        let reqBody = {
          courts: this.state.selectedCourtIds,
          startDate: toDateFormat1(this.state.fromDate),
          endDate: toDateFormat1(this.state.toDate),
          allSlots: this.state.timeCheckAll ? true : false,
          startTime: this.state.timeCheckAll ? "00:00:00" : toTimeFormat(this.state.fromTime),
          endTime:
            this.state.timeCheckAll || toTimeFormat2(this.state.toTime) === "00:00:00"
              ? "23:59:59"
              : toTimeFormat(this.state.toTime),
          ignoreUnavailableSlots: ignoreUnavailableSlots,
          days: this.state.selectedDayIds,

          ...(countState.activityType === 1 && { slotCount: countValue }),
        };
        await this.props.dispatch(verifySlotsAvailability(reqBody));
        if (this.props.slotsAvailabilityToast) {
          if (this.props.slotsAvailabilityToast.resourcesNotLoadedMessage) {
            resourcesNotLoadedToast();
          } else if (
            this.props.slotsAvailabilityToast.httpErrorMessage &&
            this.props.slotsAvailabilityToast.statusCode === 404
          ) {
            toaster.danger(this.props.slotsAvailabilityToast.httpErrorMessage, {
              id: "HTTP-ERROR-TOAST-4",
            });
            await this.setState({ isCheckAvailabilityBtnDisabled: true });
            setTimeout(() => {
              this.setState({ isCheckAvailabilityBtnDisabled: false });
            }, 3000);
          } else if (this.props.slotsAvailabilityToast.httpErrorMessage) {
            toaster.danger(this.props.slotsAvailabilityToast.httpErrorMessage, {
              id: "HTTP-ERROR-TOAST-4",
            });
          } else if (this.props.slotsAvailabilityToast.requestStatus === -1) {
            toaster.notify("Sorry, you don't have permission to do that");
          } else if (this.props.slotsAvailabilityToast.requestStatus === 0) {
            toaster.danger(this.props.slotsAvailabilityToast.message, {
              id: "BF-TOAST-3",
            });
          } else if (
            this.props.slotsAvailabilityToast.requestStatus === 1 &&
            this.props.slotsAvailabilityData
          ) {
            this.setState({ showDialog: true });
          }
        }
      } else {
        if (isTimeRangeInvalid) {
          await this.scrollToElement("isTimeRangeInvalid");
        }
        if (selectedDayIds.length <= 0) {
          await this.setState({ isDayInvalid: true });
          await this.scrollToElement("isDayInvalid");
        }
        if (isDateRangeInvalid) {
          await this.scrollToElement("isDateRangeInvalid");
        }
        if (selectedCourtIds.length <= 0) {
          await this.setState({ isCourtInvalid: true });
          await this.scrollToElement("isCourtInvalid");
        }
      }
    } else {
      if (!isCountValid) {
        await this.setState({
          invalidCount: true,
        });
        // await this.scrollToElement("isCountInvalid");
      } else {
        await this.setState({ isActivitiesInvalid: true });
        await this.scrollToElement("isActivitiesInvalid");
      }
    }
  };

  // -----------------------------------------------------------------------
  // handleCloseDialog()
  // -----------------------------------------------------------------------
  handleCloseDialog = async (e) => {
    await this.props.dispatch({
      type: "SET_VERIFY_SLOTS_AVAILABILITY_DATA",
      payload: null,
    });
    this.setState({ showDialog: false });
  };

  // -----------------------------------------------------------------------
  // resetForm()
  // -----------------------------------------------------------------------
  resetForm = async (e) => {
    e.persist();
    const modifiedDaysData = this.state.daysData.map((data) => {
      return (data = { ...data, checked: false });
    });
    await this.setState({
      selectedActivityId: null,
      courtCheckAll: false,
      dayCheckAll: false,
      timeCheckAll: false,
      memberBulkBookingCheck: false,
      fromDate: new Date(),
      toDate: new Date(),
      fromTime: new Date(),
      toTime: new Date(),

      selectedActivityValue: "select-a-sport",
      daysData: modifiedDaysData,
      modifiedCourtList: [],
      selectedCourtIds: [],
      selectedDayIds: [],
      blockingRemarks: "",
      isCheckAvailabilityBtnDisabled: false,
      isActivitiesInvalid: false,
      isCourtInvalid: false,
      isDateRangeInvalid: false,
      isDayInvalid: false,
      isTimeRangeInvalid: false,
      ignoreUnavailableSlots:true
    });
  };

  // -----------------------------------------------------------------------
  // handleBlockingRemarksInput()
  // -----------------------------------------------------------------------
  handleBlockingRemarksInput = async (e) => {
    e.persist();
    await this.setState({ blockingRemarks: e.target.value });
  };

  handleCountChange = async (e) => {
    this.setState({
      countValue: e.target.value,
      invalidCount: false,
    });
  };

  // -----------------------------------------------------------------------
  // render()
  // -----------------------------------------------------------------------
  render() {
    const {
      defaultActivityName,
      defaultActivityValue,
      selectedActivityValue,
      modifiedCourtList,
      courtCheckAll,
      dayCheckAll,
      timeCheckAll,
      memberBulkBookingCheck,
      showDialog,
      clubMinuteStep,
      selectedActivityId,
      fromDate,
      toDate,
      daysData,
      fromTime,
      toTime,
      selectedCourtIds,
      selectedDayIds,
      blockingRemarks,
      isCheckAvailabilityBtnDisabled,
      isActivitiesInvalid,
      isCourtInvalid,
      isDateRangeInvalid,
      isDayInvalid,
      isTimeRangeInvalid,
      countState,
      countValue,
      defaultCount,
      invalidCount,
      ignoreUnavailableSlots,
    } = this.state;
    const {
      slotsAvailabilityData,
      courtList,
      formFor,
      selectedTab,
      handleTabsChange,
      showTabs,
      handleBookingCheckoutDisplay,
      handleSeeAllBulkBookingsFromBulkForm,
      setResetBookingCheckoutInputFieldsTrue,
    } = this.props;

    return (
      <React.Fragment>
        <div className='BF-A1-wrapper'>
          <div className='BF-A1-form-container'>
            {showTabs && (
              <div className='BF-A1-actions-wrapper BF-A1-margin-bottom-16'>
                <Tablist marginRight={24}>
                  <Tab
                    height={32}
                    width={180}
                    isSelected={selectedTab === "block"}
                    onSelect={() => handleTabsChange("block")}
                  >
                    Block Slots
                  </Tab>
                  <Tab
                    height={32}
                    width={180}
                    isSelected={selectedTab === "unblock"}
                    onSelect={() => handleTabsChange("unblock")}
                  >
                    Unblock Slots
                  </Tab>
                </Tablist>
              </div>
            )}
            {!showTabs && (
              <div
                className='BF-A1-inline-alert-actions-wrapper BF-A1-margin-bottom-16'
                onClick={(e) => handleSeeAllBulkBookingsFromBulkForm(e)}
              >
                <CustomInlineAlert alertMsg='See all Bulk Bookings' intent='none' />
              </div>
            )}
            <div className='BF-A1-actions-wrapper BF-A1-margin-bottom-16'>
              <div className='BF-A1-col-container'>
                <div className='BF-A1-label-container BF-A1-margin-bottom-16'>
                  <Text id='BF-A1-label-type-one'>Activities</Text>
                </div>
                <div className='BF-A1-row-container '>
                  <ClubActivityListSelect
                    handleActivityChange={this.handleActivityChange}
                    defaultActivityName={defaultActivityName}
                    defaultActivityValue={defaultActivityValue}
                    selectedActivityValue={selectedActivityValue}
                  />
                  <div>
                    {countState && countState.activityType === 1 && (
                      <CountSelect
                        handleCountChange={this.handleCountChange}
                        countValue={countValue}
                        countState={countState}
                        defaultCount={defaultCount}
                      />
                    )}
                    {invalidCount && (
                      <Pane
                        marginLeft={30}
                        paddingTop={10}
                        className='BF-A1-custom-inline-alert-container'
                        ref={"isCountInvalid"}
                      >
                        <CustomInlineAlert intent='danger' alertMsg='Select Slot Count' />
                      </Pane>
                    )}
                  </div>
                </div>

                {isActivitiesInvalid && (
                  <div className='BF-A1-custom-inline-alert-container' ref={"isActivitiesInvalid"}>
                    <CustomInlineAlert intent='danger' alertMsg='Select an activity' />
                  </div>
                )}
              </div>
            </div>
            <div className='BF-A1-actions-wrapper BF-A1-margin-bottom-16'>
              <div className='BF-A1-col-container'>
                <div className='BF-A1-label-container BF-A1-margin-bottom-16'>
                  <Text id='BF-A1-label-type-one'>Court</Text>
                </div>
                <div className='BF-A1-checkbox-wrapper'>
                  <Checkbox
                    label='Select All'
                    marginRight={16}
                    onChange={(e) => this.handleCheckAll(e, "court")}
                    checked={courtCheckAll}
                  />
                  {courtList &&
                    modifiedCourtList &&
                    modifiedCourtList.length > 0 &&
                    modifiedCourtList.map((court) => (
                      <Checkbox
                        marginRight={16}
                        key={court.courtId}
                        label={court.courtName}
                        checked={court.checked}
                        onChange={(e) => this.handleCheckIndividual(e, "court", court.courtId)}
                      />
                    ))}
                </div>
                {isCourtInvalid && (
                  <div className='BF-A1-custom-inline-alert-container' ref={"isCourtInvalid"}>
                    <CustomInlineAlert intent='danger' alertMsg='Select at least one court' />
                  </div>
                )}
              </div>
            </div>
            <div className='BF-A1-actions-wrapper BF-A1-margin-bottom-16'>
              <div className='BF-A1-col-container'>
                <div className='BF-A1-label-container BF-A1-margin-bottom-16'>
                  <Text id='BF-A1-label-type-one'>Date</Text>
                </div>
                <div className='BF-A1-row-container'>
                  <div className='BF-A1-col-container BF-A1-margin-right-124'>
                    <div className='BF-A1-label-container BF-A1-margin-bottom-4'>
                      <Text id='BF-A1-label-type-two'>From</Text>
                    </div>
                    <div className='BF-A1-from-datepicker-container'>
                      <CustomizedDatePicker
                        date={fromDate}
                        handleDateChange={this.handleFromDateChange}
                      />
                    </div>
                  </div>
                  <div className='BF-A1-col-container'>
                    <div className='BF-A1-label-container BF-A1-margin-bottom-4'>
                      <Text id='BF-A1-label-type-two'>To</Text>
                    </div>
                    <div className='BF-A1-to-datepicker-container'>
                      <CustomizedDatePicker
                        date={toDate}
                        handleDateChange={this.handleToDateChange}
                      />
                    </div>
                  </div>
                </div>
                {isDateRangeInvalid && (
                  <div className='BF-A1-custom-inline-alert-container' ref={"isDateRangeInvalid"}>
                    <CustomInlineAlert intent='danger' alertMsg='Invalid date range selected' />
                  </div>
                )}
              </div>
            </div>
            <div className='BF-A1-actions-wrapper BF-A1-margin-bottom-16'>
              <div className='BF-A1-col-container'>
                <div className='BF-A1-label-container BF-A1-margin-bottom-16'>
                  <Text id='BF-A1-label-type-one'>Day</Text>
                </div>
                <div className='BF-A1-checkbox-wrapper'>
                  <Checkbox
                    label='All Days'
                    marginRight={16}
                    onChange={(e) => this.handleCheckAll(e, "day")}
                    checked={dayCheckAll}
                  />
                  {daysData.map((data) => (
                    <Checkbox
                      marginRight={16}
                      key={data.id}
                      label={data.day}
                      checked={data.checked}
                      onChange={(e) => this.handleCheckIndividual(e, "day", data.id)}
                    />
                  ))}
                </div>
                {isDayInvalid && (
                  <div className='BF-A1-custom-inline-alert-container' ref={"isDayInvalid"}>
                    <CustomInlineAlert intent='danger' alertMsg='Select at least one day' />
                  </div>
                )}
              </div>
            </div>
            <div className='BF-A1-actions-wrapper BF-A1-margin-bottom-16'>
              <div className='BF-A1-col-container'>
                <div className='BF-A1-label-container BF-A1-margin-bottom-16'>
                  <Text id='BF-A1-label-type-one'>Time</Text>
                </div>
                {/*<div className="BF-A1-row-container BF-A1-margin-bottom-16">*/}
                {/*<div className="BF-A1-checkbox-wrapper">*/}
                {/*<Checkbox*/}
                {/*label="All Slots"*/}
                {/*checked={timeCheckAll}*/}
                {/*onChange={e => this.handleCheckAll(e, "time")}*/}
                {/*/>*/}
                {/*</div>*/}
                {/*</div>*/}
                {!timeCheckAll && (
                  <div className='BF-A1-row-container'>
                    <div className='BF-A1-col-container BF-A1-margin-right-124'>
                      <div className='BF-A1-label-container BF-A1-margin-bottom-4'>
                        <Text id='BF-A1-label-type-two'>From</Text>
                      </div>
                      <div className='BF-A1-from-timepicker-container'>
                        <CustomizedTimePicker
                          time={fromTime}
                          clubMinuteStep={clubMinuteStep}
                          handleTimeChange={this.handleFromTimeChange}
                        />
                      </div>
                    </div>
                    <div className='BF-A1-col-container'>
                      <div className='BF-A1-label-container BF-A1-margin-bottom-4'>
                        <Text id='BF-A1-label-type-two'>To</Text>
                      </div>
                      <div className='BF-A1-to-timepicker-container'>
                        <CustomizedTimePicker
                          time={toTime}
                          clubMinuteStep={clubMinuteStep}
                          handleTimeChange={this.handleToTimeChange}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {!timeCheckAll && isTimeRangeInvalid && (
                  <div className='BF-A1-custom-inline-alert-container' ref={"isTimeRangeInvalid"}>
                    <CustomInlineAlert intent='danger' alertMsg='Invalid time range selected' />
                  </div>
                )}
              </div>
            </div>
            {formFor === "block-slots" && (
              <div className='BF-A1-actions-wrapper BF-A1-margin-bottom-16'>
                <div className='BF-A1-col-container'>
                  <div className='BF-A1-label-container BF-A1-margin-bottom-16'>
                    <Text id='BF-A1-label-type-one'>Remark</Text>
                  </div>
                  <div className='BF-A1-row-container'>
                    <Textarea
                      className='BF-A1-input-field'
                      style={{ resize: "none" }}
                      width={336}
                      height={80}
                      placeholder='Remarks'
                      maxLength='100'
                      value={blockingRemarks}
                      onChange={(e) => this.handleBlockingRemarksInput(e)}
                    />
                  </div>
                </div>
              </div>
            )}
            {formFor === "bulk-booking" && (
              <Pane display='flex' justifyContent='center' alignItems='center' flexDirection='row'>
                <Checkbox
                  marginRight={16}
                  label='Ignore unavailable slots?'
                  onChange={() =>
                    this.setState({ ignoreUnavailableSlots: !ignoreUnavailableSlots })
                  }
                  checked={ignoreUnavailableSlots}
                />
                <Tooltip
                  display='flex'
                  justifyContent='center'
                  alignItems='center'
                  flexDirection='row'
                  content='By deselecting this option, If a booking already exists for a date and court for the selected pattern, the slots for that date and court will not be booked.'
                >
                  <InfoSignIcon />
                </Tooltip>
              </Pane>
            )}
            <div className='BF-A1-last-actions-wrapper'>
              <div className='BF-A1-col-container'>
                <div className='BF-A1-row-container'>
                  <Button
                    className='BF-A1-btn'
                    width={140}
                    marginRight={32}
                    onClick={(e) => this.resetForm(e)}
                  >
                    Reset
                  </Button>
                  <Button
                    className='BF-A1-btn'
                    width={200}
                    appearance='primary'
                    onClick={(e) => this.onCheckAvailability(e)}
                    disabled={isCheckAvailabilityBtnDisabled}
                  >
                    {formFor === "bulk-booking" && "Check Availability"}
                    {formFor === "block-slots" && "Check Slots to Block"}
                    {formFor === "unblock-slots" && "Check Slots to Unblock"}
                  </Button>
                </div>

                {formFor === "bulk-booking" && (
                  <div className='BF-A1-checkbox-wrapper BF-A1-row-container'>
                    <Checkbox
                      marginRight={16}
                      label='Select, If member bulk booking?'
                      onChange={(e) => this.handleCheckAll(e, "member-bulk-booking")}
                      checked={memberBulkBookingCheck}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {formFor === "bulk-booking" && slotsAvailabilityData && (
          <AvailabilityDialog
            selectedActivityId={selectedActivityId}
            fromDate={fromDate}
            toDate={toDate}
            fromTime={fromTime}
            toTime={toTime}
            timeCheckAll={timeCheckAll}
            selectedCourtIds={selectedCourtIds}
            selectedDayIds={selectedDayIds}
            showDialog={showDialog}
            handleCloseDialog={this.handleCloseDialog}
            slotsAvailabilityData={slotsAvailabilityData}
            handleBookingCheckoutDisplay={handleBookingCheckoutDisplay}
            setResetBookingCheckoutInputFieldsTrue={setResetBookingCheckoutInputFieldsTrue}
          />
        )}
        {formFor === "block-slots" && slotsAvailabilityData && (
          <BlockSlotsDialog
            selectedActivityId={selectedActivityId}
            fromDate={fromDate}
            toDate={toDate}
            fromTime={fromTime}
            toTime={toTime}
            timeCheckAll={timeCheckAll}
            selectedCourtIds={selectedCourtIds}
            selectedDayIds={selectedDayIds}
            showDialog={showDialog}
            handleCloseDialog={this.handleCloseDialog}
            slotsAvailabilityData={slotsAvailabilityData}
            blockingRemarks={blockingRemarks}
          />
        )}
        {formFor === "unblock-slots" && slotsAvailabilityData && (
          <UnblockSlotsDialog
            selectedActivityId={selectedActivityId}
            fromDate={fromDate}
            toDate={toDate}
            fromTime={fromTime}
            toTime={toTime}
            timeCheckAll={timeCheckAll}
            selectedCourtIds={selectedCourtIds}
            selectedDayIds={selectedDayIds}
            showDialog={showDialog}
            handleCloseDialog={this.handleCloseDialog}
            slotsAvailabilityData={slotsAvailabilityData}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({
  courtList,
  slotsAvailabilityData,
  slotsAvailabilityToast,
  courtListToast,
  clubActivityData,
}) => ({
  courtList,
  slotsAvailabilityData,
  slotsAvailabilityToast,
  courtListToast,
  clubActivityData,
});

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default connect(mapStateToProps)(BulkForm);
