// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PageBody from "../../components/PageBody";
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import HomePageActionBar from "./HomePageActionBar";
import SlotTable from "./SlotTable";
import DrawerHolder from "../../components/DrawerHolder";
import {
  addSlotToCart,
  clearCart,
  getActivities,
  getBookingDetails,
  getBookingDetailsOnHover,
  getCart,
  getClubExtrasList,
  getClubHandlers,
  getCouponDetails,
  getEscalationMatrix,
  getIndividualBulkBookingSearchResult,
  getMemberDetails,
  getMembershipPackageDetails,
  getMembershipSubscriptionDetails,
  getPriceClass,
  getStaffMember,
  getSlots,
  getTransactionCodesAndTransactionTypes,
  logout,
  removeFromCart,
  saveMembershipPackageDetailsForEditing,
  saveSelectedMembershipPackageDetails,
  switchAccount,
  togglePremiumFeatureFlag,
  unblocking,
  updateMembershipPackageDetails,
  getConfigExtraList,
} from "../../actions";
import {
  checkAuthTokenExists,
  checkClubNameExists,
  checkUserNameExists,
  getCurrentClub,
  getPlayoClubName,
  getPlayoUserName,
  getUserClubs,
  removePlayoTokens,
  getPlayoUserId,
  resourcesNotLoadedToast,
  stringifyIt,
  toDateFormat1,
} from "../../utils";
import { toaster } from "evergreen-ui";

import BulkBooking from "../BulkBooking";
import BlockSlots from "../Blocking";
import BookingCheckout from "../BookingCheckout";
import Search from "../Search";
import Members from "../Members";
import CreateMembershipPackagesPage from "../MembershipPackages/CreateMembershipPackagesPage";
import MembershipPackagesCheckoutPage from "../MembershipPackages/MembershipPackagesCheckoutPage";
import AllMembersListPage from "../MembershipPackages/AllMembersListPage";
import GenerateCoupons from "../GenerateCoupons";
import Pricing from "../Pricing";
import EditPricingPage from "../Pricing/EditPricingPage";
import Reports from "../Reports";
import Analytics from "../Analytics";
import ViewReportsPage from "../Reports/ViewReportsPage";
import PremiumFeature from "../PremiumFeature";
import AddDeskPersonDialogContent from "../../components/AddDeskPersonDialogContent";
import ChangePasswordDialogContent from "../../components/ChangePasswordDialogContent";
import SwitchAccountDialogContent from "../../components/SwitchAccountDialogContent";
import ContactSupportDialogContent from "../../components/ContactSupportDialogContent";
import BlankPageGuide from "../../components/BlankPageGuide";
import ViewMembershipPackagesPage from "../MembershipPackages/ViewMembershipPackagesPage";
import StaffConsole from "../StaffConsole/staff-console";
import AddonConfig from "../ExtraConfig/extraConfig";
import update from "immutability-helper";
import ComingSoon from "../ComingSoon";
import Api from "../../api";
import Cookies from "universal-cookie";
import Dashboard from "../Dashboard";
import EventDashBoard from "../EventDashBoard";
import MembershipPackages from "../MembershipPackages";

const cookies = new Cookies();

// -----------------------------------------------------------------------
// Homepage Component
// -----------------------------------------------------------------------
class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clubId: 1,
      pageNum: 1,
      cartSlotCount: 0,
      date: new Date(),
      defaultActivityName: "Select a sport",
      defaultActivityValue: "select-a-sport",
      selectedActivityValue: "select-a-sport",
      selectedActivityId: null,
      slotsListFlag: false,
      rightPanelRender: "dashboard",
      selectedListItem: "dashboard",
      rightPanelDivWidth: "calc(100vw - 240px)",
      openDrawerForCalendarPage: false,
      activitiesData: null,
      selectedActivityName: null,
      homepageDisplay: "block",
      bookingCheckoutDisplay: "none",
      showOnDrawer: "",
      searchedFromHomepage: false,
      searchStringOnHomepage: "",
      seeBookingsFromMembersDrawer: false,
      seeBookingsFromMembersDrawerMemberMobile: "",
      createMembershipPackagesPageDisplay: "none",
      membershipPackagesCheckoutPageDisplay: "none",
      allMembersListPageDisplay: "none",
      editPricingPageDisplay: "none",
      viewReportsPageDisplay: "none",
      viewMembershipPackagesPageDisplay: "none",
      playoUserName: "",
      playoClubName: "",
      currentBookingId: "",
      disableShowAvailability: false,
      showAddDeskPersonDialog: false,
      showChangePasswordDialog: false,
      showSwitchAccountDialog: false,
      currentBulkBookingId: "",
      seeAllBookingsFromBulkBookingDetailsDrawer: false,
      seeAllBookingsFromBulkBookingDetailsDrawerBulkBookingId: "",
      showContactSupportDialog: false,
      showBookingTableOnSearchPage: false,
      seeAllBulkBookingsFromBulkForm: false,
      resetBookingCheckoutAll: false,
      resetBookingCheckoutInputFields: false,
      goBackToPage: null,
      hideDashBoard: false,
      viewMembershipPackagesPageData: {
        showMenuPopover: true,
        activePackageDetails: {},
        currentPage: 1,
      },
      userClubs: [],
      currentClub: getCurrentClub(),
      managePermissionPage: false,
      staffMember: "",
      isAdmin: false,
      bookingSlot: "",
      bookDate: "",
      activityType: null,
      addMemberOnCheckout: false,
    };
    this.baseState = this.state;
  }

  // -----------------------------------------------------------------------
  // componentDidMount()
  // -----------------------------------------------------------------------
  componentDidMount() {
    document.title = "Playo Partner Console";
    this.onPageLoad();
    this.setState({
      userClubs: getUserClubs(),
    });
  }

  // -----------------------------------------------------------------------
  // onPageLoad()
  // -----------------------------------------------------------------------
  onPageLoad = async () => {
    if (
      checkAuthTokenExists() &&
      checkUserNameExists() &&
      checkClubNameExists()
    ) {
      this.setUserName();
      this.setClubName();
      this.loadActivitiesInformation();
      //this.getClubExtrasInformation();
      this.loadClubHandlers();
      // this.loadClubHandlers();
      this.loadTransactionCodesAndTransactionTypes();
      this.loadCartInformation();
    }
  };

  // -----------------------------------------------------------------------
  // loadTransactionCodesAndTransactionTypes()
  // -----------------------------------------------------------------------
  loadTransactionCodesAndTransactionTypes = async () => {
    await this.props.dispatch(getTransactionCodesAndTransactionTypes());
    if (this.props.transactionCodesAndTransactionTypes) {
      if (
        this.props.transactionCodesAndTransactionTypes.resourcesNotLoadedMessage
      ) {
        resourcesNotLoadedToast();
      } else if (
        this.props.transactionCodesAndTransactionTypes.httpErrorMessage
      ) {
        toaster.danger(
          this.props.transactionCodesAndTransactionTypes.httpErrorMessage,
          {
            id: "HTTP-ERROR-TOAST-5",
          }
        );
      } else if (
        this.props.transactionCodesAndTransactionTypes.requestStatus === -1
      ) {
        toaster.notify("Sorry, you don't have permission to do that");
      }
    }
  };

  // -----------------------------------------------------------------------
  // setUserName()
  // -----------------------------------------------------------------------
  setUserName = async () => {
    await this.setState({ playoUserName: getPlayoUserName() });
  };

  // -----------------------------------------------------------------------
  // setClubName()
  // -----------------------------------------------------------------------
  setClubName = async () => {
    await this.setState({ playoClubName: getPlayoClubName() });
  };

  // -----------------------------------------------------------------------
  // resetCartCount()
  // -----------------------------------------------------------------------
  resetCartCount = async () => {
    await this.setState({ cartSlotCount: 0, activityType: null });
  };

  // -----------------------------------------------------------------------
  // resetCartCount()
  // -----------------------------------------------------------------------
  hideSidePanelItem = async () => {
    this.setState({
      hideDashBoard: true,
    });
  };

  // -----------------------------------------------------------------------
  // handleLogout()
  // -----------------------------------------------------------------------
  handleLogout = async (e) => {
    e.persist();
    await this.props.dispatch(logout());
    if (this.props.logoutDetails) {
      if (this.props.logoutDetails.resourcesNotLoadedMessage) {
        resourcesNotLoadedToast();
      } else if (this.props.logoutDetails.httpErrorMessage) {
        toaster.danger(this.props.logoutDetails.httpErrorMessage, {
          id: "HTTP-ERROR-TOAST-4",
        });
      } else if (this.props.logoutDetails.requestStatus === 0) {
        toaster.danger(stringifyIt(this.props.logoutDetails.message), {
          id: "HP-TOAST",
        });
      } else if (this.props.logoutDetails.requestStatus === 1) {
        removePlayoTokens();
        await this.props.dispatch({ type: "TOGGLE_AUTH_STATUS" });
      } else if (this.props.logoutDetails.requestStatus === -1) {
        toaster.notify("Sorry, you don't have permission to do that");
      }
    }
  };

  // -----------------------------------------------------------------------
  // loadClubHandlers()
  // -----------------------------------------------------------------------
  loadClubHandlers = async () => {
    try {
      await this.props.dispatch(getClubHandlers());
      if (this.props.clubHandlersList) {
        if (this.props.clubHandlersList.resourcesNotLoadedMessage) {
          resourcesNotLoadedToast();
        } else if (this.props.clubHandlersList.httpErrorMessage) {
          toaster.danger(this.props.clubHandlersList.httpErrorMessage, {
            id: "HTTP-ERROR-TOAST-1",
          });
        } else if (this.props.clubHandlersList.requestStatus === 0) {
          toaster.danger(stringifyIt(this.props.clubHandlersList.message), {
            id: "HP-TOAST",
          });
        } else if (this.props.clubHandlersList.requestStatus === -1) {
          toaster.notify("Sorry, you don't have permission to do that");
        }
      }
    } catch (err) {
      toaster.danger("Some error has occured", { id: "HP-TOAST" });
    }
  };

  // -----------------------------------------------------------------------
  // setSeeBookingsFromMembersDrawerFalse()
  // -----------------------------------------------------------------------
  setSeeBookingsFromMembersDrawerFalse = () => {
    this.setState({
      seeBookingsFromMembersDrawer: false,
    });
  };

  // -----------------------------------------------------------------------
  // setSearchedFromHomepageFalse()
  // -----------------------------------------------------------------------
  setSearchedFromHomepageFalse = () => {
    this.setState({
      searchedFromHomepage: false,
    });
  };

  // -----------------------------------------------------------------------
  // setSeeAllBookingsFromBulkBookingDetailsDrawerFalse()
  // -----------------------------------------------------------------------
  setSeeAllBookingsFromBulkBookingDetailsDrawerFalse = () => {
    this.setState({
      seeAllBookingsFromBulkBookingDetailsDrawer: false,
    });
  };

  // ----------------------------------------------------------------------
  // handleSearchOnHomepageChange()
  // ----------------------------------------------------------------------
  handleSearchOnHomepageChange = (e) => {
    if (e.target.value.trim().length > 0) {
      this.setState({
        searchStringOnHomepage: e.target.value.trim(),
      });
      this.setSearchedFromHomepageFalse();
    }
  };

  // ----------------------------------------------------------------------
  // handleSearchOnHomepage()
  // ----------------------------------------------------------------------
  handleSearchOnHomepage = async (e) => {
    e.persist();
    if (e.key === "Enter" && e.target.value.trim().length > 0) {
      await this.setState({ searchedFromHomepage: true });
      this.handleSidebar(e, "search");
    }
  };

  // -----------------------------------------------------------------------
  // handleSeeBookingsFromMembersDrawer()
  // -----------------------------------------------------------------------
  handleSeeBookingsFromMembersDrawer = async (e, memberMobile) => {
    e.persist();
    this.setState({
      seeBookingsFromMembersDrawerMemberMobile: memberMobile,
    });
    await this.setState({ seeBookingsFromMembersDrawer: true });
    await this.handleSidebar(e, "search");
  };

  // -----------------------------------------------------------------------
  // handleSeeAllBookingsfromBulkBookingDetailsDrawer()
  // -----------------------------------------------------------------------
  handleSeeAllBookingsfromBulkBookingDetailsDrawer = async (
    e,
    bulkBookingId
  ) => {
    console.log("seeee");
    e.persist();
    await this.setState({
      seeAllBookingsFromBulkBookingDetailsDrawerBulkBookingId: bulkBookingId,
    });
    await this.setState({ seeAllBookingsFromBulkBookingDetailsDrawer: true });
    await this.handleSidebar(e, "search");
  };

  // -----------------------------------------------------------------------
  // handleGetBookingDetails()
  // -----------------------------------------------------------------------
  handleGetBookingDetails = async (
    bookingId,
    showDrawer = true,
    event = false
  ) => {
    console.log("test11");
    await this.setState({ currentBookingId: bookingId });
    if (!event) {
      console.log("inside");
      await this.props.dispatch(getBookingDetails(bookingId));

      if (this.props.bookingDetails) {
        if (this.props.bookingDetails.resourcesNotLoadedMessage) {
          resourcesNotLoadedToast();
        } else if (this.props.bookingDetails.httpErrorMessage) {
          toaster.danger(this.props.bookingDetails.httpErrorMessage, {
            id: "HTTP-ERROR-TOAST-4",
          });
        } else if (this.props.bookingDetails.requestStatus === 0) {
          toaster.danger(stringifyIt(this.props.bookingDetails.message), {
            id: "HP-TOAST",
          });
        } else if (
          this.props.bookingDetails.requestStatus === 1 &&
          showDrawer
        ) {
          await this.setState({
            showOnDrawer: "regularBookingDetailsOnDrawer",
          });
          await this.handleDrawer("open");
        } else if (this.props.bookingDetails.requestStatus === -1) {
          toaster.notify("Sorry, you don't have permission to do that");
        }
      }
    } else {
      console.log("test");
      await this.setState({
        showOnDrawer: "regularBookingDetailsOnDrawer",
      });
      await this.handleDrawer("open");
    }
  };

  // -----------------------------------------------------------------------
  // handleGetBookingDetails()
  // -----------------------------------------------------------------------
  handleGetBookingDetailsOnHover = async (bookingId, showDrawer = true) => {
    await this.setState({ currentBookingId: bookingId });
    await this.props.dispatch(getBookingDetailsOnHover(bookingId));
    if (this.props.bookingDetailsOnHover) {
      if (this.props.bookingDetailsOnHover.resourcesNotLoadedMessage) {
        resourcesNotLoadedToast();
      } else if (this.props.bookingDetailsOnHover.httpErrorMessage) {
        toaster.danger(this.props.bookingDetailsOnHover.httpErrorMessage, {
          id: "HTTP-ERROR-TOAST-4",
        });
      } else if (this.props.bookingDetailsOnHover.requestStatus === 0) {
        toaster.danger(stringifyIt(this.props.bookingDetailsOnHover.message), {
          id: "HP-TOAST",
        });
      } else if (
        this.props.bookingDetailsOnHover.requestStatus === 1 &&
        showDrawer
      ) {
        await this.setState({
          showOnDrawer: "regularBookingDetailsOnDrawer",
        });
        await this.handleDrawer("open");
      } else if (this.props.bookingDetailsOnHover.requestStatus === -1) {
        toaster.notify("Sorry, you don't have permission to do that");
      }
    }
  };

  // -----------------------------------------------------------------------
  //     handleGetSlotLockedStatus()
  // -----------------------------------------------------------------------
  handleGetSlotLockedStatus = async (slotData) => {
    const slotLockedStatus = await Api.getSlotLockedStatus(
      slotData,
      toDateFormat1(this.state.date)
    );
    toaster.notify(stringifyIt(slotLockedStatus.body.message), {
      id: "HP-TOAST",
    });
  };

  // -----------------------------------------------------------------------
  // handleGetIndividualBulkBookingDetails()
  // -----------------------------------------------------------------------
  handleGetIndividualBulkBookingDetails = async (patternBookingId) => {
    await this.setState({ currentBulkBookingId: patternBookingId });
    let reqParams = {
      input: patternBookingId,
      page: 1,
    };
    await this.props.dispatch(getIndividualBulkBookingSearchResult(reqParams));
    if (this.props.individualBulkBookingSearchResult) {
      if (
        this.props.individualBulkBookingSearchResult.resourcesNotLoadedMessage
      ) {
        resourcesNotLoadedToast();
      } else if (
        this.props.individualBulkBookingSearchResult.httpErrorMessage
      ) {
        toaster.danger(
          this.props.individualBulkBookingSearchResult.httpErrorMessage,
          {
            id: "HTTP-ERROR-TOAST-1",
          }
        );
      } else if (
        this.props.individualBulkBookingSearchResult.requestStatus === -1
      ) {
        toaster.notify("Sorry, you don't have permission to do that");
      } else if (
        this.props.individualBulkBookingSearchResult.requestStatus === 1
      ) {
        this.setState({
          showOnDrawer: "bulkBookingDetailsOnCalendarPage",
        });
        await this.handleDrawer("open");
      } else if (
        this.props.individualBulkBookingSearchResult.requestStatus === 0
      ) {
        toaster.danger(
          stringifyIt(this.props.individualBulkBookingSearchResult.message),
          {
            id: "HTTP-ERROR-TOAST-1",
          }
        );
      }
    }
  };

  // -----------------------------------------------------------------------
  // updateBookingDetails()
  // -----------------------------------------------------------------------
  updateBookingDetails = async () => {
    await this.handleGetBookingDetails(this.state.currentBookingId);
  };

  // -----------------------------------------------------------------------
  // updateCalendarPage()
  // -----------------------------------------------------------------------
  updateCalendarPage = async () => {
    if (this.state.selectedActivityValue !== "select-a-sport") {
      let reqBody = {
        activityIds: [parseInt(this.state.selectedActivityId)],
        activityStartDate: toDateFormat1(this.state.date),
        activityEndDate: toDateFormat1(this.state.date),
        customerStatus: 0,
      };
      await this.slotsListAvailabilityCheck(reqBody);
    }
  };

  // -----------------------------------------------------------------------
  // slotsListAvailabilityCheck()
  // -----------------------------------------------------------------------
  slotsListAvailabilityCheck = async (reqBody) => {
    if (this.state.showOnDrawer !== "cartDetailsOnCalendarPage") {
      await this.handleDrawer("close");
    }
    await this.props.dispatch(getSlots(reqBody));
    if (this.props.slotsToast) {
      if (this.props.slotsToast.resourcesNotLoadedMessage) {
        resourcesNotLoadedToast();
      } else if (
        this.props.slotsToast.httpErrorMessage &&
        this.props.slotsToast.statusCode === 404
      ) {
        toaster.danger(this.props.slotsToast.httpErrorMessage, {
          id: "HTTP-ERROR-TOAST-1",
        });
        await this.setState({ disableShowAvailability: true });
        setTimeout(() => {
          this.setState({ disableShowAvailability: false });
        }, 3000);
      } else if (this.props.slotsToast.httpErrorMessage) {
        toaster.danger(this.props.slotsToast.httpErrorMessage, {
          id: "HTTP-ERROR-TOAST-1",
        });
      } else if (
        this.props.slotsList &&
        this.props.slotsToast.requestStatus === 1
      ) {
        await this.setState({
          slotsListFlag: true,
        });
      } else if (this.props.slotsToast.requestStatus === 0) {
        await this.setState({
          slotsListFlag: false,
        });
        await this.props.dispatch({
          type: "SET_SLOTS_LIST",
          payload: null,
        });
        toaster.danger(stringifyIt(this.props.slotsToast.message), {
          id: "HP-TOAST",
        });
      } else if (this.props.slotsToast.requestStatus === -1) {
        toaster.notify("Sorry, you don't have permission to do that");
      }
    }
  };

  // -----------------------------------------------------------------------
  // handleDateChange()
  // -----------------------------------------------------------------------
  handleDateChange = async (date) => {
    if (this.state.selectedActivityId !== null) {
      await this.setState({
        date: date,
        slotsListFlag: false,
      });
      let reqBody = {
        activityIds: [parseInt(this.state.selectedActivityId)],
        activityStartDate: toDateFormat1(this.state.date),
        activityEndDate: toDateFormat1(this.state.date),
        customerStatus: 0,
      };
      this.slotsListAvailabilityCheck(reqBody);
    }
  };

  // -----------------------------------------------------------------------
  // handleActivityChange()
  // -----------------------------------------------------------------------
  handleActivityChange = async (e) => {
    e.persist();
    if (e.target.value !== "select-a-sport") {
      await this.setState({
        selectedActivityValue: e.target.value,
        selectedActivityId: parseInt(e.target.value),
        selectedActivityName:
          e.nativeEvent.target[
            e.nativeEvent.target.selectedIndex
          ].text.toString(),
        date: this.state.date,
        slotsListFlag: false,
      });
      let reqBody = {
        activityIds: [parseInt(e.target.value)],
        activityStartDate: toDateFormat1(this.state.date),
        activityEndDate: toDateFormat1(this.state.date),
        customerStatus: 0,
      };
      this.slotsListAvailabilityCheck(reqBody);
    }
    await this.setState({
      selectedActivityValue: e.target.value,
      // date: new Date(),
      slotsListFlag: false,
    });
    await this.props.dispatch({ type: "SET_SLOTS_LIST", payload: null });
  };

  // -----------------------------------------------------------------------
  // handleShowAvailability()
  // -----------------------------------------------------------------------
  handleShowAvailability = async () => {
    if (this.state.selectedActivityValue !== "select-a-sport") {
      let reqBody = {
        activityIds: [parseInt(this.state.selectedActivityId)],
        activityStartDate: toDateFormat1(this.state.date),
        activityEndDate: toDateFormat1(this.state.date),
        customerStatus: 0,
      };
      this.slotsListAvailabilityCheck(reqBody);
    } else {
      toaster.notify("Select an activity first", { id: "HP-TOAST" });
    }
  };

  // -----------------------------------------------------------------------
  // showCartDrawer()
  // -----------------------------------------------------------------------
  showCartDrawer = async () => {
    if (
      this.props.cartData &&
      this.props.cartData.cart &&
      this.props.cartData.cart.slots &&
      this.props.cartData.cart.slots.length > 0
    ) {
      await this.setState({
        showOnDrawer: "cartDetailsOnCalendarPage",
      });
      await this.handleDrawer("open");
    }
  };

  // -----------------------------------------------------------------------
  // handleDrawer()
  // -----------------------------------------------------------------------
  handleDrawer = (type) => {
    if (type === "open") {
      this.setState({
        openDrawerForCalendarPage: true,
      });
      this.changeRightPaneDivWidth("decrease");
    }
    if (type === "close") {
      this.setState({
        openDrawerForCalendarPage: false,
        showOnDrawer: "",
      });
      this.changeRightPaneDivWidth("increase");
    }
  };

  // -----------------------------------------------------------------------
  // changeRightPaneDivWidth()
  // -----------------------------------------------------------------------
  changeRightPaneDivWidth = (type) => {
    if (type === "increase") {
      this.setState({
        rightPanelDivWidth: "calc(100vw - 240px)",
      });
    }
    if (type === "decrease") {
      this.setState({
        rightPanelDivWidth: "calc(100vw - 640px)",
      });
    }
  };

  // -----------------------------------------------------------------------
  // loadCartInformation()
  // -----------------------------------------------------------------------
  loadCartInformation = async () => {
    await this.props.dispatch(getCart());
    if (this.props.cartData) {
      if (this.props.cartData.resourcesNotLoadedMessage) {
        resourcesNotLoadedToast();
      } else if (this.props.cartData.httpErrorMessage) {
        toaster.danger(this.props.cartData.httpErrorMessage, {
          id: "HTTP-ERROR-TOAST-2",
        });
      } else if (this.props.cartData.requestStatus === -1) {
        toaster.notify("Sorry, you don't have permission to do that");
      } else {
        if (
          this.props.cartData.cart.slots &&
          this.props.cartData.cart.cartingType &&
          this.props.cartData.cart.cartingType === 1
        ) {
          await this.props.dispatch(clearCart());
          if (this.props.cartData && this.props.cartToast) {
            if (this.props.cartToast.resourcesNotLoadedMessage) {
              resourcesNotLoadedToast();
            } else if (this.props.cartToast.httpErrorMessage) {
              toaster.danger(this.props.cartToast.httpErrorMessage, {
                id: "HTTP-ERROR-TOAST-4",
              });
            } else if (this.props.cartToast.requestStatus === -1) {
              toaster.notify("Sorry, you don't have permission to do that");
            }
          }
        } else if (
          this.props.cartData.cart.slots &&
          this.props.cartData.cart.slots.length > 0
        ) {
          await this.setState({
            cartSlotCount: parseInt(this.props.cartData.cart.slots.length),
            // showOnDrawer: "cartDetailsOnCalendarPage"
          });
          // await this.handleDrawer("open");
        }
      }
    }
  };

  // -----------------------------------------------------------------------
  // loadActivitiesInformation()
  // -----------------------------------------------------------------------
  loadActivitiesInformation = async () => {
    try {
      await this.props.dispatch(getActivities());
      if (this.props.clubActivityData) {
        if (this.props.clubActivityData.resourcesNotLoadedMessage) {
          resourcesNotLoadedToast();
        } else if (this.props.clubActivityData.httpErrorMessage) {
          toaster.danger(this.props.clubActivityData.httpErrorMessage, {
            id: "HTTP-ERROR-TOAST-3",
          });
        } else if (this.props.clubActivityData.requestStatus === 1) {
          await this.setState({
            activitiesData: this.props.clubActivityData,
          });
        } else if (this.props.clubActivityData.requestStatus === -1) {
          toaster.notify("Sorry, you don't have permission to do that");
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  // -----------------------------------------------------------------------
  // handleClearCart()
  // -----------------------------------------------------------------------
  handleClearCart = async () => {
    this.handleDrawer("close");
    await this.props.dispatch(clearCart());
    if (this.props.cartData && this.props.cartToast) {
      if (this.props.cartToast.resourcesNotLoadedMessage) {
        resourcesNotLoadedToast();
      } else if (this.props.cartToast.httpErrorMessage) {
        toaster.danger(this.props.cartToast.httpErrorMessage, {
          id: "HTTP-ERROR-TOAST-4",
        });
      } else if (this.props.cartToast.requestStatus === 0) {
        toaster.danger(stringifyIt(this.props.cartToast.message), {
          id: "HP-TOAST",
        });
      } else if (this.props.cartToast.requestStatus === 1) {
        toaster.success(this.props.cartToast.message, { id: "HP-TOAST" });
        await this.setState({
          cartSlotCount: 0,
          resetBookingCheckoutAll: true,
          activityType: null,
        });
      } else if (this.props.cartToast.requestStatus === -1) {
        toaster.notify("Sorry, you don't have permission to do that");
      }
    }
  };

  // -----------------------------------------------------------------------
  // createReqBodyForCartSlot()
  // -----------------------------------------------------------------------
  generateReqBodyForCartSlot = (
    slotData,
    date,
    selectedActivityId,
    slotBookingCount
  ) => {
    return {
      slotDuration: slotData.slotDuration,
      slot: {
        activityId: parseInt(selectedActivityId),
        activityName: this.state.activitiesData[selectedActivityId],
        activityType: slotData.activityType,
        count: parseInt(slotBookingCount),
        courtId: parseInt(slotData.courtId),
        courtName: slotData.courtName,
        courtBrothers: slotData.courtBrothers,
        slotDate: toDateFormat1(date),
        slotTime: slotData.slotTime,
        endTime: slotData.endTime,
        available: slotData.available,
        blocked: slotData.blocked,
        blockingId: slotData.blockingId,
        price: slotData.price,
        slotDiscount: slotData.slotDiscount,
      },
    };
  };

  // -----------------------------------------------------------------------
  // onAddToCart()
  // -----------------------------------------------------------------------
  onAddToCart = async (
    slotData,
    date,
    selectedActivityId,
    slotBookingCount = 1
  ) => {
    let reqBody = this.generateReqBodyForCartSlot(
      slotData,
      date,
      selectedActivityId,
      slotBookingCount
    );

    let activityType = this.state.activityType;

    if (activityType === reqBody.slot.activityType || activityType === null) {
      await this.setState({
        activityType: reqBody.slot.activityType,
      });
      await this.props.dispatch(addSlotToCart(reqBody));
      if (this.props.cartData && this.props.cartToast) {
        if (this.props.cartToast.resourcesNotLoadedMessage) {
          resourcesNotLoadedToast();
        } else if (this.props.cartToast.httpErrorMessage) {
          toaster.danger(this.props.cartToast.httpErrorMessage, {
            id: "HTTP-ERROR-TOAST-4",
          });
        } else if (this.props.cartToast.requestStatus === 0) {
          toaster.danger(stringifyIt(this.props.cartToast.message), {
            id: "HP-TOAST",
          });
        } else if (this.props.cartToast.requestStatus === 1) {
          toaster.success(this.props.cartToast.message, { id: "HP-TOAST" });
          await this.setState({
            cartSlotCount: parseInt(this.props.cartData.cart.slots.length),
            showOnDrawer: "cartDetailsOnCalendarPage",
          });
          await this.handleDrawer("open");
        } else if (this.props.cartToast.requestStatus === -1) {
          toaster.notify("Sorry, you don't have permission to do that");
        }
      }
    } else {
      toaster.warning("Can't book diffrent type of activities at once ");
    }
  };

  // -----------------------------------------------------------------------
  // onBookNow()
  // -----------------------------------------------------------------------
  onBookNow = async (
    slotData,
    date,
    selectedActivityId,
    slotBookingCount = 1
  ) => {
    let reqBody = this.generateReqBodyForCartSlot(
      slotData,
      date,
      selectedActivityId,
      slotBookingCount
    );

    let activityType = this.state.activityType;

    if (activityType === reqBody.slot.activityType || activityType === null) {
      await this.setState({
        bookingSlot: slotData,
        bookDate: date,
        activityType: reqBody.slot.activityType,
      });
      await this.props.dispatch(addSlotToCart(reqBody));
      if (this.props.cartData && this.props.cartToast) {
        if (this.props.cartToast.resourcesNotLoadedMessage) {
          resourcesNotLoadedToast();
        } else if (this.props.cartToast.httpErrorMessage) {
          toaster.danger(this.props.cartToast.httpErrorMessage, {
            id: "HTTP-ERROR-TOAST-4",
          });
        } else if (this.props.cartToast.requestStatus === 0) {
          toaster.danger(stringifyIt(this.props.cartToast.message), {
            id: "HP-TOAST",
          });
        } else if (this.props.cartToast.requestStatus === 1) {
          await this.setState({
            cartSlotCount: parseInt(this.props.cartData.cart.slots.length),
          });
          this.handleBookingCheckoutDisplay("show");
        } else if (this.props.cartToast.requestStatus === -1) {
          toaster.notify("Sorry, you don't have permission to do that");
        }
      }
    } else {
      toaster.warning("Can't book diffrent type of activities at once ");
    }
  };

  // -----------------------------------------------------------------------
  // onRemoveFromCart()
  // -----------------------------------------------------------------------
  onRemoveFromCart = async (cartedSlotId) => {
    await this.props.dispatch(removeFromCart(cartedSlotId));
    if (this.props.cartData && this.props.cartToast) {
      if (this.props.cartToast.resourcesNotLoadedMessage) {
        resourcesNotLoadedToast();
      } else if (this.props.cartToast.httpErrorMessage) {
        toaster.danger(this.props.cartToast.httpErrorMessage, {
          id: "HTTP-ERROR-TOAST-4",
        });
      } else if (this.props.cartToast.requestStatus === 0) {
        toaster.danger(stringifyIt(this.props.cartToast.message), {
          id: "HP-TOAST",
        });
      } else if (this.props.cartToast.requestStatus === 1) {
        if (this.props.cartData.cart.slots.length <= 0) {
          toaster.success(this.props.cartToast.message, { id: "HP-TOAST" });
          this.setState({
            cartSlotCount: 0,
            activityType: null,
          });
          await this.handleDrawer("close");
        } else {
          toaster.success(this.props.cartToast.message, { id: "HP-TOAST" });
          await this.setState({
            cartSlotCount: parseInt(this.props.cartData.cart.slots.length),
          });
        }
      } else if (this.props.cartToast.requestStatus === -1) {
        toaster.notify("Sorry, you don't have permission to do that");
      }
    }
  };

  // -----------------------------------------------------------------------
  // handleUnblocking()
  // -----------------------------------------------------------------------
  handleUnblocking = async (slotData) => {
    let reqBodyForUnblocking = {
      blocking_id: 0,
      slots: [
        {
          slotDate: slotData.slotDate,
          slotTime: slotData.slotTime,
          activityId: slotData.activityId,
          courtId: slotData.courtId,
          available: slotData.available,
        },
      ],
    };
    await this.props.dispatch(unblocking(reqBodyForUnblocking));
    if (this.props.unblockingToast) {
      if (this.props.unblockingToast.resourcesNotLoadedMessage) {
        resourcesNotLoadedToast();
      } else if (this.props.unblockingToast.httpErrorMessage) {
        toaster.danger(this.props.unblockingToast.httpErrorMessage, {
          id: "HTTP-ERROR-TOAST-4",
        });
      } else if (this.props.unblockingToast.requestStatus === 0) {
        toaster.warning(stringifyIt(this.props.unblockingToast.message), {
          id: "HP-TOAST",
        });
      } else if (this.props.unblockingToast.requestStatus === 1) {
        toaster.success(this.props.unblockingToast.message, { id: "HP-TOAST" });
        let reqBodyForSlotsAvailability = {
          activityIds: [parseInt(this.state.selectedActivityId)],
          activityStartDate: toDateFormat1(this.state.date),
          activityEndDate: toDateFormat1(this.state.date),
          customerStatus: 0,
        };
        await this.slotsListAvailabilityCheck(reqBodyForSlotsAvailability);
      } else if (this.props.unblockingToast.requestStatus === -1) {
        toaster.notify("Sorry, you don't have permission to do that");
      }
    }
  };

  handleAddnewMemberOnMemberShipCheckout = async () => {
    await this.setState({
      membershipPackagesCheckoutPageDisplay: "none",
      addMemberOnCheckout: true,
    });
    await this.handleSidebar("", "members");
    this.showPage("view-membership-packages-page");
  };

  // -----------------------------------------------------------------------
  // handleSidebar()
  // -----------------------------------------------------------------------
  handleSidebar = async (e, listItem) => {
    if (listItem === "calendar") {
      if (!this.state.openDrawerForCalendarPage) {
        this.changeRightPaneDivWidth("increase");
      }
      if (this.state.selectedActivityValue !== "select-a-sport") {
        let reqBody = {
          activityIds: [parseInt(this.state.selectedActivityId)],
          activityStartDate: toDateFormat1(this.state.date),
          activityEndDate: toDateFormat1(this.state.date),
          customerStatus: 0,
        };
        await this.slotsListAvailabilityCheck(reqBody);
      }
      if (
        this.props.cartData.cart &&
        this.props.cartData.cart.slots &&
        this.props.cartData.cart.slots.length > 0
      ) {
        this.setState({
          showOnDrawer: "cartDetailsOnCalendarPage",
        });
        await this.handleDrawer("open");
      }
      await this.rightPaneContentChange(listItem);
    } else if (listItem === "search") {
      await this.setState({ showBookingTableOnSearchPage: true });
      this.handleDrawer("close");
      await this.rightPaneContentChange(listItem);
    } else if (listItem === "members") {
      let reqParams = { page: this.state.pageNum };
      await this.props.dispatch(getMemberDetails(reqParams));
      if (this.props.memberDetails) {
        if (this.props.memberDetails.resourcesNotLoadedMessage) {
          resourcesNotLoadedToast();
        } else if (this.props.memberDetails.httpErrorMessage) {
          toaster.danger(this.props.memberDetails.httpErrorMessage, {
            id: "HTTP-ERROR-TOAST-4",
          });
        } else if (
          this.props.memberDetails.requestStatus === 0 ||
          this.props.memberDetails.requestStatus === 1
        ) {
          this.handleDrawer("close");
          await this.rightPaneContentChange(listItem);
        }
        //  else if (this.props.memberDetails.requestStatus === 0) {
        //   this.handleDrawer("close");
        //   await this.rightPaneContentChange(listItem);
        // } else if (this.props.memberDetails.requestStatus === 1) {
        //   this.handleDrawer("close");
        //   await this.rightPaneContentChange(listItem);
        // }
        else if (this.props.memberDetails.requestStatus === -1) {
          toaster.notify("Sorry, you don't have permission to do that");
        }
      }
    } else if (listItem === "membership-packages") {
      await this.rightPaneContentChange(listItem);
      // this.handleDrawer("close");

      // let reqParams = { page: this.state.pageNum };
      // await this.props.dispatch(getMembershipPackageDetails(reqParams));
      // if (this.props.membershipPackageDetails) {
      //   if (this.props.membershipPackageDetails.resourcesNotLoadedMessage) {
      //     resourcesNotLoadedToast();
      //   } else if (this.props.membershipPackageDetails.httpErrorMessage) {
      //     toaster.danger(this.props.membershipPackageDetails.httpErrorMessage, {
      //       id: "HTTP-ERROR-TOAST-4",
      //     });
      //   } else if (this.props.membershipPackageDetails.requestStatus === 0) {
      //     this.handleDrawer("close");
      //     await this.rightPaneContentChange(listItem);
      //   } else if (this.props.membershipPackageDetails.requestStatus === 1) {
      //     this.handleDrawer("close");
      //     await this.rightPaneContentChange(listItem);
      //   } else if (this.props.membershipPackageDetails.requestStatus === -1) {
      //     toaster.notify("Sorry, you don't have permission to do that");
      //   }
      // }
    } else if (listItem === "pricing") {
      this.handleDrawer("close");
      await this.props.dispatch(getPriceClass());
      if (this.props.priceClassDetails) {
        if (this.props.priceClassDetails.resourcesNotLoadedMessage) {
          resourcesNotLoadedToast();
        } else if (this.props.priceClassDetails.httpErrorMessage) {
          toaster.danger(this.props.priceClassDetails.httpErrorMessage, {
            id: "HTTP-ERROR-TOAST-4",
          });
        } else if (this.props.priceClassDetails.requestStatus === 1) {
          await this.rightPaneContentChange(listItem);
        } else if (this.props.priceClassDetails.requestStatus === -1) {
          toaster.notify("Sorry, you don't have permission to do that");
        }
      }
    } else if (listItem === "staff-console") {
      let userId = getPlayoUserId();
      this.handleDrawer("close");
      await this.props.dispatch(getStaffMember());
      if (this.props.staffMemberList) {
        if (this.props.staffMemberList.resourcesNotLoadedMessage) {
          resourcesNotLoadedToast();
        } else if (this.props.staffMemberList.httpErrorMessage) {
          toaster.danger(this.props.staffMemberList.httpErrorMessage, {
            id: "HTTP-ERROR-TOAST-4",
          });
        } else if (this.props.staffMemberList.requestStatus === 1) {
          await this.isUserAdmin(this.props.staffMemberList, userId);
          await this.showManagePermission(false);
          await this.rightPaneContentChange(listItem);
        } else if (this.props.staffMemberList.requestStatus === -1) {
          toaster.notify("Sorry, you don't have permission to do that");
        }
      }
    } else if (listItem === "addon-config") {
      let userId = getPlayoUserId();
      this.handleDrawer("close");
      await this.props.dispatch(getConfigExtraList());
      if (this.props.configExtrasList) {
        if (this.props.configExtrasList.resourcesNotLoadedMessage) {
          resourcesNotLoadedToast();
        } else if (this.props.configExtrasList.httpErrorMessage) {
          toaster.danger(this.props.configExtrasList.httpErrorMessage, {
            id: "HTTP-ERROR-TOAST-4",
          });
        } else if (this.props.configExtrasList.requestStatus === 1) {
          await this.rightPaneContentChange(listItem);
        } else if (this.props.configExtrasList.requestStatus === -1) {
          toaster.notify("Sorry, you don't have permission to do that");
        }
      }
    } else if (listItem === "generate-coupons") {
      this.handleDrawer("close");
      await this.props.dispatch(getCouponDetails({ couponId: "", page: 1 }));
      if (this.props.couponDetails) {
        if (this.props.couponDetails.resourcesNotLoadedMessage) {
          resourcesNotLoadedToast();
        } else if (this.props.couponDetails.httpErrorMessage) {
          toaster.danger(this.props.couponDetails.httpErrorMessage, {
            id: "HTTP-ERROR-TOAST-4",
          });
        } else if (this.props.couponDetails.requestStatus === 1) {
          await this.rightPaneContentChange(listItem);
        } else if (this.props.couponDetails.requestStatus === 0) {
          this.handleDrawer("close");
          await this.rightPaneContentChange(listItem);
        } else if (this.props.couponDetails.requestStatus === -1) {
          toaster.notify("Sorry, you don't have permission to do that");
        }
      }
    } else {
      this.handleDrawer("close");
      await this.rightPaneContentChange(listItem);
    }
  };

  // -----------------------------------------------------------------------
  // setShowBookingTableOnSearchPageFalse()
  // -----------------------------------------------------------------------
  setShowBookingTableOnSearchPageFalse = () => {
    this.setState({
      showBookingTableOnSearchPage: false,
    });
  };

  // -----------------------------------------------------------------------
  // rightPaneContentChange()
  // -----------------------------------------------------------------------
  rightPaneContentChange = (listItem) => {
    this.setState({
      rightPanelRender: listItem,
      selectedListItem: listItem,
    });
  };

  // -----------------------------------------------------------------------
  // isUserAdmin
  // -----------------------------------------------------------------------

  isUserAdmin = async (staffList, userId) => {
    let user = await staffList.data.filter((x) => x.userId == userId);

    if (user && user[0] && user[0].role.toLowerCase() === "admin") {
      await this.setState({
        isAdmin: true,
      });
    }
  };

  // -----------------------------------------------------------------------
  // showPage()
  // -----------------------------------------------------------------------
  showPage = async (page) => {
    if (page === "homepage") {
      await this.setState({
        homepageDisplay: "block",
        bookingCheckoutDisplay: "none",
        createMembershipPackagesPageDisplay: "none",
        membershipPackagesCheckoutPageDisplay: "none",
        allMembersListPageDisplay: "none",
        editPricingPageDisplay: "none",
        viewReportsPageDisplay: "none",
        viewMembershipPackagesPageDisplay: "none",
      });
    }
    if (page === "booking-checkout-page") {
      await this.setState({
        homepageDisplay: "none",
        bookingCheckoutDisplay: "block",
        createMembershipPackagesPageDisplay: "none",
        membershipPackagesCheckoutPageDisplay: "none",
        allMembersListPageDisplay: "none",
        editPricingPageDisplay: "none",
        viewReportsPageDisplay: "none",
        viewMembershipPackagesPageDisplay: "none",
      });
    }
    if (page === "create-membership-packages-page") {
      await this.setState({
        homepageDisplay: "none",
        bookingCheckoutDisplay: "none",
        createMembershipPackagesPageDisplay: "block",
        membershipPackagesCheckoutPageDisplay: "none",
        allMembersListPageDisplay: "none",
        editPricingPageDisplay: "none",
        viewReportsPageDisplay: "none",
        viewMembershipPackagesPageDisplay: "none",
      });
    }
    if (page === "membership-packages-checkout-page") {
      await this.setState({
        homepageDisplay: "none",
        bookingCheckoutDisplay: "none",
        createMembershipPackagesPageDisplay: "none",
        membershipPackagesCheckoutPageDisplay: "block",
        allMembersListPageDisplay: "none",
        editPricingPageDisplay: "none",
        viewReportsPageDisplay: "none",
        viewMembershipPackagesPageDisplay: "none",
      });
    }
    if (page === "all-members-list-page") {
      await this.setState({
        homepageDisplay: "none",
        bookingCheckoutDisplay: "none",
        createMembershipPackagesPageDisplay: "none",
        membershipPackagesCheckoutPageDisplay: "none",
        allMembersListPageDisplay: "block",
        editPricingPageDisplay: "none",
        viewReportsPageDisplay: "none",
        viewMembershipPackagesPageDisplay: "none",
      });
    }
    if (page === "edit-pricing-page") {
      await this.setState({
        homepageDisplay: "none",
        bookingCheckoutDisplay: "none",
        createMembershipPackagesPageDisplay: "none",
        membershipPackagesCheckoutPageDisplay: "none",
        allMembersListPageDisplay: "none",
        editPricingPageDisplay: "block",
        viewReportsPageDisplay: "none",
        viewMembershipPackagesPageDisplay: "none",
      });
    }
    if (page === "view-reports-page") {
      await this.setState({
        homepageDisplay: "none",
        bookingCheckoutDisplay: "none",
        createMembershipPackagesPageDisplay: "none",
        membershipPackagesCheckoutPageDisplay: "none",
        allMembersListPageDisplay: "none",
        editPricingPageDisplay: "none",
        viewReportsPageDisplay: "block",
        viewMembershipPackagesPageDisplay: "none",
      });
    }
    if (page === "view-membership-packages-page") {
      await this.setState({
        homepageDisplay: "none",
        bookingCheckoutDisplay: "none",
        createMembershipPackagesPageDisplay: "none",
        membershipPackagesCheckoutPageDisplay: "none",
        allMembersListPageDisplay: "none",
        editPricingPageDisplay: "none",
        viewReportsPageDisplay: "none",
        viewMembershipPackagesPageDisplay: "block",
        addMemberOnCheckout: false,
      });
    }
  };

  // -----------------------------------------------------------------------
  // handleBookingCheckoutDisplay()
  // -----------------------------------------------------------------------
  handleBookingCheckoutDisplay = async (type) => {
    if (type === "show") {
      await this.props.dispatch(getCart());
      if (this.props.cartData) {
        if (this.props.cartData.resourcesNotLoadedMessage) {
          resourcesNotLoadedToast();
        } else if (this.props.cartData.httpErrorMessage) {
          toaster.danger(this.props.cartData.httpErrorMessage, {
            id: "HTTP-ERROR-TOAST-2",
          });
        } else if (this.props.cartData.requestStatus === -1) {
          toaster.notify("Sorry, you don't have permission to do that");
        } else {
          if (
            this.props.cartData.cart.slots &&
            this.props.cartData.cart.slots.length > 0
          ) {
            await this.showPage("booking-checkout-page");
          }
        }
      }
    } else if (type === "hide") {
      await this.showPage("homepage");
    }
  };

  // -----------------------------------------------------------------------
  // handleCreateMembershipPackagesPageDisplay()
  // -----------------------------------------------------------------------
  handleCreateMembershipPackagesPageDisplay = async (
    type,
    goBackToPage = null
  ) => {
    if (goBackToPage !== null) {
      await this.setState({ goBackToPage: goBackToPage });
    }
    if (type === "show") {
      await this.showPage("create-membership-packages-page");
    }
    if (type === "hide") {
      if (this.state.goBackToPage !== null) {
        this.showPage(this.state.goBackToPage);
        await this.setState({ goBackToPage: null });
      } else {
        let reqParams = { page: this.state.pageNum };
        await this.props.dispatch(getMembershipPackageDetails(reqParams));
        if (this.props.membershipPackageDetails) {
          if (this.props.membershipPackageDetails.resourcesNotLoadedMessage) {
            resourcesNotLoadedToast();
          } else if (this.props.membershipPackageDetails.httpErrorMessage) {
            toaster.danger(
              this.props.membershipPackageDetails.httpErrorMessage,
              {
                id: "HTTP-ERROR-TOAST-4",
              }
            );
          } else if (this.props.membershipPackageDetails.requestStatus === 0) {
            await this.showPage("homepage");
          } else if (this.props.membershipPackageDetails.requestStatus === 1) {
            await this.showPage("homepage");
          } else if (this.props.membershipPackageDetails.requestStatus === -1) {
            toaster.notify("Sorry, you don't have permission to do that");
          }
        }
      }
    }
  };

  // -----------------------------------------------------------------------
  // handleMembershipPackagesCheckoutPageDisplay()
  // -----------------------------------------------------------------------
  handleMembershipPackagesCheckoutPageDisplay = async (
    type,
    goBackToPage = null
  ) => {
    if (goBackToPage !== null) {
      await this.setState({ goBackToPage: goBackToPage });
    }
    if (type === "show") {
      await this.showPage("membership-packages-checkout-page");
    }
    if (type === "hide") {
      if (this.state.goBackToPage !== null) {
        this.showPage(this.state.goBackToPage);
        await this.setState({ goBackToPage: null });
      } else {
        await this.showPage("homepage");
      }
    }
  };

  // -----------------------------------------------------------------------
  // handleAllMembersListPageDisplay()
  // -----------------------------------------------------------------------
  handleAllMembersListPageDisplay = async (
    type,
    packageId = null,
    goBackToPage = null
  ) => {
    if (goBackToPage !== null) {
      await this.setState({ goBackToPage: goBackToPage });
    }
    if (type === "show") {
      let reqParams = {};
      if (packageId === null) {
        reqParams = {};
      } else {
        reqParams = { packageId: packageId };
      }
      await this.props.dispatch(getMembershipSubscriptionDetails(reqParams));
      if (this.props.membershipSubscriptionDetails) {
        if (
          this.props.membershipSubscriptionDetails.resourcesNotLoadedMessage
        ) {
          resourcesNotLoadedToast();
        } else if (this.props.membershipSubscriptionDetails.httpErrorMessage) {
          toaster.danger(
            this.props.membershipSubscriptionDetails.httpErrorMessage,
            {
              id: "HTTP-ERROR-TOAST-4",
            }
          );
        } else if (
          this.props.membershipSubscriptionDetails.requestStatus === 0
        ) {
          toaster.warning(
            stringifyIt(this.props.membershipSubscriptionDetails.message),
            {
              id: "HP-TOAST",
            }
          );
        } else if (
          this.props.membershipSubscriptionDetails.requestStatus === 1
        ) {
          await this.showPage("all-members-list-page");
        } else if (
          this.props.membershipSubscriptionDetails.requestStatus === -1
        ) {
          toaster.notify("Sorry, you don't have permission to do that");
        }
      }
    }
    if (type === "hide") {
      if (this.state.goBackToPage !== null) {
        this.showPage(this.state.goBackToPage);
        await this.setState({ goBackToPage: null });
      } else {
        await this.showPage("homepage");
      }
    }
  };

  // -----------------------------------------------------------------------
  // handleEditPricingPageDisplay()
  // -----------------------------------------------------------------------
  handleEditPricingPageDisplay = async (type) => {
    if (type === "show") {
      if (this.props.pricingData) {
        if (this.props.pricingData.requestStatus === 1) {
          await this.showPage("edit-pricing-page");
        }
      }
    }
    if (type === "hide") {
      await this.showPage("homepage");
    }
  };

  // -----------------------------------------------------------------------
  // handleViewReportsPageDisplay()
  // -----------------------------------------------------------------------
  handleViewReportsPageDisplay = async (type) => {
    try {
      if (type === "show") {
        await this.showPage("view-reports-page");
      }
      if (type === "hide") {
        await this.showPage("homepage");
      }
    } catch (err) {
      toaster.danger("Some error has occured", { id: "HP-TOAST" });
    }
  };

  // -----------------------------------------------------------------------
  // handleViewAnalyticsPageDisplay()
  // -----------------------------------------------------------------------
  handleViewAnalyticsPageDisplay = async (type) => {
    try {
      if (type === "show") {
        await this.showPage("view-analytics-page");
      }
      if (type === "hide") {
        await this.showPage("homepage");
      }
    } catch (err) {
      toaster.danger("Some error has occured", { id: "HP-TOAST" });
    }
  };

  // -----------------------------------------------------------------------
  // handleViewMembershipPackagesPageDisplay()
  // -----------------------------------------------------------------------
  handleViewMembershipPackagesPageDisplay = async (type) => {
    try {
      if (type === "show") {
        let reqParams = { page: 1 };
        await this.props.dispatch(getMembershipPackageDetails(reqParams));
        if (this.props.membershipPackageDetails) {
          if (this.props.membershipPackageDetails.resourcesNotLoadedMessage) {
            resourcesNotLoadedToast();
          } else if (this.props.membershipPackageDetails.httpErrorMessage) {
            toaster.danger(
              this.props.membershipPackageDetails.httpErrorMessage,
              {
                id: "HTTP-ERROR-TOAST-4",
              }
            );
          } else if (
            this.props.membershipPackageDetails.requestStatus === 0 ||
            this.props.membershipPackageDetails.requestStatus === 1
          ) {
            await this.showPage("view-membership-packages-page");
          } else if (this.props.membershipPackageDetails.requestStatus === -1) {
            toaster.notify("Sorry, you don't have permission to do that");
          }
        }
      }
      if (type === "hide") {
        await this.viewMembershipPackagesPageFunctions.handlePageChange(1);
        await this.showPage("homepage");
      }
    } catch (err) {
      toaster.danger("Some error has occured", { id: "HP-TOAST" });
    }
  };

  // -----------------------------------------------------------------------
  // hidePremiumFeaturePage()
  // -----------------------------------------------------------------------
  hidePremiumFeaturePage = async () => {
    await this.props.dispatch(togglePremiumFeatureFlag(false));
  };

  // -----------------------------------------------------------------------
  // getClubExtrasInformation()
  // -----------------------------------------------------------------------
  getClubExtrasInformation = async () => {
    await this.props.dispatch(getClubExtrasList());
    if (this.props.clubExtrasList) {
      if (this.props.clubExtrasList.resourcesNotLoadedMessage) {
        resourcesNotLoadedToast();
      } else if (this.props.clubExtrasList.httpErrorMessage) {
        toaster.danger(this.props.clubExtrasList.httpErrorMessage, {
          id: "HTTP-ERROR-TOAST-5",
        });
      } else if (this.props.clubExtrasList.requestStatus === -1) {
        toaster.notify("Sorry, you don't have permission to do that");
      }
    }
  };

  // -----------------------------------------------------------------------
  // handleAddDeskPersonDialogVisibility()
  // -----------------------------------------------------------------------
  handleAddDeskPersonDialogVisibility = async (type) => {
    if (type === "open") {
      await this.setState({ showAddDeskPersonDialog: true });
    } else if (type === "close") {
      await this.setState({ showAddDeskPersonDialog: false });
    }
  };

  // -----------------------------------------------------------------------
  // handleChangePasswordDialogVisibility()
  // -----------------------------------------------------------------------
  handleChangePasswordDialogVisibility = async (type) => {
    if (type === "open") {
      await this.setState({ showChangePasswordDialog: true });
    } else if (type === "close") {
      await this.setState({ showChangePasswordDialog: false });
    }
  };

  // -----------------------------------------------------------------------
  // handleSwitchAccountDialogVisibility()
  // -----------------------------------------------------------------------
  handleSwitchAccountDialogVisibility = async (type) => {
    if (type === "open") {
      await this.setState({ showSwitchAccountDialog: true });
    } else if (type === "close") {
      await this.setState({ showSwitchAccountDialog: false });
    }
  };

  // -----------------------------------------------------------------------
  // handleSwitch()
  // -----------------------------------------------------------------------
  handleSwitchAccount = async (e, club) => {
    await this.props.dispatch(clearCart());
    await this.props.dispatch(switchAccount(club));
    removePlayoTokens();
    if (this.props.switchAccountDetails) {
      cookies.set(
        "playoAuthToken",
        this.props.switchAccountDetails.userAuth.authToken.toString(),
        {
          path: "/",
          maxAge: 31536000,
        }
      );
      cookies.set(
        "playoUserName",
        this.props.switchAccountDetails.userAuth.username.toString(),
        {
          path: "/",
          maxAge: 31536000,
        }
      );
      cookies.set(
        "playoClubName",
        this.props.switchAccountDetails.userAuth.clubName.toString(),
        {
          path: "/",
          maxAge: 31536000,
        }
      );
      cookies.set(
        "playoClubCountryCallingCode",
        this.props.switchAccountDetails.userAuth.countryCode.toString(),
        {
          path: "/",
          // maxAge: 31536000
        }
      );
      cookies.set(
        "playoClubCurrency",
        this.props.switchAccountDetails.userAuth.clubCurrency.toString(),
        {
          path: "/",
          // maxAge: 31536000
        }
      );
      cookies.set(
        "playoUserClubs",
        JSON.stringify(this.props.switchAccountDetails.userAuth.userClubs),
        {
          path: "/",
          // maxAge: 31536000
        }
      );
      cookies.set(
        "playoClubId",
        this.props.switchAccountDetails.userAuth.clubId.toString(),
        {
          path: "/",
          // maxAge: 31536000
        }
      );
    }
    this.handleSidebar("", "calendar");
    this.setState({
      ...this.baseState,
      userClubs: this.props.switchAccountDetails.userAuth.userClubs,
      currentClub: this.props.switchAccountDetails.userAuth.clubId,
    });
    await this.onPageLoad();
    this.handleSidebar("", "dashboard");
  };

  // -----------------------------------------------------------------------
  // handleContactSupportDialogVisibility()
  // -----------------------------------------------------------------------
  handleContactSupportDialogVisibility = async (type) => {
    if (type === "open") {
      await this.props.dispatch(getEscalationMatrix());
      if (this.props.escalationMatrixData) {
        if (this.props.escalationMatrixData.resourcesNotLoadedMessage) {
          resourcesNotLoadedToast();
        } else if (this.props.escalationMatrixData.httpErrorMessage) {
          toaster.danger(this.props.escalationMatrixData.httpErrorMessage, {
            id: "HTTP-ERROR-TOAST-4",
          });
        } else if (this.props.escalationMatrixData.requestStatus === 0) {
          toaster.warning(
            stringifyIt(this.props.escalationMatrixData.message),
            {
              id: "HP-TOAST",
            }
          );
        } else if (this.props.escalationMatrixData.requestStatus === 1) {
          await this.setState({ showContactSupportDialog: true });
        } else if (this.props.escalationMatrixData.requestStatus === -1) {
          toaster.notify("Sorry, you don't have permission to do that");
        }
      }
    } else if (type === "close") {
      await this.setState({ showContactSupportDialog: false });
    }
  };

  // -----------------------------------------------------------------------
  // handleSeeAllBulkBookingsFromBulkForm()
  // -----------------------------------------------------------------------
  handleSeeAllBulkBookingsFromBulkForm = async (e) => {
    e.persist();
    await this.setState({ seeAllBulkBookingsFromBulkForm: true });
    await this.handleSidebar(e, "search");
  };

  // -----------------------------------------------------------------------
  // setSeeAllBulkBookingsFromBulkFormFalse()
  // -----------------------------------------------------------------------
  setSeeAllBulkBookingsFromBulkFormFalse = async () => {
    await this.setState({
      seeAllBulkBookingsFromBulkForm: false,
    });
  };

  // -----------------------------------------------------------------------
  // setResetBookingCheckoutInputFieldsTrue()
  // -----------------------------------------------------------------------
  setResetBookingCheckoutInputFieldsTrue = async () => {
    await this.setState({
      resetBookingCheckoutInputFields: true,
    });
  };

  // -----------------------------------------------------------------------
  // setResetBookingCheckoutInputFieldsFalse()
  // -----------------------------------------------------------------------
  setResetBookingCheckoutInputFieldsFalse = async () => {
    await this.setState({
      resetBookingCheckoutInputFields: false,
    });
  };

  // -----------------------------------------------------------------------
  // setResetBookingCheckoutAllFalse()
  // -----------------------------------------------------------------------
  setResetBookingCheckoutAllFalse = async () => {
    await this.setState({
      resetBookingCheckoutAll: false,
    });
  };

  // ----------------------------------------------------------------------------------------------------------------------------------------------
  // ViewMembershipPackagesPage Functions
  // ----------------------------------------------------------------------------------------------------------------------------------------------
  viewMembershipPackagesPageFunctions = {
    // -----------------------------------------------------------------------
    // mutateViewMembershipPackagesPageData()
    // -----------------------------------------------------------------------
    mutateViewMembershipPackagesPageData: async (propertyName, value) => {
      const viewMembershipPackagesPageData =
        this.state.viewMembershipPackagesPageData;
      const newViewMembershipPackagesPageData = update(
        viewMembershipPackagesPageData,
        propertyName === "showMenuPopover"
          ? { showMenuPopover: { $set: value } }
          : propertyName === "activePackageDetails"
          ? { activePackageDetails: { $set: value } }
          : propertyName === "currentPage"
          ? { currentPage: { $set: value } }
          : null
      );
      await this.setState({
        viewMembershipPackagesPageData: newViewMembershipPackagesPageData,
      });
    },
    // -----------------------------------------------------------------------
    // toggleMenuPopover()
    // -----------------------------------------------------------------------
    toggleMenuPopover: async (type) => {
      if (type === "show") {
        this.viewMembershipPackagesPageFunctions.mutateViewMembershipPackagesPageData(
          "showMenuPopover",
          true
        );
      } else if (type === "hide") {
        this.viewMembershipPackagesPageFunctions.mutateViewMembershipPackagesPageData(
          "showMenuPopover",
          false
        );
      }
    },
    // -----------------------------------------------------------------------
    // handleAddMember()
    // -----------------------------------------------------------------------
    handleAddMember: async (goBackToPage = null) => {
      this.viewMembershipPackagesPageFunctions.toggleMenuPopover("hide");
      await this.props.dispatch(
        saveSelectedMembershipPackageDetails(
          this.state.viewMembershipPackagesPageData.activePackageDetails,
          null
        )
      );
      this.viewMembershipPackagesPageFunctions.toggleMenuPopover("show");
      if (
        this.props.selectedMembershipPackageDetails &&
        this.props.selectedMembershipPackageDetails.requestStatus === 1
      ) {
        await this.handleMembershipPackagesCheckoutPageDisplay(
          "show",
          goBackToPage
        );
      }
    },
    // -----------------------------------------------------------------------
    // changeActivePackageDetails()
    // -----------------------------------------------------------------------
    changeActivePackageDetails: async (packageDetails) => {
      this.viewMembershipPackagesPageFunctions.mutateViewMembershipPackagesPageData(
        "activePackageDetails",
        packageDetails
      );
    },
    // -----------------------------------------------------------------------
    // handleEditPackage()
    // -----------------------------------------------------------------------
    handleEditPackage: async (goBackToPage = null) => {
      this.viewMembershipPackagesPageFunctions.toggleMenuPopover("hide");
      await this.props.dispatch(
        saveMembershipPackageDetailsForEditing(
          this.state.viewMembershipPackagesPageData.activePackageDetails
        )
      );
      this.viewMembershipPackagesPageFunctions.toggleMenuPopover("show");
      if (
        this.props.membershipPackageDetailsForEditing &&
        this.props.membershipPackageDetailsForEditing.requestStatus === 1
      ) {
        await this.handleCreateMembershipPackagesPageDisplay(
          "show",
          goBackToPage
        );
      }
    },
    // -----------------------------------------------------------------------
    // getMembershipPackages()
    // -----------------------------------------------------------------------
    getMembershipPackages: async () => {
      let reqParams = {
        page: this.state.viewMembershipPackagesPageData.currentPage,
      };
      await this.props.dispatch(getMembershipPackageDetails(reqParams));
      if (this.props.membershipPackageDetails) {
        if (this.props.membershipPackageDetails.resourcesNotLoadedMessage) {
          resourcesNotLoadedToast();
        } else if (this.props.membershipPackageDetails.httpErrorMessage) {
          toaster.danger(this.props.membershipPackageDetails.httpErrorMessage, {
            id: "HTTP-ERROR-TOAST-4",
          });
        } else if (this.props.membershipPackageDetails.requestStatus === -1) {
          toaster.notify("Sorry, you don't have permission to do that");
        } else if (this.props.membershipPackageDetails.requestStatus === 0) {
          toaster.danger(this.props.membershipPackageDetails.message, {
            id: "HP-TOAST",
          });
        }
      }
    },
    // -----------------------------------------------------------------------
    // reActivatePackage()
    // -----------------------------------------------------------------------
    reActivatePackage: async () => {
      let reqBody = {
        packageId:
          this.state.viewMembershipPackagesPageData.activePackageDetails
            .packageId,
        packageName:
          this.state.viewMembershipPackagesPageData.activePackageDetails
            .packageName,
        price:
          this.state.viewMembershipPackagesPageData.activePackageDetails
            .packagePrice,
        remarks:
          this.state.viewMembershipPackagesPageData.activePackageDetails
            .description,
        credits:
          this.state.viewMembershipPackagesPageData.activePackageDetails
            .packageCredits,
        memberLimit:
          this.state.viewMembershipPackagesPageData.activePackageDetails
            .memberLimit,
        additionalInfo:
          this.state.viewMembershipPackagesPageData.activePackageDetails
            .additionalInfo,
        type:
          this.state.viewMembershipPackagesPageData.activePackageDetails
            .type === "Rotational"
            ? 1
            : 2,
        startDate:
          this.state.viewMembershipPackagesPageData.activePackageDetails
            .startDate,
        period:
          this.state.viewMembershipPackagesPageData.activePackageDetails.period,
        isActive: true,
      };
      this.viewMembershipPackagesPageFunctions.toggleMenuPopover("hide");
      await this.props.dispatch(updateMembershipPackageDetails(reqBody));
      this.viewMembershipPackagesPageFunctions.toggleMenuPopover("show");
      await this.viewMembershipPackagesPageFunctions.getMembershipPackages();
      if (this.props.updateMembershipPackageDetailsToast) {
        if (
          this.props.updateMembershipPackageDetailsToast
            .resourcesNotLoadedMessage
        ) {
          resourcesNotLoadedToast();
        } else if (
          this.props.updateMembershipPackageDetailsToast.httpErrorMessage
        ) {
          toaster.danger(
            this.props.updateMembershipPackageDetailsToast.httpErrorMessage,
            {
              id: "HTTP-ERROR-TOAST-4",
            }
          );
        } else if (
          this.props.updateMembershipPackageDetailsToast.requestStatus === -1
        ) {
          toaster.notify("Sorry, you don't have permission to do that");
        } else if (
          this.props.updateMembershipPackageDetailsToast.requestStatus === 0
        ) {
          toaster.danger(
            this.props.updateMembershipPackageDetailsToast.message,
            {
              id: "HP-TOAST",
            }
          );
        } else if (
          this.props.updateMembershipPackageDetailsToast.requestStatus === 1
        ) {
          this.handleViewMembershipPackagesPageDisplay("hide");
          toaster.success(
            this.props.updateMembershipPackageDetailsToast.message,
            { id: "MPP-TOAST-1" }
          );
        }
      }
    },
    // -----------------------------------------------------------------------
    // deActivatePackage()
    // -----------------------------------------------------------------------
    deActivatePackage: async () => {
      let reqBody = {
        packageId:
          this.state.viewMembershipPackagesPageData.activePackageDetails
            .packageId,
        packageName:
          this.state.viewMembershipPackagesPageData.activePackageDetails
            .packageName,
        price:
          this.state.viewMembershipPackagesPageData.activePackageDetails
            .packagePrice,
        remarks:
          this.state.viewMembershipPackagesPageData.activePackageDetails
            .description,
        credits:
          this.state.viewMembershipPackagesPageData.activePackageDetails
            .packageCredits,
        memberLimit:
          this.state.viewMembershipPackagesPageData.activePackageDetails
            .memberLimit,
        additionalInfo:
          this.state.viewMembershipPackagesPageData.activePackageDetails
            .additionalInfo,
        type:
          this.state.viewMembershipPackagesPageData.activePackageDetails
            .type === "Rotational"
            ? 1
            : 2,
        startDate:
          this.state.viewMembershipPackagesPageData.activePackageDetails
            .startDate,
        period:
          this.state.viewMembershipPackagesPageData.activePackageDetails.period,
        isActive: false,
      };
      this.viewMembershipPackagesPageFunctions.toggleMenuPopover("hide");
      await this.props.dispatch(updateMembershipPackageDetails(reqBody));
      this.viewMembershipPackagesPageFunctions.toggleMenuPopover("show");
      await this.viewMembershipPackagesPageFunctions.getMembershipPackages();
      if (this.props.updateMembershipPackageDetailsToast) {
        if (
          this.props.updateMembershipPackageDetailsToast
            .resourcesNotLoadedMessage
        ) {
          resourcesNotLoadedToast();
        } else if (
          this.props.updateMembershipPackageDetailsToast.httpErrorMessage
        ) {
          toaster.danger(
            this.props.updateMembershipPackageDetailsToast.httpErrorMessage,
            {
              id: "HTTP-ERROR-TOAST-4",
            }
          );
        } else if (
          this.props.updateMembershipPackageDetailsToast.requestStatus === 0
        ) {
          toaster.danger(
            this.props.updateMembershipPackageDetailsToast.message,
            {
              id: "HP-TOAST",
            }
          );
        } else if (
          this.props.updateMembershipPackageDetailsToast.requestStatus === 1
        ) {
          toaster.success(
            this.props.updateMembershipPackageDetailsToast.message,
            { id: "MPP-TOAST-1" }
          );
        }
      }
    },
    // -----------------------------------------------------------------------
    // handlePageChange()
    // -----------------------------------------------------------------------
    handlePageChange: async (newPageNum) => {
      await this.viewMembershipPackagesPageFunctions.mutateViewMembershipPackagesPageData(
        "currentPage",
        newPageNum
      );
      await this.viewMembershipPackagesPageFunctions.getMembershipPackages();
    },
  };

  showManagePermission = async (value, staffMember) => {
    let { isAdmin } = this.state;
    if (isAdmin) {
      await this.setState({
        managePermissionPage: value,
        staffMember: staffMember,
      });
    } else {
      toaster.danger("You dont have permission");
    }
  };

  // ----------------------------------------------------------------------------------------------------------------------------------------------
  //
  // ----------------------------------------------------------------------------------------------------------------------------------------------
  // -----------------------------------------------------------------------
  // render()
  // -----------------------------------------------------------------------
  render() {
    const {
      date,
      cartSlotCount,
      homepageDisplay,
      bookingCheckoutDisplay,
      showOnDrawer,
      searchedFromHomepage,
      searchStringOnHomepage,
      openDrawerForCalendarPage,
      seeBookingsFromMembersDrawer,
      seeBookingsFromMembersDrawerMemberMobile,
      createMembershipPackagesPageDisplay,
      membershipPackagesCheckoutPageDisplay,
      allMembersListPageDisplay,
      editPricingPageDisplay,
      viewReportsPageDisplay,
      defaultActivityName,
      defaultActivityValue,
      selectedActivityValue,
      selectedActivityName,
      playoUserName,
      disableShowAvailability,
      playoClubName,
      showAddDeskPersonDialog,
      showChangePasswordDialog,
      showSwitchAccountDialog,
      seeAllBookingsFromBulkBookingDetailsDrawerBulkBookingId,
      seeAllBookingsFromBulkBookingDetailsDrawer,
      showContactSupportDialog,
      showBookingTableOnSearchPage,
      seeAllBulkBookingsFromBulkForm,
      resetBookingCheckoutAll,
      resetBookingCheckoutInputFields,
      viewMembershipPackagesPageDisplay,
      userClubs,
      currentClub,
      managePermissionPage,
      staffMember,
      isAdmin,
      bookingSlot,
      bookDate,
    } = this.state;
    const {
      clubActivityData,
      clubExtrasList,
      slotsList,
      cartData,
      // clubHandlersList,
      showLoader,
      bookingDetails,
      transactionCodesAndTransactionTypes,
      premiumFeatureFlag,
      staffMemberList,
    } = this.props;
    if (!checkAuthTokenExists()) {
      return <Redirect to="/login" />;
    } else {
      return clubActivityData &&
        clubActivityData.requestStatus === 1 &&
        // clubExtrasList &&
        // clubExtrasList.requestStatus === 1 &&
        // cartData &&
        // !cartData.httpErrorMessage &&
        // cartData.requestStatus === 1 &&
        // clubHandlersList &&
        // clubHandlersList.requestStatus === 1 &&
        transactionCodesAndTransactionTypes &&
        transactionCodesAndTransactionTypes.requestStatus === 1 ? (
        <React.Fragment>
          {!premiumFeatureFlag ? (
            <React.Fragment>
              <div
                className="home-page-components-holder"
                style={{ display: homepageDisplay }}
              >
                <PageBody>
                  <Sidebar
                    hideDashBoard={this.state.hideDashBoard}
                    handleLogout={this.handleLogout}
                    handleSidebar={this.handleSidebar}
                    selectedListItem={this.state.selectedListItem}
                    playoUserName={playoUserName}
                    playoClubName={playoClubName}
                    handleAddDeskPersonDialogVisibility={
                      this.handleAddDeskPersonDialogVisibility
                    }
                    handleChangePasswordDialogVisibility={
                      this.handleChangePasswordDialogVisibility
                    }
                    handleSwitchAccountDialogVisibility={
                      this.handleSwitchAccountDialogVisibility
                    }
                    handleContactSupportDialogVisibility={
                      this.handleContactSupportDialogVisibility
                    }
                    userClubsCount={userClubs.length}
                  />
                  <div
                    className="right-panel-div"
                    style={{
                      width: this.state.rightPanelDivWidth,
                      maxWidth: this.state.rightPanelDivWidth,
                      overflow: "auto",
                    }}
                  >
                    <div
                      className="calendar-component"
                      style={{
                        display:
                          this.state.rightPanelRender === "calendar"
                            ? "block"
                            : "none",
                      }}
                    >
                      <React.Fragment>
                        <Header headerText={"Booking Calendar"} />
                        <HomePageActionBar
                          date={date}
                          showCartDrawer={this.showCartDrawer}
                          cartSlotCount={cartSlotCount}
                          defaultActivityName={defaultActivityName}
                          defaultActivityValue={defaultActivityValue}
                          selectedActivityValue={selectedActivityValue}
                          handleDateChange={this.handleDateChange}
                          handleActivityChange={this.handleActivityChange}
                          handleShowAvailability={this.handleShowAvailability}
                          handleSearchOnHomepage={this.handleSearchOnHomepage}
                          handleSearchOnHomepageChange={
                            this.handleSearchOnHomepageChange
                          }
                          disableShowAvailability={disableShowAvailability}
                        />
                        {this.state.slotsListFlag && slotsList ? (
                          <SlotTable
                            slotsList={slotsList}
                            date={this.state.date}
                            selectedActivityId={this.state.selectedActivityId}
                            onAddToCart={this.onAddToCart}
                            onBookNow={this.onBookNow}
                            handleDrawer={this.handleDrawer}
                            handleUnblocking={this.handleUnblocking}
                            cartData={cartData}
                            updateCalendarPage={this.updateCalendarPage}
                            handleGetBookingDetails={
                              this.handleGetBookingDetails
                            }
                            handleGetBookingDetailsOnHover={
                              this.handleGetBookingDetailsOnHover
                            }
                            handleGetSlotLockedStatus={
                              this.handleGetSlotLockedStatus
                            }
                            bookingDetails={this.props.bookingDetailsOnHover}
                          />
                        ) : (
                          <BlankPageGuide msg="Select an Activity to See Booking Calendar" />
                        )}
                      </React.Fragment>
                    </div>

                    {this.state.rightPanelRender === "dashboard" && (
                      <div
                        className="bulk-booking-component"
                        //   style={{
                        //     display:
                        //       this.state.rightPanelRender === "dashboard"
                        //         ? "block"
                        //         : "none",
                        //   }}
                      >
                        <Dashboard
                          hideSidePanelItem={this.hideSidePanelItem}
                          handleSidebar={this.handleSidebar}
                        />
                      </div>
                    )}

                    <div
                      className="bulk-booking-component"
                      style={{
                        display:
                          this.state.rightPanelRender === "bulk-booking"
                            ? "block"
                            : "none",
                      }}
                    >
                      <BulkBooking
                        handleBookingCheckoutDisplay={
                          this.handleBookingCheckoutDisplay
                        }
                        handleSeeAllBulkBookingsFromBulkForm={
                          this.handleSeeAllBulkBookingsFromBulkForm
                        }
                        setResetBookingCheckoutInputFieldsTrue={
                          this.setResetBookingCheckoutInputFieldsTrue
                        }
                      />
                    </div>
                    <div
                      className="block-slots-component"
                      style={{
                        display:
                          this.state.rightPanelRender === "block-slots"
                            ? "block"
                            : "none",
                      }}
                    >
                      <BlockSlots
                        handleBookingCheckoutDisplay={
                          this.handleBookingCheckoutDisplay
                        }
                      />
                    </div>
                    <div
                      className="search-component"
                      style={{
                        display:
                          this.state.rightPanelRender === "search"
                            ? "block"
                            : "none",
                      }}
                    >
                      <Search
                        updateBookingDetails={this.updateBookingDetails}
                        handleGetBookingDetails={this.handleGetBookingDetails}
                        searchedFromHomepage={searchedFromHomepage}
                        searchStringOnHomepage={searchStringOnHomepage}
                        setSearchedFromHomepageFalse={
                          this.setSearchedFromHomepageFalse
                        }
                        changeRightPaneDivWidth={this.changeRightPaneDivWidth}
                        seeBookingsFromMembersDrawer={
                          seeBookingsFromMembersDrawer
                        }
                        seeBookingsFromMembersDrawerMemberMobile={
                          seeBookingsFromMembersDrawerMemberMobile
                        }
                        setSeeBookingsFromMembersDrawerFalse={
                          this.setSeeBookingsFromMembersDrawerFalse
                        }
                        seeAllBookingsFromBulkBookingDetailsDrawer={
                          seeAllBookingsFromBulkBookingDetailsDrawer
                        }
                        setSeeAllBookingsFromBulkBookingDetailsDrawerFalse={
                          this
                            .setSeeAllBookingsFromBulkBookingDetailsDrawerFalse
                        }
                        seeAllBookingsFromBulkBookingDetailsDrawerBulkBookingId={
                          seeAllBookingsFromBulkBookingDetailsDrawerBulkBookingId
                        }
                        showBookingTableOnSearchPage={
                          showBookingTableOnSearchPage
                        }
                        setShowBookingTableOnSearchPageFalse={
                          this.setShowBookingTableOnSearchPageFalse
                        }
                        seeAllBulkBookingsFromBulkForm={
                          seeAllBulkBookingsFromBulkForm
                        }
                        setSeeAllBulkBookingsFromBulkFormFalse={
                          this.setSeeAllBulkBookingsFromBulkFormFalse
                        }
                      />
                    </div>
                    {this.state.rightPanelRender === "members" && (
                      <div className="members-component">
                        <Members
                          changeRightPaneDivWidth={this.changeRightPaneDivWidth}
                          handleSeeBookingsFromMembersDrawer={
                            this.handleSeeBookingsFromMembersDrawer
                          }
                          handleMembershipPackagesCheckoutPageDisplay={
                            this.handleMembershipPackagesCheckoutPageDisplay
                          }
                          addMemberOnCheckout={this.state.addMemberOnCheckout}
                        />
                      </div>
                    )}
                    {this.state.rightPanelRender === "membership-packages" && (
                      <div>
                        {/* <ComingSoon /> */}
                        <MembershipPackages
                          handleCreateMembershipPackagesPageDisplay={
                            this.handleCreateMembershipPackagesPageDisplay
                          }
                          handleAllMembersListPageDisplay={
                            this.handleAllMembersListPageDisplay
                          }
                          handleMembershipPackagesCheckoutPageDisplay={
                            this.handleMembershipPackagesCheckoutPageDisplay
                          }
                          handleViewMembershipPackagesPageDisplay={
                            this.handleViewMembershipPackagesPageDisplay
                          }
                          handleSidebar={this.handleSidebar}
                        />
                      </div>
                    )}
                    <div
                      className="generate-coupons-component"
                      style={{
                        display:
                          this.state.rightPanelRender === "generate-coupons"
                            ? "block"
                            : "none",
                      }}
                    >
                      <GenerateCoupons />
                    </div>
                    <div
                      className="pricing-component"
                      style={{
                        display:
                          this.state.rightPanelRender === "pricing"
                            ? "block"
                            : "none",
                      }}
                    >
                      <Pricing
                        handleEditPricingPageDisplay={
                          this.handleEditPricingPageDisplay
                        }
                      />
                    </div>
                    {this.state.rightPanelRender === "event-dashboard" && (
                      <div
                        className="bulk-booking-component"
                        //   style={{
                        //     display:
                        //       this.state.rightPanelRender === "dashboard"
                        //         ? "block"
                        //         : "none",
                        //   }}
                      >
                        <EventDashBoard
                          handleGetBookingDetails={this.handleGetBookingDetails}
                          handleSidebar={this.handleSidebar}
                        />
                      </div>
                    )}
                    <div
                      className="staff-console"
                      style={{
                        display:
                          this.state.rightPanelRender === "staff-console"
                            ? "block"
                            : "none",
                      }}
                    >
                      <StaffConsole
                        staffList={staffMemberList}
                        handleAddDeskPersonDialogVisibility={
                          this.handleAddDeskPersonDialogVisibility
                        }
                        isAdmin={isAdmin}
                        handleSidebar={this.handleSidebar}
                        managePermissionPage={managePermissionPage}
                        staffMember={staffMember}
                        showManagePermission={this.showManagePermission}
                      />
                    </div>
                    <div
                      className="addon-config"
                      style={{
                        display:
                          this.state.rightPanelRender === "addon-config"
                            ? "block"
                            : "none",
                      }}
                    >
                      <AddonConfig
                        staffList={staffMemberList}
                        handleAddDeskPersonDialogVisibility={
                          this.handleAddDeskPersonDialogVisibility
                        }
                        isAdmin={isAdmin}
                        handleSidebar={this.handleSidebar}
                        managePermissionPage={managePermissionPage}
                        staffMember={staffMember}
                        showManagePermission={this.showManagePermission}
                      />
                    </div>
                    <div
                      className="reports-component"
                      style={{
                        display:
                          this.state.rightPanelRender === "reports"
                            ? "block"
                            : "none",
                      }}
                    >
                      <Reports
                        handleViewReportsPageDisplay={
                          this.handleViewReportsPageDisplay
                        }
                      />
                    </div>
                    <div
                      className="reports-component"
                      style={{
                        display:
                          this.state.rightPanelRender === "analytics"
                            ? "block"
                            : "none",
                      }}
                    >
                      <Analytics
                        handleViewAnalyticsPageDisplay={
                          this.handleViewAnalyticsPageDisplay
                        }
                      />
                    </div>
                  </div>
                  {showAddDeskPersonDialog && (
                    <AddDeskPersonDialogContent
                      handleSidebar={this.handleSidebar}
                      showAddDeskPersonDialog={showAddDeskPersonDialog}
                      handleAddDeskPersonDialogVisibility={
                        this.handleAddDeskPersonDialogVisibility
                      }
                    />
                  )}
                  {showChangePasswordDialog && (
                    <ChangePasswordDialogContent
                      showChangePasswordDialog={showChangePasswordDialog}
                      handleChangePasswordDialogVisibility={
                        this.handleChangePasswordDialogVisibility
                      }
                    />
                  )}
                  {showSwitchAccountDialog && userClubs.length > 1 && (
                    <SwitchAccountDialogContent
                      showSwitchAccountDialog={showSwitchAccountDialog}
                      handleSwitchAccountDialogVisibility={
                        this.handleSwitchAccountDialogVisibility
                      }
                      userClubs={userClubs}
                      currentClub={currentClub}
                      handleSwitchAccount={this.handleSwitchAccount}
                    />
                  )}
                  {showContactSupportDialog && (
                    <ContactSupportDialogContent
                      showContactSupportDialog={showContactSupportDialog}
                      handleContactSupportDialogVisibility={
                        this.handleContactSupportDialogVisibility
                      }
                    />
                  )}
                </PageBody>
                <DrawerHolder
                  showOnDrawer={showOnDrawer}
                  cartData={cartData}
                  handleDrawer={this.handleDrawer}
                  bookingDetails={bookingDetails}
                  openDrawer={openDrawerForCalendarPage}
                  handleClearCart={this.handleClearCart}
                  onRemoveFromCart={this.onRemoveFromCart}
                  selectedActivityName={selectedActivityName}
                  handleBookingCheckoutDisplay={
                    this.handleBookingCheckoutDisplay
                  }
                  updateCalendarPage={this.updateCalendarPage}
                  updateBookingDetails={this.updateBookingDetails}
                  handleSeeAllBookingsfromBulkBookingDetailsDrawer={
                    this.handleSeeAllBookingsfromBulkBookingDetailsDrawer
                  }
                  handleShowAvailability={this.handleShowAvailability}
                />
              </div>
              <div
                className="booking-checkout-component-holder"
                style={{ display: bookingCheckoutDisplay }}
              >
                <BookingCheckout
                  resetCartCount={this.resetCartCount}
                  showCartDrawer={this.showCartDrawer}
                  handleDrawer={this.handleDrawer}
                  handleBookingCheckoutDisplay={
                    this.handleBookingCheckoutDisplay
                  }
                  bookingSlot={bookingSlot}
                  bookDate={bookDate}
                  updateCalendarPage={this.updateCalendarPage}
                  handleGetBookingDetails={this.handleGetBookingDetails}
                  handleGetIndividualBulkBookingDetails={
                    this.handleGetIndividualBulkBookingDetails
                  }
                  bookingCheckoutDisplay={bookingCheckoutDisplay}
                  setResetBookingCheckoutAllFalse={
                    this.setResetBookingCheckoutAllFalse
                  }
                  resetBookingCheckoutAll={resetBookingCheckoutAll}
                  resetBookingCheckoutInputFields={
                    resetBookingCheckoutInputFields
                  }
                  setResetBookingCheckoutInputFieldsFalse={
                    this.setResetBookingCheckoutInputFieldsFalse
                  }
                />
              </div>
              <div
                className="create-membership-packages-component-holder"
                style={{ display: createMembershipPackagesPageDisplay }}
              >
                <CreateMembershipPackagesPage
                  handleCreateMembershipPackagesPageDisplay={
                    this.handleCreateMembershipPackagesPageDisplay
                  }
                  handleViewMembershipPackagesPageDisplay={
                    this.handleViewMembershipPackagesPageDisplay
                  }
                />
              </div>
              {membershipPackagesCheckoutPageDisplay === "block" && (
                <div className="membership-packages-checkout-component-holder">
                  <MembershipPackagesCheckoutPage
                    handleMembershipPackagesCheckoutPageDisplay={
                      this.handleMembershipPackagesCheckoutPageDisplay
                    }
                    handleViewMembershipPackagesPageDisplay={
                      this.handleViewMembershipPackagesPageDisplay
                    }
                    handleAddnewMemberOnMemberShipCheckout={
                      this.handleAddnewMemberOnMemberShipCheckout
                    }
                    handleSidebar={this.handleSidebar}
                  />
                </div>
              )}
              {allMembersListPageDisplay === "block" && (
                <div>
                  <AllMembersListPage
                    handleMembershipPackagesCheckoutPageDisplay={
                      this.handleMembershipPackagesCheckoutPageDisplay
                    }
                    handleAllMembersListPageDisplay={
                      this.handleAllMembersListPageDisplay
                    }
                  />
                </div>
              )}
              <div
                className="edit-pricing-page-component-holder"
                style={{ display: editPricingPageDisplay }}
              >
                <EditPricingPage
                  handleEditPricingPageDisplay={
                    this.handleEditPricingPageDisplay
                  }
                />
              </div>
              <div
                className="view-reports-page-component-holder"
                style={{ display: viewReportsPageDisplay }}
              >
                <ViewReportsPage
                  handleViewReportsPageDisplay={
                    this.handleViewReportsPageDisplay
                  }
                />
              </div>
              <div
                className="view-membership-packages-page-component-holder"
                style={{ display: viewMembershipPackagesPageDisplay }}
              >
                <ViewMembershipPackagesPage
                  handleViewMembershipPackagesPageDisplay={
                    this.handleViewMembershipPackagesPageDisplay
                  }
                  handleCreateMembershipPackagesPageDisplay={
                    this.handleCreateMembershipPackagesPageDisplay
                  }
                  handleAllMembersListPageDisplay={
                    this.handleAllMembersListPageDisplay
                  }
                  showMenuPopover={
                    this.state.viewMembershipPackagesPageData.showMenuPopover
                  }
                  handleAddMember={
                    this.viewMembershipPackagesPageFunctions.handleAddMember
                  }
                  handleEditPackage={
                    this.viewMembershipPackagesPageFunctions.handleEditPackage
                  }
                  reActivatePackage={
                    this.viewMembershipPackagesPageFunctions.reActivatePackage
                  }
                  deActivatePackage={
                    this.viewMembershipPackagesPageFunctions.deActivatePackage
                  }
                  changeActivePackageDetails={
                    this.viewMembershipPackagesPageFunctions
                      .changeActivePackageDetails
                  }
                  currentPage={
                    this.state.viewMembershipPackagesPageData.currentPage
                  }
                  handlePageChange={
                    this.viewMembershipPackagesPageFunctions.handlePageChange
                  }
                />
              </div>
              {showLoader && (
                <div className="loader loader-default is-active" />
              )}
            </React.Fragment>
          ) : (
            <PremiumFeature
              hidePremiumFeaturePage={this.hidePremiumFeaturePage}
            />
          )}
        </React.Fragment>
      ) : (
        <div className="loader loader-default is-active" />
      );
    }
  }
}

const mapStateToProps = ({
  showLoader,
  clubActivityData,
  slotsList,
  cartData,
  slotsToast,
  unblockingToast,
  bookingDetailsOnHover,
  bookingDetails,
  memberDetails,
  membershipPackageDetails,
  clubExtrasList,
  membershipSubscriptionDetails,
  priceClassDetails,
  pricingData,
  clubHandlersList,
  authStatus,
  logoutDetails,
  cartToast,
  transactionCodesAndTransactionTypes,
  premiumFeatureFlag,
  individualBulkBookingSearchResult,
  escalationMatrixData,
  regularBookingSearchResult,
  selectedMembershipPackageDetails,
  membershipPackageDetailsForEditing,
  updateMembershipPackageDetailsToast,
  switchAccountDetails,
  couponDetails,
  staffMemberList,
  configExtrasList,
}) => ({
  showLoader,
  clubActivityData,
  slotsList,
  cartData,
  slotsToast,
  unblockingToast,
  bookingDetailsOnHover,
  bookingDetails,
  memberDetails,
  membershipPackageDetails,
  clubExtrasList,
  membershipSubscriptionDetails,
  priceClassDetails,
  pricingData,
  clubHandlersList,
  authStatus,
  logoutDetails,
  cartToast,
  transactionCodesAndTransactionTypes,
  premiumFeatureFlag,
  individualBulkBookingSearchResult,
  escalationMatrixData,
  regularBookingSearchResult,
  selectedMembershipPackageDetails,
  membershipPackageDetailsForEditing,
  updateMembershipPackageDetailsToast,
  switchAccountDetails,
  couponDetails,
  staffMemberList,
  configExtrasList,
});

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default connect(mapStateToProps)(HomePage);
