// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import { ChevronLeftIcon, Heading, IconButton } from "evergreen-ui";

// -----------------------------------------------------------------------
// PageHeadingAndActionsNav Component
// -----------------------------------------------------------------------
class PageHeadingAndActionsNav extends React.Component {
  // -----------------------------------------------------------------------
  // handleBackButton()
  // -----------------------------------------------------------------------
  handleBackButton = async (e) => {
    e.persist();
    if (this.props.handleCreateMembershipPackagesPageDisplay) {
      this.props.handleCreateMembershipPackagesPageDisplay("hide");
      await this.props.resetAfterLeavingCreateMembershipPackagesPage();
    } else if (this.props.handleBookingCheckoutPageDisplay) {
      this.props.handleBookingCheckoutPageDisplay(e);
    } else if (this.props.handleConfirmDismissDialog) {
      this.props.handleConfirmDismissDialog("show");
    } else if (this.props.handleBackButtonOnAllMembersListPage) {
      this.props.handleBackButtonOnAllMembersListPage(e);
    } else if (this.props.handleBackButtonOnViewReportsPage) {
      await this.props.handleBackButtonOnViewReportsPage();
    } else if (this.props.hidePremiumFeaturePage) {
      await this.props.hidePremiumFeaturePage();
    } else if (this.props.handleViewMembershipPackagesPageDisplay) {
      await this.props.handleViewMembershipPackagesPageDisplay("hide");
    }
  };

  // -----------------------------------------------------------------------
  // render()
  // -----------------------------------------------------------------------
  render() {
    const { headingText } = this.props;
    return (
      <React.Fragment>
        <div className="PHAAN-A1-wrapper">
          <div className="PHAAN-A1-actions-and-heading-container">
            <ChevronLeftIcon
              appearance="minimal"
              cursor="pointer"
              iconSize={16}
              marginRight={12}
              onClick={(e) => this.handleBackButton(e)}
            />
            <Heading id="PHAAN-A1-heading">{headingText}</Heading>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default PageHeadingAndActionsNav;
