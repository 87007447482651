// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import { connect } from "react-redux";
import Header from "../../components/Header";
import {
  Button,
  toaster,
  Text,
  Badge,
  Paragraph,
  IconButton,
  Dialog,
  AddIcon,
  EditIcon,
  CalendarIcon,
} from "evergreen-ui";
import PriceClassSelect from "../../components/PriceClassSelect";
import {
  getPricingConfigDetails,
  savePricingData,
  getHolidays,
  togglePremiumFeatureFlag,
} from "../../actions";
import {
  isObjEmpty,
  determineBadgeColorForPricingSlot,
  generateShortDayName,
  toTimeFormat1,
  toDateFormat32,
  resourcesNotLoadedToast,
  generateCurrencySymbol,
} from "../../utils";
import SpecialPricingDatePickerDialogContent from "../../components/SpecialPricingDatePickerDialogContent";
import HolidayListingDialogContent from "../../components/HolidayListingDialogContent";
import BlankPageGuide from "../../components/BlankPageGuide";

// -----------------------------------------------------------------------
// Pricing Component
// -----------------------------------------------------------------------
class Pricing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      slotDuration: 0,
      openHour: "",
      closeHour: "",
      selectedPriceClassValue: "select-a-class",
      selectedPriceClassActivityId: "",
      defaultPriceClassValue: "select-a-class",
      defaultPriceClassName: "Select a class",
      selectedClassCourts: [],
      showCourtPills: false,
      showPriceConfigDetails: false,
      showSpecialPricingDatePickerDialog: false,
      specialPricingStatus: "add-new",
      showHolidayListingDialog: false,
    };
  }

  // -----------------------------------------------------------------------
  // updatePriceClassAdditionalInformation()
  // -----------------------------------------------------------------------
  updatePriceClassAdditionalInformation = async (priceClassId) => {
    if (
      this.props.priceClassDetails &&
      this.props.priceClassDetails.priceClasses
    ) {
      for (
        let i = 0;
        i < this.props.priceClassDetails.priceClasses.length;
        i++
      ) {
        if (
          this.props.priceClassDetails.priceClasses[
            i
          ].priceClassId.toString() === priceClassId.toString()
        ) {
          await this.setState({
            openHour: this.props.priceClassDetails.priceClasses[
              i
            ].openCloseHours
              .toString()
              .slice(0, 8)
              .trim(),
            closeHour: this.props.priceClassDetails.priceClasses[
              i
            ].openCloseHours
              .toString()
              .slice(-9)
              .trim(),
            slotDuration:
              this.props.priceClassDetails.priceClasses[i].slotDuration,
          });
          break;
        }
      }
    }
  };

  // -----------------------------------------------------------------------
  // handlePriceClassChange()
  // -----------------------------------------------------------------------
  handlePriceClassChange = async (e) => {
    e.persist();
    await this.setState({ selectedPriceClassValue: e.target.value });
    await this.updatePriceClassAdditionalInformation(e.target.value);
    if (
      this.state.selectedPriceClassValue !== this.state.defaultPriceClassValue
    ) {
      this.generateSelectedClassCourts();
      await this.setState({
        showCourtPills: true,
        selectedPriceClassActivityId: parseInt(
          e.nativeEvent.target.selectedOptions[0].getAttribute("activityid")
        ),
      });
      let reqParams = {
        priceClass: parseInt(this.state.selectedPriceClassValue),
        activityId: this.state.selectedPriceClassActivityId,
      };
      await this.props.dispatch(getPricingConfigDetails(reqParams));
      if (this.props.pricingConfigDetails) {
        if (this.props.pricingConfigDetails.resourcesNotLoadedMessage) {
          await this.setState({ showPriceConfigDetails: false });
          resourcesNotLoadedToast();
        } else if (this.props.pricingConfigDetails.httpErrorMessage) {
          await this.setState({ showPriceConfigDetails: false });
          toaster.danger(this.props.pricingConfigDetails.httpErrorMessage, {
            id: "HTTP-ERROR-TOAST-1",
          });
        } else if (this.props.pricingConfigDetails.requestStatus === -1) {
          toaster.notify("Sorry, you don't have permission to do that");
        } else if (this.props.pricingConfigDetails.requestStatus === 1) {
          await this.setState({ showPriceConfigDetails: true });
        } else if (this.props.savePricingDetailsToast.requestStatus === 0) {
          await this.setState({ showPriceConfigDetails: false });
        }
      }
    } else {
      this.setState({
        selectedClassCourts: [],
        showCourtPills: false,
        showPriceConfigDetails: false,
      });
    }
  };

  // -----------------------------------------------------------------------
  // generateSelectedClassCourts()
  // -----------------------------------------------------------------------
  generateSelectedClassCourts = () => {
    for (var i = 0; i < this.props.priceClassDetails.priceClasses.length; i++) {
      if (
        this.props.priceClassDetails.priceClasses[i].priceClassId.toString() ===
        this.state.selectedPriceClassValue
      ) {
        this.setState({
          selectedClassCourts:
            this.props.priceClassDetails.priceClasses[i].courts,
        });
      }
    }
  };

  // -----------------------------------------------------------------------
  // generateSlotDiscountKeys()
  // -----------------------------------------------------------------------
  generateSlotDiscountKeys = (slotDiscountObj) => {
    return Object.keys(slotDiscountObj);
  };

  // -----------------------------------------------------------------------
  // generateSpecialDatePeriodKeys()
  // -----------------------------------------------------------------------
  generateSpecialDatePeriodKeys = (specialPricingObj) => {
    return Object.keys(specialPricingObj);
  };

  // -----------------------------------------------------------------------
  // generateSpecialDatePeriod()
  // -----------------------------------------------------------------------
  generateSpecialDatePeriod = (datePeriod, type) => {
    if (type === "start-date") return datePeriod.toString().split(" - ")[0];
    return datePeriod.toString().split(" - ")[1];
  };

  // -----------------------------------------------------------------------
  // handleAddSpecialPricing()
  // -----------------------------------------------------------------------
  handleAddSpecialPricing = async (e) => {
    e.persist();
    await this.setState({ specialPricingStatus: "add-new" });
    await this.handleSpecialPricingDatePickerDialog("show");
  };

  // -----------------------------------------------------------------------
  // handleAddPricing()
  // -----------------------------------------------------------------------
  handleAddPricing = async (e, type) => {
    e.persist();
    var pricingType = "";
    // if (type === "special") {
    //   pricingType = "special";
    // }
    if (type === "holiday") {
      pricingType = "holiday";
      await this.props.dispatch(
        savePricingData(
          pricingType,
          this.state.selectedPriceClassValue,
          this.state.selectedPriceClassActivityId,
          [],
          this.state.openHour,
          this.state.closeHour,
          this.state.slotDuration
        )
      );
      await this.props.handleEditPricingPageDisplay("show");
    }
    if (type === "regular") {
      pricingType = "regular";
      await this.props.dispatch(
        savePricingData(
          pricingType,
          this.state.selectedPriceClassValue,
          this.state.selectedPriceClassActivityId,
          [],
          this.state.openHour,
          this.state.closeHour,
          this.state.slotDuration
        )
      );
      await this.props.handleEditPricingPageDisplay("show");
    }
  };

  // -----------------------------------------------------------------------
  // handleEditPricing()
  // -----------------------------------------------------------------------
  handleEditPricing = async (e, type, priceSlots, specialDatePeriod = "") => {
    e.persist();
    var pricingType = "";

    var modifiedPriceSlots = await this.modifyPriceSlots(priceSlots);
    if (type === "special") {
      pricingType = "special";
      await this.props.dispatch(
        savePricingData(
          pricingType,
          this.state.selectedPriceClassValue,
          this.state.selectedPriceClassActivityId,
          modifiedPriceSlots,
          this.state.openHour,
          this.state.closeHour,
          this.state.slotDuration,
          true,
          this.generateSpecialDatePeriod(specialDatePeriod, "start-date"),
          this.generateSpecialDatePeriod(specialDatePeriod, "end-date")
        )
      );
      await this.props.handleEditPricingPageDisplay("show");
    }
    if (type === "holiday") {
      pricingType = "holiday";
      await this.props.dispatch(
        savePricingData(
          pricingType,
          this.state.selectedPriceClassValue,
          this.state.selectedPriceClassActivityId,
          modifiedPriceSlots,
          this.state.openHour,
          this.state.closeHour,
          this.state.slotDuration
        )
      );
      await this.props.handleEditPricingPageDisplay("show");
    }
    if (type === "regular") {
      pricingType = "regular";
      await this.props.dispatch(
        savePricingData(
          pricingType,
          this.state.selectedPriceClassValue,
          this.state.selectedPriceClassActivityId,
          modifiedPriceSlots,
          this.state.openHour,
          this.state.closeHour,
          this.state.slotDuration
        )
      );
      await this.props.handleEditPricingPageDisplay("show");
    }
  };

  // -----------------------------------------------------------------------
  // modifyPriceSlots()
  // -----------------------------------------------------------------------
  modifyPriceSlots = async (priceSlots) => {
    // let modifiedPriceSlots = JSON.parse(JSON.stringify(priceSlots));

    // await modifiedPriceSlots[0].priceSlots.forEach(slot => {
    //   slot.slotDiscount = Object.entries(slot.slotDiscount);
    // });
    // return modifiedPriceSlots;

    let modifiedPriceSlots = JSON.parse(JSON.stringify(priceSlots));

    await modifiedPriceSlots[0].priceSlots.forEach((slot) => {
      if (slot.slotDiscount === null) {
        slot.slotDiscount = [["", ""]];
      } else if (isObjEmpty(slot.slotDiscount)) {
        slot.slotDiscount = [["", ""]];
      } else {
        slot.slotDiscount = Object.entries(slot.slotDiscount);
      }
      slot.isInvalid = false;
    });
    return modifiedPriceSlots;
  };

  // -----------------------------------------------------------------------
  // handleSpecialPricingDatePickerDialog()
  // -----------------------------------------------------------------------
  handleSpecialPricingDatePickerDialog = async (type) => {
    if (type === "show") {
      await this.setState({
        showSpecialPricingDatePickerDialog: true,
      });
    } else {
      await this.setState({ showSpecialPricingDatePickerDialog: false });
    }
  };

  // -----------------------------------------------------------------------
  // handleChangeSpecialPricingDatePeriod()
  // -----------------------------------------------------------------------
  handleChangeSpecialPricingDatePeriod = async () => {};

  // -----------------------------------------------------------------------
  // loadHolidaysData()
  // -----------------------------------------------------------------------
  loadHolidaysData = async () => {
    await this.props.dispatch(getHolidays());
    if (this.props.holidaysData) {
      if (this.props.holidaysData.resourcesNotLoadedMessage) {
        resourcesNotLoadedToast();
      } else if (this.props.holidaysData.httpErrorMessage) {
        toaster.danger(this.props.holidaysData.httpErrorMessage, {
          id: "HTTP-ERROR-TOAST-3",
        });
      } else if (this.props.holidaysData.requestStatus === -1) {
        toaster.notify("Sorry, you don't have permission to do that");
      } else if (this.props.holidaysData.requestStatus === 1) {
        await this.setState({ showHolidayListingDialog: true });
      } else if (this.props.holidaysData.requestStatus === 0) {
        toaster.danger(this.props.holidaysData.message, {
          id: "HTTP-ERROR-TOAST-3",
        });
      }
    }
  };

  // -----------------------------------------------------------------------
  // handleHolidayListingDialog()
  // -----------------------------------------------------------------------
  handleHolidayListingDialog = async (type) => {
    if (type === "show") {
      await this.loadHolidaysData();
    } else if (type === "hide") {
      await this.setState({ showHolidayListingDialog: false });
    }
  };

  // -----------------------------------------------------------------------
  // render()
  // -----------------------------------------------------------------------
  render() {
    const {
      selectedPriceClassValue,
      selectedPriceClassActivityId,
      defaultPriceClassValue,
      defaultPriceClassName,
      selectedClassCourts,
      showCourtPills,
      showPriceConfigDetails,
      showSpecialPricingDatePickerDialog,
      specialPricingStatus,
      showHolidayListingDialog,
      openHour,
      closeHour,
      slotDuration,
    } = this.state;
    const { pricingConfigDetails, handleEditPricingPageDisplay } = this.props;
    return (
      <React.Fragment>
        <div className="PP-A1-wrapper">
          <Header headerText={"Club Pricing"} />
          <div className="PP-A1-action-bar">
            <div className="PP-A1-row-container">
              <div className="PP-A1-col-container PP-A1-margin-right-32">
                <div className="PP-A1-label-container PP-A1-margin-bottom-8">
                  <Text id="PP-A1-label-type-one">Class</Text>
                </div>
                <div className="PP-A1-price-class-select-container">
                  <PriceClassSelect
                    selectedPriceClassValue={selectedPriceClassValue}
                    defaultPriceClassValue={defaultPriceClassValue}
                    defaultPriceClassName={defaultPriceClassName}
                    changePriceClass={this.handlePriceClassChange}
                  />
                </div>
              </div>
              <div className="PP-A1-vertical-line-decorator PP-A1-margin-right-32" />
              <Button
                className="button"
                width={149}
                height={32}
                marginRight={32}
                // onClick={e => this.handleAddPricing(e, "special")}
                onClick={(e) => this.handleAddSpecialPricing(e)}
                iconBefore={<AddIcon />}
                intent="warning"
                appearance="primary"
                disabled={selectedPriceClassValue === defaultPriceClassValue}
              >
                Add Specials
              </Button>
              <Dialog
                width={560}
                isShown={showHolidayListingDialog}
                title="Holiday List"
                onCloseComplete={() => this.handleHolidayListingDialog("hide")}
                hasFooter={false}
              >
                <HolidayListingDialogContent
                  loadHolidaysData={this.loadHolidaysData}
                />
              </Dialog>
              <Button
                className="button"
                width={149}
                height={32}
                marginRight={32}
                onClick={(e) => this.handleHolidayListingDialog("show")}
                appearance="primary"
                intent="success"
              >
                Holiday Listing
              </Button>
            </div>
            {showCourtPills && (
              <div className="PP-A1-row-container PP-A1-margin-top-16">
                <div className="PP-A1-court-pills-container">
                  {selectedClassCourts.map((court, index) => (
                    <Badge
                      key={index}
                      color="green"
                      marginRight={8}
                      marginBottom={8}
                    >
                      {court}
                    </Badge>
                  ))}
                </div>
              </div>
            )}
          </div>
          {!showPriceConfigDetails && (
            <BlankPageGuide msg="Select a Price Class to See its Configured Prices" />
          )}
          {showPriceConfigDetails && (
            <div className="PP-A1-price-config-details-wrapper PP-A1-margin-top-24">
              <div className="PP-A1-price-config-details-container">
                <div className="PP-A1-price-type-heading-container">
                  <Text id="PP-A1-label-type-two">Regular</Text>
                </div>
                {pricingConfigDetails &&
                pricingConfigDetails.pricing &&
                pricingConfigDetails.pricing.regular.length > 0 ? (
                  pricingConfigDetails.pricing.regular.map(
                    (priceSlotDetails, index) => (
                      <div
                        className="PP-A1-pricing-slot-details-container PP-A1-border-r-l-b"
                        key={index}
                      >
                        <div className="PP-A1-pricing-slot-days-and-actions-container">
                          {priceSlotDetails.startDay ===
                          priceSlotDetails.endDay ? (
                            <Badge
                              width={104}
                              className="PP-A1-pricing-days-badge"
                              color={determineBadgeColorForPricingSlot(
                                index,
                                "regular"
                              )}
                              isSolid
                            >
                              {generateShortDayName(priceSlotDetails.startDay)}
                            </Badge>
                          ) : (
                            <Badge
                              width={104}
                              className="PP-A1-pricing-days-badge"
                              color={determineBadgeColorForPricingSlot(
                                index,
                                "regular"
                              )}
                              isSolid
                            >
                              {`${generateShortDayName(
                                priceSlotDetails.startDay
                              )} - ${generateShortDayName(
                                priceSlotDetails.endDay
                              )}`}
                            </Badge>
                          )}
                          <Button
                            className="PP-A1-button"
                            minWidth={109}
                            height={32}
                            iconBefore={<EditIcon />}
                            appearance="minimal"
                            onClick={(e) =>
                              this.handleEditPricing(e, "regular", [
                                priceSlotDetails,
                              ])
                            }
                          >
                            Edit Pricing
                          </Button>
                        </div>
                        {priceSlotDetails.priceSlots.map((priceSlot, index) => (
                          <div
                            className="PP-A1-price-slot-details-wrapper"
                            key={index}
                          >
                            <div className="PP-A1-label-container PP-A1-width-150 PP-A1-margin-right-150">
                              <Text id="PP-A1-label-type-three">
                                {`${toTimeFormat1(
                                  priceSlot.startTime
                                )} - ${toTimeFormat1(priceSlot.endTime)}`}
                              </Text>
                            </div>
                            <div className="PP-A1-label-container PP-A1-width-150 PP-A1-margin-right-120">
                              <Text id="PP-A1-label-type-three">
                                {`${generateCurrencySymbol()} ${
                                  priceSlot.pricingAmount
                                }`}
                              </Text>
                            </div>
                            {isObjEmpty(priceSlot.slotDiscount) ? (
                              <div className="PP-A1-label-container">
                                <Text id="PP-A1-label-type-five">-</Text>
                              </div>
                            ) : (
                              <div className="PP-A1-col-container">
                                {this.generateSlotDiscountKeys(
                                  priceSlot.slotDiscount
                                )
                                  .slice(0, 2)
                                  .map((slotNum, index) => (
                                    <div
                                      className="PP-A1-width-100"
                                      key={index}
                                    >
                                      <Paragraph marginBottom={8} key={index}>
                                        <Text
                                          id="PP-A1-label-type-four"
                                          key={index}
                                        >
                                          {`${slotNum} Slot `}
                                        </Text>
                                        <Text id="PP-A1-label-type-five">{`- ${priceSlot.slotDiscount[slotNum]}%`}</Text>
                                      </Paragraph>
                                    </div>
                                  ))}
                                {this.generateSlotDiscountKeys(
                                  priceSlot.slotDiscount
                                ).length > 2 && (
                                  <Text id="PP-A1-label-type-six">
                                    {`+${
                                      this.generateSlotDiscountKeys(
                                        priceSlot.slotDiscount
                                      ).length - 2
                                    } more`}
                                  </Text>
                                )}
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    )
                  )
                ) : (
                  <div className="PP-A1-add-pricing-btn-container PP-A1-border-r-l-b">
                    <Button
                      className="PP-A1-button"
                      width={156}
                      height={32}
                      iconBefore={<AddIcon />}
                      appearance="minimal"
                      onClick={(e) => this.handleAddPricing(e, "regular")}
                    >
                      Add Regular Pricing
                    </Button>
                  </div>
                )}
              </div>
              <div className="PP-A1-price-config-details-container PP-A1-margin-top-32">
                {pricingConfigDetails &&
                pricingConfigDetails.pricing &&
                pricingConfigDetails.pricing.holiday.length > 0 ? (
                  <div className="PP-A1-price-type-heading-container PP-A1-justify-content-flex-end">
                    <div className="PP-A1-margin-right-354 ">
                      <Text id="PP-A1-label-type-two">Holidays</Text>
                    </div>
                    <div className="PP-A1-margin-right-16">
                      <IconButton
                        appearance="minimal"
                        icon={<EditIcon />}
                        iconSize={16}
                        onClick={(e) =>
                          this.handleEditPricing(
                            e,
                            "holiday",
                            pricingConfigDetails.pricing.holiday
                          )
                        }
                      />
                    </div>
                  </div>
                ) : (
                  <div className="PP-A1-price-type-heading-container">
                    <Text id="PP-A1-label-type-two">Holidays</Text>
                  </div>
                )}
                {pricingConfigDetails &&
                pricingConfigDetails.pricing &&
                pricingConfigDetails.pricing.holiday.length > 0 ? (
                  pricingConfigDetails.pricing.holiday.map(
                    (priceSlotDetails, index) => (
                      <div
                        className="PP-A1-pricing-slot-details-container PP-A1-border-r-l-b"
                        key={index}
                      >
                        {priceSlotDetails.priceSlots.map((priceSlot, index) => (
                          <div
                            className="PP-A1-price-slot-details-wrapper"
                            key={index}
                          >
                            <div className="PP-A1-label-container PP-A1-width-150 PP-A1-margin-right-150">
                              <Text id="PP-A1-label-type-three">
                                {`${toTimeFormat1(
                                  priceSlot.startTime
                                )} - ${toTimeFormat1(priceSlot.endTime)}`}
                              </Text>
                            </div>
                            <div className="PP-A1-label-container PP-A1-width-150 PP-A1-margin-right-120">
                              <Text id="PP-A1-label-type-three">
                                {`${generateCurrencySymbol()} ${
                                  priceSlot.pricingAmount
                                }`}
                              </Text>
                            </div>
                            {isObjEmpty(priceSlot.slotDiscount) ? (
                              <div className="PP-A1-label-container">
                                <Text id="PP-A1-label-type-five">-</Text>
                              </div>
                            ) : (
                              <div className="PP-A1-col-container">
                                {this.generateSlotDiscountKeys(
                                  priceSlot.slotDiscount
                                )
                                  .slice(0, 2)
                                  .map((slotNum, index) => (
                                    <div
                                      className="PP-A1-width-100"
                                      key={index}
                                    >
                                      <Paragraph marginBottom={8} key={index}>
                                        <Text
                                          id="PP-A1-label-type-four"
                                          key={index}
                                        >
                                          {`${slotNum} Slot `}
                                        </Text>
                                        <Text id="PP-A1-label-type-five">{`- ${priceSlot.slotDiscount[slotNum]}%`}</Text>
                                      </Paragraph>
                                    </div>
                                  ))}
                                {this.generateSlotDiscountKeys(
                                  priceSlot.slotDiscount
                                ).length > 2 && (
                                  <Text id="PP-A1-label-type-six">
                                    {`+${
                                      this.generateSlotDiscountKeys(
                                        priceSlot.slotDiscount
                                      ).length - 2
                                    } more`}
                                  </Text>
                                )}
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    )
                  )
                ) : (
                  <div className="PP-A1-add-pricing-btn-container PP-A1-border-r-l-b">
                    <Button
                      className="PP-A1-button"
                      width={156}
                      height={32}
                      iconBefore={<AddIcon />}
                      appearance="minimal"
                      onClick={(e) => this.handleAddPricing(e, "holiday")}
                    >
                      Add Holiday Pricing
                    </Button>
                  </div>
                )}
              </div>
              <div className="PP-A1-price-config-details-container PP-A1-margin-top-32">
                <div className="PP-A1-price-type-heading-container">
                  <Text id="PP-A1-label-type-two">Specials</Text>
                </div>
                {pricingConfigDetails &&
                pricingConfigDetails.pricing &&
                isObjEmpty(pricingConfigDetails.pricing.special) ? (
                  <div className="PP-A1-add-pricing-btn-container PP-A1-border-r-l-b">
                    <Button
                      className="PP-A1-button"
                      width={156}
                      height={32}
                      iconBefore={<AddIcon />}
                      appearance="minimal"
                      onClick={(e) => this.handleAddSpecialPricing(e)}
                    >
                      Add Special Pricing
                    </Button>
                  </div>
                ) : (
                  this.generateSpecialDatePeriodKeys(
                    pricingConfigDetails.pricing.special
                  ).map((specialSlotPeriod, index) => (
                    <React.Fragment key={index}>
                      <div className="PP-A1-special-datepicker-actions-container  PP-A1-border-r-l-b">
                        <Button
                          minWidth={224}
                          height={32}
                          className="PP-A1-date-range-button"
                          iconBefore={<CalendarIcon />}
                          onClick={(e) =>
                            this.handleChangeSpecialPricingDatePeriod(e)
                          }
                        >{`${toDateFormat32(
                          this.generateSpecialDatePeriod(
                            specialSlotPeriod,
                            "start-date"
                          )
                        )} to ${toDateFormat32(
                          this.generateSpecialDatePeriod(
                            specialSlotPeriod,
                            "end-date"
                          )
                        )}`}</Button>
                      </div>
                      {pricingConfigDetails.pricing.special[
                        specialSlotPeriod
                      ].map((pricingDetails, index) => (
                        <div
                          className="PP-A1-pricing-slot-details-container PP-A1-border-r-l-b"
                          key={index}
                        >
                          <div className="PP-A1-pricing-slot-days-and-actions-container">
                            {pricingDetails.startDay ===
                            pricingDetails.endDay ? (
                              <Badge
                                width={104}
                                className="PP-A1-pricing-days-badge"
                                color={determineBadgeColorForPricingSlot(
                                  index,
                                  "special"
                                )}
                                isSolid
                              >
                                {generateShortDayName(pricingDetails.startDay)}
                              </Badge>
                            ) : (
                              <Badge
                                width={104}
                                className="PP-A1-pricing-days-badge"
                                color={determineBadgeColorForPricingSlot(
                                  index,
                                  "special"
                                )}
                                isSolid
                              >
                                {`${generateShortDayName(
                                  pricingDetails.startDay
                                )} - ${generateShortDayName(
                                  pricingDetails.endDay
                                )}`}
                              </Badge>
                            )}
                            <Button
                              className="PP-A1-button"
                              minWidth={109}
                              height={32}
                              iconBefore={<EditIcon />}
                              appearance="minimal"
                              onClick={(e) =>
                                this.handleEditPricing(
                                  e,
                                  "special",
                                  [pricingDetails],
                                  specialSlotPeriod
                                )
                              }
                            >
                              Edit Pricing
                            </Button>
                          </div>
                          {pricingDetails.priceSlots.map((priceSlot, index) => (
                            <div
                              className="PP-A1-price-slot-details-wrapper"
                              key={index}
                            >
                              <div className="PP-A1-label-container PP-A1-width-150 PP-A1-margin-right-150">
                                <Text id="PP-A1-label-type-three">
                                  {`${toTimeFormat1(
                                    priceSlot.startTime
                                  )} - ${toTimeFormat1(priceSlot.endTime)}`}
                                </Text>
                              </div>
                              <div className="PP-A1-label-container PP-A1-width-150 PP-A1-margin-right-120">
                                <Text id="PP-A1-label-type-three">
                                  {`${generateCurrencySymbol()} ${
                                    priceSlot.pricingAmount
                                  }`}
                                </Text>
                              </div>
                              {isObjEmpty(priceSlot.slotDiscount) ? (
                                <div className="PP-A1-label-container">
                                  <Text id="PP-A1-label-type-five">-</Text>
                                </div>
                              ) : (
                                <div className="PP-A1-col-container">
                                  {this.generateSlotDiscountKeys(
                                    priceSlot.slotDiscount
                                  )
                                    .slice(0, 2)
                                    .map((slotNum, index) => (
                                      <div
                                        className="PP-A1-width-100"
                                        key={index}
                                      >
                                        <Paragraph marginBottom={8} key={index}>
                                          <Text
                                            id="PP-A1-label-type-four"
                                            key={index}
                                          >
                                            {`${slotNum} Slot `}
                                          </Text>
                                          <Text id="PP-A1-label-type-five">{`- ${priceSlot.slotDiscount[slotNum]}%`}</Text>
                                        </Paragraph>
                                      </div>
                                    ))}
                                  {this.generateSlotDiscountKeys(
                                    priceSlot.slotDiscount
                                  ).length > 2 && (
                                    <Text id="PP-A1-label-type-six">
                                      {`+${
                                        this.generateSlotDiscountKeys(
                                          priceSlot.slotDiscount
                                        ).length - 2
                                      } more`}
                                    </Text>
                                  )}
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      ))}
                    </React.Fragment>
                  ))
                )}
              </div>
            </div>
          )}
        </div>
        <Dialog
          width={600}
          isShown={showSpecialPricingDatePickerDialog}
          title="Special Pricing"
          hasFooter={false}
          onCloseComplete={(e) =>
            this.handleSpecialPricingDatePickerDialog("hide")
          }
        >
          <SpecialPricingDatePickerDialogContent
            specialPricingStatus={specialPricingStatus}
            handleEditPricingPageDisplay={handleEditPricingPageDisplay}
            selectedPriceClassValue={selectedPriceClassValue}
            selectedPriceClassActivityId={selectedPriceClassActivityId}
            handleSpecialPricingDatePickerDialog={
              this.handleSpecialPricingDatePickerDialog
            }
            openHour={openHour}
            closeHour={closeHour}
            slotDuration={slotDuration}
          />
        </Dialog>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({
  priceClassDetails,
  pricingConfigDetails,
  holidaysData,
}) => ({
  priceClassDetails,
  pricingConfigDetails,
  holidaysData,
});

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default connect(mapStateToProps)(Pricing);
