// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import { connect } from "react-redux";
import {
  Text,
  Icon,
  Pagination,
  Pane,
  Button,
  ArrowLeftIcon,
  ArrowRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "evergreen-ui";
import { BarChart, XAxis, Bar, Tooltip, CartesianGrid } from "recharts";
import ovalLeft from "../../assets/images/oval-left.png";
import ovalRight from "../../assets/images/oval-right.png";

// -----------------------------------------------------------------------
// BarChartBox Component
// -----------------------------------------------------------------------
class BarChartBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      barsPerPage: 3,
    };
  }
  handlePageChange = (newPage) => {
    this.setState({ currentPage: newPage });
  };
  // -----------------------------------------------------------------------
  // render()
  // -----------------------------------------------------------------------
  render() {
    const {
      chartLabel,
      chartData,
      handleChartNavigaionBtn,
      disableBarChartNextBtn,
      disableBarChartPreviousBtn,
    } = this.props;
    const BarsPerPage = 10; // Number of bars to display per page
    const { currentPage, barsPerPage } = this.state;

    const startIndex = (currentPage - 1) * barsPerPage;
    const endIndex = startIndex + barsPerPage;
    const barsToShow = chartData.slice(startIndex, endIndex);

    const totalPages = Math.ceil(chartData.length / barsPerPage);
    return (
      <div className="BCB-A1-wrapper">
        <div className="BCB-A1-label-container BCB-A1-margin-bottom-8">
          <Text id="BCB-A1-label-type-one">{chartLabel}</Text>
        </div>
        <div className="BCB-A1-chart-wrapper">
          <div
            onClick={() =>
              currentPage > 1 && this.handlePageChange(currentPage - 1)
            }
            className="BCB-A1-navigation-btn-container"
          >
            <img src={ovalLeft} alt="chevron-left" />
            <div className="BCB-A1-icon-container BCB-A1-chevron-left-icon-container">
              {currentPage > 1 && <ChevronLeftIcon color="muted" size={16} />}
            </div>
          </div>

          <div className="BCB-A1-chart-container">
            <BarChart
              width={340}
              height={220}
              data={barsToShow}
              margin={{ top: 10, right: 30, left: 20, bottom: 10 }}
            >
              <XAxis
                dataKey="packageName"
                tick={{
                  width: 91.33,
                  fontSize: 11,
                }}
                interval={0}
              />

              <Tooltip />
              <Bar
                dataKey="count"
                barSize={30}
                radius={[2, 2, 2, 2]}
                fill="#82ca9d"
              />
            </BarChart>
          </div>
          {currentPage < totalPages && (
            <div
              onClick={() => this.handlePageChange(currentPage + 1)}
              className="BCB-A1-navigation-btn-container"
            >
              <img src={ovalRight} alt="chevron-right" />
              <div className="BCB-A1-icon-container BCB-A1-chevron-right-icon-container">
                <ChevronRightIcon color="muted" size={16} />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ membershipPackageDetails }) => ({
  membershipPackageDetails,
});

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default connect(mapStateToProps)(BarChartBox);
