// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
// -----------------------------------------------------------------------
// LoginPage Component
// -----------------------------------------------------------------------
class Download extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isUserNameInvalid: false,
    };
  }

  getRequest = (url, config) => {
 
    return axios.get(url, config);
  };

  downloadBookingReport = async (loadUrl,endDate,booked ,auth) => {
    const url = `${loadUrl}&endDate=${endDate}&booked=${booked}`;
    const config = this.getConfig("", "blob", auth);
    let res = await this.getRequest(url, config);
    return res;
  };

  getConfig = (params = Object(), responseType = "application/json", auth = "") => {
    return Object.assign(Object(), {
      headers: Object.assign(Object(), {
        "Content-Type": "application/json",
        Authorization: auth,
        // "User-Agent": "Partner-App"
      }),
      params,
      responseType,
    });
  };

  // -----------------------------------------------------------------------
  // ComponentDimMount()
  // -----------------------------------------------------------------------
  async componentDidMount() {
    document.title = "Reports download";
    const urlParams = new URLSearchParams(window.location.search);
    let auth = urlParams.get("auth");
    let loadUrl = urlParams.get("URL");
    let endDate = urlParams.get("endDate");
    let booked = urlParams.get("booked");
    let name = urlParams.get("name");
   
    let res = await this.downloadBookingReport(loadUrl, endDate, booked, auth);
    const url = window.URL.createObjectURL(res.data);
    const link = document.createElement("a");
   
    link.href = url;
    link.setAttribute("download", name);
    document.body.appendChild(link);
    link.click();
   setTimeout(() => {
    window.close()
   },4000)
   

    // link.click();
    // link.parentNode.removeChild(link);
  }

  // -----------------------------------------------------------------------
  // render()
  // -----------------------------------------------------------------------
  render() {
    return <React.Fragment>Downloading.....</React.Fragment>;
  }
}

const mapStateToProps = ({ authStatus, loginDetails }) => ({
  authStatus,
  loginDetails,
});

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default connect(mapStateToProps)(Download);
