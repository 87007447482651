// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from 'react'
import { connect } from 'react-redux'
import {
  Text,
  Checkbox,
  toaster,
  Radio,
  TextInput,
  Textarea,
  Dialog
} from 'evergreen-ui'
import CustomizedDatePicker from '../CustomizedDatePicker'
import ClubActivityListSelect from '../ClubActivityListSelect'
import CustomizedTimePicker from '../CustomizedTimePicker'
import {
  generateCoupon,
  getCouponDetails,
  togglePremiumFeatureFlag
} from '../../actions'
import {
  validateCreditsAndPrice,
  checkPositiveInteger,
  toDateFormat1,
  toTimeFormat,
  checkIntOrFloat,
  validatePercentage,
  resourcesNotLoadedToast,
  checkIfDateIsAfter,
  checkIfDatesAreSame,
  checkIfTimeIsSameOrAfter,
  getPlayoUserId,
  toTimeFormat2
} from '../../utils'
import CustomInlineAlert from '../../components/CustomInlineAlert'

// -----------------------------------------------------------------------
// EditCouponsForm Component
// -----------------------------------------------------------------------
class EditCouponsForm extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      defaultActivityName: 'All',
      defaultActivityValue: 'all',
      selectedActivityName: 'All',
      selectedActivityValue: 'all',
      fromDate: new Date(),
      toDate: new Date(),
      fromTime: new Date(),
      toTime: new Date(),
      clubMinuteStep: 15,
      dayCheckAll: false,
      daysData: [
        { day: 'Mon', id: 0, checked: false },
        { day: 'Tue', id: 1, checked: false },
        { day: 'Wed', id: 2, checked: false },
        { day: 'Thu', id: 3, checked: false },
        { day: 'Fri', id: 4, checked: false },
        { day: 'Sat', id: 5, checked: false },
        { day: 'Sun', id: 6, checked: false }
      ],
      selectedDayIds: [],
      applicableOnMultipleSlot: false,
      typeRadioValue: 'cash-discount',
      showCashDiscountActions: true,
      showPercentageDiscountActions: false,
      permanentCheck: false,
      showMaxUsageActions: false,
      couponRemark: '',
      cashDiscountAmount: '',
      discountPercentage: '',
      upperLimit: '',
      maxUsageCount: '',
      couponCount: '',
      isCashDiscountAmountInvalid: false,
      isDiscountPercentageInvalid: false,
      isUpperLimitInvalid: false,
      isMaxUsageCountInvalid: false,
      isCouponCountInvalid: false,
      email: '',
      emailList: [],
      isDayInvalid: false,
      isDateRangeInvalid: false,
      isTimeRangeInvalid: false
    }
  }

  // -----------------------------------------------------------------------
  // handleActivityChange()
  // -----------------------------------------------------------------------
  handleActivityChange = e => {
    e.persist()
    this.setState({
      selectedActivityName: e.nativeEvent.target[
        e.nativeEvent.target.selectedIndex
      ].text.toString(),
      selectedActivityValue: e.target.value
    })
  }

  // -----------------------------------------------------------------------
  // scrollToElement()
  // -----------------------------------------------------------------------
  scrollToElement = async elem => {
    await this.refs[elem].scrollIntoView({ block: 'end', behavior: 'smooth' })
  }

  // -----------------------------------------------------------------------
  // handleFromDateChange()
  // -----------------------------------------------------------------------
  handleFromDateChange = async date => {
    await this.setState({ fromDate: date, toDate: date })
    await this.validateDates()
  }

  // -----------------------------------------------------------------------
  // handleToDateChange()
  // -----------------------------------------------------------------------
  handleToDateChange = async date => {
    await this.setState({ toDate: date })
    await this.validateDates()
  }

  // -----------------------------------------------------------------------
  // validateDates()
  // -----------------------------------------------------------------------
  validateDates = async () => {
    if (
      checkIfDatesAreSame(this.state.toDate, this.state.fromDate) ||
      checkIfDateIsAfter(this.state.toDate, this.state.fromDate)
    ) {
      await this.setState({
        isDateRangeInvalid: false
      })
    } else {
      await this.setState({
        isDateRangeInvalid: true
      })
    }
  }

  // -----------------------------------------------------------------------
  // handleCheckAll()
  // -----------------------------------------------------------------------
  handleCheckAll = async (e, type) => {
    e.persist()
    if (type === 'day') {
      const modifiedDaysData = await this.state.daysData.map(data => {
        return (data = {
          ...data,
          checked: e.target.checked ? true : false
        })
      })
      await this.setState({
        daysData: modifiedDaysData,
        dayCheckAll: !this.state.dayCheckAll
      })
      await this.setState({
        selectedDayIds: e.target.checked
          ? this.state.daysData.map(data => {
              return data.id
            })
          : []
      })
      if (this.state.selectedDayIds.length > 0) {
        await this.setState({
          isDayInvalid: false
        })
      } else {
        await this.setState({
          isDayInvalid: true
        })
      }
    }
  }

  // -----------------------------------------------------------------------
  // handleCheckIndividual()
  // -----------------------------------------------------------------------
  handleCheckIndividual = async (e, type, identifier) => {
    e.persist()
    if (type === 'day') {
      const modifiedDaysData = JSON.parse(JSON.stringify(this.state.daysData))
      for (var i = 0; i < modifiedDaysData.length; i++) {
        if (modifiedDaysData[i].id === identifier) {
          modifiedDaysData[i].checked = e.target.checked ? true : false
        }
      }
      await this.setState({
        daysData: modifiedDaysData
      })
      const updatedSelectedDayIds = []
      for (i = 0; i < modifiedDaysData.length; i++) {
        if (modifiedDaysData[i].checked) {
          updatedSelectedDayIds.push(modifiedDaysData[i].id)
        }
      }
      await this.setState({ selectedDayIds: updatedSelectedDayIds })
      if (this.state.selectedDayIds.length !== this.state.daysData.length) {
        await this.setState({
          dayCheckAll: false
        })
      }
      if (this.state.selectedDayIds.length > 0) {
        await this.setState({
          isDayInvalid: false
        })
      } else {
        await this.setState({
          isDayInvalid: true
        })
      }
    }
  }

  // -----------------------------------------------------------------------
  // handleFromTimeChange()
  // -----------------------------------------------------------------------
  handleFromTimeChange = async time => {
    await this.setState({ fromTime: time, toTime: time })
    await this.validateTimes()
  }

  // -----------------------------------------------------------------------
  // handleToTimeChange()
  // -----------------------------------------------------------------------
  handleToTimeChange = async time => {
    await this.setState({ toTime: time })
    await this.validateTimes()
  }

  // -----------------------------------------------------------------------
  // validateTimes()
  // -----------------------------------------------------------------------
  validateTimes = async () => {
    if (
      checkIfTimeIsSameOrAfter(this.state.toTime, this.state.fromTime) ||
      toTimeFormat2(this.state.toTime) === '00:00:00'
    ) {
      await this.setState({ isTimeRangeInvalid: false })
    } else {
      await this.setState({ isTimeRangeInvalid: true })
    }
  }

  // -----------------------------------------------------------------------
  // handleApplicableOnMultipleSlotCheckbox()
  // -----------------------------------------------------------------------
  handleApplicableOnMultipleSlotCheckbox = e => {
    this.setState({
      applicableOnMultipleSlot: e.target.checked ? true : false
    })
  }

  // -----------------------------------------------------------------------
  // handleTypeRadioOptionChange()
  // -----------------------------------------------------------------------
  handleTypeRadioOptionChange = async (e, type) => {
    e.persist()
    await this.setState({ typeRadioValue: type })
    if (type === 'cash-discount') {
      await this.setState({
        showCashDiscountActions: true,
        showPercentageDiscountActions: false,
        discountPercentage: '',
        upperLimit: '',
        isDiscountPercentageInvalid: false,
        isUpperLimitInvalid: false
      })
    } else if (type === 'percentage-discount') {
      await this.setState({
        showCashDiscountActions: false,
        showPercentageDiscountActions: true,
        cashDiscountAmount: '',
        isCashDiscountAmountInvalid: false
      })
    }
  }

  // -----------------------------------------------------------------------
  // handlePermanentCheckbox()
  // -----------------------------------------------------------------------
  handlePermanentCheckbox = async e => {
    e.persist()
    await this.setState({
      permanentCheck: e.target.checked ? true : false,
      showMaxUsageActions: e.target.checked ? true : false
    })
    if (!e.target.checked) {
      await this.setState({ maxUsageCount: '', isMaxUsageCountInvalid: false })
    }
  }

  // -----------------------------------------------------------------------
  // handleCouponRemarksInput()
  // -----------------------------------------------------------------------
  handleCouponRemarksInput = e => {
    this.setState({ couponRemark: e.target.value })
  }

  // -----------------------------------------------------------------------
  // handleInputFields()
  // -----------------------------------------------------------------------
  handleInputFields = async (e, type) => {
    e.persist()
    if (type === 'cash-discount-amount') {
      if (validateCreditsAndPrice(e.target.value) === 'set') {
        await this.setState({
          cashDiscountAmount: e.target.value
        })
      } else if (validateCreditsAndPrice(e.target.value) === 'clear') {
        await this.setState({
          cashDiscountAmount: ''
        })
      }
      if (this.state.cashDiscountAmount !== '') {
        await this.setState({ isCashDiscountAmountInvalid: false })
      } else {
        await this.setState({ isCashDiscountAmountInvalid: true })
      }
    }
    if (type === 'discount-percentage') {
      if (validatePercentage(e.target.value) === 'set') {
        await this.setState({
          discountPercentage: e.target.value
        })
      } else if (validatePercentage(e.target.value) === 'clear') {
        await this.setState({
          discountPercentage: ''
        })
      }
      if (this.state.discountPercentage !== '') {
        await this.setState({ isDiscountPercentageInvalid: false })
      } else {
        await this.setState({ isDiscountPercentageInvalid: true })
      }
    }
    if (type === 'upper-limit') {
      if (validateCreditsAndPrice(e.target.value) === 'set') {
        await this.setState({
          upperLimit: e.target.value
        })
      } else if (validateCreditsAndPrice(e.target.value) === 'clear') {
        await this.setState({
          upperLimit: ''
        })
      }
      if (this.state.upperLimit !== '') {
        await this.setState({ isUpperLimitInvalid: false })
      } else {
        await this.setState({ isUpperLimitInvalid: true })
      }
    }
    if (type === 'max-usage-count') {
      if (checkPositiveInteger(e.target.value) === 'set') {
        await this.setState({
          maxUsageCount: e.target.value
        })
      } else if (checkPositiveInteger(e.target.value) === 'clear') {
        await this.setState({
          maxUsageCount: ''
        })
      }
      if (this.state.maxUsageCount !== '') {
        await this.setState({ isMaxUsageCountInvalid: false })
      } else {
        await this.setState({ isMaxUsageCountInvalid: true })
      }
    }
    if (type === 'coupon-count') {
      if (checkPositiveInteger(e.target.value) === 'set') {
        await this.setState({
          couponCount: e.target.value
        })
      } else if (checkPositiveInteger(e.target.value) === 'clear') {
        await this.setState({
          couponCount: ''
        })
      }
      if (this.state.couponCount !== '') {
        await this.setState({ isCouponCountInvalid: false })
      } else {
        await this.setState({ isCouponCountInvalid: true })
      }
    }
  }

  // -----------------------------------------------------------------------
  // handleEmailInputChange()
  // -----------------------------------------------------------------------
  handleEmailInputChange = async e => {
    await this.setState({
      email: e.target.value.toString()
    })
    await this.generateEmailList()
  }

  // -----------------------------------------------------------------------
  // generateEmailList()
  // -----------------------------------------------------------------------
  generateEmailList = async () => {
    let emailList = this.state.email.split(',')
    await this.setState({ emailList: emailList })
  }

  // -----------------------------------------------------------------------
  // resetForm()
  // -----------------------------------------------------------------------
  resetForm = async () => {
    await this.setState({
      selectedActivityName: 'All',
      selectedActivityValue: Object.keys(
        this.props.clubActivityData.activities
      ),
      fromDate: new Date(),
      toDate: new Date(),
      fromTime: new Date(),
      toTime: new Date(),
      dayCheckAll: false,
      daysData: [
        { day: 'Mon', id: 0, checked: false },
        { day: 'Tue', id: 1, checked: false },
        { day: 'Wed', id: 2, checked: false },
        { day: 'Thu', id: 3, checked: false },
        { day: 'Fri', id: 4, checked: false },
        { day: 'Sat', id: 5, checked: false },
        { day: 'Sun', id: 6, checked: false }
      ],
      selectedDayIds: [],
      applicableOnMultipleSlot: false,
      typeRadioValue: 'cash-discount',
      showCashDiscountActions: true,
      showPercentageDiscountActions: false,
      permanentCheck: false,
      showMaxUsageActions: false,
      couponRemark: '',
      cashDiscountAmount: '',
      discountPercentage: '',
      upperLimit: '',
      maxUsageCount: '',
      couponCount: '',
      isCashDiscountAmountInvalid: false,
      isDiscountPercentageInvalid: false,
      isUpperLimitInvalid: false,
      isMaxUsageCountInvalid: false,
      isCouponCountInvalid: false,
      email: '',
      emailList: [],
      isDateRangeInvalid: false,
      isTimeRangeInvalid: false,
      isDayInvalid: false
    })
    await this.props.toggleGenerateCouponsDialogVisibility('close')
  }

  // -----------------------------------------------------------------------
  // confirmGenerateCoupon()
  // -----------------------------------------------------------------------
  confirmGenerateCoupon = async () => {
    const day_map = {
      0: 'monday',
      1: 'tuesday',
      2: 'wednesday',
      3: 'thursday',
      4: 'friday',
      5: 'saturday',
      6: 'sunday'
    }
    const isStartAndEndTimeSame =
      toTimeFormat(this.state.fromTime) === toTimeFormat(this.state.toTime)
    if (
      !this.state.isDateRangeInvalid &&
      this.state.selectedDayIds.length > 0 &&
      !this.state.isTimeRangeInvalid &&
      (await this.validateTypeField()) &&
      this.validatePermanenetField() &&
      this.state.couponCount !== ''
    ) {
      let reqBody = {
        couponActivity:
          this.state.selectedActivityValue === 'all'
            ? // JSON.stringify(Object.keys(this.props.clubActivityData.activities).map(activityId => {
              //     return parseInt(activityId)
              // }))
              null
            : JSON.stringify([parseInt(this.state.selectedActivityValue)]),
        startDate: toDateFormat1(this.state.fromDate),
        endDate: toDateFormat1(this.state.toDate),
        days: this.state.selectedDayIds.map(day => {
          return day_map[day]
        }),
        startTime: isStartAndEndTimeSame
          ? null
          : toTimeFormat(this.state.fromTime),
        endTime: isStartAndEndTimeSame ? null : toTimeFormat(this.state.toTime),
        amount:
          this.state.typeRadioValue === 'cash-discount'
            ? checkIntOrFloat(this.state.cashDiscountAmount)
            : null,
        percentage:
          this.state.typeRadioValue === 'percentage-discount'
            ? checkIntOrFloat(this.state.discountPercentage)
            : null,
        upperLimit:
          this.state.typeRadioValue === 'percentage-discount'
            ? checkIntOrFloat(this.state.upperLimit)
            : null,
        remarks: this.state.couponRemark,
        creator: getPlayoUserId(),
        customCreator: null,
        notifyEmails: this.state.emailList,
        isPlayoOnly: false,
        couponCount: checkIntOrFloat(this.state.couponCount),
        perUserCount: 0,
        maxUsage: this.state.permanentCheck
          ? checkIntOrFloat(this.state.maxUsageCount)
          : null,
        isNewUser: false,
        isMultipleSlot: this.state.applicableOnMultipleSlot,
        isPermanent: this.state.permanentCheck,
        playoPercent: 0,
        isUserSpecific: false
      }
      await this.props.dispatch(generateCoupon(reqBody))
      if (this.props.generateCouponToast) {
        if (this.props.generateCouponToast.resourcesNotLoadedMessage) {
          resourcesNotLoadedToast()
        } else if (this.props.generateCouponToast.httpErrorMessage) {
          toaster.danger(this.props.generateCouponToast.httpErrorMessage, {
            id: 'HTTP-ERROR-TOAST-4'
          })
        } else if (this.props.generateCouponToast.requestStatus === -1) {
          toaster.notify("Sorry, you don't have permission to do that")
        } else if (this.props.generateCouponToast.requestStatus === 1) {
          await this.resetForm()
          toaster.success(this.props.generateCouponToast.message, {
            id: 'GCF-TOAST-2'
          })
        } else if (this.props.generateCouponToast.requestStatus === 0) {
          toaster.danger(this.props.generateCfouponToast.message, {
            id: 'GCF-TOAST-2'
          })
        }
      }
      await this.props.dispatch(getCouponDetails({ couponId: '', page: 1 }))
    } else {
      if (this.state.couponCount === '') {
        await this.setState({ isCouponCountInvalid: true })
        await this.scrollToElement('isCouponCountInvalid')
      }
      if (this.state.permanentCheck) {
        if (this.state.maxUsageCount === '') {
          await this.setState({ isMaxUsageCountInvalid: true })
          await this.scrollToElement('isMaxUsageCountInvalid')
        }
      }
      if (this.state.typeRadioValue === 'cash-discount') {
        if (this.state.cashDiscountAmount === '') {
          await this.setState({ isCashDiscountAmountInvalid: true })
          await this.scrollToElement('isCashDiscountAmountInvalid')
        }
      } else if (this.state.typeRadioValue === 'percentage-discount') {
        if (this.state.discountPercentage === '') {
          await this.setState({ isDiscountPercentageInvalid: true })
          await this.scrollToElement('isDiscountPercentageInvalid')
        }
        if (this.state.upperLimit === '') {
          await this.setState({ isUpperLimitInvalid: true })
          await this.scrollToElement('isUpperLimitInvalid')
        }
      }
      if (this.state.isTimeRangeInvalid) {
        await this.scrollToElement('isTimeRangeInvalid')
      }
      if (this.state.selectedDayIds.length <= 0) {
        await this.setState({ isDayInvalid: true })
        await this.scrollToElement('isDayInvalid')
      }
      if (this.state.isDateRangeInvalid) {
        await this.scrollToElement('isDateRangeInvalid')
      }
    }
  }

  // -----------------------------------------------------------------------
  // validateTypeField()
  // -----------------------------------------------------------------------
  validateTypeField = async () => {
    if (this.state.typeRadioValue === 'percentage-discount') {
      return (
        this.state.discountPercentage !== '' && this.state.upperLimit !== ''
      )
      // if (
      //   this.state.discountPercentage !== "" &&
      //   this.state.upperLimit !== ""
      // ) {
      //   return true;
      // } else {
      //   return false;
      // }
    } else if (this.state.typeRadioValue === 'cash-discount') {
      return this.state.cashDiscountAmount !== ''
      // if (this.state.cashDiscountAmount !== "") {
      //   return true;
      // } else {
      //   return false;
      // }
    }
  }

  // -----------------------------------------------------------------------
  // validatePermanenetField()
  // -----------------------------------------------------------------------
  validatePermanenetField = async () => {
    if (this.state.permanentCheck) {
      return this.state.maxUsageCount !== ''
      // if (this.state.maxUsageCount !== "") {
      //   return true;
      // } else {
      //   return false;
      // }
    } else {
      return true
    }
  }

  // -----------------------------------------------------------------------
  // render()
  // -----------------------------------------------------------------------
  render () {
    const {
      defaultActivityName,
      defaultActivityValue,
      selectedActivityValue,
      fromDate,
      toDate,
      dayCheckAll,
      daysData,
      fromTime,
      toTime,
      clubMinuteStep,
      applicableOnMultipleSlot,
      typeRadioValue,
      showCashDiscountActions,
      showPercentageDiscountActions,
      permanentCheck,
      showMaxUsageActions,
      couponRemark,
      cashDiscountAmount,
      discountPercentage,
      upperLimit,
      maxUsageCount,
      couponCount,
      isCashDiscountAmountInvalid,
      isDiscountPercentageInvalid,
      isUpperLimitInvalid,
      isMaxUsageCountInvalid,
      isCouponCountInvalid,
      isDayInvalid,
      isDateRangeInvalid,
      isTimeRangeInvalid
    } = this.state

    const { showGenerateCouponsDialog } = this.props

    return (
      <Dialog
        width={540}
        isShown={showGenerateCouponsDialog}
        title='Generate Coupons'
        onConfirm={() => this.confirmGenerateCoupon()}
        onCloseComplete={() => this.resetForm()}
      >
        <React.Fragment>
          <div className='GCF-A1-wrapper'>
            <div className='GCF-A1-actions-wrapper GCF-A1-margin-bottom-16'>
              <div className='GCF-A1-col-container'>
                <div className='GCF-A1-label-container GCF-A1-margin-bottom-16'>
                  <Text id='GCF-A1-label-type-one'>Activities</Text>
                </div>
                <div className='GCF-A1-club-activity-list-select-container'>
                  <ClubActivityListSelect
                    defaultActivityName={defaultActivityName}
                    defaultActivityValue={defaultActivityValue}
                    selectedActivityValue={selectedActivityValue}
                    handleActivityChange={this.handleActivityChange}
                  />
                </div>
              </div>
            </div>
            <div className='GCF-A1-actions-wrapper GCF-A1-margin-bottom-16'>
              <div className='GCF-A1-col-container'>
                <div className='GCF-A1-label-container GCF-A1-margin-bottom-16'>
                  <Text id='GCF-A1-label-type-one'>Date</Text>
                </div>
                <div className='GCF-A1-row-container'>
                  <div className='GCF-A1-col-container GCF-A1-margin-right-124'>
                    <div className='GCF-A1-label-container GCF-A1-margin-bottom-4'>
                      <Text id='GCF-A1-label-type-two'>From</Text>
                    </div>
                    <div className='GCF-A1-from-datepicker-container'>
                      <CustomizedDatePicker
                        date={fromDate}
                        handleDateChange={this.handleFromDateChange}
                      />
                    </div>
                  </div>
                  <div className='GCF-A1-col-container'>
                    <div className='GCF-A1-label-container GCF-A1-margin-bottom-4'>
                      <Text id='GCF-A1-label-type-two'>To</Text>
                    </div>
                    <div className='GCF-A1-to-datepicker-container'>
                      <CustomizedDatePicker
                        date={toDate}
                        handleDateChange={this.handleToDateChange}
                      />
                    </div>
                  </div>
                </div>
                {isDateRangeInvalid && (
                  <div
                    className='GCF-A1-custom-inline-alert-container'
                    ref={'isDateRangeInvalid'}
                  >
                    <CustomInlineAlert
                      intent='danger'
                      alertMsg='Invalid date range selected'
                    />
                  </div>
                )}
              </div>
            </div>
            <div className='GCF-A1-actions-wrapper GCF-A1-margin-bottom-16'>
              <div className='GCF-A1-col-container'>
                <div className='GCF-A1-label-container GCF-A1-margin-bottom-16'>
                  <Text id='GCF-A1-label-type-one'>Day</Text>
                </div>
                <div className='GCF-A1-checkbox-wrapper'>
                  <Checkbox
                    label='All Days'
                    marginRight={32}
                    onChange={e => this.handleCheckAll(e, 'day')}
                    checked={dayCheckAll}
                  />
                  {daysData.map(data => (
                    <Checkbox
                      marginRight={16}
                      key={data.id}
                      label={data.day}
                      checked={data.checked}
                      onChange={e =>
                        this.handleCheckIndividual(e, 'day', data.id)
                      }
                    />
                  ))}
                </div>
                {isDayInvalid && (
                  <div
                    className='GCF-A1-custom-inline-alert-container'
                    ref={'isDayInvalid'}
                  >
                    <CustomInlineAlert
                      intent='danger'
                      alertMsg='Select at least one day'
                    />
                  </div>
                )}
              </div>
            </div>
            <div className='GCF-A1-actions-wrapper GCF-A1-margin-bottom-16'>
              <div className='GCF-A1-col-container'>
                <div className='GCF-A1-label-container GCF-A1-margin-bottom-16'>
                  <Text id='GCF-A1-label-type-one'>Time</Text>
                </div>
                <div className='GCF-A1-row-container GCF-A1-margin-bottom-16'>
                  <div className='GCF-A1-checkbox-wrapper'>
                    <Checkbox
                      label='Applicable on multiple slot'
                      checked={applicableOnMultipleSlot}
                      onChange={e =>
                        this.handleApplicableOnMultipleSlotCheckbox(e)
                      }
                    />
                  </div>
                </div>
                <div className='GCF-A1-row-container'>
                  <div className='GCF-A1-col-container GCF-A1-margin-right-124'>
                    <div className='GCF-A1-label-container GCF-A1-margin-bottom-4'>
                      <Text id='GCF-A1-label-type-two'>From</Text>
                    </div>
                    <div className='GCF-A1-from-timepicker-container'>
                      <CustomizedTimePicker
                        time={fromTime}
                        clubMinuteStep={clubMinuteStep}
                        handleTimeChange={this.handleFromTimeChange}
                      />
                    </div>
                  </div>
                  <div className='GCF-A1-col-container'>
                    <div className='GCF-A1-label-container GCF-A1-margin-bottom-4'>
                      <Text id='GCF-A1-label-type-two'>To</Text>
                    </div>
                    <div className='GCF-A1-to-timepicker-container'>
                      <CustomizedTimePicker
                        time={toTime}
                        clubMinuteStep={clubMinuteStep}
                        handleTimeChange={this.handleToTimeChange}
                      />
                    </div>
                  </div>
                </div>
                {isTimeRangeInvalid && (
                  <div
                    className='GCF-A1-custom-inline-alert-container'
                    ref={'isTimeRangeInvalid'}
                  >
                    <CustomInlineAlert
                      intent='danger'
                      alertMsg='Invalid time range selected'
                    />
                  </div>
                )}
              </div>
            </div>
            <div className='GCF-A1-actions-wrapper GCF-A1-margin-bottom-16'>
              <div className='GCF-A1-col-container'>
                <div className='GCF-A1-label-container GCF-A1-margin-bottom-16'>
                  <Text id='GCF-A1-label-type-one'>Type</Text>
                </div>
                <div
                  className='GCF-A1-radio-btn-container GCF-A1-row-container GCF-A1-margin-bottom-16'
                  aria-label='Radio Group Label 16'
                  role='group'
                >
                  <Radio
                    className='GCF-A1-radio-btn'
                    width={128}
                    marginRight={124}
                    size={16}
                    name='group15'
                    label='Cash Discount'
                    checked={typeRadioValue === 'cash-discount'}
                    onChange={e =>
                      this.handleTypeRadioOptionChange(e, 'cash-discount')
                    }
                  />
                  <Radio
                    className='GCF-A1-radio-btn'
                    size={16}
                    name='group15'
                    label='Percentage Discount'
                    checked={typeRadioValue === 'percentage-discount'}
                    onChange={e =>
                      this.handleTypeRadioOptionChange(e, 'percentage-discount')
                    }
                  />
                </div>
                {showCashDiscountActions && (
                  <div className='GCF-A1-col-container'>
                    <div className='GCF-A1-row-container'>
                      <TextInput
                        width={224}
                        placeholder='Amount'
                        value={cashDiscountAmount}
                        isInvalid={isCashDiscountAmountInvalid}
                        onChange={e =>
                          this.handleInputFields(e, 'cash-discount-amount')
                        }
                      />
                    </div>
                    {isCashDiscountAmountInvalid && (
                      <div
                        className='GCF-A1-custom-inline-alert-container'
                        ref={'isCashDiscountAmountInvalid'}
                      >
                        <CustomInlineAlert
                          intent='danger'
                          alertMsg='Amount is required'
                        />
                      </div>
                    )}
                  </div>
                )}
                {showPercentageDiscountActions && (
                  <div className='GCF-A1-row-container'>
                    <div className='GCF-A1-col-container'>
                      <div className='GCF-A1-row-container'>
                        <TextInput
                          width={224}
                          marginRight={32}
                          placeholder='Percentage'
                          value={discountPercentage}
                          isInvalid={isDiscountPercentageInvalid}
                          onChange={e =>
                            this.handleInputFields(e, 'discount-percentage')
                          }
                        />
                      </div>
                      {isDiscountPercentageInvalid && (
                        <div
                          className='GCF-A1-custom-inline-alert-container'
                          ref={'isDiscountPercentageInvalid'}
                        >
                          <CustomInlineAlert
                            intent='danger'
                            alertMsg='Discount percentage is required'
                          />
                        </div>
                      )}
                    </div>
                    <div className='GCF-A1-col-container'>
                      <div className='GCF-A1-row-container'>
                        <TextInput
                          width={224}
                          placeholder='Upper limit'
                          value={upperLimit}
                          isInvalid={isUpperLimitInvalid}
                          onChange={e =>
                            this.handleInputFields(e, 'upper-limit')
                          }
                        />
                      </div>
                      {isUpperLimitInvalid && (
                        <div
                          className='GCF-A1-custom-inline-alert-container'
                          ref={'isUpperLimitInvalid'}
                        >
                          <CustomInlineAlert
                            intent='danger'
                            alertMsg='Upper limit is required'
                          />
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='GCF-A1-actions-wrapper GCF-A1-margin-bottom-16'>
              <div className='GCF-A1-col-container'>
                <div className='GCF-A1-label-container GCF-A1-margin-bottom-16'>
                  <Text id='GCF-A1-label-type-one'>Permanent</Text>
                </div>
                <div className='GCF-A1-checkbox-wrapper GCF-A1-row-container'>
                  <Checkbox
                    label='Yes (If yes, coupon can be used multiple times over specified period)'
                    checked={permanentCheck}
                    onChange={e => this.handlePermanentCheckbox(e)}
                  />
                </div>
                {showMaxUsageActions && (
                  <div className='GCF-A1-col-container GCF-A1-margin-top-16'>
                    <div className='GCF-A1-label-container GCF-A1-margin-bottom-4'>
                      <Text id='GCF-A1-label-type-two'>Max Usage</Text>
                    </div>
                    <div className='GCF-A1-row-container'>
                      <TextInput
                        width={224}
                        marginRight={32}
                        placeholder='Max usage count'
                        value={maxUsageCount}
                        isInvalid={isMaxUsageCountInvalid}
                        onChange={e =>
                          this.handleInputFields(e, 'max-usage-count')
                        }
                      />
                    </div>
                    {isMaxUsageCountInvalid && (
                      <div
                        className='GCF-A1-custom-inline-alert-container'
                        ref={'isMaxUsageCountInvalid'}
                      >
                        <CustomInlineAlert
                          intent='danger'
                          alertMsg='Max usage count is required'
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className='GCF-A1-actions-wrapper GCF-A1-margin-bottom-16'>
              <div className='GCF-A1-col-container'>
                <div className='GCF-A1-label-container GCF-A1-margin-bottom-16'>
                  <Text id='GCF-A1-label-type-one'>Remarks</Text>
                </div>
                <div className='GCF-A1-row-container'>
                  <Textarea
                    className='GCF-A1-input-field'
                    style={{ resize: 'none' }}
                    width={336}
                    height={80}
                    placeholder='Coupon remarks'
                    maxLength='100'
                    value={couponRemark}
                    onChange={e => this.handleCouponRemarksInput(e)}
                  />
                </div>
              </div>
            </div>
            <div className='GCF-A1-last-actions-wrapper GCF-A1-margin-bottom-16'>
              <div className='GCF-A1-col-container'>
                <div className='GCF-A1-label-container GCF-A1-margin-bottom-16'>
                  <Text id='GCF-A1-label-type-one'>Coupon Count</Text>
                </div>
                <div className='GCF-A1-row-container'>
                  <TextInput
                    width={224}
                    marginRight={32}
                    placeholder='No. of coupons to generate'
                    value={couponCount}
                    isInvalid={isCouponCountInvalid}
                    onChange={e => this.handleInputFields(e, 'coupon-count')}
                  />
                </div>
                {isCouponCountInvalid && (
                  <div
                    className='GCF-A1-custom-inline-alert-container'
                    ref={'isCouponCountInvalid'}
                  >
                    <CustomInlineAlert
                      intent='danger'
                      alertMsg='Coupon count is required'
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </React.Fragment>
      </Dialog>
    )
  }
}

const mapStateToProps = ({ generateCouponToast, clubActivityData }) => ({
  generateCouponToast,
  clubActivityData
})

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default connect(mapStateToProps)(EditCouponsForm)
