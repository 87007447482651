import React from "react";
import { SelectField, Pane } from "evergreen-ui";
import { connect } from "react-redux";

const index = ({ handleCountChange, countValue, countState, defaultCount }) => {
  const maxCount = Array.from(Array(countState.maxSlotCount).keys());

  return (
    <Pane className='CALS-A1-select-input-field-container'>
      <SelectField
        className='CALS-A1-select-input-field'
        marginLeft={20}
        label='Slot Count'
        width={150}
        height={32}
        onChange={(e) => handleCountChange(e)}
        value={countValue}
      >
        <option value={defaultCount}>{defaultCount}</option>
        {maxCount &&
          maxCount.map((count) => (
            <option key={count} value={count + 1}>
              {count + 1}
            </option>
          ))}
      </SelectField>
    </Pane>
  );
};

export default index;
