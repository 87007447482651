// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import Header from "../../components/Header";
import BlockSlots from "./BlockSlots";
import UnblockSlots from "./UnblockSlots";

// -----------------------------------------------------------------------
// Blocking Component
// -----------------------------------------------------------------------
class Blocking extends React.Component {
  constructor(props) {
    super(props);
    this.state = { selectedTab: "block" };
  }

  // -----------------------------------------------------------------------
  // handleTabsChange()
  // -----------------------------------------------------------------------
  handleTabsChange = type => {
    this.setState({ selectedTab: type });
  };

  // -----------------------------------------------------------------------
  // render()
  // -----------------------------------------------------------------------
  render() {
    const { selectedTab } = this.state;

    return (
      <React.Fragment>
        <Header
          headerText={selectedTab === "block" ? "Block Slots" : "Unblock Slots"}
        />
        <div style={{ display: selectedTab === "block" ? "block" : "none" }}>
          <BlockSlots
            selectedTab={selectedTab}
            handleTabsChange={this.handleTabsChange}
          />
        </div>

        <div style={{ display: selectedTab === "unblock" ? "block" : "none" }}>
          <UnblockSlots
            selectedTab={selectedTab}
            handleTabsChange={this.handleTabsChange}
          />
        </div>
      </React.Fragment>
    );
  }
}

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default Blocking;
