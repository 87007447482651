// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import { connect } from "react-redux";
import { Button, Paragraph, Text, Badge } from "evergreen-ui";
import { toDateFormat32, generateCurrencySymbol } from "../../utils";
import { saveSelectedMembershipPackageDetails } from "../../actions";
import moment from "moment";

// -----------------------------------------------------------------------
// SubscribedPackagesDialogContent Component
// -----------------------------------------------------------------------
class SubscribedPackagesDialogContent extends React.Component {
  // -----------------------------------------------------------------------
  // handleRenewPackage()
  // -----------------------------------------------------------------------
  handleRenewPackage = async (e, selectedMembershipPackageDetails) => {
    e.persist();
    await this.props.dispatch(
      saveSelectedMembershipPackageDetails(
        selectedMembershipPackageDetails,
        this.props.selectedMemberDetails,
        "renew-package"
      )
    );
    if (this.props.selectedMembershipPackageDetails) {
      if (this.props.selectedMembershipPackageDetails.requestStatus === 1) {
        await this.props.handleshowAvailedMembershipPackagesDialogVisibility(
          "close"
        );
        await this.props.handleMembershipPackagesCheckoutPageDisplay("show");
      }
    }
  };

  // -----------------------------------------------------------------------
  // render()
  // -----------------------------------------------------------------------
  render() {
    const { membersSubscribedPackagesData, handlePayNowDialogVisibility } =
      this.props;
    return (
      <React.Fragment>
        <div className="SPDC-A1-wrapper">
          {membersSubscribedPackagesData &&
            membersSubscribedPackagesData.packages.map(
              (membershipPackage, index) => (
                <div className="SPDC-A1-data-container" key={index}>
                  <div className="SPDC-A1-row-container">
                    <div className="SPDC-A1-left-pane-content SPDC-A1-max-width-264">
                      <Text id="SPDC-A1-text-type-one">
                        {membershipPackage.packageName}
                      </Text>
                    </div>
                    <div className="SPDC-A1-right-pane-content SPDC-A1-max-width-264">
                      {membershipPackage.balance > 0 ? (
                        <Badge minWidth={96} color="yellow">
                          {`Balance ${generateCurrencySymbol()} ${
                            membershipPackage.balance
                          }`}
                        </Badge>
                      ) : moment(membershipPackage.renewalDate).isBefore(
                          new Date()
                        ) ? (
                        <Badge color="red">Expired</Badge>
                      ) : (
                        <Badge color="green">
                          {`Renew on ${toDateFormat32(
                            membershipPackage.renewalDate
                          )}`}
                        </Badge>
                      )}
                    </div>
                  </div>
                  {membershipPackage.packageDescription !== "" && (
                    <div className="SPDC-A1-row-container SPDC-A1-margin-bottom-16">
                      <div className="SPDC-A1-width-328">
                        <Paragraph id="SPDC-A1-text-type-two">
                          {membershipPackage.packageDescription}
                        </Paragraph>
                      </div>
                    </div>
                  )}
                  {membershipPackage.additionalInfo.length > 0 && (
                    <div className="SPDC-A1-row-container SPDC-A1-margin-bottom-16">
                      <div className=" SPDC-A1-width-328">
                        <Paragraph id="SPDC-A1-text-type-four">
                          {membershipPackage.additionalInfo.map(
                            (info) => `${info} `
                          )}
                        </Paragraph>
                      </div>
                    </div>
                  )}

                  <div className="SPDC-A1-row-container SPDC-A1-align-items-center">
                    <div className="SPDC-A1-left-pane-content SPDC-A1-width-328">
                      <Text id="SPDC-A1-text-type-one">{`${generateCurrencySymbol()} ${
                        membershipPackage.grossAmount
                      }`}</Text>

                      {membershipPackage.startDate !== "" && (
                        <Text id="SPDC-A1-text-type-three">{` | Started on ${toDateFormat32(
                          membershipPackage.startDate
                        )}`}</Text>
                      )}
                      <Text id="SPDC-A1-text-type-three">{` | ${membershipPackage.period} Days`}</Text>
                    </div>

                    <div className="SPDC-A1-right-pane-content SPDC-A1-width-184 SPDC-A1-justify-content-flex-end">
                      {membershipPackage.balance > 0 ? (
                        <Button
                          className="SPDC-A1-button"
                          width={114}
                          height={32}
                          appearance="primary"
                          intent="warning"
                          onClick={(e) =>
                            handlePayNowDialogVisibility(
                              "open",
                              membershipPackage.subscriptionId,
                              membershipPackage.balance
                            )
                          }
                        >
                          Pay Now
                        </Button>
                      ) : moment(membershipPackage.renewalDate).isBefore(
                          new Date()
                        ) ? (
                        <Button
                          className="SPDC-A1-button"
                          width={114}
                          height={32}
                          appearance="primary"
                          onClick={(e) =>
                            this.handleRenewPackage(e, membershipPackage)
                          }
                        >
                          Renew
                        </Button>
                      ) : (
                        <Button
                          className="SPDC-A1-button"
                          width={114}
                          height={32}
                          appearance="primary"
                          intent="danger"
                          onClick={(e) =>
                            this.handleRenewPackage(e, membershipPackage)
                          }
                        >
                          Renew
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              )
            )}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({
  membersSubscribedPackagesData,
  selectedMembershipPackageDetails,
}) => ({
  membersSubscribedPackagesData,
  selectedMembershipPackageDetails,
});

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default connect(mapStateToProps)(SubscribedPackagesDialogContent);
