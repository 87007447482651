// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import { connect } from "react-redux";
import {
  Text,
  Button,
  toaster,
  Strong,
  Pane,
  Table,
  Avatar,
  NewPersonIcon,
} from "evergreen-ui";

import { getPlayoUserId, getAuthToken } from "../../utils";
import { removeUser } from "../../actions/index";
import ManagePermission from "./managePermission";

// -----------------------------------------------------------------------
// LoginPage Component
// -----------------------------------------------------------------------
class StaffConsole extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      managePermissionPage: false,
      staffMember: "",
    };
    this.baseState = this.state;
  }

  removeUser = async (userId) => {
    let { isAdmin, handleSidebar } = this.props;
    if (isAdmin) {
      let data = {
        userId: userId,
      };
      await this.props.dispatch(removeUser(data));
      if (this.props.userRemove.requestStatus === 1) {
        toaster.success("User Removed");
      } else {
        toaster.danger(this.props.userRemove.message);
      }

      handleSidebar("", "staff-console");
    } else {
      toaster.danger("You dont have permission");
    }
  };

  // -----------------------------------------------------------------------
  // ComponentDimMount()
  // -----------------------------------------------------------------------
  componentDidMount() {
    this.setState({
      ...this.baseState,
    });
  }

  render() {
    let userId = getPlayoUserId();
    let auth = getAuthToken();

    const {
      staffList,
      handleAddDeskPersonDialogVisibility,
      showManagePermission,
      staffMember,
      managePermissionPage,
    } = this.props;

    let staffCount = staffList && staffList.data && staffList.data.length;
    return !managePermissionPage && staffCount ? (
      <React.Fragment>
        <Pane
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"flex-start"}
          alignItems={"flex-start"}
        >
          <Pane padding={10}>Permission</Pane>
          <Pane
            display={"flex"}
            flexDirection={"row"}
            background="tint2"
            padding={20}
            marginBottom={16}
            width="100%"
            justifyContent={"flext-start"}
          >
            {/* <TextInputField
							width="22%"
							label="Username"
							placeholder="Enter Username"
						/>
						<SelectField width="22%" label="Role" marginLeft={20}>
							<option>admin</option>
							<option>support</option>
							<option>Manager</option>
						</SelectField> */}
            <Button
              width={150}
              marginTop={25}
              height={30}
              iconBefore={<NewPersonIcon />}
              appearance="primary"
              intent="success"
              onClick={() => handleAddDeskPersonDialogVisibility("open")}
            >
              Add User
            </Button>
          </Pane>
          <Strong marginTop={20} padding={10}>
            Team Members ( {staffCount} )
          </Strong>
          <Pane padding={10} width="80%" marginBottom={30}>
            <Table border="default">
              <Table.Head>
                <Table.TextHeaderCell>Name</Table.TextHeaderCell>
                <Table.TextHeaderCell>Role</Table.TextHeaderCell>
                <Table.TextHeaderCell>Option</Table.TextHeaderCell>
              </Table.Head>

              {staffList && staffList.data && staffList.data.length ? (
                <Table.Body>
                  {staffList &&
                    staffList.data.map((staffMember, index) => (
                      <Table.Row height={65}>
                        <Table.TextCell>
                          <Pane
                            width="20%"
                            display={"flex"}
                            flexDirection={"row"}
                          >
                            <Avatar name="Jeroen Ransijn" size={20} />
                            <Text marginLeft={10}>{staffMember.username}</Text>
                          </Pane>
                        </Table.TextCell>
                        <Table.TextCell>
                          <Text>{staffMember.role}</Text>
                        </Table.TextCell>
                        <Table.TextCell>
                          <Button
                            height={30}
                            appearance="primary"
                            onClick={() =>
                              showManagePermission(true, staffMember)
                            }
                          >
                            Manage Permission
                          </Button>

                          {staffMember &&
                          staffMember.role.toLowerCase() === "admin" ? null : (
                            <Text
                              marginLeft={35}
                              onClick={() =>
                                this.removeUser(staffMember.userId)
                              }
                              cursor={"pointer"}
                            >
                              Delete
                            </Text>
                          )}
                        </Table.TextCell>
                      </Table.Row>
                    ))}
                </Table.Body>
              ) : null}
            </Table>
          </Pane>
        </Pane>
      </React.Fragment>
    ) : (
      <React.Fragment>
        {staffMember ? (
          <ManagePermission
            showManagePermission={showManagePermission}
            staffMember={staffMember}
            handleSidebar={this.props.handleSidebar}
          />
        ) : null}
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ authStatus, loginDetails, userRemove }) => ({
  authStatus,
  loginDetails,
  userRemove,
});

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default connect(mapStateToProps)(StaffConsole);
