const configExtrasList = (state = null, action) => {
    if (action.type === "SET_CONFIG_EXTRA_LIST") {
      return action.payload;
    } else {
      return state;
    }
    // switch (action.type) {
    //   case "SET_CLUB_EXTRAS_LIST": {
    //     return action.payload;
    //   }
    //   default: {
    //     return state;
    //   }
    // }
  };
  
  export default configExtrasList;
  