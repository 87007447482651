// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import { Text } from "evergreen-ui";
import RemoveButton from "../../components/RemoveButton";
import CartActions from "../../components/CartActions";
import { toDateTimeFormat, generateCurrencySymbol } from "../../utils";

// -----------------------------------------------------------------------
// CartOnCalendarPage Component
// -----------------------------------------------------------------------
class CartOnCalendarPage extends React.Component {
  // -----------------------------------------------------------------------
  //  render
  // -----------------------------------------------------------------------
  render() {
    const {
      cartData,
      handleClearCart,
      onRemoveFromCart,

      handleBookingCheckoutDisplay,
    } = this.props;

    const cartDataSlots = cartData.cart.slots;
    return (
      <div className='COCP-A1-wrapper'>
        <div className='COCP-A1-cart-data-container'>
          <div className='COCP-A1-selected-sport-name-container'>
            {/* <div className="COCP-A1-badge-container">
							{selectedActivityName && selectedActivityName.length > 0 && (
								<Badge color="blue" isSolid marginRight={16}>
									{selectedActivityName}
								</Badge>
							)}
						</div> */}
          </div>
          {cartDataSlots &&
            cartDataSlots.map((slot, index) => (
              <div className='COCP-A1-cart-item-wrapper' key={index}>
                <div className='COCP-A1-cart-item-container'>
                  <div className='COCP-A1-cart-item-date-time-container'>
                    <div className='COCP-A1-label-container'>
                      <Text id='COCP-A1-type-one-label'>
                        {toDateTimeFormat(slot.slotDate, slot.slotTime, slot.endTime)}
                      </Text>
                    </div>
                  </div>
                  <div className='COCP-A1-cart-item-court-name-price-container'>
                    <div className='COCP-A1-label-container'>
                      <Text id='COCP-A1-type-two-label'>{`${slot.courtName}${
                        slot.count > 1 || slot.activityType === 1
                          ? " | " + slot.count + " slot(s)"
                          : ""
                      }`}</Text>
                    </div>
                    <div className='COCP-A1-label-container'>
                      {slot.grossPrice === slot.price ? (
                        <Text id='COCP-A1-type-three-label'>
                          {`${generateCurrencySymbol()} ${
                            slot.count ? slot.count * slot.grossPrice : slot.grossPrice
                          }`}
                        </Text>
                      ) : (
                        <React.Fragment>
                          <Text id='COCP-A1-type-four-label' marginRight={12}>
                            {`${generateCurrencySymbol()} ${
                              slot.count ? slot.count * slot.price : slot.price
                            }`}
                          </Text>
                          <Text id='COCP-A1-type-three-label'>
                            {`${generateCurrencySymbol()} ${
                              slot.count ? slot.count * slot.grossPrice : slot.grossPrice
                            }`}
                          </Text>
                        </React.Fragment>
                      )}
                    </div>
                  </div>
                  <div className='COCP-A1-remove-cart-item-button-container'>
                    <RemoveButton onRemoveFromCart={onRemoveFromCart} slot={slot} />
                  </div>
                </div>
              </div>
            ))}
        </div>
        <div className='COCP-A1-total-price-cart-actions-wrapper'>
          <div className='COCP-A1-total-text-price-container'>
            <div className='COCP-A1-margin-top-25'>
              <Text id='COCP-A1-type-five-label'>Total</Text>
            </div>
            <div className='COCP-A1-margin-top-25'>
              <Text id='COCP-A1-type-five-label'>
                {`${generateCurrencySymbol()} ${cartData.cart.cartPrice}`}
              </Text>
            </div>
          </div>
          <CartActions
            handleClearCart={handleClearCart}
            handleBookingCheckoutDisplay={handleBookingCheckoutDisplay}
          />
        </div>
      </div>
    );
  }
}

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default CartOnCalendarPage;
