// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import {Pane} from "evergreen-ui";

// -----------------------------------------------------------------------
// DayGraph Component
// -----------------------------------------------------------------------
class GraphPane extends React.Component {
    render() {
        const {
            selectedTab,
            graphProjections
        } = this.props;

        return (
            <Pane padding={16} flex="1">
                {Object.values(graphProjections).map((tab, index) => (
                    <Pane
                        key={tab.type}
                        id={`panel-${tab.type}`}
                        role="tabpanel"
                        aria-labelledby={tab.type}
                        display={index === selectedTab ? 'block' : 'none'}
                    >
                        {tab.graph}
                    </Pane>
                ))}
            </Pane>
        )
    }
}

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default GraphPane;