// -----------------------------------------------------------------------
// Import Api and other Resources
// -----------------------------------------------------------------------
import { toggleLoaderOff } from "./loaderUtils";
import { removePlayoTokens } from "../utils";
import { toggleAuthStatus } from "../actions";

// -----------------------------------------------------------------------
// handleServerResponse()
// -----------------------------------------------------------------------
export const handleServerResponse = async (dispatch, res, reducerType) => {
  if (res === undefined) {
    toggleLoaderOff(dispatch);
    dispatch({
      type: reducerType,
      payload: {
        resourcesNotLoadedMessage: true
      }
    });
  } else if (res.status === 200) {
    toggleLoaderOff(dispatch);
    const body = res.body;

    dispatch({ type: reducerType, payload: body });
  } else if (res.status === 401 || res.status === 403) {
    toggleLoaderOff(dispatch);
    removePlayoTokens();
    await dispatch(toggleAuthStatus());
  } else if (res.status === 404) {
    toggleLoaderOff(dispatch);
    dispatch({
      type: reducerType,
      payload: {
        httpErrorMessage: "Please try again later",
        statusCode: 404
      }
    });
  } else {
    toggleLoaderOff(dispatch);
    dispatch({
      type: reducerType,
      payload: {
        httpErrorMessage: "Something went wrong, please contact Playo",
        statusCode: res.statusCode
      }
    });
  }
};
