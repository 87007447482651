// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import { connect } from "react-redux";
import { Button, Paragraph, Text, Badge } from "evergreen-ui";
import {
  determineBadgeColorForSlotsAvailability,
  toDateFormat32,
  generateCurrencySymbol
} from "../../utils";
import { saveSelectedMembershipPackageDetails } from "../../actions";

// -----------------------------------------------------------------------
// MembershipPackagesDialogContent Component
// -----------------------------------------------------------------------
class MembershipPackagesDialogContent extends React.Component {
  // -----------------------------------------------------------------------
  // handleAddPackage()
  // -----------------------------------------------------------------------
  handleAddPackage = async (e, selectedMembershipPackageDetails) => {
    e.persist();
    await this.props.dispatch(
      saveSelectedMembershipPackageDetails(
        selectedMembershipPackageDetails,
        this.props.selectedMemberDetails,
        "add-package",
        this.props.pageNumOnMembersPage,
        this.props.updateIndividualMemberDetails ? true : false
      )
    );
    if (this.props.selectedMembershipPackageDetails) {
      if (this.props.selectedMembershipPackageDetails.requestStatus === 1) {
        await this.props.handleshowMembershipPackagesDialogVisibility("close");
        await this.props.handleMembershipPackagesCheckoutPageDisplay("show");
      }
    }
  };

  // -----------------------------------------------------------------------
  // handleScroll()
  // -----------------------------------------------------------------------
  handleScroll = e => {
    let element = e.target;
    if (element.scrollHeight - element.scrollTop === element.clientHeight) {
      if (
        this.props.membershipPackageDetails &&
        this.props.membershipPackageDetails.paginationData.next !== -1
      ) {
        let reqParams = {
          page: this.props.membershipPackageDetails.paginationData.next
        };
        this.props.handleGetMembershipPackageDetailsResponse(reqParams);
      }
    }
  };

  // -----------------------------------------------------------------------
  // render()
  // -----------------------------------------------------------------------
  render() {
    const { concatedMembershipPackageDetails } = this.props;
    return (
      <React.Fragment>
        <div
          className="MPDC-A1-wrapper"
          style={{ overflow: "auto" }}
          onScroll={e => this.handleScroll(e)}
        >
          {concatedMembershipPackageDetails &&
            concatedMembershipPackageDetails.map((membershipPackage, index) => (
              <div className="MPDC-A1-data-container" key={index}>
                <div className="MPDC-A1-row-container">
                  <div className="MPDC-A1-left-pane-content MPDC-A1-max-width-264">
                    <Text id="MPDC-A1-text-type-one">
                      {membershipPackage.packageName}
                    </Text>
                  </div>
                  <div className="MPDC-A1-right-pane-content MPDC-A1-max-width-264">
                    <Badge
                      minWidth={96}
                      color={determineBadgeColorForSlotsAvailability(
                        membershipPackage.availableTickets,
                        membershipPackage.totalTickets
                      )}
                    >
                      {membershipPackage.availableTickets === 0
                        ? `full`
                        : `${membershipPackage.availableTickets} Available`}
                    </Badge>
                  </div>
                </div>
                {membershipPackage.description !== "" && (
                  <div className="MPDC-A1-row-container MPDC-A1-margin-bottom-16">
                    <div className="MPDC-A1-width-328">
                      <Paragraph id="MPDC-A1-text-type-two">
                        {membershipPackage.description}
                      </Paragraph>
                    </div>
                  </div>
                )}
                {membershipPackage.additionalInfo.length > 0 && (
                  <div className="MPDC-A1-row-container MPDC-A1-margin-bottom-16">
                    <div className=" MPDC-A1-width-328">
                      <Paragraph id="MPDC-A1-text-type-four">
                        {membershipPackage.additionalInfo.map(
                          info => `${info} `
                        )}
                      </Paragraph>
                    </div>
                  </div>
                )}
                <div className="MPDC-A1-row-container MPDC-A1-align-items-center">
                  <div className="MPDC-A1-left-pane-content MPDC-A1-width-328">
                    <Text id="MPDC-A1-text-type-one">{`${generateCurrencySymbol()} ${
                      membershipPackage.packagePrice
                    }`}</Text>
                    {membershipPackage.startDate !== "" && (
                      <Text id="MPDC-A1-text-type-three">{` | Started on ${toDateFormat32(
                        membershipPackage.startDate
                      )}`}</Text>
                    )}
                    <Text id="MPDC-A1-text-type-three">{` | ${membershipPackage.period} Days`}</Text>
                  </div>
                  <div className="MPDC-A1-right-pane-content MPDC-A1-width-184 MPDC-A1-justify-content-flex-end">
                    <Button
                      className="MPDC-A1-button"
                      width={114}
                      height={32}
                      appearance="primary"
                      onClick={e => this.handleAddPackage(e, membershipPackage)}
                    >
                      Add Package
                    </Button>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({
  selectedMembershipPackageDetails,
  membershipPackageDetails
}) => ({
  selectedMembershipPackageDetails,
  membershipPackageDetails
});

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default connect(mapStateToProps)(MembershipPackagesDialogContent);
