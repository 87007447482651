// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import { connect } from "react-redux";
import {
  Text,
  Table,
  Avatar,
  Badge,
  IconButton,
  Popover,
  Menu,
  Dialog,
  toaster,
  Position,
  Button,
  Pane,
  PeopleIcon,
  AddIcon,
  CircleArrowRightIcon,
  TrashIcon,
  MoreIcon,
} from "evergreen-ui";
import {
  slotDateConverter4,
  slotTimeConverter12,
  resourcesNotLoadedToast,
  toDateAndTimeFormat,
} from "../../utils";
import {
  getTransactionsDetails,
  togglePremiumFeatureFlag,
  generateBookingStatement,
  getClubExtrasList,
  booking,
} from "../../actions";
import CustomizedPagination from "../../components/CustomizedPagination";
import TransactionsDialogContent from "../../components/TransactionsDialogContent";
import CancelBookingDialogContent from "../../components/CancelBookingDialogContent";
import ExtrasActions from "../../components/ExtrasActions/index";

// -----------------------------------------------------------------------
// BulkBookingTable Component
// -----------------------------------------------------------------------
class BulkBookingTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showTransactionsDialog: false,
      daysData: {
        0: "Mon",
        1: "Tue",
        2: "Wed",
        3: "Thu",
        4: "Fri",
        5: "Sat",
        6: "Sun",
      },
      showExtrasDialog: false,
      activePatternBookingId: "",
      custMobile: "",
      patternBookingId: "",
      isPlayoBooking: false,
    };
  }

  // -----------------------------------------------------------------------
  // handleExtrasDialog()
  // -----------------------------------------------------------------------
  handleExtrasDialog = async (type, booking) => {
    let body = {
      activityId: booking && booking.activityId,
    };

    if (type === "open") {
      this.props.dispatch(getClubExtrasList(body));

      this.setState({
        showExtrasDialog: true,
        custMobile: booking.customerDetails.mobile,
        patternBookingId: booking.patternBookingId,
      });
    } else if (type === "close") {
      await this.props.dispatch({ type: "SET_CUSTOMER_DETAILS", payload: {} });
      this.setState({ showExtrasDialog: false });
    }
  };

  // -----------------------------------------------------------------------
  //  handleTransactionsDialog()
  // -----------------------------------------------------------------------
  handleTransactionsDialog = async (type, patternBookingId = null) => {
    if (type === "hide") {
      await this.setState({ showTransactionsDialog: false });
    }
    if (type === "show") {
      await this.props.dispatch(getTransactionsDetails(patternBookingId));
      if (this.props.transactionsDetails) {
        if (this.props.transactionsDetails.resourcesNotLoadedMessage) {
          resourcesNotLoadedToast();
        } else if (this.props.transactionsDetails.httpErrorMessage) {
          toaster.danger(this.props.transactionsDetails.httpErrorMessage, {
            id: "HTTP-ERROR-TOAST-1",
          });
        } else if (this.props.transactionsDetails.requestStatus === -1) {
          toaster.notify("Sorry, you don't have permission to do that");
        } else if (this.props.transactionsDetails.requestStatus === 1) {
          await this.setState({ showTransactionsDialog: true });
        } else if (this.props.transactionsDetails.requestStatus === 0) {
          toaster.danger(this.props.transactionsDetails.message);
        }
      }
    }
  };

  // -----------------------------------------------------------------------
  // handleCancelBookingDialogBefore()
  // -----------------------------------------------------------------------
  handleCancelBookingDialogBefore = async (
    patternBookingId,
    isPlayoBooking
  ) => {
    this.setState({ activePatternBookingId: patternBookingId });
    this.setState({ isPlayoBooking: isPlayoBooking });
    await this.handleCancelBookingDialog("open");
  };

  // -----------------------------------------------------------------------
  // handleCancelBookingDialog
  // -----------------------------------------------------------------------
  handleCancelBookingDialog = (type) => {
    if (type === "open") {
      this.setState({ showCancelBookingDialog: true });
    }
    if (type === "close") {
      this.setState({ showCancelBookingDialog: false });
    }
  };

  // -----------------------------------------------------------------------
  // changeActiveBookingDetails()
  // -----------------------------------------------------------------------
  changeActiveBookingDetails = async (bookingDetails) => {
    await this.setState({
      activePatternBookingId: bookingDetails.patternBookingId,
    });
  };

  // -----------------------------------------------------------------------
  //  generateStatement()
  // -----------------------------------------------------------------------
  generateStatement = async () => {
    let reqParams = { patternBookingId: this.state.activePatternBookingId };
    await this.props.dispatch(generateBookingStatement(reqParams));
    if (this.props.generateBookingStatementToast) {
      if (this.props.generateBookingStatementToast.resourcesNotLoadedMessage) {
        resourcesNotLoadedToast();
      } else if (this.props.generateBookingStatementToast.httpErrorMessage) {
        toaster.danger(
          this.props.generateBookingStatementToast.httpErrorMessage,
          {
            id: "HTTP-ERROR-TOAST-1",
          }
        );
      } else if (
        this.props.generateBookingStatementToast.requestStatus === -1
      ) {
        toaster.notify("Sorry, you don't have permission to do that");
      } else if (this.props.generateBookingStatementToast.requestStatus === 1) {
        // toaster.success(this.props.generateBookingStatementToast.message);
      } else if (this.props.generateBookingStatementToast.requestStatus === 0) {
        toaster.danger(this.props.generateBookingStatementToast.message);
      }
    }
  };

  // -----------------------------------------------------------------------
  // render()
  // -----------------------------------------------------------------------
  render() {
    const {
      showTransactionsDialog,
      showCancelBookingDialog,
      activePatternBookingId,
      showExtrasDialog,
      isPlayoBooking,
    } = this.state;
    const {
      pageNum,
      handlePageChange,
      bulkBookingSearchResult,
      getPatternBookingDetailsForDrawer,
      transactionsDetails,
    } = this.props;
    return (
      <React.Fragment>
        <div className="ST-A1-wrapper" style={{ overflowX: "visible" }}>
          <div className="ST-A1-table-container">
            <Table>
              <Table.Head>
                <Table.TextHeaderCell className="ST-A1-table-text-header-cell ST-A1-flex-basis-180">
                  Customer
                </Table.TextHeaderCell>
                <Table.TextHeaderCell className="ST-A1-table-text-header-cell ST-A1-flex-basis-180">
                  Activity
                </Table.TextHeaderCell>
                <Table.TextHeaderCell className="ST-A1-table-text-header-cell ST-A1-flex-basis-165">
                  Dates/Days
                </Table.TextHeaderCell>
                <Table.TextHeaderCell className="ST-A1-table-text-header-cell ST-A1-flex-basis-165">
                  Timing
                </Table.TextHeaderCell>
                <Table.TextHeaderCell className="ST-A1-table-text-header-cell ST-A1-flex-basis-160">
                  Source / Timestamp
                </Table.TextHeaderCell>
                <Table.TextHeaderCell className="ST-A1-table-text-header-cell ST-A1-flex-basis-120">
                  Payment
                </Table.TextHeaderCell>
                <Table.TextHeaderCell className="ST-A1-table-text-header-cell ST-A1-flex-basis-120">
                  Status
                </Table.TextHeaderCell>
                <Table.TextHeaderCell className="ST-A1-table-text-header-cell ST-A1-flex-basis-48" />
              </Table.Head>
              <Table.Body className="ST-A1-table-body" overflow="none">
                {bulkBookingSearchResult &&
                  bulkBookingSearchResult.patternBookings &&
                  bulkBookingSearchResult.patternBookings.map(
                    (booking, index) => (
                      <Table.Row
                        isSelectable
                        className="ST-A1-table-row"
                        key={index}
                      >
                        <Table.Cell className="ST-A1-table-cell ST-A1-flex-basis-180">
                          <div className="ST-A1-table-cell-data-container">
                            <div className="ST-A1-member-avatar-and-name-container">
                              <Avatar
                                className="ST-A1-avatar"
                                name={booking.customerDetails.name}
                                size={24}
                                marginRight={12}
                              />
                              <Text id="ST-A1-label-type-one">
                                {booking.customerDetails.name}
                              </Text>
                            </div>
                          </div>
                        </Table.Cell>
                        <Table.Cell className="ST-A1-table-cell ST-A1-flex-basis-180">
                          <div className="ST-A1-table-cell-data-container">
                            <Text id="ST-A1-label-type-three">
                              {booking.sport}
                            </Text>
                            <Text id="ST-A1-label-type-two" marginTop={8}>
                              {booking.courts.map((court, index) => {
                                if (index === booking.courts.length - 1) {
                                  return court;
                                } else return court + ", ";
                              })}
                            </Text>
                          </div>
                        </Table.Cell>
                        <Table.Cell className="ST-A1-table-cell ST-A1-flex-basis-165">
                          <div className="ST-A1-table-cell-data-container">
                            <Text
                              id="ST-A1-label-type-four"
                              onClick={(e) =>
                                getPatternBookingDetailsForDrawer(
                                  e,
                                  booking.patternBookingId
                                )
                              }
                            >
                              {slotDateConverter4(
                                booking.startDate,
                                booking.endDate
                              )}
                            </Text>
                            <Text id="ST-A1-label-type-two" marginTop={8}>
                              {booking.days.length === 7
                                ? "All"
                                : booking.days.map((day, index) => {
                                    if (index === booking.days.length - 1) {
                                      return (
                                        this.state.daysData[day] ||
                                        day[0].toUpperCase() +
                                          day.substring(1, 3)
                                      );
                                    } else {
                                      return (
                                        (this.state.daysData[day] ||
                                          day[0].toUpperCase() +
                                            day.substring(1, 3)) + ", "
                                      );
                                    }
                                  })}
                            </Text>
                          </div>
                        </Table.Cell>
                        <Table.Cell className="ST-A1-table-cell ST-A1-flex-basis-165">
                          <div className="ST-A1-table-cell-data-container">
                            <Text id="ST-A1-label-type-one">
                              {slotTimeConverter12(
                                booking.startTime,
                                booking.endTime
                              )}
                            </Text>
                            <Text id="ST-A1-label-type-two" marginTop={8}>
                              {`${booking.slotCount} Slots`}
                            </Text>
                          </div>
                        </Table.Cell>
                        <Table.Cell className="ST-A1-table-cell ST-A1-flex-basis-160">
                          <div className="ST-A1-table-cell-data-container">
                            <Text id="ST-A1-label-type-two">Club /</Text>
                            <Text id="ST-A1-label-type-two" marginTop={8}>
                              {toDateAndTimeFormat(booking.timestamp)}
                            </Text>
                          </div>
                        </Table.Cell>
                        <Table.Cell className="ST-A1-table-cell ST-A1-flex-basis-120">
                          <div className="ST-A1-table-cell-data-container">
                            {booking.balance === 0 && (
                              <Badge
                                width={72}
                                height={16}
                                color="green"
                                // isSolid
                              >
                                Paid
                              </Badge>
                            )}
                            {booking.balance > 0 && (
                              // <Badge width={72} height={16} color="red" isSolid>
                              <Badge width={72} height={16} color="red">
                                Pending
                              </Badge>
                            )}
                          </div>
                        </Table.Cell>
                        <Table.Cell className="ST-A1-table-cell ST-A1-flex-basis-120">
                          <div className="ST-A1-table-cell-data-container">
                            <div className="ST-A1-status-container">
                              {booking.cancelled === 0 ? (
                                <div className="ST-A1-status-hint-circle ST-A1-ongoing" />
                              ) : booking.cancelled === 1 ? (
                                <div className="ST-A1-status-hint-circle ST-A1-cancelled" />
                              ) : booking.cancelled === 2 ? (
                                <div className="ST-A1-status-hint-circle ST-A1-completed" />
                              ) : null}
                              <Text id="ST-A1-label-type-five">
                                {booking.status}
                              </Text>
                            </div>
                          </div>
                        </Table.Cell>
                        <Table.Cell className="ST-A1-table-cell ST-A1-flex-basis-48">
                          <div className="ST-A1-action-btn-container">
                            <Popover
                              onOpen={() =>
                                this.changeActiveBookingDetails(booking)
                              }
                              content={
                                <Menu>
                                  <Menu.Group>
                                    <Menu.Item
                                      icon={<PeopleIcon />}
                                      onSelect={() =>
                                        this.handleTransactionsDialog(
                                          "show",
                                          booking.patternBookingId
                                        )
                                      }
                                    >
                                      Transactions
                                    </Menu.Item>
                                    <Menu.Item
                                      icon={<AddIcon />}
                                      cursor={"pointer"}
                                      onClick={() =>
                                        this.handleExtrasDialog("open", booking)
                                      }
                                    >
                                      Add Addons
                                    </Menu.Item>
                                    <Menu.Item
                                      icon={<CircleArrowRightIcon />}
                                      onSelect={() => this.generateStatement()}
                                    >
                                      Statements
                                    </Menu.Item>
                                  </Menu.Group>
                                  {booking.cancelled === 2 && <Menu.Divider />}
                                  {booking.cancelled === 2 && (
                                    <Menu.Group>
                                      <Menu.Item
                                        icon={<TrashIcon />}
                                        intent="danger"
                                        onSelect={() =>
                                          this.handleCancelBookingDialogBefore(
                                            booking.patternBookingId,
                                            booking.isPlayoBooking
                                          )
                                        }
                                      >
                                        Cancel
                                      </Menu.Item>
                                    </Menu.Group>
                                  )}
                                  {booking.cancelled === 0 && <Menu.Divider />}
                                  {booking.cancelled === 0 && (
                                    <Menu.Group>
                                      <Menu.Item
                                        icon={<TrashIcon />}
                                        intent="danger"
                                        onSelect={() =>
                                          this.handleCancelBookingDialogBefore(
                                            booking.patternBookingId
                                          )
                                        }
                                      >
                                        Cancel
                                      </Menu.Item>
                                    </Menu.Group>
                                  )}
                                </Menu>
                              }
                              position={Position.BOTTOM_RIGHT}
                            >
                              <IconButton
                                className="ST-A1-action-btn"
                                appearance="minimal"
                                icon={<MoreIcon />}
                                width={24}
                                height={24}
                              />
                            </Popover>
                          </div>
                        </Table.Cell>
                      </Table.Row>
                    )
                  )}
              </Table.Body>
            </Table>
          </div>
          <div className="ST-A1-pagination-wrapper">
            <CustomizedPagination
              currentPage={pageNum}
              totalPages={
                bulkBookingSearchResult &&
                bulkBookingSearchResult.paginationData &&
                bulkBookingSearchResult.paginationData.total
              }
              handlePageChange={handlePageChange}
            />
          </div>
        </div>
        <Dialog
          width={560}
          // shouldCloseOnOverlayClick={false}
          isShown={showExtrasDialog}
          title="Accessories"
          hasFooter={false}
          onCloseComplete={() =>
            this.handleExtrasDialog("close", booking.activityId)
          }
        >
          <ExtrasActions
            mobile={this.state.custMobile}
            bookingId={this.state.patternBookingId}
            bulkBooking={true}
          />
        </Dialog>

        <Dialog
          width="fit-content"
          isShown={showTransactionsDialog}
          title="Transactions"
          onCloseComplete={() => this.handleTransactionsDialog("hide")}
          hasFooter={false}
        >
          <TransactionsDialogContent
            transactionsDetails={transactionsDetails}
          />
        </Dialog>
        <Dialog
          isShown={showCancelBookingDialog}
          title="Cancel Booking"
          onCloseComplete={() => this.handleCancelBookingDialog("close")}
          hasFooter={false}
        >
          <CancelBookingDialogContent
            bookingId={activePatternBookingId}
            handleCancelBookingDialog={this.handleCancelBookingDialog}
            bookingType="bulk"
            isPlayoBooking={isPlayoBooking}
          />
        </Dialog>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({
  transactionsDetails,
  generateBookingStatementToast,
}) => ({
  transactionsDetails,
  generateBookingStatementToast,
});

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default connect(mapStateToProps)(BulkBookingTable);
