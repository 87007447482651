// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import { connect } from "react-redux";
import {
  Button,
  Tooltip,
  Popover,
  Text,
  IconButton,
  Pane,
  CrossIcon,
} from "evergreen-ui";
import styled from "styled-components";

import CourtActions from "../../components/CourtActions";
import UnblockActions from "../../components/UnblockActions";
import ActivityType1Details from "../ActivityTypeOne/activityType1Details";
import { getActivityTypeOneDetails } from "../../actions";
import {
  toDateFormat,
  slotTimeConverter,
  generateCurrencySymbol,
  toDateFormat1,
} from "../../utils";

// -----------------------------------------------------------------------
// Styled Components
// -----------------------------------------------------------------------
const DrawerDiv = styled.div`
  position: relative;
  width: 400px;
  height: 100%;
  background-color: #f5f6f7;

  .close-btn-cart-top-label-container {
    width: 400px;
    height: 56px;
    margin-top: 56px;
    box-shadow: inset 0 -1px 0 0 #edf0f2;
    background-color: #ffffff;
    display: flex;
    align-items: center;
  }

  .cart-top-label-container {
    height: 32px;
    display: flex;
    align-items: center;
  }

  #cart-top-label {
    width: fit-content;
    height: 24px;
    font-size: 20px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2;
    letter-spacing: -0.1px;
    color: #234361;
    text-align: center;
  }
`;

const PopoverContentDiv = styled.div`
  width: 340px;
  height: 132px;

  .slot-date-time-close-btn-container {
    display: flex;
    margin: 14px auto 10px auto;
    justify-content: space-between;
    width: 316px;
  }

  #slot-date-time {
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: -0.1px;
    color: #234361;
  }

  .court-name-price-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin: 10px auto 20px auto;
    width: 292px;
  }

  #court-name {
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: -0.1px;
    color: #425a70;
  }

  #court-price {
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: -0.1px;
    text-align: right;
    color: #425a70;
  }
`;

// -----------------------------------------------------------------------
// SlotButton Component
// -----------------------------------------------------------------------

class SlotButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lockedMsg: "Locked for booking by the concept of brother courts",
      showBookingToolTip: false,
      slotBookingCount: 1,
    };
  }

  updateSlotBookingCount = (value) => {
    this.setState({
      slotBookingCount: value,
    });
  };

  activityType1Details = async (slotData, isDrawer) => {
    let body = {
      activityId: slotData.activityId,
      slot: slotData.slotTime,
      date: toDateFormat1(this.props.date),
      courtId: slotData.courtId,
    };
    this.props.handleDrawer("close");
    if (isDrawer) {
      await this.props.dispatch(getActivityTypeOneDetails(body));
    }
    if (this.state.isShown && isDrawer) {
      this.setState({ isShown: false });
    } else {
      this.setState({ isShown: isDrawer });
    }
  };

  handleSlotBookingCarting = async (slotData, date, selectedActivityId) => {
    const { slotBookingCount } = this.state;
    const { onAddToCart } = this.props;
    onAddToCart(slotData, date, selectedActivityId, slotBookingCount);
    await this.setState({
      slotBookingCount: 1,
    });
  };

  handleSlotBookNow = async (slotData, date, selectedActivityId) => {
    const { slotBookingCount } = this.state;
    const { onBookNow } = this.props;
    onBookNow(slotData, date, selectedActivityId, slotBookingCount);
    await this.setState({
      slotBookingCount: 1,
    });
  };

  // -----------------------------------------------------------------------
  // render()
  // -----------------------------------------------------------------------
  render() {
    const {
      slotData,
      date,
      selectedActivityId,
      onAddToCart,
      onBookNow,
      handleUnblocking,
      cartData,
      handleGetBookingDetails,
      handleGetBookingDetailsOnHover,
      handleGetSlotLockedStatus,
      bookingDetails,
      updateCalendarPage,
      handleDrawer,
    } = this.props;

    const { showBookingToolTip, slotBookingCount } = this.state;
    const { status } = this.props.slotData;
    const intent = status === "Blocked" ? "danger" : "success";

    // -----------------------------------------------------------------------
    // generateSlots()
    // -----------------------------------------------------------------------
    const generateSlots = () => {
      for (let i = 0; i < cartData.cart.slots.length; i++) {
        if (
          cartData.cart.slots[i].activityId === slotData.activityId &&
          cartData.cart.slots[i].courtId === slotData.courtId &&
          cartData.cart.slots[i].slotDate === slotData.slotDate &&
          cartData.cart.slots[i].slotTime === slotData.slotTime
        ) {
          return (
            <Button
              className="button"
              width={112}
              height={32}
              appearance="primary"
            >
              Carted
            </Button>
          );
        }
      }
      return slotData.activityType === 1 ? (
        <ActivityType1Details
          slotData={slotData}
          date={date}
          updateCalendarPage={updateCalendarPage}
          status={status}
          selectedActivityId={selectedActivityId}
          slotBookingCount={slotBookingCount}
          activityType1Details={this.activityType1Details}
          isShown={this.state.isShown}
          onAddToCart={this.handleSlotBookingCarting}
          onBookNow={this.handleSlotBookNow}
          updateSlotBookingCount={this.updateSlotBookingCount}
        />
      ) : (
        <Button
          className="button"
          width={112}
          height={32}
          appearance="primary"
          intent={
            slotData.maxSlotCount !== slotData.available ? "warning" : intent
          }
          onClick={(e) => {
            onAddToCart(slotData, date, selectedActivityId);
          }}
        >
          {status}
        </Button>
      );
    };

    return (
      <React.Fragment>
        {status === "Locked" && (
          // <Tooltip content={this.state.lockedMsg}>
          <Button
            className="button"
            appearance="minimal"
            width={112}
            height={32}
            color="#00783e"
            // iconAfter="info-sign"
            onClick={(e) => handleGetSlotLockedStatus(slotData)}
          >
            {status}
          </Button>
          // </Tooltip>
        )}
        {status === "Booked" && (
          <div>
            {slotData.activityType === 1 ? (
              <ActivityType1Details
                slotData={slotData}
                date={date}
                updateCalendarPage={updateCalendarPage}
                intent={"danger"}
                status={status}
                selectedActivityId={selectedActivityId}
                slotBookingCount={slotBookingCount}
                activityType1Details={this.activityType1Details}
                isShown={this.state.isShown}
                onAddToCart={this.handleSlotBookingCarting}
                onBookNow={this.handleSlotBookNow}
                updateSlotBookingCount={this.updateSlotBookingCount}
              />
            ) : (
              <div
                onMouseEnter={() => {
                  handleGetBookingDetailsOnHover(slotData.bookingId, false);
                  this.setState({
                    showBookingToolTip: true,
                  });
                }}
                onMouseLeave={() =>
                  this.setState({ showBookingToolTip: false })
                }
              >
                {" "}
                <Tooltip
                  content={
                    bookingDetails &&
                    bookingDetails.bookingDetails &&
                    bookingDetails.bookingDetails[0] && (
                      <Pane display={"flex"} flexDirection={"column"}>
                        <Text color={"white"}>
                          {`Booking ID: ${bookingDetails.bookingDetails[0].bookingId}`}
                        </Text>
                        <Text color={"white"}>
                          {`Name: ${bookingDetails.bookingDetails[0].customerDetails.customerName}`}
                        </Text>
                        <Text color={"white"}>
                          {`Balance: ${bookingDetails.bookingDetails[0].summary.balance}`}
                        </Text>
                        <Text color={"white"}>
                          {`Place: ${bookingDetails.bookingDetails[0].place}`}
                        </Text>
                      </Pane>
                    )
                  }
                  isShown={
                    showBookingToolTip &&
                    bookingDetails &&
                    bookingDetails.bookingDetails &&
                    bookingDetails.bookingDetails[0].bookingId ===
                      slotData.bookingId
                  }
                >
                  <Button
                    width={112}
                    height={32}
                    // backgroundColor={
                    //   slotData.paymentPending ? "#FFB020" : "#D14343"
                    // }

                    background={slotData.paymentPending ? "#FFB020" : "#D14343"}
                    color={"white"}
                    // color={slotData.paymentPending ? "white" : "white"}
                    appearance={"gray400"}
                    // intent={slotData.paymentPending ? "warning" : "danger"}
                    onClick={(e) => handleGetBookingDetails(slotData.bookingId)}
                  >
                    <spna
                      style={{
                        maxWidth: "60px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {this.props.slotData.customerName
                        ? this.props.slotData.customerName
                        : status}
                    </spna>
                  </Button>
                </Tooltip>
              </div>
            )}
          </div>
        )}
        {status === "Blocked" && (
          <Popover
            content={({ close }) => (
              <PopoverContentDiv>
                <div className="slot-date-time-close-btn-container">
                  <Text id="slot-date-time">
                    {toDateFormat(date) +
                      ` | ` +
                      slotTimeConverter(slotData.slotTime, slotData.endTime)}
                  </Text>
                  <IconButton
                    icon={<CrossIcon />}
                    height={24}
                    appearance="minimal"
                    onClick={close}
                  />
                </div>
                <div className="court-name-price-container">
                  <Text id="court-name">{slotData.courtName}</Text>
                </div>
                <React.Fragment>
                  <UnblockActions
                    slotData={slotData}
                    date={date}
                    selectedActivityId={selectedActivityId}
                    handleUnblocking={handleUnblocking}
                  />
                </React.Fragment>
              </PopoverContentDiv>
            )}
          >
            <Button
              className="button"
              width={112}
              height={32}
              appearance="default"
            >
              {status}
            </Button>
          </Popover>
        )}
        {status === "Booking in Progress" && (
          <Button
            className="button"
            width={112}
            height={32}
            appearance="minimal"
          >
            {status}
          </Button>
        )}
        {status === "Book" &&
        cartData.cart.slots &&
        cartData.cart.slots.length > 0
          ? generateSlots()
          : status === "Book" &&
            (slotData.activityType === 1 ? (
              <ActivityType1Details
                slotData={slotData}
                updateCalendarPage={updateCalendarPage}
                date={date}
                status={status}
                selectedActivityId={selectedActivityId}
                slotBookingCount={slotBookingCount}
                activityType1Details={this.activityType1Details}
                isShown={this.state.isShown}
                onAddToCart={this.handleSlotBookingCarting}
                onBookNow={this.handleSlotBookNow}
                updateSlotBookingCount={this.updateSlotBookingCount}
              />
            ) : (
              <Popover
                content={({ close }) => (
                  <PopoverContentDiv>
                    <div className="slot-date-time-close-btn-container">
                      <Text id="slot-date-time">
                        {toDateFormat(date) +
                          ` | ` +
                          slotTimeConverter(
                            slotData.slotTime,
                            slotData.endTime
                          )}
                      </Text>
                      <IconButton
                        icon={<CrossIcon />}
                        height={24}
                        appearance="minimal"
                        onClick={close}
                      />
                    </div>
                    <div className="court-name-price-container">
                      <Text id="court-name">{slotData.courtName}</Text>
                      <Text id="court-price">{`${generateCurrencySymbol()} ${
                        slotData.price
                      }`}</Text>
                    </div>
                    <React.Fragment>
                      <CourtActions
                        slotData={slotData}
                        date={date}
                        selectedActivityId={selectedActivityId}
                        onAddToCart={onAddToCart}
                        onBookNow={onBookNow}
                      />
                    </React.Fragment>
                  </PopoverContentDiv>
                )}
              >
                <Button
                  className="button"
                  width={112}
                  height={32}
                  appearance="primary"
                  intent={
                    slotData.maxSlotCount !== slotData.available
                      ? "warning"
                      : intent
                  }
                >
                  {status}
                </Button>
              </Popover>
            ))}
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ createUserToast }) => ({ createUserToast });

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default connect(mapStateToProps)(SlotButton);
