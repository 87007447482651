// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import {Select, Text} from "evergreen-ui";


// -----------------------------------------------------------------------
// ClubHandlerSelect Component
// -----------------------------------------------------------------------
class ClubHandlerSelect extends React.Component {
    // -----------------------------------------------------------------------
    // render()
    // -----------------------------------------------------------------------
    render() {
        const {
            handleClubHandlerChange,
            selectedClubHandlerValue,
            handlers,
            width
        } = this.props;
        return (
            <React.Fragment>
                {handlers && (
                    <div className="CHS-A1-wrapper">
                        <div className="CHS-A1-label-container CHS-A1-margin-bottom-8">
                            <Text id="CHS-A1-type-one-label">Handler</Text>
                        </div>
                        <div className="CHS-A1-select-input-field-container">
                            <Select
                                className="CHS-A1-select-input-field"
                                width={width !== undefined ? width : 280}
                                height={32}
                                onChange={e => handleClubHandlerChange(e)}
                                value={selectedClubHandlerValue}
                            >
                                <option value=''>All</option>
                                {handlers.map((handler, index) => (
                                    <option key={index} value={handler.username}>
                                        {handler.username}
                                    </option>
                                ))}
                            </Select>
                        </div>
                    </div>
                )}
            </React.Fragment>
        );
    }
}

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default ClubHandlerSelect;
