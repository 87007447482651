import React from "react";
import {
  Table,
  Avatar,
  Badge,
  IconButton,
  Popover,
  Menu,
  Text,
  RefreshIcon,
  DeleteIcon,
  MoreIcon,
  Dialog,
  Pane,
  Strong,
} from "evergreen-ui"; // Import your UI library
import moment from "moment";
import { toDateFormat32 } from "../../utils";
import { toggleLoaderOn } from "../../actions/loaderUtils";
import { Position } from "evergreen-ui";

const MemberRow = ({
  memberDetails,
  showMenuPopover,
  changeActiveMemberDetails,
  handleRenewPackage,
  handleDeleteMemberDialog,
}) => {
  const [isShown, setIsShown] = React.useState(false);

  return (
    <Table.Row height="auto" isSelectable className="AMLP-A1-table-row">
      <Table.Cell className="AMLP-A1-table-cell AMLP-A1-flex-basis-252">
        <div className="AMLP-A1-member-avatar-and-name-container">
          <Avatar
            className="AMLP-A1-avatar"
            name={memberDetails.memberName}
            size={24}
            marginRight={12}
          />
          <Text id="AMLP-A1-label-type-two">{memberDetails.memberName}</Text>
        </div>
      </Table.Cell>
      <Table.Cell className="AMLP-A1-table-cell AMLP-A1-flex-basis-241">
        <div className="AMLP-A1-table-cell-data-container">
          <Text
            textDecoration={"underline"}
            cursor={"pointer"}
            onClick={() => setIsShown(true)}
            id="AMLP-A1-label-type-two"
          >
            {memberDetails.packageName}
          </Text>
        </div>
      </Table.Cell>
      <Table.Cell className="AMLP-A1-table-cell AMLP-A1-flex-basis-241">
        <div className="AMLP-A1-table-cell-data-container">
          <Text id="AMLP-A1-label-type-two">{memberDetails.memberMobile}</Text>
        </div>
      </Table.Cell>
      <Table.Cell className="AMLP-A1-table-cell AMLP-A1-flex-basis-182">
        <div className="AMLP-A1-table-cell-data-container">
          {moment(memberDetails.renewalDate).isBefore(new Date()) ? (
            <Badge color="red">Expired</Badge>
          ) : (
            <Text id="AMLP-A1-label-type-three">
              {`Renew on ${toDateFormat32(memberDetails.renewalDate)}`}
            </Text>
          )}
        </div>
      </Table.Cell>
      <Table.Cell className="AMLP-A1-table-cell AMLP-A1-flex-basis-48">
        <div className="AMLP-A1-table-cell-data-container">
          {showMenuPopover && (
            <Popover
              onOpen={() => changeActiveMemberDetails(memberDetails)}
              content={
                <Menu>
                  <Menu.Group>
                    <Menu.Item
                      icon={<RefreshIcon />}
                      onSelect={() => handleRenewPackage()}
                    >
                      Renew Package
                    </Menu.Item>
                  </Menu.Group>
                  <Menu.Divider />
                  {!moment(memberDetails.renewalDate).isBefore(new Date()) && (
                    <Menu.Group>
                      <Menu.Item
                        icon={<DeleteIcon />}
                        intent="danger"
                        onSelect={() => {
                          handleDeleteMemberDialog(
                            "open",
                            memberDetails.membershipId
                          );
                        }}
                      >
                        Cancel Membership
                      </Menu.Item>
                    </Menu.Group>
                  )}
                </Menu>
              }
              position={Position.BOTTOM_RIGHT}
            >
              <IconButton
                className="AMLP-A1-icon-btn"
                appearance="minimal"
                icon={<MoreIcon />}
                width={24}
                height={24}
              />
            </Popover>
          )}
        </div>
      </Table.Cell>
      <Dialog
        isShown={isShown}
        title="Package Details"
        hasFooter={false}
        hasClose={true}
        onCloseComplete={() => setIsShown(false)}
      >
        <Pane
          display="grid"
          gridTemplateColumns="1fr 1fr"
          gap={16}
          padding={16}
        >
          <Pane>
            <Text fontSize={14} fontWeight="semibold" color="#5C85FF">
              Member Information
            </Text>
            <Pane display="flex" flexDirection="column">
              <Text>Member Name:</Text>
              <Strong>{memberDetails.memberName}</Strong>
            </Pane>

            <Pane display="flex" flexDirection="column" marginTop={8}>
              <Text>Package Information:</Text>
              <Strong>{memberDetails.packageName}</Strong>
            </Pane>
            <Pane display="flex" flexDirection="column" marginTop={8}>
              <Text>Type:</Text>
              <Strong>{memberDetails.type}</Strong>
            </Pane>
            <Pane display="flex" marginY={10} flexDirection="column">
              <Text>Member Mobile:</Text>
              <Strong>{memberDetails.memberMobile}</Strong>
            </Pane>
            {/* Add more member-related fields here */}
          </Pane>
          <Pane>
            <Text fontSize={14} fontWeight="semibold" color="#5C85FF">
              Membership Dates
            </Text>
            <Pane display="flex" flexDirection="column">
              <Text>Membership Start Date:</Text>
              <Strong>{memberDetails.membershipStartDate}</Strong>
            </Pane>
            <Pane display="flex" flexDirection="column" marginTop={8}>
              <Text>Renewal Date:</Text>
              <Strong>{memberDetails.renewalDate}</Strong>
            </Pane>
            <Pane display="flex" flexDirection="column" marginTop={8}>
              <Text>Start Date:</Text>
              <Strong>{memberDetails.startDate}</Strong>
            </Pane>
            <Pane display="flex" flexDirection="column" marginTop={8}>
              <Text>End Date:</Text>
              <Strong>{memberDetails.endDate}</Strong>
            </Pane>
            {/* Add more date-related fields here */}
          </Pane>
          <Pane>
            <Text fontSize={14} fontWeight="semibold" color="#5C85FF">
              Payment Details
            </Text>
            <Pane display="flex" flexDirection="column">
              <Text>Advance:</Text>
              <Strong>{memberDetails.advance}</Strong>
            </Pane>
            <Pane display="flex" flexDirection="column" marginTop={8}>
              <Text>Balance:</Text>
              <Strong>{memberDetails.balance}</Strong>
            </Pane>
            <Pane display="flex" flexDirection="column" marginTop={8}>
              <Text>Gross Amount:</Text>
              <Strong>{memberDetails.grossAmount}</Strong>
            </Pane>
            <Pane display="flex" flexDirection="column" marginTop={8}>
              <Text>Package Price:</Text>
              <Strong>{memberDetails.packagePrice}</Strong>
            </Pane>
            {/* Add more financial-related fields here */}
          </Pane>
          <Pane>
            <Text fontSize={14} fontWeight="semibold" color="#5C85FF">
              Description
            </Text>
            <Pane display="flex" flexDirection="column" marginTop={8}>
              <Text>{memberDetails.packageDescription}</Text>
            </Pane>
          </Pane>
        </Pane>
      </Dialog>
    </Table.Row>
  );
};

export default MemberRow;
