import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Fuse from "fuse.js";
import {
  Table,
  Text,
  Avatar,
  Badge,
  IconButton,
  Menu,
  Popover,
  Position,
  Pane,
  Spinner,
} from "evergreen-ui";
import moment from "moment";
import NoMatchingResultsMessage from "../../components/NoMatchingResultsMessage";
import { toDateFormat32 } from "../../utils";
import { getMembershipExpiryTableData } from "../../actions";

export default function UserExpiryTable({ dispatch }) {
  const [allMembersList, setAllMembersList] = useState([]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setLoader(true);
    let reqParams = { expiry: true };
    getMembershipExpiryTableData(reqParams).then((res) => {
      if (res.requestStatus === 1) {
        setLoader(false);
        setAllMembersList(res?.packages);
      } else {
        setLoader(false);
        setAllMembersList([]);
      }
    });
  }, []);

  return loader ? (
    <Spinner marginX={32} />
  ) : (
    <React.Fragment>
      {allMembersList?.length > 0 ? (
        <Pane marginX={34} position={"relative"} paddingY={20}>
          <div className="AMLP-A1-header-container">
            <Text id="BCB-A1-label-type-one">Upcoming Membership Expiry</Text>
          </div>

          <Pane marginY={20}>
            <Table marginY={10} width={860}>
              <Table.Head>
                <Table.TextHeaderCell
                  flexBasis={500}
                  flexShrink={0}
                  flexGrow={0}
                >
                  Member
                </Table.TextHeaderCell>
                <Table.TextHeaderCell
                  flexBasis={400}
                  flexShrink={0}
                  flexGrow={0}
                >
                  Package
                </Table.TextHeaderCell>
                <Table.TextHeaderCell
                  flexBasis={400}
                  flexShrink={0}
                  flexGrow={0}
                >
                  status
                </Table.TextHeaderCell>
              </Table.Head>
              <Table.Body maxHeight={350} overflowY={"scroll"}>
                {allMembersList
                  ? allMembersList.map((memberDetails, index) => (
                      <Table.Row
                        height="auto"
                        isSelectable
                        className="AMLP-A1-table-row"
                        key={index}
                      >
                        <Table.Cell
                          flexBasis={320}
                          className="AMLP-A1-table-cell "
                        >
                          <div className="AMLP-A1-member-avatar-and-name-container">
                            <Avatar
                              className="AMLP-A1-avatar"
                              name={memberDetails.memberName}
                              size={24}
                              marginRight={12}
                            />
                            <Text id="AMLP-A1-label-type-two">
                              {memberDetails.memberName}
                            </Text>
                          </div>
                        </Table.Cell>
                        <Table.Cell className="AMLP-A1-table-cell AMLP-A1-flex-basis-241">
                          <div className="AMLP-A1-table-cell-data-container">
                            <Text id="AMLP-A1-label-type-two">
                              {memberDetails.packageName}
                            </Text>
                          </div>
                        </Table.Cell>
                        {
                          <Table.Cell className="AMLP-A1-table-cell AMLP-A1-flex-basis-182">
                            {
                              <div className="AMLP-A1-table-cell-data-container">
                                {moment(memberDetails.renewalDate).isBefore(
                                  new Date()
                                ) ? (
                                  <Badge color="red">Expired</Badge>
                                ) : (
                                  <Text id="AMLP-A1-label-type-three">
                                    {`Renew on ${toDateFormat32(
                                      memberDetails.renewalDate
                                    )}`}
                                  </Text>
                                )}
                              </div>
                            }
                          </Table.Cell>
                        }
                      </Table.Row>
                    ))
                  : null}
              </Table.Body>
            </Table>
          </Pane>
        </Pane>
      ) : (
        <NoMatchingResultsMessage />
      )}
    </React.Fragment>
  );
}
