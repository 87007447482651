// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import SlotButton from "../../components/SlotButton";
import { Text } from "evergreen-ui";
import { slotTimeConverter, toDateFormat1 } from "../../utils";

// -----------------------------------------------------------------------
// getCourtsName()
// -----------------------------------------------------------------------
const getCourtsName = (slotsList) => {
	return slotsList.map((court) => ({
		name: court.courtName,
		courtId: court.courtId,
	}));
};

// -----------------------------------------------------------------------
// getSlotTable()
// -----------------------------------------------------------------------
const getSlotTable = (slotsList, date, selectedActivityId) => {
	return slotsList.reduce((acc, court) => {
		const {
			closeTime,
			courtBrothers,
			courtId,
			courtInfo,
			courtName,
			courtOffset,
			courtPriceClass,
			openTime,
			peoplePerCourt,
			slotDuration,
			activityType,
			slots,
		} = court;
		return slots.reduce((acc1, slot) => {
			const slotTimeDuration = slotTimeConverter(slot.slotTime, slot.endTime);
			const objSlot = (acc1 && acc1[slotTimeDuration]) || { courts: {} };
			objSlot.courts[court.courtId] = {
				...slot,
				closeTime,
				courtBrothers,
				courtId,
				courtInfo,
				courtName,
				courtOffset,
				courtPriceClass,
				openTime,
				peoplePerCourt,
				slotDuration,
				activityType,
				// maxSlotCount,
				activityId: selectedActivityId,
				slotDate: toDateFormat1(date),
			};
			acc1[slotTimeDuration] = objSlot;
			return acc1;
		}, acc);
	}, {});
};

// -----------------------------------------------------------------------
// SlotTable Component
// -----------------------------------------------------------------------
class SlotTable extends React.Component {
	// -----------------------------------------------------------------------
	// render()
	// -----------------------------------------------------------------------
	render() {
		const {
			slotsList,
			date,
			selectedActivityId,
			onAddToCart,
			onBookNow,
			handleUnblocking,
			cartData,
			handleGetBookingDetails,
			handleGetBookingDetailsOnHover,
			handleGetSlotLockedStatus,
			bookingDetails,
			updateCalendarPage,
			handleDrawer,
		} = this.props;

		const courtList = getCourtsName(slotsList);
		const slotTable = getSlotTable(slotsList, date, selectedActivityId);
		return (
			<div className="ST-B1-wrapper">
				<table className="ST-B1-table">
					<thead className="ST-B1-table-header">
						<tr>
							<th>
								<Text id="ST-B1-label-type-one">Time</Text>
							</th>
							{courtList &&
								courtList.map((court) => (
									<th key={court.courtId}>
										<Text id="ST-B1-label-type-one">{court.name}</Text>
									</th>
								))}
						</tr>
					</thead>
					<tbody className="ST-B1-table-body">
						{slotTable &&
							Object.keys(slotTable).map((time, i) => {
								const { courts } = slotTable[time];
								return (
									<tr key={i}>
										<th>
											<Text id="ST-B1-label-type-two">{time}</Text>
										</th>
										{Object.keys(courts).map((courtId) => {
											const slotData = courts[courtId];
											return (
												<td key={courtId}  >
													<SlotButton
													
														handleDrawer={handleDrawer}
														slotData={slotData}
														updateCalendarPage={updateCalendarPage}
														date={date}
														selectedActivityId={selectedActivityId}
														onAddToCart={onAddToCart}
														onBookNow={onBookNow}
														handleUnblocking={handleUnblocking}
														cartData={cartData}
														handleGetBookingDetails={handleGetBookingDetails}
														handleGetBookingDetailsOnHover={
															handleGetBookingDetailsOnHover
														}
														handleGetSlotLockedStatus={
															handleGetSlotLockedStatus
														}
														bookingDetails={bookingDetails}
													/>
												</td>
											);
										})}
									</tr>
								);
							})}
					</tbody>
				</table>
			</div>
		);
	}
}

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default SlotTable;
