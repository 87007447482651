// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import { connect } from "react-redux";
import { Button, Text, Radio, toaster } from "evergreen-ui";
import CustomizedDatePicker from "../CustomizedDatePicker";
import {
  updateMemberCreditsValidity,
  getMemberDetails,
  getIndividualMemberDetails,
  togglePremiumFeatureFlag
} from "../../actions";
import { toDateFormat1, resourcesNotLoadedToast } from "../../utils";
import moment from "moment";

// -----------------------------------------------------------------------
// CreditValidityDialogContent Component
// -----------------------------------------------------------------------
class CreditValidityDialogContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = { date: new Date(), checkedOptionValue: "date" };
  }

  // -----------------------------------------------------------------------
  // componentDidMount()
  // -----------------------------------------------------------------------
  componentDidMount() {
    this.changeStateWithMemberCreditsExpiry();
  }

  // -----------------------------------------------------------------------
  // changeStateWithMemberCreditsExpiry()
  // -----------------------------------------------------------------------
  changeStateWithMemberCreditsExpiry = async () => {
    if (this.props.creditsExpiry) {
      if (this.props.creditsExpiry === "9999-12-31") {
        await this.setState({ checkedOptionValue: "lifetime" });
      } else {
        if (this.props.date) {
          await this.setState({ date: new Date(this.props.date) });
        }
        await this.setState({ checkedOptionValue: "date" });
      }
    }
  };

  // -----------------------------------------------------------------------
  // changeRadioOption()
  // -----------------------------------------------------------------------
  changeRadioOption = (e, optionValue) => {
    e.persist();
    this.setState({ checkedOptionValue: optionValue });
  };

  // -----------------------------------------------------------------------
  // handleDateChange()
  // -----------------------------------------------------------------------
  handleDateChange = async date => {
    if (moment(date).isBefore(new Date())) {
      toaster.warning("Cannot choose to set expiry to a past date");
    } else {
      await this.setState({ date: date });
    }
  };

  // -----------------------------------------------------------------------
  // confirmUpdateMemberCreditsValidity()
  // -----------------------------------------------------------------------
  confirmUpdateMemberCreditsValidity = async (e, clubId, memberMobile) => {
    e.persist();
    let reqBody = {
      clubId: clubId,
      mobile: memberMobile,
      expiryDate:
        this.state.checkedOptionValue === "date"
          ? toDateFormat1(this.state.date)
          : "max"
    };
    await this.props.dispatch(updateMemberCreditsValidity(reqBody));
    if (this.props.updateMemberCreditsValidityToast) {
      if (
        this.props.updateMemberCreditsValidityToast.resourcesNotLoadedMessage
      ) {
        resourcesNotLoadedToast();
      } else if (this.props.updateMemberCreditsValidityToast.httpErrorMessage) {
        toaster.danger(
          this.props.updateMemberCreditsValidityToast.httpErrorMessage,
          {
            id: "HTTP-ERROR-TOAST-4"
          }
        );
      } else if (
        this.props.updateMemberCreditsValidityToast.requestStatus === 0
      ) {
        toaster.danger(this.props.updateMemberCreditsValidityToast, {
          id: "BF-TOAST-3"
        });
      } else if (
        this.props.updateMemberCreditsValidityToast.requestStatus === 1
      ) {
        toaster.success(this.props.updateMemberCreditsValidityToast.message);
        this.props.dispatch(
          getIndividualMemberDetails({
            clubId: clubId,
            page: 1,
            mobile: memberMobile
          })
        );
        this.props.dispatch(
          getMemberDetails({
            clubId: clubId,
            page: this.props.pageNumOnMembersPage
          })
        );
        if (this.props.individualMemberDetailsToast) {
          if (
            this.props.individualMemberDetailsToast.resourcesNotLoadedMessage
          ) {
            resourcesNotLoadedToast();
          } else if (this.props.individualMemberDetailsToast.httpErrorMessage) {
            toaster.danger(
              this.props.individualMemberDetailsToast.httpErrorMessage
            );
          } else if (
            this.props.individualMemberDetailsToast.requestStatus === -1
          ) {
            toaster.notify("Sorry, you don't have permission to do that");
          }
        }
        if (this.props.memberDetails) {
          if (this.props.memberDetails.resourcesNotLoadedMessage) {
            resourcesNotLoadedToast();
          } else if (this.props.memberDetails.httpErrorMessage) {
            toaster.danger(this.props.memberDetails.httpErrorMessage);
          } else if (this.props.memberDetails.requestStatus === -1) {
            toaster.notify("Sorry, you don't have permission to do that");
          }
        }
        await this.props.handleCreditValidityDialog("close");
      }
    }
  };

  // -----------------------------------------------------------------------
  // render()
  // -----------------------------------------------------------------------
  render() {
    const { date, checkedOptionValue } = this.state;
    const { handleCreditValidityDialog, clubId, memberMobile } = this.props;
    return (
      <React.Fragment>
        <div className="CVDC-A1-wrapper">
          <div className="CVDC-A1-body-content">
            <div className="CVDC-A1-row-container">
              <Text id="CVDC-A1-small-label">
                Select the validity of member credits
              </Text>
            </div>
            <div
              className="CVDC-A1-row-container CVDC-A1-radio-btn-row"
              aria-label="Radio Group Label 16"
              role="group"
            >
              <Radio
                className="CVDC-A1-radio-input"
                marginRight={162}
                size={16}
                name="group5"
                label="Date"
                checked={checkedOptionValue === "date" ? true : false}
                onChange={e => this.changeRadioOption(e, "date")}
              />
              <Radio
                className="CVDC-A1-radio-input"
                marginRight={16}
                size={16}
                name="group5"
                label="Lifetime"
                checked={checkedOptionValue === "lifetime" ? true : false}
                onChange={e => this.changeRadioOption(e, "lifetime")}
              />
            </div>
            {checkedOptionValue === "date" && (
              <div className="CVDC-A1-row-container CVDC-A1-date-picker-row">
                <CustomizedDatePicker
                  date={date}
                  handleDateChange={this.handleDateChange}
                />
              </div>
            )}
          </div>
          <div className="CVDC-A1-footer-content">
            <Button
              className="button"
              width={72}
              height={32}
              marginRight={12}
              onClick={() => handleCreditValidityDialog("close")}
            >
              Cancel
            </Button>
            <Button
              className="button"
              appearance="primary"
              intent="success"
              width={88}
              height={32}
              onClick={e =>
                this.confirmUpdateMemberCreditsValidity(e, clubId, memberMobile)
              }
            >
              Done
            </Button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({
  updateMemberCreditsValidityToast,
  individualMemberDetailsToast,
  memberDetails
}) => ({
  updateMemberCreditsValidityToast,
  individualMemberDetailsToast,
  memberDetails
});

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default connect(mapStateToProps)(CreditValidityDialogContent);
