// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import { connect } from "react-redux";
import { TextInput, Text, InlineAlert, Button, toaster } from "evergreen-ui";
import {
  updateMemberPin,
  getIndividualMemberDetails,
  getMemberDetails,
  togglePremiumFeatureFlag
} from "../../actions";
import { resourcesNotLoadedToast } from "../../utils";

// -----------------------------------------------------------------------
// UpdatePinDialogContent Component
// -----------------------------------------------------------------------
class UpdatePinDialogContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      oldPin: "",
      newPin: "",
      confirmPin: "",
      showPinMatchesMsg: false,
      showPinDoesNotMatchMsg: false,
      showOldPinDoesNotMatchMsg: false,
      isOldPinInvalid: false,
      isNewPinInvalid: false,
      isConfirmPinInvalid: false
    };
  }

  // -----------------------------------------------------------------------
  // changePinInput()
  // -----------------------------------------------------------------------
  changePinInput = async (e, changeFor) => {
    e.persist();
    if (changeFor === "old-pin") {
      await this.setState({ oldPin: e.target.value.toString().trim() });
      if (this.state.oldPin.length > 0) {
        await this.setState({
          isOldPinInvalid: false,
          showOldPinDoesNotMatchMsg: false
        });
      }
    }
    if (changeFor === "new-pin") {
      await this.setState({ newPin: e.target.value.toString().trim() });
      if (this.state.newPin.length > 0) {
        await this.setState({ isNewPinInvalid: false });
      }
    }
    if (changeFor === "confirm-pin") {
      this.setState({ confirmPin: e.target.value.toString().trim() });
    }
    if (changeFor !== "old-pin") {
      await this.checkPinMatches(e);
    }
  };

  // -----------------------------------------------------------------------
  // checkPinMatches()
  // -----------------------------------------------------------------------
  checkPinMatches = async e => {
    e.persist();
    if (this.state.newPin.length > 0) {
      await this.setState({ isNewPinInvalid: false });
      if (this.state.confirmPin.length > 0) {
        if (this.state.confirmPin === this.state.newPin) {
          this.setState({
            showPinDoesNotMatchMsg: false,
            showPinMatchesMsg: true,
            isNewPinInvalid: false,
            isConfirmPinInvalid: false
          });
        } else {
          this.setState({
            showPinDoesNotMatchMsg: true,
            showPinMatchesMsg: false,
            isNewPinInvalid: false,
            isConfirmPinInvalid: true
          });
        }
      } else {
        this.setState({
          showPinDoesNotMatchMsg: false,
          showPinMatchesMsg: false,
          isNewPinInvalid: false,
          isConfirmPinInvalid: false
        });
      }
    } else if (this.state.confirmPin.length > 0) {
      if (this.state.newPin.length <= 0) {
        this.setState({
          showPinDoesNotMatchMsg: false,
          showPinMatchesMsg: false,
          isNewPinInvalid: true,
          isConfirmPinInvalid: false
        });
      }
      if (this.state.oldPin.length <= 0) {
        this.setState({
          showPinDoesNotMatchMsg: false,
          showPinMatchesMsg: false,
          isOldPinInvalid: true,
          isConfirmPinInvalid: false
        });
      }
    } else if (this.state.confirmPin.length <= 0) {
      this.setState({
        showPinDoesNotMatchMsg: false,
        showPinMatchesMsg: false,
        isNewPinInvalid: false,
        isConfirmPinInvalid: false
      });
    }
  };

  // -----------------------------------------------------------------------
  // handleConfirmUpdatePin()
  // -----------------------------------------------------------------------
  handleConfirmUpdatePin = async e => {
    e.persist();
    if (
      this.state.oldPin !== "" &&
      this.state.newPin !== "" &&
      this.state.confirmPin !== "" &&
      !this.state.isNewPinInvalid &&
      !this.state.isConfirmPinInvalid &&
      !this.state.showPinDoesNotMatchMsg &&
      this.state.showPinMatchesMsg
    ) {
      this.setState({
        isNewPinInvalid: false,
        isConfirmPinInvalid: false
      });
      let reqBody = {
        clubId: this.props.clubId,
        mobile: this.props.mobile.toString(),
        memberPin: this.state.oldPin.toString(),
        newPin: this.state.newPin.toString()
      };
      await this.props.dispatch(updateMemberPin(reqBody));
      if (this.props.updateMemberPinToast) {
        if (this.props.updateMemberPinToast.resourcesNotLoadedMessage) {
          resourcesNotLoadedToast();
        } else if (this.props.updateMemberPinToast.httpErrorMessage) {
          toaster.danger(this.props.updateMemberPinToast.httpErrorMessage, {
            id: "HTTP-ERROR-TOAST-1"
          });
        } else if (this.props.updateMemberPinToast.requestStatus === -1) {
          toaster.notify("Sorry, you don't have permission to do that");
        } else if (this.props.updateMemberPinToast.requestStatus === 1) {
          await this.setState({
            isOldPinInvalid: false,
            showOldPinDoesNotMatchMsg: false
          });
          this.props.dispatch(
            getIndividualMemberDetails({
              clubId: this.props.clubId,
              page: 1,
              mobile: this.props.mobile
            })
          );
          this.props.dispatch(
            getMemberDetails({
              clubId: this.props.clubId,
              page: this.props.pageNumOnMembersPage
            })
          );
          if (this.props.individualMemberDetailsToast) {
            if (
              this.props.individualMemberDetailsToast.resourcesNotLoadedMessage
            ) {
              resourcesNotLoadedToast();
            } else if (
              this.props.individualMemberDetailsToast.httpErrorMessage
            ) {
              toaster.danger(
                this.props.individualMemberDetailsToast.httpErrorMessage,
                {
                  id: "HTTP-ERROR-TOAST-4"
                }
              );
            } else if (
              this.props.individualMemberDetailsToast.requestStatus === -1
            ) {
              toaster.notify("Sorry, you don't have permission to do that");
            } else if (
              this.props.individualMemberDetailsToast.requestStatus === 0
            ) {
              toaster.danger(this.props.individualMemberDetailsToast.message, {
                id: "USER-TOAST"
              });
            }
          }
          if (this.props.memberDetails) {
            if (this.props.memberDetails.resourcesNotLoadedMessage) {
              resourcesNotLoadedToast();
            } else if (this.props.memberDetails.httpErrorMessage) {
              toaster.danger(this.props.memberDetails.httpErrorMessage, {
                id: "HTTP-ERROR-TOAST-4"
              });
            } else if (this.props.memberDetails.requestStatus === -1) {
              toaster.notify("Sorry, you don't have permission to do that");
            } else if (this.props.memberDetails.requestStatus === 0) {
              toaster.danger(this.props.memberDetails.message, {
                id: "USER-TOAST"
              });
            }
          }
          await this.props.handleUpdatePinDialogVisibility("close");
          await toaster.success(this.props.updateMemberPinToast.message);
        } else if (this.props.updateMemberPinToast.requestStatus === 0) {
          await this.setState({
            isOldPinInvalid: true,
            showOldPinDoesNotMatchMsg: true
          });
        }
      }
    } else {
      if (this.state.oldPin === "") {
        this.setState({ isOldPinInvalid: true });
      }
      if (this.state.newPin === "") {
        this.setState({ isNewPinInvalid: true });
      }
      if (this.state.confirmPin === "") {
        this.setState({ isConfirmPinInvalid: true });
      }
    }
  };

  // -----------------------------------------------------------------------
  // render()
  // -----------------------------------------------------------------------
  render() {
    const {
      showOldPinDoesNotMatchMsg,
      showPinMatchesMsg,
      showPinDoesNotMatchMsg,
      isOldPinInvalid,
      isNewPinInvalid,
      isConfirmPinInvalid
    } = this.state;
    const { handleUpdatePinDialogVisibility } = this.props;
    return (
      <React.Fragment>
        <div className="UPDC-A1-wrapper">
          <div className="UPDC-A1-body-container">
            <div className="UPDC-A1-col-container">
              <Text marginBottom={8} id="UPDC-A1-label">
                Old Pin
              </Text>
              <TextInput
                width={280}
                height={32}
                placeholder="Enter Old PIN"
                type="password"
                isInvalid={isOldPinInvalid}
                onChange={e => this.changePinInput(e, "old-pin")}
              />
              {showOldPinDoesNotMatchMsg && (
                <InlineAlert marginTop={8} intent="danger">
                  <Text size={300} id="UPDC-A1-danger-inline-alert">
                    {this.props.updateMemberPinToast.message}
                  </Text>
                </InlineAlert>
              )}
            </div>
            <div className="UPDC-A1-col-container">
              <Text marginBottom={8} id="UPDC-A1-label">
                New Pin
              </Text>
              <TextInput
                width={280}
                height={32}
                placeholder="Enter New PIN"
                type="password"
                isInvalid={isNewPinInvalid}
                onChange={e => this.changePinInput(e, "new-pin")}
                // onBlur={e => this.checkPinMatches(e)}
              />
            </div>
            <div className="UPDC-A1-col-container UPDC-A1-last-col-container">
              <Text marginBottom={8} id="UPDC-A1-label">
                Confirm
              </Text>
              <TextInput
                width={280}
                height={32}
                placeholder="Re-enter PIN"
                type="password"
                isInvalid={isConfirmPinInvalid}
                onChange={e => this.changePinInput(e, "confirm-pin")}
                // onBlur={e => this.checkPinMatches(e)}
              />
              {showPinMatchesMsg && (
                <InlineAlert marginTop={8} intent="success">
                  <Text size={300} id="UPDC-A1-success-inline-alert">
                    Pin matches
                  </Text>
                </InlineAlert>
              )}
              {showPinDoesNotMatchMsg && (
                <InlineAlert marginTop={8} intent="danger">
                  <Text size={300} id="UPDC-A1-danger-inline-alert">
                    Pin does not match
                  </Text>
                </InlineAlert>
              )}
            </div>
          </div>
          <div className="UPDC-A1-footer-container">
            <Button
              className="button"
              width={72}
              height={32}
              marginRight={12}
              onClick={() => handleUpdatePinDialogVisibility("close")}
            >
              Cancel
            </Button>
            <Button
              className="button"
              width={88}
              height={32}
              appearance="primary"
              intent="success"
              onClick={e => this.handleConfirmUpdatePin(e)}
            >
              Set PIN
            </Button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ updateMemberPinToast }) => ({
  updateMemberPinToast
});

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default connect(mapStateToProps)(UpdatePinDialogContent);
