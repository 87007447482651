// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import { connect } from "react-redux";
import Header from "../../components/Header";
import { Text, Radio, SearchInput, Button, toaster } from "evergreen-ui";
import {
  getRegularBookingSearchResult,
  getBulkBookingSearchResult,
  getBlockingSearchResult,
  unblocking,
  getBookingDetails,
  getIndividualBulkBookingSearchResult,
  togglePremiumFeatureFlag,
} from "../../actions";
import { resourcesNotLoadedToast } from "../../utils";
import RegularBookingTable from "./RegularBookingTable";
import BulkBookingTable from "./BulkBookingTable";
import BlockingTable from "./BlockingTable";
// import validator from "validator";
import DrawerHolder from "../../components/DrawerHolder";
import ClubActivityListSelect from "../../components/ClubActivityListSelect";
import NoMatchingResultsMessage from "../../components/NoMatchingResultsMessage";
import CustomInlineAlert from "../../components/CustomInlineAlert";
import BlankPageGuide from "../../components/BlankPageGuide";

// -----------------------------------------------------------------------
// Search Component
// -----------------------------------------------------------------------
class Search extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchString: "",
      pageNum: 1,
      defaultPageNum: 1,
      showSearchField: true,
      showRegularBookingTable: false,
      showBlockingTable: false,
      showResultNotFoundMsg: false,
      defaultActivityName: "All",
      defaultActivityValue: "all",
      selectedActivityValue: "all",
      checkedOptionValue: "regular-booking",
      showResults: false,
      openDrawer: false,
      showOnDrawer: "",
      seeAllBookingsPatternBookingId: "",
      seeAllBookingsEnabled: false,
      isShowResultsBtnDisabled: false,
      invalidSearchInput: false,
    };
  }

  // -----------------------------------------------------------------------
  //  componentDidUpdate()
  // -----------------------------------------------------------------------
  componentDidUpdate = async () => {
    if (
      this.props.showBookingTableOnSearchPage &&
      this.state.searchString === ""
    ) {
      if (this.props.seeAllBulkBookingsFromBulkForm) {
        await this.props.setShowBookingTableOnSearchPageFalse();
        let reqParams = {
          input: "",
          page: 1,
          activityId: "",
        };
        // await this.handleGetBulkBookingSearchResultResponse(reqParams);
        await this.props.setSeeAllBulkBookingsFromBulkFormFalse();
        await this.setState({
          checkedOptionValue: "bulk-booking",
          searchString: "",
          selectedActivityValue: "all",
        });
      } else if (
        this.state.checkedOptionValue === "regular-booking" &&
        this.props.showBookingTableOnSearchPage
      ) {
        await this.props.setShowBookingTableOnSearchPageFalse();
        let reqParams = {
          input: "",
          page: 1,
        };
        // await this.handleGetRegularBookingSearchResultResponse(reqParams);
      } else if (
        (this.state.checkedOptionValue === "bulk-booking" &&
          this.props.showBookingTableOnSearchPage) ||
        this.props.seeAllBulkBookingsFromBulkForm
      ) {
        await this.props.setShowBookingTableOnSearchPageFalse();
        let reqParams = {
          input: "",
          page: 1,
          activityId: "",
        };
        // await this.handleGetBulkBookingSearchResultResponse(reqParams);
      }
    }
    console.log(this.props.showBookingTableOnSearchPage);
    if (!this.props.showBookingTableOnSearchPage) {
      if (
        this.props.searchedFromHomepage ||
        this.props.seeBookingsFromMembersDrawer ||
        this.props.seeAllBookingsFromBulkBookingDetailsDrawer ||
        this.state.seeAllBookingsEnabled
      ) {
        console.log("test inside");
        this.setState({
          searchString: this.props.searchedFromHomepage
            ? this.props.searchStringOnHomepage
            : this.props.seeBookingsFromMembersDrawer
            ? this.props.seeBookingsFromMembersDrawerMemberMobile
            : this.props.seeAllBookingsFromBulkBookingDetailsDrawer
            ? this.props.seeAllBookingsFromBulkBookingDetailsDrawerBulkBookingId
            : this.state.seeAllBookingsEnabled
            ? this.state.seeAllBookingsPatternBookingId
            : "",
          showSearchField: true,
          selectedActivityValue: "all",
          checkedOptionValue: "regular-booking",
          pageNum: 1,
        });
        this.props.searchedFromHomepage &&
          this.props.setSearchedFromHomepageFalse();
        this.props.seeBookingsFromMembersDrawer &&
          this.props.setSeeBookingsFromMembersDrawerFalse();
        this.props.seeAllBookingsFromBulkBookingDetailsDrawer &&
          this.props.setSeeAllBookingsFromBulkBookingDetailsDrawerFalse();
        this.state.seeAllBookingsEnabled &&
          this.setSeeAllBookingsEnabledFalse();
        this.setState({ showResults: true });
      }
      if (this.state.showResults) {
        await this.handleShowResults();
        await this.setState({ showResults: false });
      }
    }
  };

  // -----------------------------------------------------------------------
  //  changeDefaultActivityForClubActivitySelect()
  // -----------------------------------------------------------------------
  changeDefaultActivityForClubActivitySelect = async (type) => {
    if (type === "blocked-slots") {
      await this.setState({
        defaultActivityName: "Select a sport",
        defaultActivityValue: "select-a-sport",
        selectedActivityValue: "select-a-sport",
      });
    } else {
      await this.setState({
        defaultActivityName: "All",
        defaultActivityValue: "all",
        selectedActivityValue: "all",
      });
    }
  };

  // -----------------------------------------------------------------------
  // setSeeAllBookingsEnabledFalse()
  // -----------------------------------------------------------------------
  setSeeAllBookingsEnabledFalse = () => {
    this.setState({
      seeAllBookingsEnabled: false,
    });
  };

  // -----------------------------------------------------------------------
  // handleSeeAllBookings()
  // -----------------------------------------------------------------------
  handleSeeAllBookings = async (e, patternBookingId) => {
    console.log("all");
    e.persist();
    this.setState({
      seeAllBookingsPatternBookingId: patternBookingId,
    });
    this.setState({ seeAllBookingsEnabled: true }, () => {
      console.log(this.state.seeAllBookingsEnabled);
      this.handleDrawer("close");
    });
  };

  // -----------------------------------------------------------------------
  // handleSearchInputChange()
  // -----------------------------------------------------------------------
  handleSearchInputChange = async (e) => {
    e.persist();
    if (this.props.searchedFromHomepage) {
      this.props.setSearchedFromHomepageFalse();
    }
    if (this.props.seeBookingsFromMembersDrawer) {
      this.props.setSeeBookingsFromMembersDrawerFalse();
    }
    if (this.props.seeAllBookingsFromBulkBookingDetailsDrawer) {
      this.props.setSeeAllBookingsFromBulkBookingDetailsDrawerFalse();
    }
    if (this.state.seeAllBookingsEnabled) {
      this.setSeeAllBookingsEnabledFalse();
    }
    if (this.props.setShowBookingTableOnSearchPageFalse) {
      this.props.setShowBookingTableOnSearchPageFalse();
    }
    await this.setState({
      searchString: e.target.value.toString(),
      invalidSearchInput: false,
      pageNum: 1,
    });
    await this.resetPageNum();
    // await this.hideAllTables();
    if (e.target.value.toString().trim().length <= 0) {
      await this.setState({ selectedActivityValue: "all" });
    }
  };

  // -----------------------------------------------------------------------
  // handleRadioOptionChange()
  // -----------------------------------------------------------------------
  handleRadioOptionChange = async (e, type) => {
    e.persist();
    await this.setState({
      checkedOptionValue: type,
      pageNum: 1,
      invalidSearchInput: false,
    });
    await this.hideAllTables();
    await this.resetPageNum();
    await this.setState({ searchString: "" });
    if (type === "blocked-slots") {
      await this.changeDefaultActivityForClubActivitySelect("blocked-slots");
      await this.toggleSearchField("hide");
    } else {
      await this.changeDefaultActivityForClubActivitySelect("others");
      await this.toggleSearchField("show");
    }
    if (type === "bulk-booking") {
      let reqParams = {
        input: "",
        page: 1,
        activityId: "",
      };
      await this.handleGetBulkBookingSearchResultResponse(reqParams);
    }
    if (type === "regular-booking") {
      let reqParams = {
        input: "",
        page: 1,
      };
      await this.handleGetRegularBookingSearchResultResponse(reqParams);
    }
  };

  // -----------------------------------------------------------------------
  // toggleSearchField()
  // -----------------------------------------------------------------------
  toggleSearchField = async (toggle) => {
    if (toggle === "show") {
      await this.setState({ showSearchField: true });
    }
    if (toggle === "hide") {
      await this.setState({ showSearchField: false });
    }
  };

  // -----------------------------------------------------------------------
  // resetPageNum()
  // -----------------------------------------------------------------------
  resetPageNum = async () => {
    await this.setState({ pageNum: 1 });
  };

  // -----------------------------------------------------------------------
  // handleActivityChange()
  // -----------------------------------------------------------------------
  handleActivityChange = async (e) => {
    e.persist();
    if (this.state.showSearchField) {
      this.setState({ selectedActivityValue: e.target.value });
      await this.resetPageNum();
      await this.hideAllTables();
      await this.handleShowResults();
    } else {
      await this.setState({ selectedActivityValue: e.target.value });
      await this.resetPageNum();
      await this.hideAllTables();
      if (this.state.selectedActivityValue !== "select-a-sport") {
        let reqParams = {
          page: this.state.pageNum,
          activityId: this.state.selectedActivityValue,
        };
        await this.handleGetBlockingSearchResultResponse(reqParams);
      }
    }
  };

  // -----------------------------------------------------------------------
  // handlePageChange()
  // -----------------------------------------------------------------------
  handlePageChange = async (newPageNum) => {
    if (this.state.showSearchField) {
      await this.setState({ pageNum: newPageNum });
      await this.handleShowResults();
    } else {
      await this.setState({ pageNum: newPageNum });
      let reqParams = {
        page: this.state.pageNum,
        activityId: this.state.selectedActivityValue,
      };
      await this.handleGetBlockingSearchResultResponse(reqParams);
    }
  };

  // -----------------------------------------------------------------------
  // hideAllTables()
  // -----------------------------------------------------------------------
  hideAllTables = async () => {
    await this.setState({
      showResultNotFoundMsg: false,
      showBulkBookingTable: false,
      showBlockingTable: false,
      showRegularBookingTable: false,
    });
  };

  // -----------------------------------------------------------------------
  // showRegularBookingTable()
  // -----------------------------------------------------------------------
  showRegularBookingTable = async () => {
    await this.setState({
      showResultNotFoundMsg: false,
      showBulkBookingTable: false,
      showBlockingTable: false,
      showRegularBookingTable: true,
    });
  };

  // -----------------------------------------------------------------------
  // showBulkBookingTable()
  // -----------------------------------------------------------------------
  showBulkBookingTable = async () => {
    await this.setState({
      showResultNotFoundMsg: false,
      showBlockingTable: false,
      showRegularBookingTable: false,
      showBulkBookingTable: true,
    });
  };

  // -----------------------------------------------------------------------
  // showBlockingTable()
  // -----------------------------------------------------------------------
  showBlockingTable = async () => {
    await this.setState({
      showResultNotFoundMsg: false,
      showRegularBookingTable: false,
      showBulkBookingTable: false,
      showBlockingTable: true,
    });
  };

  // -----------------------------------------------------------------------
  // showResultNotFoundMsg()
  // -----------------------------------------------------------------------
  showResultNotFoundMsg = async () => {
    await this.setState({
      showRegularBookingTable: false,
      showBulkBookingTable: false,
      showBlockingTable: false,
      showResultNotFoundMsg: true,
    });
  };

  // ----------------------------------------------------------------------
  // handleEnterKeySearch()
  // ----------------------------------------------------------------------
  handleEnterKeySearch = async (e) => {
    e.persist();
    if (e.key === "Enter" && e.target.value.length > 0) {
      await this.handleShowResults();
    }
  };

  // -----------------------------------------------------------------------
  // updateSearchPage()
  // -----------------------------------------------------------------------
  updateSearchPage = () => {
    this.handleShowResults();
  };

  // -----------------------------------------------------------------------
  // handleGetRegularBookingSearchResultResponse()
  // -----------------------------------------------------------------------
  handleGetRegularBookingSearchResultResponse = async (reqParams) => {
    await this.props.dispatch(getRegularBookingSearchResult(reqParams));
    if (this.props.regularBookingSearchResult) {
      if (this.props.regularBookingSearchResult.resourcesNotLoadedMessage) {
        resourcesNotLoadedToast();
      } else if (
        this.props.regularBookingSearchResult.httpErrorMessage &&
        this.props.regularBookingSearchResult.statusCode === 404
      ) {
        toaster.danger(this.props.regularBookingSearchResult.httpErrorMessage, {
          id: "HTTP-ERROR-TOAST-4",
        });
        await this.setState({ isShowResultsBtnDisabled: true });
        setTimeout(() => {
          this.setState({ isShowResultsBtnDisabled: false });
        }, 3000);
      } else if (this.props.regularBookingSearchResult.httpErrorMessage) {
        toaster.danger(this.props.regularBookingSearchResult.httpErrorMessage, {
          id: "HTTP-ERROR-TOAST-1",
        });
      } else if (this.props.regularBookingSearchResult.requestStatus === -1) {
        toaster.notify("Sorry, you don't have permission to do that");
      } else if (this.props.regularBookingSearchResult.requestStatus === 1) {
        this.showRegularBookingTable();
      } else if (this.props.regularBookingSearchResult.requestStatus === 0) {
        this.showResultNotFoundMsg();
      }
    }
  };

  // -----------------------------------------------------------------------
  // handleGetBulkBookingSearchResultResponse()
  // -----------------------------------------------------------------------
  handleGetBulkBookingSearchResultResponse = async (reqParams) => {
    await this.props.dispatch(getBulkBookingSearchResult(reqParams));
    if (this.props.bulkBookingSearchResult) {
      if (this.props.bulkBookingSearchResult.resourcesNotLoadedMessage) {
        resourcesNotLoadedToast();
      } else if (
        this.props.bulkBookingSearchResult.httpErrorMessage &&
        this.props.bulkBookingSearchResult.statusCode === 404
      ) {
        toaster.danger(this.props.bulkBookingSearchResult.httpErrorMessage, {
          id: "HTTP-ERROR-TOAST-4",
        });
        await this.setState({ isShowResultsBtnDisabled: true });
        setTimeout(() => {
          this.setState({ isShowResultsBtnDisabled: false });
        }, 3000);
      } else if (this.props.bulkBookingSearchResult.httpErrorMessage) {
        toaster.danger(this.props.bulkBookingSearchResult.httpErrorMessage, {
          id: "HTTP-ERROR-TOAST-1",
        });
      } else if (this.props.bulkBookingSearchResult.requestStatus === -1) {
        toaster.notify("Sorry, you don't have permission to do that");
      } else if (this.props.bulkBookingSearchResult.requestStatus === 1) {
        this.showBulkBookingTable();
      } else if (this.props.bulkBookingSearchResult.requestStatus === 0) {
        this.showResultNotFoundMsg();
      }
    }
  };

  // -----------------------------------------------------------------------
  // handleGetBlockingSearchResultResponse()
  // -----------------------------------------------------------------------
  handleGetBlockingSearchResultResponse = async (reqParams) => {
    await this.props.dispatch(getBlockingSearchResult(reqParams));
    if (this.props.blockingSearchResult) {
      if (this.props.blockingSearchResult.resourcesNotLoadedMessage) {
        resourcesNotLoadedToast();
      } else if (
        this.props.blockingSearchResult.httpErrorMessage &&
        this.props.blockingSearchResult.statusCode === 404
      ) {
        toaster.danger(this.props.blockingSearchResult.httpErrorMessage, {
          id: "HTTP-ERROR-TOAST-4",
        });
        await this.setState({ isShowResultsBtnDisabled: true });
        setTimeout(() => {
          this.setState({ isShowResultsBtnDisabled: false });
        }, 3000);
      } else if (this.props.blockingSearchResult.httpErrorMessage) {
        toaster.danger(this.props.blockingSearchResult.httpErrorMessage, {
          id: "HTTP-ERROR-TOAST-1",
        });
      } else if (this.props.blockingSearchResult.requestStatus === -1) {
        toaster.notify("Sorry, you don't have permission to do that");
      } else if (this.props.blockingSearchResult.requestStatus === 1) {
        this.showBlockingTable();
      } else if (this.props.blockingSearchResult.requestStatus === 0) {
        this.showResultNotFoundMsg();
      }
    }
  };

  // -----------------------------------------------------------------------
  // handleShowResults()
  // -----------------------------------------------------------------------
  handleShowResults = async () => {
    // if (this.state.searchString.toString().trim() !== "") {
    if (this.state.checkedOptionValue === "regular-booking") {
      if (this.state.selectedActivityValue === "all") {
        let reqParams = {
          input: this.state.searchString,
          page: this.state.pageNum,
        };
        await this.handleGetRegularBookingSearchResultResponse(reqParams);
      }
      if (this.state.selectedActivityValue !== "all") {
        let reqParams = {
          input: this.state.searchString,
          page: this.state.pageNum,
          activityId: this.state.selectedActivityValue,
        };
        await this.handleGetRegularBookingSearchResultResponse(reqParams);
      }
    }
    // }
    //  else {
    //   await this.setState({ invalidSearchInput: true, searchString: "" });
    // }
    if (this.state.checkedOptionValue === "bulk-booking") {
      if (this.state.selectedActivityValue === "all") {
        let reqParams = {
          input: this.state.searchString,
          page: this.state.pageNum,
        };
        await this.handleGetBulkBookingSearchResultResponse(reqParams);
      }
      if (this.state.selectedActivityValue !== "all") {
        let reqParams = {
          input: this.state.searchString,
          page: this.state.pageNum,
          activityId: this.state.selectedActivityValue,
        };
        await this.handleGetBulkBookingSearchResultResponse(reqParams);
      }
    }
  };

  // -----------------------------------------------------------------------
  // handleUnblocking()
  // -----------------------------------------------------------------------
  handleUnblocking = async (e) => {
    let reqBody = {
      blockingId: e.target.value,
      slots: [],
    };
    await this.props.dispatch(unblocking(reqBody));
    if (this.props.unblockingToast) {
      if (this.props.unblockingToast.resourcesNotLoadedMessage) {
        resourcesNotLoadedToast();
      } else if (this.props.unblockingToast.httpErrorMessage) {
        toaster.danger(this.props.unblockingToast.httpErrorMessage, {
          id: "HTTP-ERROR-TOAST-1",
        });
      } else if (this.props.unblockingToast.requestStatus === -1) {
        toaster.notify("Sorry, you don't have permission to do that");
      } else if (this.props.unblockingToast.requestStatus === 1) {
        let reqParams = {
          page: this.state.defaultPageNum,
          activityId: this.state.selectedActivityValue,
        };
        await this.handleGetBlockingSearchResultResponse(reqParams);
        toaster.success(this.props.unblockingToast.message);
      } else if (this.props.unblockingToast.requestStatus === 0) {
        toaster.danger(this.props.unblockingToast.message);
      }
    }
  };

  // -----------------------------------------------------------------------
  // handleDrawer()
  // -----------------------------------------------------------------------
  handleDrawer = (type) => {
    if (type === "open") {
      this.setState({
        openDrawer: true,
      });
      this.props.changeRightPaneDivWidth("decrease");
    }
    if (type === "close") {
      this.setState({
        openDrawer: false,
        showOnDrawer: "",
      });
      this.props.changeRightPaneDivWidth("increase");
    }
  };

  // -----------------------------------------------------------------------
  // handleGetBookingDetails()
  // -----------------------------------------------------------------------
  handleGetBookingDetails = async (e, bookingId, type) => {
    e.persist();
    if (type === 0) {
      this.setState({
        showOnDrawer: "regularBookingDetailsOnDrawer",
      });
      await this.handleDrawer("open");
    } else {
      await this.props.dispatch(getBookingDetails(bookingId));
      if (this.props.bookingDetails) {
        if (this.props.bookingDetails.resourcesNotLoadedMessage) {
          resourcesNotLoadedToast();
        } else if (this.props.bookingDetails.httpErrorMessage) {
          toaster.danger(this.props.bookingDetails.httpErrorMessage, {
            id: "HTTP-ERROR-TOAST-1",
          });
        } else if (this.props.bookingDetails.requestStatus === -1) {
          toaster.notify("Sorry, you don't have permission to do that");
        } else if (this.props.bookingDetails.requestStatus === 1) {
          this.setState({
            showOnDrawer: "regularBookingDetailsOnDrawer",
          });
          await this.handleDrawer("open");
        } else if (this.props.bookingDetails.requestStatus === 0) {
          toaster.danger(this.props.bookingDetails.message);
        }
      }
    }
  };

  // -----------------------------------------------------------------------
  // getPatternBookingDetailsForDrawer()
  // -----------------------------------------------------------------------
  getPatternBookingDetailsForDrawer = async (e, bookingId) => {
    e.persist();
    let reqParams = {
      input: bookingId,
      page: 1,
    };
    await this.props.dispatch(getIndividualBulkBookingSearchResult(reqParams));
    if (this.props.individualBulkBookingSearchResult) {
      if (
        this.props.individualBulkBookingSearchResult.resourcesNotLoadedMessage
      ) {
        resourcesNotLoadedToast();
      } else if (
        this.props.individualBulkBookingSearchResult.httpErrorMessage
      ) {
        toaster.danger(
          this.props.individualBulkBookingSearchResult.httpErrorMessage,
          {
            id: "HTTP-ERROR-TOAST-1",
          }
        );
      } else if (
        this.props.individualBulkBookingSearchResult.requestStatus === -1
      ) {
        toaster.notify("Sorry, you don't have permission to do that");
      } else if (
        this.props.individualBulkBookingSearchResult.requestStatus === 1
      ) {
        this.setState({
          showOnDrawer: "bulkBookingDetailsOnSearchPage",
        });
        await this.handleDrawer("open");
      } else if (
        this.props.individualBulkBookingSearchResult.requestStatus === 0
      ) {
        toaster.danger(this.props.individualBulkBookingSearchResult.message, {
          id: "HTTP-ERROR-TOAST-1",
        });
      }
    }
  };

  // -----------------------------------------------------------------------
  // render()
  // -----------------------------------------------------------------------
  render() {
    const {
      defaultActivityName,
      defaultActivityValue,
      selectedActivityValue,
      pageNum,
      checkedOptionValue,
      showRegularBookingTable,
      showBulkBookingTable,
      showBlockingTable,
      showResultNotFoundMsg,
      searchString,
      showSearchField,
      updateBookingDetails,
      openDrawer,
      showOnDrawer,
      isShowResultsBtnDisabled,
      invalidSearchInput,
    } = this.state;

    const {
      // bookingDetails,
      regularBookingSearchResult,
      bulkBookingSearchResult,
      blockingSearchResult,
      // individualBulkBookingSearchResult
    } = this.props;
    return (
      <React.Fragment>
        <Header headerText={"Find Bookings"} />
        <div className="SP-A1-wrapper">
          <div className="SP-A1-search-actions-wrapper">
            <div className="SP-A1-row-container">
              <div className="SP-A1-col-container">
                <div className="SP-A1-label-container">
                  <Text id="SP-A1-label">Booking</Text>
                </div>
                <div
                  className="SP-A1-radio-btn-container"
                  aria-label="Radio Group Label 16"
                  role="group"
                >
                  <Radio
                    className="SP-A1-radio-btn"
                    width={144}
                    marginRight={8}
                    size={16}
                    name="group2"
                    label="Regular Booking"
                    checked={
                      checkedOptionValue === "regular-booking" ? true : false
                    }
                    onChange={(e) =>
                      this.handleRadioOptionChange(e, "regular-booking")
                    }
                  />
                  <Radio
                    className="SP-A1-radio-btn"
                    width={144}
                    marginRight={8}
                    size={16}
                    name="group2"
                    label="Bulk Booking"
                    checked={
                      checkedOptionValue === "bulk-booking" ? true : false
                    }
                    onChange={(e) =>
                      this.handleRadioOptionChange(e, "bulk-booking")
                    }
                  />
                  {/*<Radio*/}
                  {/*className="SP-A1-radio-btn"*/}
                  {/*width={144}*/}
                  {/*marginRight={8}*/}
                  {/*size={16}*/}
                  {/*name="group2"*/}
                  {/*label="Blocked Slots"*/}
                  {/*checked={*/}
                  {/*checkedOptionValue === "blocked-slots" ? true : false*/}
                  {/*}*/}
                  {/*onChange={e =>*/}
                  {/*this.handleRadioOptionChange(e, "blocked-slots")*/}
                  {/*}*/}
                  {/*/>*/}
                </div>
              </div>
            </div>
            <div className="SP-A1-row-container SP-A1-margin-top-14">
              {showSearchField && (
                <div className="SP-A1-col-container SP-A1-find-booking-container">
                  <div className="SP-A1-label-container">
                    <Text id="SP-A1-label">Find Booking</Text>
                  </div>
                  <SearchInput
                    width={328}
                    height={32}
                    placeholder={
                      checkedOptionValue === "bulk-booking"
                        ? "Pattern Booking ID or Phone Number"
                        : "Booking ID or Phone Number"
                    }
                    value={searchString}
                    onChange={(e) => this.handleSearchInputChange(e)}
                    onKeyUp={(e) => this.handleEnterKeySearch(e)}
                    disabled={isShowResultsBtnDisabled}
                  />
                </div>
              )}
              <div className="SP-A1-club-activity-list-select-container SP-A1-margin-right-24">
                <ClubActivityListSelect
                  selectedActivityValue={selectedActivityValue}
                  defaultActivityValue={defaultActivityValue}
                  defaultActivityName={defaultActivityName}
                  handleActivityChange={this.handleActivityChange}
                  disabled={
                    // !isShowResultsBtnDisabled
                    //   ? showSearchField && searchString.length <= 0
                    //     ? true
                    //     : false
                    //   : isShowResultsBtnDisabled
                    isShowResultsBtnDisabled
                  }
                />
              </div>
              {showSearchField && (
                <div className="SP-A1-col-container SP-A1-show-results-btn-container">
                  <Button
                    className="button"
                    minWidth={124}
                    height={32}
                    onClick={(e) => this.handleShowResults()}
                    disabled={isShowResultsBtnDisabled}
                    appearance="primary"
                  >
                    Show Results
                  </Button>
                </div>
              )}
            </div>
            {invalidSearchInput && (
              <div
                className="SP-A1-custom-inline-alert-container"
                ref={"invalidSearchInput"}
              >
                <CustomInlineAlert
                  intent="danger"
                  alertMsg="Booking ID or phone number is required"
                />
              </div>
            )}
          </div>
          {checkedOptionValue === "blocked-slots" &&
            !showBlockingTable &&
            !showResultNotFoundMsg && (
              <BlankPageGuide msg="Select an Activity to See all of its Blocked Slots" />
            )}
          {showRegularBookingTable &&
            regularBookingSearchResult.requestStatus === 1 && (
              <RegularBookingTable
                updateBookingDetails={updateBookingDetails}
                regularBookingSearchResult={regularBookingSearchResult}
                pageNum={pageNum}
                handlePageChange={this.handlePageChange}
                handleGetBookingDetails={this.handleGetBookingDetails}
                updateSearchPage={this.updateSearchPage}
              />
            )}
          {showBulkBookingTable &&
            bulkBookingSearchResult.requestStatus === 1 && (
              <BulkBookingTable
                bulkBookingSearchResult={bulkBookingSearchResult}
                pageNum={pageNum}
                handlePageChange={this.handlePageChange}
                getPatternBookingDetailsForDrawer={
                  this.getPatternBookingDetailsForDrawer
                }
              />
            )}
          {showBlockingTable && blockingSearchResult.requestStatus === 1 && (
            <BlockingTable
              selectedActivityValue={selectedActivityValue}
              blockingSearchResult={blockingSearchResult}
              pageNum={pageNum}
              handlePageChange={this.handlePageChange}
              handleUnblocking={this.handleUnblocking}
            />
          )}
          {showResultNotFoundMsg && <NoMatchingResultsMessage />}
          <DrawerHolder
            updateBookingDetails={updateBookingDetails}
            openDrawer={openDrawer}
            showOnDrawer={showOnDrawer}
            handleDrawer={this.handleDrawer}
            // bookingDetails={bookingDetails}
            // individualBulkBookingSearchResult={
            //   individualBulkBookingSearchResult
            // }
            handleSeeAllBookings={this.handleSeeAllBookings}
            updateSearchPage={this.updateSearchPage}
            handleGetBookingDetails={this.handleGetBookingDetails}
            getPatternBookingDetailsForDrawer={
              this.getPatternBookingDetailsForDrawer
            }
          />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({
  regularBookingSearchResult,
  bulkBookingSearchResult,
  blockingSearchResult,
  unblockingToast,
  bookingDetails,
  individualBulkBookingSearchResult,
}) => ({
  regularBookingSearchResult,
  bulkBookingSearchResult,
  blockingSearchResult,
  unblockingToast,
  bookingDetails,
  individualBulkBookingSearchResult,
});

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default connect(mapStateToProps)(Search);
