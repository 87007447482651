// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import {
    TextInput,
    Text,
    InlineAlert,
    TextInputField,
    Textarea,
    Radio, Pane, Heading
} from "evergreen-ui";
import CountrySelectMenu from "../CountrySelectMenu";
import TransactionModeSelect from "../../components/TransactionModeSelect";
import {checkIfDateIsAfter, checkIfDateIsBefore, getDateObject} from "../../utils";
import {DatePicker} from "antd";
import "antd/dist/antd.css";

// -----------------------------------------------------------------------
// AddNewMemberDialogContent Component
// -----------------------------------------------------------------------
class AddNewMemberDialogContent extends React.Component {

    fromDateChange = (date) => {
        let {
            toDate,
            dateChange
        } = this.props;
        const dateObject = getDateObject(date, "DD - MMM - YY");
        const toDateObject = getDateObject(toDate);
        if (checkIfDateIsAfter(dateObject, toDateObject)) {
            toDate = dateObject;
        }
        dateChange({
            fromDate: getDateObject(date, "DD - MMM - YY").format("YYYY-MM-DD"),
            toDate: getDateObject(toDate).format("YYYY-MM-DD"),
        });
    };

    toDateChange = (date) => {
        let {
            fromDate,
            dateChange
        } = this.props;
        const dateObject = getDateObject(date, "DD - MMM - YY");
        const fromDateObject = getDateObject(fromDate);
        if (checkIfDateIsBefore(dateObject, fromDateObject)) {
            fromDate = dateObject;
        }
        dateChange({
            toDate: dateObject.format("YYYY-MM-DD"),
            fromDate: getDateObject(fromDate).format("YYYY-MM-DD")
        });
    };

    // -----------------------------------------------------------------------
    // render()
    // -----------------------------------------------------------------------
    render() {
        const mobileFieldLabel = (
            <Text id="ANMDC-A1-large-label">
                contact number<font color="#EC4C47">&nbsp;*</font>
            </Text>
        );
        const nameFieldLabel = (
            <Text id="ANMDC-A1-large-label">
                name<font color="#EC4C47">&nbsp;*</font>
            </Text>
        );
        const {
            memberMobile,
            isMobileInvalid,
            showInvalidMobileDiv,
            isNameInvalid,
            isNameInvalidMsg,
            isMobileRequiredMsg,
            memberName,
            isEmailInvalid,
            isEmailInvalidMsg,
            checkedGenderValue,
            onChangeOfMobileInput,
            onBlurOfMobileInput,
            onChangeOfNameInput,
            onChangeOfEmailInput,
            validateEmail,
            changeTransactionMode,
            registrationFee,
            onChangeOfRegistrationFeeInput,
            shakeOnInvalid,
            onCountryChange,
            onChangeOfAddressInput,
            onChangeOfGenderRadioInput,
            disableNoPay,
            selectedTransactionMode,
            isRegistrationFeeInvalid,
            fromDate,
            toDate
        } = this.props;
        return (
            <React.Fragment>
                <div className="ANMDC-A1-wrapper">
                    <div className="ANMDC-A1-col-elements-container">
                        <div className="ANMDC-A1-label-container">{mobileFieldLabel}</div>
                        <div className="ANMDC-A1-row-elements-container">
                            <div className="ANMDC-A1-country-select-menu-container">
                                <CountrySelectMenu onCountryChange={onCountryChange}/>
                            </div>
                            <div className="ANMDC-A1-mobile-num-input-container ANMDC-A1-input-container">
                                <TextInput
                                    className={
                                        shakeOnInvalid &&
                                        (memberMobile.length <= 0 || isMobileInvalid)
                                            ? "invalid-input-shake"
                                            : ""
                                    }
                                    width={192}
                                    height={32}
                                    placeholder="Mobile"
                                    onBlur={e => onBlurOfMobileInput(e)}
                                    onChange={e => onChangeOfMobileInput(e)}
                                    isInvalid={isMobileInvalid}
                                />
                            </div>
                        </div>
                        {isMobileRequiredMsg && (
                            <div className="ANMDC-A1-invalid-mobile-num-msg-container">
                                <InlineAlert intent="danger">
                                    <Text size={300}>
                                        <font color="#bf0e08">Mobile is required</font>
                                    </Text>
                                </InlineAlert>
                            </div>
                        )}
                        {showInvalidMobileDiv && (
                            <div className="ANMDC-A1-invalid-mobile-num-msg-container">
                                <InlineAlert intent="danger">
                                    <Text size={300}>
                                        <font color="#bf0e08">Invalid mobile number</font>
                                    </Text>
                                </InlineAlert>
                            </div>
                        )}
                    </div>
                    <div className="ANMDC-A1-col-elements-container">
                        <div className="ANMDC-A1-row-elements-container">
                            <div className="ANMDC-A1-name-input-container ANMDC-A1-input-container">
                                <TextInputField
                                    className={
                                        shakeOnInvalid && (memberName.length <= 0 || isNameInvalid)
                                            ? "invalid-input-shake"
                                            : ""
                                    }
                                    label={nameFieldLabel}
                                    width={336}
                                    height={32}
                                    placeholder="Full Name"
                                    onChange={e => onChangeOfNameInput(e)}
                                    isInvalid={isNameInvalid}
                                    validationMessage={
                                        isNameInvalidMsg ? "Name is required" : false
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div className="ANMDC-A1-col-elements-container">
                        <div className="ANMDC-A1-row-elements-container">
                            <div className="ANMDC-A1-email-input-container ANMDC-A1-input-container">
                                <TextInputField
                                    className={
                                        shakeOnInvalid && isEmailInvalid
                                            ? "invalid-input-shake"
                                            : ""
                                    }
                                    label="Email"
                                    width={336}
                                    height={32}
                                    placeholder="Email Address"
                                    onChange={e => onChangeOfEmailInput(e)}
                                    onBlur={e => validateEmail(e)}
                                    isInvalid={isEmailInvalid}
                                    validationMessage={
                                        isEmailInvalidMsg ? "Invalid email" : false
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div className="ANMDC-A1-col-elements-container">
                        <div className="ANMDC-A1-label-container">
                            <Text id="ANMDC-A1-large-label">Address</Text>
                        </div>
                        <div className="ANMDC-A1-row-elements-container">
                            <div className="ANMDC-A1-address-input-container ANMDC-A1-input-container">
                <Textarea
                    label="Address"
                    width={336}
                    height={80}
                    placeholder="Address (Optional)"
                    maxLength="150"
                    onChange={e => onChangeOfAddressInput(e)}
                />
                            </div>
                        </div>
                    </div>

                    <Pane
                        display={"flex"}
                        justifyContent={"space-between"}
                        marginBottom={28}
                    >
                        <Pane
                            marginRight={10}
                            display={"flex"}
                            flexDirection={"column"}
                            width={"50%"}
                        >
                            <Heading
                                marginBottom={4}
                                size={400}
                                color={"#425A70"}
                            >
                                From
                            </Heading>
                            <DatePicker
                                className={"date-picker"}
                                // defaultValue={currentDate}
                                value={getDateObject(fromDate)}
                                format={"DD - MMM - YY"}
                                onChange={(e) => {
                                    document.querySelector(".date-picker input").blur();
                                    this.fromDateChange(e.format("DD - MMM - YY"));
                                }}
                            />
                        </Pane>
                        <Pane
                            marginLeft={10}
                            display={"flex"}
                            flexDirection={"column"}
                            width={"50%"}
                        >
                            <Heading
                                marginBottom={4}
                                size={400}
                                color={"#425A70"}
                            >
                                To
                            </Heading>
                            <DatePicker
                                className={"date-picker"}
                                // defaultValue={currentDate}
                                value={getDateObject(toDate)}
                                format={"DD - MMM - YY"}
                                onChange={(e) => {
                                    document.querySelector(".date-picker input").blur();
                                    this.toDateChange(e.format("DD - MMM - YY"));
                                }}
                            />
                        </Pane>
                    </Pane>

                    <div className="ANMDC-A1-col-elements-container">
                        <div className="ANMDC-A1-label-container">
                            <Text id="ANMDC-A1-large-label">Gender</Text>
                        </div>
                        <div
                            className="ANMDC-A1-row-elements-container"
                            aria-label="Radio Group Label 16"
                            role="group"
                        >
                            <Radio
                                className="ANMDC-A1-radio-input"
                                width={60}
                                marginRight={16}
                                size={16}
                                name="group4"
                                label="Male"
                                checked={checkedGenderValue === "M" ? true : false}
                                onChange={e => onChangeOfGenderRadioInput(e, "M")}
                            />
                            <Radio
                                className="ANMDC-A1-radio-input"
                                width={66}
                                marginRight={16}
                                size={16}
                                name="group4"
                                label="Female"
                                checked={checkedGenderValue === "F" ? true : false}
                                onChange={e => onChangeOfGenderRadioInput(e, "F")}
                            />
                            <Radio
                                className="ANMDC-A1-radio-input"
                                width={66}
                                marginRight={16}
                                size={16}
                                name="group4"
                                label="Other"
                                checked={checkedGenderValue === "O" ? true : false}
                                onChange={e => onChangeOfGenderRadioInput(e, "O")}
                            />
                        </div>
                    </div>
                    <div className="ANMDC-A1-col-elements-container">
                        <div className="ANMDC-A1-label-container">
                            <Text id="ANMDC-A1-large-label">Fee</Text>
                        </div>
                        <div className="ANMDC-A1-row-elements-container">
                            <div className="ANMDC-A1-registration-fee-input-container ANMDC-A1-input-container">
                                <TextInput
                                    width={208}
                                    height={32}
                                    value={registrationFee}
                                    placeholder="Registration Fee (Optional)"
                                    onChange={e => onChangeOfRegistrationFeeInput(e)}
                                    isInvalid={isRegistrationFeeInvalid}
                                />
                            </div>
                            <div className="ANMDC-A1-transaction-mode-select-container">
                                <TransactionModeSelect
                                    changeTransactionMode={changeTransactionMode}
                                    selectedTransactionMode={selectedTransactionMode}
                                    disableNoPay={disableNoPay}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default AddNewMemberDialogContent;
