// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import {
  Text,
  Table,
  Pane,
  Tablist,
  Tab,
  Paragraph,
  Spinner,
} from "evergreen-ui";
import { connect } from "react-redux";
import { toDateFormat16, toDateFormat32 } from "../../utils";

// -----------------------------------------------------------------------
// CreditsTable Component
// -----------------------------------------------------------------------
class CreditsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedIndex: 0,
      loader: false,
      tabs: [],
    };
  }

  handleTabSelect = (index) => {
    this.setState({ loader: true }, () => {
      // The loader state is now true
      this.setState({ selectedIndex: index, loader: false });
      // The loader state is now false
    });
  };
  // -----------------------------------------------------------------------
  // render()
  // -----------------------------------------------------------------------
  render() {
    const { reportsData } = this.props;
    const { selectedIndex } = this.state;
    return (
      <React.Fragment>
        <Tablist marginY={20} marginRight={24}>
          {reportsData?.data.map((club, index) => (
            <Tab
              aria-controls={`panel-${club}`}
              isSelected={index === selectedIndex}
              key={club?.clubId}
              onSelect={() => this.handleTabSelect(index)}
            >
              {club.clubName}
            </Tab>
          ))}
        </Tablist>
        <Pane background="tint1" flex="1">
          {reportsData?.data.map((club, index) => (
            <Pane
              aria-labelledby={club}
              aria-hidden={index !== selectedIndex}
              display={index === selectedIndex ? "block" : "none"}
              key={club?.clubId}
              role="tabpanel"
            >
              <div className="RT-A1-reports-table-container">
                <Table>
                  <Table.Head>
                    <Table.TextHeaderCell className="RT-A1-table-text-header-cell RT-A1-flex-basis-257">
                      Timestamp
                    </Table.TextHeaderCell>
                    <Table.TextHeaderCell className="RT-A1-table-text-header-cell RT-A1-flex-basis-259">
                      Balance Credits
                    </Table.TextHeaderCell>
                    <Table.TextHeaderCell className="RT-A1-table-text-header-cell RT-A1-flex-basis-286">
                      Last Credit Transaction
                    </Table.TextHeaderCell>
                    <Table.TextHeaderCell className="RT-A1-table-text-header-cell RT-A1-flex-basis-190">
                      Credit Expiry
                    </Table.TextHeaderCell>
                  </Table.Head>
                  <Table.Body className="RT-A1-table-body" overflow="none">
                    {club &&
                      club.data &&
                      club.data.map((data, index) => (
                        <Table.Row
                          isSelectable
                          className="RT-A1-table-row"
                          key={index}
                        >
                          <Table.Cell className="RT-A1-table-cell RT-A1-flex-basis-257">
                            <div className="RT-A1-table-cell-data-container">
                              <Text
                                id="RT-A1-label-type-three"
                                marginBottom={8}
                              >
                                {data.member}
                              </Text>
                              <Text id="RT-A1-label-type-two" marginBottom={8}>
                                {data.mobile}
                              </Text>
                              <Text id="RT-A1-label-type-two" marginBottom={8}>
                                {data.email}
                              </Text>
                            </div>
                          </Table.Cell>
                          <Table.Cell className="RT-A1-table-cell RT-A1-flex-basis-259">
                            <div className="RT-A1-table-cell-data-container">
                              <Text
                                id="RT-A1-label-type-three"
                                marginBottom={8}
                              >
                                {data.balance}
                              </Text>
                            </div>
                          </Table.Cell>
                          <Table.Cell className="RT-A1-table-cell RT-A1-flex-basis-286">
                            <div className="RT-A1-table-cell-data-container">
                              <Text
                                id="RT-A1-label-type-three"
                                marginBottom={8}
                              >
                                {toDateFormat16(
                                  data.lastTransaction.split(" ")[0]
                                )}
                              </Text>
                              <Text id="RT-A1-label-type-two" marginBottom={8}>
                                {data.lastTransaction.split(" ")[1]}
                              </Text>
                            </div>
                          </Table.Cell>
                          <Table.Cell className="RT-A1-table-cell RT-A1-flex-basis-190">
                            <Text id="RT-A1-label-type-two" marginBottom={8}>
                              <div className="RT-A1-table-cell-data-container">
                                {data.creditsExpiry === "9999-12-31"
                                  ? "Lifetime"
                                  : toDateFormat32(data.creditsExpiry)}
                              </div>
                            </Text>
                          </Table.Cell>
                        </Table.Row>
                      ))}
                  </Table.Body>
                </Table>
              </div>
            </Pane>
          ))}
        </Pane>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ reportsData }) => ({ reportsData });

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default connect(mapStateToProps)(CreditsTable);
