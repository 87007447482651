// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import { connect } from "react-redux";
import { Text, Button, Strong, Pane, Table, EditIcon } from "evergreen-ui";
import { generateCurrencySymbol } from "../../utils";

// -----------------------------------------------------------------------
// LoginPage Component
// -----------------------------------------------------------------------
class tableBody extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let { configExtrasList, editAddons } = this.props;

    return (
      <Pane padding={18} width="97%" marginBottom={30}>
        <Table border="default">
          <Table.Head>
            <Table.TextHeaderCell>
              <Strong size={300} color={"#888A8D"}>
                Name
              </Strong>
            </Table.TextHeaderCell>
            <Table.TextHeaderCell>
              <Strong size={300} color={"#888A8D"}>
                Qty Available
              </Strong>
            </Table.TextHeaderCell>
            <Table.TextHeaderCell>
              <Strong size={300} color={"#888A8D"}>
                Price Per Item ({generateCurrencySymbol()})
              </Strong>
            </Table.TextHeaderCell>
            <Table.TextHeaderCell>
              <Strong size={300} color={"#888A8D"}>
                Per / All Activity
              </Strong>
            </Table.TextHeaderCell>
            <Table.TextHeaderCell>
              <Strong size={300} color={"#888A8D"}>
                Rental /Non Rental
              </Strong>
            </Table.TextHeaderCell>
            <Table.TextHeaderCell>
              <Strong size={300} color={"#888A8D"}>
                Actice / Inactive
              </Strong>
            </Table.TextHeaderCell>

            <Table.TextHeaderCell>
              <Strong></Strong>
            </Table.TextHeaderCell>
          </Table.Head>

          <Table.Body>
            {configExtrasList &&
              configExtrasList.data &&
              configExtrasList.data.data.map((extras, index) => (
                <Table.Row height={55}>
                  <Table.TextCell>
                    <Text size={300}>{extras.name}</Text>
                  </Table.TextCell>
                  <Table.TextCell>
                    <Text size={300}>{extras.quantity}</Text>
                  </Table.TextCell>
                  <Table.TextCell>
                    <Text size={300}>{extras.price}</Text>
                  </Table.TextCell>
                  <Table.TextCell>
                    {extras.isPerActivity ? (
                      <Pane display={"flex"} flexDirection="column">
                        <Text size={300}>Per Activity</Text>
                        <Text size={300} color={"#888A8D"}>
                          {extras.activityName}
                        </Text>
                      </Pane>
                    ) : (
                      <Text size={300}>All Activity</Text>
                    )}
                  </Table.TextCell>
                  <Table.TextCell>
                    {extras.isRent ? (
                      <Text size={300}>Rental</Text>
                    ) : (
                      <Text size={300}>Non Rental</Text>
                    )}
                  </Table.TextCell>
                  <Table.TextCell>
                    {extras.isActive ? (
                      <Text size={300}>Active</Text>
                    ) : (
                      <Text size={300}>Inactive</Text>
                    )}
                  </Table.TextCell>
                  <Table.TextCell>
                    <Button
                      height={30}
                      backgroundColor="white"
                      appearance="minimal"
                      iconBefore={<EditIcon />}
                      onClick={() => editAddons(extras)}
                    >
                      <Strong color="primary" size={300}>
                        Edit
                      </Strong>
                    </Button>

                    {/* {"" && "".role.toLowerCase() === "admin" ? null : (
										<Text
											marginLeft={35}
											onClick={() => this.removeUser("".userId)}
											cursor={"pointer"}
										>
											test
										</Text>
									)} */}
                  </Table.TextCell>
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
      </Pane>
    );
  }
}

const mapStateToProps = ({ authStatus }) => ({
  authStatus,
});

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default connect(mapStateToProps)(tableBody);
