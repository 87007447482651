// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import { connect } from "react-redux";
import { Button, Text, toaster } from "evergreen-ui";
import { toDateFormat1 } from "../../utils";
import CustomizedDatePicker from "../CustomizedDatePicker";
import { savePricingData } from "../../actions";

// -----------------------------------------------------------------------
// SpecialPricingDatePickerDialogContent Component
// -----------------------------------------------------------------------
class SpecialPricingDatePickerDialogContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = { fromDate: new Date(), toDate: new Date(), showStep: 1 };
  }

  // -----------------------------------------------------------------------
  // confirmChangeDate()
  // -----------------------------------------------------------------------
  confirmChangeDate = async e => {
    e.persist();
  };

  // -----------------------------------------------------------------------
  // handleFromDateChange()
  // -----------------------------------------------------------------------
  handleFromDateChange = async date => {
    await this.setState({ fromDate: date });
  };

  // -----------------------------------------------------------------------
  // handleToDateChange()
  // -----------------------------------------------------------------------
  handleToDateChange = async date => {
    await this.setState({ toDate: date });
  };

  // -----------------------------------------------------------------------
  // changeStep()
  // -----------------------------------------------------------------------
  changeStep = async e => {
    e.persist();
    try {
      if (this.props.specialPricingStatus === "add-new") {
        await this.props.dispatch(
          savePricingData(
            "special",
            this.props.selectedPriceClassValue,
            this.props.selectedPriceClassActivityId,
            [],
            this.props.openHour,
            this.props.closeHour,
            this.props.slotDuration,
            true,
            toDateFormat1(this.state.fromDate),
            toDateFormat1(this.state.toDate)
          )
        );
        await this.props.handleSpecialPricingDatePickerDialog("hide");
        await this.props.handleEditPricingPageDisplay("show");
      }
    } catch (err) {
      toaster.danger("Something went wrong", { id: "SPDCDC-TOAST" });
    }
  };

  // -----------------------------------------------------------------------
  // render()
  // -----------------------------------------------------------------------
  render() {
    const { fromDate, toDate, showStep } = this.state;
    return (
      <React.Fragment>
        <div className="SPDCDC-A1-wrapper">
          <div className="SPDCDC-A1-row-container">
            <Text id="SPDCDC-A1-label-type-one">
              {showStep === 1
                ? "Date range for special pricing"
                : "Update date range for special pricing"}
            </Text>
          </div>
          <div className="SPDCDC-A1-row-container SPDCDC-A1-margin-top-24">
            <div className="SPDCDC-A1-col-container SPDCDC-A1-margin-right-32">
              <div className="SPDCDC-A1-label-container SPDCDC-A1-margin-bottom-8">
                <Text id="SPDCDC-A1-label-type-two">From</Text>
              </div>
              <div className="SPDCDC-A1-date-picker-container">
                <CustomizedDatePicker
                  date={fromDate}
                  handleDateChange={this.handleFromDateChange}
                />
              </div>
            </div>
            <div className="SPDCDC-A1-col-container">
              <div className="SPDCDC-A1-label-container SPDCDC-A1-margin-bottom-8">
                <Text id="SPDCDC-A1-label-type-two">To</Text>
              </div>
              <div className="SPDCDC-A1-date-picker-container">
                <CustomizedDatePicker
                  date={toDate}
                  handleDateChange={this.handleToDateChange}
                />
              </div>
            </div>
          </div>
          <div className="SPDCDC-A1-row-container SPDCDC-A1-margin-top-32">
            <Button
              width={144}
              height={32}
              appearance="primary"
              className="SPDCDC-A1-button"
              onClick={
                showStep === 1
                  ? e => this.changeStep(e)
                  : e => this.confirmChangeDate(e)
              }
            >
              {showStep === 1 ? "Next" : "Change"}
            </Button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ savePricingDetailsToast }) => ({
  savePricingDetailsToast
});

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default connect(mapStateToProps)(SpecialPricingDatePickerDialogContent);
