// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import Header from "../../components/Header";
import { Text, Icon, ChevronRightIcon } from "evergreen-ui";
import TransactionActions from "./TransactionActions";
import BookingActions from "./BookingActions";
import BalanceActions from "./BalanceActions";
import CancellationActions from "./CancellationActions";
import ExtrasActions from "./ExtrasActions";
// import ExtrasActions from "./ExtrasActions";
import RechargeActions from "./RechargeActions";
// import PackagesActions from "./PackagesActions";
import CreditsActions from "./CreditsActions";

// -----------------------------------------------------------------------
// Reports Component
// -----------------------------------------------------------------------
class Reports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reportsType: [
        "master",
        "booking",
        "balance",
        "cancellation",
        "recharge",
        "credits",
        "addon",
      ],
      selectedReportId: 0,
      clubIds: [],
      selectedReportType: "master",
      transactionReportSummary: `The master report 
      enables you to view a list of your transactions based on 
      date range, handler and the type of transaction. 
      Available for download.`,
      bookingReportSummary: `The booking report enables you to 
      view a list of your bookings based on date range, handler 
      and the type of transaction. Available for download.`,
      balanceReportSummary: `The balance report enables you to 
      view a list of balances and their details with respect 
      to members. Available for download.`,
      cancellationReportSummary: `The cancellation report 
      lists the details of cancelled bookings. 
      Available for download.`,
      rechargeReportSummary: `The recharge report enables 
      you to view a list of recharges and their details 
      with respect to members. Available for download.`,
      creditsReportSummary: `The credit report lists the available 
      credit balance for members. Available for download.`,
      extrasReportSummary: `The add on report enables you to view addon added to the bookings`,
    };
  }

  // -----------------------------------------------------------------------
  // handleReportTypeChange()
  // -----------------------------------------------------------------------
  handleReportTypeChange = async (e, index, reportType) => {
    e.persist();
    await this.setState({
      selectedReportId: index,
      selectedReportType: reportType,
    });
  };

  // -----------------------------------------------------------------------
  // handleReportTypeChange()
  // -----------------------------------------------------------------------
  onClubSelectionChange = (selectedClubs) => {
    this.setState(
      (prevState) => ({
        clubIds: selectedClubs,
      }),
      () => {
        console.log("Updated clubIds:", this.state.clubIds);
      }
    );
  };

  // -----------------------------------------------------------------------
  // render()
  // -----------------------------------------------------------------------
  render() {
    const {
      reportsType,
      selectedReportId,
      selectedReportType,
      transactionReportSummary,
      bookingReportSummary,
      balanceReportSummary,
      cancellationReportSummary,
      rechargeReportSummary,
      creditsReportSummary,
      extrasReportSummary,
      clubIds,
    } = this.state;
    const { handleViewReportsPageDisplay } = this.props;
    return (
      <React.Fragment>
        <Header headerText={"Reports"} />
        <div className="RP-A1-wrapper">
          <div className="RP-A1-reports-type-sidebar">
            {reportsType.map((reportType, index) => (
              <div
                key={index}
                className={
                  selectedReportId === index
                    ? "RP-A1-report-type-container-selected"
                    : "RP-A1-report-type-container"
                }
                onClick={(e) =>
                  this.handleReportTypeChange(e, index, reportType)
                }
              >
                <Text
                  id={
                    selectedReportId === index
                      ? "RP-A1-label-type-one-selected"
                      : "RP-A1-label-type-one"
                  }
                >
                  {reportType}
                </Text>
                <ChevronRightIcon
                  cursor={"pointer"}
                  color={selectedReportId === index ? "#1070CA" : "#66788A"}
                  size={16}
                />
              </div>
            ))}
          </div>
          {selectedReportType === "master" && (
            <TransactionActions
              onClubSelectionChange={this.onClubSelectionChange}
              clubIds={clubIds}
              reportTypeHeading={selectedReportType}
              reportSummary={transactionReportSummary}
              handleViewReportsPageDisplay={handleViewReportsPageDisplay}
            />
          )}
          {selectedReportType === "booking" && (
            <BookingActions
              onClubSelectionChange={this.onClubSelectionChange}
              clubIds={clubIds}
              reportTypeHeading={selectedReportType}
              reportSummary={bookingReportSummary}
              handleViewReportsPageDisplay={handleViewReportsPageDisplay}
            />
          )}
          {selectedReportType === "balance" && (
            <BalanceActions
              onClubSelectionChange={this.onClubSelectionChange}
              clubIds={clubIds}
              reportTypeHeading={selectedReportType}
              reportSummary={balanceReportSummary}
              handleViewReportsPageDisplay={handleViewReportsPageDisplay}
            />
          )}
          {selectedReportType === "cancellation" && (
            <CancellationActions
              onClubSelectionChange={this.onClubSelectionChange}
              clubIds={clubIds}
              reportTypeHeading={selectedReportType}
              reportSummary={cancellationReportSummary}
              handleViewReportsPageDisplay={handleViewReportsPageDisplay}
            />
          )}
          {selectedReportType === "addon" && (
            <ExtrasActions
              onClubSelectionChange={this.onClubSelectionChange}
              clubIds={clubIds}
              reportTypeHeading={selectedReportType}
              reportSummary={extrasReportSummary}
              handleViewReportsPageDisplay={handleViewReportsPageDisplay}
            />
          )}
          {selectedReportType === "recharge" && (
            <RechargeActions
              onClubSelectionChange={this.onClubSelectionChange}
              clubIds={clubIds}
              reportTypeHeading={selectedReportType}
              reportSummary={rechargeReportSummary}
              handleViewReportsPageDisplay={handleViewReportsPageDisplay}
            />
          )}
          {/* {selectedReportType === "packages" && (
            <PackagesActions
              reportTypeHeading={selectedReportType}
              reportSummary={packagesReportSummary}
              handleViewReportsPageDisplay={handleViewReportsPageDisplay}
            />
          )} */}
          {selectedReportType === "credits" && (
            <CreditsActions
              onClubSelectionChange={this.onClubSelectionChange}
              clubIds={clubIds}
              reportTypeHeading={selectedReportType}
              reportSummary={creditsReportSummary}
              handleViewReportsPageDisplay={handleViewReportsPageDisplay}
            />
          )}
        </div>
      </React.Fragment>
    );
  }
}

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default Reports;
