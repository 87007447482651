// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import { connect } from "react-redux";
import Header from "../../components/Header";
import {
  Text,
  SearchInput,
  Button,
  toaster,
  Dialog,
  InlineAlert,
  AddIcon,
} from "evergreen-ui";
import {
  getMemberDetails,
  registerMember,
  getIndividualMemberDetails,
  updateMemberDetails,
} from "../../actions";
import AddNewMemberDialogContent from "../../components/AddNewMemberDialogContent";
import validator from "validator";
import {
  checkIntOrFloat,
  toDateFormat1,
  validateCreditsAndPrice,
  resourcesNotLoadedToast,
  getPlayoClubCountryCallingCode,
  checkClubCountryCallingCodeExists,
} from "../../utils";
import MembersTable from "./MembersTable";
import DrawerHolder from "../../components/DrawerHolder";
import moment from "moment";
import CustomInlineAlert from "../../components/CustomInlineAlert";

// -----------------------------------------------------------------------
// Members Component
// -----------------------------------------------------------------------
class Members extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchString: "",
      isSearchStringInvalid: false,
      clubId: 1,
      pageNum: 1,
      showAddNewMemberDialog: false || this.props.addMemberOnCheckout,
      memberMobile: "",
      unchangedMemberMobile: "",
      isMobileInvalid: false,
      showInvalidMobileDiv: false,
      memberName: "",
      isNameInvalid: false,
      isNameInvalidMsg: false,
      isMobileRequiredMsg: false,
      memberEmail: "",
      isEmailInvalid: false,
      isEmailInvalidMsg: false,
      checkedGenderValue: null,
      paymentMode: "No Pay",
      transactionMode: 0,
      registrationFee: "",
      shakeOnInvalid: false,
      countryCode: checkClubCountryCallingCodeExists()
        ? getPlayoClubCountryCallingCode()
        : "+91",
      memberAddress: "",
      openDrawer: false,
      showOnDrawer: "",
      showDeleteMemberDialog: false,
      showUpdateMemberDetailsDialogOnMembersPage: false,
      showUpdateMemberDetailsDialogOnMemberDetailsOnDrawerPage: false,
      memberExpiryDate: new Date(),
      checkedMemberExpiryRadioValue: null,
      disableNoPay: false,
      selectedTransactionMode: 0,
      isRegistrationFeeInvalid: false,
      isSearchBtnDisabled: false,
      toDate: moment().format("YYYY-MM-DD"),
      fromDate: moment().format("YYYY-MM-DD"),
    };
  }

  // -----------------------------------------------------------------------
  // handleMemberDetailsResponse()
  // -----------------------------------------------------------------------
  handleMemberDetailsResponse = async (reqParams) => {
    await this.props.dispatch(getMemberDetails(reqParams));
    if (this.props.memberDetails) {
      if (this.props.memberDetails.resourcesNotLoadedMessage) {
        resourcesNotLoadedToast();
      } else if (
        this.props.memberDetails.httpErrorMessage &&
        this.props.memberDetails.statusCode === 404
      ) {
        toaster.danger(this.props.memberDetails.httpErrorMessage, {
          id: "HTTP-ERROR-TOAST-1",
        });
        await this.setState({ isSearchBtnDisabled: true });
        setTimeout(() => {
          this.setState({ isSearchBtnDisabled: false });
        }, 3000);
      } else if (this.props.memberDetails.httpErrorMessage) {
        toaster.danger(this.props.memberDetails.httpErrorMessage, {
          id: "HTTP-ERROR-TOAST-4",
        });
      } else if (this.props.memberDetails.requestStatus === -1) {
        toaster.notify("Sorry, you don't have permission to do that");
      } else if (this.props.memberDetails.requestStatus === 0) {
        // toaster.danger(this.props.memberDetails.message, { id: "USER-TOAST" });
      }
    }
  };

  // -----------------------------------------------------------------------
  // getUpdatedMemberDetails()
  // -----------------------------------------------------------------------
  getUpdatedMemberDetails = async () => {
    let reqParams = { page: this.state.pageNum };
    await this.handleMemberDetailsResponse(reqParams);
  };

  // -----------------------------------------------------------------------
  // handleSearchInputChange()
  // -----------------------------------------------------------------------
  handleSearchInputChange = (e) => {
    this.setState({
      pageNum: 1,
      searchString: e.target.value.toString(),
      isSearchStringInvalid: false,
    });
  };

  // -----------------------------------------------------------------------
  // handleSearchInputKeyUp()
  // -----------------------------------------------------------------------
  handleSearchInputKeyUp = async (e) => {
    e.persist();
    if (e.key === "Enter" && e.target.value.length > 0) {
      await this.searchMemberDetails(e);
    }
  };

  // -----------------------------------------------------------------------
  // searchMemberDetails()
  // -----------------------------------------------------------------------
  searchMemberDetails = async (e) => {
    e.persist();
    if (this.state.searchString.length > 0) {
      if (validator.isMobilePhone(this.state.searchString)) {
        let reqParams = {
          page: this.state.pageNum,
          mobile: this.state.searchString,
        };
        await this.handleMemberDetailsResponse(reqParams);
      } else {
        await this.setState({ isSearchStringInvalid: true });
        await this.scrollToElement("isSearchStringInvalid");
      }
    } else {
      await this.setState({ pageNum: 1 });
      let reqParams = {
        page: this.state.pageNum,
      };
      await this.handleMemberDetailsResponse(reqParams);
    }
  };

  // -----------------------------------------------------------------------
  // handlePageChange()
  // -----------------------------------------------------------------------
  handlePageChange = async (newPageNum) => {
    await this.setState({ pageNum: newPageNum });
    if (this.state.searchString.length > 0) {
      let reqParams = {
        page: this.state.pageNum,
        mobile: this.state.searchString,
      };
      await this.handleMemberDetailsResponse(reqParams);
    } else {
      let reqParams = {
        page: this.state.pageNum,
      };
      await this.handleMemberDetailsResponse(reqParams);
    }
  };

  // -----------------------------------------------------------------------
  // toggleAddNewMemberDialogVisibility()
  // -----------------------------------------------------------------------
  toggleAddNewMemberDialogVisibility = async (type) => {
    if (type === "open") {
      this.setState({ showAddNewMemberDialog: true });
    }
    if (type === "close") {
      this.resetMemberFormFields();
      await this.setState({ showAddNewMemberDialog: false });
    }
  };

  // -----------------------------------------------------------------------
  // toggleUpdateMemberDetailsDialogVisibility()
  // -----------------------------------------------------------------------
  toggleUpdateMemberDetailsDialogVisibility = async (
    type,
    onPage = "",
    activeMemberDetails = null
  ) => {
    if (type === "open") {
      await this.updateStateWithActiveMemberDetails(activeMemberDetails);
      if (onPage === "MembersPage") {
        await this.setState({
          showUpdateMemberDetailsDialogOnMembersPage: true,
        });
      }
      if (onPage === "MemberDetailsOnDrawer") {
        await this.setState({
          showUpdateMemberDetailsDialogOnMemberDetailsOnDrawerPage: true,
        });
      }
    }
    if (type === "close") {
      this.resetMemberFormFields();
      await this.setState({
        showUpdateMemberDetailsDialogOnMembersPage: false,
        showUpdateMemberDetailsDialogOnMemberDetailsOnDrawerPage: false,
      });
    }
  };

  // -----------------------------------------------------------------------
  // resetMemberFormFields()
  // -----------------------------------------------------------------------
  resetMemberFormFields = () => {
    this.setState({
      memberMobile: "",
      unchangedMemberMobile: "",
      isMobileInvalid: false,
      showInvalidMobileDiv: false,
      memberName: "",
      isNameInvalid: false,
      isNameInvalidMsg: false,
      isMobileRequiredMsg: false,
      memberEmail: "",
      isEmailInvalid: false,
      isEmailInvalidMsg: false,
      checkedGenderValue: null,
      paymentMode: "No Pay",
      transactionMode: 0,
      registrationFee: "",
      shakeOnInvalid: false,
      countryCode: checkClubCountryCallingCodeExists()
        ? getPlayoClubCountryCallingCode()
        : "+91",
      memberAddress: "",
      memberExpiryDate: new Date(),
      checkedMemberExpiryRadioValue: null,
      disableNoPay: false,
      selectedTransactionMode: 0,
      isRegistrationFeeInvalid: false,
    });
  };

  // -----------------------------------------------------------------------
  // handleAddNewMember()
  // -----------------------------------------------------------------------
  handleAddNewMember = async () => {
    if (
      this.state.memberMobile.length > 0 &&
      this.state.memberName.length > 0 &&
      !this.state.isMobileInvalid &&
      !this.state.isMobileRequiredMsg &&
      !this.state.isNameInvalid &&
      !this.state.isEmailInvalid
    ) {
      let reqBody = await {
        name: this.state.memberName,
        countryCode: this.state.countryCode,
        mobile: this.state.memberMobile,
        email: this.state.memberEmail,
        address: this.state.memberAddress,
        gender: this.state.checkedGenderValue,
        registrationFee:
          this.state.registrationFee === ""
            ? 0
            : checkIntOrFloat(this.state.registrationFee),
        transactionData: {
          type: 7,
          mode: this.state.transactionMode,
          handler: "ground_admin",
          place: "partner",
        },
        startDate: this.state.fromDate,
        toDate: this.state.toDate,
      };
      await this.props.dispatch(registerMember(reqBody));
      if (this.props.registerMemberToast) {
        if (this.props.registerMemberToast.resourcesNotLoadedMessage) {
          resourcesNotLoadedToast();
        } else if (
          this.props.registerMemberToast.httpErrorMessage &&
          this.props.registerMemberToast.statusCode === 404
        ) {
          toaster.danger(this.props.registerMemberToast.httpErrorMessage, {
            id: "HTTP-ERROR-TOAST-1",
          });
          await this.toggleAddNewMemberDialogVisibility("close");
        } else if (this.props.registerMemberToast.httpErrorMessage) {
          toaster.danger(this.props.registerMemberToast.httpErrorMessage, {
            id: "HTTP-ERROR-TOAST-4",
          });
        } else if (this.props.registerMemberToast.requestStatus === -1) {
          toaster.notify("Sorry, you don't have permission to do that");
        } else if (this.props.registerMemberToast.requestStatus === 0) {
          toaster.danger(this.props.registerMemberToast.message, {
            id: "USER-TOAST",
          });
        } else if (this.props.registerMemberToast.requestStatus === 1) {
          this.getUpdatedMemberDetails();
          this.toggleAddNewMemberDialogVisibility("close");
          await toaster.success(this.props.registerMemberToast.message);
        }
      }
    } else {
      if (this.state.memberMobile.length <= 0 || this.state.isMobileInvalid) {
        this.setState({
          isMobileInvalid: true,
        });
        this.enableShake();
      }
      if (this.state.memberName.length <= 0 || this.state.isNameInvalid) {
        this.setState({
          isNameInvalid: true,
        });
        this.enableShake();
      }
      if (this.state.isEmailInvalid) {
        this.setState({ isEmailInvalid: true });
        this.enableShake();
      }
    }
  };

  // -----------------------------------------------------------------------
  // handleUpdateMemberDetails()
  // -----------------------------------------------------------------------
  handleUpdateMemberDetails = async (onPage, mobile) => {
    if (
      this.state.memberMobile.length > 0 &&
      this.state.memberName.length > 0 &&
      !this.state.isMobileInvalid &&
      !this.state.isMobileRequiredMsg &&
      !this.state.isNameInvalid &&
      !this.state.isEmailInvalid
    ) {
      let reqBody = {
        mobile: this.state.unchangedMemberMobile,
        updateData: {
          name: this.state.memberName,
          countryCode: this.state.countryCode,
          mobile: this.state.memberMobile,
          email: this.state.memberEmail,
          address: this.state.memberAddress,
          gender: this.state.checkedGenderValue,
          expiryDate:
            this.state.checkedMemberExpiryRadioValue === "date"
              ? toDateFormat1(this.state.memberExpiryDate)
              : "max",
        },
      };
      await this.props.dispatch(updateMemberDetails(reqBody));
      if (onPage === "MemberDetailsOnDrawer") {
        await this.props.dispatch(
          getIndividualMemberDetails({
            page: 1,
            mobile: mobile,
          })
        );
        if (this.props.individualMemberDetailsToast) {
          if (
            this.props.individualMemberDetailsToast.resourcesNotLoadedMessage
          ) {
            resourcesNotLoadedToast();
          } else if (this.props.individualMemberDetailsToast.httpErrorMessage) {
            toaster.danger(
              this.props.individualMemberDetailsToast.httpErrorMessage,
              {
                id: "HTTP-ERROR-TOAST-4",
              }
            );
          } else if (
            this.props.individualMemberDetailsToast.requestStatus === -1
          ) {
            toaster.notify("Sorry, you don't have permission to do that");
          } else if (
            this.props.individualMemberDetailsToast.requestStatus === 0
          ) {
            toaster.danger(this.props.individualMemberDetailsToast.message, {
              id: "USER-TOAST",
            });
          }
        }
      }
      if (this.props.updateMemberDetailsToast) {
        if (this.props.updateMemberDetailsToast.resourcesNotLoadedMessage) {
          resourcesNotLoadedToast();
        } else if (
          this.props.updateMemberDetailsToast.httpErrorMessage &&
          this.props.updateMemberDetailsToast.statusCode === 404
        ) {
          toaster.danger(this.props.updateMemberDetailsToast.httpErrorMessage, {
            id: "HTTP-ERROR-TOAST-1",
          });
          this.toggleUpdateMemberDetailsDialogVisibility("close");
        } else if (this.props.updateMemberDetailsToast.httpErrorMessage) {
          toaster.danger(this.props.updateMemberDetailsToast.httpErrorMessage, {
            id: "HTTP-ERROR-TOAST-4",
          });
        } else if (this.props.updateMemberDetailsToast.requestStatus === 0) {
          toaster.danger(this.props.updateMemberDetailsToast.message, {
            id: "USER-TOAST",
          });
        } else if (this.props.updateMemberDetailsToast.requestStatus === -1) {
          toaster.notify("Sorry, you don't have permission to do that");
        } else if (this.props.updateMemberDetailsToast.requestStatus === 1) {
          this.getUpdatedMemberDetails();
          this.toggleUpdateMemberDetailsDialogVisibility("close");
          await toaster.success(this.props.updateMemberDetailsToast.message);
        }
      }
    } else {
      if (this.state.memberMobile.length <= 0 || this.state.isMobileInvalid) {
        this.setState({
          isMobileInvalid: true,
        });
        this.enableShake();
      }
      if (this.state.memberName.length <= 0 || this.state.isNameInvalid) {
        this.setState({
          isNameInvalid: true,
        });
        this.enableShake();
      }
      if (this.state.isEmailInvalid) {
        this.setState({ isEmailInvalid: true });
        this.enableShake();
      }
    }
  };

  // -----------------------------------------------------------------------
  // enableShake()
  // -----------------------------------------------------------------------
  enableShake = () => {
    this.setState({ shakeOnInvalid: true });
    window.setTimeout(() => {
      this.setState({ shakeOnInvalid: false });
    }, 320);
  };

  // -----------------------------------------------------------------------
  // onChangeOfMobileInput()
  // -----------------------------------------------------------------------
  onChangeOfMobileInput = async (e) => {
    e.persist();
    this.setState({ shakeOnInvalid: false });
    if (e.target.value.length <= 0) {
      this.setState({ memberMobile: e.target.value.toString() });
      await this.setState({
        showInvalidMobileDiv: false,
        isMobileInvalid: true,
        isMobileRequiredMsg: true,
      });
    } else {
      this.setState({ memberMobile: e.target.value.toString() });
      await this.setState({
        showInvalidMobileDiv: false,
        isMobileInvalid: false,
        isMobileRequiredMsg: false,
      });
    }
  };

  // -----------------------------------------------------------------------
  // onBlurOfMobileInput()
  // -----------------------------------------------------------------------
  onBlurOfMobileInput = (e) => {
    e.persist();
    if (this.state.memberMobile.length > 0) {
      if (validator.isMobilePhone(this.state.memberMobile.toString())) {
        this.setState({ showInvalidMobileDiv: false, isMobileInvalid: false });
      } else {
        this.setState({ showInvalidMobileDiv: true, isMobileInvalid: true });
      }
    }
  };

  // -----------------------------------------------------------------------
  // onChangeOfNameInput()
  // -----------------------------------------------------------------------
  onChangeOfNameInput = async (e) => {
    e.persist();
    this.setState({ shakeOnInvalid: false });
    if (e.target.value.length > 0) {
      this.setState({
        memberName: e.target.value.toString(),
      });
      await this.setState({ isNameInvalid: false, isNameInvalidMsg: false });
    } else {
      this.setState({
        memberName: e.target.value.toString(),
      });
      await this.setState({ isNameInvalid: true, isNameInvalidMsg: true });
    }
  };

  // -----------------------------------------------------------------------
  // onChangeOfEmailInput()
  // -----------------------------------------------------------------------
  onChangeOfEmailInput = async (e) => {
    this.setState({ shakeOnInvalid: false });
    await this.setState({
      memberEmail: e.target.value.toString(),
    });
    if (this.state.memberEmail.length <= 0) {
      this.setState({ isEmailInvalid: false, isEmailInvalidMsg: false });
    }
  };

  // -----------------------------------------------------------------------
  // validateEmail()
  // -----------------------------------------------------------------------
  validateEmail = () => {
    if (this.state.memberEmail.length > 0) {
      if (validator.isEmail(this.state.memberEmail)) {
        this.setState({ isEmailInvalidMsg: false, isEmailInvalid: false });
      } else {
        this.setState({ isEmailInvalidMsg: true, isEmailInvalid: true });
      }
    } else {
      this.setState({ isEmailInvalid: false, isEmailInvalidMsg: false });
    }
  };

  // -----------------------------------------------------------------------
  // onChangeOfGenderRadioInput()
  // -----------------------------------------------------------------------
  onChangeOfGenderRadioInput = (e, genderValue) => {
    this.setState({ checkedGenderValue: genderValue });
  };

  // -----------------------------------------------------------------------
  // onChangeOfMemberExpiryRadioInput()
  // -----------------------------------------------------------------------
  onChangeOfMemberExpiryRadioInput = (e, memberExpiryRadioValue) => {
    e.persist();
    this.setState({ checkedMemberExpiryRadioValue: memberExpiryRadioValue });
  };

  // -----------------------------------------------------------------------
  // changeTransactionMode()
  // -----------------------------------------------------------------------
  changeTransactionMode = (e) => {
    e.persist();
    if (this.state.registrationFee.length > 0) {
      this.setState({
        paymentMode:
          e.nativeEvent.target[
            e.nativeEvent.target.selectedIndex
          ].text.toString(),
        transactionMode: parseInt(e.target.value),
        selectedTransactionMode: parseInt(e.target.value),
        isRegistrationFeeInvalid: false,
      });
    } else {
      this.setState({ isRegistrationFeeInvalid: true });
    }
  };

  // -----------------------------------------------------------------------
  // onChangeOfAddressInput()
  // -----------------------------------------------------------------------
  onChangeOfAddressInput = (e) => {
    this.setState({ memberAddress: e.target.value.toString().trim() });
  };

  // -----------------------------------------------------------------------
  // onChangeOfRegistrationFeeInput()
  // -----------------------------------------------------------------------
  onChangeOfRegistrationFeeInput = async (e) => {
    if (validateCreditsAndPrice(e.target.value) === "set") {
      await this.setState({ registrationFee: e.target.value });
      if (
        this.state.selectedTransactionMode === 0 &&
        this.state.registrationFee > 0
      ) {
        await this.setState({
          paymentMode: "Cash",
          transactionMode: 1,
          selectedTransactionMode: 1,
          disableNoPay: true,
          isRegistrationFeeInvalid: false,
        });
      }
      if (this.state.registrationFee <= 0) {
        await this.setState({
          paymentMode: "No Pay",
          transactionMode: 0,
          selectedTransactionMode: 0,
          disableNoPay: false,
        });
      }
    } else if (validateCreditsAndPrice(e.target.value) === "clear") {
      this.setState({
        registrationFee: "",
        paymentMode: "No Pay",
        transactionMode: 0,
        selectedTransactionMode: 0,
        disableNoPay: false,
      });
    }
  };

  // -----------------------------------------------------------------------
  // onCountryChange()
  // -----------------------------------------------------------------------
  onCountryChange = (callingCode) => {
    this.setState({ countryCode: callingCode });
  };

  // -----------------------------------------------------------------------
  // handleGetIndividualMemberDetails()
  // -----------------------------------------------------------------------
  handleGetIndividualMemberDetails = async (reqParams) => {
    await this.props.dispatch(getIndividualMemberDetails(reqParams));
    if (this.props.individualMemberDetailsToast) {
      if (this.props.individualMemberDetailsToast.resourcesNotLoadedMessage) {
        resourcesNotLoadedToast();
      } else if (this.props.individualMemberDetailsToast.httpErrorMessage) {
        toaster.danger(
          this.props.individualMemberDetailsToast.httpErrorMessage,
          {
            id: "HTTP-ERROR-TOAST-4",
          }
        );
      } else if (this.props.individualMemberDetailsToast.requestStatus === -1) {
        toaster.notify("Sorry, you don't have permission to do that");
      } else if (this.props.individualMemberDetailsToast.requestStatus === 0) {
        toaster.danger(this.props.individualMemberDetailsToast.message, {
          id: "USER-TOAST",
        });
      } else if (this.props.individualMemberDetailsToast.requestStatus === 1) {
        await this.handleDrawer("open");
      }
    }
  };

  // -----------------------------------------------------------------------
  // handleDrawer()
  // -----------------------------------------------------------------------
  handleDrawer = (type) => {
    if (type === "open") {
      this.setState({
        showOnDrawer: "memberDetailsOnMembersPage",
        openDrawer: true,
      });
      this.props.changeRightPaneDivWidth("decrease");
    }
    if (type === "close") {
      this.setState({
        openDrawer: false,
        showOnDrawer: "",
      });
      this.props.changeRightPaneDivWidth("increase");
    }
  };

  // -----------------------------------------------------------------------
  // handleDeleteMemberDialog()
  // -----------------------------------------------------------------------
  handleDeleteMemberDialog = (type) => {
    if (type === "open") {
      this.setState({ showDeleteMemberDialog: true });
    }
    if (type === "close") {
      this.setState({ showDeleteMemberDialog: false });
    }
  };

  // -----------------------------------------------------------------------
  // confirmDeleteMember()
  // -----------------------------------------------------------------------
  confirmDeleteMember = () => {
    this.handleDeleteMemberDialog("close");
  };

  // -----------------------------------------------------------------------
  // updateStateWithActiveMemberDetails()
  // -----------------------------------------------------------------------
  updateStateWithActiveMemberDetails = async (activeMemberDetails) => {
    await this.setState({
      unchangedMemberMobile: activeMemberDetails.memberMobile.toString(),
      memberMobile: activeMemberDetails.memberMobile.toString(),
      memberName: activeMemberDetails.memberName,
      memberEmail: activeMemberDetails.memberEmail,
      memberAddress: activeMemberDetails.address,
      checkedGenderValue: activeMemberDetails.memberGender,
      memberExpiryDate:
        activeMemberDetails.endDate === "9999-12-31"
          ? new Date()
          : activeMemberDetails.endDate,
      checkedMemberExpiryRadioValue:
        activeMemberDetails.endDate !== "9999-12-31" ? "date" : "lifetime",
    });
  };

  // -----------------------------------------------------------------------
  // handleMemberExpiryDateChange()
  // -----------------------------------------------------------------------
  handleMemberExpiryDateChange = async (date) => {
    if (moment(date).isBefore(new Date())) {
      toaster.warning("Cannot choose to set expiry to a past date");
    } else {
      await this.setState({ memberExpiryDate: date });
    }
  };

  // -----------------------------------------------------------------------
  // scrollToElement()
  // -----------------------------------------------------------------------
  scrollToElement = async (elem) => {
    await this.refs[elem].scrollIntoView({ block: "end", behavior: "smooth" });
  };

  dateChange = (dateChangeData) => {
    this.setState({ ...dateChangeData });
  };

  // -----------------------------------------------------------------------
  // render()
  // -----------------------------------------------------------------------
  render() {
    const {
      clubId,
      pageNum,
      memberMobile,
      isMobileInvalid,
      showInvalidMobileDiv,
      isNameInvalid,
      isNameInvalidMsg,
      isMobileRequiredMsg,
      memberName,
      isEmailInvalid,
      isEmailInvalidMsg,
      memberEmail,
      checkedGenderValue,
      registrationFee,
      shakeOnInvalid,
      showAddNewMemberDialog,
      showOnDrawer,
      openDrawer,
      showDeleteMemberDialog,
      showUpdateMemberDetailsDialogOnMembersPage,
      showUpdateMemberDetailsDialogOnMemberDetailsOnDrawerPage,
      memberAddress,
      memberExpiryDate,
      checkedMemberExpiryRadioValue,
      selectedTransactionMode,
      disableNoPay,
      isRegistrationFeeInvalid,
      isSearchBtnDisabled,
      isSearchStringInvalid,
      fromDate,
      toDate,
    } = this.state;
    const {
      memberDetails,
      individualMemberDetails,
      handleSeeBookingsFromMembersDrawer,
      handleMembershipPackagesCheckoutPageDisplay,
      addMemberOnCheckout,
    } = this.props;
    return (
      <React.Fragment>
        <div className="MP-A1-wrapper">
          <Header headerText={"Members"} />
          <div className="MP-A1-action-bar">
            <div className="MP-A1-col-container">
              <div className="MP-A1-label-container MP-A1-margin-bottom-8">
                <Text id="MP-A1-label-type-one">Find Members</Text>
              </div>
              <div className="MP-A1-row-container MP-A1-align-items-center">
                <SearchInput
                  placeholder="Find by Contact Number"
                  type="text"
                  width={328}
                  height={32}
                  marginRight={8}
                  isInvalid={isSearchStringInvalid}
                  onChange={(e) => this.handleSearchInputChange(e)}
                  onKeyUp={(e) => this.handleSearchInputKeyUp(e)}
                />
                <Button
                  className="MP-A1-button"
                  appearance="primary"
                  minWidth={88}
                  height={32}
                  marginRight={24}
                  onClick={(e) => this.searchMemberDetails(e)}
                  disabled={isSearchBtnDisabled}
                >
                  Search
                </Button>
                <div className="MP-A1-vertical-line-decorator" />
                <Button
                  className="MP-A1-button"
                  iconBefore={<AddIcon />}
                  appearance="primary"
                  intent="warning"
                  minWidth={149}
                  height={32}
                  marginLeft={24}
                  marginRight={24}
                  onClick={() =>
                    this.toggleAddNewMemberDialogVisibility("open")
                  }
                >
                  Add New Member
                </Button>
              </div>

              {isSearchStringInvalid && (
                <div
                  className="MP-A1-custom-inline-alert-container"
                  ref={"isSearchStringInvalid"}
                >
                  <CustomInlineAlert
                    intent="danger"
                    alertMsg="Invalid mobile number"
                  />
                </div>
              )}
            </div>
          </div>
          {memberDetails && memberDetails.requestStatus === 1 ? (
            <MembersTable
              confirmDeleteMember={this.confirmDeleteMember}
              handleDeleteMemberDialog={this.handleDeleteMemberDialog}
              showDeleteMemberDialog={showDeleteMemberDialog}
              memberDetails={memberDetails}
              clubId={clubId}
              pageNumOnMembersPage={pageNum}
              handleGetIndividualMemberDetails={
                this.handleGetIndividualMemberDetails
              }
              handlePageChange={this.handlePageChange}
              memberMobileInputValue={memberMobile}
              isMobileInvalid={isMobileInvalid}
              showInvalidMobileDiv={showInvalidMobileDiv}
              isNameInvalid={isNameInvalid}
              isNameInvalidMsg={isNameInvalidMsg}
              isMobileRequiredMsg={isMobileRequiredMsg}
              memberName={memberName}
              isEmailInvalid={isEmailInvalid}
              isEmailInvalidMsg={isEmailInvalidMsg}
              memberEmail={memberEmail}
              memberAddress={memberAddress}
              checkedGenderValue={checkedGenderValue}
              memberExpiryDate={memberExpiryDate}
              onChangeOfMobileInput={this.onChangeOfMobileInput}
              onBlurOfMobileInput={this.onBlurOfMobileInput}
              onChangeOfNameInput={this.onChangeOfNameInput}
              onChangeOfEmailInput={this.onChangeOfEmailInput}
              validateEmail={this.validateEmail}
              onChangeOfGenderRadioInput={this.onChangeOfGenderRadioInput}
              shakeOnInvalid={shakeOnInvalid}
              onCountryChange={this.onCountryChange}
              onChangeOfAddressInput={this.onChangeOfAddressInput}
              showUpdateMemberDetailsDialogOnMembersPage={
                showUpdateMemberDetailsDialogOnMembersPage
              }
              toggleUpdateMemberDetailsDialogVisibility={
                this.toggleUpdateMemberDetailsDialogVisibility
              }
              checkedMemberExpiryRadioValue={checkedMemberExpiryRadioValue}
              onChangeOfMemberExpiryRadioInput={
                this.onChangeOfMemberExpiryRadioInput
              }
              handleUpdateMemberDetails={this.handleUpdateMemberDetails}
              handleMemberExpiryDateChange={this.handleMemberExpiryDateChange}
              handleMembershipPackagesCheckoutPageDisplay={
                handleMembershipPackagesCheckoutPageDisplay
              }
              getUpdatedMemberDetails={this.getUpdatedMemberDetails}
            />
          ) : (
            <div className="MP-A1-message-container">
              <InlineAlert intent="warning" marginLeft={16} marginRight={4}>
                No Matching Results.
              </InlineAlert>
              <Text marginTop={10}>Try to find by some other details.</Text>
            </div>
          )}
        </div>
        <Dialog
          width={560}
          isShown={showAddNewMemberDialog}
          title="Add New Member"
          confirmLabel="Add Member"
          onConfirm={() => this.handleAddNewMember()}
          onCloseComplete={() =>
            this.toggleAddNewMemberDialogVisibility("close")
          }
        >
          <AddNewMemberDialogContent
            memberMobile={memberMobile}
            isMobileInvalid={isMobileInvalid}
            showInvalidMobileDiv={showInvalidMobileDiv}
            isNameInvalid={isNameInvalid}
            isNameInvalidMsg={isNameInvalidMsg}
            isMobileRequiredMsg={isMobileRequiredMsg}
            memberName={memberName}
            isEmailInvalid={isEmailInvalid}
            isEmailInvalidMsg={isEmailInvalidMsg}
            memberEmail={memberEmail}
            checkedGenderValue={checkedGenderValue}
            onChangeOfMobileInput={this.onChangeOfMobileInput}
            onBlurOfMobileInput={this.onBlurOfMobileInput}
            onChangeOfNameInput={this.onChangeOfNameInput}
            onChangeOfEmailInput={this.onChangeOfEmailInput}
            validateEmail={this.validateEmail}
            onChangeOfGenderRadioInput={this.onChangeOfGenderRadioInput}
            changeTransactionMode={this.changeTransactionMode}
            registrationFee={registrationFee}
            onChangeOfRegistrationFeeInput={this.onChangeOfRegistrationFeeInput}
            shakeOnInvalid={shakeOnInvalid}
            onCountryChange={this.onCountryChange}
            onChangeOfAddressInput={this.onChangeOfAddressInput}
            disableNoPay={disableNoPay}
            selectedTransactionMode={selectedTransactionMode}
            isRegistrationFeeInvalid={isRegistrationFeeInvalid}
            fromDate={fromDate}
            toDate={toDate}
            dateChange={this.dateChange}
          />
        </Dialog>
        <DrawerHolder
          openDrawer={openDrawer}
          showOnDrawer={showOnDrawer}
          handleDrawer={this.handleDrawer}
          clubId={clubId}
          pageNumOnMembersPage={pageNum}
          individualMemberDetails={individualMemberDetails}
          handleSeeBookingsFromMembersDrawer={
            handleSeeBookingsFromMembersDrawer
          }
          memberMobileInputValue={memberMobile}
          isMobileInvalid={isMobileInvalid}
          showInvalidMobileDiv={showInvalidMobileDiv}
          isNameInvalid={isNameInvalid}
          isNameInvalidMsg={isNameInvalidMsg}
          isMobileRequiredMsg={isMobileRequiredMsg}
          memberName={memberName}
          isEmailInvalid={isEmailInvalid}
          isEmailInvalidMsg={isEmailInvalidMsg}
          memberEmail={memberEmail}
          memberAddress={memberAddress}
          checkedGenderValue={checkedGenderValue}
          memberExpiryDate={memberExpiryDate}
          onChangeOfMobileInput={this.onChangeOfMobileInput}
          onBlurOfMobileInput={this.onBlurOfMobileInput}
          onChangeOfNameInput={this.onChangeOfNameInput}
          onChangeOfEmailInput={this.onChangeOfEmailInput}
          validateEmail={this.validateEmail}
          onChangeOfGenderRadioInput={this.onChangeOfGenderRadioInput}
          shakeOnInvalid={shakeOnInvalid}
          onCountryChange={this.onCountryChange}
          onChangeOfAddressInput={this.onChangeOfAddressInput}
          showUpdateMemberDetailsDialogOnMemberDetailsOnDrawerPage={
            showUpdateMemberDetailsDialogOnMemberDetailsOnDrawerPage
          }
          toggleUpdateMemberDetailsDialogVisibility={
            this.toggleUpdateMemberDetailsDialogVisibility
          }
          checkedMemberExpiryRadioValue={checkedMemberExpiryRadioValue}
          onChangeOfMemberExpiryRadioInput={
            this.onChangeOfMemberExpiryRadioInput
          }
          handleUpdateMemberDetails={this.handleUpdateMemberDetails}
          handleMemberExpiryDateChange={this.handleMemberExpiryDateChange}
          handleMembershipPackagesCheckoutPageDisplay={
            handleMembershipPackagesCheckoutPageDisplay
          }
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({
  memberDetails,
  registerMemberToast,
  updateMemberDetailsToast,
  individualMemberDetails,
  individualMemberDetailsToast,
}) => ({
  memberDetails,
  registerMemberToast,
  updateMemberDetailsToast,
  individualMemberDetails,
  individualMemberDetailsToast,
});

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default connect(mapStateToProps)(Members);
