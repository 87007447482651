import { BookIcon, CalendarIcon, Pane, Strong, Text } from "evergreen-ui";
import React, { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-3";

function DoughnutChart({ chartData, currency }) {
  const [pluginsData, setPlugins] = useState({});
  const data = {
    datasets: [
      {
        hover: true,
        data: [chartData.online, chartData.offline],
        backgroundColor: ["rgba(255, 99, 132, 0.2)"],
        backgroundColor: ["#00B562", "#0F4D88"],
        borderWidth: 1,
      },
    ],
  };
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    tooltips: { enabled: false },
    cutoutPercentage: 60,
  };

  return (
    <Pane
      display="flex"
      background="gray300"
      flexDirection="column"
      justifyContent="space-between"
      borderRadius={"10px"}
      width="49%"
      padding="20px"
    >
      <Pane
        display={"flex"}
        flexDirection={"row"}
        justifyContent="space-between"
      >
        <Pane
          display={"flex"}
          flexDirection={"row"}
          justifyContent="space-between"
          alignItems={"center"}
        >
          <Pane
            width={40}
            height={40}
            borderRadius={"50%"}
            background={"white"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            {chartData.title === "bookings" ? (
              <BookIcon color="#0F4D88" size={24} />
            ) : (
              <CalendarIcon color="#0F4D88" size={24} />
            )}{" "}
          </Pane>

          <Text textTransform={"capitalize"} marginLeft={20}>
            No. of {chartData.title}
          </Text>
        </Pane>
        <Strong>{chartData.sum}</Strong>
      </Pane>
      <Pane
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        marginTop="20px"
      >
        <Pane
          display="flex"
          flexDirection="row"
          justifyContent="start"
          alignItems="start"
          width="200px"
          height="200px"
          position={"relative"}
        >
          {chartData.sum === 0 ? (
            <Pane
              height={182}
              width={182}
              marginTop={12}
              borderRadius={"50%"}
              border={"32px solid #8f95b2"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              position={"relative"}
            ></Pane>
          ) : (
            <Doughnut options={options} plugins={pluginsData} data={data} />
          )}
          <Pane position={"absolute"} top={96} left={90}>
            <Strong>{chartData.sum} </Strong>
          </Pane>
        </Pane>

        {chartData.sum === 0 ? (
          <Pane display={"flex"} flexDirection={"column"}>
            <Strong size={500} color={"#0F4D88"}>
              {" "}
              {chartData.title === "bookings"
                ? "No bookings"
                : "No cancellation"}
            </Strong>
            <Text>
              The are no{" "}
              {chartData.title === "bookings" ? "bookings" : "cancellation"} for
              the selected period.
            </Text>
          </Pane>
        ) : (
          <Pane
            display="flex"
            width={"50%"}
            flexDirection="column"
            justifyContent="space-between"
            alignItems="center"
          >
            <Pane
              display="flex"
              justifyContent="space-between"
              flexDirection="row"
              width={"100%"}
            >
              <Pane
                display="flex"
                justifyContent="space-between"
                flexDirection="row"
              >
                <Pane
                  height={20}
                  borderRadius={"4px"}
                  width={20}
                  background={"#00B562"}
                ></Pane>
                <Text marginLeft={10}>Online</Text>
              </Pane>
              <Pane
                display="flex"
                justifyContent="end"
                flexDirection="row"
                width={"60%"}
                alignItems="end"
              >
                <Strong>{chartData.online}</Strong>
              </Pane>
            </Pane>
            <Pane
              display="flex"
              justifyContent="space-between"
              flexDirection="row"
              width={"100%"}
              marginTop={16}
            >
              <Pane
                display="flex"
                justifyContent="space-between"
                flexDirection="row"
              >
                <Pane
                  height={20}
                  borderRadius={"4px"}
                  width={20}
                  background={"#0F4D88"}
                ></Pane>
                <Text marginLeft={10}>Offline</Text>
              </Pane>
              <Pane
                display="flex"
                justifyContent="end"
                flexDirection="row"
                width={"60%"}
                alignItems="end"
              >
                <Strong>{chartData.offline}</Strong>
              </Pane>
            </Pane>
          </Pane>
        )}
      </Pane>
    </Pane>
  );
}

export default DoughnutChart;
