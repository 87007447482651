// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import { connect } from "react-redux";
import { Button, Text, toaster, TrashIcon } from "evergreen-ui";
import CustomizedDatePicker from "../../components/CustomizedDatePicker";
import {
  configureHoliday,
  removeHoliday,
  togglePremiumFeatureFlag,
} from "../../actions";
import {
  toDateFormat1,
  resourcesNotLoadedToast,
  timeConverter121,
} from "../../utils";

// -----------------------------------------------------------------------
// HolidayListingDialogContent Component
// -----------------------------------------------------------------------
class HolidayListingDialogContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: null,
      showDatePicker: false,
      openDatePickerPopover: false,
      disableAddHoliday: true,
    };
  }

  // -----------------------------------------------------------------------
  // handleDateChange()
  // -----------------------------------------------------------------------
  handleDateChange = async (date) => {
    await this.setState({
      date: date,
    });
  };

  // // -----------------------------------------------------------------------
  // // handleSelectADateBtn()
  // // -----------------------------------------------------------------------
  // handleSelectADateBtn = async e => {
  //   e.persist();
  //   await this.setState({
  //     showDatePicker: true,
  //     disableAddHoliday: false
  //   });
  // };

  // -----------------------------------------------------------------------
  // confirmAddHoliday()
  // -----------------------------------------------------------------------
  confirmAddHoliday = async (e) => {
    e.persist();
    let reqBody = {
      dates: [toDateFormat1(this.state.date).toString()],
    };
    await this.props.dispatch(configureHoliday(reqBody));
    if (this.props.configureHolidayToast) {
      if (this.props.configureHolidayToast.resourcesNotLoadedMessage) {
        resourcesNotLoadedToast();
      } else if (this.props.configureHolidayToast.httpErrorMessage) {
        toaster.danger(this.props.configureHolidayToast.httpErrorMessage, {
          id: "HTTP-ERROR-TOAST-3",
        });
      } else if (this.props.configureHolidayToast.requestStatus === -1) {
        toaster.notify("Sorry, you don't have permission to do that");
      } else if (this.props.configureHolidayToast.requestStatus === 1) {
        await this.props.loadHolidaysData();
        toaster.success(this.props.configureHolidayToast.message, {
          id: "HTTP-ERROR-TOAST-3",
        });
      } else if (this.props.configureHolidayToast.requestStatus === 0) {
        toaster.danger(this.props.configureHolidayToast.message, {
          id: "HTTP-ERROR-TOAST-3",
        });
      }
    }
  };

  // -----------------------------------------------------------------------
  // confirmRemoveHoliday()
  // -----------------------------------------------------------------------
  confirmRemoveHoliday = async (e, holidayDate) => {
    e.persist();
    let reqBody = {
      dates: [holidayDate.toString()],
    };
    await this.props.dispatch(removeHoliday(reqBody));
    if (this.props.removeHolidayToast) {
      if (this.props.removeHolidayToast.resourcesNotLoadedMessage) {
        resourcesNotLoadedToast();
      } else if (this.props.removeHolidayToast.httpErrorMessage) {
        toaster.danger(this.props.removeHolidayToast.httpErrorMessage, {
          id: "HTTP-ERROR-TOAST-3",
        });
      } else if (this.props.removeHolidayToast.requestStatus === -1) {
        toaster.notify("Sorry, you don't have permission to do that");
      } else if (this.props.removeHolidayToast.requestStatus === 1) {
        await this.props.loadHolidaysData();
        toaster.success(this.props.removeHolidayToast.message, {
          id: "HTTP-ERROR-TOAST-3",
        });
      } else if (this.props.removeHolidayToast.requestStatus === 0) {
        toaster.danger(this.props.removeHolidayToast.message, {
          id: "HTTP-ERROR-TOAST-3",
        });
      }
    }
  };

  // -----------------------------------------------------------------------
  // render()
  // -----------------------------------------------------------------------
  render() {
    // const { date, showDatePicker, disableAddHoliday } = this.state;
    const { date } = this.state;
    const { holidaysData } = this.props;
    return (
      <React.Fragment>
        <div className="HLDC-A1-wrapper">
          <div className="HLDC-A1-top-container">
            <div className="HLDC-A1-row-container">
              <div className="HLDC-A1-col-container HLDC-A1-margin-right-40">
                <div className="HLDC-A1-label-container HLDC-A1-margin-bottom-8">
                  <Text id="HLDC-A1-label-type-one">Add New Date</Text>
                </div>
                {/* {!showDatePicker && (
                  <Button
                    className="HLDC-A1-button"
                    width={112}
                    height={32}
                    onClick={e => this.handleSelectADateBtn(e)}
                  >
                    Select a Date
                  </Button>
                )} */}
                {/* {showDatePicker && ( */}
                <div className="HLDC-A1-date-picker-container">
                  <CustomizedDatePicker
                    // openDatePickerPopover={openDatePickerPopover}
                    // onClickOutsideDatePickerPopover={
                    //   this.onClickOutsideDatePickerPopover
                    // }
                    date={date}
                    handleDateChange={this.handleDateChange}
                  />
                </div>
                {/* )} */}
              </div>
              <div className="HLDC-A1-add-holiday-btn-container">
                <Button
                  className="HLDC-A1-button"
                  width={120}
                  height={32}
                  onClick={(e) => this.confirmAddHoliday(e)}
                  appearance="primary"
                  disabled={date === null ? true : false}
                >
                  Add Holiday
                </Button>
              </div>
            </div>
          </div>
          <div className="HLDC-A1-horizontal-line-decorator" />
          <div className="HLDC-A1-bottom-container">
            <div className="HLDC-A1-label-container">
              <Text id="HLDC-A1-label-type-two">Holidays</Text>
            </div>
            {holidaysData &&
            holidaysData.requestStatus === 1 &&
            holidaysData.holidays.length > 0 ? (
              <div className="HLDC-A1-configured-holidays-list-container">
                {holidaysData.holidays.map((holiday, index) => (
                  <div
                    key={index}
                    className="HLDC-A1-row-container HLDC-A1-padding-top-24"
                  >
                    <div className="HLDC-A1-label-container HLDC-A1-margin-right-56 HLDC-A1-align-self-center HLDC-A1-width-120">
                      <Text id="HLDC-A1-label-type-three">
                        {timeConverter121(holiday)}
                      </Text>
                    </div>
                    <div className="HLDC-A1-delete-holiday-btn-container">
                      <Button
                        className="HLDC-A1-button"
                        width={82}
                        height={32}
                        onClick={(e) => this.confirmRemoveHoliday(e, holiday)}
                        appearance="minimal"
                        intent="danger"
                        iconBefore={<TrashIcon />}
                      >
                        Delete
                      </Button>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="HLDC-A1-label-container HLDC-A1-padding-top-24 HLDC-A1-padding-bottom-24">
                <Text id="HLDC-A1-label-type-four">No holidays configured</Text>
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({
  holidaysData,
  configureHolidayToast,
  removeHolidayToast,
}) => ({
  holidaysData,
  configureHolidayToast,
  removeHolidayToast,
});

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default connect(mapStateToProps)(HolidayListingDialogContent);
