// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import { Button } from "evergreen-ui";
import styled from "styled-components";

// -----------------------------------------------------------------------
// Styled Components
// -----------------------------------------------------------------------
const CartActionsDiv = styled.div`
  .action-btn-container {
    width: 288px;
    margin: 40px auto 0 auto;
    display: flex;
    justify-content: space-between;
  }
`;

// -----------------------------------------------------------------------
// CartActions Component
// -----------------------------------------------------------------------
class CartActions extends React.Component {
  // -----------------------------------------------------------------------
  // render()
  // -----------------------------------------------------------------------
  render() {
    const { handleBookingCheckoutDisplay, handleClearCart } = this.props;
    return (
      <React.Fragment>
        <CartActionsDiv>
          <div className="action-btn-container">
            <Button
              className="button"
              width={132}
              height={32}
              onClick={e => handleClearCart()}
            >
              Clear All
            </Button>
            <Button
              className="button"
              appearance="primary"
              width={132}
              height={32}
              onClick={e => handleBookingCheckoutDisplay("show")}
            >
              Proceed
            </Button>
          </div>
        </CartActionsDiv>
      </React.Fragment>
    );
  }
}

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default CartActions;
