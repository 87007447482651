// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import Header from "../../components/Header";
import ActionBar from "./ActionBar";
import GraphActionBar from "./GraphActionBar";
import GraphPane from "./Graph";
import DayGraph from "./DayGraph";
import HourGraph from "./HourGraph";
import DayHourGraph from "./DayHourGraph";
import Api from "../../api";
import {toDateFormat1} from "../../utils";

// -----------------------------------------------------------------------
// Analytics Component
// -----------------------------------------------------------------------
class Analytics extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: new Date(),
            endDate: new Date(),
            defaultActivityName: "Select a sport",
            defaultActivityValue: "select-a-sport",
            activityId: undefined,
            graphType: 0,
            disableShowUtilisation: true,
            graphProjections: [
                {type: 2, name: 'Day', graph: <div/>},
                {type: 1, name: 'Hour', graph: <div/>},
                {type: 0, name: 'Day & Hour', graph: <div/>}
            ],
            utilisation: undefined
        };
    }

    handleStartDateChange = async date => {
        this.setState({
            startDate: date,
        });
    };

    handleEndDateChange = async date => {
        this.setState({
            endDate: date,
        });
    };

    handleActivityChange = async e => {
        e.persist();
        this.setState({
            activityId: e.target.value,
            disableShowUtilisation: false
        });
    };

    handleGetUtilisation = async (activityId, startDate, endDate, graphType) => {
        if (activityId) {
            const utilisation = await Api.getUtilisationData({
                activityId,
                graphType: this.state.graphProjections[graphType].type,
                startDate: toDateFormat1(startDate),
                endDate: toDateFormat1(endDate),
            });
            return utilisation.body.data
        }
    };

    updateGraphType = async type => {
        const {activityId, startDate, endDate} = this.state;
        const utilisation = await this.handleGetUtilisation(activityId, startDate, endDate, type);
        this.setState({
            graphType: type,
            graphProjections: [
                {
                    type: 2,
                    name: 'Day',
                    graph: <DayGraph utilisation={{...utilisation}} graphType={type}/>
                },
                {
                    type: 1,
                    name: 'Hour',
                    graph: <HourGraph utilisation={{...utilisation}} graphType={type}/>
                },
                {
                    type: 0,
                    name: 'Day & Hour',
                    graph: <DayHourGraph utilisation={{...utilisation}} graphType={type}/>
                }
            ],
            utilisation
        })
    };

    showUtilisation = async () => {
        const {activityId, startDate, endDate, graphType} = this.state;
        const utilisation = await this.handleGetUtilisation(activityId, startDate, endDate, graphType);
        this.setState({
            graphProjections: [
                {
                    type: 2,
                    name: 'Day',
                    graph: <DayGraph utilisation={{...utilisation}} graphType={graphType}/>
                },
                {
                    type: 1,
                    name: 'Hour',
                    graph: <HourGraph utilisation={{...utilisation}} graphType={graphType}/>
                },
                {
                    type: 0,
                    name: 'Day & Hour',
                    graph: <DayHourGraph utilisation={{...utilisation}} graphType={graphType}/>
                }
            ],
            utilisation
        });
    };

    render() {
        return (
            <React.Fragment>
                <Header headerText={"Analytics"}/>
                <ActionBar
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                    handleStartDateChange={this.handleStartDateChange}
                    handleEndDateChange={this.handleEndDateChange}
                    defaultActivityName={this.state.defaultActivityName}
                    defaultActivityValue={this.state.defaultActivityValue}
                    selectedActivityValue={this.state.activityId}
                    handleActivityChange={this.handleActivityChange}
                    showUtilisation={this.showUtilisation}
                    disableShowUtilisation={this.state.disableShowUtilisation}
                />
                <GraphActionBar
                    handleTabsChange={this.updateGraphType}
                    selectedTab={this.state.graphType}
                    graphProjections={this.state.graphProjections}
                />
                <GraphPane
                    selectedTab={this.state.graphType}
                    graphProjections={this.state.graphProjections}
                />
            </React.Fragment>
        )
    }
}

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default Analytics;
