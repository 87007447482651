// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import BulkForm from "../../components/BulkForm";

// -----------------------------------------------------------------------
// BlockSlots Component
// -----------------------------------------------------------------------
class BlockSlots extends React.Component {
  // -----------------------------------------------------------------------
  // render()
  // -----------------------------------------------------------------------
  render() {
    const { selectedTab, handleTabsChange } = this.props;
    return (
      <React.Fragment>
        <BulkForm
          formFor="block-slots"
          showTabs={true}
          selectedTab={selectedTab}
          handleTabsChange={handleTabsChange}
        />
      </React.Fragment>
    );
  }
}

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default BlockSlots;
