// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import {
  Text,
  Dialog,
  Table,
  Paragraph,
  Button,
  IconButton,
  toaster,
  Icon,
  CrossIcon,
  TickCircleIcon,
} from "evergreen-ui";
import styled from "styled-components";
import {
  toDateFormat,
  toTimeFormat1,
  resourcesNotLoadedToast,
} from "../../utils";
import { connect } from "react-redux";
import { unblocking, togglePremiumFeatureFlag } from "../../actions";

// -----------------------------------------------------------------------
// Styled Components
// -----------------------------------------------------------------------
const AvailabilityDiv = styled.div`
  display: flex;
  flex-direction: column;

  .availability-count-wrapper span,
  #footer-message,
  #header-message {
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #425a70;
  }

  .availability-count-wrapper {
    border-top: 1px solid #e4e7eb;
    border-bottom: 1px solid #e4e7eb;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 8px 0;
  }

  .availability-count-container {
    display: flex;
    flex-direction: column;
    span {
      font-size: 12px;
      font-weight: 600;
    }
  }

  .availability-table-container {
    margin-top: 16px;
    border-bottom: 1px solid #e4e7eb;
  }

  .footer-actions-container,
  .footer-wrapper,
  .header-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .header-wrapper {
    padding: 16px 0;
  }

  .footer-wrapper {
    margin-top: 16px;
    padding: 16px 12px;
  }

  .header-message-container,
  .footer-message-container {
    width: 100%;
  }

  .footer-message-container {
    margin-right: 32px;
  }

  #header-message {
    font-size: 20px;
    font-weight: 500;
    color: #234361;
    text-align: left;
  }

  #footer-message {
    font-size: 12px;
    font-style: italic;
    color: #66788a;
    text-align: left;
  }
`;

// -----------------------------------------------------------------------
// UnblockSlotsDialog Component
// -----------------------------------------------------------------------
class UnblockSlotsDialog extends React.Component {
  // -----------------------------------------------------------------------
  // handleContinueUnblocking()
  // -----------------------------------------------------------------------
  handleContinueUnblocking = async (e) => {
    let reqBody = {
      blockingId: null,
      slots: this.props.slotsAvailabilityData.blockedSlots.map((slot) => {
        return slot;
      }),
    };
    await this.props.dispatch(unblocking(reqBody));
    if (this.props.unblockingToast) {
      if (this.props.unblockingToast.resourcesNotLoadedMessage) {
        resourcesNotLoadedToast();
      } else if (this.props.unblockingToast.httpErrorMessage) {
        toaster.danger(this.props.unblockingToast.httpErrorMessage, {
          id: "HTTP-ERROR-TOAST-4",
        });
      } else if (this.props.unblockingToast.requestStatus === 0) {
        toaster.warning(this.props.unblockingToast.message, {
          id: "HP-TOAST",
        });
      } else if (this.props.unblockingToast.requestStatus === 1) {
        toaster.success(this.props.unblockingToast.message);
        this.props.handleCloseDialog(e);
      } else if (this.props.unblockingToast.requestStatus === -1) {
        toaster.notify("Sorry, you don't have permission to do that");
      }
    }
  };

  // -----------------------------------------------------------------------
  // render()
  // -----------------------------------------------------------------------
  render() {
    const { showDialog, handleCloseDialog, slotsAvailabilityData } = this.props;
    return (
      <React.Fragment>
        {slotsAvailabilityData && (
          <Dialog
            width={720}
            isShown={showDialog}
            onCloseComplete={(e) => handleCloseDialog(e)}
            hasFooter={false}
            hasHeader={false}
          >
            <AvailabilityDiv>
              <div className="header-wrapper">
                <div className="header-message-container">
                  {slotsAvailabilityData.blockedSlots.length > 0 ? (
                    <Paragraph id="header-message">
                      Following slots can be unblocked as part of this pattern.
                    </Paragraph>
                  ) : (
                    <Paragraph id="header-message">
                      None of the slots in the pattern are blocked.
                    </Paragraph>
                  )}
                </div>
                <div className="header-actions-container">
                  <IconButton
                    icon={<CrossIcon />}
                    height={32}
                    onClick={(e) => handleCloseDialog(e)}
                  />
                </div>
              </div>
              <div className="availability-count-wrapper">
                <div className="availability-count-container">
                  <Text>Available for unblocking</Text>
                  <Text>{slotsAvailabilityData.blockedSlots.length}</Text>
                </div>
              </div>
              {slotsAvailabilityData.blockedSlots.length > 0 && (
                <div className="availability-table-container">
                  <Table>
                    <Table.Head>
                      <Table.TextHeaderCell>Date</Table.TextHeaderCell>
                      <Table.TextHeaderCell>Time</Table.TextHeaderCell>
                      <Table.TextHeaderCell>Court</Table.TextHeaderCell>
                      <Table.TextHeaderCell>
                        Can be unblocked
                      </Table.TextHeaderCell>
                    </Table.Head>
                    <Table.Body maxHeight={220}>
                      {slotsAvailabilityData.blockedSlots.map((slot, index) => (
                        <Table.Row key={index}>
                          <Table.TextCell>
                            {toDateFormat(slot.slotDate)}
                          </Table.TextCell>
                          <Table.TextCell>
                            {toTimeFormat1(slot.slotTime)}
                          </Table.TextCell>
                          <Table.TextCell>{slot.courtId}</Table.TextCell>
                          <Table.TextCell>
                            <Icon
                              icon={<TickCircleIcon />}
                              color="success"
                              marginRight={16}
                            />
                          </Table.TextCell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                </div>
              )}
              <div className="footer-wrapper">
                <div className="footer-message-container">
                  {slotsAvailabilityData.blockedSlots.length > 0 ? (
                    <Paragraph id="footer-message">
                      All the slots in the selected pattern will be unblocked
                      and will be available for booking again.
                    </Paragraph>
                  ) : (
                    <Paragraph id="footer-message">
                      None of the slots are available for unblocking.
                    </Paragraph>
                  )}
                </div>
                <div className="footer-actions-container">
                  <Button
                    className="button"
                    width={132}
                    marginRight={16}
                    onClick={(e) => handleCloseDialog(e)}
                  >
                    Cancel
                  </Button>

                  <Button
                    className="button"
                    width={132}
                    appearance="primary"
                    intent="success"
                    disabled={
                      slotsAvailabilityData.blockedSlots.length > 0
                        ? false
                        : true
                    }
                    onClick={(e) => this.handleContinueUnblocking(e)}
                  >
                    Continue
                  </Button>
                </div>
              </div>
            </AvailabilityDiv>
          </Dialog>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ unblockingToast }) => ({
  unblockingToast,
});

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default connect(mapStateToProps)(UnblockSlotsDialog);
