import {
  Button,
  ExportIcon,
  Heading,
  Pagination,
  Pane,
  Position,
  Spinner,
  StatusIndicator,
  Strong,
  Table,
  Text,
  TickCircleIcon,
  Tooltip,
} from "evergreen-ui";
import React, { useEffect } from "react";
import CustomizedPagination from "../CustomizedPagination";

export default function EventBookingsTable({
  eventBookingList,
  downloadBookings,
  handleGetBookingDetails,
  currentPage,
  totalPages,
  handlePageChange,
  bookingLoader,
  dispatch,
  bookingDetails,
}) {
  useEffect(() => {
    if (bookingDetails) {
      for (const eventBooking of eventBookingList) {
        for (const bookingDetail of bookingDetails.bookingDetails) {
          // Check if bookingId and dateBooked match
          if (
            eventBooking.bookingId === bookingDetail.bookingId &&
            eventBooking.dateBooked === bookingDetail.dateBooked
          ) {
            // Update eventBooking with bookingDetail
            Object.assign(eventBooking, bookingDetail);
          }
        }
      }
    }
  }, [bookingDetails]);

  return (
    <Pane
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"space-between"}
      width={"100%"}
    >
      <Pane
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        width={"100%"}
        marginTop={20}
      >
        <Heading size={500}>Registration</Heading>
        <Button
          onClick={() => downloadBookings()}
          marginY={8}
          marginRight={12}
          iconBefore={ExportIcon}
        >
          Export
        </Button>
      </Pane>

      <Table
        minWidth={800}
        overflow={"scroll"}
        width={"100%"}
        marginTop={20}
        marginBottom={20}
      >
        <Table.Head>
          <Table.TextHeaderCell>Booking Id</Table.TextHeaderCell>
          <Table.TextHeaderCell>Booking Date</Table.TextHeaderCell>
          <Table.TextHeaderCell> Name / Mobile No.</Table.TextHeaderCell>
          <Table.TextHeaderCell>Email</Table.TextHeaderCell>
          <Table.TextHeaderCell> Tickets / Source</Table.TextHeaderCell>
          <Table.TextHeaderCell>Total / PAID</Table.TextHeaderCell>
        </Table.Head>
        {bookingLoader ? (
          <Pane
            display="flex"
            alignItems="center"
            justifyContent="center"
            height={320}
          >
            <Spinner />
          </Pane>
        ) : (
          <Table.VirtualBody height={310}>
            {eventBookingList.map((data) => (
              <Table.Row key={data.bookingId}>
                <Table.TextCell
                  cursor={"pointer"}
                  onClick={() => {
                    const bookingDetails = {
                      bookingDetails: [data],
                    };
                    dispatch({
                      type: "SET_BOOKING_DETAILS",
                      payload: bookingDetails,
                    });
                    handleGetBookingDetails(data.bookingId, true, true);
                  }}
                >
                  {" "}
                  <Strong textDecoration={"underline"}>
                    {" "}
                    {data?.bookingId}
                  </Strong>
                  {/* <Tooltip
                    isShown={data.checkinData === null ? false : undefined}
                    content={
                      <Pane
                        display="flex"
                        alignItems="center"
                        color="white"
                        flexDirection={"column"}
                      >
                        {data.checkinData === null ? null : (
                          <Pane display="flex" flexDirection="column" gap={4}>
                            <strong size={200}>Checked by:</strong>
                            <Text color="white">
                              {" "}
                              {data?.checkinData?.handler}
                            </Text>

                            <strong size={200}>Time:</strong>
                            <Text color="white">
                              {" "}
                              {data?.checkinData?.timestamp}
                            </Text>
                          </Pane>
                        )}
                      </Pane>
                    }
                    position={Position.TOP}
                  >
                    <Pane
                      display={"flex"}
                      alignItems={"center"}
                      flexDirection={"row"}
                    >
                      {data.checkinData === null ? null : (
                        <TickCircleIcon
                          color="success"
                          marginRight={6}
                          size={16}
                        />
                      )}
                      <Strong textDecoration={"underline"}>
                        {" "}
                        {data?.bookingId}
                      </Strong>
                    </Pane>
                  </Tooltip> */}
                </Table.TextCell>
                <Table.TextCell width={60}>{data?.dateBooked}</Table.TextCell>
                <Table.TextCell>
                  <Pane>{data?.customerDetails?.customerName} / </Pane>
                  <Pane>{data?.customerDetails?.customerMobile}</Pane>
                </Table.TextCell>
                <Table.TextCell>
                  {data?.customerDetails?.customerEmail}
                </Table.TextCell>
                <Table.TextCell width={60}> {data?.slotCount}</Table.TextCell>
                <Table.TextCell width={250} overflow={"visible"}>
                  {" "}
                  {data?.summary?.gross} / {data?.summary?.advance}
                </Table.TextCell>
              </Table.Row>
            ))}
          </Table.VirtualBody>
        )}
      </Table>
      <Pane
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
        justifyContent={"end"}
        width={"100%"}
      >
        <CustomizedPagination
          currentPage={currentPage}
          totalPages={totalPages}
          handlePageChange={handlePageChange}
        />
      </Pane>
    </Pane>
  );
}
