// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import {Pane} from "evergreen-ui";
import Chart from "react-apexcharts"

// -----------------------------------------------------------------------
// DayHourGraph Component
// -----------------------------------------------------------------------
class DayHourGraph extends React.Component {

    options = {
        chart: {
            id: 'heatmap'
        },
        dataLabels: {
            enabled: false
        },
        tooltip: {
            enabled: true,
            y: {
                formatter: (value) => {
                    return value + "%"
                }
            }
        },
        plotOptions: {
            heatmap: {
                radius: 2,
                enableShades: false,
                shadeIntensity: 1,
                reverseNegativeShade: false,
                distributed: true,
                colorScale: {
                    ranges: [
                        {color: '#1890FF02', name: '0-1', from: 0, to: 1,},
                        {color: '#1890FF19', name: '1-10', from: 1, to: 10,},
                        {color: '#1890FF33', name: '10-20', from: 10, to: 20,},
                        {color: '#1890FF4C', name: '20-30', from: 20, to: 30,},
                        {color: '#1890FF66', name: '30-40', from: 30, to: 40,},
                        {color: '#1890FF80', name: '40-50', from: 40, to: 50,},
                        {color: '#1890FF99', name: '50-60', from: 50, to: 60,},
                        {color: '#1890FFB3', name: '60-70', from: 60, to: 70,},
                        {color: '#1890FFCC', name: '70-80', from: 70, to: 80,},
                        {color: '#1890FFE6', name: '80-90', from: 80, to: 90,},
                        {color: '#1890FFFF', name: '90-100', from: 90, to: 100,}
                    ],
                    inverse: true,
                    min: 0,
                    max: 100
                },
            }
        }
    };

    getFormattedUtilisation = (utilisation) => {
        return Object.keys(utilisation).reduce((accumulator, day) => {
            accumulator.push({
                name: day, data: Object.keys(utilisation[day]).map(slot => {
                    return {x: slot.slice(0, 5), y: utilisation[day][slot].percentage}
                })
            });
            return accumulator
        }, [])
    };

    render() {
        const {utilisation, graphType} = this.props;
        return (
            graphType === 2 ?
                <Pane padding={16} flex="1">
                    <Chart
                        options={this.options}
                        series={this.getFormattedUtilisation(utilisation)}
                        type="heatmap"
                        width="1000"
                        height="400"
                    />
                </Pane> : <div/>
        )
    }
}

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default DayHourGraph;
