// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import {
  Pane,
  Text,
  IconButton,
  Select,
  TextInput,
  TextInputField,
  Textarea,
  Button,
  Checkbox,
  Dialog,
  Menu,
  Strong,
  Popover,
  Icon,
  Position,
  InlineAlert,
  toaster,
  TickCircleIcon,
  CrossIcon,
} from "evergreen-ui";
import Navbar from "../../components/Navbar";
import BookingActions from "../../components/BookingActions";
import {
  getCustomerDetails,
  redeemCredits,
  resetCredits,
  applyDiscount,
  resetDiscount,
  booking,
  patternBooking,
  clearCart,
  togglePremiumFeatureFlag,
  getClubExtrasList,
} from "../../actions";
import { connect } from "react-redux";
import ExtrasActions from "../../components/ExtrasActions";
import validator from "validator";
import {
  checkIntOrFloat,
  resourcesNotLoadedToast,
  validateCreditsAndPrice,
  isObjEmpty,
  generateCurrencySymbol,
  getPlayoClubCountryCallingCode,
  checkClubCountryCallingCodeExists,
} from "../../utils";
import CountrySelectMenu from "../../components/CountrySelectMenu";
import TransactionModeSelect from "../../components/TransactionModeSelect";
import PageHeadingAndActionsNav from "../../components/PageHeadingAndActionsNav";

// -----------------------------------------------------------------------
// BookingCheckout Component
// -----------------------------------------------------------------------
class BookingCheckout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clubId: 1,
      sendConfirmationSmschecked: false,
      showLessThanZero: false,
      memberPin: "",
      customerName: "",
      customerEmail: "",
      customerMobile: "",
      customerUniqueId: "",
      customerUniqueIdExists: false,
      customerCredits: 0,
      showExtrasDialog: false,
      discountAmount: "",
      discountApplied: 0,
      showDiscountAppliedDiv: false,
      creditsApplied: 0,
      showRedeemMsg: false,
      showDiscountActionDiv: true,
      showCreditsActionDiv: true,
      showCreditsAppliedDiv: false,
      advanceAmount: "",
      bookingRemarks: "",
      countryCode: checkClubCountryCallingCodeExists()
        ? getPlayoClubCountryCallingCode()
        : "+91",
      showInvalidEmailDiv: false,
      showInvalidMobileDiv: false,
      showConfirmDismissDialog: false,
      isMobileInvalid: false,
      mobileIsRequiredMsg: false,
      isNameInvalid: false,
      nameIsRequiredMsg: false,
      isEmailInvalid: false,
      showInvalidDiscountDiv: false,
      isDiscountInvalid: false,
      showInvalidAdvanceDiv: false,
      isAdvanceInvalid: false,
      isConfirmBookingBtnDisabled: false,
      isMemberPinInvalid: false,
      paymentMode: "No Pay",
      transactionMode: 0,
      selectedTransactionMode: 0,
      showDiscountExceedsBalanceDiv: false,
      showCreditsExceedsBalanceDiv: false,
      disableNoPay: false,
      showAdvanceResetToZeroDiv: false,
      creditsAppliedFlag: false,
      showRedeemCreditsPopover: true,
      disableNameAndEmailEdit: false,
      isEmailMandatory: false,
      sendPaymentLinkIsChecked: false,
    };
  }

  checkIsEmailMandatory = () => {
    const clubConfig = JSON.parse(localStorage.getItem("clubConfig"));
    return !!(
      clubConfig.bookingCheckout && clubConfig.bookingCheckout.isEmailMandatory
    );
  };

  // -----------------------------------------------------------------------
  // componentDidMount()
  // -----------------------------------------------------------------------
  async componentDidMount() {
    // if(this.checkIsEmailMandatory()) {
    //     this.setState({
    //         isEmailMandatory: true
    //     })
    // }

    if (
      this.props.cartData &&
      !isObjEmpty(this.props.cartData.cart) &&
      this.props.cartData.cart.slotsNetPrice !==
        this.props.cartData.cart.slotsGrossPrice
    ) {
      this.handleResetCredits();
      this.resetDiscount();
    }
  }

  // -----------------------------------------------------------------------
  //  componentDidUpdate()
  // -----------------------------------------------------------------------
  componentDidUpdate = (prevProps) => {
    if (prevProps.bookingCheckoutDisplay === "none") {
      // this.scrollToElement("scrollTopPosition");
    }
    if (
      this.props.resetBookingCheckoutAll &&
      this.props.setResetBookingCheckoutAllFalse
    ) {
      this.props.setResetBookingCheckoutAllFalse();
      this.handleResetForm();
    }
    if (
      this.props.resetBookingCheckoutInputFields &&
      this.props.setResetBookingCheckoutInputFieldsFalse
    ) {
      this.props.setResetBookingCheckoutInputFieldsFalse();
      this.resetBookingCheckoutInputFields();
    }
  };

  // -----------------------------------------------------------------------
  // resetBookingCheckoutInputFields()
  // -----------------------------------------------------------------------
  resetBookingCheckoutInputFields = async () => {
    await this.setState({
      sendConfirmationSmschecked: false,
      sendPaymentLinkIsChecked: false,
      memberPin: "",
      customerName: "",
      customerEmail: "",
      customerMobile: "",
      customerUniqueId: "",
      customerUniqueIdExists: false,
      customerCredits: 0,
      showExtrasDialog: false,
      discountAmount: "",
      discountApplied: 0,
      showDiscountAppliedDiv: false,
      creditsApplied: 0,
      showRedeemMsg: false,
      showDiscountActionDiv: true,
      showCreditsActionDiv: true,
      showCreditsAppliedDiv: false,
      advanceAmount: "",
      bookingRemarks: "",
      countryCode: checkClubCountryCallingCodeExists()
        ? getPlayoClubCountryCallingCode()
        : "+91",
      showInvalidEmailDiv: false,
      showInvalidMobileDiv: false,
      showConfirmDismissDialog: false,
      isMobileInvalid: false,
      mobileIsRequiredMsg: false,
      isNameInvalid: false,
      nameIsRequiredMsg: false,
      isEmailInvalid: false,
      showInvalidDiscountDiv: false,
      isDiscountInvalid: false,
      showInvalidAdvanceDiv: false,
      isAdvanceInvalid: false,
      isConfirmBookingBtnDisabled: false,
      isMemberPinInvalid: false,
      paymentMode: "No Pay",
      transactionMode: 0,
      selectedTransactionMode: 0,
      showDiscountExceedsBalanceDiv: false,
      showCreditsExceedsBalanceDiv: false,
      disableNoPay: false,
      showAdvanceResetToZeroDiv: false,
      creditsAppliedFlag: false,
      showRedeemCreditsPopover: true,
      disableNameAndEmailEdit: false,
      isEmailMandatory: false,
    });
  };

  // -----------------------------------------------------------------------
  // scrollToElement()
  // -----------------------------------------------------------------------
  scrollToElement = async (elem) => {
    await this.refs[elem].scrollIntoView({
      block: "start",
      behavior: "auto",
    });
  };

  // -----------------------------------------------------------------------
  // smoothScrollToElement()
  // -----------------------------------------------------------------------
  smoothScrollToElement = async (elem) => {
    await this.refs[elem].scrollIntoView({
      block: "end",
      behavior: "smooth",
    });
  };

  // -----------------------------------------------------------------------
  // handleConfirmDismissDialog()
  // -----------------------------------------------------------------------
  handleConfirmDismissDialog = async (e, type) => {
    if (type === "hide-dialog") {
      this.setState({ showConfirmDismissDialog: false });
    }
    if (type === "confirm") {
      this.props.resetCartCount();
      this.props.dispatch({
        type: "SET_BULK_BOOKING_INFORMATION",
        payload: null,
      });
      await this.handleResetForm();
      this.setState({ showConfirmDismissDialog: false });
    }
  };

  // -----------------------------------------------------------------------
  //  handleBookingCheckoutPageDisplay()
  // -----------------------------------------------------------------------
  handleBookingCheckoutPageDisplay = async (e) => {
    e.persist();
    await this.setState({
      showCreditsExceedsBalanceDiv: false,
      showDiscountExceedsBalanceDiv: false,
      showInvalidDiscountDiv: false,
    });
    if (this.state.customerName.length <= 0) {
      await this.setState({ isNameInvalid: false, nameIsRequiredMsg: false });
    }
    if (this.state.customerMobile.length <= 0) {
      await this.setState({
        isMobileInvalid: false,
        mobileIsRequiredMsg: false,
      });
    }
    if (this.props.bulkBookingInformation !== null) {
      await this.setState({ showConfirmDismissDialog: true });
    } else {
      await this.props.showCartDrawer();
      await this.props.handleBookingCheckoutDisplay("hide");
    }
  };

  // -----------------------------------------------------------------------
  // handleRedeemCreditsPinInput()
  // -----------------------------------------------------------------------
  handleRedeemCreditsPinInput = (e) => {
    this.setState({ memberPin: e.target.value });
  };

  // -----------------------------------------------------------------------
  // handleRedeemCreditsClearInput()
  // -----------------------------------------------------------------------
  handleRedeemCreditsClearInput = () => {
    this.setState({ memberPin: "", showRedeemMsg: false });
  };

  // -----------------------------------------------------------------------
  // handleShowRedeemCreditsPopover()
  // -----------------------------------------------------------------------
  handleShowRedeemCreditsPopover = async (type) => {
    await this.setState({
      showRedeemCreditsPopover: type === "show",
    });
  };
  // -----------------------------------------------------------------------
  // handleRedeemCredits()
  // -----------------------------------------------------------------------
  handleRedeemCredits = async (e) => {
    e.persist();
    if (this.props.cartData && !this.props.cartData.cart.slotsNetPrice > 0) {
      await this.setState({
        showCreditsExceedsBalanceDiv: true,
        showCreditsActionDiv: true,
      });
      await this.handleShowRedeemCreditsPopover("hide");
      await this.handleShowRedeemCreditsPopover("show");
    } else {
      let reqBody = {
        credits: this.props.customerDetails.customerDetails.credits,
        expiry: this.props.customerDetails.customerDetails.creditsExpiry,
        memberPin: this.state.memberPin,
        mobile: this.props.customerDetails.customerDetails.customerMobile,
        advance:
          this.state.advanceAmount === ""
            ? 0
            : checkIntOrFloat(this.state.advanceAmount),
      };
      await this.props.dispatch(redeemCredits(reqBody));
      if (this.props.cartToast) {
        if (this.props.cartToast.resourcesNotLoadedMessage) {
          resourcesNotLoadedToast();
        } else if (this.props.cartToast.httpErrorMessage) {
          toaster.danger(this.props.cartToast.httpErrorMessage, {
            id: "HTTP-ERROR-TOAST-4",
          });
        } else if (this.props.cartToast.requestStatus === -1) {
          toaster.notify("Sorry, you don't have permission to do that");
        } else if (this.props.cartToast.requestStatus === 1) {
          await this.setState({ creditsAppliedFlag: true });
          // if (this.state.advanceAmount !== "" && this.state.advanceAmount > 0) {
          //   await this.setState({
          //     advanceAmount: "",
          //     showAdvanceResetToZeroDiv: true,
          //     paymentMode: "No Pay",
          //     transactionMode: 0,
          //     selectedTransactionMode: 0,
          //     disableNoPay: false
          //   });
          // }
          await this.setState({
            showCreditsExceedsBalanceDiv: false,
            showRedeemMsg: false,
            isMemberPinInvalid: false,
          });
          if (this.props.cartData && this.props.cartData.cart.credits) {
            await this.setState({
              creditsApplied: this.props.cartData.cart.credits,
            });
          }
          // if (
          //   this.props.cartData &&
          //   this.props.cartData.cart.slotsGrossPrice <=
          //     this.state.customerCredits
          // ) {
          //   await this.setState({
          //     creditsApplied:
          //       this.props.cartData.cart.slotsGrossPrice -
          //       this.state.discountApplied -
          //       Number(this.state.advanceAmount)
          //   });
          // } else {
          //   if (
          //     this.props.cartData.cart.slotsGrossPrice -
          //       this.state.discountApplied <=
          //     this.state.customerCredits
          //   ) {
          //     await this.setState({
          //       creditsApplied:
          //         this.props.cartData.cart.slotsGrossPrice -
          //         this.state.discountApplied
          //     });
          //   } else {
          //     await this.setState({
          //       creditsApplied: this.state.customerCredits
          //     });
          //   }
          // }
          await this.setState({
            showCreditsActionDiv: false,
            showCreditsAppliedDiv: true,
          });
        } else if (this.props.cartToast.requestStatus === 0) {
          await this.setState({
            isMemberPinInvalid: true,
            showRedeemMsg: true,
          });
        }
      }
      // if (
      //   this.props.cartData.cart.cartPrice +
      //     this.state.creditsApplied -
      //     this.state.discountApplied -
      //     this.state.advanceAmount <
      //   0
      // ) {
      //   await this.setState({
      //     showInvalidAdvanceDiv: true,
      //     isAdvanceInvalid: true,
      //     isConfirmBookingBtnDisabled: true
      //   });
      // } else {
      //   await this.setState({
      //     showInvalidAdvanceDiv: false,
      //     isAdvanceInvalid: false,
      //     isConfirmBookingBtnDisabled: false
      //   });
      // }
    }
  };

  // -----------------------------------------------------------------------
  // handleResetCredits()
  // -----------------------------------------------------------------------
  handleResetCredits = async () => {
    // e.persist();
    let discountAmount = this.state.discountAmount;
    let advanceAmount = this.state.advanceAmount;
    let creditsApplied = this.state.creditsApplied;
    if (
      this.props.cartData &&
      this.props.cartData.cart &&
      this.props.cartData.cart.totalSlotCount > this.props.cartData &&
      this.props.cartData.cart &&
      this.props.cartData.cart.slots.length
    ) {
      discountAmount /= this.props.cartData.cart.totalSlotCount;
      advanceAmount /= this.props.cartData.cart.totalSlotCount;
      creditsApplied /= this.props.cartData.cart.totalSlotCount;
    }
    await this.props.dispatch(resetCredits());
    if (this.props.cartToast) {
      if (this.props.cartToast.resourcesNotLoadedMessage) {
        resourcesNotLoadedToast();
      } else if (this.props.cartToast.httpErrorMessage) {
        toaster.danger(this.props.cartToast.httpErrorMessage, {
          id: "HTTP-ERROR-TOAST-4",
        });
      } else if (this.props.cartToast.requestStatus === -1) {
        toaster.notify("Sorry, you don't have permission to do that");
      } else if (this.props.cartToast.requestStatus === 1) {
        await this.setState({ creditsAppliedFlag: false });
        // if (this.state.advanceAmount !== "" && this.state.advanceAmount > 0) {
        //   await this.setState({
        //     advanceAmount: "",
        //     showAdvanceResetToZeroDiv: true,
        //     paymentMode: "No Pay",
        //     transactionMode: 0,
        //     selectedTransactionMode: 0,
        //     disableNoPay: false
        //   });
        // }
        await this.setState({ creditsApplied: 0 });
        await this.setState({ memberPin: "", showRedeemMsg: false });
        await this.setState({
          showCreditsActionDiv: true,
          showCreditsAppliedDiv: false,
        });
        if (
          this.props.cartData.cart.cartPrice +
            creditsApplied -
            discountAmount -
            advanceAmount <
          0
        ) {
          this.setState({
            showInvalidAdvanceDiv: true,
            isAdvanceInvalid: true,
            isConfirmBookingBtnDisabled: true,
          });
        } else {
          this.setState({
            showInvalidAdvanceDiv: false,
            isAdvanceInvalid: false,
            isConfirmBookingBtnDisabled: false,
          });
        }
      } else if (this.props.cartToast.requestStatus === 0) {
        toaster.danger(this.props.cartToast.message, {
          id: "HTTP-ERROR-TOAST-4",
        });
      }
    }
  };

  // -----------------------------------------------------------------------
  // handleSendConfirmationSmsCheckbox()
  // -----------------------------------------------------------------------
  handleSendConfirmationSmsCheckbox = async (e) => {
    e.persist();
    await this.setState({
      sendConfirmationSmschecked: e.target.checked,
    });
  };

  // -----------------------------------------------------------------------
  // handleSendConfirmationSmsCheckbox()
  // -----------------------------------------------------------------------
  handleSendPaymentLinkCheckbox = async (e) => {
    e.persist();
    await this.setState({
      sendPaymentLinkIsChecked: e.target.checked,
    });
  };

  // -----------------------------------------------------------------------
  // onBlurOfMobileInputField()
  // -----------------------------------------------------------------------
  onBlurOfMobileInputField = async (e) => {
    e.persist();
    let mobile =
      this.props.customerDetails && this.props.customerDetails.mobile;

    if (this.state.customerMobile.toString().trim().length > 0) {
      if (
        validator.isMobilePhone(this.state.customerMobile.toString().trim())
      ) {
        await this.setState({ showInvalidMobileDiv: false });
        await this.setState({ isMobileInvalid: false });
        let reqBody = {
          clubId: this.state.clubId,
          mobile: this.state.customerMobile.toString().trim(),
        };
        if (this.state.customerMobile.toString().trim() != mobile) {
          await this.props.dispatch(getCustomerDetails(reqBody, true));
          this.handleResetCredits();
          this.resetDiscount();
        }
        await this.setState({
          showLessThanZero: false,
          isDiscountInvalid: false,
        });

        if (this.props.customerDetails) {
          await this.setState({
            customerName: "",
            customerEmail: "",
            customerUniqueId: "",
            disableNameAndEmailEdit: false,
            customerUniqueIdExists: false,
          });
          if (this.props.customerDetails.resourcesNotLoadedMessage) {
            resourcesNotLoadedToast();
          } else if (this.props.customerDetails.httpErrorMessage) {
            toaster.danger(this.props.customerDetails.httpErrorMessage, {
              id: "HTTP-ERROR-TOAST-4",
            });
          } else if (this.props.customerDetails.requestStatus === -1) {
            toaster.notify("Sorry, you don't have permission to do that");
          } else if (
            this.props.customerDetails &&
            !this.props.customerDetails.toBeRegistered &&
            this.props.customerDetails.customerDetails
          ) {
            await this.setState({
              customerName:
                this.props.customerDetails.customerDetails.customerName,
              customerCredits:
                this.props.cart && this.props.cart.customerAvailableCredits
                  ? this.props.cart.customerAvailableCredits
                  : 0,
              customerUniqueId:
                this.props.customerDetails.customerDetails.uniqueId || "",
              customerUniqueIdExists:
                !!this.props.customerDetails.customerDetails.uniqueId,
            });
            if (
              validator.isEmail(
                this.props.customerDetails.customerDetails.customerEmail
                  .toString()
                  .trim()
              )
            ) {
              await this.setState({
                customerEmail:
                  this.props.customerDetails.customerDetails.customerEmail,
              });
            }
            if (this.props.customerDetails["isMember"]) {
              await this.setState({
                disableNameAndEmailEdit: true,
              });
            } else {
              await this.setState({
                disableNameAndEmailEdit: false,
              });
            }
            if (this.state.customerName.length > 0) {
              this.setState({ isNameInvalid: false, nameIsRequiredMsg: false });
            }
            if (validator.isEmail(this.state.customerEmail)) {
              this.setState({ showInvalidEmailDiv: false });
            }
          }
        }
      } else {
        await this.setState({ showInvalidMobileDiv: true });
        await this.setState({ isMobileInvalid: true });
      }
    } else {
      await this.setState({ customerMobile: "" });
    }
  };

  // -----------------------------------------------------------------------
  // onChangeOfMobileInputField()
  // -----------------------------------------------------------------------
  onChangeOfMobileInputField = async (e) => {
    if (validateCreditsAndPrice(e.target.value) === "set") {
      await this.setState({
        customerMobile: e.target.value.toString(),
        showInvalidMobileDiv: false,
        isMobileInvalid: false,
        mobileIsRequiredMsg: false,
      });
    } else if (validateCreditsAndPrice(e.target.value) === "clear") {
      await this.setState({
        customerMobile: "",
        showInvalidMobileDiv: false,
        isMobileInvalid: true,
        mobileIsRequiredMsg: true,
      });
    }
  };

  // -----------------------------------------------------------------------
  // handleNameInputChange()
  // -----------------------------------------------------------------------
  handleNameInputChange = async (e) => {
    await this.setState({
      customerName: e.target.value.toString(),
    });
    if (this.state.customerName.length > 0) {
      this.setState({ isNameInvalid: false, nameIsRequiredMsg: false });
    } else {
      this.setState({ isNameInvalid: true, nameIsRequiredMsg: true });
    }
  };

  // -----------------------------------------------------------------------
  // handleNameInputBlur()
  // -----------------------------------------------------------------------
  handleNameInputBlur = async (e) => {
    e.persist();
    if (this.state.customerName.toString().trim().length <= 0) {
      await this.setState({ customerName: "" });
    }
  };

  // -----------------------------------------------------------------------
  // handleEmailInputChange()
  // -----------------------------------------------------------------------
  handleEmailInputChange = async (e) => {
    e.persist();
    await this.setState({
      customerEmail: e.target.value.toString(),
    });
    if (this.state.customerEmail.length > 0) {
      this.setState({ isEmailMandatory: false });
    } else {
      this.setState({ isEmailMandatory: this.checkIsEmailMandatory() });
    }
  };

  // -----------------------------------------------------------------------
  // handleUniqueIdInputChange()
  // -----------------------------------------------------------------------
  handleUniqueIdInputChange = async (e) => {
    e.persist();
    await this.setState({
      customerUniqueId: e.target.value.toString(),
    });
  };

  // -----------------------------------------------------------------------
  // handleEmailInputBlur()
  // -----------------------------------------------------------------------
  handleEmailInputBlur = async (e) => {
    e.persist();
    if (this.state.customerEmail.toString().trim().length <= 0) {
      await this.setState({
        showInvalidEmailDiv: false,
        isEmailInvalid: false,
        customerEmail: "",
      });
    } else if (this.state.customerEmail.toString().trim().length > 0) {
      if (validator.isEmail(this.state.customerEmail.toString().trim())) {
        await this.setState({
          showInvalidEmailDiv: false,
          isEmailInvalid: false,
        });
      } else {
        await this.setState({
          showInvalidEmailDiv: true,
          isEmailInvalid: true,
        });
      }
    }
  };

  // -----------------------------------------------------------------------
  // changeDiscountAmount()
  // -----------------------------------------------------------------------
  changeDiscountAmount = async (e) => {
    e.persist();
    this.setState({ isDiscountInvalid: false });
    if (e.target.value.length > 0) {
      if (
        validator.isInt(e.target.value) ||
        validator.isFloat(e.target.value)
      ) {
        await this.setState({
          discountAmount: e.target.value,
          showDiscountExceedsBalanceDiv: false,
        });
      }
    } else {
      await this.setState({
        discountAmount: e.target.value,
        showInvalidDiscountDiv: false,
        isDiscountInvalid: false,
        showDiscountExceedsBalanceDiv: false,
      });
    }
  };

  // -----------------------------------------------------------------------
  // applyDiscount()
  // -----------------------------------------------------------------------
  applyDiscount = async () => {
    let discountAmount = this.state.discountAmount;
    let advanceAmount = this.state.advanceAmount;
    if (discountAmount <= 0) {
      await this.setState({
        showLessThanZero: true,
        isDiscountInvalid: true,
      });
    }

    if (
      this.props.cartData.cart.totalSlotCount >
      this.props.cartData.cart.slots.length
    ) {
      discountAmount /= this.props.cartData.cart.totalSlotCount;
      advanceAmount /= this.props.cartData.cart.totalSlotCount;
    }
    if (
      discountAmount >
      this.props.cartData.cart.slotsNetPrice +
        this.props.cartData.cart.extrasNetPrice
    ) {
      await this.setState({
        showInvalidDiscountDiv: false,
        showDiscountExceedsBalanceDiv: true,
        isDiscountInvalid: true,
      });
    } else {
      let reqBody = {
        discountAmount:
          this.state.discountAmount !== ""
            ? checkIntOrFloat(Number(this.state.discountAmount))
            : 0,
      };
      // if (Number(this.state.discountAmount) > 0) {
      //   if (
      //     Number(this.state.discountAmount) <=
      //     this.props.cartData.cart.slotsNetPrice
      //   ) {
      this.setState({
        showInvalidDiscountDiv: false,
        isDiscountInvalid: false,
        showDiscountExceedsBalanceDiv: false,
      });
      await this.props.dispatch(applyDiscount(reqBody));
      if (this.props.cartToast) {
        if (this.props.cartToast.resourcesNotLoadedMessage) {
          resourcesNotLoadedToast();
        } else if (this.props.cartToast.httpErrorMessage) {
          toaster.danger(this.props.cartToast.httpErrorMessage, {
            id: "HTTP-ERROR-TOAST-4",
          });
        } else if (this.props.cartToast.requestStatus === -1) {
          toaster.notify("Sorry, you don't have permission to do that");
        } else if (this.props.cartToast.requestStatus === 1) {
          // if (this.state.creditsAppliedFlag) {
          //   await this.handleResetCredits();
          // }
          if (Number(this.state.discountAmount) > 0) {
            if (advanceAmount !== "" && advanceAmount > 0) {
              await this.setState({
                advanceAmount: "",
                showAdvanceResetToZeroDiv: true,
                paymentMode: "No Pay",
                transactionMode: 0,
                selectedTransactionMode: 0,
                disableNoPay: false,
              });
            }
            await this.setState({
              discountApplied: Number(this.state.discountAmount),
              showDiscountAppliedDiv: true,
              showDiscountActionDiv: false,
              showInvalidDiscountDiv: false,
              isDiscountInvalid: false,
            });
            if (
              this.props.cartData.cart.cartPrice -
                this.state.creditsApplied -
                discountAmount -
                advanceAmount <
              0
            ) {
              this.setState({
                showInvalidAdvanceDiv: true,
                isAdvanceInvalid: true,
                isConfirmBookingBtnDisabled: true,
              });
            } else {
              this.setState({
                showInvalidAdvanceDiv: false,
                isAdvanceInvalid: false,
                isConfirmBookingBtnDisabled: false,
              });
            }
          }
        } else if (this.props.cartToast.requestStatus === 0) {
          toaster.danger(this.props.cartToast.message, {
            id: "HTTP-ERROR-TOAST-4",
          });
        }
      }
    }
    // else {
    //   await this.setState({
    //     showDiscountAppliedDiv: false,
    //     showDiscountActionDiv: true,
    //     showInvalidDiscountDiv: true,
    //     showDiscountExceedsBalanceDiv: false,
    //     isDiscountInvalid: true
    //   });
    // }
    // }
    // }
  };

  // -----------------------------------------------------------------------
  // resetDiscount()
  // -----------------------------------------------------------------------
  resetDiscount = async (e) => {
    let discountAmount = this.state.discountAmount;
    let advanceAmount = this.state.advanceAmount;
    let creditsApplied = this.state.creditsApplied;
    if (
      this.props.cartData.cart &&
      this.props.cartData.cart.totalSlotCount > this.props.cartData.cart &&
      this.props.cartData.cart.slots.length
    ) {
      discountAmount /= this.props.cartData.cart.totalSlotCount;
      advanceAmount /= this.props.cartData.cart.totalSlotCount;
      creditsApplied /= this.props.cartData.cart.totalSlotCount;
    }
    await this.props.dispatch(resetDiscount());
    if (this.props.cartToast) {
      if (this.props.cartToast.resourcesNotLoadedMessage) {
        resourcesNotLoadedToast();
      } else if (this.props.cartToast.httpErrorMessage) {
        toaster.danger(this.props.cartToast.httpErrorMessage, {
          id: "HTTP-ERROR-TOAST-4",
        });
      } else if (this.props.cartToast.requestStatus === -1) {
        toaster.notify("Sorry, you don't have permission to do that");
      } else if (this.props.cartToast.requestStatus === 1) {
        if (this.state.creditsAppliedFlag) {
          await this.handleResetCredits();
        }
        if (this.state.advanceAmount !== "" && this.state.advanceAmount > 0) {
          await this.setState({
            advanceAmount: "",
            showAdvanceResetToZeroDiv: true,
            paymentMode: "No Pay",
            transactionMode: 0,
            selectedTransactionMode: 0,
            disableNoPay: false,
          });
        }
        await this.setState({
          discountAmount: "",
          showDiscountAppliedDiv: false,
          showDiscountActionDiv: true,
        });
        await this.setState({ discountApplied: 0 });
        if (
          this.props.cartData.cart.cartPrice +
            creditsApplied -
            discountAmount -
            advanceAmount <
          0
        ) {
          this.setState({
            showInvalidAdvanceDiv: true,
            isAdvanceInvalid: true,
            isConfirmBookingBtnDisabled: true,
          });
        } else {
          this.setState({
            showInvalidAdvanceDiv: false,
            isAdvanceInvalid: false,
            isConfirmBookingBtnDisabled: false,
          });
        }
      } else if (this.props.cartToast.requestStatus === 0) {
        toaster.danger(this.props.cartToast.message, {
          id: "HTTP-ERROR-TOAST-4",
        });
      }
    }
  };

  // -----------------------------------------------------------------------
  // handleAdvanceInput()
  // -----------------------------------------------------------------------
  handleAdvanceInput = async (e) => {
    e.persist();
    const cartData = this.props.cartData;
    let discountAmount = this.state.discountAmount;
    let advanceAmount = e.target.value;
    let creditsApplied = this.state.creditsApplied;
    if (cartData.cart.totalSlotCount > cartData.cart.slots.length) {
      discountAmount /= cartData.cart.totalSlotCount;
      advanceAmount /= cartData.cart.totalSlotCount;
      creditsApplied /= cartData.cart.totalSlotCount;
    }
    if (validateCreditsAndPrice(e.target.value) === "set") {
      if (
        advanceAmount <=
        this.props.cartData.cart.cartPrice -
          creditsApplied -
          discountAmount -
          cartData.cart.extrasGrossPrice
      ) {
        await this.setState({
          advanceAmount: e.target.value,
          showInvalidAdvanceDiv: false,
          isAdvanceInvalid: false,
          isConfirmBookingBtnDisabled: false,
        });
      } else {
        await this.setState({
          showInvalidAdvanceDiv: true,
        });
      }
      if (
        this.state.selectedTransactionMode === 0 &&
        this.state.advanceAmount > 0
      ) {
        await this.setState({
          paymentMode: "Cash",
          transactionMode: 1,
          selectedTransactionMode: 1,
          disableNoPay: true,
        });
      }
      if (this.state.advanceAmount <= 0) {
        await this.setState({
          paymentMode: "No Pay",
          transactionMode: 0,
          selectedTransactionMode: 0,
          disableNoPay: false,
        });
      }
    } else if (validateCreditsAndPrice(e.target.value) === "clear") {
      await this.setState({
        advanceAmount: "",
        showInvalidAdvanceDiv: false,
        isAdvanceInvalid: false,
        isConfirmBookingBtnDisabled: false,
        paymentMode: "No Pay",
        transactionMode: 0,
        selectedTransactionMode: 0,
        disableNoPay: false,
      });
    }
    await this.setState({ showAdvanceResetToZeroDiv: false });
  };

  // -----------------------------------------------------------------------
  // handlebookingRemarksInput()
  // -----------------------------------------------------------------------
  handlebookingRemarksInput = async (e) => {
    e.persist();
    await this.setState({ bookingRemarks: e.target.value });
  };

  // -----------------------------------------------------------------------
  // handleBookingRemarksInputBlur()
  // -----------------------------------------------------------------------
  handleBookingRemarksInputBlur = async (e) => {
    e.persist();
    if (this.state.bookingRemarks.toString().trim().length <= 0) {
      await this.setState({ bookingRemarks: "" });
    }
  };

  // -----------------------------------------------------------------------
  // onCountryChange()
  // -----------------------------------------------------------------------
  onCountryChange = (callingCode) => {
    this.setState({ countryCode: callingCode });
  };

  // -----------------------------------------------------------------------
  // handleResetForm()
  // -----------------------------------------------------------------------
  handleResetForm = async () => {
    await this.props.dispatch({
      type: "SET_CUSTOMER_DETAILS",
      payload: null,
    });
    await this.props.dispatch(clearCart());
    if (this.props.cartToast) {
      if (this.props.cartToast.resourcesNotLoadedMessage) {
        resourcesNotLoadedToast();
      } else if (this.props.cartToast.httpErrorMessage) {
        toaster.danger(this.props.cartToast.httpErrorMessage, {
          id: "HTTP-ERROR-TOAST-4",
        });
      } else if (this.props.cartToast.requestStatus === -1) {
        toaster.notify("Sorry, you don't have permission to do that");
      } else if (this.props.cartToast.requestStatus === 1) {
        await this.setState({
          sendPaymentLinkIsChecked: false,
          sendConfirmationSmschecked: false,
          memberPin: "",
          customerName: "",
          customerEmail: "",
          customerMobile: "",
          customerUniqueId: "",
          customerUniqueIdExists: "",
          customerCredits: 0,
          showExtrasDialog: false,
          discountAmount: "",
          discountApplied: 0,
          showDiscountAppliedDiv: false,
          creditsApplied: 0,
          showRedeemMsg: false,
          showDiscountActionDiv: true,
          showCreditsActionDiv: true,
          showCreditsAppliedDiv: false,
          advanceAmount: "",
          bookingRemarks: "",
          countryCode: checkClubCountryCallingCodeExists()
            ? getPlayoClubCountryCallingCode()
            : "+91",
          showInvalidEmailDiv: false,
          showInvalidMobileDiv: false,
          showConfirmDismissDialog: false,
          isMobileInvalid: false,
          mobileIsRequiredMsg: false,
          isNameInvalid: false,
          nameIsRequiredMsg: false,
          isEmailInvalid: false,
          showInvalidDiscountDiv: false,
          isDiscountInvalid: false,
          showInvalidAdvanceDiv: false,
          isAdvanceInvalid: false,
          isConfirmBookingBtnDisabled: false,
          isMemberPinInvalid: false,
          paymentMode: "No Pay",
          transactionMode: 0,
          selectedTransactionMode: 0,
          showDiscountExceedsBalanceDiv: false,
          showCreditsExceedsBalanceDiv: false,
          disableNoPay: false,
          showAdvanceResetToZeroDiv: false,
          creditsAppliedFlag: false,
          showRedeemCreditsPopover: true,
          disableNameAndEmailEdit: false,
          isEmailMandatory: false,
          showLessThanZero: false,
          isDiscountInvalid: false,
        });
        await this.props.handleDrawer("close");
        await this.props.handleBookingCheckoutDisplay("hide");
      } else if (this.props.cartToast.requestStatus === 0) {
        toaster.danger(this.props.bookingToast.message, {
          id: "BC-TOAST",
        });
      }
    }
  };

  // -----------------------------------------------------------------------
  // handleDismissAction()
  // -----------------------------------------------------------------------
  handleDismissAction = async (e) => {
    e.persist();

    await this.setState({
      showConfirmDismissDialog: true,
      showLessThanZero: false,
      isDiscountInvalid: false,
    });
  };

  // -----------------------------------------------------------------------
  // changeTransactionMode()
  // -----------------------------------------------------------------------
  changeTransactionMode = (e) => {
    e.persist();
    if (this.state.advanceAmount.length > 0) {
      this.setState({
        paymentMode:
          e.nativeEvent.target[
            e.nativeEvent.target.selectedIndex
          ].text.toString(),
        transactionMode: parseInt(e.target.value),
        selectedTransactionMode: parseInt(e.target.value),
        isAdvanceInvalid: false,
      });
    } else {
      this.setState({ isAdvanceInvalid: true });
    }
  };

  // -----------------------------------------------------------------------
  // onMemberPinPopoverClose()
  // -----------------------------------------------------------------------
  onMemberPinPopoverClose = () => {
    this.setState({ isMemberPinInvalid: false, showRedeemMsg: false });
  };
  // -----------------------------------------------------------------------
  // handleConfirmBooking()
  // -----------------------------------------------------------------------
  handleConfirmBooking = async (e) => {
    e.persist();
    if (
      this.state.customerName !== "" &&
      !this.state.isNameInvalid &&
      this.state.customerMobile !== "" &&
      !this.state.isMobileInvalid &&
      !this.state.isEmailInvalid &&
      !this.state.isDiscountInvalid &&
      !this.state.isAdvanceInvalid &&
      !(this.checkIsEmailMandatory() && this.state.customerEmail.length <= 0)
    ) {
      let reqBody = {
        coupon: null,
        toBeRegistered:
          this.props.customerDetails &&
          this.props.customerDetails.toBeRegistered,
        memberId:
          this.props.customerDetails && this.props.customerDetails.isMember
            ? this.props.customerDetails.customerDetails.id
            : null,
        nonMemberId:
          this.props.customerDetails && this.props.customerDetails.isNonMember
            ? this.props.customerDetails.customerDetails.id
            : null,
        paymentMode: this.state.paymentMode,
        bookingRemarks: this.state.bookingRemarks.toString().trim(),
        totalPaidAmount:
          this.state.advanceAmount === ""
            ? 0
            : checkIntOrFloat(this.state.advanceAmount),
        grossAmount: this.props.cartData.cart.cartPrice,
        clubDiscount: this.state.discountApplied,
        credits: this.state.creditsApplied,
        customerDetails: {
          name: this.state.customerName.toString().trim(),
          countryCode: this.state.countryCode,
          mobile: this.state.customerMobile.toString().trim(),
          email: this.state.customerEmail.toString().trim(),
          additionalInfo: "",
          company: "",
          uniqueId: this.state.customerUniqueId.toString().trim(),
        },
        isPatternBooking: this.props.bulkBookingInformation
          ? this.props.bulkBookingInformation.isPatternBooking
          : false,
        patternBookingData: this.props.bulkBookingInformation
          ? this.props.bulkBookingInformation.patternBookingData
          : {},
        transactionData: {
          type: this.props.bulkBookingInformation ? 2 : 1,
          mode: this.state.transactionMode,
        },
        sendSMS: this.state.sendConfirmationSmschecked,
        sendPaymentLink: this.state.sendPaymentLinkIsChecked,
      };
      await this.props.dispatch(
        this.props.bulkBookingInformation
          ? patternBooking(reqBody)
          : booking(reqBody)
      );
      if (!this.props.bulkBookingInformation && this.props.bookingToast) {
        if (this.props.bookingToast.resourcesNotLoadedMessage) {
          resourcesNotLoadedToast();
        } else if (
          this.props.bookingToast.httpErrorMessage &&
          this.props.bookingToast.statusCode === 404
        ) {
          toaster.danger(this.props.bookingToast.httpErrorMessage, {
            id: "HTTP-ERROR-TOAST-4",
          });
          await this.setState({ isConfirmBookingBtnDisabled: true });
          setTimeout(() => {
            this.setState({ isConfirmBookingBtnDisabled: false });
          }, 3000);
        } else if (this.props.bookingToast.httpErrorMessage) {
          toaster.danger(this.props.bookingToast.httpErrorMessage, {
            id: "HTTP-ERROR-TOAST-4",
          });
        } else if (this.props.bookingToast.requestStatus === -1) {
          toaster.notify("Sorry, you don't have permission to do that");
        } else if (this.props.bookingToast.requestStatus === 1) {
          this.props.resetCartCount();
          this.props.updateCalendarPage();
          await this.handleResetForm();
          // 					let body = {
          // 						activityId: this.props.bookingSlot.activityId,
          // 						slot: this.props.bookingSlot.slotTime,
          // 						date: toDateFormat1(this.props.bookDate),
          // 						courtId: this.props.bookingSlot.courtId,
          // 					};
          // console.log({body})
          //await this.props.dispatch(getActivityTypeOneDetails(body));

          await toaster.success(this.props.bookingToast.message, {
            id: "BC-TOAST",
          });
          if (
            this.props.bookingToast.bookingId &&
            this.props.bookingToast.bookingId !== ""
          ) {
            await this.props.handleGetBookingDetails(
              this.props.bookingToast.bookingId
            );
          }
        } else if (this.props.bookingToast.requestStatus === 0) {
          toaster.danger(this.props.bookingToast.message, {
            id: "BC-TOAST",
          });
        }
      } else if (
        this.props.bulkBookingInformation &&
        this.props.patternBookingToast
      ) {
        if (this.props.patternBookingToast.resourcesNotLoadedMessage) {
          resourcesNotLoadedToast();
        } else if (
          this.props.patternBookingToast.httpErrorMessage &&
          this.props.patternBookingToast.statusCode === 404
        ) {
          toaster.danger(this.props.patternBookingToast.httpErrorMessage, {
            id: "HTTP-ERROR-TOAST-4",
          });
          await this.setState({ isConfirmBookingBtnDisabled: true });
          setTimeout(() => {
            this.setState({ isConfirmBookingBtnDisabled: false });
          }, 3000);
        } else if (this.props.patternBookingToast.httpErrorMessage) {
          toaster.danger(this.props.patternBookingToast.httpErrorMessage, {
            id: "HTTP-ERROR-TOAST-4",
          });
        } else if (this.props.patternBookingToast.requestStatus === -1) {
          toaster.notify("Sorry, you don't have permission to do that");
        } else if (this.props.patternBookingToast.requestStatus === 1) {
          this.props.dispatch({
            type: "SET_BULK_BOOKING_INFORMATION",
            payload: null,
          });
          await this.props.resetCartCount();
          await this.handleResetForm();
          await toaster.success(this.props.patternBookingToast.message, {
            id: "BC-TOAST",
          });
          if (
            this.props.patternBookingToast.patternBookingId &&
            this.props.patternBookingToast.patternBookingId !== ""
          ) {
            await this.props.handleGetIndividualBulkBookingDetails(
              this.props.patternBookingToast.patternBookingId
            );
          }
        } else if (this.props.patternBookingToast.requestStatus === 0) {
          toaster.danger(this.props.patternBookingToast.message, {
            id: "BC-TOAST",
          });
        }
      }
    } else {
      if (this.state.isAdvanceInvalid) {
        await this.smoothScrollToElement("invalidAdvance");
      }
      if (this.state.isDiscountInvalid) {
        await this.smoothScrollToElement("invalidDiscount");
      }
      if (this.state.isEmailInvalid) {
        await this.smoothScrollToElement("invalidEmail");
      }
      if (this.state.customerName === "") {
        await this.smoothScrollToElement("invalidName");
        this.setState({ nameIsRequiredMsg: true });
        this.setState({ isNameInvalid: true });
      }
      if (this.state.customerMobile === "" || this.state.isMobileInvalid) {
        await this.smoothScrollToElement("invalidMobile");
        await this.setState({
          isMobileInvalid: true,
          mobileIsRequiredMsg: this.state.customerMobile === "",
        });
      }
      if (
        this.checkIsEmailMandatory() &&
        this.state.customerEmail.length <= 0
      ) {
        await this.smoothScrollToElement("invalidEmail");
        await this.setState({
          isEmailMandatory: true,
        });
      }
    }
  };

  // -----------------------------------------------------------------------
  // handleExtrasDialog()
  // -----------------------------------------------------------------------
  handleExtrasDialog = async (type) => {
    let { creditsApplied, discountApplied } = this.state;
    if (creditsApplied > 0) {
      this.handleResetCredits();
    }

    let body = {
      activityId: this.props.cartData.cart.slots[0].activityId,
    };
    if (discountApplied > 0) {
      this.resetDiscount();
    }
    if (type === "open") {
      this.props.dispatch(getClubExtrasList(body));
      if (this.state.customerMobile !== "" && !this.state.isMobileInvalid) {
        this.setState({ showExtrasDialog: true });
      } else {
        await this.smoothScrollToElement("invalidMobile");
        await this.setState({
          isMobileInvalid: true,
          mobileIsRequiredMsg: this.state.customerMobile === "",
        });
      }
    } else if (type === "close") {
      await this.props.dispatch({ type: "SET_CUSTOMER_DETAILS", payload: {} });
      this.setState({ showExtrasDialog: false });
    }
  };

  // -----------------------------------------------------------------------
  // render()
  // -----------------------------------------------------------------------
  render() {
    const {
      customerName,
      customerEmail,
      customerMobile,
      customerUniqueId,
      customerUniqueIdExists,
      sendConfirmationSmschecked,
      showExtrasDialog,
      showDiscountAppliedDiv,
      creditsApplied,
      discountAmount,
      discountApplied,
      showDiscountActionDiv,
      showCreditsActionDiv,
      showCreditsAppliedDiv,
      advanceAmount,
      showInvalidEmailDiv,
      showInvalidMobileDiv,
      mobileIsRequiredMsg,
      bookingRemarks,
      showConfirmDismissDialog,
      isMobileInvalid,
      isNameInvalid,
      nameIsRequiredMsg,
      isEmailInvalid,
      showInvalidDiscountDiv,
      showLessThanZero,
      isDiscountInvalid,
      showInvalidAdvanceDiv,
      isAdvanceInvalid,
      isConfirmBookingBtnDisabled,
      isMemberPinInvalid,
      showRedeemMsg,
      showDiscountExceedsBalanceDiv,
      showCreditsExceedsBalanceDiv,
      disableNoPay,
      selectedTransactionMode,
      showAdvanceResetToZeroDiv,
      showRedeemCreditsPopover,
      disableNameAndEmailEdit,
      isEmailMandatory,
      sendPaymentLinkIsChecked,
    } = this.state;
    const { customerDetails, cartData } = this.props;
    const reqMobFieldLabel = (
      <Text id="BC-A1-input-field-label">
        contact number<font color="#EC4C47">&nbsp;*</font>
      </Text>
    );
    const reqNameFieldLabel = (
      <Text id="BC-A1-input-field-label">
        name<font color="#EC4C47">&nbsp;*</font>
      </Text>
    );
    const reqEmailFieldLabel = (
      <Text id="BC-A1-input-field-label">
        email<font color="#EC4C47">&nbsp;*</font>
      </Text>
    );
    return (
      <React.Fragment>
        <div className="BC-A1-wrapper">
          <div className="BC-A1-header-container">
            <Navbar />
            <PageHeadingAndActionsNav
              headingText={"Booking Checkout"}
              handleBookingCheckoutPageDisplay={
                this.handleBookingCheckoutPageDisplay
              }
            />
            <Dialog
              width={560}
              isShown={showConfirmDismissDialog}
              title="Warning"
              hasFooter={false}
              onCloseComplete={(e) =>
                this.handleConfirmDismissDialog(e, "hide-dialog")
              }
            >
              <div className="BC-A1-confirm-dismiss-dialog-wrapper">
                <div className="BC-A1-confirm-dismiss-dialog-body-container">
                  <Text id="BC-A1-confirm-dismiss-dialog-message">
                    All slots in the cart will be cleared. Do you wish to
                    continue?
                  </Text>
                </div>
                <div className="BC-A1-confirm-dismiss-dialog-footer-container">
                  <Button
                    className="BC-A1-button"
                    marginRight={12}
                    width={72}
                    height={32}
                    onClick={(e) =>
                      this.handleConfirmDismissDialog(e, "hide-dialog")
                    }
                  >
                    Cancel
                  </Button>
                  <Button
                    className="BC-A1-button"
                    width={88}
                    height={32}
                    appearance="primary"
                    intent="danger"
                    onClick={(e) =>
                      this.handleConfirmDismissDialog(e, "confirm")
                    }
                  >
                    Clear Cart
                  </Button>
                </div>
              </div>
            </Dialog>
          </div>
          {cartData && cartData.cart && (
            <div className="BC-A1-body-container">
              <div
                className="BC-A1-booking-checkout-form-container"
                ref={"scrollTopPosition"}
              >
                <div className="BC-A1-booking-checkout-form-left-panel-container">
                  <div className="BC-A1-left-panel-col-content BC-A1-margin-top-32">
                    <div className="BC-A1-big-label-container">
                      <Text id="BC-A1-big-label">Customer Details</Text>
                    </div>
                    <div className="BC-A1-col-container BC-A1-margin-top-17">
                      <div className="BC-A1-input-field-label-container BC-A1-margin-bottom-8">
                        {reqMobFieldLabel}
                      </div>
                      <div className="BC-A1-row-container">
                        <div className="BC-A1-country-select-menu-container BC-A1-margin-right-8">
                          <CountrySelectMenu
                            onCountryChange={this.onCountryChange}
                          />
                        </div>
                        <div
                          className="BC-A1-text-input-field-container"
                          ref={"invalidMobile"}
                        >
                          <TextInput
                            className="BC-A1-text-input-field"
                            width={192}
                            height={32}
                            value={customerMobile}
                            placeholder="Mobile"
                            onBlur={(e) => this.onBlurOfMobileInputField(e)}
                            onChange={(e) => this.onChangeOfMobileInputField(e)}
                            isInvalid={isMobileInvalid}
                          />
                        </div>
                      </div>
                      {showInvalidMobileDiv && (
                        <div className="BC-A1-invalid-input-msg-container">
                          <InlineAlert
                            className="BC-A1-inline-alert"
                            intent="danger"
                          >
                            <Text id="BC-A1-invalid-input-msg">
                              Invalid mobile number
                            </Text>
                          </InlineAlert>
                        </div>
                      )}
                      {mobileIsRequiredMsg && (
                        <div className="BC-A1-invalid-input-msg-container">
                          <InlineAlert
                            className="BC-A1-inline-alert"
                            intent="danger"
                          >
                            <Text id="BC-A1-invalid-input-msg">
                              Mobile is required
                            </Text>
                          </InlineAlert>
                        </div>
                      )}
                    </div>
                    <div className="BC-A1-row-container BC-A1-margin-top-32">
                      <div
                        className="BC-A1-text-input-field-container"
                        ref={"invalidName"}
                      >
                        <TextInputField
                          className="BC-A1-text-input-field"
                          label={reqNameFieldLabel}
                          width={336}
                          height={32}
                          value={customerName}
                          onChange={(e) => this.handleNameInputChange(e)}
                          onBlur={(e) => this.handleNameInputBlur(e)}
                          isInvalid={isNameInvalid}
                          disabled={disableNameAndEmailEdit}
                          validationMessage={
                            nameIsRequiredMsg ? "Name is required" : false
                          }
                        />
                      </div>
                    </div>
                    <div className="BC-A1-col-container BC-A1-margin-top-32">
                      <div
                        className="BC-A1-text-input-field-container"
                        ref={"invalidEmail"}
                      >
                        <TextInputField
                          className="BC-A1-text-input-field"
                          label={reqEmailFieldLabel}
                          width={336}
                          height={32}
                          value={customerEmail}
                          onChange={(e) => this.handleEmailInputChange(e)}
                          onBlur={(e) => this.handleEmailInputBlur(e)}
                          disabled={disableNameAndEmailEdit}
                          isInvalid={isEmailInvalid}
                          validationMessage={
                            !customerEmail.length && isEmailMandatory
                              ? "Email is required"
                              : false
                          }
                        />
                      </div>
                      {showInvalidEmailDiv && (
                        <div className="BC-A1-invalid-input-msg-container">
                          <InlineAlert
                            className="BC-A1-inline-alert"
                            intent="danger"
                          >
                            <Text id="BC-A1-invalid-input-msg">
                              Invalid email
                            </Text>
                          </InlineAlert>
                        </div>
                      )}
                    </div>
                    {/*{*/}
                    {/*getPlayoClubCountryCallingCode() === "+974" &&*/}
                    {/*<div className="BC-A1-col-container BC-A1-margin-top-32">*/}
                    {/*<div*/}
                    {/*className="BC-A1-text-input-field-container"*/}
                    {/*ref={"invalidEmail"}*/}
                    {/*>*/}
                    {/*<TextInputField*/}
                    {/*className="BC-A1-text-input-field"*/}
                    {/*label="Unique ID"*/}
                    {/*width={336}*/}
                    {/*height={32}*/}
                    {/*value={customerUniqueId}*/}
                    {/*onChange={e => this.handleUniqueIdInputChange(e)}*/}
                    {/*disabled={customerUniqueIdExists}*/}
                    {/*/>*/}
                    {/*</div>*/}
                    {/*</div>*/}
                    {/*}*/}
                    <div className="BC-A1-big-label-container BC-A1-margin-top-48">
                      <Text id="BC-A1-big-label">Booking Remarks</Text>
                    </div>
                    <div className="BC-A1-row-container BC-A1-margin-top-8">
                      <div className="BC-A1-text-input-field-container">
                        <Textarea
                          className="BC-A1-text-input-field"
                          style={{ resize: "none" }}
                          width={336}
                          height={80}
                          placeholder="Remarks"
                          maxLength="100"
                          value={bookingRemarks}
                          onChange={(e) => this.handlebookingRemarksInput(e)}
                          onBlur={(e) => this.handleBookingRemarksInputBlur(e)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="BC-A1-booking-checkout-form-right-panel-container">
                  <div className="BC-A1-right-panel-col-content BC-A1-margin-top-32">
                    <div className="BC-A1-big-label-container">
                      <Strong color="muted" size={400}>
                        PAYMENT DETAILS
                      </Strong>
                    </div>
                    <div className="BC-A1-row-container BC-A1-margin-top-16">
                      <div className="BC-A1-right-panel-left-row-max-156">
                        <Text id="BC-A1-medium-dark-label">Gross</Text>{" "}
                        <Text size={500}> (Court Price)</Text>
                      </div>
                      <div className="BC-A1-right-panel-right-row-max-156 BC-A1-text-align-right">
                        <Text id="BC-A1-medium-dark-label">
                          {`${generateCurrencySymbol()} ${checkIntOrFloat(
                            cartData.cart.slotsGrossPrice
                          )}`}
                        </Text>
                      </div>
                    </div>

                    {/* *******Extra Component----- start


										{/* <div className="BC-A1-row-container BC-A1-margin-top-16">
											<div className="BC-A1-right-panel-left-row-max-156">
												<Text id="BC-A1-medium-dark-label">Accessories</Text>
											</div>
											<div className="BC-A1-right-panel-right-row-max-156 BC-A1-text-align-right">
												<Text id="BC-A1-medium-light-label">
													{`${generateCurrencySymbol()} ${
														cartData.cart && cartData.cart.extrasGrossPrice
															? checkIntOrFloat(cartData.cart.extrasGrossPrice)
															: 0
													}`}
												</Text>
											</div>
										</div> */}
                    {/* <div className="BC-A1-reverse-row-container BC-A1-margin-top-8">
											<Dialog
												width={560}
												// shouldCloseOnOverlayClick={false}
												isShown={showExtrasDialog}
												title="Accessories"
												hasFooter={false}
												onCloseComplete={() => this.handleExtrasDialog("close")}
											>
												<ExtrasActions
													mobile={this.state.mobile}
													creditsExpiry={
														this.props.customerDetails &&
														this.props.customerDetails.customerDetails
															.creditsExpiry
													}
													credits={
														cartData.cart &&
														cartData.cart.customerAvailableCredits
													}
												/>
											</Dialog>
											<Button
												className="BC-A1-button"
												width={"auto"}
												padding={5}
												height={32}
												iconBefore="add"
												appearance="minimal"
												onClick={() => this.handleExtrasDialog("open")}
											>
												{this.props.cartData.cart.extras &&
												this.props.cartData.cart.extras.length > 0
													? "Edit Accessories"
													: "Add Accessories"}
											</Button>
										</div> */}

                    {/* *******Extra Component----- end  */}

                    <div className="BC-A1-horizontal-line-decorator BC-A1-margin-top-8" />
                    {/* <div className="BC-A1-row-container BC-A1-margin-top-16">
											<div className="BC-A1-right-panel-left-row-max-156">
												<Text id="BC-A1-medium-dark-label">Gross</Text>
											</div>
											<div className="BC-A1-right-panel-right-row-max-156 BC-A1-text-align-right">
												<Text id="BC-A1-medium-light-label">
													{` ${generateCurrencySymbol()} ${checkIntOrFloat(
														cartData.cart &&
															cartData.cart.cartPrice &&
															cartData.cart.cartPrice
													)}`}
												</Text>
											</div>
										</div> */}
                    <div className="BC-A1-col-container BC-A1-margin-top-24">
                      {showDiscountAppliedDiv && (
                        <div className="BC-A1-success-msg-container">
                          <div className="BC-A1-success-msg-left-pane">
                            <Icon
                              icon={<TickCircleIcon />}
                              color="success"
                              size={14}
                              marginRight={12}
                            />
                            <Text size={300}> Discount applied </Text>
                            <IconButton
                              appearance="minimal"
                              icon={<CrossIcon />}
                              intent="danger"
                              iconSize={16}
                              onClick={(e) => this.resetDiscount(e)}
                            />
                          </div>
                          <div className="BC-A1-success-msg-right-pane">
                            <Text id="BC-A1-medium-dark-label">
                              {` ${generateCurrencySymbol()} ${checkIntOrFloat(
                                discountApplied
                              )}`}
                            </Text>
                          </div>
                        </div>
                      )}
                      {showDiscountActionDiv && (
                        <div className="BC-A1-col-container">
                          <div className="BC-A1-row-344-container">
                            <Select width={98} height={32} marginRight={8}>
                              <option value="discount" checked>
                                Discount
                              </option>
                            </Select>
                            <div
                              className="BC-A1-text-input-field-container"
                              ref={"invalidDiscount"}
                            >
                              <TextInput
                                className="BC-A1-text-input-field"
                                width={150}
                                height={32}
                                placeholder="Amount"
                                value={discountAmount}
                                onChange={(e) => this.changeDiscountAmount(e)}
                                isInvalid={isDiscountInvalid}
                              />
                            </div>
                            <Button
                              className="border-button"
                              width={70}
                              height={32}
                              marginLeft={8}
                              appearance="minimal"
                              onClick={(e) => this.applyDiscount(e)}
                            >
                              <Strong color={" #0679c5"}>Apply</Strong>
                            </Button>
                          </div>
                          {showInvalidDiscountDiv && (
                            <div className="BC-A1-invalid-input-msg-container">
                              <InlineAlert
                                className="BC-A1-inline-alert"
                                intent="danger"
                              >
                                <Text id="BC-A1-invalid-input-msg">
                                  Discount cannot be greater than slots net
                                  price
                                </Text>
                              </InlineAlert>
                            </div>
                          )}
                          {showLessThanZero && (
                            <div className="BC-A1-invalid-input-msg-container">
                              <InlineAlert
                                className="BC-A1-inline-alert"
                                intent="danger"
                              >
                                <Text id="BC-A1-invalid-input-msg">
                                  Discount should be greater than Rs. 0
                                </Text>
                              </InlineAlert>
                            </div>
                          )}
                          {showDiscountExceedsBalanceDiv && (
                            <div className="BC-A1-invalid-input-msg-container">
                              <InlineAlert
                                className="BC-A1-inline-alert"
                                intent="danger"
                              >
                                <Text id="BC-A1-invalid-input-msg">
                                  Discount cannot be applied as it exceeds slots
                                  net price
                                </Text>
                              </InlineAlert>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    <div className="BC-A1-horizontal-line-decorator BC-A1-margin-top-16" />
                    <div className="BC-A1-row-container BC-A1-margin-top-24">
                      <div className="BC-A1-right-panel-left-row-max-156">
                        <Strong size={400}>Net Price</Strong>
                      </div>
                      <div className="BC-A1-right-panel-right-row-max-156 BC-A1-text-align-right">
                        <Text
                          id="BC-A1-bigger-label"
                          id="BC-A1-medium-dark-label"
                        >
                          {`${generateCurrencySymbol()} ${checkIntOrFloat(
                            cartData.cart.cartPrice -
                              creditsApplied -
                              discountApplied
                          )}`}
                        </Text>
                      </div>
                    </div>

                    {/* <div className="BC-A1-big-label-container BC-A1-margin-top-24">
											<Text id="BC-A1-big-label">Payment Details</Text>
										</div> */}
                    {customerDetails &&
                      customerDetails.isMember &&
                      customerDetails.customerDetails.credits > 0 &&
                      customerDetails &&
                      customerDetails.customerDetails.customerMobile && (
                        <div className=" BC-A1-horizontal-line-decorator  BC-A1-col-container BC-A1-margin-top-8">
                          <React.Fragment>
                            <div style={{ marginTop: "12px" }}>
                              {showCreditsAppliedDiv && (
                                <div className="BC-A1-success-msg-container ">
                                  <div className="BC-A1-success-msg-left-pane">
                                    <Icon
                                      icon={<TickCircleIcon />}
                                      color="success"
                                      size={14}
                                      marginRight={12}
                                    />
                                    <Text id="BC-A1-medium-dark-label">
                                      {`Credits applied - ${generateCurrencySymbol()} ${checkIntOrFloat(
                                        creditsApplied
                                      )}`}
                                    </Text>
                                  </div>
                                  <div className="BC-A1-success-msg-right-pane">
                                    <IconButton
                                      appearance="minimal"
                                      icon={<CrossIcon />}
                                      intent="danger"
                                      iconSize={16}
                                      onClick={(e) => this.handleResetCredits()}
                                    />
                                  </div>
                                </div>
                              )}
                              {showCreditsActionDiv &&
                                customerDetails &&
                                customerDetails.customerDetails
                                  .customerMobile && (
                                  <div className="BC-A1-col-container">
                                    <div className="BC-A1-row-344-container">
                                      <div className="BC-A1-width-120 BC-A1-margin-right-8">
                                        <Text id="BC-A1-small-light-label">
                                          Credits Available
                                        </Text>
                                      </div>
                                      <div className="BC-A1-width-88 BC-A1-margin-right-8 BC-A1-word-break-all">
                                        <Text id="BC-A1-medium-dark-label">
                                          {customerDetails &&
                                            checkIntOrFloat(
                                              customerDetails.customerDetails
                                                .credits
                                            )}
                                        </Text>
                                      </div>
                                      <div className="BC-A1-redeem-credits-button-container">
                                        {showRedeemCreditsPopover && (
                                          <Popover
                                            onOpen={() =>
                                              this.setState({
                                                showCreditsExceedsBalanceDiv: false,
                                              })
                                            }
                                            onClose={() =>
                                              this.onMemberPinPopoverClose()
                                            }
                                            content={({ close }) => (
                                              <Pane
                                                width={394}
                                                height={224}
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="center"
                                                flexDirection="column"
                                              >
                                                <div className="BC-A1-redeem-credits-popover-container">
                                                  <div className="BC-A1-redeem-credits-cross-btn-container">
                                                    <IconButton
                                                      icon={<CrossIcon />}
                                                      height={32}
                                                      onClick={close}
                                                      appearance="minimal"
                                                    />
                                                  </div>
                                                  <div className="BC-A1-redeem-credits-top-div">
                                                    <Text id="BC-A1-avail-member-credits-text">
                                                      Avail Member Credits
                                                    </Text>
                                                    <TextInputField
                                                      width={280}
                                                      height={32}
                                                      type="password"
                                                      autoComplete="new-password"
                                                      placeholder="Enter Member Pin"
                                                      value={
                                                        this.state.memberPin
                                                      }
                                                      isInvalid={
                                                        isMemberPinInvalid
                                                      }
                                                      validationMessage={
                                                        showRedeemMsg &&
                                                        this.props.cartToast
                                                          .message
                                                      }
                                                      onChange={(e) =>
                                                        this.handleRedeemCreditsPinInput(
                                                          e
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                  <div className="BC-A1-redeem-credits-action-btn-container">
                                                    <Button
                                                      className="BC-A1-button"
                                                      width={72}
                                                      height={32}
                                                      marginRight={12}
                                                      onClick={(e) =>
                                                        this.handleRedeemCreditsClearInput()
                                                      }
                                                    >
                                                      Clear
                                                    </Button>
                                                    <Button
                                                      className="BC-A1-button"
                                                      width={100}
                                                      height={32}
                                                      appearance="primary"
                                                      onClick={(e) =>
                                                        this.handleRedeemCredits(
                                                          e
                                                        )
                                                      }
                                                    >
                                                      Redeem
                                                    </Button>
                                                  </div>
                                                </div>
                                              </Pane>
                                            )}
                                            position={Position.TOP_RIGHT}
                                          >
                                            <Button
                                              marginLeft={20}
                                              className="border-button"
                                              width={95}
                                              height={32}
                                              appearance="minimal"
                                              disabled={showInvalidAdvanceDiv}
                                            >
                                              <Strong color={" #0679c5"}>
                                                Redeem
                                              </Strong>
                                            </Button>
                                          </Popover>
                                        )}
                                      </div>
                                    </div>
                                    {showCreditsExceedsBalanceDiv && (
                                      <div className="BC-A1-invalid-input-msg-container">
                                        <InlineAlert
                                          className="BC-A1-inline-alert"
                                          intent="danger"
                                        >
                                          <Text id="BC-A1-invalid-input-msg">
                                            Credits cannot be redeemed as slots
                                            net price is 0
                                          </Text>
                                        </InlineAlert>
                                      </div>
                                    )}
                                  </div>
                                )}
                            </div>
                          </React.Fragment>
                        </div>
                      )}
                    <div className="BC-A1-horizontal-line-decorator BC-A1-margin-top-24" />
                    <div className="BC-A1-col-container BC-A1-margin-top-16">
                      {cartData.cart.slotsNetPrice > 0 && (
                        <div className="BC-A1-row-344-container">
                          {/* <div className="BC-A1-width-86 BC-A1-margin-right-8">
														<Text id="BC-A1-small-light-label">Advance</Text>
													</div> */}
                          <Pane
                            display="flex"
                            flexDirection={"row"}
                            width={"100%"}
                            justifyContent="space-between"
                          >
                            <div
                              className="BC-A1-text-input-field-container BC-A1-width-104 BC-A1-margin-right-8"
                              ref={"invalidAdvance"}
                            >
                              <TextInputField
                                className="BC-A1-text-input-field"
                                width={220}
                                label="Collect Payment"
                                height={32}
                                marginRight={30}
                                placeholder={`${generateCurrencySymbol()} 0`}
                                value={advanceAmount}
                                onChange={(e) => this.handleAdvanceInput(e)}
                                isInvalid={isAdvanceInvalid}
                              />
                            </div>
                            <Pane marginTop={32}>
                              <TransactionModeSelect
                                disableNoPay={disableNoPay}
                                selectedTransactionMode={
                                  selectedTransactionMode
                                }
                                changeTransactionMode={
                                  this.changeTransactionMode
                                }
                              />
                            </Pane>
                          </Pane>
                        </div>
                      )}
                      {showInvalidAdvanceDiv && (
                        <div className="BC-A1-invalid-input-msg-container">
                          <InlineAlert
                            className="BC-A1-inline-alert"
                            intent="danger"
                          >
                            <Text id="BC-A1-invalid-input-msg">
                              Advance cannot be greater than net price
                            </Text>
                          </InlineAlert>
                        </div>
                      )}
                      {showAdvanceResetToZeroDiv && (
                        <div className="BC-A1-invalid-input-msg-container">
                          <InlineAlert
                            className="BC-A1-inline-alert"
                            intent="none"
                          >
                            <Text id="BC-A1-info-input-msg">
                              Advance has been reset. Please enter again
                            </Text>
                          </InlineAlert>
                        </div>
                      )}
                    </div>
                    <div className="BC-A1-horizontal-line-decorator BC-A1-margin-top-24" />
                    <div className="BC-A1-row-container BC-A1-margin-top-24">
                      <div className="BC-A1-right-panel-left-row-max-156">
                        <Text
                          id="BC-A1-bigger-label"
                          id="BC-A1-medium-dark-label"
                        >
                          Balance
                        </Text>
                      </div>
                      <div className="BC-A1-right-panel-right-row-max-156 BC-A1-text-align-right">
                        <Text
                          id="BC-A1-bigger-label "
                          id="BC-A1-medium-dark-label"
                        >
                          {`${generateCurrencySymbol()} ${checkIntOrFloat(
                            cartData.cart.cartPrice -
                              creditsApplied -
                              discountApplied -
                              advanceAmount
                          )}`}
                        </Text>
                      </div>
                    </div>
                    <div className="BC-A1-horizontal-line-decorator BC-A1-margin-top-24" />
                    <div className="BC-A1-row-344-container">
                      <Checkbox
                        label="Send Payment Link"
                        checked={sendPaymentLinkIsChecked}
                        onChange={(e) => this.handleSendPaymentLinkCheckbox(e)}
                      />
                    </div>
                    {this.props?.bulkBookingInformation
                      ?.isPatternBooking ? null : (
                      <div className="BC-A1-row-344-container">
                        <Checkbox
                          label="Send Confirmation SMS/Email"
                          checked={sendConfirmationSmschecked}
                          onChange={(e) =>
                            this.handleSendConfirmationSmsCheckbox(e)
                          }
                        />
                      </div>
                    )}
                    <div className="BC-A1-row-344-container BC-A1-margin-top-32">
                      <BookingActions
                        isConfirmBookingBtnDisabled={
                          isConfirmBookingBtnDisabled
                        }
                        handleConfirmBooking={this.handleConfirmBooking}
                        handleDismissAction={this.handleDismissAction}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({
  countryCodes,
  customerDetails,
  cartData,
  extrasData,
  bookingToast,
  patternBookingToast,
  bulkBookingInformation,
  clubExtrasList,
  resetCreditsToast,
  cartToast,
}) => ({
  countryCodes,
  customerDetails,
  cartData,
  extrasData,
  bookingToast,
  patternBookingToast,
  bulkBookingInformation,
  clubExtrasList,
  resetCreditsToast,
  cartToast,
});

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default connect(mapStateToProps)(BookingCheckout);
