// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import { SelectField } from "evergreen-ui";
import { connect } from "react-redux";

// -----------------------------------------------------------------------
// ClubCourtListSelect Component
// -----------------------------------------------------------------------
class ClubCourtListSelect extends React.Component {
  // -----------------------------------------------------------------------
  // render()
  // -----------------------------------------------------------------------
  render() {
    const {
      handleCourtChange,
      selectedCourtValue,
      defaultCourtValue,
      defaultCourtName,
      disabled
    } = this.props;
    return (
      <React.Fragment>
        <div className="CCLS-A1-select-input-field-container">
          <SelectField
            className="CCLS-A1-select-input-field"
            label="court"
            width={336}
            height={32}
            onChange={e => handleCourtChange(e)}
            value={selectedCourtValue}
            disabled={disabled}
          >
            <option value={defaultCourtValue}>{defaultCourtName}</option>
          </SelectField>
        </div>
      </React.Fragment>
    );
  }
}

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default connect()(ClubCourtListSelect);
