// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import { connect } from "react-redux";
import { Button, Text, TextInput, toaster } from "evergreen-ui";
import {
  rechargeMemberCredits,
  getIndividualMemberDetails,
  getMemberDetails,
  togglePremiumFeatureFlag,
} from "../../actions";
import TransactionModeSelect from "../TransactionModeSelect";
import {
  resourcesNotLoadedToast,
  validateCreditsAndPrice,
  generateCurrencySymbol,
} from "../../utils";
import CustomInlineAlert from "../CustomInlineAlert";

// -----------------------------------------------------------------------
// CreditsRechargeDialogContent Component
// -----------------------------------------------------------------------
class CreditsRechargeDialogContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentMode: "No Pay",
      transactionMode: 0,
      amount: "",
      credits: "",
      isCreditsInvalid: false,
      selectedTransactionMode: 0,
      isAmountInvalid: false,
      disableNoPay: false,
      rechargeEnable: true,
    };
  }

  // -----------------------------------------------------------------------
  // changeTransactionMode()
  // -----------------------------------------------------------------------
  changeTransactionMode = (e) => {
    e.persist();
    if (this.state.amount.length > 0) {
      this.setState({
        paymentMode:
          e.nativeEvent.target[
            e.nativeEvent.target.selectedIndex
          ].text.toString(),
        transactionMode: parseInt(e.target.value),
        selectedTransactionMode: parseInt(e.target.value),
        isAmountInvalid: false,
      });
    } else {
      this.setState({ isAmountInvalid: true });
    }
  };

  // -----------------------------------------------------------------------
  // changeAmountInput()
  // -----------------------------------------------------------------------
  changeAmountInput = async (e) => {
    e.persist();
    if (validateCreditsAndPrice(e.target.value) === "set") {
      await this.setState({
        amount: e.target.value,
        isAmountInvalid: false,
      });
      if (this.state.selectedTransactionMode === 0 && this.state.amount > 0) {
        await this.setState({
          paymentMode: "Cash",
          transactionMode: 1,
          selectedTransactionMode: 1,
          disableNoPay: true,
        });
      }
      if (this.state.amount <= 0) {
        await this.setState({
          paymentMode: "No Pay",
          transactionMode: 0,
          selectedTransactionMode: 0,
          disableNoPay: false,
        });
      }
    } else if (validateCreditsAndPrice(e.target.value) === "clear") {
      await this.setState({
        amount: "",
        isAmountInvalid: true,
        paymentMode: "No Pay",
        transactionMode: 0,
        selectedTransactionMode: 0,
        disableNoPay: false,
      });
    }
  };

  // -----------------------------------------------------------------------
  // changCreditsAmountInput()
  // -----------------------------------------------------------------------
  changeCreditsInput = async (e) => {
    e.persist();
    if (validateCreditsAndPrice(e.target.value) === "set") {
      await this.setState({
        credits: e.target.value,
        isCreditsInvalid: false,
      });
    } else if (validateCreditsAndPrice(e.target.value) === "clear") {
      await this.setState({
        credits: "",
        isCreditsInvalid: true,
      });
    }
  };

  // -----------------------------------------------------------------------
  // confirmRechargeMemberCredits()
  // -----------------------------------------------------------------------
  confirmRechargeMemberCredits = async (e, clubId, memberMobile) => {
    this.setState({ rechargeEnable: false });
    e.persist();
    if (this.state.amount === "") {
      this.setState({ rechargeEnable: true });
      await this.setState({ isAmountInvalid: true });
      // toaster.warning("Amount is required", {
      //   id: "BF-TOAST-3"
      // });
    }
    if (this.state.credits === "") {
      this.setState({ rechargeEnable: true });
      await this.setState({ isCreditsInvalid: true });
      // toaster.warning("Credits is required", {
      //   id: "BF-TOAST-3"
      // });
    }
    // else if (this.state.amount <= 0) {
    //   await this.setState({ isAmountInvalid: true });
    //   toaster.warning("Amount must be greater than 0", {
    //     id: "BF-TOAST-3"
    //   });
    // }
    // else if (this.state.credits <= 0) {
    //   await this.setState({ isCreditsInvalid: true });
    //   toaster.warning("Credits must be greater than 0", {
    //     id: "BF-TOAST-3"
    //   });
    // }
    else if (this.state.amount !== "" && this.state.credits !== "") {
      let reqBody = {
        mobile: memberMobile,
        paidAmount: this.state.amount === "" ? 0 : this.state.amount,
        credits: this.state.credits === "" ? 0 : this.state.credits,
        transactionData: {
          type: 5,
          mode: this.state.transactionMode,
        },
      };
      await this.props.dispatch(rechargeMemberCredits(reqBody));
      if (this.props.rechargeMemberCreditsToast) {
        if (this.props.rechargeMemberCreditsToast.resourcesNotLoadedMessage) {
          resourcesNotLoadedToast();
        } else if (this.props.rechargeMemberCreditsToast.httpErrorMessage) {
          toaster.danger(
            this.props.rechargeMemberCreditsToast.httpErrorMessage,
            {
              id: "HTTP-ERROR-TOAST-4",
            }
          );
        } else if (this.props.rechargeMemberCreditsToast.requestStatus === -1) {
          this.setState({ rechargeEnable: true });
          toaster.notify("Sorry, you don't have permission to do that");
        } else if (this.props.rechargeMemberCreditsToast.requestStatus === 0) {
          this.setState({ rechargeEnable: true });
          toaster.danger(this.props.rechargeMemberCreditsToast, {
            id: "BF-TOAST-3",
          });
        } else if (this.props.rechargeMemberCreditsToast.requestStatus === 1) {
          this.setState({ rechargeEnable: true });
          toaster.success(this.props.rechargeMemberCreditsToast.message);
          this.props.dispatch(
            getIndividualMemberDetails({
              clubId: clubId,
              page: 1,
              mobile: memberMobile,
            })
          );
          this.props.dispatch(
            getMemberDetails({
              clubId: clubId,
              page: this.props.pageNumOnMembersPage,
            })
          );
          if (this.props.individualMemberDetailsToast) {
            this.setState({ rechargeEnable: true });
            if (
              this.props.individualMemberDetailsToast.resourcesNotLoadedMessage
            ) {
              resourcesNotLoadedToast();
            } else if (
              this.props.individualMemberDetailsToast.httpErrorMessage
            ) {
              toaster.danger(
                this.props.individualMemberDetailsToast.httpErrorMessage
              );
            } else if (
              this.props.individualMemberDetailsToast.requestStatus === -1
            ) {
              toaster.notify("Sorry, you don't have permission to do that");
            }
          }
          if (this.props.memberDetails) {
            this.setState({ rechargeEnable: true });
            if (this.props.memberDetails.resourcesNotLoadedMessage) {
              resourcesNotLoadedToast();
            } else if (this.props.memberDetails.httpErrorMessage) {
              toaster.danger(this.props.memberDetails.httpErrorMessage);
            } else if (this.props.memberDetails.requestStatus === -1) {
              toaster.notify("Sorry, you don't have permission to do that");
            }
          }
          await this.props.handleCreditsRechargeDialog("close");
        }
      }
    }
  };

  // -----------------------------------------------------------------------
  // render()
  // -----------------------------------------------------------------------
  render() {
    const {
      amount,
      credits,
      selectedTransactionMode,
      disableNoPay,
      isAmountInvalid,
      isCreditsInvalid,
    } = this.state;
    const { clubId, memberMobile, handleCreditsRechargeDialog } = this.props;
    return (
      <React.Fragment>
        <div className="CRDC-A1-wrapper">
          <div className="CRDC-A1-body-content">
            <div className="CRDC-A1-row-container CRDC-A1-margin-bottom-24">
              <div className="CRDC-A1-label-container">
                <Text id="CRDC-A1-small-label">Amount</Text>
              </div>
              <div className="CRDC-A1-col-container">
                <div className="CRDC-A1-row-container">
                  <TextInput
                    width={104}
                    height={32}
                    marginRight={16}
                    placeholder={`${generateCurrencySymbol()} 0`}
                    value={amount}
                    onChange={(e) => this.changeAmountInput(e)}
                    isInvalid={isAmountInvalid}
                  />
                  <div className="transaction-mode-select-container">
                    <TransactionModeSelect
                      changeTransactionMode={this.changeTransactionMode}
                      disableNoPay={disableNoPay}
                      selectedTransactionMode={selectedTransactionMode}
                    />
                  </div>
                </div>
                {isAmountInvalid && (
                  <div className="CRDC-A1-custom-inline-alert-container">
                    <CustomInlineAlert
                      intent="danger"
                      alertMsg="Amount is required"
                    />
                  </div>
                )}
              </div>
            </div>

            <div className="CRDC-A1-row-container">
              <div className="CRDC-A1-label-container">
                <Text id="CRDC-A1-small-label">Credits</Text>
              </div>
              <div className="CRDC-A1-col-container">
                <TextInput
                  width={104}
                  height={32}
                  marginRight={16}
                  placeholder="0"
                  value={credits}
                  onChange={(e) => this.changeCreditsInput(e)}
                  isInvalid={isCreditsInvalid}
                />
                {isCreditsInvalid && (
                  <div className="CRDC-A1-custom-inline-alert-container">
                    <CustomInlineAlert
                      intent="danger"
                      alertMsg="Credits is required"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="CRDC-A1-footer-content">
            <Button
              className="button"
              width={72}
              height={32}
              marginRight={12}
              onClick={() => handleCreditsRechargeDialog("close")}
            >
              Cancel
            </Button>
            <Button
              className="button"
              appearance="primary"
              intent="success"
              disabled={!this.state.rechargeEnable}
              width={88}
              height={32}
              onClick={(e) =>
                this.confirmRechargeMemberCredits(e, clubId, memberMobile)
              }
            >
              Recharge
            </Button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({
  rechargeMemberCreditsToast,
  individualMemberDetailsToast,
  memberDetails,
}) => ({
  rechargeMemberCreditsToast,
  individualMemberDetailsToast,
  memberDetails,
});

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default connect(mapStateToProps)(CreditsRechargeDialogContent);
