const individualMemberDetails = (state = null, action) => {
  if (action.type === "SET_INDIVIDUAL_MEMBER_DETAILS") {
    return action.payload;
  } else {
    return state;
  }
  // switch (action.type) {
  //   case "SET_INDIVIDUAL_MEMBER_DETAILS": {
  //     return action.payload;
  //   }

  //   default: {
  //     return state;
  //   }
  // }
};

export default individualMemberDetails;
