// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import { toaster } from "evergreen-ui";
import { connect } from "react-redux";
import ReportSummary from "../../components/ReportsSummary";
// import ReportsPeriodPicker from "../../components/ReportsPeriodPicker";
import GetReportsActionButtons from "../../components/GetReportsActionButtons";
import {
  getReports,
  downloadReport,
  togglePremiumFeatureFlag,
} from "../../actions";
import { toDateFormat1, resourcesNotLoadedToast } from "../../utils";

// -----------------------------------------------------------------------
// CreditsActions Component
// -----------------------------------------------------------------------
class CreditsActions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPeriodType: "range",
      showPeriodChangePopover: true,
      fromDate: new Date(),
      toDate: new Date(),
    };
  }

  // -----------------------------------------------------------------------
  // handlePeriodTypeChange()
  // -----------------------------------------------------------------------
  handlePeriodTypeChange = async (e, type) => {
    await this.setState({ selectedPeriodType: type });
    await this.togglePeriodChangePopover("hide");
    await this.togglePeriodChangePopover("show");
  };

  // -----------------------------------------------------------------------
  // handleFromDateChange()
  // -----------------------------------------------------------------------
  handleFromDateChange = async (date) => {
    await this.setState({ fromDate: date });
  };

  // -----------------------------------------------------------------------
  // handleToDateChange()
  // -----------------------------------------------------------------------
  handleToDateChange = async (date) => {
    await this.setState({ toDate: date });
  };

  // -----------------------------------------------------------------------
  // togglePeriodChangePopover()
  // -----------------------------------------------------------------------
  togglePeriodChangePopover = async (type) => {
    await this.setState({
      showPeriodChangePopover: type === "show" ? true : false,
    });
  };

  // -----------------------------------------------------------------------
  // handleViewReports()
  // -----------------------------------------------------------------------
  handleViewReports = async (e, reportType = "view") => {
    e.persist();
    try {
      let reqParams = {
        clubIds: this.props.clubIds,
        startDate: toDateFormat1(this.state.fromDate),
        endDate:
          this.state.selectedPeriodType === "range"
            ? toDateFormat1(this.state.toDate)
            : toDateFormat1(this.state.fromDate),
      };
      if (reportType === "view") {
        await this.props.dispatch(
          getReports(
            reqParams,
            "credits",
            this.state.fromDate,
            this.state.selectedPeriodType === "range"
              ? this.state.toDate
              : this.state.fromDate
          )
        );
        if (this.props.reportsData) {
          if (this.props.reportsData.resourcesNotLoadedMessage) {
            resourcesNotLoadedToast();
          } else if (this.props.reportsData.httpErrorMessage) {
            toaster.danger(this.props.reportsData.httpErrorMessage, {
              id: "HTTP-ERROR-TOAST-1",
            });
          } else if (this.props.reportsData.requestStatus === -1) {
            toaster.notify("Sorry, you don't have permission to do that");
          } else if (
            this.props.reportsData.requestStatus === 1 &&
            this.props.reportsData.data.length > 0
          ) {
            await this.props.handleViewReportsPageDisplay("show");
          } else if (
            this.props.reportsData.requestStatus === 1 &&
            this.props.reportsData.data.length <= 0
          ) {
            await this.props.dispatch({
              type: "SET_REPORTS_DATA",
              payload: null,
            });
            toaster.notify(
              "No reports are available for the selected category",
              {
                id: "RA-TOAST",
              }
            );
          }
        }
      } else if (reportType === "download") {
        await this.props.dispatch(downloadReport(reqParams, "credits"));
        if (this.props.downloadReportToast) {
          if (this.props.downloadReportToast.resourcesNotLoadedMessage) {
            resourcesNotLoadedToast();
          } else if (this.props.downloadReportToast.httpErrorMessage) {
            toaster.danger(this.props.downloadReportToast.httpErrorMessage, {
              id: "HTTP-ERROR-TOAST-3",
            });
          } else if (this.props.downloadReportToast.requestStatus === 1) {
            // toaster.success(this.props.downloadReportToast.message, {
            //   id: "HTTP-ERROR-TOAST-3"
            // });
          }
        }
      }
    } catch (err) {
      toaster.danger("Some error has occured", { id: "RA-TOAST" });
    }
  };

  // -----------------------------------------------------------------------
  // handleDownloadReport()
  // -----------------------------------------------------------------------
  handleDownloadReport = async (e) => {
    e.persist();
    await this.handleViewReports(e, "download");
  };

  // -----------------------------------------------------------------------
  // render()
  // -----------------------------------------------------------------------
  render() {
    // const {
    //   selectedPeriodType,
    //   showPeriodChangePopover,
    //   fromDate,
    //   toDate
    // } = this.state;
    const { reportTypeHeading, reportSummary, onClubSelectionChange } =
      this.props;
    return (
      <React.Fragment>
        <div className="RA-A1-wrapper">
          <div className="RA-A1-reports-summary-container RA-A1-margin-bottom-32">
            <ReportSummary
              reportTypeHeading={reportTypeHeading}
              reportSummary={reportSummary}
            />
          </div>
          {/* <div className="RA-A1-reports-period-picker-holder RA-A1-margin-bottom-32">
            <ReportsPeriodPicker
              selectedPeriodType={selectedPeriodType}
              showPeriodChangePopover={showPeriodChangePopover}
              fromDate={fromDate}
              toDate={toDate}
              handlePeriodTypeChange={this.handlePeriodTypeChange}
              handleFromDateChange={this.handleFromDateChange}
              handleToDateChange={this.handleToDateChange}
            />
          </div> */}
          <div className="RA-A1-get-reports-action-buttons-holder">
            <GetReportsActionButtons
              onClubSelectionChange={onClubSelectionChange}
              handleViewReports={this.handleViewReports}
              handleDownloadReport={this.handleDownloadReport}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ reportsData, downloadReportToast }) => ({
  reportsData,
  downloadReportToast,
});

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default connect(mapStateToProps)(CreditsActions);
