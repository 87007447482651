// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import { connect } from "react-redux";
import { Button, Text, Radio, toaster, Pane, Checkbox, Strong } from "evergreen-ui";
import { cancelBooking, togglePremiumFeatureFlag } from "../../actions";
import { resourcesNotLoadedToast } from "../../utils";
import CancellationActions from "../../pages/Reports/CancellationActions";

// -----------------------------------------------------------------------
// CancelBookingDialogContent Component
// -----------------------------------------------------------------------
class CancelBookingDialogContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = { sendSms: true, checkedRefundType: "2" };
  }

  // -----------------------------------------------------------------------
  // handleRefundTypeChange()
  // -----------------------------------------------------------------------
  handleRefundTypeChange = async (e, type) => {
    e.persist();
    await this.setState({ checkedRefundType: type });
  };

  // -----------------------------------------------------------------------
  // handleCancelBooking()
  // -----------------------------------------------------------------------
  handleCancelBooking = async (e) => {
    try {
      e.persist();
      let reqBody = {
        bookingId: this.props.bookingType === "regular" ? this.props.bookingId.toString() : null,
        patternBookingId:
          this.props.bookingType === "bulk" ? this.props.bookingId.toString() : null,
        cancelRemarks: "",
        playoCancelRemarks: "",
        refundMode: "cash",
        refundType: parseInt(this.state.checkedRefundType),
        transactionData: {
          type: -1,
          mode: 1,
        },
        sendSMS: this.state.sendSms,
      };
      await this.props.dispatch(cancelBooking(reqBody));
      if (this.props.cancelBookingToast) {
        if (this.props.cancelBookingToast.resourcesNotLoadedMessage) {
          resourcesNotLoadedToast();
        } else if (this.props.cancelBookingToast.httpErrorMessage) {
          toaster.danger(this.props.cancelBookingToast.httpErrorMessage, {
            id: "HTTP-ERROR-TOAST-4",
          });
        } else if (this.props.cancelBookingToast.requestStatus === -1) {
          toaster.notify("Sorry, you don't have permission to do that");
        } else if (this.props.cancelBookingToast.requestStatus === 0) {
          toaster.danger(JSON.stringify(this.props.cancelBookingToast.message));
        } else if (this.props.cancelBookingToast.requestStatus === 1) {
          this.props.handleCancelBookingDialog("close");
          if (this.props.getUpdatedIndividualBulkBookingSearchResult) {
            await this.props.getUpdatedIndividualBulkBookingSearchResult(this.props.bookingId);
          }
          if (this.props.updateCalendarPage) {
            await this.props.updateCalendarPage();
            // await this.props.handleDrawer("close");
          }
          if (this.props.updateSearchPage) {
            await this.props.updateSearchPage();
            // await this.props.handleDrawer("close");
          }
          if (this.props.updateBookingDetails) {
            await this.props.updateBookingDetails();
          }
          if (this.props.handleGetBookingDetails) {
            await this.props.handleGetBookingDetails(e, this.props.bookingId);
          }
          if (this.props.getPatternBookingDetailsForDrawer) {
            await this.props.getPatternBookingDetailsForDrawer(e, this.props.bookingId.toString());
          }
          await toaster.success(this.props.cancelBookingToast.message);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  handleSmsChange = async () => {
    this.setState({
      sendSms: !this.state.sendSms,
    });
  };

  // -----------------------------------------------------------------------
  // render()
  // -----------------------------------------------------------------------
  render() {
    const { checkedRefundType, sendSms } = this.state;
    const { handleCancelBookingDialog ,isPlayoBooking} = this.props;
    return (
      <div className='CBDC-A1-wrapper'>
        <div className='CBDC-A1-cancel-booking-message-container CBDC-A1-margin-bottom-16'>
          <Text id='CBDC-A1-label-type-one'>Are you sure you want to cancel the booking?</Text>
        </div>
        <div className='CBDC-A1-refund-label-container CBDC-A1-margin-bottom-8'>
          <Text id='CBDC-A1-label-type-two'>Refund</Text>
        </div>
        <div
          className='CBDC-A1-refund-type-radio-btns-container'
          aria-label='Radio Group Label 16'
          role='group'
        >
          <Radio
            className='CBDC-A1-radio-btn'
            width={144}
            height={28}
            marginRight={8}
            size={16}
            name='group1'
            label='As per Policy'
            checked={checkedRefundType === "2" ? true : false}
            onChange={(e) => this.handleRefundTypeChange(e, "2")}
          />
          <Radio
            className='CBDC-A1-radio-btn'
            width={144}
            height={28}
            marginRight={8}
            size={16}
            name='group1'
            label='Full Refund'
            checked={checkedRefundType === "1" ? true : false}
            onChange={(e) => this.handleRefundTypeChange(e, "1")}
          />
          <Radio
            className='CBDC-A1-radio-btn'
            width={144}
            height={28}
            marginRight={8}
            size={16}
            name='group1'
            label='No Refund'
            checked={checkedRefundType === "0" ? true : false}
            onChange={(e) => this.handleRefundTypeChange(e, "0")}
          />
        </div>
        {this.props.bookingType === "bulk" ? null : (
          <Pane display={"flex"} flexDirection='row' justifyContent='flex-start'>
            <Checkbox
              label={<Strong>Send Cancellation Sms</Strong>}
              onChange={() => this.handleSmsChange()}
              checked={sendSms}
            />
          </Pane>
        )}
        {/* <Pane
          width={"100%"}
          marginTop={"20px"}
          borderRadius={"5px"}
          display='flex'
          alignItems='center'
          justifyContent='center'
          background={isPlayoBooking ? "#CCFFE7" : "#FFDAD4"}
          height={"25px"}
        >
          <Pane size='300' color={isPlayoBooking ? "#00331B" : "#410001"}>
            {isPlayoBooking ? "Playo Booking" : "Club Booking"}
          </Pane>
        </Pane> */}
        <div className='CBDC-A1-action-btn-container'>
          <Button
            className='CBDC-A1-button'
            width={72}
            height={32}
            marginRight={12}
            onClick={(e) => handleCancelBookingDialog("close")}
          >
            Cancel
          </Button>
          <Button
            className='CBDC-A1-button'
            width={88}
            height={32}
            appearance='primary'
            onClick={(e) => this.handleCancelBooking(e)}
          >
            Confirm
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ cancelBookingToast }) => ({
  cancelBookingToast,
});

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default connect(mapStateToProps)(CancelBookingDialogContent);
