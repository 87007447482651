import {
  Button,
  Dialog,
  EditIcon,
  Heading,
  Pane,
  Strong,
  Text,
} from "evergreen-ui";
import React from "react";

export default function EventSummary({ summaryDetails, handleEditTickets }) {
  console.log({ summaryDetails });
  const [isShown, setIsShown] = React.useState(false);
  //   paidRegistrations: 55
  // ​​
  // totalGross: 550
  // ​​
  // totalPaid: 550
  // ​​
  // totalRegistrations: 55
  // ​​
  // totalSoldTickets: 65
  // ​​
  // totalTickets: 79500

  let data = [
    {
      label: "Total Tickets",
      subLabel: "Sold Tickets",
      value: summaryDetails.totalTickets,
      subValue: summaryDetails.totalSoldTickets,
      edit: true,
    },
    {
      label: "Total Registration",
      subLabel: "Sold Registration",
      value: summaryDetails.totalRegistrations,
      subValue: summaryDetails.paidRegistrations,
    },
    {
      label: "Total Gross",
      subLabel: "Paid Gross",
      value: summaryDetails.totalGross,
      subValue: summaryDetails.totalPaid,
    },
  ];
  return (
    <Pane
      marginTop={24}
      borderRadius={"10px"}
      background={"gray300"}
      width={"100%"}
      minWidth={600}
      display={"flex"}
      flexDirection={"row"}
      justifyContent={"space-around"}
      padding={24}
      gap={24}
    >
      {data.map((item) => {
        return (
          <Pane
            width={"100%"}
            background={"white"}
            borderRadius={"10px"}
            display={"flex"}
            alignContent={"center"}
            justifyContent={"center"}
            flexDirection={"column"}
            gap={32}
            padding={20}
          >
            <Pane
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
              width={"100%"}
            >
              {" "}
              <Heading color={"#0F4D88"} size={800}>
                {item.value}
              </Heading>
              {item.edit && (
                <EditIcon
                  onClick={() => {
                    handleEditTickets();
                  }}
                  size={28}
                  cursor={"pointer"}
                  color={"#0F4D88"}
                />
              )}
            </Pane>
            <Pane
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
              width={"100%"}
            >
              {" "}
              <Text size={500} color={"gray600"}>
                {item.label}
              </Text>
            </Pane>
            <Pane
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
              width={"100%"}
            >
              {" "}
              <Text size={500} color={"gray800"}>
                {item.subLabel}
              </Text>{" "}
              <Strong size={500}>{item.subValue}</Strong>
            </Pane>
          </Pane>
        );
      })}
    </Pane>
  );
}
