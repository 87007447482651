// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import { connect } from "react-redux";
import {
  Text,
  Button,
  Dialog,
  IconButton,
  toaster,
  EditIcon,
  ChangesIcon,
  KeyIcon,
  CogIcon,
  AddIcon,
} from "evergreen-ui";
import {
  toDateFormat32,
  checkIntOrFloat,
  resourcesNotLoadedToast,
} from "../../utils";
import MemberDetailsDrawerActions from "../../components/MemberDetailsDrawerActions";
import CreditsRechargeDialogContent from "../../components/CreditsRechargeDialogContent";
import CreditsValidityDialogContent from "../../components/CreditsValidityDialogContent";
import UpdateMemberDetailsDialogContent from "../../components/UpdateMemberDetailsDialogContent";
import SetPinDialogContent from "../../components/SetPinDialogContent";
import UpdatePinDialogContent from "../../components/UpdatePinDialogContent";
import MembershipPackagesDialogContent from "../../components/MembershipPackagesDialogContent";
import {
  getMembershipPackageDetails,
  togglePremiumFeatureFlag,
} from "../../actions";

// -----------------------------------------------------------------------
// MemberDetailsOnDrawer Component
// -----------------------------------------------------------------------
class MemberDetailsOnDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clubId: 1,
      defaultPageNum: 1,
      showCreditsRechargeDialog: false,
      showCreditValidityDialog: false,
      showSetPinDialog: false,
      showUpdatePinDialog: false,
      showMembershipPackagesDialog: false,
      concatedMembershipPackageDetails: [],
    };
  }

  // -----------------------------------------------------------------------
  // handleCreditsRechargeDialog()
  // -----------------------------------------------------------------------
  handleCreditsRechargeDialog = (type) => {
    if (type === "open") {
      this.setState({ showCreditsRechargeDialog: true });
    } else {
      this.setState({ showCreditsRechargeDialog: false });
    }
  };

  // -----------------------------------------------------------------------
  // handleCreditValidityDialog()
  // -----------------------------------------------------------------------
  handleCreditValidityDialog = (type) => {
    if (type === "open") {
      this.setState({ showCreditValidityDialog: true });
    } else {
      this.setState({ showCreditValidityDialog: false });
    }
  };

  // -----------------------------------------------------------------------
  // handleSetPinDialogVisibility()
  // -----------------------------------------------------------------------
  handleSetPinDialogVisibility = (type) => {
    if (type === "open") {
      this.setState({ showSetPinDialog: true });
    } else {
      this.setState({ showSetPinDialog: false });
    }
  };

  // -----------------------------------------------------------------------
  // handleUpdatePinDialogVisibility()
  // -----------------------------------------------------------------------
  handleUpdatePinDialogVisibility = (type) => {
    if (type === "open") {
      this.setState({ showUpdatePinDialog: true });
    } else {
      this.setState({ showUpdatePinDialog: false });
    }
  };

  // -----------------------------------------------------------------------
  // generateConcatedMembershipPackages()
  // -----------------------------------------------------------------------
  generateConcatedMembershipPackages = async (membershipPackages) => {
    let tempList = JSON.parse(
      JSON.stringify(this.state.concatedMembershipPackageDetails)
    );
    await this.setState({
      concatedMembershipPackageDetails: tempList.concat(membershipPackages),
    });
  };

  // -----------------------------------------------------------------------
  // handleshowMembershipPackagesDialogVisibility()
  // -----------------------------------------------------------------------
  handleshowMembershipPackagesDialogVisibility = async (type) => {
    if (type === "open") {
      let reqParams = {
        page: this.state.defaultPageNum,
      };
      await this.handleGetMembershipPackageDetailsResponse(reqParams);
    } else {
      this.setState({
        showMembershipPackagesDialog: false,
        concatedMembershipPackageDetails: [],
      });
    }
  };

  // -----------------------------------------------------------------------
  // handleGetMembershipPackageDetailsResponse()
  // -----------------------------------------------------------------------
  handleGetMembershipPackageDetailsResponse = async (reqParams) => {
    await this.props.dispatch(getMembershipPackageDetails(reqParams, true));
    if (this.props.membershipPackageDetails) {
      if (this.props.membershipPackageDetails.resourcesNotLoadedMessage) {
        resourcesNotLoadedToast();
      } else if (this.props.membershipPackageDetails.httpErrorMessage) {
        toaster.danger(this.props.membershipPackageDetails.httpErrorMessage, {
          id: "HTTP-ERROR-TOAST-4",
        });
      } else if (this.props.membershipPackageDetails.requestStatus === -1) {
        toaster.notify("Sorry, you don't have permission to do that");
      } else if (this.props.membershipPackageDetails.requestStatus === 0) {
        toaster.danger(this.props.membershipPackageDetails.message, {
          id: "USER-TOAST",
        });
      } else if (this.props.membershipPackageDetails.requestStatus === 1) {
        await this.generateConcatedMembershipPackages(
          this.props.membershipPackageDetails.packages
        );
        await this.setState({ showMembershipPackagesDialog: true });
      }
    }
  };

  // -----------------------------------------------------------------------
  // render()
  // -----------------------------------------------------------------------
  render() {
    const {
      showCreditsRechargeDialog,
      showCreditValidityDialog,
      showSetPinDialog,
      showUpdatePinDialog,
      showMembershipPackagesDialog,
      concatedMembershipPackageDetails,
    } = this.state;
    const {
      clubId,
      pageNumOnMembersPage,
      individualMemberDetails,
      handleSeeBookingsFromMembersDrawer,
      memberMobileInputValue,
      isMobileInvalid,
      showInvalidMobileDiv,
      isNameInvalid,
      isNameInvalidMsg,
      isMobileRequiredMsg,
      memberName,
      isEmailInvalid,
      isEmailInvalidMsg,
      memberEmail,
      memberAddress,
      checkedGenderValue,
      memberExpiryDate,
      onChangeOfMobileInput,
      onBlurOfMobileInput,
      onChangeOfNameInput,
      onChangeOfEmailInput,
      validateEmail,
      shakeOnInvalid,
      onCountryChange,
      onChangeOfAddressInput,
      onChangeOfGenderRadioInput,
      showUpdateMemberDetailsDialogOnMemberDetailsOnDrawerPage,
      toggleUpdateMemberDetailsDialogVisibility,
      checkedMemberExpiryRadioValue,
      onChangeOfMemberExpiryRadioInput,
      handleUpdateMemberDetails,
      handleMemberExpiryDateChange,
      // membershipPackageDetails,
      handleMembershipPackagesCheckoutPageDisplay,
    } = this.props;
    return (
      <React.Fragment>
        <div className="MDOD-A1-wrapper">
          <div className="MDOD-A1-row-top-16-container">
            <div className="MDOD-A1-left-160-col-container">
              <Text id="MDOD-A1-label" marginBottom={3}>
                Name
              </Text>
              <Text id="MDOD-A1-data-text">
                {individualMemberDetails.memberName}
              </Text>
            </div>
            <div className="MDOD-A1-right-160-col-container">
              <Text id="MDOD-A1-label" marginBottom={3}>
                Phone Number
              </Text>
              <Text id="MDOD-A1-data-text">
                {individualMemberDetails.memberMobile}
              </Text>
            </div>
          </div>
          <div className="MDOD-A1-row-top-16-container">
            <div className="MDOD-A1-left-160-col-container">
              <Text id="MDOD-A1-label" marginBottom={3}>
                Email
              </Text>
              <Text id="MDOD-A1-data-text">
                {individualMemberDetails.memberEmail !== ""
                  ? individualMemberDetails.memberEmail
                  : "-"}
              </Text>
            </div>
            <div className="MDOD-A1-right-160-col-container">
              <Text id="MDOD-A1-label" marginBottom={3}>
                Gender
              </Text>
              <Text id="MDOD-A1-data-text">
                {individualMemberDetails.memberGender !== null
                  ? individualMemberDetails.memberGender
                  : "-"}
              </Text>
            </div>
          </div>
          <div className="MDOD-A1-row-top-16-container">
            <div className="MDOD-A1-336-col-container">
              <Text id="MDOD-A1-label" marginBottom={3}>
                Address
              </Text>
              <div className="MDOD-A1-address-container">
                <Text id="MDOD-A1-data-text">
                  {individualMemberDetails.address !== ""
                    ? individualMemberDetails.address
                    : "-"}
                </Text>
              </div>
            </div>
          </div>
          <div className="MDOD-A1-row-top-16-container">
            <div className="MDOD-A1-left-160-col-container">
              <Text id="MDOD-A1-label" marginBottom={3}>
                Member Since
              </Text>
              <Text id="MDOD-A1-data-text">
                {toDateFormat32(individualMemberDetails.startDate)}
              </Text>
            </div>
            <div className="MDOD-A1-right-160-col-container">
              <Text id="MDOD-A1-label" marginBottom={3}>
                Member Expiry
              </Text>
              <Text id="MDOD-A1-data-text">
                {individualMemberDetails.endDate === "9999-12-31"
                  ? `Valid for lifetime`
                  : `${toDateFormat32(individualMemberDetails.endDate)}`}
              </Text>
            </div>
          </div>
          <div className="MDOD-A1-row-top-16-container">
            <Dialog
              width={560}
              isShown={showUpdateMemberDetailsDialogOnMemberDetailsOnDrawerPage}
              title="Update Member Details"
              onConfirm={() =>
                handleUpdateMemberDetails(
                  "MemberDetailsOnDrawer",
                  individualMemberDetails.memberMobile
                )
              }
              confirmLabel="Save Details"
              onCloseComplete={() =>
                toggleUpdateMemberDetailsDialogVisibility("close")
              }
            >
              <UpdateMemberDetailsDialogContent
                memberMobileInputValue={memberMobileInputValue}
                isMobileInvalid={isMobileInvalid}
                showInvalidMobileDiv={showInvalidMobileDiv}
                isNameInvalid={isNameInvalid}
                isNameInvalidMsg={isNameInvalidMsg}
                isMobileRequiredMsg={isMobileRequiredMsg}
                memberName={memberName}
                isEmailInvalid={isEmailInvalid}
                isEmailInvalidMsg={isEmailInvalidMsg}
                memberEmail={memberEmail}
                checkedGenderValue={checkedGenderValue}
                onChangeOfMobileInput={onChangeOfMobileInput}
                onBlurOfMobileInput={onBlurOfMobileInput}
                onChangeOfNameInput={onChangeOfNameInput}
                onChangeOfEmailInput={onChangeOfEmailInput}
                validateEmail={validateEmail}
                onChangeOfGenderRadioInput={onChangeOfGenderRadioInput}
                shakeOnInvalid={shakeOnInvalid}
                onCountryChange={onCountryChange}
                onChangeOfAddressInput={onChangeOfAddressInput}
                checkedMemberExpiryRadioValue={checkedMemberExpiryRadioValue}
                memberAddress={memberAddress}
                memberExpiryDate={memberExpiryDate}
                onChangeOfMemberExpiryRadioInput={
                  onChangeOfMemberExpiryRadioInput
                }
                handleMemberExpiryDateChange={handleMemberExpiryDateChange}
              />
            </Dialog>
            <Button
              className="button"
              width={120}
              height={32}
              appearance="minimal"
              iconBefore={<EditIcon />}
              onClick={() =>
                toggleUpdateMemberDetailsDialogVisibility(
                  "open",
                  "MemberDetailsOnDrawer",
                  {
                    memberMobile: individualMemberDetails.memberMobile,
                    memberName: individualMemberDetails.memberName,
                    memberEmail: individualMemberDetails.memberEmail,
                    address: individualMemberDetails.address,
                    memberGender: individualMemberDetails.memberGender,
                    endDate: individualMemberDetails.endDate,
                  }
                )
              }
            >
              Edit Details
            </Button>
          </div>
          <div className="MDOD-A1-horizontal-line-decorator" />
          <div className="MDOD-A1-row-top-24-container">
            <Text id="MDOD-A1-dark-label" marginRight={61}>
              Credits
            </Text>
            <div className="MDOD-A1-credits-container">
              <Text id="MDOD-A1-dark-label">
                {checkIntOrFloat(individualMemberDetails.credits)}
              </Text>
            </div>
            <Dialog
              isShown={showCreditsRechargeDialog}
              title="Recharge"
              onCloseComplete={() => this.handleCreditsRechargeDialog("close")}
              hasFooter={false}
            >
              <CreditsRechargeDialogContent
                clubId={clubId}
                pageNumOnMembersPage={pageNumOnMembersPage}
                memberMobile={individualMemberDetails.memberMobile}
                handleCreditsRechargeDialog={this.handleCreditsRechargeDialog}
              />
            </Dialog>
            <Button
              className="button"
              width={88}
              height={32}
              onClick={() => this.handleCreditsRechargeDialog("open")}
            >
              Recharge
            </Button>
          </div>
          <div className="MDOD-A1-row-top-16-container">
            <div className="MDOD-A1-left-160-col-container">
              <Text id="MDOD-A1-label" marginBottom={3}>
                Credits validity
              </Text>
              <Dialog
                isShown={showCreditValidityDialog}
                title="Credits Validity"
                onCloseComplete={() => this.handleCreditValidityDialog("close")}
                hasFooter={false}
              >
                <CreditsValidityDialogContent
                  clubId={clubId}
                  date={individualMemberDetails.creditsExpiry}
                  creditsExpiry={individualMemberDetails.creditsExpiry}
                  pageNumOnMembersPage={pageNumOnMembersPage}
                  memberMobile={individualMemberDetails.memberMobile}
                  handleCreditValidityDialog={this.handleCreditValidityDialog}
                />
              </Dialog>
              <Text
                id="MDOD-A1-data-underlined-text"
                onClick={() => this.handleCreditValidityDialog("open")}
              >
                {individualMemberDetails.creditsExpiry === "9999-12-31"
                  ? `Lifetime`
                  : `Till ${toDateFormat32(
                      individualMemberDetails.creditsExpiry
                    )}`}
              </Text>
            </div>
            <div className="MDOD-A1-right-160-col-container">
              <Text id="MDOD-A1-label" marginBottom={3}>
                Registration Fee
              </Text>
              <Text id="MDOD-A1-data-text">Paid</Text>
            </div>
          </div>
          <div className="MDOD-A1-row-top-16-container">
            <Button
              className="button"
              width={120}
              height={32}
              marginRight={64}
              appearance="minimal"
              iconBefore={<ChangesIcon />}
            >
              Transactions
            </Button>
            {individualMemberDetails.isPasswordProtected ? (
              <React.Fragment>
                <Dialog
                  isShown={showUpdatePinDialog}
                  title="Set PIN"
                  onCloseComplete={() =>
                    this.handleUpdatePinDialogVisibility("close")
                  }
                  hasFooter={false}
                >
                  <UpdatePinDialogContent
                    clubId={clubId}
                    mobile={individualMemberDetails.memberMobile}
                    handleUpdatePinDialogVisibility={
                      this.handleUpdatePinDialogVisibility
                    }
                    pageNumOnMembersPage={pageNumOnMembersPage}
                  />
                </Dialog>
                <Button
                  className="button"
                  width={120}
                  height={32}
                  appearance="minimal"
                  iconBefore={<KeyIcon />}
                  onClick={() => this.handleUpdatePinDialogVisibility("open")}
                >
                  Change PIN
                </Button>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Dialog
                  isShown={showSetPinDialog}
                  title="Set PIN"
                  onCloseComplete={() =>
                    this.handleSetPinDialogVisibility("close")
                  }
                  hasFooter={false}
                >
                  <SetPinDialogContent
                    clubId={clubId}
                    mobile={individualMemberDetails.memberMobile}
                    handleSetPinDialogVisibility={
                      this.handleSetPinDialogVisibility
                    }
                    pageNumOnMembersPage={pageNumOnMembersPage}
                  />
                </Dialog>
                <Button
                  className="button"
                  width={120}
                  height={32}
                  appearance="minimal"
                  iconBefore={<KeyIcon />}
                  onClick={() => this.handleSetPinDialogVisibility("open")}
                >
                  Set PIN
                </Button>
              </React.Fragment>
            )}
          </div>
          {/* <div className="MDOD-A1-horizontal-line-decorator-margin-bottom-0" />
          <div className="MDOD-A1-row-top-16-container">
            <Text id="MDOD-A1-dark-label">Linked Accounts</Text>
          </div>
          <div className="MDOD-A1-row-top-16-container">
            <Button
              className="button"
              width={120}
              height={32}
              appearance="minimal"
              iconBefore="add"
            >
              Link Account
            </Button>
          </div> */}
          <div className="MDOD-A1-horizontal-line-decorator-margin-bottom-0" />
          <div className="MDOD-A1-row-top-16-container">
            <Text id="MDOD-A1-dark-label">Subscribed Packages</Text>
          </div>
          <div className="MDOD-A1-row-top-16-container">
            <Dialog
              width="auto"
              isShown={showMembershipPackagesDialog}
              title="Select Package"
              onCloseComplete={() =>
                this.handleshowMembershipPackagesDialogVisibility("close")
              }
              hasFooter={false}
            >
              <MembershipPackagesDialogContent
                updateIndividualMemberDetails={true}
                concatedMembershipPackageDetails={
                  concatedMembershipPackageDetails
                }
                handleshowMembershipPackagesDialogVisibility={
                  this.handleshowMembershipPackagesDialogVisibility
                }
                handleMembershipPackagesCheckoutPageDisplay={
                  handleMembershipPackagesCheckoutPageDisplay
                }
                selectedMemberDetails={{
                  memberName: individualMemberDetails.memberName,
                  memberEmail: individualMemberDetails.memberEmail,
                  memberMobile: individualMemberDetails.memberMobile,
                  memberId: individualMemberDetails.memberId,
                }}
                handleGetMembershipPackageDetailsResponse={
                  this.handleGetMembershipPackageDetailsResponse
                }
              />
            </Dialog>
            <Button
              className="button"
              width={120}
              height={32}
              appearance="minimal"
              iconBefore={<AddIcon />}
              onClick={() =>
                this.handleshowMembershipPackagesDialogVisibility("open")
              }
            >
              Add Package
            </Button>
          </div>
          {individualMemberDetails.packages &&
            individualMemberDetails.packages.length > 0 &&
            individualMemberDetails.packages.map((membershipPackage, index) => (
              <div className="MDOD-A1-row-top-16-container" key={index}>
                <div className="MDOD-A1-336-col-container">
                  <div className="MDOD-A1-336-row-container">
                    <div className="MDOD-A1-width-288 MDOD-A1-margin-right-24 MDOD-A1-align-self-center">
                      <Text id="MDOD-A1-label-type-four">
                        {membershipPackage.packageName}
                      </Text>
                    </div>
                    <IconButton
                      icon={<CogIcon />}
                      appearance="minimal"
                      iconSize={12}
                      width={24}
                      height={24}
                    />
                  </div>
                  <div className="MDOD-A1-width-288 MDOD-A1-margin-top-8">
                    <Text id="MDOD-A1-label-type-five">
                      {`Renew on ${toDateFormat32(
                        membershipPackage.renewalDate
                      )}`}
                    </Text>
                  </div>
                </div>
              </div>
            ))}
        </div>
        <div className="MDOD-A1-member-details-actions-wrapper">
          <MemberDetailsDrawerActions
            handleSeeBookingsFromMembersDrawer={
              handleSeeBookingsFromMembersDrawer
            }
            memberMobile={individualMemberDetails.memberMobile}
          />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ membershipPackageDetails }) => ({
  membershipPackageDetails,
});

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default connect(mapStateToProps)(MemberDetailsOnDrawer);
