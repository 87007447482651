// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import { Text, Paragraph } from "evergreen-ui";

// -----------------------------------------------------------------------
// ReportsSummary Component
// -----------------------------------------------------------------------
class ReportsSummary extends React.Component {
  // -----------------------------------------------------------------------
  // render()
  // -----------------------------------------------------------------------
  render() {
    const { reportTypeHeading, reportSummary } = this.props;
    return (
      <React.Fragment>
        <div className="RS-A1-wrapper">
          <div className="RS-A1-report-type-container">
            <Text id="RS-A1-type-one-label">{reportTypeHeading}</Text>
          </div>
          <div className="RS-A1-report-summary-paragraph-container">
            <Paragraph id="RS-A1-type-two-label">{reportSummary}</Paragraph>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default ReportsSummary;
