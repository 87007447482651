// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import { connect } from "react-redux";
import { Text, Table, Button } from "evergreen-ui";
import {
  slotTimeConverter3,
  slotDateConverter4,
  slotTimeConverter12
} from "../../utils";
import CustomizedPagination from "../../components/CustomizedPagination";

// -----------------------------------------------------------------------
// BlockingTable Component
// -----------------------------------------------------------------------
class BlockingTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      daysData: {
        1: "Mon",
        2: "Tue",
        3: "Wed",
        4: "Thu",
        5: "Fri",
        6: "Sat",
        0: "Sun"
      }
    };
  }

  // -----------------------------------------------------------------------
  // render()
  // -----------------------------------------------------------------------
  render() {
    const {
      clubActivityData,
      pageNum,
      handlePageChange,
      blockingSearchResult,
      selectedActivityValue,
      handleUnblocking
    } = this.props;
    return (
      <React.Fragment>
        <div className="ST-A1-wrapper">
          <div className="ST-A1-table-container">
            <Table>
              <Table.Head>
                <Table.TextHeaderCell className="ST-A1-table-text-header-cell ST-A1-flex-basis-230">
                  Court
                </Table.TextHeaderCell>
                <Table.TextHeaderCell className="ST-A1-table-text-header-cell ST-A1-flex-basis-185">
                  Dates / Days
                </Table.TextHeaderCell>
                <Table.TextHeaderCell className="ST-A1-table-text-header-cell ST-A1-flex-basis-185">
                  Timing / Slots
                </Table.TextHeaderCell>
                <Table.TextHeaderCell className="ST-A1-table-text-header-cell ST-A1-flex-basis-310">
                  Remarks
                </Table.TextHeaderCell>
                <Table.TextHeaderCell className="ST-A1-table-text-header-cell ST-A1-flex-basis-128" />
              </Table.Head>
              <Table.Body className="ST-A1-table-body" overflow="none">
                {blockingSearchResult &&
                  blockingSearchResult.blockingDetails &&
                  blockingSearchResult.blockingDetails.map(
                    (blocking, index) => (
                      <Table.Row className="ST-A1-table-row" key={index}>
                        <Table.Cell className="ST-A1-table-cell ST-A1-flex-basis-230">
                          <div className="ST-A1-table-cell-data-container">
                            <Text id="ST-A1-label-type-one">
                              {blocking.courts.map((court, index) => {
                                if (index === blocking.courts.length - 1) {
                                  return court;
                                } else return court + ", ";
                              })}
                            </Text>
                            <Text id="ST-A1-label-type-two" marginTop={8}>
                              {
                                clubActivityData.activities[
                                  selectedActivityValue
                                ]
                              }
                            </Text>
                          </div>
                        </Table.Cell>
                        <Table.Cell className="ST-A1-table-cell ST-A1-flex-basis-185">
                          <div className="ST-A1-table-cell-data-container">
                            <Text id="ST-A1-label-type-one">
                              {blocking.startDate === blocking.endDate
                                ? slotTimeConverter3(blocking.startDate)
                                : slotDateConverter4(
                                    blocking.startDate,
                                    blocking.endDate
                                  )}
                            </Text>
                            <Text marginTop={8} id="ST-A1-label-type-two">
                              {blocking.days.length === 7
                                ? "All"
                                : blocking.days.map((day, index) => {
                                    if (index === blocking.days.length - 1) {
                                      return this.state.daysData[day];
                                    } else {
                                      return this.state.daysData[day] + ", ";
                                    }
                                  })}
                            </Text>
                          </div>
                        </Table.Cell>
                        <Table.Cell className="ST-A1-table-cell ST-A1-flex-basis-185">
                          <div className="ST-A1-table-cell-data-container">
                            <Text id="ST-A1-label-type-one">
                              {slotTimeConverter12(
                                blocking.startTime,
                                blocking.endTime
                              )}
                            </Text>
                            <Text id="ST-A1-label-type-two" marginTop={8}>
                              {`${blocking.slotCount} Slots`}
                            </Text>
                          </div>
                        </Table.Cell>
                        <Table.Cell className="ST-A1-table-cell ST-A1-flex-basis-310">
                          <div className="ST-A1-table-cell-data-container">
                            <Text id="ST-A1-label-type-two">
                              {blocking.remarks.trim().length > 0
                                ? blocking.remarks
                                : "No Remark"}
                            </Text>
                          </div>
                        </Table.Cell>
                        <Table.Cell className="ST-A1-table-cell ST-A1-flex-basis-128">
                          <div className="ST-A1-table-cell-data-container">
                            <Button
                              className="button"
                              width={88}
                              height={32}
                              appearance="primary"
                              intent="warning"
                              value={blocking.blockingId}
                              onClick={e => handleUnblocking(e)}
                            >
                              Unblock
                            </Button>
                          </div>
                        </Table.Cell>
                      </Table.Row>
                    )
                  )}
              </Table.Body>
            </Table>
          </div>
          <div className="ST-A1-pagination-wrapper">
            <CustomizedPagination
              currentPage={pageNum}
              totalPages={
                blockingSearchResult &&
                blockingSearchResult.paginationData &&
                blockingSearchResult.paginationData.total
              }
              handlePageChange={handlePageChange}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ clubActivityData }) => ({ clubActivityData });

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default connect(mapStateToProps)(BlockingTable);
