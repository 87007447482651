// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import { connect } from "react-redux";
import {
  Text,
  InlineAlert,
  Button,
  toaster,
  Select,
  ArrowLeftIcon,
  Pane,
} from "evergreen-ui";
import {
  toDateTimeFormat,
  toDateFormat1,
  toTimeFormat,
  checkIntOrFloat,
  resourcesNotLoadedToast,
} from "../../utils";
import CustomizedDatePicker from "../CustomizedDatePicker";
import CustomizedTimePicker from "../CustomizedTimePicker";
import {
  verifyBookingRescheduleAvailability,
  getBookingReschedulePricing,
  confirmBookingReschedule,
  togglePremiumFeatureFlag,
} from "../../actions";
import RescheduleHistoryDialogue from "./RescheduleHistoryDialogue";

// -----------------------------------------------------------------------
// RescheduleBookingDialogContent Component
// -----------------------------------------------------------------------
class RescheduleBookingDialogContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clubId: 1,
      date: new Date(),
      time: new Date(),
      clubMinuteStep: 15,
      rescheduleAvailabilityData: [],
      availableCourtList: [],
      showCourtSelect: false,
      showCheckAvailabilityBtn: true,
      showRefreshBtn: false,
      isDatePickerDisabled: false,
      isTimePickerDisabled: false,
      selectedCourtValue: null,
      showSlotsAvailableAlert: false,
      showSlotsUnavailableAlert: false,
      showSelectACourtAlert: false,
      showFooter: false,
      isDialogShown: false,
    };
  }

  handleButtonClick = () => {
    this.setState({ isDialogShown: true });
  };

  handleCloseDialog = () => {
    this.setState({ isDialogShown: false });
  };

  // -----------------------------------------------------------------------
  // handleDateChange()
  // -----------------------------------------------------------------------
  handleDateChange = (date) => {
    this.setState({ date: date });
  };

  // -----------------------------------------------------------------------
  // handleTimeChange()
  // -----------------------------------------------------------------------
  handleTimeChange = (time) => {
    this.setState({ time: time });
  };

  // -----------------------------------------------------------------------
  // generateAvailableCourtList()
  // -----------------------------------------------------------------------
  generateAvailableCourtList = async (rescheduleAvailabilityData) => {
    const courtList = Object.keys(rescheduleAvailabilityData).map((key) => {
      return {
        courtName: rescheduleAvailabilityData[key].courtName,
        value: key,
      };
    });
    await this.setState({ availableCourtList: courtList });
  };

  // -----------------------------------------------------------------------
  // handleCheckAvailability()
  // -----------------------------------------------------------------------
  handleCheckAvailability = async (e) => {
    e.persist();
    let reqBody = {
      bookingId: this.props.bookingId,
      rescheduleDate: toDateFormat1(this.state.date),
      startTime: toTimeFormat(this.state.time),
    };
    await this.props.dispatch(verifyBookingRescheduleAvailability(reqBody));
    if (this.props.verifyBookingRescheduleAvailabilityData) {
      if (
        this.props.verifyBookingRescheduleAvailabilityData
          .resourcesNotLoadedMessage
      ) {
        resourcesNotLoadedToast();
      } else if (
        this.props.verifyBookingRescheduleAvailabilityData.httpErrorMessage
      ) {
        toaster.danger(
          this.props.verifyBookingRescheduleAvailabilityData.httpErrorMessage,
          {
            id: "HTTP-ERROR-TOAST-1",
          }
        );
      } else if (
        this.props.verifyBookingRescheduleAvailabilityData.requestStatus === -1
      ) {
        toaster.notify("Sorry, you don't have permission to do that");
      } else if (
        this.props.verifyBookingRescheduleAvailabilityData.requestStatus === 1
      ) {
        this.setState({ showSelectACourtAlert: true, showFooter: true });
        toaster.success(
          this.props.verifyBookingRescheduleAvailabilityData.message,
          { id: "RBDC-TOAST-1" }
        );
        await this.setState({
          rescheduleAvailabilityData:
            this.props.verifyBookingRescheduleAvailabilityData
              .rescheduleAvailability,
        });
        await this.generateAvailableCourtList(
          this.props.verifyBookingRescheduleAvailabilityData
            .rescheduleAvailability
        );
        await this.setState({
          showCourtSelect: true,
          showCheckAvailabilityBtn: false,
          showRefreshBtn: true,
          isDatePickerDisabled: true,
          isTimePickerDisabled: true,
        });
      } else if (
        this.props.verifyBookingRescheduleAvailabilityData.requestStatus === 0
      ) {
        toaster.danger(
          this.props.verifyBookingRescheduleAvailabilityData.message,
          { id: "RBDC-TOAST-2" }
        );
      }
    }
  };

  // -----------------------------------------------------------------------
  // handleCourtChange()
  // -----------------------------------------------------------------------
  handleCourtChange = async (courtValue) => {
    if (courtValue !== "select-court") {
      this.setState({
        showSelectACourtAlert: false,
        showSlotsAvailableAlert: false,
        showSlotsUnavailableAlert: false,
      });
      await this.setState({ selectedCourtValue: parseInt(courtValue) });
      let reqBody = {
        bookingId: this.props.bookingId,
        rescheduleDate: toDateFormat1(this.state.date),
        slots:
          this.state.rescheduleAvailabilityData[this.state.selectedCourtValue]
            .slots,
      };
      await this.props.dispatch(getBookingReschedulePricing(reqBody));
      if (this.props.bookingReschedulePricingData) {
        if (this.props.bookingReschedulePricingData.resourcesNotLoadedMessage) {
          resourcesNotLoadedToast();
        } else if (this.props.bookingReschedulePricingData.httpErrorMessage) {
          toaster.danger(
            this.props.bookingReschedulePricingData.httpErrorMessage,
            {
              id: "HTTP-ERROR-TOAST-1",
            }
          );
        } else if (
          this.props.bookingReschedulePricingData.requestStatus === -1
        ) {
          toaster.notify("Sorry, you don't have permission to do that");
        } else if (
          this.props.bookingReschedulePricingData.requestStatus === 1
        ) {
          this.setState({
            showSelectACourtAlert: false,
            showSlotsAvailableAlert: true,
            showSlotsUnavailableAlert: false,
          });
        } else if (
          this.props.bookingReschedulePricingData.requestStatus === 0
        ) {
          this.setState({
            showSelectACourtAlert: false,
            showSlotsAvailableAlert: false,
            showSlotsUnavailableAlert: true,
            showFooter: false,
          });
        }
      }
    } else {
      this.setState({
        showSelectACourtAlert: true,
        showSlotsAvailableAlert: false,
        showSlotsUnavailableAlert: false,
      });
    }
  };

  // -----------------------------------------------------------------------
  // handleRefreshButton()
  // -----------------------------------------------------------------------
  handleRefreshButton = (e) => {
    e.persist();
    this.setState({
      isDatePickerDisabled: false,
      isTimePickerDisabled: false,
      showCourtSelect: false,
      showSelectACourtAlert: false,
      showSlotsAvailableAlert: false,
      showSlotsUnavailableAlert: false,
      showRefreshBtn: false,
      showCheckAvailabilityBtn: true,
      showFooter: false,
      date: new Date(),
      time: new Date(),
    });
  };

  // -----------------------------------------------------------------------
  // handleConfirmBookingReschedule()
  // -----------------------------------------------------------------------
  handleConfirmBookingReschedule = async (e) => {
    e.persist();
    if (this.props.bookingReschedulePricingData.requestStatus === 1) {
      let reqBody = {
        clubId: this.state.clubId,
        bookingId: this.props.bookingId,
        reschedulePrice: checkIntOrFloat(
          this.props.bookingReschedulePricingData.reschedulePrice
        ),
        slots: this.props.bookingReschedulePricingData.slots,
      };
      await this.props.dispatch(confirmBookingReschedule(reqBody));
      if (this.props.confirmBookingRescheduleData) {
        if (this.props.confirmBookingRescheduleData.resourcesNotLoadedMessage) {
          resourcesNotLoadedToast();
        } else if (this.props.confirmBookingRescheduleData.httpErrorMessage) {
          toaster.danger(
            this.props.confirmBookingRescheduleData.httpErrorMessage,
            {
              id: "HTTP-ERROR-TOAST-1",
            }
          );
        } else if (
          this.props.confirmBookingRescheduleData.requestStatus === -1
        ) {
          toaster.notify("Sorry, you don't have permission to do that");
        } else if (
          this.props.confirmBookingRescheduleData.requestStatus === 1
        ) {
          if (this.props.updateCalendarPage) {
            this.props.updateCalendarPage();
          }
          if (this.props.updateBookingDetails) {
            await this.props.updateBookingDetails();
          }
          if (this.props.updateSearchPage) {
            await this.props.updateSearchPage();
          }
          if (this.props.handleGetBookingDetails) {
            await this.props.handleGetBookingDetails(e, this.props.bookingId);
          }
          await this.props.handleRescheduleBookingDialog("close");
          await toaster.success(
            this.props.confirmBookingRescheduleData.message
          );
        } else if (
          this.props.confirmBookingRescheduleData.requestStatus === 0
        ) {
          toaster.danger(this.props.confirmBookingRescheduleData.message);
        }
      }
    }
  };

  // -----------------------------------------------------------------------
  // render()
  // -----------------------------------------------------------------------
  render() {
    const {
      date,
      time,
      clubMinuteStep,
      availableCourtList,
      showCourtSelect,
      showCheckAvailabilityBtn,
      showRefreshBtn,
      isDatePickerDisabled,
      isTimePickerDisabled,
      showSelectACourtAlert,
      showSlotsAvailableAlert,
      showSlotsUnavailableAlert,
      showFooter,
    } = this.state;
    const {
      courts,
      dateBooked,
      startTime,
      endTime,
      handleRescheduleBookingDialog,
    } = this.props;
    return (
      <React.Fragment>
        <div className="RBDC-A1-wrapper">
          <div className="RBDC-A1-body-container">
            <div className="RBDC-A1-row-container">
              <div className="RBDC-A1-col-container">
                <div className="RBDC-A1-text-container">
                  <Text id="RBDC-A1-large-dark-label">Booking Details</Text>
                </div>
                <div className="RBDC-A1-text-container">
                  <Text id="RBDC-A1-large-light-label">
                    {`${courts} | ${toDateTimeFormat(
                      dateBooked,
                      startTime,
                      endTime
                    )}`}
                  </Text>
                </div>
              </div>
              {this.props.rescheduleHistory.length > 0 && (
                <Pane
                  display={"flex"}
                  alignItems={"start"}
                  justifyContent={"fles-end"}
                >
                  <Button
                    marginLeft={40}
                    border={"none"}
                    onClick={this.handleButtonClick}
                    color={"primary"}
                    textDecoration={"underline"}
                  >
                    See Reschedule History
                  </Button>
                </Pane>
              )}

              <RescheduleHistoryDialogue
                rescheduleHistory={this.props.rescheduleHistory}
                isShown={this.state.isDialogShown}
                onClose={this.handleCloseDialog}
              />
            </div>
            <div className="RBDC-A1-row-container">
              <div className="RBDC-A1-col-container">
                <div className="RBDC-A1-text-container">
                  <Text id="RBDC-A1-large-dark-label">Reschedule Details</Text>
                </div>
                <div className="RBDC-A1-row-container RBDC-A1-reschedule-details-actions-container">
                  <div className="RBDC-A1-col-container RBDC-A1-small-col-container">
                    <Text marginBottom={8} id="RBDC-A1-medium-light-label">
                      Date
                    </Text>
                    <CustomizedDatePicker
                      date={date}
                      handleDateChange={this.handleDateChange}
                      isDatePickerDisabled={isDatePickerDisabled}
                    />
                  </div>
                  <div className="RBDC-A1-col-container RBDC-A1-small-col-container">
                    <Text marginBottom={8} id="RBDC-A1-medium-light-label">
                      Time
                    </Text>
                    <CustomizedTimePicker
                      time={time}
                      clubMinuteStep={clubMinuteStep}
                      handleTimeChange={this.handleTimeChange}
                      isTimePickerDisabled={isTimePickerDisabled}
                    />
                  </div>
                  {showRefreshBtn && (
                    <div className="RBDC-A1-col-container RBDC-A1-small-col-container RBDC-A1-refresh-btn-container">
                      <Button
                        className="button"
                        width={144}
                        height={32}
                        appearance="minimal"
                        intent="none"
                        iconBefore={<ArrowLeftIcon />}
                        onClick={(e) => this.handleRefreshButton(e)}
                      >
                        Return
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {showCourtSelect && (
              <div className="RBDC-A1-row-container">
                <div className="RBDC-A1-col-container RBDC-A1-small-col-container">
                  <Text marginBottom={8} id="RBDC-A1-medium-light-label">
                    Court
                  </Text>
                  <Select
                    minWidth={112}
                    minHeight={32}
                    onChange={(e) => this.handleCourtChange(e.target.value)}
                  >
                    <option value="select-court">Select Court</option>
                    {availableCourtList.map((court) => (
                      <option key={court.value} value={court.value}>
                        {court.courtName}
                      </option>
                    ))}
                  </Select>
                </div>
              </div>
            )}
            {showSelectACourtAlert && (
              <div className="RBDC-A1-row-container RDBC-A1-alert-container">
                <InlineAlert intent="none">Select a court</InlineAlert>
              </div>
            )}
            {showSlotsAvailableAlert && (
              <div className="RBDC-A1-row-container RDBC-A1-alert-container">
                <InlineAlert intent="success">
                  {this.props.bookingReschedulePricingData.message}
                </InlineAlert>
              </div>
            )}
            {showSlotsUnavailableAlert && (
              <div className="RBDC-A1-row-container RDBC-A1-alert-container">
                <InlineAlert intent="danger">
                  {this.props.bookingReschedulePricingData.message}
                </InlineAlert>
              </div>
            )}
            {showCheckAvailabilityBtn && (
              <div className="RBDC-A1-row-container RBDC-A1-check-availability-btn-container">
                <Button
                  className="button"
                  width={144}
                  height={32}
                  appearance="primary"
                  onClick={(e) => this.handleCheckAvailability(e)}
                >
                  Check Availability
                </Button>
              </div>
            )}
          </div>
          {showFooter && (
            <div className="RBDC-A1-footer-container">
              <Button
                className="button"
                width={72}
                height={32}
                marginRight={12}
                onClick={() => handleRescheduleBookingDialog("close")}
              >
                Cancel
              </Button>
              <Button
                className="button"
                appearance="primary"
                intent="success"
                width={88}
                height={32}
                onClick={(e) => this.handleConfirmBookingReschedule(e)}
              >
                Confirm
              </Button>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({
  verifyBookingRescheduleAvailabilityData,
  bookingReschedulePricingData,
  confirmBookingRescheduleData,
}) => ({
  verifyBookingRescheduleAvailabilityData,
  bookingReschedulePricingData,
  confirmBookingRescheduleData,
});

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default connect(mapStateToProps)(RescheduleBookingDialogContent);
