// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import { connect } from "react-redux";
import {
  Text,
  Table,
  Avatar,
  IconButton,
  Popover,
  Menu,
  Dialog,
  Paragraph,
  AddToFolderIcon,
  EditIcon,
  TrashIcon,
  MoreIcon,
} from "evergreen-ui";
import {
  toDateFormat32,
  checkIntOrFloat,
  validateCreditsAndPrice,
  resourcesNotLoadedToast,
} from "../../utils";
import CreditsRechargeDialogContent from "../../components/CreditsRechargeDialogContent";
import UpdateMemberDetailsDialogContent from "../../components/UpdateMemberDetailsDialogContent";
import SubscribedPackagesDialogContent from "../../components/SubscribedPackagesDialogContent";
import PayNowDialogContent from "../../components/PayNowDialogContent";
import { toaster } from "evergreen-ui/commonjs/toaster";
import {
  membershipSubscriptionPaymentUpdate,
  getMembersSubscribedPackages,
  getMembershipPackageDetails,
  togglePremiumFeatureFlag,
} from "../../actions";
import MembershipPackagesDialogContent from "../../components/MembershipPackagesDialogContent";
import CustomizedPagination from "../../components/CustomizedPagination";

// -----------------------------------------------------------------------
// MembersTable Component
// -----------------------------------------------------------------------
class MembersTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clubId: 1,
      pageNumOne: 1,
      defaultPageNum: 1,
      showCreditsRechargeDialog: false,
      activeMemberMobile: "",
      activeMemberId: "",
      activeMemberName: "",
      activeMemberEmail: "",
      activeMemberAddress: "",
      activeMemberGenderValue: "",
      activeMemberExpiryDate: "",
      showAvailedMembershipPackagesDialog: false,
      selectedMemberDetails: {},
      showPayNowDialog: false,
      payNowData: {},
      paymentMode: "No Pay",
      transactionMode: 0,
      selectedTransactionMode: 0,
      disableNoPay: false,
      payNowAmount: "",
      isPayNowInvalid: false,
      showMembershipPackagesDialog: false,
      concatedMembershipPackageDetails: [],
    };
  }

  // -----------------------------------------------------------------------
  // handleCreditsRechargeDialog()
  // -----------------------------------------------------------------------
  handleCreditsRechargeDialog = (type) => {
    if (type === "open") {
      this.setState({ showCreditsRechargeDialog: true });
    } else {
      this.setState({ showCreditsRechargeDialog: false });
    }
  };

  // -----------------------------------------------------------------------
  // changeActiveMemberDetails()
  // -----------------------------------------------------------------------
  changeActiveMemberDetails = async (member) => {
    await this.setState({
      activeMemberMobile: member.memberMobile,
      activeMemberId: member.memberId,
      activeMemberName: member.memberName,
      activeMemberEmail: member.memberEmail,
      activeMemberAddress: member.address,
      activeMemberGenderValue: member.memberGender,
      activeMemberExpiryDate: member.endDate,
    });
  };

  // -----------------------------------------------------------------------
  // handleshowAvailedMembershipPackagesDialogVisibility()
  // -----------------------------------------------------------------------
  handleshowAvailedMembershipPackagesDialogVisibility = async (
    type,
    selectedMemberDetails = {}
  ) => {
    if (type === "open") {
      await this.setState({ selectedMemberDetails: selectedMemberDetails });
      let reqBody = {
        memberIds: [selectedMemberDetails.memberId],
      };
      await this.props.dispatch(getMembersSubscribedPackages(reqBody));
      if (this.props.membersSubscribedPackagesData) {
        if (
          this.props.membersSubscribedPackagesData.resourcesNotLoadedMessage
        ) {
          resourcesNotLoadedToast();
        } else if (this.props.membersSubscribedPackagesData.httpErrorMessage) {
          toaster.danger(
            this.props.membersSubscribedPackagesData.httpErrorMessage,
            {
              id: "HTTP-ERROR-TOAST-4",
            }
          );
        } else if (
          this.props.membersSubscribedPackagesData.requestStatus === -1
        ) {
          toaster.notify("Sorry, you don't have permission to do that");
        } else if (
          this.props.membersSubscribedPackagesData.requestStatus === 0
        ) {
          toaster.danger(this.props.membersSubscribedPackagesData.message, {
            id: "USER-TOAST",
          });
        } else if (
          this.props.membersSubscribedPackagesData.requestStatus === 1
        ) {
          await this.setState({ showAvailedMembershipPackagesDialog: true });
        }
      }
    } else {
      this.setState({ showAvailedMembershipPackagesDialog: false });
    }
  };

  // -----------------------------------------------------------------------
  // handlePayNowDialogVisibility()
  // -----------------------------------------------------------------------
  handlePayNowDialogVisibility = async (
    type,
    subscriptionId = null,
    balance = null
  ) => {
    if (type === "open") {
      await this.setState({
        payNowData: { subscriptionId: subscriptionId, balance: balance },
      });
      await this.setState({
        showPayNowDialog: true,
        showAvailedMembershipPackagesDialog: false,
      });
    } else if (type === "close") {
      await this.setState({
        showPayNowDialog: false,
        showAvailedMembershipPackagesDialog: true,
      });
    }
  };

  // -----------------------------------------------------------------------
  // changeTransactionMode()
  // -----------------------------------------------------------------------
  changeTransactionMode = (e) => {
    e.persist();
    if (this.state.payNowAmount.length > 0) {
      this.setState({
        paymentMode:
          e.nativeEvent.target[
            e.nativeEvent.target.selectedIndex
          ].text.toString(),
        transactionMode: parseInt(e.target.value),
        selectedTransactionMode: parseInt(e.target.value),
        isPayNowInvalid: false,
      });
    } else {
      this.setState({ isPayNowInvalid: true });
    }
  };

  // -----------------------------------------------------------------------
  //  handlePayNow()
  // -----------------------------------------------------------------------
  handlePayNow = async (e, type) => {
    if (type === "amount-change") {
      if (validateCreditsAndPrice(e.target.value) === "set") {
        await this.setState({
          payNowAmount: e.target.value,
          isPayNowInvalid: false,
        });
        if (
          this.state.selectedTransactionMode === 0 &&
          this.state.payNowAmount > 0
        ) {
          await this.setState({
            paymentMode: "Cash",
            transactionMode: 1,
            selectedTransactionMode: 1,
            disableNoPay: true,
          });
        }
        if (this.state.payNowAmount <= 0) {
          await this.setState({
            paymentMode: "No Pay",
            transactionMode: 0,
            selectedTransactionMode: 0,
            disableNoPay: false,
          });
        }
      } else if (validateCreditsAndPrice(e.target.value) === "clear") {
        await this.setState({
          payNowAmount: "",
          paymentMode: "No Pay",
          transactionMode: 0,
          selectedTransactionMode: 0,
          disableNoPay: false,
        });
      }
    }
    if (type === "confirm-payment") {
      if (this.state.payNowAmount <= 0) {
        toaster.warning("Amount must be greater than 0", { id: "MT-TOAST-1" });
      } else if (this.state.payNowAmount > this.state.payNowData.balance) {
        toaster.warning("Amount cannot be greater than balance", {
          id: "MT-TOAST-1",
        });
      } else if (this.state.payNowAmount !== "") {
        let reqBodyForMembershipSubscriptionPaymentUpdate = {
          subscriptionId: this.state.payNowData.subscriptionId,
          amount: checkIntOrFloat(this.state.payNowAmount),
          transactionData: {
            mode: this.state.transactionMode,
            type: 3,
          },
        };
        await this.props.dispatch(
          membershipSubscriptionPaymentUpdate(
            reqBodyForMembershipSubscriptionPaymentUpdate
          )
        );
        if (this.props.membershipSubscriptionPaymentUpdateToast) {
          if (
            this.props.membershipSubscriptionPaymentUpdateToast
              .resourcesNotLoadedMessage
          ) {
            resourcesNotLoadedToast();
          } else if (
            this.props.membershipSubscriptionPaymentUpdateToast.httpErrorMessage
          ) {
            toaster.danger(
              this.props.membershipSubscriptionPaymentUpdateToast
                .httpErrorMessage,
              {
                id: "HTTP-ERROR-TOAST-4",
              }
            );
          } else if (
            this.props.membershipSubscriptionPaymentUpdateToast
              .requestStatus === -1
          ) {
            toaster.notify("Sorry, you don't have permission to do that");
          } else if (
            this.props.membershipSubscriptionPaymentUpdateToast
              .requestStatus === 0
          ) {
            toaster.danger(
              this.props.membershipSubscriptionPaymentUpdateToast.message,
              {
                id: "USER-TOAST",
              }
            );
          } else if (
            this.props.membershipSubscriptionPaymentUpdateToast
              .requestStatus === 1
          ) {
            let reqBodyForGetMembersSubscribedPackages = {
              memberIds: [this.state.selectedMemberDetails.memberId],
            };
            await this.props.dispatch(
              getMembersSubscribedPackages(
                reqBodyForGetMembersSubscribedPackages
              )
            );
            await toaster.success(
              this.props.membershipSubscriptionPaymentUpdateToast.message,
              { id: "MT-TOAST-2" }
            );
            this.handlePayNowDialogVisibility("close");
          }
        }
      } else {
        toaster.warning("Amount is required", { id: "MT-TOAST-1" });
      }
    }
  };

  // -----------------------------------------------------------------------
  // getNextPageMembershipPackages()
  // -----------------------------------------------------------------------
  getNextPageMembershipPackages = async (pageNum) => {};

  // -----------------------------------------------------------------------
  // generateConcatedMembershipPackages()
  // -----------------------------------------------------------------------
  generateConcatedMembershipPackages = async (membershipPackages) => {
    let tempList = JSON.parse(
      JSON.stringify(this.state.concatedMembershipPackageDetails)
    );
    await this.setState({
      concatedMembershipPackageDetails: tempList.concat(membershipPackages),
    });
  };

  // -----------------------------------------------------------------------
  // handleshowMembershipPackagesDialogVisibility()
  // -----------------------------------------------------------------------
  handleshowMembershipPackagesDialogVisibility = async (type) => {
    if (type === "open") {
      let reqParams = {
        page: this.state.defaultPageNum,
      };
      await this.handleGetMembershipPackageDetailsResponse(reqParams);
    } else {
      this.setState({
        showMembershipPackagesDialog: false,
        concatedMembershipPackageDetails: [],
      });
    }
  };

  // -----------------------------------------------------------------------
  // handleGetMembershipPackageDetailsResponse()
  // -----------------------------------------------------------------------
  handleGetMembershipPackageDetailsResponse = async (reqParams) => {
    await this.props.dispatch(getMembershipPackageDetails(reqParams, true));
    if (this.props.membershipPackageDetails) {
      if (this.props.membershipPackageDetails.resourcesNotLoadedMessage) {
        resourcesNotLoadedToast();
      } else if (this.props.membershipPackageDetails.httpErrorMessage) {
        toaster.danger(this.props.membershipPackageDetails.httpErrorMessage, {
          id: "HTTP-ERROR-TOAST-4",
        });
      } else if (this.props.membershipPackageDetails.requestStatus === -1) {
        toaster.notify("Sorry, you don't have permission to do that");
      } else if (this.props.membershipPackageDetails.requestStatus === 0) {
        toaster.danger(this.props.membershipPackageDetails.message, {
          id: "USER-TOAST",
        });
      } else if (this.props.membershipPackageDetails.requestStatus === 1) {
        await this.generateConcatedMembershipPackages(
          this.props.membershipPackageDetails.packages
        );
        await this.setState({ showMembershipPackagesDialog: true });
      }
    }
  };

  // -----------------------------------------------------------------------
  // render()
  // -----------------------------------------------------------------------
  render() {
    const {
      showCreditsRechargeDialog,
      pageNumOne,
      activeMemberMobile,
      activeMemberEmail,
      activeMemberName,
      activeMemberId,
      showAvailedMembershipPackagesDialog,
      selectedMemberDetails,
      showPayNowDialog,
      payNowData,
      payNowAmount,
      showMembershipPackagesDialog,
      selectedTransactionMode,
      disableNoPay,
      isPayNowInvalid,
      concatedMembershipPackageDetails,
    } = this.state;
    const {
      memberDetails,
      handleGetIndividualMemberDetails,
      clubId,
      pageNumOnMembersPage,
      handlePageChange,
      showDeleteMemberDialog,
      handleDeleteMemberDialog,
      confirmDeleteMember,
      memberMobileInputValue,
      isMobileInvalid,
      showInvalidMobileDiv,
      isNameInvalid,
      isNameInvalidMsg,
      isMobileRequiredMsg,
      memberName,
      isEmailInvalid,
      isEmailInvalidMsg,
      memberEmail,
      memberAddress,
      checkedGenderValue,
      memberExpiryDate,
      onChangeOfMobileInput,
      onBlurOfMobileInput,
      onChangeOfNameInput,
      onChangeOfEmailInput,
      validateEmail,
      shakeOnInvalid,
      onCountryChange,
      onChangeOfAddressInput,
      onChangeOfGenderRadioInput,
      showUpdateMemberDetailsDialogOnMembersPage,
      toggleUpdateMemberDetailsDialogVisibility,
      checkedMemberExpiryRadioValue,
      onChangeOfMemberExpiryRadioInput,
      handleUpdateMemberDetails,
      handleMemberExpiryDateChange,
      handleMembershipPackagesCheckoutPageDisplay,
      getUpdatedMemberDetails,
    } = this.props;
    return (
      <React.Fragment>
        <div className="MT-A1-wrapper" style={{ overflowX: "visible" }}>
          <div className="MT-A1-table-container">
            <Table>
              <Table.Head>
                <Table.TextHeaderCell className="MT-A1-table-text-header-cell">
                  Name
                </Table.TextHeaderCell>
                <Table.TextHeaderCell className="MT-A1-table-text-header-cell">
                  Contact Details
                </Table.TextHeaderCell>
                <Table.TextHeaderCell className="MT-A1-table-text-header-cell">
                  Credit Point/Validity
                </Table.TextHeaderCell>
                <Table.TextHeaderCell className="MT-A1-availed-packages-text-header-cell">
                  Availed Packages
                </Table.TextHeaderCell>
                <Table.TextHeaderCell className="MT-A1-table-text-header-cell">
                  Membership Validity
                </Table.TextHeaderCell>
                <Table.TextHeaderCell className="MT-A1-table-action-btn-text-header-cell" />
              </Table.Head>
              <Table.Body className="MT-A1-table-body" overflow="none">
                {memberDetails &&
                  memberDetails.members &&
                  memberDetails.members.map((member, index) => (
                    <Table.Row
                      isSelectable
                      className="MT-A1-table-row"
                      key={index}
                    >
                      <Table.Cell className="MT-A1-table-cell">
                        <div className="MT-A1-member-avatar-and-name-container">
                          <Avatar
                            className="MT-A1-avatar"
                            name={member.memberName}
                            size={24}
                            marginRight={12}
                          />
                          <Text
                            id="MT-A1-big-underline-label"
                            onClick={(e) =>
                              handleGetIndividualMemberDetails({
                                clubId: clubId,
                                page: pageNumOne,
                                mobile: member.memberMobile,
                              })
                            }
                          >
                            {member.memberName}
                          </Text>
                        </div>
                      </Table.Cell>
                      <Table.Cell className="MT-A1-table-cell">
                        <div className="MT-A1-member-mobile-and-email-container">
                          <Text id="MT-A1-small-label" marginBottom={8}>
                            {member.memberMobile !== ""
                              ? member.memberMobile
                              : "-"}
                          </Text>
                          <Text id="MT-A1-small-label" marginBottom={8}>
                            {member.memberEmail !== ""
                              ? member.memberEmail
                              : "-"}
                          </Text>
                        </div>
                      </Table.Cell>
                      <Table.Cell className="MT-A1-table-cell">
                        <div className="MT-A1-member-credits-and-validity-container">
                          <Text id="MT-A1-big-dark-label" marginBottom={8}>
                            {`${checkIntOrFloat(member.credits)} Pts`}
                          </Text>
                          <Text id="MT-A1-small-label" marginBottom={8}>
                            {member.creditsExpiry === "9999-12-31"
                              ? `Valid for lifetime`
                              : `Valid till ${toDateFormat32(
                                  member.creditsExpiry
                                )} `}
                          </Text>
                        </div>
                      </Table.Cell>
                      <Table.Cell className="MT-A1-availed-packages-table-cell">
                        <div
                          className="MT-A1-member-availed-packages-container"
                          key={index}
                          onClick={(e) =>
                            this.handleshowAvailedMembershipPackagesDialogVisibility(
                              "open",
                              {
                                memberName: member.memberName,
                                memberEmail: member.memberEmail,
                                memberMobile: member.memberMobile,
                                memberId: member.memberId,
                              }
                            )
                          }
                        >
                          {member.packages && member.packages.length > 0 ? (
                            member.packages
                              .slice(0, 2)
                              .map((membershipPackage, index) => (
                                <div
                                  className="MT-A1-membership-package-data-content"
                                  key={index}
                                >
                                  <Paragraph>
                                    {membershipPackage.balance > 0 ? (
                                      <Text
                                        id="MT-A1-small-uppercase-type-one-label"
                                        marginBottom={8}
                                      >
                                        {membershipPackage.status}
                                      </Text>
                                    ) : membershipPackage.status ===
                                      "Expired" ? (
                                      <Text
                                        id="MT-A1-small-uppercase-type-two-label"
                                        marginBottom={8}
                                      >
                                        {membershipPackage.status}
                                      </Text>
                                    ) : (
                                      <Text
                                        id="MT-A1-small-uppercase-type-three-label"
                                        marginBottom={8}
                                      >
                                        {`Renew on ${toDateFormat32(
                                          membershipPackage.renewalDate
                                        )}`}
                                      </Text>
                                    )}
                                    <Text
                                      id="MT-A1-small-label"
                                      marginBottom={8}
                                    >
                                      {membershipPackage.packageName}
                                    </Text>
                                  </Paragraph>
                                </div>
                              ))
                          ) : (
                            <div className="MT-A1-member-availed-packages-container">
                              <Text id="MT-A1-small-label" marginBottom={8}>
                                --
                              </Text>
                            </div>
                          )}
                          {member.packages && member.packages.length > 2 && (
                            <div className="MT-A1-availed-packages-more-information">
                              <Text
                                id="MT-A1-label-type-six"
                                marginBottom={8}
                                onClick={(e) =>
                                  this.handleshowAvailedMembershipPackagesDialogVisibility(
                                    "open",
                                    {
                                      memberName: member.memberName,
                                      memberEmail: member.memberEmail,
                                      memberMobile: member.memberMobile,
                                      memberId: member.memberId,
                                    }
                                  )
                                }
                              >
                                {`See All ${member.packages.length}`}
                              </Text>
                            </div>
                          )}
                        </div>
                      </Table.Cell>
                      <Table.Cell className="MT-A1-table-cell">
                        <div className="MT-A1-member-credits-and-validity-container">
                          <Text
                            size={400}
                            id="MT-A1-small-label"
                            marginTop={18}
                          >
                            {member.endDate === "9999-12-31"
                              ? `Valid for lifetime`
                              : ` ${toDateFormat32(member.endDate)} `}
                          </Text>
                        </div>
                      </Table.Cell>
                      <Table.Cell className="MT-A1-action-btn-container-table-cell">
                        <div className="MT-A1-member-actions-menu-and-btn-container">
                          <Popover
                            onOpen={() =>
                              this.changeActiveMemberDetails(member)
                            }
                            content={
                              <Menu>
                                <Menu.Group>
                                  {/*<Menu.Item*/}
                                  {/*icon="social-media"*/}
                                  {/*onSelect={() =>*/}
                                  {/*this.handleshowMembershipPackagesDialogVisibility(*/}
                                  {/*"open"*/}
                                  {/*)*/}
                                  {/*}*/}
                                  {/*>*/}
                                  {/*Package*/}
                                  {/*</Menu.Item>*/}
                                  {/*<Menu.Item icon="changes">*/}
                                  {/*Transactions*/}
                                  {/*</Menu.Item>*/}
                                  <Menu.Item
                                    icon={<AddToFolderIcon />}
                                    onSelect={() =>
                                      this.handleCreditsRechargeDialog("open")
                                    }
                                  >
                                    Recharge
                                  </Menu.Item>
                                  <Menu.Item
                                    icon={<EditIcon />}
                                    onSelect={() =>
                                      toggleUpdateMemberDetailsDialogVisibility(
                                        "open",
                                        "MembersPage",
                                        member
                                      )
                                    }
                                  >
                                    Edit Details
                                  </Menu.Item>
                                </Menu.Group>
                                <Menu.Divider />
                                <Menu.Group>
                                  <Menu.Item
                                    icon={<TrashIcon />}
                                    intent="danger"
                                    onSelect={() =>
                                      handleDeleteMemberDialog("open")
                                    }
                                  >
                                    Delete Member
                                  </Menu.Item>
                                </Menu.Group>
                              </Menu>
                            }
                          >
                            <IconButton
                              appearance="minimal"
                              icon={<MoreIcon />}
                              width={24}
                              height={24}
                            />
                          </Popover>
                        </div>
                      </Table.Cell>
                    </Table.Row>
                  ))}
              </Table.Body>
            </Table>
          </div>
          <div className="MT-A1-pagination-wrapper">
            <CustomizedPagination
              currentPage={pageNumOnMembersPage}
              totalPages={
                memberDetails &&
                memberDetails.paginationData &&
                memberDetails.paginationData.total
              }
              handlePageChange={handlePageChange}
            />
          </div>
        </div>
        <Dialog
          intent="danger"
          isShown={showDeleteMemberDialog}
          title="Warning"
          onCloseComplete={() => handleDeleteMemberDialog("close")}
          confirmLabel="Delete Member"
          onConfirm={() => confirmDeleteMember()}
        >
          <div className="dialog-msg-container">
            <Text id="MT-A1-label-type-seven">
              The member will be deleted. Do you wish to continue?
            </Text>
          </div>
        </Dialog>
        <Dialog
          isShown={showCreditsRechargeDialog}
          title="Recharge"
          onCloseComplete={() => this.handleCreditsRechargeDialog("close")}
          hasFooter={false}
        >
          <CreditsRechargeDialogContent
            clubId={clubId}
            pageNumOnMembersPage={pageNumOnMembersPage}
            memberMobile={activeMemberMobile}
            handleCreditsRechargeDialog={this.handleCreditsRechargeDialog}
          />
        </Dialog>
        <Dialog
          width={560}
          isShown={showUpdateMemberDetailsDialogOnMembersPage}
          title="Update Member Details"
          onConfirm={() =>
            handleUpdateMemberDetails("MembersPage", activeMemberMobile)
          }
          confirmLabel="Save Details"
          onCloseComplete={() =>
            toggleUpdateMemberDetailsDialogVisibility("close")
          }
        >
          <UpdateMemberDetailsDialogContent
            memberMobileInputValue={memberMobileInputValue}
            isMobileInvalid={isMobileInvalid}
            showInvalidMobileDiv={showInvalidMobileDiv}
            isNameInvalid={isNameInvalid}
            isNameInvalidMsg={isNameInvalidMsg}
            isMobileRequiredMsg={isMobileRequiredMsg}
            memberName={memberName}
            isEmailInvalid={isEmailInvalid}
            isEmailInvalidMsg={isEmailInvalidMsg}
            memberEmail={memberEmail}
            checkedGenderValue={checkedGenderValue}
            onChangeOfMobileInput={onChangeOfMobileInput}
            onBlurOfMobileInput={onBlurOfMobileInput}
            onChangeOfNameInput={onChangeOfNameInput}
            onChangeOfEmailInput={onChangeOfEmailInput}
            validateEmail={validateEmail}
            onChangeOfGenderRadioInput={onChangeOfGenderRadioInput}
            shakeOnInvalid={shakeOnInvalid}
            onCountryChange={onCountryChange}
            onChangeOfAddressInput={onChangeOfAddressInput}
            checkedMemberExpiryRadioValue={checkedMemberExpiryRadioValue}
            memberAddress={memberAddress}
            memberExpiryDate={memberExpiryDate}
            onChangeOfMemberExpiryRadioInput={onChangeOfMemberExpiryRadioInput}
            handleMemberExpiryDateChange={handleMemberExpiryDateChange}
          />
        </Dialog>
        <Dialog
          width="auto"
          isShown={showAvailedMembershipPackagesDialog}
          title="Subscribed Packages"
          onCloseComplete={() =>
            this.handleshowAvailedMembershipPackagesDialogVisibility("close")
          }
          hasFooter={false}
        >
          <SubscribedPackagesDialogContent
            handleshowAvailedMembershipPackagesDialogVisibility={
              this.handleshowAvailedMembershipPackagesDialogVisibility
            }
            selectedMemberDetails={selectedMemberDetails}
            handleMembershipPackagesCheckoutPageDisplay={
              handleMembershipPackagesCheckoutPageDisplay
            }
            handlePayNowDialogVisibility={this.handlePayNowDialogVisibility}
          />
        </Dialog>
        <Dialog
          width={560}
          isShown={showPayNowDialog}
          title="Pay Now"
          intent="success"
          onConfirm={(e) => this.handlePayNow(e, "confirm-payment")}
          onCloseComplete={(e) => this.handlePayNowDialogVisibility("close")}
        >
          <PayNowDialogContent
            changeTransactionMode={this.changeTransactionMode}
            balance={payNowData.balance}
            payNowAmount={payNowAmount}
            handlePayNow={this.handlePayNow}
            disableNoPay={disableNoPay}
            selectedTransactionMode={selectedTransactionMode}
            isPayNowInvalid={isPayNowInvalid}
          />
        </Dialog>
        <Dialog
          width="auto"
          isShown={showMembershipPackagesDialog}
          title="Select Package"
          onCloseComplete={() =>
            this.handleshowMembershipPackagesDialogVisibility("close")
          }
          hasFooter={false}
        >
          <MembershipPackagesDialogContent
            pageNumOnMembersPage={pageNumOnMembersPage}
            getUpdatedMemberDetails={getUpdatedMemberDetails}
            concatedMembershipPackageDetails={concatedMembershipPackageDetails}
            handleshowMembershipPackagesDialogVisibility={
              this.handleshowMembershipPackagesDialogVisibility
            }
            handleMembershipPackagesCheckoutPageDisplay={
              handleMembershipPackagesCheckoutPageDisplay
            }
            selectedMemberDetails={{
              memberName: activeMemberName,
              memberEmail: activeMemberEmail,
              memberMobile: activeMemberMobile,
              memberId: activeMemberId,
            }}
            handleGetMembershipPackageDetailsResponse={
              this.handleGetMembershipPackageDetailsResponse
            }
          />
        </Dialog>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({
  membershipSubscriptionPaymentUpdateToast,
  membersSubscribedPackagesData,
  membershipPackageDetails,
}) => ({
  membershipSubscriptionPaymentUpdateToast,
  membersSubscribedPackagesData,
  membershipPackageDetails,
});

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default connect(mapStateToProps)(MembersTable);
