// -----------------------------------------------------------------------
// toggleLoaderOn()
// -----------------------------------------------------------------------
export function toggleLoaderOn(dispatch) {
  dispatch({ type: "TOGGLE_LOADER", payload: true });
}

// -----------------------------------------------------------------------
// toggleLoaderOff()
// -----------------------------------------------------------------------
export function toggleLoaderOff(dispatch) {
  dispatch({ type: "TOGGLE_LOADER", payload: false });
}
