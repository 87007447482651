// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import { connect } from "react-redux";
import { Select } from "evergreen-ui";

// -----------------------------------------------------------------------
// PriceClassSelect Component
// -----------------------------------------------------------------------
class PriceClassSelect extends React.Component {
  // -----------------------------------------------------------------------
  // render()
  // -----------------------------------------------------------------------
  render() {
    const {
      changePriceClass,
      priceClassDetails,
      defaultPriceClassValue,
      defaultPriceClassName,
      selectedPriceClassValue
    } = this.props;
    return (
      <React.Fragment>
        <Select
          width={280}
          height={32}
          onChange={e => changePriceClass(e)}
          value={selectedPriceClassValue}
        >
          <option value={defaultPriceClassValue}>
            {defaultPriceClassName}
          </option>
          {priceClassDetails &&
            priceClassDetails.priceClasses &&
            priceClassDetails.priceClasses.map((priceClass, index) => (
              <option
                key={index}
                value={priceClass.priceClassId}
                activityid={priceClass.activityId}
              >
                {priceClass.pricingClassName}
              </option>
            ))}
        </Select>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ priceClassDetails }) => ({
  priceClassDetails
});

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default connect(mapStateToProps)(PriceClassSelect);
