// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import { AddIcon, Button } from "evergreen-ui";
import { connect } from "react-redux";
import Navbar from "../../components/Navbar";
import PageHeadingAndActionsNav from "../../components/PageHeadingAndActionsNav";
import ClubActivityListSelect from "../../components/ClubActivityListSelect";
import MembershipPackagesTable from "../MembershipPackages/MembershipPackagesTable";
import NoMatchingResultsMessage from "../../components/NoMatchingResultsMessage";
// -----------------------------------------------------------------------
// ViewMembershipPackagesPage Component
// -----------------------------------------------------------------------
class ViewMembershipPackagesPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedActivityValue: "select-a-sport",
      defaultActivityValue: "select-a-sport",
      defaultActivityName: "Select a sport",
    };
  }

  // -----------------------------------------------------------------------
  // handleActivityChange()
  // -----------------------------------------------------------------------
  handleActivityChange = async (e) => {
    e.persist();
    await this.setState({ selectedActivityValue: e.target.value });
  };

  // -----------------------------------------------------------------------
  // render()
  // -----------------------------------------------------------------------
  render() {
    const { selectedActivityValue, defaultActivityName, defaultActivityValue } =
      this.state;
    const {
      membershipPackageDetails,
      handleViewMembershipPackagesPageDisplay,
      handleCreateMembershipPackagesPageDisplay,
      handleAllMembersListPageDisplay,
      showMenuPopover,
      handleAddMember,
      handleEditPackage,
      reActivatePackage,
      deActivatePackage,
      changeActivePackageDetails,
      currentPage,
      handlePageChange,
    } = this.props;
    return (
      <React.Fragment>
        {membershipPackageDetails &&
          membershipPackageDetails.requestStatus === 1 && (
            <div className="VMPP-A1-wrapper">
              <div className="VMPP-A1-header-container">
                <Navbar />
                <PageHeadingAndActionsNav
                  headingText={"Membership Packages"}
                  handleViewMembershipPackagesPageDisplay={
                    handleViewMembershipPackagesPageDisplay
                  }
                />
              </div>
              <div className="VMPP-A1-body-container">
                <div className="VMPP-A1-body-contents-wrapper">
                  <div className="VMPP-A1-action-bar">
                    {/* <div className="VMPP-A1-club-activity-list-select-container">
                      <ClubActivityListSelect
                        selectedActivityValue={selectedActivityValue}
                        defaultActivityName={defaultActivityName}
                        defaultActivityValue={defaultActivityValue}
                        handleActivityChange={this.handleActivityChange}
                      />
                    </div>
                    <div className="VMPP-A1-vertical-line-decorator" /> */}
                    <Button
                      className="button"
                      iconBefore={<AddIcon />}
                      appearance="primary"
                      intent="warning"
                      width={149}
                      height={32}
                      marginRight={32}
                      onClick={() =>
                        handleCreateMembershipPackagesPageDisplay(
                          "show",
                          "view-membership-packages-page"
                        )
                      }
                    >
                      Create Package
                    </Button>
                    <Button
                      className="button"
                      appearance="primary"
                      intent="success"
                      width={149}
                      height={32}
                      marginRight={32}
                      onClick={() =>
                        handleAllMembersListPageDisplay(
                          "show",
                          null,
                          "view-membership-packages-page"
                        )
                      }
                    >
                      See Members
                    </Button>
                  </div>
                  <div className="VMPP-A1-membership-packages-table-wrapper">
                    {membershipPackageDetails &&
                    membershipPackageDetails.requestStatus === 1 ? (
                      <MembershipPackagesTable
                        showMenuPopover={showMenuPopover}
                        handleAddMember={handleAddMember}
                        handleEditPackage={handleEditPackage}
                        reActivatePackage={reActivatePackage}
                        deActivatePackage={deActivatePackage}
                        changeActivePackageDetails={changeActivePackageDetails}
                        currentPage={currentPage}
                        handlePageChange={handlePageChange}
                        membershipPackageDetails={membershipPackageDetails}
                        handleAllMembersListPageDisplay={
                          handleAllMembersListPageDisplay
                        }
                      />
                    ) : (
                      <NoMatchingResultsMessage />
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ membershipPackageDetails }) => ({
  membershipPackageDetails,
});

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default connect(mapStateToProps)(ViewMembershipPackagesPage);
