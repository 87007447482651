import {
  Button,
  Dialog,
  DollarIcon,
  Heading,
  InboxIcon,
  Pane,
  RefreshIcon,
  Select,
  Spinner,
  Strong,
  Switch,
  Text,
  TextInputField,
  toaster,
} from "evergreen-ui";
import React, { Component } from "react";

import { connect } from "react-redux";
import {
  downloadEventBooking,
  getCategoriesList,
  getEventBookingList,
  getEventCategorySummary,
  getEvents,
  updateCategoryStatus,
  updateCategoryTickets,
} from "../../actions";
import { toggleLoaderOff, toggleLoaderOn } from "../../actions/loaderUtils";
import EventBookingsTable from "../../components/EventDashboard/EventBookingsTable";
import EventSummary from "../../components/EventDashboard/EventSummary";

class EventDashBoard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPage: false,
      eventList: [],
      categoryList: [],
      eventName: "Select Event",
      defaultEventName: "Select Event",
      defaultEventId: "select-event",
      defaultCategoryName: "Select Category",
      defaultCategoryId: "select-category",
      eventId: "",
      active: false,
      categoryId: "",
      categoryName: "Select Category",
      pageNo: 1,
      eventBookingList: null,
      summaryDetails: null,
      isShown: false,
      updatedTicketValue: "",
      errorMsg: "",
      inValidTicketUpdate: false,
      categoryIndex: "",
      nextPage: 0,
      totalPage: 0,
      bookingLoader: false,
    };
  }

  componentDidMount() {
    toggleLoaderOn(this.props.dispatch);
    getEvents().then((data) => {
      toggleLoaderOff(this.props.dispatch);
      if (data.requestStatus === 1) {
        this.setState({
          showPage: true,
          eventList: data.eventList,
        });
      } else {
        toaster.warning(data?.message);
        this.setState({
          showPage: false,
        });
      }
    });
  }

  getCategoriesByEventId = (e) => {
    let value = e.target.value;
    let name =
      e.nativeEvent.target[e.nativeEvent.target.selectedIndex].text.toString();

    if (value === "select-event") {
      toaster.notify("select an event");
    } else {
      toggleLoaderOn(this.props.dispatch);
      getCategoriesList(value).then((data) => {
        if (data.requestStatus === 1) {
          this.setState({
            eventName: name,
            eventId: value,
            categoryList: data.categoryList,
            categoryId: "",
            categoryName: "Select Category",
            pageNo: 1,
            eventBookingList: null,
            summaryDetails: null,
          });
        } else {
          toaster.warning(data?.message);
        }

        toggleLoaderOff(this.props.dispatch);
      });
    }
  };

  getEventBooking = (e) => {
    let { categoryList } = this.state;
    let value = e.target.value;
    let categoryIndex = e.target.selectedIndex;
    console.log({ categoryIndex });
    let name =
      e.nativeEvent.target[e.nativeEvent.target.selectedIndex].text.toString();

    if (value === "select-category") {
      toaster.notify("select a category");
    } else {
      this.setState({ pageNo: 1 });
      let active = categoryList[categoryIndex - 1].active;
      toggleLoaderOn(this.props.dispatch);
      let { eventId, categoryId, pageNo } = this.state;
      this.getSummary(value, active, categoryIndex, name);
      this.getBooking(eventId, value, 1);
    }
  };

  getSummary = (value, active, categoryIndex, name) => {
    getEventCategorySummary(value).then((data) => {
      if (data.requestStatus === 1) {
        this.setState({
          summaryDetails: data?.summaryDetails,
          categoryName: name,
          categoryId: value,
          categoryIndex: categoryIndex,
          inValidTicketUpdate: false,
          updatedTicketValue: null,
          errorMsg: "",
          active: active,
        });
      } else {
        toaster.warning(data?.message);
      }
    });
  };
  getBooking = (eventId, value, pageNo) => {
    this.setState({ bookingLoader: true });
    getEventBookingList(eventId, value, pageNo).then((data) => {
      toggleLoaderOff(this.props.dispatch);

      this.setState({
        eventBookingList: data?.bookingDetails,
        nextPage: data.paginationData?.next,
        totalPage: data.paginationData?.total,
        bookingLoader: false,
      });
    });
  };

  handlePageChange = async (newPageNum) => {
    let { eventId, categoryId, pageNo } = this.state;
    this.setState({ pageNo: newPageNum });
    this.getBooking(eventId, categoryId, newPageNum);
  };

  handleActiveChange = (e) => {
    let { categoryList, categoryIndex } = this.state;
    let active = e.target.checked;
    let body = {
      categoryId: this.state.categoryId,
      status: active,
    };
    console.log({ categoryList });
    updateCategoryStatus(body).then((data) => {
      if (data.requestStatus === 1) {
        let category = categoryList[categoryIndex - 1];

        let newCategory = { ...category, active };

        let updatedCategoryList = [...categoryList];
        updatedCategoryList[categoryIndex - 1] = newCategory;
        console.log({ updatedCategoryList });
        this.setState({
          active: active,
          categoryList: updatedCategoryList,
        });
        toaster.success(data?.message);
      } else {
        toaster.notify(data?.message);
      }
    });
  };

  updateTickets = () => {
    let body = {
      categoryId: this.state.categoryId,
      tickets: this.state.updatedTicketValue,
    };
    updateCategoryTickets(body).then((data) => {
      if (data.requestStatus === 1) {
        let totalTickets = Number(this.state.updatedTicketValue);

        toaster.success(data?.message);
        this.setState({
          isShown: false,
          updatedTicketValue: null,
          summaryDetails: { ...this.state.summaryDetails, totalTickets },
        });
      } else {
        this.setState({
          inValidTicketUpdate: true,
          errorMsg: data?.message,
        });
      }
    });
  };

  downloadBookings = () => {
    this.props.dispatch(
      downloadEventBooking(this.state.categoryId, this.state.eventId)
    );
  };

  handleEditTickets = () => {
    this.setState({
      isShown: !this.state.isShown,
    });
  };

  render() {
    let {
      eventList,
      categoryList,
      eventBookingList,
      defaultEventName,
      defaultEventId,
      eventId,
      categoryId,
      defaultCategoryName,
      active,
      defaultCategoryId,
      summaryDetails,
      isShown,
      errorMsg,
      inValidTicketUpdate,
      pageNo,
      totalPage,
      bookingLoader,
      showPage,
    } = this.state;

    console.log({ summaryDetails });
    return showPage ? (
      <Pane
        display={"flex"}
        margin={60}
        flexDirection={"column"}
        alignItems={"start"}
        justifyContent={"start"}
      >
        <Pane
          display={"flex"}
          flexDirection={"row"}
          alignItems={"start"}
          justifyContent={"start"}
        >
          <Heading size={900}>Events</Heading>
        </Pane>
        <Pane
          marginTop={16}
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          width={"100%"}
          justifyContent={"space-between"}
        >
          <Pane
            marginTop={32}
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
            gap={20}
          >
            <Select
              // label="sport"
              size="400"
              height={40}
              width={300}
              value={eventId}
              // disabled={disabled}
              onChange={(e) => this.getCategoriesByEventId(e)}
            >
              <option value={defaultEventId}>{defaultEventName}</option>
              {eventList.map((event, index) => (
                <option key={index} value={event.id}>
                  {event.name}
                </option>
              ))}
            </Select>

            <Select
              // label="sport"
              size="400"
              disabled={categoryList.length > 0 ? false : true}
              height={40}
              width={300}
              value={categoryId}
              onChange={(e) => this.getEventBooking(e)}
              // disabled={disabled}
            >
              <option value={defaultCategoryId}>{defaultCategoryName}</option>

              {categoryList.map((category, index) => (
                <option key={index} value={category.id}>
                  {category.name}
                </option>
              ))}
            </Select>
            {/* {eventBookingList && (
              <RefreshIcon
                cursor={"pointer"}
                onClick={() => {
                  this.getBooking(eventId, categoryId, pageNo);
                  this.getSummary(value,active,categoryIndex,name)
                }}
              />
            )} */}
          </Pane>
          {categoryId.length > 0 && (
            <Pane
              marginTop={32}
              marginLeft={10}
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              gap={20}
            >
              <Strong>Active</Strong>{" "}
              <Switch
                onChange={(e) => this.handleActiveChange(e)}
                checked={active}
              />
            </Pane>
          )}
        </Pane>
        {summaryDetails ? (
          <EventSummary
            handleEditTickets={this.handleEditTickets}
            summaryDetails={summaryDetails}
          />
        ) : (
          <Pane
            display="flex"
            alignItems="center"
            flexDirection={"row"}
            justifyContent="center"
            width={"100%"}
            height={400}
          >
            <InboxIcon size={50} color={"lightGray"} />
          </Pane>
        )}
        {eventBookingList && (
          <EventBookingsTable
            handleGetBookingDetails={this.props.handleGetBookingDetails}
            downloadBookings={this.downloadBookings}
            eventBookingList={eventBookingList}
            currentPage={pageNo}
            totalPages={totalPage}
            handlePageChange={this.handlePageChange}
            bookingLoader={bookingLoader}
            dispatch={this.props.dispatch}
            bookingDetails={this.props.bookingDetails}
          />
        )}
        <Dialog
          isShown={isShown}
          onCancel={() =>
            this.setState({
              errorMsg: "",
              inValidTicketUpdate: false,
              updatedTicketValue: null,
              isShown: false,
            })
          }
          hasFooter={false}
          title="Edit Tickets"
        >
          <Pane
            display="flex"
            flexDirection={"row"}
            alignItems={"start"}
            justifyContent={"space-between"}
            gap={20}
          >
            <TextInputField
              width={250}
              label={"Total Tickets"}
              validationMessage={inValidTicketUpdate && errorMsg}
              value={this.state.updatedTicketValue}
              onChange={(e) =>
                this.setState({
                  updatedTicketValue: e.target.value,
                })
              }
            />
            <TextInputField
              cursor={"pointer"}
              width={250}
              disabled
              value={summaryDetails?.totalSoldTickets}
              label={"Sold Tickets"}
            />
          </Pane>

          <Pane
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="end"
            marginBottom={10}
          >
            <Button
              onClick={() => this.updateTickets()}
              background="#00B562"
              color={"white"}
              appearance="#00B562"
            >
              Done
            </Button>
          </Pane>
        </Dialog>
      </Pane>
    ) : (
      <Pane
        display={"flex"}
        flexDirection={"column"}
        width={"100%"}
        alignItems={"center"}
        justifyContent={"center"}
        height={400}
      >
        {"Events Are Not Available "}
      </Pane>
    );
  }
}

const mapStateToProps = ({ clubActivityData, bookingDetails }) => ({
  clubActivityData,
  bookingDetails,
});

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default connect(mapStateToProps)(EventDashBoard);
