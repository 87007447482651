import { Button, Pane, toaster } from "evergreen-ui";
import moment from "moment";

import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { checkIfDateIsAfter } from "../../utils";

function SingleDateRangePicker({
  handleDateRangeChange,
  handleClose,
  startDateLabel,
  endDateLabel,
  setPeriod,
}) {
  // range demo
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  let startLabel = moment(startDate).format("YYYY-MM-DD");
  let endLabel = moment(endDate).format("YYYY-MM-DD");
  console.log({ startLabel, endLabel });
  const today = moment();
  const disableFutureDt = (current) => {
    return current.isBefore(today);
  };

  function handleDateChange(date) {
    // initial change start by setting the startDate
    if (!startDate && !endDate) {
      setStartDate(date);
      // startDate has been set, set the end date
    } else if (startDate && !endDate) {
      if (checkIfDateIsAfter(startDate, date)) {
        setStartDate(date);
      } else {
        setEndDate(date);
      }
    }

    // user is choosing another range => set the start date
    // and set the endDate back to null
    if (startDate && endDate) {
      setStartDate(date);
      setEndDate(null);
    }
  }

  const applyDates = async (startDateLabel, endDateLabel) => {
    if (startDateLabel === "Invalid date" || endDateLabel === "Invalid date") {
      toaster.warning("Please Select  Valid Dates");
    } else {
      handleDateRangeChange(startDateLabel, endDateLabel);
      setPeriod("Custom");
      handleClose();
    }
  };

  return (
    <Pane
      width="100%"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      gap="20px"
    >
      <Pane
        width="80%"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Button className="custom-input">
          {startLabel === "Invalid date" ? "From Date" : startLabel}
        </Button>
        <Button className="custom-input">
          {endLabel === "Invalid date" ? "To Date" : endLabel}
        </Button>
      </Pane>
      <Pane
        width="100%"
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
      >
        <DatePicker
          onChange={(date) => handleDateChange(date)}
          selectsStart={true}
          selected={startDate}
          startDate={startDate}
          endDate={endDate}
          inline={true}
          selectsRange
          maxDate={moment().toDate()}
        />
      </Pane>

      <hr style={{ width: "300px", border: "0.5px solid lightgray" }} />
      <Pane
        display="flex"
        flexDirection="row"
        justifyContent="end"
        width="80%"
        alignItems="end"
      >
        <button
          className="apply-button"
          onClick={() => applyDates(startDate, endDate)}
        >
          Apply
        </button>
      </Pane>
    </Pane>
  );
}

export default SingleDateRangePicker;
