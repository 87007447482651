// -----------------------------------------------------------------------
// Import Resources
// -----------------------------------------------------------------------
import moment from "moment";
import validator from "validator";
import { toaster } from "evergreen-ui";
import Cookies from "universal-cookie";

const cookies = new Cookies();

// -----------------------------------------------------------------------
// resourcesNotLoadedToast()
// -----------------------------------------------------------------------
export const resourcesNotLoadedToast = () => {
  toaster.danger("Some Resources could not be loaded, try reloading the page", {
    id: "HTTP-RESOURCES-ERROR_TOAST",
  });
};

// -----------------------------------------------------------------------
// checkAuthTokenExists()
// -----------------------------------------------------------------------
export const checkAuthTokenExists = () => {
  let authToken = cookies.get("playoAuthToken", { path: "/" });
  return authToken !== undefined ? true : false;
};

// -----------------------------------------------------------------------
// getAuthToken()
// -----------------------------------------------------------------------
export const getAuthToken = () => {
  let authToken = cookies.get("playoAuthToken", { path: "/" });
  return authToken;
};

// -----------------------------------------------------------------------
// getUserClubs()
// -----------------------------------------------------------------------
export const getUserClubs = () => {
  return JSON.parse(localStorage.getItem("playoUserClubs"));
};

// -----------------------------------------------------------------------
// getCurrentClubId()
// -----------------------------------------------------------------------
export const getCurrentClub = () => {
  return parseInt(cookies.get("playoClubId", { path: "/" }));
};

// -----------------------------------------------------------------------
// checkUserNameExists()
// -----------------------------------------------------------------------
export const checkUserNameExists = () => {
  let userName = cookies.get("playoUserName", { path: "/" });
  return userName !== undefined ? true : false;
};

// -----------------------------------------------------------------------
// getPlayoUserName()
// -----------------------------------------------------------------------
export const getPlayoUserName = () => {
  let userName = cookies.get("playoUserName", { path: "/" });
  return userName.toString();
};

// -----------------------------------------------------------------------
// getPlayoUserName()
// -----------------------------------------------------------------------
export const getPlayoUserId = () => {
  let userId = cookies.get("playoUserId", { path: "/" });
  return userId;
};

// -----------------------------------------------------------------------
// checkClubNameExists()
// -----------------------------------------------------------------------
export const checkClubNameExists = () => {
  let clubName = cookies.get("playoClubName", { path: "/" });
  return clubName !== undefined ? true : false;
};

// -----------------------------------------------------------------------
// getPlayoClubName()
// -----------------------------------------------------------------------
export const getPlayoClubName = () => {
  let clubName = cookies.get("playoClubName", { path: "/" });
  return clubName.toString();
};

// -----------------------------------------------------------------------
// checkClubCountryCallingCodeExists()
// -----------------------------------------------------------------------
export const checkClubCountryCallingCodeExists = () => {
  let clubCountryCallingCode = cookies.get("playoClubCountryCallingCode", {
    path: "/",
  });
  return clubCountryCallingCode !== undefined ? true : false;
};

// -----------------------------------------------------------------------
// getPlayoClubCountryCallingCode()
// -----------------------------------------------------------------------
export const getPlayoClubCountryCallingCode = () => {
  let clubCountryCallingCode = cookies.get("playoClubCountryCallingCode", {
    path: "/",
  });
  return clubCountryCallingCode.toString();
};

// -----------------------------------------------------------------------
// removePlayoTokens()
// -----------------------------------------------------------------------
export const removePlayoTokens = () => {
  cookies.remove("playoAuthToken", {
    path: "/",
  });
  cookies.remove("playoUserName", {
    path: "/",
  });
  cookies.remove("playoClubName", {
    path: "/",
  });
  cookies.remove("playoClubCountryCallingCode", {
    path: "/",
  });
  cookies.remove("playoClubCurrency", {
    path: "/",
  });
  cookies.remove("playoClubId", {
    path: "/",
  });
  cookies.remove("playoClubUsers", {
    path: "/",
  });
};

// -----------------------------------------------------------------------
// convert slot start time and end time as
// (h:mm - h:mm A) e.x., (5:00 - 5:30 AM)
// -----------------------------------------------------------------------
export const slotTimeConverter = (startTime, endTime) => {
  return (
    moment(startTime, "HH:mm:ss").format("h:mm") +
    " - " +
    moment(endTime, "HH:mm:ss").format("h:mm A")
  );
};

// -----------------------------------------------------------------------
// convert slot start time and end time as
// (h:mm A - h:mm A) e.x., (5:00 AM - 5:30 AM)
// -----------------------------------------------------------------------
export const slotTimeConverter12 = (startTime, endTime) => {
  return (
    moment(startTime, "HH:mm:ss").format("h:mm A") +
    " - " +
    moment(endTime, "HH:mm:ss").format("h:mm A")
  );
};

// -----------------------------------------------------------------------
// convert date as (DD ddd YYYY) e.x., (1 May 2019)
// -----------------------------------------------------------------------
export const slotTimeConverter3 = (date) => {
  return moment(date, "YYYY-MM-DD").format("DD MMM YYYY");
};

// -----------------------------------------------------------------------
// convert date as (DD ddd YYYY) e.x., (1 - May - 2019)
// -----------------------------------------------------------------------
export const timeConverter121 = (date) => {
  return moment(date, "YYYY-MM-DD").format("DD - MMM - YYYY");
};

// -----------------------------------------------------------------------
// convert start date and end date as
// (DD MMM - DD ddd YYYY) e.x., (1 Apr - 1 May 2019)
// -----------------------------------------------------------------------
export const slotDateConverter4 = (startDate, endDate) => {
  return (
    moment(startDate, "YYYY-MM-DD").format("DD MMM") +
    " - " +
    moment(endDate, "YYYY-MM-DD").format("DD MMM YYYY")
  );
};

// -----------------------------------------------------------------------
// format date to (ddd, DD-MMM) e.x., (Tue, 29-Nov)
// -----------------------------------------------------------------------
export const toDateFormat = (date) => {
  return moment(date).format("ddd, DD-MMM");
};

// -----------------------------------------------------------------------
// format date to (ddd, DD MMM YYYY) e.x., (Tue, 29 Nov 2019)
// -----------------------------------------------------------------------
export const toDateFormat16 = (date) => {
  return moment(date, "YYYY-MM-DD hh:mm:ss").format("ddd, DD MMM YYYY");
};

// -----------------------------------------------------------------------
// format date to (DD-MMM-YYYY) e.x., (29-Nov-2019)
// -----------------------------------------------------------------------
export const toDateFormat32 = (date) => {
  return moment(date, "YYYY-MM-DD").format("DD - MMM - YYYY");
};

// -----------------------------------------------------------------------
// format time to (h:mm:ss a) e.x., (3:45:29 PM)
// -----------------------------------------------------------------------
export const toTimeFormat16 = (date) => {
  return moment(date, "YYYY-MM-DD hh:mm:ss").format("h:mm:ss a");
};

// -----------------------------------------------------------------------
// format time to (h:mm:ss a) e.x., (3:45:29 PM)
// -----------------------------------------------------------------------
export const toDateAndTimeFormat = (date) => {
  return moment(date, "YYYY-MM-DD hh:mm:ss").format("DD MMM YY, hh:mm a");
};

// -----------------------------------------------------------------------
// format date to (YYYY-MM-DD) e.x., (2019-02-28)
// -----------------------------------------------------------------------
export const toDateFormat1 = (date) => {
  return moment(date).format("YYYY-MM-DD");
};

// -----------------------------------------------------------------------
// format date to (ddd, D-MMM) e.x., (Tue, 29-Nov) and time to
// (h:mm - h:mm A) e.x., (5:00 - 5:30 AM) and return complete string as
// (ddd, D-MMM) | (h:mm - h:mm A) e.x., (Tue, 29-Nov | 5:00 - 5:30 AM)
// -----------------------------------------------------------------------
export const toDateTimeFormat = (date, startTime, endTime) => {
  return (
    moment(date, "YYYY-MM-DD").format("ddd, D-MMM") +
    " | " +
    moment(startTime, "HH:mm:ss").format("h:mm") +
    " - " +
    moment(endTime, "HH:mm:ss").format("h:mm A")
  );
};

// -----------------------------------------------------------------------
// format date to (ddd, D-MMM) e.x., (Tue, 29-Nov) and time to
// (h:mm A - h:mm A) e.x., (5:00 AM - 5:30 AM) and return complete string as
// (ddd, D-MMM) | (h:mm - h:mm A) e.x., (Tue, 29-Nov | 5:00 - 5:30 AM)
// -----------------------------------------------------------------------
export const toDateTimeFormat15 = (date, startTime, endTime) => {
  return (
    moment(date, "YYYY-MM-DD").format("ddd, D-MMM") +
    " | " +
    moment(startTime, "HH:mm:ss").format("h:mm A") +
    " - " +
    moment(endTime, "HH:mm:ss").format("h:mm A")
  );
};

// -----------------------------------------------------------------------
// format time to (HH:mm:00) e.x., (12:00:00), notice that seconds is
// reset to 00
// -----------------------------------------------------------------------
export const toTimeFormat = (time) => {
  return moment(time).format("HH:mm:00");
};

// -----------------------------------------------------------------------
// format time to (HH:mm:ss) e.x., (12:59:59)
// -----------------------------------------------------------------------
export const toTimeFormat2 = (time) => {
  return moment(time).format("HH:mm:ss");
};

// -----------------------------------------------------------------------
// format time to (h:mm a) and return moment object
// -----------------------------------------------------------------------
export const getMomentTimeObj1 = (time) => {
  return moment(time, "h:mm a").toDate();
};

// -----------------------------------------------------------------------
// format time to (h:mm A) e.x., (5:30 AM)
// -----------------------------------------------------------------------
export const toTimeFormat1 = (time) => {
  return moment(time, "HH:mm:ss").format("h:mm A");
};

// -----------------------------------------------------------------------
// check whether the number is an Integer and return the number as it is
// or, if float return it upto two decimal places
// -----------------------------------------------------------------------
export const checkIntOrFloat = (num) => {
  return Number.isInteger(num)
    ? Number(parseInt(num))
    : Number(parseFloat(num).toFixed(2));
};

// -----------------------------------------------------------------------
// check whether the number is an Integer and return the number as it is
// or, if float return it without using to fixed
// -----------------------------------------------------------------------
export const checkIntOrFloat2 = (num) => {
  return Number.isInteger(num)
    ? Number(parseInt(num))
    : Number(parseFloat(num));
};

// // -----------------------------------------------------------------------
// // Validate Person Name
// // -----------------------------------------------------------------------
// export const validatePersonName = name => {
//   return /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/.test(name);
// };

// -----------------------------------------------------------------------
// Validate if number is int or float and greater than 0
// -----------------------------------------------------------------------
export const validateNumber1 = (num) => {
  return (
    (validator.isInt(num) || validator.isFloat(num)) &&
    num > 0 &&
    num.toString().charAt(0) !== "+"
  );
};

// -----------------------------------------------------------------------
// Validate if number is int and not float and greater than equal to 0
// -----------------------------------------------------------------------
export const validateNumber2 = (num) => {
  if (
    num.length > 0 &&
    num >= 0 &&
    validator.isInt(num) &&
    !validator.contains(num, ".") &&
    !validator.contains(num, "+")
  ) {
    return "set";
  } else if (num.length === 1 && num.charAt(0) === 0) {
    return "clear";
  } else if (num.length <= 0) {
    return "clear";
  }
};

// -----------------------------------------------------------------------
// Validate Credits and Price
// -----------------------------------------------------------------------
export const validateCreditsAndPrice = (price) => {
  if (
    (validator.isInt(price) || validator.isFloat(price)) &&
    price >= 0 &&
    price.toString().charAt(0) !== "+"
  ) {
    return "set";
  } else if (price.toString().charAt(0) === "0" && price.length === 1) {
    return "clear";
  } else if (price.toString().charAt(0) === "." && price.length === 1) {
    return "clear";
  } else if (price.length === 0) {
    return "clear";
  }
};

// -----------------------------------------------------------------------
// Check positive integer
// -----------------------------------------------------------------------
export const checkPositiveInteger = (num) => {
  if (validator.isInt(num) && num > 0) {
    return "set";
  } else {
    return "clear";
  }
};

// -----------------------------------------------------------------------
// validate Percentage
// -----------------------------------------------------------------------
export const validatePercentage = (num) => {
  if (
    (validator.isInt(num) || validator.isFloat(num)) &&
    num > 0 &&
    num <= 100 &&
    num.toString().charAt(0) !== "+" &&
    num.toString().charAt(3) !== "."
  ) {
    return "set";
  } else if (num.toString().charAt(0) === "0" && num.length === 1) {
    return "clear";
  } else if (num.toString().charAt(0) === "." && num.length === 1) {
    return "clear";
  } else if (num.length === 0) {
    return "clear";
  }
};

// -----------------------------------------------------------------------
// determineBadgeColorForSlotsAvailability()
// -----------------------------------------------------------------------
export const determineBadgeColorForSlotsAvailability = (
  availableSlots,
  totalSlots
) => {
  var color = "";
  if (totalSlots > 0) {
    const availabilityPercentage = (availableSlots / totalSlots) * 100;
    if (availabilityPercentage >= 31 && availabilityPercentage <= 100) {
      color = "green";
    } else if (availabilityPercentage >= 1 && availabilityPercentage <= 30) {
      color = "orange";
    } else if (availabilityPercentage === 0) {
      color = "red";
    } else {
      color = "neutral";
    }
    return color;
  } else {
    color = "red";
    return color;
  }
};

// -----------------------------------------------------------------------
// isObjEmpty()
// -----------------------------------------------------------------------
export const isObjEmpty = (obj) => {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
};

// -----------------------------------------------------------------------
// determineBadgeColorForPricingSlot()
// -----------------------------------------------------------------------
export const determineBadgeColorForPricingSlot = (index, type) => {
  if (type === "regular") {
    return index % 2 === 0 ? "yellow" : "purple";
  } else if (type === "special") {
    return index % 2 === 0 ? "blue" : "teal";
  }
};

// -----------------------------------------------------------------------
// generateShortDayName()
// -----------------------------------------------------------------------
export const generateShortDayName = (day) => {
  return day.toString().slice(0, 3);
};

// -----------------------------------------------------------------------
// checkIfDateIsAfter()
// -----------------------------------------------------------------------
export const checkIfDateIsAfter = (date1, date2) => {
  return moment(toDateFormat1(date1)).isAfter(toDateFormat1(date2));
};

// -----------------------------------------------------------------------
// checkIfDatesAreSame()
// -----------------------------------------------------------------------
export const checkIfDatesAreSame = (date1, date2) => {
  return moment(toDateFormat1(date1)).isSame(toDateFormat1(date2));
};

// -----------------------------------------------------------------------
// checkIfTimeIsAfter()
// -----------------------------------------------------------------------
export const checkIfTimeIsAfter = (time1, time2) => {
  return (
    toTimeFormat(time1).replace(/:/g, "") >
    toTimeFormat(time2).replace(/:/g, "")
  );
};

// -----------------------------------------------------------------------
// checkIfTimeIsSameOrAfter()
// -----------------------------------------------------------------------
export const checkIfTimeIsSameOrAfter = (time1, time2) => {
  return (
    toTimeFormat(time1).replace(/:/g, "") >=
    toTimeFormat(time2).replace(/:/g, "")
  );
};

// -----------------------------------------------------------------------
// stringifyIt()
// -----------------------------------------------------------------------
export const stringifyIt = (somethingArg) => {
  return JSON.stringify(somethingArg).slice(1, -1);
};

// -----------------------------------------------------------------------
// mailToPlayo()
// -----------------------------------------------------------------------
export const mailToPlayo = async () => {
  const playoLink = document.createElement("a");
  playoLink.href = "mailto:partner.support@playo.co";
  document.body.appendChild(playoLink);
  await playoLink.click();
  await playoLink.parentNode.removeChild(playoLink);
};

// -----------------------------------------------------------------------
// generateCurrencySymbol()
// -----------------------------------------------------------------------
export const generateCurrencySymbol = () => {
  let playoClubCurrency = cookies.get("playoClubCurrency", {
    path: "/",
  });
  if (playoClubCurrency !== undefined && playoClubCurrency.length >= 1) {
    return playoClubCurrency.toLowerCase() === "rs"
      ? "₹"
      : playoClubCurrency.toUpperCase();
  } else {
    return "Amt.";
  }
};

export const getDateObject = (dateString, format = "YYYY-MM-DD") => {
  return moment(dateString, format);
};

export const checkIfDateIsBefore = (date1, date2) => {
  return date1.isBefore(date2);
};

// -----------------------------------------------------------------------
// getLineGraphLabelsAndValue()
// -----------------------------------------------------------------------
export const getDashboardFormatedData = async (data) => {
  let { bookingGtv, bookingCount } = data.dashboardDetails;

  let labels = [];
  let graphValue = [];
  let bookingGtvData = await getBookingCountAndGtvData(bookingGtv);
  let bookingCountData = await getBookingCountAndGtvData(bookingCount);

  data.dashboardDetails.gtvPerDay.map((item) => {
    labels.push(item.date);
    graphValue.push(item.gtvValue);
  });

  return { labels, graphValue, bookingGtvData, bookingCountData };
};

export const getBookingCountAndGtvData = async (data) => {
  let dataArray = [];
  Object.keys(data).forEach((item) => {
    dataArray.push({
      title: item,
      online:
        item === "bookings" ? data.bookings.online : data.cancellation.online,
      offline:
        item === "bookings" ? data.bookings.offline : data.cancellation.offline,
      sum:
        item === "bookings"
          ? data.bookings.online + data.bookings.offline
          : data.cancellation.online + data.cancellation.offline,
    });
  });
  return dataArray;
};

export const createObjects = (data) => {
  // Create an array of objects
  const objectsArray = [];

  // Create an array of keys based on the input object
  const keys = Object.keys(data);

  // Split the input data into arrays based on the keys
  const keyArrays = {};

  keys.forEach((key) => {
    keyArrays[key] = data[key].split(", ");
  });

  // Find the maximum length among the arrays
  const maxLength = Math.max(...keys.map((key) => keyArrays[key].length));

  // Create objects by iterating over the arrays
  for (let i = 0; i < maxLength; i++) {
    const obj = {};
    keys.forEach((key) => {
      obj[key] = keyArrays[key][i] || ""; // Handle cases where there's no value
    });
    objectsArray.push(obj);
  }

  return objectsArray;
};

export const filterBookingDetailsByDate = (res, targetDate) => {
  // Check if bookingDetails array exists in the response body
  if (res.body && res.body.bookingDetails) {
    // Filter the array based on the targetDate
    res.body.bookingDetails = res.body.bookingDetails.filter(
      (booking) => booking.dateBooked === targetDate
    );
  }
  console.log("infunc-----", res.body);
  return res;
};
