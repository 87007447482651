import React from 'react'
import {
 
  Checkbox,
  Strong,
 
  Pane
} from 'evergreen-ui'

const index = ({ onNoShow, bookingId }) => {
  return (
    <Pane
      display={'flex'}
      flexDirection={'column'}
      border
      marginTop={10}
      borderRadius={5}
      padding={1}
    >
      {' '}
      <Pane
        display='flex'
        flexDirection='row'
        alignItems='center'
        justifyContent={'space-around'}
      >
        {' '}
        <Strong color={'#696f8c'}>Mark As No Show</Strong>{' '}
        <Checkbox
          checked={false}
          onChange={() => {
            onNoShow(bookingId)
          }}
        />
        {/* <Button
          appearance='minimal'
          minWidth={70}
          onClick={() => {
            onNoSHow(bookingId)
          }}
        > */}
        {/* <Strong color='#0679c5'>Submit</Strong>
        </Button> */}
      </Pane>
    </Pane>
  )
}

export default index
