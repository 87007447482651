const updateExtraPaymentToast = (state = null, action) => {
    if (action.type === "SET_UPDATE_EXTRA_PAYMENT_TOAST") {
      return action.payload;
    } else {
      return state;
    }
    // switch (action.type) {
    //   case "SET_UPDATE_BOOKING_PAYMENT_TOAST": {
    //     return action.payload;
    //   }
  
    //   default: {
    //     return state;
    //   }
    // }
  };
  
  export default updateExtraPaymentToast;
  