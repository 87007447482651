import React, { useState } from "react";
import { Button, Dialog, Pane, Table } from "evergreen-ui";

export default function RescheduleHistoryDialogue({
  rescheduleHistory,
  isShown,
  onClose,
}) {
  return (
    <Dialog
      isShown={isShown}
      title="Reschedule History"
      onCloseComplete={onClose}
      hasFooter={false}
    >
      <Pane>
        <Table>
          <Table.Head>
            <Table.TextHeaderCell>Court</Table.TextHeaderCell>
            <Table.TextHeaderCell>Date</Table.TextHeaderCell>
            <Table.TextHeaderCell>Start Time</Table.TextHeaderCell>
            <Table.TextHeaderCell>End Time</Table.TextHeaderCell>
            <Table.TextHeaderCell>Gross Amount</Table.TextHeaderCell>
          </Table.Head>
          <Table.Body>
            {rescheduleHistory.map((item) => (
              <Table.Row key={item.id}>
                <Table.TextCell>{item.court}</Table.TextCell>
                <Table.TextCell>{item.date}</Table.TextCell>
                <Table.TextCell>{item.start_time}</Table.TextCell>
                <Table.TextCell>{item.end_time}</Table.TextCell>
                <Table.TextCell>{item.gross_amount}</Table.TextCell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Pane>
    </Dialog>
  );
}
