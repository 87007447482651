// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import { Text, InlineAlert } from "evergreen-ui";

// -----------------------------------------------------------------------
// CustomInlineAlert Component
// -----------------------------------------------------------------------
class CustomInlineAlert extends React.Component {
  // -----------------------------------------------------------------------
  // render()
  // -----------------------------------------------------------------------
  render() {
    const { alertMsg, intent } = this.props;
    return (
      <React.Fragment>
        <div className="CIA-A1-inline-alert-input-msg-container">
          <InlineAlert className="CIA-A1-inline-alert" intent={intent}>
            <Text
              id={
                intent === "danger"
                  ? "CIA-A1-invalid-input-msg"
                  : "CIA-A1-info-input-msg"
              }
            >
              {alertMsg}
            </Text>
          </InlineAlert>
        </div>
      </React.Fragment>
    );
  }
}

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default CustomInlineAlert;
