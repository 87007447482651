// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import { Text } from "evergreen-ui";
import arrowVector from "../../assets/images/arrow.svg";

// -----------------------------------------------------------------------
// BlankPageGuide Component
// -----------------------------------------------------------------------
class BlankPageGuide extends React.Component {
  // -----------------------------------------------------------------------
  // render()
  // -----------------------------------------------------------------------
  render() {
    const { msg } = this.props;
    return (
      <div className="BPG-A1-wrapper">
        <img
          className="BPG-A1-arrow-vector"
          src={arrowVector}
          alt="curve-arrow-pointing-to-select"
        />
        <div className="BPG-A1-msg-container">
          <Text id="BPG-A1-msg">{msg}</Text>
        </div>
      </div>
    );
  }
}

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default BlankPageGuide;
