import {
  ArrowDownIcon,
  ArrowRightIcon,
  ArrowUpIcon,
  Pane,
  Small,
  Strong,
  Text,
} from "evergreen-ui";
import React from "react";
import { checkPositiveInteger } from "../../utils";

function DashboardBottomStats({ slotUtilisation, uniqueUsers, handleSidebar }) {
  let isPositive = checkPositiveInteger(toString(uniqueUsers?.uniqueUserCount));
  console.log({ isPositive });
  return (
    <Pane
      justifyContent="space-between"
      flexDirection={"row"}
      display={"flex"}
      marginTop={"20px"}
      width={"100%"}
    >
      <Pane
        display="flex"
        justifyContent="space-between"
        flexDirection="row"
        width={"49%"}
        border="default"
        borderRadius={"10px"}
        padding={"20px"}
        maxHeight={60}
      >
        <Pane
          display="flex"
          justifyContent="start"
          flexDirection="row"
          width={"50%"}
          alignItems="center"
        >
          <Text>Unique Users</Text>
        </Pane>
        <Pane
          display="flex"
          justifyContent="space-around"
          flexDirection="row"
          alignItems={"center"}
        >
          <Strong size={500} marginRight={10} color={"selected"}>
            {uniqueUsers?.uniqueUserCount}
          </Strong>
          {/* <ArrowUpIcon marginLeft={"10px"} color={"#00B562"} size={14} /> */}
          {uniqueUsers?.uniqueUserPerc ? (
            <Pane display={"flex"} alignItems={"center"} flexDirection={"row"}>
              {" "}
              <Small
                marginRight={5}
                color={isPositive === "set" ? "#00B562" : "red"}
                size={12}
              >
                {uniqueUsers.uniqueUserPerc}%
              </Small>
              {isPositive === "set" ? (
                <ArrowUpIcon size={16} color={"#00B562"} marginRight={"2px"} />
              ) : (
                <ArrowDownIcon size={16} color={"red"} marginRight={"2px"} />
              )}
            </Pane>
          ) : (
            <Small
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              marginLeft={"5px"}
              color={"lightgray"}
              size={12}
            >
              <ArrowUpIcon size={16} marginRight={"2px"} /> 0 %
            </Small>
          )}
        </Pane>
      </Pane>
      {/* <Pane
        display="flex"
        justifyContent="space-between"
        flexDirection="column"
        width={"49%"}
        borderRadius={"10px"}
        background={"rgba(15, 77, 136, 0.2)"}
      >
        <Pane
          display="flex"
          justifyContent="space-between"
          flexDirection="row"
          width={"100%"}
          background={"white"}
          borderRadius={"10px"}
          border="default"
          padding={"20px"}
        >
        

          <Pane
            display="flex"
            justifyContent="start"
            flexDirection="column"
            width={"50%"}
            alignItems="start"
          >
            <Text size={500}>Slots Utilization</Text>
            <Text size={300} marginTop={"2px"}>
              It represents utilisation for the selected date period
            </Text>
          </Pane>
          <Pane
            display="flex"
            justifyContent="space-between"
            flexDirection="row"
            alignItems={"center"}
          >
            <Strong size={500} color={"selected"}>
              {slotUtilisation.utilisationPerc}%
            </Strong>
            <Small marginLeft={5} color={"muted"}>
              {slotUtilisation.usedSlots} / {slotUtilisation.totalSlots} slots
            </Small>
          </Pane>
        </Pane>
        <Pane
          height={"40px"}
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"center"}
          cursor={"pointer"}
          onClick={(e) => handleSidebar(e, "analytics")}
        >
          <Strong color="selected">See Details</Strong>
          <ArrowRightIcon color="selected" marginLeft={"5px"} />
        </Pane>
      </Pane> */}
    </Pane>
  );
}

export default DashboardBottomStats;
