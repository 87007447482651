import {
  Pane,
  Menu,
  Checkbox,
  Popover,
  Button,
  Text,
  Tag,
  Badge,
  CaretDownIcon,
} from "evergreen-ui";
import { Position } from "evergreen-ui";
import React, { useEffect, useState } from "react";

const ClubSelector = ({ clubs, onClubSelectionChange, updateSelectedClub }) => {
  const [selectedClubs, setSelectedClubs] = useState([]);
  const [isPaneOpen, setPaneOpen] = useState(true);

  const handleClubCheckboxChange = (clubId) => {
    const isSelected = selectedClubs.includes(clubId);

    if (isSelected) {
      setSelectedClubs((prevSelectedClubs) =>
        prevSelectedClubs.filter((id) => id !== clubId)
      );
    } else {
      setSelectedClubs((prevSelectedClubs) => [...prevSelectedClubs, clubId]);
    }
  };

  const handleSelectAll = () => {
    const allClubIds = clubs?.data?.map((club) => club.club_id) || [];
    setSelectedClubs(allClubIds);
  };

  const handleDeselectAll = () => {
    setSelectedClubs([]);
  };

  useEffect(() => {
    onClubSelectionChange(selectedClubs);
    updateSelectedClub(selectedClubs);
  }, [selectedClubs, onClubSelectionChange]);

  useEffect(() => {
    // Auto-select the only club if there is only one
    if (clubs?.data?.length === 1) {
      handleSelectAll();
      setPaneOpen(false); // Optionally hide the component
    }
  }, [clubs]);

  return (
    isPaneOpen && (
      <div>
        {!selectedClubs?.length > 0 && (
          <Text
            height={10}
            marginBottom={2}
            marginTop={5}
            color={"red300"}
            size={400}
          >
            Please choose a club from the following list to download reports:
          </Text>
        )}
        <Popover
          marginBottom={20}
          position={Position.BOTTOM}
          content={
            <Pane marginTop={10} marginBottom={20}>
              <Menu>
                <Menu.Group>
                  <Menu.Item>
                    <Checkbox
                      label="Select All"
                      checked={selectedClubs.length === clubs?.data?.length}
                      onChange={
                        selectedClubs.length === clubs?.data?.length
                          ? handleDeselectAll
                          : handleSelectAll
                      }
                    />
                  </Menu.Item>
                </Menu.Group>

                <Menu.Divider />

                <Menu.Group>
                  {clubs?.data?.map((club) => (
                    <Menu.Item key={club.club_id}>
                      <Checkbox
                        label={club.club_name}
                        checked={selectedClubs.includes(club.club_id)}
                        onChange={() => handleClubCheckboxChange(club.club_id)}
                      />
                    </Menu.Item>
                  ))}
                </Menu.Group>
              </Menu>
            </Pane>
          }
        >
          <Button
            marginBottom={10}
            marginTop={10}
            alignItems={"center"}
            width={"200px"}
            display={"flex"}
            flexDirection={"row"}
            justifyContent="start"
          >
            <Pane
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
              width={"200px"}
            >
              <Text>Clubs</Text>
              <CaretDownIcon size={16} color={"tint"} />
            </Pane>
          </Button>
        </Popover>

        {/* Pane to show selected club names */}
        {selectedClubs.length > 0 && (
          <Pane
            display="flex"
            flexDirection="row"
            alignItems="center"
            marginTop={10}
            marginBottom={20}
            width={"100%"}
            flexWrap="wrap"
          >
            {selectedClubs.map((clubId) => {
              const selectedClub = clubs.data.find(
                (club) => club.club_id === clubId
              );
              return (
                <Badge
                  color="green"
                  marginRight={10}
                  key={clubId}
                  height={16}
                  display={"flex"}
                  flexDirection={"row"}
                  alignItems={"center"}
                  marginTop={10}
                >
                  {selectedClub?.club_name}
                </Badge>
              );
            })}
          </Pane>
        )}
      </div>
    )
  );
};

export default ClubSelector;
