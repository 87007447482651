// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// -----------------------------------------------------------------------
// CustomizedDatePicker Component
// -----------------------------------------------------------------------
class CustomizedDatePicker extends React.Component {
  // -----------------------------------------------------------------------
  // render()
  // -----------------------------------------------------------------------
  render() {
    const { handleDateChange, date, isDatePickerDisabled, maxDate } =
      this.props;
    return (
      <div className="CDP-A1-wrapper">
        <DatePicker
          placeholderText="Select a Date"
          selected={date}
          onChange={handleDateChange}
          dateFormat="dd - MMM - yy"
          disabled={isDatePickerDisabled}
          maxDate={maxDate || ""}
        />
      </div>
    );
  }
}

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default CustomizedDatePicker;
