import React, { useEffect, useState } from "react";
import { Button, Pane, Text } from "evergreen-ui";
import ClubSelector from "../ClubSelector";
import { getUserClubsData } from "../../actions";
import { getPlayoUserName } from "../../utils";

const GetReportsActionButtons = ({
  handleViewReports,
  onClubSelectionChange,
  handleDownloadReport,
}) => {
  const [clubs, setClubs] = useState([]);
  const [clubIds, setClubIds] = useState([]);
  let username = getPlayoUserName();
  useEffect(() => {
    getUserClubsData({ username }).then((res) => {
      setClubs(res);
    });
  }, []);

  const updateSelectedClub = (data) => {
    setClubIds(data);
  };

  return (
    <>
      <ClubSelector
        clubs={clubs}
        updateSelectedClub={updateSelectedClub}
        onClubSelectionChange={onClubSelectionChange}
      />

      <div className="GRAB-A1-wrapper">
        <Button
          width={152}
          height={32}
          marginRight={36}
          appearance="primary"
          disabled={clubIds?.length > 0 ? false : true}
          className="GRAB-A1-button"
          onClick={(e) => handleDownloadReport(e)}
        >
          Download
        </Button>
        <Button
          width={152}
          height={32}
          appearance="primary"
          intent="success"
          className="GRAB-A1-button"
          disabled={clubIds?.length > 0 ? false : true}
          onClick={(e) => handleViewReports(e)}
        >
          View Report
        </Button>
      </div>
    </>
  );
};

export default GetReportsActionButtons;
