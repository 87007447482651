// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import {
  Text,
  Table,
  IconButton,
  Popover,
  Menu,
  Badge,
  Position,
  AddToArtifactIcon,
  AddToFolderIcon,
  BanCircleIcon,
  EditIcon,
  TickCircleIcon,
  MoreIcon,
} from "evergreen-ui";
import {
  toDateFormat32,
  checkIntOrFloat,
  determineBadgeColorForSlotsAvailability,
  generateCurrencySymbol,
} from "../../utils";
import CustomizedPagination from "../../components/CustomizedPagination";

// -----------------------------------------------------------------------
// MembershipPackagesTable Component
// -----------------------------------------------------------------------
class MembershipPackagesTable extends React.Component {
  // -----------------------------------------------------------------------
  // render()
  // -----------------------------------------------------------------------
  render() {
    const {
      membershipPackageDetails,
      currentPage,
      handlePageChange,
      handleAllMembersListPageDisplay,
      reActivatePackage,
      deActivatePackage,
      changeActivePackageDetails,
      handleEditPackage,
      showMenuPopover,
      handleAddMember,
    } = this.props;
    return (
      <React.Fragment>
        <div className="MPT-A1-wrapper">
          <div className="MPT-A1-table-container">
            <Table>
              <Table.Head>
                <Table.TextHeaderCell className="MPT-A1-table-text-header-cell MPT-A1-flex-basis-280">
                  Name
                </Table.TextHeaderCell>
                <Table.TextHeaderCell className="MPT-A1-table-text-header-cell MPT-A1-flex-basis-320">
                  Details
                </Table.TextHeaderCell>
                <Table.TextHeaderCell className="MPT-A1-table-text-header-cell MPT-A1-flex-basis-210">
                  Other Details
                </Table.TextHeaderCell>
                <Table.TextHeaderCell className="MPT-A1-table-text-header-cell MPT-A1-flex-basis-140">
                  Subscribed
                </Table.TextHeaderCell>
                <Table.TextHeaderCell className="MPT-A1-table-text-header-cell MPT-A1-flex-basis-48" />
              </Table.Head>
              <Table.Body className="MPT-A1-table-body" overflow="none">
                {membershipPackageDetails &&
                  membershipPackageDetails.packages &&
                  membershipPackageDetails.packages.map(
                    (membershipPackage, index) => (
                      <Table.Row
                        isSelectable
                        className="MPT-A1-table-row"
                        key={index}
                      >
                        <Table.Cell className="MPT-A1-table-cell MPT-A1-flex-basis-280">
                          <div className="MPT-A1-table-cell-data-container">
                            <Badge
                              color={determineBadgeColorForSlotsAvailability(
                                membershipPackage.availableTickets,
                                membershipPackage.totalTickets
                              )}
                              marginBottom={4}
                              minWidth={96}
                              height="auto"
                              textAlign="left"
                            >
                              {membershipPackage.availableTickets === 0
                                ? `full`
                                : `${membershipPackage.availableTickets} / ${membershipPackage.totalTickets} Available`}
                            </Badge>
                            <Text id="MPT-A1-label-type-one" marginBottom={8}>
                              {membershipPackage.packageName}
                            </Text>
                            <Text marginBottom={8}>
                              {membershipPackage.type}
                            </Text>
                          </div>
                        </Table.Cell>
                        <Table.Cell className="MPT-A1-table-cell MPT-A1-flex-basis-320">
                          <div className="MPT-A1-table-cell-data-container">
                            <Text id="MPT-A1-label-type-two" marginBottom={8}>
                              {membershipPackage.description !== ""
                                ? membershipPackage.description
                                : "-"}
                            </Text>
                            {membershipPackage.additionalInfo.length > 0 && (
                              <Text
                                id="MPT-A1-label-type-three"
                                marginBottom={8}
                              >
                                {membershipPackage.additionalInfo.map(
                                  (info) => `${info} `
                                )}
                              </Text>
                            )}
                          </div>
                        </Table.Cell>
                        <Table.Cell className="MPT-A1-table-cell MPT-A1-flex-basis-210">
                          <div className="MPT-A1-table-cell-data-container">
                            <Text id="MPT-A1-label-type-one" marginBottom={8}>
                              {`${generateCurrencySymbol()} ${checkIntOrFloat(
                                membershipPackage.packagePrice
                              )}`}
                            </Text>
                            <Text id="MPT-A1-label-type-one" marginBottom={8}>
                              Credits: {membershipPackage.packageCredits}
                            </Text>
                            <Text id="MPT-A1-label-type-two" marginBottom={8}>
                              {`${membershipPackage.period} Days`}
                            </Text>
                            <Text id="MPT-A1-label-type-two" marginBottom={8}>
                              {`Started on ${toDateFormat32(
                                membershipPackage.startDate
                              )}`}
                            </Text>
                          </div>
                        </Table.Cell>
                        <Table.Cell className="MPT-A1-table-cell MPT-A1-flex-basis-140">
                          <div className="MPT-A1-table-cell-data-container">
                            <Text
                              id="MPT-A1-label-type-four"
                              marginBottom={8}
                              onClick={(e) =>
                                handleAllMembersListPageDisplay(
                                  "show",
                                  membershipPackage.packageId
                                )
                              }
                            >
                              {`${membershipPackage.soldTickets} Members`}
                            </Text>
                          </div>
                        </Table.Cell>
                        <Table.Cell className="MPT-A1-table-cell MPT-A1-flex-basis-48">
                          <div className="MPT-A1-action-btn-container">
                            {showMenuPopover && (
                              <Popover
                                onOpen={() =>
                                  changeActivePackageDetails(membershipPackage)
                                }
                                content={
                                  <Menu>
                                    <Menu.Group>
                                      <Menu.Item
                                        icon={<AddToFolderIcon />}
                                        onSelect={() =>
                                          handleAddMember(
                                            "view-membership-packages-page"
                                          )
                                        }
                                      >
                                        Add Member
                                      </Menu.Item>
                                      <Menu.Item
                                        icon={<EditIcon />}
                                        onSelect={() =>
                                          handleEditPackage(
                                            "view-membership-packages-page"
                                          )
                                        }
                                      >
                                        Edit Package
                                      </Menu.Item>
                                    </Menu.Group>
                                    <Menu.Divider />
                                    <Menu.Group>
                                      {membershipPackage.isActive ? (
                                        <Menu.Item
                                          icon={<BanCircleIcon />}
                                          intent="danger"
                                          onSelect={() => deActivatePackage()}
                                        >
                                          Deactivate
                                        </Menu.Item>
                                      ) : (
                                        <Menu.Item
                                          icon={<TickCircleIcon />}
                                          intent="success"
                                          onSelect={() => reActivatePackage()}
                                        >
                                          Reactivate
                                        </Menu.Item>
                                      )}
                                    </Menu.Group>
                                  </Menu>
                                }
                                position={Position.BOTTOM_RIGHT}
                              >
                                <IconButton
                                  appearance="minimal"
                                  icon={<MoreIcon />}
                                  width={24}
                                  height={24}
                                />
                              </Popover>
                            )}
                          </div>
                        </Table.Cell>
                      </Table.Row>
                    )
                  )}
              </Table.Body>
            </Table>
          </div>
          {membershipPackageDetails &&
            membershipPackageDetails.packages &&
            membershipPackageDetails.packages?.length > 0 && (
              <div className="MPT-A1-pagination-wrapper">
                <CustomizedPagination
                  currentPage={currentPage}
                  handlePageChange={handlePageChange}
                  totalPages={
                    membershipPackageDetails &&
                    membershipPackageDetails.paginationData &&
                    membershipPackageDetails.paginationData.total
                  }
                />
              </div>
            )}
        </div>
      </React.Fragment>
    );
  }
}

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default MembershipPackagesTable;
