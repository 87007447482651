const configExtrasToast = (state = null, action) => {
    if (action.type === "SET_CONFIG_EXTRA_TOAST") {
      return action.payload;
    } else {
      return state;
    }
    // switch (action.type) {
    //   case "SET_CLUB_EXTRAS_LIST": {
    //     return action.payload;
    //   }
    //   default: {
    //     return state;
    //   }
    // }
  };
  
  export default configExtrasToast;
  