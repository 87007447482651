// -----------------------------------------------------------------------
// Import React and other resources
// -----------------------------------------------------------------------
import React from "react";
import { Button, Dialog, Text, Pane } from "evergreen-ui";
import CancelBookingDialogContent from "../../components/CancelBookingDialogContent";

// -----------------------------------------------------------------------
// BulkBookingDetailsOnSearchScreenActions Component
// -----------------------------------------------------------------------
class BulkBookingDetailsOnSearchScreenActions extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isShown: false,
		};
	}

	// -----------------------------------------------------------------------
	// handleCancelBookingDialog
	// -----------------------------------------------------------------------
	handleCancelBookingDialog = (type) => {
		if (type === "open") {
			this.setState({ isShown: true });
		}
		if (type === "close") {
			this.setState({ isShown: false });
		}
	};

	// -----------------------------------------------------------------------
	// render()
	// -----------------------------------------------------------------------
	render() {
		const { isShown } = this.state;
		const {
			bookingId,
			handleCloseDrawer,
			bookingDetails,
			bookingCancelled,
			getPatternBookingDetailsForDrawer,
			getUpdatedIndividualBulkBookingSearchResult,
		} = this.props;
		return (
			<div className="BBDOSC-A1-wrapper">
				{bookingCancelled === 0 ? (
					<div className="BBDOSC-A1-action-btn-container">
						<Dialog
							isShown={isShown}
							title="Cancel Booking"
							onCloseComplete={(e) => this.handleCancelBookingDialog("close")}
							hasFooter={false}
						>
							<CancelBookingDialogContent
								bookingId={bookingId}
								isPlayoBooking={bookingDetails?.isPlayoBooking}
								handleCancelBookingDialog={this.handleCancelBookingDialog}
								handleCloseDrawer={handleCloseDrawer}
								getPatternBookingDetailsForDrawer={
									getPatternBookingDetailsForDrawer
								}
								bookingType="bulk"
								getUpdatedIndividualBulkBookingSearchResult={
									getUpdatedIndividualBulkBookingSearchResult
								}
							/>
						</Dialog>
						<Button
							className="BBDOSC-A1-button"
							appearance="default"
							intent="danger"
							width={144}
							height={32}
							onClick={(e) => this.handleCancelBookingDialog("open")}
						>
							Cancel Booking
						</Button>
					</div>
				) : bookingCancelled === 1 ? (
					<div className="BBDOSC-A1-cancelled-text-container">
						<Text id="BBDOSC-A1-cancelled-text">Booking is cancelled</Text>
					</div>
				) : bookingCancelled === 2 ? (
					<div className="BBDOSC-A1-action-btn-container">
						<Dialog
							isShown={isShown}
							title="Cancel Booking"
							onCloseComplete={(e) => this.handleCancelBookingDialog("close")}
							hasFooter={false}
						>
							<CancelBookingDialogContent
								bookingId={bookingId}
								isPlayoBooking={bookingDetails?.isPlayoBooking}
								handleCancelBookingDialog={this.handleCancelBookingDialog}
								handleCloseDrawer={handleCloseDrawer}
								getPatternBookingDetailsForDrawer={
									getPatternBookingDetailsForDrawer
								}
								bookingType="bulk"
								getUpdatedIndividualBulkBookingSearchResult={
									getUpdatedIndividualBulkBookingSearchResult
								}
							/>
						</Dialog>

						<Button
							className="BBDOSC-A1-button"
							appearance="default"
							intent="danger"
							width={120}
							height={32}
							onClick={(e) => this.handleCancelBookingDialog("open")}
						>
							Cancel Booking
						</Button>
						<Pane display={"flex"} flexDirection={"column"}>
							<Text margin={10} id="BBDOSC-A1-cancelled-text">
								Booking is Partially cancelled
							</Text>{" "}
						</Pane>
					</div>
				) : null}
			</div>
		);
	}
}

// -----------------------------------------------------------------------
// Export
// -----------------------------------------------------------------------
export default BulkBookingDetailsOnSearchScreenActions;
